import {useParams} from 'react-router-dom';
import {useCurrentRefinements, useClearRefinements} from 'react-instantsearch';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {useSearchAlgoliaStore} from '../../../../contexts/SearchAlgoliaContext';
import {breakpoints} from '../../../../constants';
import Responsive from '../../../../components/Responsive';
import {ReactComponent as CloseIcon} from '../../../../assets/icons/icon-close.svg';
import styles from './CurrentFilters.module.scss';

export const CurrentFilters = () => {
  const {t} = useTranslation('search-page');
  const {slug} = useParams();
  const [searchState, searchActions] = useSearchAlgoliaStore();

  const refinementsProps = {
    excludedAttributes: [
      'meta_side',
      slug && 'meta_issue_name',
      'model',
      'meta_available_for',
      'meta_position',
      'meta_status',
      'query',
    ],
  };

  const {items, refine} = useCurrentRefinements(refinementsProps);
  const {refine: clearRefinements} = useClearRefinements(refinementsProps);

  const refinements = items
    .map((item) => item.refinements.map((refinement) => refinement))
    .flat();

  return (
    <div className={styles.currentFilters}>
      <div className={styles.filtersHeader}>
        <div>
          <h3>{t('filters.title')}</h3>
          <span
            onClick={() => refinements.length && clearRefinements()}
            className={styles.clearAll}>
            {t('filters.clear-filters')}
          </span>
        </div>
        <Responsive>
          {!searchState.hideFilters && (
            <div
              min={breakpoints.lg}
              onClick={() => searchActions.setHideFilters(true)}
              className={styles.hideFilters}>
              <FontAwesomeIcon icon={faChevronLeft} size="1x" />
              {t('filters.hide')}
            </div>
          )}
        </Responsive>
      </div>
      <ul>
        {refinements.map((refinement, index) => (
          <li key={`${refinement.attribute}-${index}`}>
            <span className={styles.filter}>
              {t(`filters.${refinement.attribute.replaceAll('_', '-')}`)}
            </span>
            <span className={styles.filterValue}>{refinement.label}</span>
            <div className={styles.closeIcon}>
              <CloseIcon onClick={() => refine(refinement)} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
