import styled from 'styled-components';
import {mediaQueries} from '../../../constants';

const Container = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.isRightAligned ? 'flex-end' : 'flex-start'};
  ${(props) => (!props.isRightAligned ? 'padding-left: 32px' : '')};
  height: 100%;

  background-image: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-position: ${(props) =>
    !props.isRightAligned
      ? 'right -10px bottom -10px'
      : 'left -10px bottom -10px'};
  background-size: 582px 477px;

  ${mediaQueries.lg} {
    background-position: ${(props) =>
      !props.adaptMobileBackground
        ? 'left 0 bottom -135px;'
        : 'right -45px bottom -135px;'};
    padding-left: 0;
  }
`;

export default Container;
