import i18n from 'i18next';
import * as yup from 'yup';
import {createTranslationKey} from './i18n';

const passwordValidationScheme = yup.object().shape({
  password: yup
    .string()
    .required()
    .min(8)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      i18n.t('forms:errors.string.security'),
    ),
  confirmPassword: yup.string().oneOf(
    [yup.ref('password'), null],
    createTranslationKey('forms:errors.string.match', {
      fieldName: i18n.t('forms:fields.password'),
    }),
  ),
});

const marketValueValidation = () =>
  yup
    .number()
    .transform((_value, originalValue) => {
      if (originalValue) {
        return Number(originalValue.toString().replace(',', '.'));
      }
    })
    .positive();

export {passwordValidationScheme, marketValueValidation};
