import React from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {Col} from 'react-bootstrap';

import Form from '../ItemForm/Form';
import Radio from '../Form/Radio';
import ImageUpload from '../Form/ImageUpload';
import Label from '../Form/Label';
import TextArea from '../Form/TextArea';
import Footer from '../ItemForm/Footer';
import Button from '../Button';
import styles from './ItemConfirmForm.module.scss';

const ClaimConfirmationForm = (props) => {
  const {claim} = props;
  const {t} = useTranslation('confirm');
  const {values, handleChange, errors, touched, handleSubmit, setFieldValue} =
    useFormik({
      initialValues: {
        confirmationStatus: '',
        confirmationComment: '',
        images: [],
      },
      validationSchema: yup.object().shape({
        confirmationStatus: yup.string().required('Required'),
        confirmationComment: yup.string().when('confirmationStatus', {
          is: 'fulfilled',
          then: yup.string().notRequired(),
          otherwise: yup.string().required('Required'),
        }),
      }),
      onSubmit: (values) => {
        values.claimId = claim.id;
        props.onSubmit(values);
      },
    });

  const handleImagesChange = (images) => {
    setFieldValue('images', images);
  };

  if (!claim) return null;

  const renderImageField = () => (
    <div className={styles.imageUploadWrapper}>
      <Label>{t('label.upload-image')}</Label>
      <ImageUpload
        multiple
        name="images"
        onChange={handleImagesChange}
        value={values.images}
        noRequirementImage
      />
    </div>
  );

  const confirmationLabels = {
    complete: {
      supply: t('label.supply.items-complete'),
      service: t('label.service.items-complete'),
    },
    incomplete: {
      supply: t('label.supply.items-concerns'),
      service: t('label.service.items-concerns'),
    },
    failed: {
      supply: t('label.supply.items-failed'),
      service: t('label.service.items-failed'),
    },
  };

  const confirmationOptions = [
    {
      label: confirmationLabels['complete'][props.item.kind],
      value: 'fulfilled',
      renderOptionContent: renderImageField,
    },
    {
      label: confirmationLabels['incomplete'][props.item.kind],
      value: 'partially_fulfilled',
      renderOptionContent: renderImageField,
    },
    {
      label: confirmationLabels['failed'][props.item.kind],
      value: 'rejected',
    },
  ];

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} sm="12">
          <Radio
            name="confirmationStatus"
            options={confirmationOptions}
            onChange={handleChange}
            value={values.confirmationStatus}
            isInvalid={
              !!errors.confirmationStatus && touched.confirmationStatus
            }
            error={errors.confirmationStatus}
          />
        </Form.Group>
      </Form.Row>
      {values.confirmationStatus && (
        <Form.Row>
          <Form.Group as={Col} sm="12">
            <TextArea
              rows="4"
              name="confirmationComment"
              onChange={handleChange}
              value={values.confirmationComment}
              placeholder={t('placeholder')}
              isInvalid={
                !!errors.confirmationComment && touched.confirmationComment
              }
              error={errors.confirmationComment}
            />
          </Form.Group>
        </Form.Row>
      )}
      <Footer>
        <Button
          type="submit"
          variant="primary"
          className="text-uppercase"
          responsiveFullWidth>
          {t('button.confirm')}
        </Button>
      </Footer>
    </Form>
  );
};

export default ClaimConfirmationForm;
