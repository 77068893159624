import React from 'react';

import Item from '../Item';
import Spinner from '../Spinner';
import styles from './ItemsList.module.scss';

const ItemsList = (props) => {
  if (props.isLoading)
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );

  const renderItem = (item, index) => {
    const onItemClick =
      props.onItemClick && item.status !== 'expired'
        ? () => props.onItemClick(item)
        : undefined;
    const onButtonClick = () => {
      if (props.onButtonClick && item.status !== 'expired')
        props.onButtonClick(item);
    };

    return (
      <Item
        className={`${styles.item} ${props.className || styles.itemWidth}`}
        item={item}
        key={index}
        issueName={props.issueName}
        location={props.location}
        onClick={onItemClick}
        onButtonClick={onButtonClick}
        buttonText={props.buttonText}
        menuItems={props.menuItems}
        showEdited={props.showEdited}
        hideErrors={!props.showInvalidItems}
        hideOrganizationName={props.hideOrganizationName}
        showPendingDelivery={props.showPendingDelivery}
      />
    );
  };

  return (
    <div
      className={`${styles.wrapper} ${
        props.className ? props.className : styles.rowWrap
      }`}>
      {props.items.map(renderItem)}
    </div>
  );
};

export default ItemsList;
