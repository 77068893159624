import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import styles from './IsUrgentWidget.module.scss';

const IsUrgentWidget = (props) => {
  const [message, setMessage] = useState('');
  const [checked, setChecked] = useState(typeof props.checked !== 'undefined');

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setMessage(
        'This action will replace the current urgent Issue with the selected one',
      );
    } else {
      setMessage('');
    }
  };

  return (
    <React.Fragment>
      <Form.Check
        type="checkbox"
        id={props.id}
        name={props.name}
        label={props.label}
        onChange={handleChange}
        checked={checked}
      />
      <p className={styles.message}>{message}</p>
    </React.Fragment>
  );
};

export default IsUrgentWidget;
