import React from 'react';
import styled from 'styled-components';

import {Card} from 'react-bootstrap';
import {mediaQueries} from '../../constants';

const StyledCard = styled(Card)`
  margin-bottom: 15px;
  padding: 40px 15px 10px;

  ${mediaQueries.lg} {
    &.card {
      background-color: transparent;
      border-style: none;
      padding: 0;
    }
    margin-bottom: 0;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin: 0;
  font-size: 1.35rem;
  font-weight: 500;
  margin-bottom: ${(props) => (props.hasSubtitle ? '10px' : '40px')};

  ${mediaQueries.lg} {
    text-align: left;
    margin-bottom: 10px;
    font-size: 18px;
  }
`;

const Subtitle = styled.p`
  font-size: 14px;
  line-height: 24px;
  text-align: center;

  ${mediaQueries.lg} {
    text-align: left;
  }
`;

const Step = (props) => {
  return (
    <StyledCard className={props.className}>
      <Title hasSubtitle={!!props.subtitle}>{props.title}</Title>
      {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
      {props.children}
    </StyledCard>
  );
};

export default Step;
