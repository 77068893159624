import React, {useState, useEffect, useCallback} from 'react';

import AddressBoxes from './AddressBoxes';
import LocationForm from './LocationForm';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import {useApi} from '../../contexts/ApiContext';

const serializeAddress = (address) => {
  if (!address) {
    return null;
  }

  const copy = {...address};
  if (typeof copy.id == 'string') {
    delete copy.id;
  }
  return copy;
};

const LocationSelectV2 = (props) => {
  const {
    apiCommit = false,
    apiFetch = true,
    onAddressesChange,
    onAddressSelected, // onChange
    addressSelected, // value
    isFullWidth = false,
    notAddAddress,
    notEditAddress,
    notDeleteAddress,
    readOnly,
  } = props;

  const api = useApi();
  const stores = useStores();
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [sequence, setSequence] = useState(1);
  const [address, setAddress] = useState(null);
  const [addresses, setAddresses] = useState(props.addresses || []);
  const {user} = stores.app;

  const fetchOrgAddress = useCallback(async () => {
    if (user.organization_id) {
      const response = await api.getOrg(user.organization_slug);
      setDefaultAddress(response.addresses[0].location);
    } else {
      const response = await api.getIpLocation();
      if (response.location) setDefaultAddress(response.location);
    }
  }, [user.organization_id, user.organization_slug, api]);

  useEffect(() => {
    fetchOrgAddress();
  }, [fetchOrgAddress]);

  // sync addresses
  useEffect(() => {
    if (onAddressesChange) {
      onAddressesChange(addresses.map(serializeAddress));
    }
  }, [addresses, onAddressesChange]);

  useEffect(() => {
    const fetchAddresses = async () => {
      const results = await api.getAddresses();
      setAddresses(results);
    };

    if (apiFetch && user.status !== 'processing') {
      fetchAddresses();
    }
  }, [apiFetch, user.status, api]);

  const handleHide = () => {
    setAddress(null);
  };

  const handleSubmit = async (values) => {
    const results = [...addresses];
    const index = results.map((item) => item.id).indexOf(values.id);
    let result;

    if (apiCommit) {
      if (values.id) {
        result = await api.updateAddress(values.id, values);
      } else {
        result = await api.createAddress(values);
      }
    } else {
      result = {...values};
    }
    if (values.id) {
      results[index] = result;
    } else {
      results.push(result);
    }

    setAddresses(results);
    if (onAddressSelected) {
      onAddressSelected(result);
    }
    handleHide();
  };

  const handleAddressAdded = (address, id) => {
    if (!id && !apiCommit) {
      id = `TMP-${sequence}`;
      setSequence(sequence + 1);
    }
    setAddress({
      ...address,
      id: id,
      location_original: address.location,
    });
  };

  const handleDeleteAddress = async (values) => {
    const results = [...addresses];
    const index = results.map((item) => item.id).indexOf(values.id);

    if (apiCommit) {
      await api.deleteAddress(values.id);
    }

    results.splice(index, 1);

    if (addressSelected && values.id === addressSelected.id) {
      if (onAddressSelected) {
        onAddressSelected(null);
      }
    }
    setAddresses(results);
  };

  const handleEditAddress = (address) => {
    setAddress({...address});
  };

  const handleCreateAddress = () => {
    setAddress({location: defaultAddress});
  };

  if (readOnly) {
    return (
      <React.Fragment>
        <AddressBoxes
          addressSelected={addressSelected}
          isFullWidth={isFullWidth}
          readOnly={readOnly}></AddressBoxes>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <AddressBoxes
        onDeleteAddress={(address) => {
          handleDeleteAddress(address);
        }}
        onSelectAddress={(address) => {
          address ? handleEditAddress(address) : handleCreateAddress();
        }}
        onClickAddress={(address) => {
          if (onAddressSelected) {
            onAddressSelected(address);
          }
        }}
        addressSelected={addressSelected}
        addresses={addresses}
        isFullWidth={isFullWidth}
        notAddAddress={notAddAddress}
        notEditAddress={notEditAddress}
        notDeleteAddress={notDeleteAddress}
      />
      {address && (
        <LocationForm
          address={address}
          onSubmit={handleSubmit}
          onHide={handleHide}
          onAddressAdded={handleAddressAdded}
        />
      )}
    </React.Fragment>
  );
};

export default observer(LocationSelectV2);
