function getUserDisplayName(user) {
  return user.first_name && user.last_name
    ? `${user.first_name} ${user.last_name}`
    : user.email;
}

function getUserOrganization(user) {
  return user.registered_organization_name;
}

export {getUserDisplayName, getUserOrganization};
