import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {CardDeck, Card, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faCheck, faPlus} from '@fortawesome/free-solid-svg-icons';
import {useApi} from '../../../contexts/ApiContext';
import {useStores} from '../../../contexts/StoresContext';
import {capitalize} from '../../../utils/string';
import {summaryApiParams} from '../../../components/ItemReports/filterUtils';
import Spinner from '../../../components/Spinner';
import MoneySign from '../../../assets/icons/money-sign.svg';
import TotalMatchesIcon from '../../../assets/icons/total_matches.svg';
import styles from './SummaryReport.module.scss';

const SummaryReport = ({filters, filterByMember, formatNumber}) => {
  const {t} = useTranslation('reports');
  const stores = useStores();
  const api = useApi();
  const {currency} = stores.app;
  const [summary, setSummary] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const userCurrencyName = capitalize(currency.name);

  useEffect(() => {
    const fetchSummary = async () => {
      const responseSummary = await api.getOrgSummaryReports({
        ...summaryApiParams(filters),
        currency_id: currency ? currency.id : undefined,
        org_id: filterByMember,
      });
      setSummary(responseSummary);
      setIsLoading(false);
    };

    fetchSummary();
    setIsLoading(true);
  }, [api, filters, filterByMember, currency]);

  const resumes = summary
    ? [
        {
          title: t('titles.total_value_of_support_received', {
            currency: userCurrencyName,
          }),
          icon: (
            <img src={MoneySign} alt="Money Icon" className={styles.bigIcon} />
          ),
          miniIcon: (
            <FontAwesomeIcon icon={faArrowDown} className={styles.miniIcon} />
          ),
          value: formatNumber(summary.total_value_of_support_received),
          symbol: currency.symbol,
        },
        {
          title: t('titles.total_offers_posted', {currency: userCurrencyName}),
          icon: (
            <img src={MoneySign} alt="Money Icon" className={styles.bigIcon} />
          ),
          miniIcon: (
            <FontAwesomeIcon icon={faPlus} className={styles.miniIcon} />
          ),
          value: formatNumber(summary.total_offers_posted),
          symbol: currency.symbol,
        },
        {
          title: t('titles.total_value_of_support_given', {
            currency: userCurrencyName,
          }),
          icon: (
            <img src={MoneySign} alt="Money Icon" className={styles.bigIcon} />
          ),
          miniIcon: (
            <FontAwesomeIcon icon={faCheck} className={styles.miniIcon} />
          ),
          value: formatNumber(summary.total_value_of_support_given),
          symbol: currency.symbol,
        },
        {
          title: t('titles.total_matches', {currency: userCurrencyName}),
          icon: (
            <img
              src={TotalMatchesIcon}
              alt="Total Matches"
              className={styles.bigIcon}
            />
          ),
          value: formatNumber(summary.total_matches),
          className: styles.cardTotalMatches,
        },
      ]
    : [];

  const mapSummary = (item) => {
    return (
      <Card
        key={`${item.value}-${item.title}`}
        className={item.className || styles.cardContainer}>
        <Card.Body>
          <Row className={styles.CardRow}>
            <Col xs={2}>
              <div className={styles.iconContainer}>
                {item.icon}
                {item.miniIcon}
              </div>
            </Col>
            <Col xs={10} className={styles.CardData}>
              <p className={styles.cardText}>{item.title}</p>
              <p className={styles.cardAmount}>
                <span className={styles.currencySymbol}>{item.symbol}</span>{' '}
                {item.value}
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <CardDeck className={styles.cardDeck}>
      {isLoading ? (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      ) : (
        resumes.map(mapSummary)
      )}
    </CardDeck>
  );
};

export default SummaryReport;
