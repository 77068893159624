import {useEffect} from 'react';

import {isFeatureEnabled} from '../../utils/features';
import {FEATURES} from '../../constants';
import {useStores} from '../../contexts/StoresContext';

const ZenDesk = () => {
  const stores = useStores();
  useEffect(() => {
    if (isFeatureEnabled(FEATURES.showZendesk, stores.app.features)) {
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src =
        'https://static.zdassets.com/ekr/snippet.js?key=9ce701c2-94cc-41ba-85e5-8b76ad350038';
      script.async = true;
      document.body.appendChild(script);
    }
  }, [stores.app.features]);

  return null;
};
export default ZenDesk;
