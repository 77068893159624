import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {getValueFromQS} from '../utils/querystring';

const DEFAULT_PAGINATOR_PARAMETER_NAME = 'page';

const usePaginator = (
  parameterName = DEFAULT_PAGINATOR_PARAMETER_NAME,
  pageSizeParamName = 'page_size',
) => {
  const history = useHistory();
  const currentPage = parseInt(getValueFromQS(parameterName, 1));
  const pageSize = parseInt(getValueFromQS(pageSizeParamName, 10));

  const changePage = useCallback(
    (to, page) => {
      const query = new URLSearchParams(history.location.search);

      if (page !== 1) {
        if (query.get(parameterName)) {
          query.set(parameterName, page);
        } else {
          query.append(parameterName, page);
        }
      } else {
        query.delete(parameterName);
      }

      history.push(to + '?' + query.toString());
    },
    [history, parameterName],
  );

  const changePageSize = useCallback(
    (to, pageSize) => {
      const query = new URLSearchParams(history.location.search);
      query.set(pageSizeParamName, pageSize);
      query.delete(parameterName);
      history.push(to + '?' + query.toString());
    },
    [history, pageSizeParamName, parameterName],
  );

  return {changePage, changePageSize, currentPage, pageSize};
};

export {usePaginator, DEFAULT_PAGINATOR_PARAMETER_NAME};
