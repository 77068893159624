import React, {forwardRef, useContext, useEffect, useRef} from 'react';
import {Form} from 'react-bootstrap';
import ChoiceOther from '../../Helper/ChoiceOther';
import FormContext from '../../../Form/FormContext';
import styles from './ChoiceControl.module.scss';

const FormRadio = forwardRef((props, ref) => {
  const {label, ...rest} = props;

  return (
    <div className={styles.option}>
      <Form.Label className={styles.label}>
        <input className={styles.radio} {...rest} type="radio" ref={ref} />
        <span className={styles.radioIcon}>
          <span className={styles.circle} />
        </span>
        {label}
      </Form.Label>
    </div>
  );
});

const ChoiceControl = (props) => {
  const {choices, name, allowOther, onChange, value} = props;
  const inputRef = useRef(null);
  const {setFieldRef} = useContext(FormContext);

  useEffect(() => {
    setFieldRef(name, inputRef);
  }, [name, setFieldRef]);

  const mapper = (choice, index) => {
    return (
      <FormRadio
        label={choice.label}
        key={`choice-${choice.id}`}
        onChange={onChange}
        name={name}
        value={choice.id}
        checked={value.indexOf(choice.id) !== -1}
        ref={index === 0 ? inputRef : undefined}
      />
    );
  };

  return (
    <Form.Group className={styles.choiceControl}>
      {choices.map(mapper)}
      <ChoiceOther
        label="Other"
        name={name}
        as={(props) => <FormRadio {...props} />}
        allowOther={allowOther}
        onChange={onChange}
      />
    </Form.Group>
  );
};

export default ChoiceControl;
