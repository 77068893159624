import {observer} from 'mobx-react';
import {Nav, NavDropdown} from 'react-bootstrap';
import React from 'react';

const Navigation = (props) => {
  const {data} = props;

  const renderItems = () => {
    return data.items.map((item, i) => {
      const params = {};

      const active = data.active === item.name;

      if (item.disabled) {
        return null;
      }

      if (!(item.items || []).length) {
        return (
          <Nav.Item {...params} key={`nav-${i}`}>
            <Nav.Link active={active} href={item.url} className="nav-link">
              {item.label}
            </Nav.Link>
          </Nav.Item>
        );
      } else {
        const items = item.items.map((item, j) => {
          return (
            <NavDropdown.Item key={`nav-${i}-${j}`} href={item.url}>
              {item.label}
            </NavDropdown.Item>
          );
        });
        return (
          <NavDropdown
            active={active}
            {...params}
            key={`nav-${i}`}
            title={item.label}
          >
            {items}
          </NavDropdown>
        );
      }
    });
  };

  return <div className={props.className}>{renderItems()}</div>;
};

export default observer(Navigation);
