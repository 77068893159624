import React, {useEffect, useMemo, useCallback, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import moment from 'moment';
import {
  Card,
  Dropdown,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {USER_GROUPS, useStores} from '../../../../contexts/StoresContext';
import {useApi} from '../../../../contexts/ApiContext';
import {USER_PROFILE} from '../../../../urls';
import {usePaginator} from '../../../../hooks/paginator';
import {breakpoints} from '../../../../constants';
import Table from '../../../../components/Table';
import CardsList from '../../../../components/CardsList/CardsList';
import Responsive from '../../../../components/Responsive';
import DotMenu from '../../../../components/DotMenu';
import UserSearchBar from '../UserSearchBar';
import MemberSearchBar from '../MemberSearchBar';
import Button from '../../../../components/Button';
import {exportCsv} from '../../../ReportsPage/utils';
import styles from './UsersList.module.scss';

const PAGE_SIZE = 6;

const UsersList = () => {
  const stores = useStores();
  const api = useApi();
  const history = useHistory();
  const match = useRouteMatch();
  const [usersCount, setUsersCount] = useState(null);
  const [users, setUsers] = useState([]);
  const {currentPage} = usePaginator('user_page');
  const [search, setSearch] = useState('');
  const [groupSearch, setGroupSearch] = useState([]);
  const {t} = useTranslation('user');
  const {organization, user} = stores.app;
  const [searchedMember, setSearchedMember] = useState(null);
  const [title, setTitle] = useState(null);
  const showAdmin = user.isAdmin;
  const [memberId, setMemberId] = useState(
    showAdmin ? (searchedMember ? searchedMember.value : '') : organization.id,
  );

  useEffect(() => {
    const name = searchedMember ? searchedMember.label : organization.name;
    const title =
      !searchedMember && showAdmin
        ? t('titles.users-list')
        : t('titles.users-list-org', {org: name});
    setTitle(title);
  }, [searchedMember, organization.name, showAdmin, t]);

  useEffect(() => {
    const id = showAdmin
      ? searchedMember
        ? searchedMember.value
        : ''
      : organization.id;

    setMemberId(id);
  }, [showAdmin, searchedMember, organization.id]);

  const fetchUsers = useCallback(async () => {
    const response = await api.getOrgUsers({
      page_size: PAGE_SIZE,
      page: currentPage,
      search,
      group_search: groupSearch,
      org_id: memberId,
    });
    setUsers(response.results);
    setUsersCount(response.count);
  }, [currentPage, search, groupSearch, memberId, api]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const renderMoreOptions = useCallback(
    (user) => {
      const handleToggleUserStatus = async () => {
        await api.toggleUserStatus(user.uuid);
        fetchUsers();
      };

      const handleUserDetails = () =>
        history.push(USER_PROFILE.replace(':userUUID', user.uuid));

      return (
        <DotMenu>
          {(user.status === 'active' ||
            user.status === 'inactive' ||
            user.status === 'disabled') && (
            <Dropdown.Item onClick={handleToggleUserStatus}>
              {user.status === 'active'
                ? t('buttons.deactivate-user')
                : t('buttons.activate-user')}
            </Dropdown.Item>
          )}
          <Dropdown.Item
            disabled={user.status === 'processing'}
            onClick={handleUserDetails}>
            {t('buttons.user-details')}
          </Dropdown.Item>
        </DotMenu>
      );
    },
    [t, fetchUsers, history, api],
  );

  const userHeaders = useMemo(() => {
    const headers = [
      {
        title:
          t('columns.user.full-name') + '/' + t('columns.user.email-address'),
        size: showAdmin ? 2 : 3,
      },
      {
        title: t('columns.user.role'),
        size: 2,
      },
      {
        title: t('columns.user.created'),
        size: 2,
      },
      {
        title: t('columns.user.last_login'),
        size: 2,
      },
    ];
    if (showAdmin) {
      headers.push({
        title: t('columns.user.organization'),
        size: 2,
      });
    }
    headers.push(
      {
        title: t('columns.user.status'),
        size: showAdmin ? 1 : 2,
      },
      {
        title: t('columns.user.more'),
        size: 1,
      },
    );

    return headers;
  }, [t, showAdmin]);
  const userColumns = useMemo(() => {
    const headers = [
      {
        renderCell: (user) => (
          <>
            {user.first_name} {user.last_name}
            <br />
            {user.email}
          </>
        ),
        size: showAdmin ? 2 : 3,
      },
      {
        renderCell: (user) => {
          const isAdmin = user.groups.some(
            (roleId) =>
              roleId === USER_GROUPS.admin_group_id ||
              roleId === USER_GROUPS.super_admin_group_id,
          );
          const isOrgAdmin = user.groups.includes(
            USER_GROUPS.org_admin_group_id,
          );
          const userRole = isAdmin
            ? t('row-data.user-role.platform-admin')
            : isOrgAdmin
            ? t('row-data.user-role.team-lead')
            : t('row-data.user-role.team-member');

          return userRole;
        },
        prefix: () => (
          <span className={styles.boldText}>{t('titles.role')}:&nbsp;</span>
        ),
        size: 2,
      },
      {
        renderCell: (user) =>
          moment(user.created_at).format('MM-DD-YYYY, h:mm:ss a'),
        prefix: () => (
          <span className={styles.boldText}>
            {t('titles.created-at')}:&nbsp;
          </span>
        ),
        size: 2,
      },
      {
        renderCell: (user) =>
          user.last_login
            ? moment(user.last_login).format('MM-DD-YYYY, h:mm:ss a')
            : '',
        prefix: () => (
          <span className={styles.boldText}>
            {t('titles.last_login')}:&nbsp;
          </span>
        ),
        size: 2,
      },
    ];
    if (showAdmin) {
      headers.push({
        renderCell: (user) =>
          user.org_name || user.registered_organization_name,
        size: 2,
      });
    }
    headers.push(
      {
        renderCell: (user) => (
          <div className={styles.statusContainer}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{t(`row-data.user-status.${user.status}`)}</Tooltip>
              }>
              <p className={styles.status}>
                <FontAwesomeIcon
                  color={user.status}
                  icon="circle"
                  className={`${styles.circleIcon} ${
                    user.status === 'active' ? styles.active : styles.notActive
                  }`}
                />
                {t(`row-data.user-status.${user.status}`)}
              </p>
            </OverlayTrigger>
          </div>
        ),
        size: showAdmin ? 1 : 2,
      },
      {
        renderCell: renderMoreOptions,
        size: 1,
        newColumn: true,
        moreOptions: true,
      },
    );

    return headers;
  }, [t, renderMoreOptions, showAdmin]);

  const handleUserSearchChange = (value) => {
    const group = [];
    if (value !== '') {
      const rawValue = value.toLowerCase().replace(' ', '');
      if ('teammember'.includes(rawValue)) group.push('org_user_group');
      if ('platformadmin'.includes(rawValue)) group.push('admin_group');
      if ('teamlead'.includes(rawValue)) group.push('org_admin_group');
    }

    setSearch(value);
    setGroupSearch(group);
    history.push(`${match.url}`);
  };

  const handleMemberSearchChange = (value) => {
    setSearchedMember(value);
    history.push(`${match.url}`);
  };

  const handleExport = async () => {
    const response = await api.getOrgUsersCsv({
      search,
      group_search: groupSearch,
      org_id: memberId,
    });
    exportCsv(response, `users_${Date.now()}.csv`);
  };

  return (
    <Card className={styles.customCard}>
      {showAdmin && <MemberSearchBar onChange={handleMemberSearchChange} />}
      <Row>
        <Col sm={6}>
          <UserSearchBar onSearchChange={handleUserSearchChange} />
        </Col>
        <Col className={styles.buttonWrapper} sm={6}>
          <Button
            onClick={handleExport}
            size="sm"
            variant="outline-primary"
            className={styles.customButton}>
            {t('buttons.export-csv')}
          </Button>
        </Col>
      </Row>
      <h1 className={styles.tableTitle}>
        {title} ({usersCount})
      </h1>
      <Responsive>
        <Table
          max={breakpoints.md}
          headers={userHeaders}
          columns={userColumns}
          data={users}
          count={usersCount}
          pageSize={PAGE_SIZE}
          paginatorParameterName={'user_page'}
        />
      </Responsive>
      <Responsive>
        <CardsList
          min={breakpoints.md}
          data={users}
          columns={userColumns}
          count={usersCount}
          pageSize={PAGE_SIZE}
          paginatorParameterName={'user_page'}
        />
      </Responsive>
    </Card>
  );
};

export default observer(UsersList);
