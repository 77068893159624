import React, {useRef, useEffect, useState} from 'react';

function getTextWidth(text, font) {
  var canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'));
  var context = canvas.getContext('2d');
  context.font = font;
  var metrics = context.measureText(text);
  return metrics.width;
}

const Paragraph = (props) => {
  const styles = useRef();
  const lines = props.lines;
  const [text, setText] = useState('');
  useEffect(() => {
    if (styles.current) {
      const fontSize = window
        .getComputedStyle(styles.current)
        .getPropertyValue('font-size');
      const width = window
        .getComputedStyle(styles.current)
        .getPropertyValue('width');
      const fontFamily = window
        .getComputedStyle(styles.current)
        .getPropertyValue('font-family');
      const fontWeight = window
        .getComputedStyle(styles.current)
        .getPropertyValue('font-weight');
      const averageWidth = Math.ceil(
        getTextWidth(props.text, `${fontWeight} ${fontSize} ${fontFamily}`) /
          props.text.length,
      );
      // 1 is a magic number in order to have the right letter width
      const charPerLine = parseInt(width, 10) / (averageWidth + 1);
      const totalAllowedText = charPerLine * lines;

      if (props.text.length > totalAllowedText) {
        setText(props.text.substring(0, totalAllowedText - 3) + '...');
      }
    }
  }, [styles, lines, props.text, text]);
  return (
    <p className={props.className} ref={styles}>
      {props.children}
      {text || props.text}
    </p>
  );
};

export default Paragraph;
