import React, {useEffect, useState, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {Card} from 'react-bootstrap';
import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';
import {usePageStore} from '../../contexts/PageContext';
import {usePanelStore} from '../../contexts/PanelContext';
import {usePaginator} from '../../hooks/paginator';
import {INVITE_USER, MANAGE_ADMIN_PAGE, INVITE_USER_SUCCESS} from '../../urls';
import Title from '../../components/Title';
import Button from '../../components/Button';
import {Route} from '../../components/Layout';
import InviteForm from '../../components/InviteForm/InviteForm';
import ThankYouModal from '../../components/Modals/ThankYouModal';
import Panel from '../../components/Panel';
import UsersInvitationsList from './components/UsersInvitationsList';
import UsersList from './components/UsersList';
import inviteLogo from '../../assets/invite-user.png';
import styles from './UserAdmin.module.scss';

const PAGE_SIZE = 6;

const UserAdmin = () => {
  const stores = useStores();
  const api = useApi();
  const [, pageActions] = usePageStore();
  const {t} = useTranslation('user');
  const history = useHistory();
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [pendingInvitationsCount, setPendingInvitationsCount] = useState(null);
  const {currentPage: invitationPage} = usePaginator('inv_page');
  const [, panelActions] = usePanelStore();

  const {name: organizationName, id: organizationId} = stores.app.organization;

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'manage-users'});
  }, [pageActions]);

  const fetchUsersInvitation = useCallback(async () => {
    const response = await api.getInvitations({
      page_size: PAGE_SIZE,
      page: invitationPage,
      invitation_type: 'org_user',
      my_org_id: organizationId,
    });

    setPendingInvitations(response.results);
    setPendingInvitationsCount(response.count);
  }, [invitationPage, organizationId, api]);

  useEffect(() => {
    fetchUsersInvitation();
  }, [fetchUsersInvitation]);

  const handleAddUserClick = () => {
    history.push(INVITE_USER);
    panelActions.setPanelMount(true);
  };

  const handleGoToUserList = () => {
    fetchUsersInvitation();
    history.push(MANAGE_ADMIN_PAGE);
  };

  const handleOnSave = () => {
    history.push(INVITE_USER_SUCCESS);
  };

  const handleResendInvitation = async (token) => {
    await api.resendInvitation({token, invitationType: 'org_user'});
    history.push(INVITE_USER_SUCCESS);
  };

  const handleDeleteInvitation = async (token) => {
    await api.deleteInvitation(token);
    fetchUsersInvitation();
  };

  return (
    <div className={styles.wrapper}>
      <Title>{t('titles.manage-users')}</Title>
      <UsersList />
      <Card className={styles.customCard}>
        <div className={styles.inviteWrapper}>
          <div>
            <img
              src={inviteLogo}
              alt="inviteLogo"
              className={styles.customImg}
            />
          </div>
          <div className={styles.inviteInnerContainer}>
            <h2 className={styles.inviteMembersTitle}>
              {t('user:titles.invite-users')}
            </h2>
            <Button onClick={handleAddUserClick}>
              {t('user:buttons.add-user')}
            </Button>
          </div>
        </div>
      </Card>
      <UsersInvitationsList
        invitations={pendingInvitations}
        count={pendingInvitationsCount}
        pageSize={PAGE_SIZE}
        onResendInvitation={handleResendInvitation}
        onDeleteInvitation={handleDeleteInvitation}
      />
      <Route path={[INVITE_USER]}>
        <Panel onClose={handleGoToUserList}>
          <Route path={INVITE_USER} exact>
            <InviteForm
              panelTitle={t('user:titles.invite-user')}
              formTitle={t('user:titles.add-user')}
              inviteType="org-user"
              onSave={handleOnSave}
              breadcrumb="user-invite-form"
            />
          </Route>
        </Panel>
      </Route>
      <Route path={INVITE_USER_SUCCESS} exact>
        <ThankYouModal
          header={t('thank-you:labels.invite.user-header')}
          titleMessage={t('thank-you:labels.invite.org-user')}
          onPrimaryClick={handleGoToUserList}
          primaryButtonText={t('thank-you:buttons.return-to-manage-users')}>
          {t('thank-you:labels.message.org-user-invited', {organizationName})}
        </ThankYouModal>
      </Route>
    </div>
  );
};

export default observer(UserAdmin);
