import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import {Col, Row} from 'react-bootstrap';

import ItemDataTitle from '../ItemForm/ItemDataTitle';
import ItemDataValue from '../ItemForm/ItemDataValue';
import {getCreatedAt} from './utils';
import UndisclosedField from './UndisclosedField';
import ItemDeliveryInformation from '../ItemDeliveryInformation/ItemDeliveryInformation';

const NeedServiceInformation = (props) => {
  const {item} = props;
  const {t} = useTranslation('forms');
  const jobTypeSerializer = {
    remote: t('fields.job-type-remote'),
    on_site: t('fields.job-type-on-site'),
    either: t('fields.job-type-either'),
  };
  const meeetingsRequirementsSerializer = {
    requires_discussion: t('fields.meetings-requirements-requires-discussion'),
    ready_to_start: t('fields.meetings-requirements-ready-to-start'),
  };

  return (
    <React.Fragment>
      <Row>
        {item.jobType && (
          <Col sm={6}>
            <ItemDataTitle>{t('labels.service.need.job-type')}</ItemDataTitle>
            <ItemDataValue>{jobTypeSerializer[item.jobType]}</ItemDataValue>
          </Col>
        )}
        {item.meetingsRequirements && (
          <Col sm={6}>
            <ItemDataTitle>
              {t('labels.service.need.meetings-requirements')}
            </ItemDataTitle>
            <ItemDataValue>
              {meeetingsRequirementsSerializer[item.meetingsRequirements]}
            </ItemDataValue>
          </Col>
        )}
        {item.deliverable && (
          <Col sm={12}>
            <ItemDataTitle>
              {t('labels.service.need.deliverable')}
            </ItemDataTitle>
            <ItemDataValue>{item.deliverable}</ItemDataValue>
          </Col>
        )}
      </Row>
      <Row>
        <Col sm={12}>
          <ItemDataTitle>{t('labels.item-created-at')}</ItemDataTitle>
          <ItemDataValue>{getCreatedAt(item)}</ItemDataValue>
        </Col>
      </Row>
      <Row>
        {item.description && (
          <Col sm={12}>
            <ItemDataTitle>{t('labels.item-description')}</ItemDataTitle>
            <ItemDataValue withoutTextStyle>{item.description}</ItemDataValue>
          </Col>
        )}
      </Row>
      {item.location && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.location')}</ItemDataTitle>
            <ItemDataValue withoutTextStyle>
              <ItemDeliveryInformation item={item} />
            </ItemDataValue>
          </Col>
        </Row>
      )}
      {item.phoneNumber && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.phone-number')}</ItemDataTitle>
            <UndisclosedField item={item} field="phone-number" />
          </Col>
        </Row>
      )}
      {item.contactEmail && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.email')}</ItemDataTitle>
            <UndisclosedField item={item} field="email" />
          </Col>
        </Row>
      )}
      {item.contactName && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.contact-name')}</ItemDataTitle>
            <ItemDataValue>{item.contactName}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.user && item.user.timezone && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.timezone')}</ItemDataTitle>
            <ItemDataValue>{item.user.timezone}</ItemDataValue>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default observer(NeedServiceInformation);
