import React, {useCallback, useEffect, useState} from 'react';

import {useApi} from '../../contexts/ApiContext';
import Select from './Select';

const CurrencySelect = (props) => {
  const {value, onChange, ...rest} = props;
  const api = useApi();
  const [currencies, setCurrencies] = useState([]);

  const fetchCurrencies = useCallback(async () => {
    const response = await api.getClientCurrencies();
    setCurrencies(response.results);
  }, [api]);

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  const handleOnChange = (e) => {
    const selectedCurrency = currencies.find(
      (currency) => currency.id === Number(e.target.value),
    );
    onChange(selectedCurrency);
  };

  const mapOptions = (opt) => (
    <option key={opt.id} value={opt.id}>
      {opt.code}
    </option>
  );

  return (
    <Select {...rest} onChange={handleOnChange} value={value}>
      {currencies.map(mapOptions)}
    </Select>
  );
};

export default CurrencySelect;
