import SummaryReport from '../ReportsPage/SummaryReport';
import ActivityDetail from '../ReportsPage/Activity/ActivityDetail';
import ItemReportsTable from './components/ItemReportsTable';
import styles from './ItemReportsPage.module.scss';
import moment from 'moment';
import {useState, useEffect, useMemo} from 'react';
import {useStores} from '../../contexts/StoresContext';
import {useApi} from '../../contexts/ApiContext';
import {ReportProvider} from './context/ReportContext';
import {useFormatNumber} from '../../hooks/number';
import {DEFAULT_PAGINATOR_PARAMETER_NAME} from '../../hooks/paginator';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {Route} from '../../components/Layout';
import {ITEM_REPORTS_ACTIVITY_DETAIL} from '../../urls';
import AddFilterButton from '../../components/ItemReports/AddFilterButton';
import SavedViewButton from '../../components/ItemReports/SavedView/SavedViewButton';
import Export from '../../components/ItemReports/Export/Export';
import Filter from '../../components/ItemReports/Filter';
import {removeQueryParam} from '../../components/ItemReports/filterUtils';
import {FILTERS} from '../../components/ItemReports/FilterControl';
import SavedViewToast from '../../components/ItemReports/SavedView/SavedViewToast';
import CustomizeColumns, {
  COLUMNS_QUERY_PARAM_NAME,
} from '../../components/ItemReports/CustomizeColumns';
import {useTranslation} from 'react-i18next';
import {
  getCsvHeaders,
  getItemsCsvData,
  getClaimedItemsCsvData,
  executeCsvDownload,
  getCsvColumns,
} from './itemReportUtils';
import {useLocaleNumber} from '../../hooks/number';
import {itemApiParams} from '../../components/ItemReports/filterUtils';
import Visualizations from '../../components/ItemReports/Visualizations/Visualizations';
import {getLookupDataByKey} from '../../utils';
import {AlertLinkReport} from '../../components/ItemReports/AlertLinkReport';
import Responsive from '../../components/Responsive';
import {breakpoints} from '../../constants';

const ItemReportsPage = () => {
  const stores = useStores();
  const api = useApi();
  const history = useHistory();
  const match = useRouteMatch();
  const localeNumber = useLocaleNumber();
  const {organization, user, currency} = stores.app;
  const [selectedFilter, setSelectedFilter] = useState('');
  const [savedViewToastText, setSavedViewToastText] = useState('');
  const [csvItemsDownloadReady, setCsvItemsDownloadReady] = useState(false);
  const [csvClaimedItemsDownloadReady, setCsvClaimedItemsDownloadReady] =
    useState(false);
  const [orgs, setOrgs] = useState([]);
  const [issues, setIssues] = useState([]);
  const [items, setItems] = useState([]);
  const [uoms, setUoms] = useState([]);
  const [claimedItems, setClaimedItems] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const {t} = useTranslation('item-reports');
  const categories = useMemo(
    () =>
      stores.app.choices.category.sort((a, b) => a.name.localeCompare(b.name)),
    [stores.app.choices.category],
  );

  const url = history.location.search;
  const queryStringParams = useMemo(() => new URLSearchParams(url), [url]);
  const url_without_page = useMemo(() => {
    const qs = new URLSearchParams(url);
    qs.delete(DEFAULT_PAGINATOR_PARAMETER_NAME);
    return qs.toString();
  }, [url]);
  const filters = useMemo(() => {
    const urlParams = new URLSearchParams(url_without_page);
    return Object.entries(FILTERS).flatMap(([name, filterClass]) => {
      const filterValues = urlParams.getAll(name);
      return filterValues.map((item, index) => {
        const filterKey = name + '_' + index;
        return new filterClass(filterKey, item);
      });
    });
  }, [url_without_page]);
  const showAdmin = user.isAdmin;
  const memberId = showAdmin ? undefined : organization.id;

  useEffect(() => {
    const fetchItems = async () => {
      const response = await api.getOrgItemsReport({
        org_id: memberId,
        sort_by: sortBy,
        sort_order: sortOrder,
        page_size: 10 ** 9, // 1 billion, essentially infinity
        ...itemApiParams(filters),
      });
      setCsvItemsDownloadReady(true);
      setItems(response.results);
    };
    fetchItems();
  }, [api, memberId, sortBy, sortOrder, filters]);

  useEffect(() => {
    const fetchClaimedItems = async () => {
      const response = await api.getOrgClaimedItemsReport({
        org_id: memberId,
        sort_by: sortBy,
        sort_order: sortOrder,
        ...itemApiParams(filters),
      });
      setCsvClaimedItemsDownloadReady(true);
      setClaimedItems(response.results);
    };
    fetchClaimedItems();
  }, [api, memberId, sortBy, sortOrder, filters]);

  // TODO: Verify that the user is an admin to use organizations.
  useEffect(() => {
    const fetchOrgs = async () => {
      const response = await api.getOrgs({page_size: 0});
      setOrgs(response);
    };
    fetchOrgs();
    const fetchIssues = async () => {
      const response = await api.getIssues({page_size: 0, order_by: 'name'});
      setIssues(response);
    };
    fetchIssues();
    const fetchOums = async () => {
      const response = await api.getUoms({page_size: 0});
      setUoms(
        response.results.filter(
          (uom) =>
            uom.is_beneficiary === false &&
            uom.kind !== 'information' &&
            uom.kind !== 'funding',
        ),
      );
    };
    fetchOums();
  }, [api]);

  const cachedLookupData = useMemo(
    () => ({
      organization: orgs,
      issue: issues,
      category: categories,
      uom: uoms,
    }),
    [orgs, issues, categories, uoms],
  );

  const formatNumber = useFormatNumber();
  const customColumns = queryStringParams.get(COLUMNS_QUERY_PARAM_NAME);

  const handleHeaderClick = (header) => {
    if (header.sortBy) {
      setSortOrder(
        sortOrder === 'desc' || sortBy !== header.sortBy ? 'asc' : 'desc',
      );
      setSortBy(header.sortBy);
      history.push({...history.location});
    }
  };

  const handleOnClose = () =>
    history.push({...history.location, pathname: match.url});

  const downloadExportAllCsv = async () => {
    const columns = getCsvColumns();
    const csvHeaders = getCsvHeaders(columns, t, currency.code);
    const csvItemsData = getItemsCsvData(
      items,
      columns,
      t,
      currency,
      localeNumber,
      moment,
    );
    const csvClaimedItemsData = getClaimedItemsCsvData(
      claimedItems,
      columns,
      t,
      currency,
      localeNumber,
      moment,
    );
    const csvContents = [csvHeaders]
      .concat(csvItemsData)
      .concat(csvClaimedItemsData);

    executeCsvDownload(csvContents, 'all_items.csv');
  };

  const downloadItemReportCsv = async () => {
    const hideMetClaimColumn = true;
    const columns = getCsvColumns(hideMetClaimColumn);
    const csvHeaders = getCsvHeaders(columns, t, currency.code);
    const csvData = getItemsCsvData(
      items,
      columns,
      t,
      currency,
      localeNumber,
      moment,
    );
    const csvContents = [csvHeaders].concat(csvData);

    executeCsvDownload(csvContents, 'item_reports.csv');
  };

  // Resets saved view when user navigates from another page in the site.
  if (
    window.location.search === '' &&
    window.localStorage.getItem('viewQueryString') !== ''
  ) {
    window.localStorage.setItem('viewQueryString', '');
    window.localStorage.setItem('viewId', '');
  }

  // TODO: Add translations
  return (
    <ReportProvider>
      <AlertLinkReport />
      <div className={styles.memberNameHeader}>
        {t('title')} {organization.name}
      </div>
      {savedViewToastText !== '' && (
        <SavedViewToast
          text={savedViewToastText}
          onClose={() => setSavedViewToastText('')}
        />
      )}
      <div className={styles.filterContainer}>
        <div className={styles.filterOptionsContainer}>
          {filters.map((filter) => {
            const name = filter.constructor.type;
            return (
              <Filter
                lookupData={getLookupDataByKey(name, cachedLookupData)}
                filter={filter}
                key={filter.key}
                selected={filter.key === selectedFilter}
                selectFilter={setSelectedFilter}
                onRemoveUrl={removeQueryParam(
                  url_without_page,
                  name,
                  filter.value,
                )}
              />
            );
          })}
          <AddFilterButton
            cachedLookupData={cachedLookupData}
            selected={selectedFilter === 'addFilter'}
            selectFilter={setSelectedFilter}
            url={url_without_page}
            isAdmin={showAdmin}
          />
        </div>
        <SavedViewButton setSavedViewToastText={setSavedViewToastText} />
      </div>
      <div className={styles.summaryReportContainer}>
        <SummaryReport
          filters={filters}
          filterByMember={memberId}
          formatNumber={formatNumber}
        />
      </div>
      <Responsive>
        <div max={breakpoints.lg} className={styles.itemReportsHeader}>
          <div className={styles.itemReportsText}>
            <div className={styles.itemReportsTitle}>{t('table_title')}</div>
            <div className={styles.itemReportsDescription}>
              {t('table_description')}
            </div>
          </div>
          <div className={styles.itemReportsControls}>
            <Export
              csvDownloadReady={
                csvItemsDownloadReady && csvClaimedItemsDownloadReady
              }
              downloadItemReportCsv={downloadItemReportCsv}
              downloadExportAllCsv={downloadExportAllCsv}
            />
            <CustomizeColumns value={customColumns} isAdmin={showAdmin} />
          </div>
        </div>
        <div max={breakpoints.lg} className={styles.itemReportsTableContainer}>
          <ItemReportsTable
            filters={filters}
            filterByMember={memberId}
            columnFilter={customColumns}
            isAdmin={showAdmin}
            handleHeaderClick={handleHeaderClick}
            sortBy={sortBy}
            sortOrder={sortOrder}
          />
        </div>
      </Responsive>
      <Visualizations filters={filters} memberId={memberId} />
      <Route path={ITEM_REPORTS_ACTIVITY_DETAIL}>
        <ActivityDetail filters={filters} onClose={handleOnClose} />
      </Route>
    </ReportProvider>
  );
};

export default ItemReportsPage;
