import React from 'react';
import {useField} from 'formik';
import {Form} from 'react-bootstrap';
import FeedbackInvalid from '../../components/Form/FeedbackInvalid';
import styles from './ItemName.module.scss';

const ItemName = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const {value} = meta;
  const {setValue} = helpers;

  const handleChange = (event) => {
    setValue([
      {
        value: event.target.value,
      },
    ]);
  };

  return (
    <React.Fragment>
      <Form.Control
        className={[styles.formControl]}
        isInvalid={!!meta.error && meta.touched}
        placeholder={props.placeholder}
        name={field.name}
        onChange={handleChange}
        value={value[0] ? value[0].value : ''}
      />
      <FeedbackInvalid meta={meta} />
    </React.Fragment>
  );
};

export default ItemName;
