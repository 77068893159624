import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, Route} from 'react-router-dom';
import {withFormik} from 'formik';

import styled from 'styled-components';
import {Form as BootstrapForm, Col, Card} from 'react-bootstrap';

import {passwordValidationScheme} from '../../utils/yupCustomFunctions';
import {mediaQueries} from '../../constants';
import {InputField} from '../../components/Fields';
import Button from '../../components/Button';
import ThankYouModal from '../../components/Modals/ThankYouModal';
import {observer} from 'mobx-react';
import {useApi} from '../../contexts/ApiContext';
import Title from '../../components/Title/Title';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${mediaQueries.lg} {
    height: 100vh;
  }
`;

const Form = styled(BootstrapForm)`
  display: flex;
  padding: 50px 40px 0 40px;
  flex-direction: column;
  justify-content: center;
  width: 954px;
  overflow: hidden;

  ${mediaQueries.lg} {
    padding: 15px;
  }

  .card {
    padding: 50px 120px;

    ${mediaQueries.lg} {
      padding: 15px;
    }
  }
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  ${mediaQueries.lg} {
    justify-content: center;
  }
`;

const FieldsContainer = styled.div`
  margin-top: auto;
`;

const Intructions = styled.p`
  text-align: center;
  margin: 15px 0 30px;
`;

const StyledTitle = styled(Title)`
  margin-top: 20px;
  text-align: center;
`;

const ResetPassword = (props) => {
  const {handleSubmit, isSubmitting, values} = props;
  const {t} = useTranslation('reset-password');

  const handleBackToLogin = () => {
    props.history.push('/login');
  };

  if (!values.token) {
    props.history.push('/login');
  }

  return (
    <Wrapper>
      <Form noValidate onSubmit={handleSubmit}>
        <FieldsContainer>
          <Card>
            <StyledTitle>{t('title')}</StyledTitle>
            <Intructions>{t('instructions')}</Intructions>
            <Form.Group as={Col} sm={12}>
              <InputField
                type="password"
                placeholder={t('place-holder.password')}
                label={t('labels.password')}
                name="password"
                isRequired
              />
            </Form.Group>
            <Form.Group as={Col} sm={12}>
              <InputField
                type="password"
                placeholder={t('place-holder.confirm-password')}
                label={t('labels.confirm-password')}
                name="confirmPassword"
                isRequired
              />
            </Form.Group>
            <FormFooter>
              <Button type="submit" disabled={isSubmitting}>
                {t('buttons.set-password')}
              </Button>
            </FormFooter>
          </Card>
        </FieldsContainer>
      </Form>
      <Route path={'/reset-password/finish'}>
        <ThankYouModal
          titleMessage={t('labels.success')}
          onPrimaryClick={handleBackToLogin}
          primaryButtonText={t('buttons.go-to-login')}
        >
          {t('message')}
        </ThankYouModal>
      </Route>
    </Wrapper>
  );
};

const EnhancedForm = withFormik({
  displayName: 'ResetPassword',
  mapPropsToValues: (props) => {
    const queryStringParams = new URLSearchParams(props.location.search);
    const token = queryStringParams.get('token');
    return {
      password: '',
      confirmPassword: '',
      token: token,
    };
  },
  validationSchema: passwordValidationScheme,
  handleSubmit: async (values, {props, setSubmitting, setErrors}) => {
    try {
      await props.api.setNewPassword({
        token: values.token,
        uid: props.match.params.uid,
        password: values.password,
        repeated_password: values.confirmPassword,
      });
      setSubmitting(false);
      props.history.push('/reset-password/finish');
    } catch (error) {
      if (error.status) {
        setErrors({
          confirmPassword: error.status,
        });
      }
    }
  },
})(ResetPassword);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const api = useApi();
  const history = useHistory();
  return <EnhancedForm history={history} api={api} {...props} />;
};

export default observer(FormWrapper);
