import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useRouteMatch} from 'react-router-dom';
import styled from 'styled-components';
import {Row, Col, Tab} from 'react-bootstrap';
import moment from 'moment';

import {useStores} from '../../contexts/StoresContext';
import {usePageStore} from '../../contexts/PageContext';
import {mediaQueries, breakpoints} from '../../constants';
import {useApi} from '../../contexts/ApiContext';
import {
  useFormatMoney,
  useLocaleNumber,
  useFormatNumber,
} from '../../hooks/number';

import Tabs from '../../components/Tabs';
import Responsive from '../../components/Responsive';
import CustomDatePicker from '../../components/Form/CustomDatePicker';
import ActivityReport from './Activity/ActivityReport';
import ActivityDetail from './Activity/ActivityDetail';
import TotalProgressReport from './TotalProgressReport';
import TrendReports from './TrendReports';
import ReportsSearchBar from './ReportsSearchBar';
import {Route} from '../../components/Layout';
import {REPORTS_ACTIVITY_DETAIL} from '../../urls';
import SummaryReport from './SummaryReport';
import {capitalize} from '../../utils/string';
import CurrencySelect from '../../components/Form/CurrencySelect';
import {DateFilterImpl} from '../../components/ItemReports/filterUtils';

const StyledBody = styled.div`
  margin-top: 40px;
`;

const StyledTabs = styled(Tabs)`
  padding-bottom: 0;

  &.nav {
    margin-bottom: 30px;
  }

  &.nav-tabs {
    .nav-item.nav-link {
      padding-top: 0;
    }
  }
`;

const StyledRow = styled(Row)`
  margin: 40px 0;
`;

const DatePickerContainer = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 0.5rem;

  ${mediaQueries.lg} {
    margin-top: 15px;
    font-size: 20px;
  }

  ${mediaQueries.md} {
    flex-direction: column;
  }
`;

const StyledCol = styled(Col)`
  align-self: flex-end;
`;

const InnerDateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ReportsPage = () => {
  const stores = useStores();
  const formatNumber = useFormatNumber();
  const formatMoney = useFormatMoney();
  const localeNumber = useLocaleNumber();
  const {t} = useTranslation('reports');
  const [, pageActions] = usePageStore();
  const history = useHistory();
  const match = useRouteMatch();
  const [searchedMember, setSearchedMember] = useState(null);
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(1, 'year').toDate(),
  );
  const [toDate, setToDate] = useState(
    moment(new Date()).endOf('day').toDate(),
  );
  const {organization, user, currency} = stores.app;
  const [reportCurrency, setReportCurrency] = useState(currency);
  const showAdmin = user.isAdmin;

  const api = useApi();
  const boundGetTotal = api.getOrgTotalValueItemsReports.bind(api);
  const boundGetTotalCSV = api.getOrgTotalValueItemsReportsCSV.bind(api);
  const boundGetTotalNumber = api.getOrgTotalNumberItemsReports.bind(api);
  const boundGetTotalNumberCSV = api.getOrgTotalNumberItemsReportsCSV.bind(api);
  const boundGetOrgTrendNumberReports = api.getOrgTrendNumberReports.bind(api);
  const boundGetOrgTrendValueReports = api.getOrgTrendValueReports.bind(api);
  const boundGetOrgSummaryReports = api.getOrgSummaryReports.bind(api);

  const [memberId, setMemberId] = useState(
    showAdmin ? undefined : organization.id,
  );

  useEffect(() => {
    const id = showAdmin
      ? searchedMember
        ? searchedMember.value
        : undefined
      : organization.id;

    setMemberId(id);
  }, [searchedMember, organization.id, showAdmin]);

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'reports'});
  }, [pageActions]);

  const handleOnChange = (value) => {
    setSearchedMember(value);
    history.push(`${match.url}`);
  };

  const handleFromOnChange = (date) =>
    date ? setFromDate(date) : setFromDate('');
  const handleToOnChange = (date) => (date ? setToDate(date) : setToDate(''));
  const handleOnClose = () =>
    history.push({...history.location, pathname: match.url});

  return (
    <React.Fragment>
      <Row>
        <Col sm={showAdmin ? 9 : 12}></Col>
        {showAdmin && (
          <StyledCol sm={3}>
            <CurrencySelect
              value={reportCurrency.id}
              onChange={setReportCurrency}
            />
          </StyledCol>
        )}
      </Row>
      <Row>
        {showAdmin && (
          <StyledCol sm={6}>
            <ReportsSearchBar onChange={handleOnChange} />
          </StyledCol>
        )}
        <Col sm={showAdmin ? 6 : 12}>
          <DatePickerContainer>
            <InnerDateWrapper>
              {t('date.from')}
              <CustomDatePicker
                selected={fromDate}
                onChange={handleFromOnChange}
                size="sm"
                maxDate={new Date()}
              />
            </InnerDateWrapper>
            <InnerDateWrapper>
              {t('date.to')}
              <CustomDatePicker
                selected={toDate}
                onChange={handleToOnChange}
                size="sm"
                maxDate={new Date()}
              />
            </InnerDateWrapper>
          </DatePickerContainer>
        </Col>
      </Row>
      <StyledBody>
        <StyledRow>
          <Col sm={12}>
            <SummaryReport
              filters={[DateFilterImpl.fromDateRange(fromDate, toDate)]}
              currency={reportCurrency}
              getData={boundGetOrgSummaryReports}
              filterByMember={memberId}
              formatNumber={formatNumber}
            />
            <StyledTabs mountOnEnter unmountOnExit>
              <Tab eventKey="transaction" title={t('tabs.transaction-reports')}>
                <Responsive>
                  <ActivityReport
                    max={breakpoints.lg}
                    from={fromDate}
                    to={toDate}
                    filterByMember={memberId}
                    currency={reportCurrency}
                  />
                </Responsive>
                <TotalProgressReport
                  from={fromDate}
                  to={toDate}
                  title={t('titles.total-number')}
                  getData={boundGetTotalNumber}
                  exportData={boundGetTotalNumberCSV}
                  filterByMember={memberId}
                  filename="total_number_items_report"
                  formatNumber={localeNumber}
                  showProgress={true}
                />
                <TrendReports
                  getData={boundGetOrgTrendNumberReports}
                  formatNumber={localeNumber}
                  from={fromDate}
                  to={toDate}
                  filterByMember={memberId}
                />
              </Tab>
              <Tab
                eventKey="total-value"
                title={t('tabs.value-reports', {
                  currency: reportCurrency.name.toUpperCase(),
                })}
              >
                <TotalProgressReport
                  from={fromDate}
                  to={toDate}
                  currency={reportCurrency}
                  title={t('titles.total-value', {
                    currency: capitalize(reportCurrency.name),
                  })}
                  getData={boundGetTotal}
                  exportData={boundGetTotalCSV}
                  filterByMember={memberId}
                  filename="total_value_items_report"
                  formatNumber={formatMoney}
                  showProgress={false}
                />
                <TrendReports
                  getData={boundGetOrgTrendValueReports}
                  formatNumber={formatMoney}
                  from={fromDate}
                  to={toDate}
                  currency={reportCurrency}
                  filterByMember={memberId}
                />
              </Tab>
            </StyledTabs>
          </Col>
        </StyledRow>
      </StyledBody>
      <Route path={REPORTS_ACTIVITY_DETAIL}>
        <ActivityDetail
          filters={[DateFilterImpl.fromDateRange(fromDate, toDate)]}
          onClose={handleOnClose}
          currency={reportCurrency}
        />
      </Route>
    </React.Fragment>
  );
};

export default ReportsPage;
