import React from 'react';
import styled from 'styled-components';
import {useTranslation, Trans} from 'react-i18next';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons';
import {mediaQueries} from '../../constants';
import Link from '../Link';

const ThankYouWrapper = styled.div`
  text-align: center;
`;

const CheckIcon = styled(FontAwesomeIcon)`
  color: #26a69a;
  font-size: 60px;
  margin: 15px 0 10px;

  ${mediaQueries.lg} {
    font-size: 28px;
    margin: 70px 0 20px;
  }
`;

const ThankYouTitle = styled.h2`
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;

  ${mediaQueries.lg} {
    font-weight: 500;
    font-size: 20px;
  }
`;

const ThankYouText = styled.p`
  font-size: 0.8em;
  line-height: 1.8em;
  padding: 0 20px;
  margin-bottom: 20px;

  ${mediaQueries.lg} {
    margin-bottom: 15px;
  }
`;

const ThankYou = (props) => {
  const {side} = props;
  const {t} = useTranslation(['search', 'thank-you']);

  return (
    <ThankYouWrapper>
      <CheckIcon icon={faCheckCircle} />
      <ThankYouTitle>{t(`search:cards.messages.done.${side}`)}</ThankYouTitle>
      <ThankYouText>
        <Trans i18nKey={`thank-you:labels.offer.suggested-${side}-submessage`}>
          text <br /> text <Link to="/manage">text</Link> text
        </Trans>
      </ThankYouText>
    </ThankYouWrapper>
  );
};

export default ThankYou;
