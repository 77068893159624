import React, {useState, useRef, useEffect, useContext} from 'react';
import {
  AsyncTypeahead,
  Menu,
  MenuItem,
  Highlighter,
} from 'react-bootstrap-typeahead';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form} from 'react-bootstrap';
import FormContext from '../FormContext';
import styles from './Typeahead.module.scss';

const Typeahead = (props) => {
  const [inputValue, setInputValue] = useState('');
  const {t} = useTranslation('common');
  const typeaheadRef = useRef(null);
  const {setFieldRef} = useContext(FormContext);
  const {setRef, allowClean, customMenu, onEnterConfirm} = props;

  useEffect(() => {
    if (setRef) {
      setRef(typeaheadRef);
    }
  }, [setRef, typeaheadRef]);

  useEffect(() => {
    const instance = {current: typeaheadRef.current};
    setFieldRef(props.name, instance);
  }, [props.name, setFieldRef]);

  const searchIcon = props.isLoading ? null : (
    <FontAwesomeIcon
      icon={'search'}
      color="#ced4da"
      className={styles.searchIcon}
    />
  );
  const renderEmptyToken = () => {};

  const handleInputChange = (value) => {
    setInputValue(value);

    if (props.onInputChange) {
      props.onInputChange(value);
    }
  };

  let handleKeyDown = undefined;

  if (props.selectEnterConfirm) {
    handleKeyDown = (event) => {
      const instance = typeaheadRef.current;

      if (instance.state.activeIndex !== -1) return;

      if (event.key === 'Enter') {
        const newItem = props.options.find((item) => props.filterBy(item));
        if (newItem) {
          props.onChange([newItem]);

          instance.clear();
          setInputValue('');
        }
      }

      if (props.onKeyDown) props.onKeyDown(event);
    };
  }

  if (
    props.selectPartialMatchOnEnter &&
    (props.allowNew === true ||
      (typeof props.allowNew === 'function' && props.allowNew()))
  ) {
    handleKeyDown = (event) => {
      // Hack accessing child's state to check if option of dropdown is selected, and ignore
      // custom enter function
      const instance = typeaheadRef.current;

      if (instance.state.activeIndex !== -1) return;

      if (event.key === 'Enter') {
        if (inputValue) {
          const customValue = {
            customOption: true,
          };
          if (props.labelKey) {
            customValue[props.labelKey] = inputValue;
          } else {
            customValue.value = inputValue;
            customValue.label = inputValue;
          }
          props.onChange([
            ...(props.multiple ? props.selected : []),
            customValue,
          ]);

          if (onEnterConfirm) {
            onEnterConfirm();
          }

          instance.clear();
          setInputValue('');
        }
      }
    };
  }

  const handleCleanInput = () => {
    props.onClean();
    setInputValue('');
  };

  const renderTypeAheadMenu = (results, menuProps) => {
    if (results.some((element) => element.customOption === true)) {
      results.pop();
    }

    const newResults = [
      {
        label: `${t('typeahead-prefix')} ${inputValue}`,
        value: inputValue,
        customOption: true,
      },
      ...results,
    ];

    return (
      <Menu {...menuProps}>
        {newResults.map((result, index) => (
          <MenuItem
            option={result}
            position={index}
            key={`search-item-${index}`}>
            <Highlighter search={inputValue}>{result.label}</Highlighter>
          </MenuItem>
        ))}
      </Menu>
    );
  };

  return (
    <div className={`${styles.container} ${props.className}`}>
      <AsyncTypeahead
        {...props}
        renderMenu={customMenu ? renderTypeAheadMenu : undefined}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        ref={typeaheadRef}
        renderToken={
          props.useCustomPills ? renderEmptyToken : props.renderToken
        }
        emptyLabel={t('typeahead-empty-label')}
        searchText={t('typeahead-search-text')}
        promptText={t('typeahead-prompt-text')}
        className={styles.customTypeahead}
      />
      {allowClean && inputValue ? (
        <FontAwesomeIcon
          icon="times"
          color="#ced4da"
          onClick={handleCleanInput}
          className={styles.cleanIcon}
        />
      ) : (
        !props.isInvalid && searchIcon
      )}
      {props.isInvalid && (
        <Form.Control.Feedback type="invalid" className={styles.customFeedback}>
          <FontAwesomeIcon
            icon="exclamation-triangle"
            className={styles.errorIcon}
          />{' '}
          {props.error}
        </Form.Control.Feedback>
      )}
    </div>
  );
};

export default Typeahead;
