import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useApi} from '../../../contexts/ApiContext';
import FileDropZone from '../../FileDropZone';
import ResponsiveImg from '../../ResponsiveImg';
import Thumbnail from '../../Thumbnail';
import Center from '../../Center';
import ErrorMessage from '../ErrorMessage';
import styles from './ImageUpload.module.scss';

const ImageUpload = ({
  value,
  onChange,
  multiple,
  error,
  noRequirementImage,
}) => {
  const {t} = useTranslation('forms');
  const api = useApi();
  const [isUploading, setIsUploading] = useState(false);
  const [imagesToUpload, setImagesToUpload] = useState(0);
  const initialImages = multiple ? value : value ? [value] : [];
  const [uploadedImages, setUploadedImages] = useState(initialImages);
  const [inputValue, setInputValue] = useState('');
  const [isTooBig, setIsTooBig] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (imagesToUpload !== 0 && uploadedImages.length === imagesToUpload) {
      setIsUploading(false);
      setImagesToUpload(0);
      setInputValue('');

      if (multiple) {
        onChange(uploadedImages);
      } else {
        onChange(uploadedImages[0]);
      }
    }
  }, [onChange, imagesToUpload, uploadedImages, multiple]);

  const MAX_IMAGE_SIZE = 2 * 1024 * 1024;

  const uploadImage = async (image, imageData) => {
    if (image.size <= MAX_IMAGE_SIZE || noRequirementImage) {
      setShowError(false);
      setIsTooBig(false);
      const response = await api.uploadImage(imageData);
      await fetch(response.signed_url, {
        method: 'PUT',
        headers: {
          'x-goog-acl': response.acl,
          'Content-type': imageData.content_type,
        },
        body: image,
      });
      response.size = image.size;
      setUploadedImages((currentValue) => [...currentValue, response]);
    } else {
      setShowError(true);
      setIsTooBig(false);
      imageData.size = image.size;
      setUploadedImages((currentValue) => [...currentValue, imageData]);
      handleDeleteImage(0);
    }
  };

  const prepareImage = (image) => {
    const reader = new FileReader();
    const img = new Image();

    reader.onload = () => (img.src = reader.result);
    img.onload = async () => {
      const imageData = {
        name: image.name,
        content_type: image.type,
        width: img.naturalWidth,
        height: img.naturalHeight,
        alt: image.name,
      };
      uploadImage(image, imageData);
    };

    reader.readAsDataURL(image);
  };

  const handleImageUpload = (files) => {
    files.forEach(prepareImage);
    setIsUploading(true);
    setImagesToUpload(files.length);
  };
  const handleDeleteImage = (index) => {
    if (!multiple) {
      setUploadedImages([]);
      onChange(null);
    } else {
      let updatedImageList;

      if (index === 0 && uploadedImages.length === 1) {
        updatedImageList = [];
      } else {
        updatedImageList = [
          ...uploadedImages.slice(0, index),
          ...uploadedImages.slice(index + 1, uploadedImages.length),
        ];
      }

      setUploadedImages(updatedImageList);
      onChange(updatedImageList);
    }
  };

  const renderMultipleImages = () => {
    const content = value.map((image, index) => {
      const onDelete = () => handleDeleteImage(index);

      return (
        <div className={styles.imageContainer} key={index}>
          <Thumbnail>
            <ResponsiveImg src={image.url} alt="" />
          </Thumbnail>
          <FontAwesomeIcon
            className={styles.removeImageIcon}
            icon="times-circle"
            onClick={onDelete}
          />
        </div>
      );
    });

    return <div>{content}</div>;
  };
  const renderSingleImage = () => {
    if (value) {
      const onDelete = () => handleDeleteImage(0);

      return (
        <Center column>
          {!isTooBig && (
            <React.Fragment>
              <div className={styles.avatarContainer}>
                <ResponsiveImg src={value.url ? value.url : value} alt="" />
              </div>
              <span className={styles.customText} onClick={onDelete}>
                {t('image.delete')}
              </span>
            </React.Fragment>
          )}
        </Center>
      );
    }
  };
  const imageSpan = () => {
    return <span className={styles.customText}>{t('image.drop')}</span>;
  };

  return (
    <React.Fragment>
      <FileDropZone
        isFileUploaded={uploadedImages.length > 0}
        renderFileLoaded={multiple ? renderMultipleImages : renderSingleImage}
        renderPlaceholder={imageSpan}
        onFilesAdded={handleImageUpload}
        isUploading={isUploading}
        multiple={multiple}
        value={inputValue}
        accept="image/*"
      />
      {error && <ErrorMessage forceDisplay>{error}</ErrorMessage>}
      {showError && (
        <ErrorMessage forceDisplay>{t('errors.logo')}</ErrorMessage>
      )}
    </React.Fragment>
  );
};

export default ImageUpload;
