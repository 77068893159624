import React from 'react';
import {Form} from 'react-bootstrap';
import styles from './Label.module.scss';

const Label = (props) => {
  const requiredLabel = props.isRequired ? (
    <span className={styles.span}>*</span>
  ) : null;
  return (
    <Form.Label className={styles.label}>
      {props.children} {requiredLabel}
    </Form.Label>
  );
};

export default Label;
