import {useEffect} from 'react';
import {useRefinementList} from 'react-instantsearch';

export const ModelRefinements = () => {
  // That component exists to filter always by model item
  const {refine} = useRefinementList({
    attribute: 'model',
    defaultRefinement: ['needslist_item.Item'],
  });

  useEffect(() => {
    refine('needslist_item.Item');
  }, [refine]);

  return <></>;
};
