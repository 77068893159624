import React from 'react';
import Claim from '../Claim/Claim';
import styles from './ClaimList.module.scss';

const ClaimList = (props) => {
  const {claims, actionText, onActionClick, side} = props;
  const renderClaims = (claim, index) => {
    const handleActionClick = () => {
      onActionClick(claim);
    };

    return (
      <div key={index} className={styles.row}>
        <Claim
          side={side}
          claim={claim}
          actionText={actionText}
          onActionClick={handleActionClick}
        />
      </div>
    );
  };

  return <div className={props.className}>{claims.map(renderClaims)}</div>;
};

export default ClaimList;
