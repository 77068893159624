import {observer} from 'mobx-react';
import React, {useEffect, useRef} from 'react';
import {useStores} from './contexts/StoresContext';
import {useApi} from './contexts/ApiContext';
import ReactDOM from 'react-dom';
import {StoresProvider} from './contexts/StoresContext';
import {ApiProvider} from './contexts/ApiContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useJsApiLoader} from '@react-google-maps/api';

// Widgets
import WidgetAddress from './widgets/AddressWidget';
import MediaImageWidget from './widgets/MediaImageWidget';
import BundleWidget from './widgets/BundleWidget';
import MediaVideoWidget from './widgets/MediaVideoWidget';
import CategoryWidget from './widgets/CategoryWidget';
import SectorWidget from './widgets/SectorWidget';
import TypeaheadCategoryWidget from './widgets/TypeaheadCategoryWidget';
import UomWidget from './widgets/UomWidget';
import KindWidget from './widgets/KindWidget';
import MainCategoryWidget from './widgets/MainCategoryWidget';
import IsUrgentWidget from './widgets/IsUrgentWidget';
import NavigationWidget from './widgets/NavigationWidget';

/**
 * Back Office
 */
const WIDGET_MAPPING = {
  'widget-address': WidgetAddress,
  'widget-media-image': MediaImageWidget,
  'widget-media-video': MediaVideoWidget,
  'widget-bundle': BundleWidget,
  'widget-category': CategoryWidget,
  'widget-sector': SectorWidget,
  'widget-typeahead-category': TypeaheadCategoryWidget,
  'widget-main-category': MainCategoryWidget,
  'widget-uom': UomWidget,
  'widget-kind': KindWidget,
  'widget-is-urgent': IsUrgentWidget,
  'widget-navigation': NavigationWidget,
};

const Portal = ({el, component, attrs}) => {
  const ref = useRef(document.createElement('div'));

  useEffect(() => {
    const current = ref.current;
    el.replaceWith(current);
    return () => {
      current.replaceWith(el);
    };
  }, [el]);

  if (!ref.current) {
    return null;
  }

  const Component = component;

  return ReactDOM.createPortal(<Component {...attrs} />, ref.current);
};

const WIDGETS = Object.keys(WIDGET_MAPPING).reduce((accum, type) => {
  accum[type] = document.querySelectorAll(`[react="${type}"]`);
  return accum;
}, {});

const App = observer((props) => {
  const stores = useStores();
  const api = useApi();

  const {isLoaded: isGoogleMapsLoaded} = useJsApiLoader({
    libraries: window.GOOGLE_MAP_LIBRARIES,
    googleMapsApiKey: window.GOOGLE_API_KEY,
  });

  const items = Object.keys(WIDGETS).reduce((accum, type) => {
    const widgets = Array.from(WIDGETS[type]).map((widget) => {
      return {
        type: type,
        widget: widget,
      };
    });
    return accum.concat(widgets);
  }, []);

  const renderContent = () => {
    return items.map((item, index) => {
      const Widget = WIDGET_MAPPING[item.type];

      if (item.type === 'widget-address' && !isGoogleMapsLoaded) {
        return null;
      }

      const attrs = {};
      item.widget.getAttributeNames().forEach(function (name) {
        attrs[name] = item.widget.getAttribute(name);
      });

      patchHtml(item.widget, attrs);
      patchAttributes(attrs);
      patchData(attrs, stores);

      return (
        <Portal attrs={attrs} key={index} component={Widget} el={item.widget} />
      );
    });
  };

  useEffect(() => {
    if (!stores.app.loaded) {
      return;
    }

    const fetchIssueCategories = async () => {
      const response = await api.getIssueCategories();
      stores.backoffice.setCategories(response.results);
    };

    const prefetchIssueCategories =
      ['widget-typeahead-category', 'widget-main-category']
        .map((name) => WIDGETS[name])
        .filter((item) => item).length > 0;

    if (prefetchIssueCategories) {
      fetchIssueCategories();
    }
  }, [stores.app, stores.backoffice, api]);

  return (
    <React.Fragment>{stores.app.loaded && renderContent()}</React.Fragment>
  );
});

export default class BackOffice {
  static init() {
    document.addEventListener('DOMContentLoaded', function (argument) {
      const root = document.getElementById('root-back-office');

      if (!root) {
        return;
      }

      ReactDOM.render(
        <StoresProvider>
          <ApiProvider>
            <App />
          </ApiProvider>
        </StoresProvider>,
        root,
      );

      // Show main (need it to wait for the css)
      document.getElementsByTagName('body')[0].style.display = 'block';

      Array.from(document.getElementsByClassName('fa')).forEach((el) => {
        el.classList.remove('fa');
        const icon = el.getAttribute('class').replace('fa-', '');
        ReactDOM.render(<FontAwesomeIcon icon={icon} />, el);
      });
    });
  }
}

function patchData(attrs, stores) {
  switch (attrs.react) {
    case 'widget-navigation': {
      attrs.data = JSON.parse(attrs.data);
      break;
    }
    case 'widget-bundle': {
      attrs.store = stores.backoffice;
      attrs.uoms = JSON.parse(attrs.uoms);
      break;
    }
    case 'widget-category': {
      attrs.store = stores.backoffice;
      attrs.choices = JSON.parse(attrs.choices);
      break;
    }
    case 'widget-uom': {
      attrs.store = stores.backoffice;
      break;
    }
    case 'widget-kind': {
      attrs.store = stores.backoffice;
      attrs.choices = JSON.parse(attrs.choices);
      break;
    }
    default: {
    }
  }
}

function patchAttributes(attrs) {
  attrs.className = attrs.class;
  attrs.readonly = typeof attrs.readonly != 'undefined';
  attrs.disabled = typeof attrs.disabled != 'undefined';
  attrs.required = typeof attrs.required != 'undefined';
  attrs.multi = typeof attrs.multi != 'undefined';
  delete attrs.class;
}

function patchHtml(widget, attrs) {
  /**
   * Prevent inner forms to be submited using "Enter"
   */
  Array.from(document.getElementsByTagName('form'))
    .filter((form) => !form.hasAttribute('enable-enter-submit'))
    .forEach((form) => {
      form.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      });
    });

  /**
   * removing label from django for inputs, then it's added in the react widget
   */
  if (widget.classList.contains('form-control')) {
    const label = widget.parentElement.getElementsByTagName('label')[0];
    if (label) {
      attrs.label = label.textContent;
      label.remove();
    }

    // also removing class and then adding it
    widget.classList.remove('form-control');

    // will copy the error message for the validation
    if (widget.nextSibling && widget.nextSibling.nextSibling) {
      if (
        widget.nextSibling.nextSibling.classList.contains('invalid-feedback')
      ) {
        attrs.errors = widget.nextSibling.nextSibling;
        widget.nextSibling.nextSibling.remove();
      }
    }

    /**
     * now removing label for checkboxes and then adding them in their respective components
     * validation errors not needed for checkboxes for now
     */
  } else if (widget.classList.contains('form-check-input')) {
    const label = widget.parentElement.getElementsByTagName('label')[0];
    if (label) {
      attrs.label = label.textContent;
      label.remove();
    }

    widget.parentElement.classList.remove('form-check');
    widget.classList.remove('form-check-input');

    // .form-group
    const parent = widget.parentElement.parentNode;

    // detach
    const el = widget.parentElement.removeChild(widget);

    // remove empty
    parent.getElementsByTagName('div')[0].remove();

    // re-attach
    parent.appendChild(el);
  }
}
