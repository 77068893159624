import React, {useEffect, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useStepper} from '../../contexts/StepperContext';

export const StepperSteps = ({children}) => {
  const {steps, setSteps} = useStepper();
  const history = useHistory();

  const stepToRender = useMemo(
    () =>
      React.Children.map(children, (child) => {
        if (steps.length) {
          return child.props.url === history.location.pathname ? child : null;
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [steps.length, history.location.pathname],
  );
  useEffect(() => {
    const stepperSteps = React.Children.map(children, (step) => step.props);
    setSteps(stepperSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSteps]);
  return <div>{children && stepToRender}</div>;
};

export const StepperStep = ({children}) => {
  return <>{children}</>;
};
