import React, {useContext, useEffect, useRef} from 'react';
import {Form} from 'react-bootstrap';
import ErrorMessage from '../../Helper/ErrorMessage';
import FormContext from '../../../Form/FormContext';
import styles from './TextFieldControl.module.scss';

const TextFieldControl = (props) => {
  const {
    inlineLabel,
    setRef,
    inlineLabelPosition,
    without_special_characters,
    ...rest
  } = props;
  const inputRef = useRef(null);
  const {setFieldRef} = useContext(FormContext);

  useEffect(() => {
    setFieldRef(props.name, inputRef);
  }, [props.name, setFieldRef]);

  return (
    <Form.Group className={styles.textFieldControl}>
      <Form.Control {...rest} ref={inputRef} />
      <ErrorMessage>{props.error}</ErrorMessage>
    </Form.Group>
  );
};

export default TextFieldControl;
