import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import {mediaQueries} from '../../constants';
import Step from '../AddItems/Step';
import {UserDetailsForm, UserDetailsFields} from '../../components/UserDetails';
import {usePageStore} from '../../contexts/PageContext';
import {
  ONBOARDING_USER_CREATION_FINISH,
  ONBOARDING_ORGANIZATION_DETAILS,
} from '../../urls';
import FormFooter from './FormFooter';

const StyledStep = styled(Step)`
  padding-left: 120px;
  padding-right: 120px;
  margin-top: 50px;
  ${mediaQueries.lg} {
    margin-top: 0;
  }
`;

const UserDetails = () => {
  const [, pageActions] = usePageStore();
  const {t} = useTranslation('create-account');
  const stores = useStores();
  const history = useHistory();

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'user-details'});
  }, [pageActions]);

  const handleSubmit = () => {
    if (stores.app.user.organization_id) {
      history.push(ONBOARDING_USER_CREATION_FINISH);
    } else {
      history.push(ONBOARDING_ORGANIZATION_DETAILS);
    }
  };

  return (
    <UserDetailsForm onSubmit={handleSubmit} user={stores.app.user}>
      <StyledStep
        title={
          stores.app.user.organization_id
            ? t('steps.org-user')
            : t('steps.first')
        }>
        <UserDetailsFields />
      </StyledStep>
      <FormFooter />
    </UserDetailsForm>
  );
};

export default observer(UserDetails);
