import React, {useCallback} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {Tab} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

import {Route} from '../../../components/Layout';
import Tabs from '../../../components/Tabs';
import FiltersProvider from '../../../components/FiltersProvider/FiltersProvider';
import MyItems from './MyItems';
import ClaimedItems from './ClaimedItems';
import Panel from '../../../components/Panel';
import ItemEditForm from '../../../components/ItemEditForm';
import ItemViewDetails from '../../../components/ItemViewDetails';
import ItemConfirmForm from '../../../components/ItemConfirmForm';
import ItemQuantityForm from '../../../components/ItemQuantityForm/ItemQuantityForm';
import {usePaginator} from '../../../hooks/paginator';
import {
  ITEM_EDIT,
  ITEM_EDIT_QUANTITY,
  ITEM_DETAIL,
  ITEM_CONFIRM,
  MANAGE_PAGE,
  CLAIMED_ITEM_CONFIRM,
} from '../../../urls';
import {usePageStore} from '../../../contexts/PageContext';
import {usePanelStore} from '../../../contexts/PanelContext';
import styles from './Content.module.scss';

const Content = () => {
  const {t} = useTranslation('manage');
  const history = useHistory();
  const match = useRouteMatch();
  const {changePage, currentPage} = usePaginator();
  const [, pageActions] = usePageStore();
  const [, panelActions] = usePanelStore();

  const handleClose = useCallback(() => {
    changePage(match.url, currentPage);
    pageActions.setBreadcrumbs({key: 'manage'});
  }, [changePage, currentPage, match.url, pageActions]);

  const handleEditItemSave = (item) => {
    history.push(`${MANAGE_PAGE}?page=${currentPage}`, {
      itemId: item.id,
      refresh: true,
    });
    pageActions.setBreadcrumbs({key: 'manage'});
    panelActions.setPanelMount(false);
  };

  const handleTabSelected = () => changePage(match.url, 1);

  return (
    <React.Fragment>
      <Tabs mountOnEnter unmountOnExit onSelect={handleTabSelected}>
        <Tab eventKey="myNeedslist" title={t('needs.tab')}>
          <div className={styles.container}>
            <FiltersProvider>
              <MyItems side="need" />
            </FiltersProvider>
            <ClaimedItems side="need" />
          </div>
        </Tab>
        <Tab eventKey="myOffers" title={t('offers.tab')}>
          <div className={styles.container}>
            <FiltersProvider>
              <MyItems side="offer" />
            </FiltersProvider>
            <ClaimedItems side="offer" />
          </div>
        </Tab>
      </Tabs>
      <Route
        path={[
          ITEM_EDIT,
          ITEM_EDIT_QUANTITY,
          ITEM_DETAIL,
          ITEM_CONFIRM,
          CLAIMED_ITEM_CONFIRM,
        ]}
        isPublic>
        <Panel onClose={handleClose}>
          <Route
            path={ITEM_EDIT}
            render={() => <ItemEditForm onSave={handleEditItemSave} />}
            isPublic
          />
          <Route
            path={ITEM_EDIT_QUANTITY}
            render={() => <ItemQuantityForm onSave={handleEditItemSave} />}
            isPublic
          />
          <Route
            path={ITEM_DETAIL}
            render={() => <ItemViewDetails />}
            isPublic
          />
          <Route path={ITEM_CONFIRM} render={() => <ItemConfirmForm />} />
          <Route
            path={CLAIMED_ITEM_CONFIRM}
            render={() => <ItemConfirmForm />}
          />
        </Panel>
      </Route>
    </React.Fragment>
  );
};

export default Content;
