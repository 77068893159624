import React from 'react';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {Col} from 'react-bootstrap';

import {useErrorFocus, useTranslatedErrors} from '../../hooks/formik';

import Input from '../Form/Input';
import Label from '../Form/Label';
import TextArea from '../Form/TextArea';
import Form from '../ItemForm/Form';
import Footer from '../ItemForm/Footer';
import Button from '../Button';
import {useStores} from '../../contexts/StoresContext';

const OfferInformationClaimForm = (props) => {
  const {values, errors, touched, handleChange, handleSubmit, isSubmitting} =
    props;

  const {t} = useTranslation('forms');
  const translatedErrors = useTranslatedErrors(errors);
  const focusOnErrorFields = ['quantity', 'phoneNumber'];
  const fieldRefs = {
    quantity: null,
    phoneNumber: null,
  };

  useErrorFocus(isSubmitting, errors, focusOnErrorFields, fieldRefs);
  const setRef = (fieldName) => (ref) => (fieldRefs[fieldName] = ref);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('labels.information.offer.quantity')}</Label>
          <Input
            name="quantity"
            onChange={handleChange}
            value={values.quantity}
            isInvalid={!!errors.quantity && touched.quantity}
            error={translatedErrors.quantity}
            setRef={setRef('quantity')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('labels.information.offer.phone-number')}</Label>
          <Input
            name="phoneNumber"
            onChange={handleChange}
            value={values.phoneNumber}
            isInvalid={!!errors.phoneNumber && touched.phoneNumber}
            error={translatedErrors.phoneNumber}
            setRef={setRef('phoneNumber')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label>{t('labels.item-description')}</Label>
          <TextArea
            rows="6"
            name="additionalInformation"
            placeholder={t(
              'labels.discount.offer.item-description-claim-placeholder',
            )}
            onChange={handleChange}
            value={values.additionalInformation}
            isInvalid={
              !!errors.additionalInformation && touched.additionalInformation
            }
            error={translatedErrors.additionalInformation}
          />
        </Form.Group>
      </Form.Row>
      <Footer>
        <Button type="submit" variant="primary" className="text-uppercase">
          {t('buttons.offer.claim-offer')}
        </Button>
      </Footer>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'OfferInformationClaimForm',
  mapPropsToValues: (props) => {
    const {item, stores} = props;
    const {user} = stores.app;

    return {
      quantity: '',
      phoneNumber: user.phone_number || '',
      additionalInformation: '',
      item: item.id,
    };
  },
  validationSchema: yup.object().shape({
    quantity: yup.number().required('Required'),
    phoneNumber: yup.string().required('Required'),
    additionalInformation: yup.string(),
  }),
  handleSubmit: (values, {props}) => {
    props.onSubmit(values);
  },
})(OfferInformationClaimForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  return <EnhancedForm stores={stores} {...props} />;
};

export default observer(FormWrapper);
