import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import {Col, Row} from 'react-bootstrap';

import ItemDeliveryInformation from '../ItemDeliveryInformation/ItemDeliveryInformation';
import ItemDataTitle from '../ItemForm/ItemDataTitle';
import ItemDataValue from '../ItemForm/ItemDataValue';

import {getCreatedAt} from './utils';
import UndisclosedField from './UndisclosedField';

const NeedSupplyInformation = (props) => {
  const {item} = props;
  const {t} = useTranslation('forms');
  const noLabel = t('labels.no');
  const yesLabel = t('labels.yes');

  return (
    <React.Fragment>
      <Row>
        <Col sm={6}>
          <ItemDataTitle>{t('labels.item-created-at')}</ItemDataTitle>
          <ItemDataValue>{getCreatedAt(item)}</ItemDataValue>
        </Col>
        {item.brand && (
          <Col sm={6}>
            <ItemDataTitle>
              {t('labels.supply.need.item-preferred-brand')}
            </ItemDataTitle>
            <ItemDataValue>{item.brand}</ItemDataValue>
          </Col>
        )}
      </Row>
      {item.size && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.supply.item-size')}</ItemDataTitle>
            <ItemDataValue>{item.size}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.isPallet && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.supply.need.item-pallet')}</ItemDataTitle>
            <ItemDataValue>{item.isPallet ? yesLabel : noLabel}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.wareHouseDelivery && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>
              {t('labels.supply.need.item-warehouse')}
            </ItemDataTitle>
            <ItemDataValue>
              {item.wareHouseDelivery ? yesLabel : noLabel}
            </ItemDataValue>
          </Col>
        </Row>
      )}
      {item.acceptingDeliveriesHoursFrom && item.acceptingDeliveriesHoursTo && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>
              {t('labels.supply.need.item-hours-range')}
            </ItemDataTitle>
            <ItemDataValue>
              {`From ${item.acceptingDeliveriesHoursFrom} to ${item.acceptingDeliveriesHoursTo}`}
            </ItemDataValue>
          </Col>
        </Row>
      )}
      {item.description && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.item-description')}</ItemDataTitle>
            <ItemDataValue withoutTextStyle>{item.description}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.location && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>
              {t('labels.supply.need.item-location-delivery-details')}
            </ItemDataTitle>
            <ItemDataValue withoutTextStyle>
              <ItemDeliveryInformation item={item} />
            </ItemDataValue>
          </Col>
        </Row>
      )}
      {item.phoneNumber && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.phone-number')}</ItemDataTitle>
            <UndisclosedField item={item} field="phone-number" />
          </Col>
        </Row>
      )}
      {item.contactEmail && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.email')}</ItemDataTitle>
            <UndisclosedField item={item} field="email" />
          </Col>
        </Row>
      )}
      {item.contactName && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.contact-name')}</ItemDataTitle>
            <ItemDataValue>{item.contactName}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.user && item.user.timezone && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.timezone')}</ItemDataTitle>
            <ItemDataValue>{item.user.timezone}</ItemDataValue>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default observer(NeedSupplyInformation);
