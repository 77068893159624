import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {usePanelStore} from '../../contexts/PanelContext';
import {PageProvider} from '../../contexts/PageContext';
import styles from './Panel.module.scss';

const modalRoot = document.getElementById('panel-root');

const Panel = ({children, onClose}) => {
  const element = useRef(document.createElement('div'));
  const match = useRouteMatch();
  const history = useHistory();
  const [panelState, panelActions] = usePanelStore();
  const hideCursor = !panelState.panelHide ? (
    <FontAwesomeIcon icon={faChevronRight} size="1x" />
  ) : (
    <FontAwesomeIcon icon={faChevronLeft} size="1x" />
  );

  const handleClick = () => {
    panelActions.setPanelHide(!panelState.panelHide);
  };

  useEffect(() => {
    const panelNode = element.current;
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        panelActions.setPanelMount(false);
        onClose && onClose();
      }
    };

    modalRoot.appendChild(panelNode);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      modalRoot.removeChild(panelNode);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [history, match.url, onClose, panelActions]);

  if (!panelState.panelMount) {
    return null;
  }

  const content = (
    <PageProvider>
      <div className={styles.wrapper}>
        <div onClick={handleClick} className={styles.hideButton}>
          {hideCursor}
        </div>
        <div
          className={`${styles.innerWrapper} ${
            panelState.panelHide && styles.hidePanel
          }`}
        >
          <div className={styles.container}>
            <FontAwesomeIcon
              icon="times"
              size="lg"
              onClick={() => {
                panelActions.setPanelMount(false);
                onClose && onClose();
              }}
              className={styles.closeIcon}
            />
            {children}
          </div>
        </div>
      </div>
    </PageProvider>
  );

  return ReactDOM.createPortal(content, modalRoot);
};

export default Panel;
