import React from 'react';
import styles from './InformationToast.module.scss';

const InformationToast = (props) => {
  const {title, text, className} = props;

  return (
    <div className={`${styles.toast} ${className}`}>
      <div className={styles.toastTitle}>{title}</div>
      <div className={styles.toastText}>{text}</div>
    </div>
  );
};

export default InformationToast;
