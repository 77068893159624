import React, {useEffect, useState} from 'react';
import LayoutLogin from './LayoutLogin';
import {useParams} from 'react-router-dom';
import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';
import {useTranslation} from 'react-i18next';
import {clientLogoURL} from '../../constants';
import {ReactComponent as LoginBannerGeneric} from '../../assets/login-banner-generic.svg';
import styles from './EmailVerified.module.scss';
import Spinner from '../../components/Spinner';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LinkButton from '../../components/Button/LinkButton';

const EmailVerifiedPage = () => {
  const api = useApi();
  const stores = useStores();
  const {t} = useTranslation('login');
  const {encoded_email, token} = useParams();
  const [error, setError] = useState(null);

  const banner = clientLogoURL ? (
    <img
      className={styles.clientBanner}
      src={clientLogoURL}
      alt="Logo Banner"
    />
  ) : (
    <LoginBannerGeneric />
  );

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const data = {user_email: encoded_email, user_token: token};
        const user = await api.verifyEmail(data);
        stores.app.login(user);
        window.location.replace('/');
      } catch (e) {
        if (e.non_field_errors) {
          switch (e.non_field_errors[0]) {
            case 'ExpiredLink':
              setError(t('email-validation.link-expired'));
              break;
            case 'LinkSignatureAltered':
              setError(t('email-validation.link-signature-altered'));
              break;
            case 'UserAlreadyActive':
              setError(t('email-validation.user-already-active'));
              break;
            case 'InvalidToken':
              setError(t('email-validation.invalid-token'));
              break;
            default:
              setError(t('error-message-title'));
              break;
          }
        }
      }
    };
    verifyEmail();
  }, [api, encoded_email, stores.app, t, token]);
  return (
    <LayoutLogin>
      <div className={styles.container}>
        <div className={styles.logoContainer}>{banner}</div>
        {error ? (
          <div className={styles.errorContainer}>
            <FontAwesomeIcon
              icon="exclamation-triangle"
              className={styles.errorIcon}>
              Icon
            </FontAwesomeIcon>
            <p className={styles.verifyText}>{error}</p>
            <LinkButton onClick={() => window.location.replace('/')}>
              {t('auth0.go-back-to-login')}
            </LinkButton>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </LayoutLogin>
  );
};

export default EmailVerifiedPage;
