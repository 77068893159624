import React, {useRef, useState, useEffect, useCallback} from 'react';
import {getHeaders} from './utils';
import {observer} from 'mobx-react';
import {getNormalizedPlaceDetails} from '../utils/google.api';

const Address = (props) => {
  return (
    <div className="list-group-item">
      {props.children}
      <dl className="row">
        <dt className="col-sm-4">Street</dt>
        <dd className="nl-truncate col-sm-8">{props.address.street || '-'}</dd>
        <dt className="col-sm-4">Number</dt>
        <dd className="nl-truncate col-sm-8">{props.address.number || '-'}</dd>
        <dt className="col-sm-4">City</dt>
        <dd className="nl-truncate col-sm-8">{props.address.city || '-'}</dd>
        <dt className="col-sm-4">Postal code</dt>
        <dd className="nl-truncate col-sm-8">
          {props.address.postal_code || '-'}
        </dd>
        <dt className="col-sm-4">State</dt>
        <dd className="nl-truncate col-sm-8">{props.address.state || '-'}</dd>
        <dt className="col-sm-4">Country</dt>
        <dd className="nl-truncate col-sm-8">{props.address.country || '-'}</dd>
      </dl>
    </div>
  );
};

function parseGeocodeResult(result) {
  return result.address_components.reduce(
    function (accum, component) {
      var ct = component.types;

      if (!accum.number && ct.indexOf('street_number') !== -1) {
        accum.number = component.long_name;
      }
      if (!accum.street && ct.indexOf('route') !== -1) {
        accum.street = component.long_name;
      }
      if (!accum.state && ct.indexOf('administrative_area_level_1') !== -1) {
        accum.state = component.long_name;
        accum.state_short = component.short_name;
      }
      if (!accum.city && ct.indexOf('locality') !== -1) {
        accum.city = component.long_name;
        accum.city_short = component.short_name;
      }
      if (!accum.country && ct.indexOf('country') !== -1) {
        accum.country = component.long_name;
        accum.country_short = component.short_name;
      }
      if (!accum.postal_code && ct.indexOf('postal_code') !== -1) {
        accum.postal_code = component.long_name;
      }
      return accum;
    },
    {
      location: [
        result.geometry.location.lat(),
        result.geometry.location.lng(),
      ],
    },
  );
}

const AddressWidget = (props) => {
  const parsed = JSON.parse(props.value);
  const [value, setValue] = useState(props.value);
  const [addresses, setAddresses] = useState(
    props.multi ? parsed : parsed ? [parsed] : [],
  );
  const [loaded, setLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [address, setAddress] = useState(null);

  useEffect(() => {
    setValue(JSON.stringify(props.multi ? addresses : addresses[0] || null));
  }, [addresses, props.multi]);

  useEffect(() => {
    setLoaded(true);
  }, [setLoaded]);

  useEffect(() => {
    if (!address && autocompleteInputRef.current) {
      autocompleteInputRef.current.value = '';
      autocompleteInputRef.current.focus();
    }
  }, [address]);

  const autocompleteInputRef = useRef();
  const autocompleteRef = useRef();
  const autocompleteSetRef = useCallback((el) => {
    if (autocompleteInputRef.current && autocompleteRef.current) {
      window.google.maps.event.clearInstanceListeners(
        autocompleteInputRef.current,
      );
    }

    if (el) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(el, {
        types: ['geocode'],
        language: 'en',
      });
      autocompleteRef.current.setFields([
        'address_components',
        'geometry',
        'icon',
        'name',
        'types',
      ]);

      autocompleteRef.current.addListener('place_changed', async () => {
        const place = autocompleteRef.current.getPlace();
        const normalizedPlaceDetails = await getNormalizedPlaceDetails(place);

        if (!normalizedPlaceDetails.geometry) {
          window.alert(`No details available for input: "${place.name}"`);
          return;
        }

        const address = parseGeocodeResult(normalizedPlaceDetails);
        address.place = autocompleteInputRef.current.value;

        setAddress((prevState) => address);
      });
      autocompleteInputRef.current = el;
    } else {
      autocompleteRef.current = null;
      autocompleteInputRef.current = null;
    }
  }, []);

  const createAddress = async (data) => {
    setProcessing((prevState) => true);
    const response = await fetch(props.api, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
    });
    setProcessing((prevState) => false);
    return response.json();
  };

  const renderAddresses = () => {
    const fragment = addresses.map((address, index) => {
      const removeButton = (
        <button
          disabled={processing}
          onClick={(event) => {
            event.preventDefault();

            setAddresses((prevState) => {
              const addresses = [...prevState];
              addresses.splice(index, 1);
              return addresses;
            });
          }}
          className="close"
        >
          <span>&times;</span>
        </button>
      );

      const isPrimaryButton = props.multi ? (
        <span
          onClick={(event) => {
            event.preventDefault();

            setAddresses((prevState) => {
              const addresses = [...prevState].map((address, i) => {
                return {
                  ...address,
                  is_primary: i === index ? !address.is_primary : false,
                };
              });
              return addresses;
            });
          }}
          style={{cursor: 'pointer'}}
          className={`badge ${
            address.is_primary ? 'badge-primary' : 'badge-secondary'
          }`}
        >
          Primary
        </span>
      ) : null;

      return (
        <Address key={index} address={address}>
          {isPrimaryButton}
          {removeButton}
        </Address>
      );
    });

    return (
      <React.Fragment>
        <div className="nl-items">{fragment}</div>
        {loaded && !addresses.length ? (
          <div className="alert alert-info">No addresses found</div>
        ) : null}
      </React.Fragment>
    );
  };

  const errors = props.errors ? (
    <div
      className={props.errors.getAttribute('class')}
      dangerouslySetInnerHTML={{
        __html: props.errors.innerHTML,
      }}
    />
  ) : null;

  return (
    <React.Fragment>
      <label>{props.label}</label>
      <div className="input-group">
        <input
          ref={autocompleteSetRef}
          disabled={address}
          className={`form-control ${errors ? 'is-invalid' : ''}`}
          splaceholder="Type address"
        />

        <div className="input-group-append">
          <button
            onClick={(event) => {
              event.preventDefault();
              setAddress((prevState) => null);
            }}
            disabled={!address}
            className="btn input-group-text"
          >
            Clear
          </button>
          <button
            onClick={async (event) => {
              event.preventDefault();

              const result = await createAddress({
                number: address.number,
                street: address.street,
                location: address.location.join(','),
                city: address.city,
                city_short: address.city_short,
                country: address.country,
                country_short: address.country_short,
                state: address.state,
                state_short: address.state_short,
                postal_code: address.postal_code,

                place: address.place,
                is_primary: false,
              });

              setAddresses((prevState) => {
                return props.multi ? [...prevState, result] : [result];
              });

              setAddress((prevState) => null);
            }}
            disabled={!address}
            className="btn input-group-text"
          >
            Select
          </button>
        </div>
        {errors}
      </div>
      {renderAddresses()}
      <textarea
        style={{display: 'none'}}
        onChange={() => {}}
        className="form-control"
        value={value}
        name={props.name}
      />
    </React.Fragment>
  );
};

export default observer(AddressWidget);
