import React, {useState} from 'react';
import Typeahead from '../Form/Typeahead';
import {useSearchIndexApi} from '../../hooks/search-index';
import {FALLBACK_LANGUAGE} from '../../constants';
import {useStores} from '../../contexts/StoresContext';
import SearchIcon from '../../assets/icons/input-suffix.svg';
import sharedStyles from './shared.module.scss';

export function KeyboardAlgoliaControl({
  className,
  placeholder,
  value,
  onSelectItem,
  selectedValues,
  applyFilter,
}) {
  const searchIndexApi = useSearchIndexApi();
  const [options, setOptions] = useState([]);
  const [openOptions, setOpenOptions] = useState(false);
  const stores = useStores();
  const userLanguage = stores.app.language;

  const handleSearch = async (value) => {
    setOpenOptions(true);
    const results = await searchIndexApi.search(value, {
      restrictSearchableAttributes: [
        `title.${userLanguage}`,
        'meta_sectors',
        'meta_categories',
      ],
      models: ['needslist_item.Item'],
      exactMatch: false,
      distict: false,
    });

    const availableItems = results.reduce((accum, hit, index) => {
      const label = (
        hit.title[userLanguage] || hit.title[FALLBACK_LANGUAGE]
      ).trim();

      const exists = accum.some(
        (x) => x.label.toLowerCase() === label.toLowerCase(),
      );

      if (!exists) {
        return [
          ...accum,
          {
            id: hit.id,
            label: label,
            value: label,
            kind: hit.meta_kind,
          },
        ];
      }

      return [...accum];
    }, []);
    setOptions(availableItems);
  };

  const reportAlgoliaAnalytics = (value) => {};

  const handleChange = (value) => {
    if (value.length === 0) return;
    reportAlgoliaAnalytics(value);

    const selectedItem = value.slice(-1)[0];
    onSelectItem(selectedItem.value);
    setOpenOptions(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !openOptions) {
      applyFilter();
    }

    if (e.key === 'Escape') setOpenOptions(false);
  };

  const handleFilterBy = (item) => {
    return !selectedValues.some((x) => x === item.value);
  };

  return (
    <div className={sharedStyles.inputContainerWithButton}>
      <div
        className={sharedStyles.inputContainer}
        onClick={(e) => e.stopPropagation()}>
        <Typeahead
          multiple
          useCustomPills
          selectPartialMatchOnEnter={false}
          labelKey="label"
          options={options}
          id="item_template_id"
          onSearch={handleSearch}
          isLoading={false}
          onChange={handleChange}
          selected={value ? [value] : []}
          placeholder={placeholder}
          data-hj-allow
          filterBy={handleFilterBy}
          className={className}
          onKeyDown={handleKeyDown}
          open={openOptions}
          selectEnterConfirm
        />
        <img
          src={SearchIcon}
          className={sharedStyles.searchIconStyle}
          alt="search"
        />
      </div>
    </div>
  );
}
