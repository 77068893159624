import React from 'react';

import styled from 'styled-components';

const Content = styled.div`
  flex: 1;
`;

const AppContainer = (props) => {
  return (
    <React.Fragment>
      <Content>{props.children}</Content>
    </React.Fragment>
  );
};

export default AppContainer;
