import React, {useEffect, useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {useApi} from '../../contexts/ApiContext';
import Carousel from '../../components/Carousel';
import {mediaQueries, breakpoints} from '../../constants';
import Issue from '../../components/Issue';
import Responsive from '../../components/Responsive';
import Paginator from '../../components/Paginator';
import Subtitle from '../../components/Subtitle';
import {usePaginator} from '../../hooks/paginator';
import {useIsMobile} from '../../hooks/utils';

const PAGE_SIZE = 3;
const NUMBER_OF_CARDS = 3;
const CAROUSEL_HEIGHT = 260;
const SPACE_BETWEEN_ITEMS = 20;

const StyledIssue = styled(Issue)`
  flex-grow: 0;
  width: 329px;
  height: ${CAROUSEL_HEIGHT}px;

  ${mediaQueries.lg} {
    flex: 0.8 0 auto;
    width: 100%;
    margin: 10px 0;

    &:first-child {
      margin-left: 0;
    }

    &.card {
      min-width: 250px;
    }
  }
`;

const StyledPaginator = styled(Paginator)`
  margin-top: 40px;

  ${mediaQueries.lg} {
    margin-top: 15px;
    justify-content: center;
  }
`;

const IssueList = (props) => {
  const api = useApi();
  const [issues, setIssues] = useState([]);
  const [count, setCount] = useState(null);
  const {changePage, currentPage} = usePaginator();
  const isMobile = useIsMobile();
  const allIssuesRef = useRef(null);
  const {t} = useTranslation('browse-issues');

  useEffect(() => {
    const params = {
      countries: props.countries.map((country) => country.value),
      categories: props.categories.map((category) => category.id),
    };

    if (!isMobile) {
      params.recent = props.hasOnlyRecent || false;
    } else {
      params.page = currentPage;
      params.page_size = PAGE_SIZE;
    }

    const fetchIssues = async () => {
      const issues = await api.getIssues({
        ...params,
      });
      setCount(issues.count);
      setIssues(issues.results);
    };

    fetchIssues();
  }, [
    currentPage,
    isMobile,
    props.hasOnlyRecent,
    props.countries,
    props.categories,
    api,
  ]);

  const renderIssues = (issue) => {
    return (
      <StyledIssue
        onClick={() => props.onClick(issue)}
        issue={issue}
        key={issue.id}
        variant="large"
        CTAText={t('cta')}
      />
    );
  };

  const handlePageChange = (to, page) => {
    changePage(to, page);
    window.scrollTo(0, allIssuesRef.current.offsetTop);
  };

  if (issues.length === 0) return null;

  return (
    <React.Fragment>
      <Responsive>
        <div max={breakpoints.lg}>
          <Subtitle>{props.subtitle}</Subtitle>
          <Carousel
            numberOfCards={NUMBER_OF_CARDS}
            carouselHeight={CAROUSEL_HEIGHT}
            spaceBetweenItems={SPACE_BETWEEN_ITEMS}
          >
            {issues.map(renderIssues)}
          </Carousel>
        </div>
        <div min={breakpoints.lg}>
          <Subtitle ref={allIssuesRef}>{props.subtitle}</Subtitle>
          {issues.map(renderIssues)}
          <StyledPaginator
            total={count}
            pageSize={PAGE_SIZE}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </Responsive>
    </React.Fragment>
  );
};

export default IssueList;
