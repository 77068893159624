import React from 'react';

const initialValues = {
  initialized: false,
  filters: {},
  setFilter: () => {},
  setMultipleFilters: () => {},
  removeRelatedFilters: () => {},
  initFilters: () => {},
  removeFilter: () => {},
  clearFilters: () => {},
  overrideFilters: () => {},
  clearFilter: () => {},
};

const FiltersContext = React.createContext(initialValues);

export default FiltersContext;
