import React from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Col, Row} from 'react-bootstrap';

import {SelectField} from '../../../components/Fields';

const GovFields = () => {
  const {t} = useTranslation('create-account');

  return (
    <Form.Group as={Row}>
      <Col>
        <SelectField
          name="levelGovernment"
          label={t('labels.level-government')}
          isRequired
        >
          <option value="">{t('select')}</option>
          <option value="city">{t('levels-government.city')}</option>
          <option value="state">{t('levels-government.state')}</option>
          <option value="federal">{t('levels-government.federal')}</option>
          <option value="country">{t('levels-government.country')}</option>
        </SelectField>
      </Col>
    </Form.Group>
  );
};

export default GovFields;
