import React from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import {observer} from 'mobx-react';
import i18n, {dateFnsLocales} from '../../../i18n';
import {useStores} from '../../../contexts/StoresContext';
import Input from '../Input';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './CustomDatePicker.module.scss';

// register lang for datepicker
for (let [name, locale] of Object.entries(dateFnsLocales)) {
  registerLocale(name, locale);
}

const CustomDatePicker = (props) => {
  const stores = useStores();
  const {selected, onChange, placeholder, ...rest} = props;
  const dateFormat = stores.app.dateFormat['date-fns'];

  return (
    <div className={styles.wrapper}>
      <DatePicker
        {...rest}
        locale={i18n.language}
        selected={selected}
        onChange={onChange}
        placeholderText={placeholder || ''}
        dateFormat={dateFormat}
        isClearable
        customInput={
          <Input
            size={props.size}
            autoComplete="off"
            isInvalid={props.isInvalid}
            error={props.error}
            setRef={props.setRef}
            data-hj-allow
          />
        }
      />
    </div>
  );
};

export default observer(CustomDatePicker);
