import React, {useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Redirect, useLocation, useRouteMatch} from 'react-router-dom';
import Title from '../../components/Title';
import TemplateList from '../../components/TemplateList';
import {useApi} from '../../contexts/ApiContext';
import {usePageStore} from '../../contexts/PageContext';
import {useStores} from '../../contexts/StoresContext';
import {
  HOME_PAGE,
  MANAGE_USER_POST_EDIT_PAGE,
  MANAGE_ITEM_CATALOG_EDIT_PAGE,
} from '../../urls';
import {usePaginator} from '../../hooks/paginator';
import styles from './ItemTemplateAdmin.module.scss';
import stylesColumns from '../../components/TemplateList/TemplateList.module.scss';
import SearchInput from '../../components/SearchInput';
import queryString from 'query-string';

const ItemTemplateAdmin = () => {
  const stores = useStores();
  const api = useApi();
  const {t} = useTranslation(['manage-items', 'user']);
  const [, pageActions] = usePageStore();
  const {changePage, currentPage, pageSize} = usePaginator();
  const match = useRouteMatch();
  const [key, setKey] = useState('users-posts');
  const [editPage, setEditPage] = useState(MANAGE_USER_POST_EDIT_PAGE);

  const [customItems, setCustomItems] = useState([]);
  const [customItemsCount, setCustomItemsCount] = useState(null);
  const [itemTemplates, setItemTemplates] = useState([]);
  const [itemTemplatesCount, setItemTemplatesCount] = useState(null);
  const location = useLocation();
  const {search = ''} = queryString.parse(location.search);

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'manage-items'});
  }, [pageActions]);

  const handleCustomItems = useCallback(
    async (page) => {
      setKey('users-posts');
      const customItems = await api.getCustomItems({
        page_size: pageSize,
        page: page || currentPage,
        search,
      });
      setCustomItems(customItems.results);
      setCustomItemsCount(customItems.count);
    },
    [api, currentPage, search, pageSize],
  );

  const handleItemTemplates = useCallback(
    async (page) => {
      setKey('standard-catalog');
      const itemTemplates = await api.getItemTemplateCatalog({
        page_size: pageSize,
        page: page || currentPage,
        search,
      });
      setItemTemplates(itemTemplates.results);
      setItemTemplatesCount(itemTemplates.count);
    },
    [api, currentPage, search, pageSize],
  );

  const handleSelectTab = useCallback(
    (key) => {
      setKey(key);
      if (key === 'users-posts') {
        changePage(match.url, 1);
        setEditPage(MANAGE_USER_POST_EDIT_PAGE);
        handleCustomItems(1);
      }
      if (key === 'standard-catalog') {
        changePage(match.url, 1);
        setEditPage(MANAGE_ITEM_CATALOG_EDIT_PAGE);
        handleItemTemplates(1);
      }
    },
    [handleCustomItems, handleItemTemplates, changePage, match.url],
  );

  const handleDelete = async (id) => {
    if (key === 'users-posts') {
      await api.deleteCustomItem(id);
      handleCustomItems();
    }
    if (key === 'standard-catalog') {
      await api.deleteItemTemplateCatalog(id);
      handleItemTemplates();
    }
  };

  const tabs = [
    {
      title: t('manage-items:tabs.users-posts'),
      eventKey: 'users-posts',
      data: customItems,
      count: customItemsCount,
    },
    {
      title: t('manage-items:tabs.standard-catalog'),
      eventKey: 'standard-catalog',
      data: itemTemplates,
      count: itemTemplatesCount,
    },
  ];

  const extraHeaders = [
    {
      title: t('user:columns.template-list.type'),
      size: 3,
      className: stylesColumns.columnText,
    },
  ];

  const extraColumns = [
    {
      renderCell: (item) => item.kind,
      prefix: () => (
        <strong>{t('user:columns.template-list.type')}:&nbsp;</strong>
      ),
      size: 3,
      className: stylesColumns.columnText,
    },
  ];

  useEffect(() => {
    if (key === 'users-posts') {
      handleCustomItems();
    }
    if (key === 'standard-catalog') {
      handleItemTemplates();
    }
  }, [key, handleCustomItems, handleItemTemplates]);

  if (!stores.app.user.isEditor && !stores.app.user.isAdmin) {
    return <Redirect to={HOME_PAGE} />;
  }

  return (
    <div className={styles.wrapper}>
      <Title className={styles.title}>{t('user:titles.manage-items')}</Title>
      <SearchInput id="search-bar-items" initialValue={search} />

      <TemplateList
        extraHeaders={extraHeaders}
        extraColumns={extraColumns}
        pageSize={pageSize}
        tabs={tabs}
        onTabSelected={handleSelectTab}
        onDelete={handleDelete}
        editPage={editPage}
      />
    </div>
  );
};

export default ItemTemplateAdmin;
