import React from 'react';
import styled, {css} from 'styled-components';
import {Navbar, Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {ReactComponent as LogoGeneric} from '../../assets/logo-generic.svg';
import {ReactComponent as LogoTesting} from '../../assets/testing-ribbon.svg';
import {
  mediaQueries,
  breakpoints,
  clientLogoURL,
  FEATURES,
} from '../../constants';
import Responsive from '../Responsive';
import {isFeatureEnabled} from '../../utils/features';
import {useStores} from '../../contexts/StoresContext';

const logoStyles = css`
  max-width: 12.1rem;
  overflow: visible;
  height: inherit;

  ${mediaQueries.lg} {
    // Vertical centering in mobile
    padding-top: 0.3rem;
  }
`;

const logoTestingStyles = css`
  transform: scale(1.6);
`;

const StyledGenericLogo = styled(LogoGeneric)`
  ${logoStyles}
`;

const StyledProductionLogo = styled.img`
  ${logoStyles}
`;

const StyledLogoTesting = styled(LogoTesting)`
  ${logoTestingStyles}
`;

const Logo = () => {
  const logo = clientLogoURL ? (
    <StyledProductionLogo src={clientLogoURL} alt="Logo" />
  ) : (
    <StyledGenericLogo />
  );
  const stores = useStores();

  return (
    <Nav>
      <Navbar.Brand as={Link} to="/">
        {!isFeatureEnabled(
          FEATURES.hideTestingBanners,
          stores.app.features,
        ) && (
          <Responsive>
            <StyledLogoTesting max={breakpoints.lg} />
          </Responsive>
        )}
        {logo}
      </Navbar.Brand>
    </Nav>
  );
};

export default Logo;
