import React from 'react';
import moment from 'moment';
import 'moment/min/locales';
import {useStores} from '../../contexts/StoresContext';
import ResponsiveImg from '../ResponsiveImg';
import styles from './ItemConfirmForm.module.scss';

const Message = (props) => {
  const stores = useStores();
  const msgDate = moment(props.date);
  const senderContent = (
    <div className={styles.senderContainer}>
      <div className={styles.senderImage}>
        <ResponsiveImg src={props.senderLogo} />
      </div>
    </div>
  );

  moment.locale(stores.app.user.language.code);

  return (
    <div className={`${styles.messageWrapper} ${props.className}`}>
      {!props.invert && senderContent}
      <div
        className={`${styles.messageBox} ${
          props.invert ? styles.invertBackground : styles.messageBoxBackground
        }`}>
        {props.children}
        <div className={styles.date}>
          {msgDate.format('MM/DD/YYYY')} - {msgDate.fromNow()}
        </div>
      </div>
      {props.invert && senderContent}
    </div>
  );
};

export default Message;
