import React, {useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Row} from 'react-bootstrap';

import {useApi} from '../../../contexts/ApiContext';
import {MultiCheckField} from '../../../components/Fields';

const FocusAreaField = (props) => {
  const api = useApi();
  const [items, setItems] = useState({general: [], sdg: []});
  const {t} = useTranslation('create-account');

  const fetchFocusAreas = useCallback(async () => {
    const focusAreas = await api.getFocusAreas();

    const newItems = focusAreas.results.reduce(
      (accum, focusArea) => {
        if (focusArea.focus_area_type === 'general') {
          return {
            ...accum,
            general: [
              ...accum.general,
              {id: focusArea.id, label: focusArea.name},
            ],
          };
        }

        return {
          ...accum,
          sdg: [...accum.sdg, {id: focusArea.id, label: focusArea.name}],
        };
      },
      {general: [], sdg: []},
    );

    setItems(newItems);
  }, [api]);

  useEffect(() => {
    fetchFocusAreas();
  }, [fetchFocusAreas]);

  return (
    <React.Fragment>
      <Form.Group as={Row}>
        <MultiCheckField
          label={t('labels.focus-area')}
          items={items.general}
          name="focusArea"
          allowOther
          otherName="otherFocusArea"
          isRequired={props.isRequired}
          isFullWidth={props.isFullWidth}
        />
      </Form.Group>
      <Form.Group as={Row}>
        <MultiCheckField
          label={t('labels.sdg')}
          items={items.sdg}
          name="sdg"
          allowNumeration
          isRequired={props.isRequired}
          isFullWidth={props.isFullWidth}
        />
      </Form.Group>
    </React.Fragment>
  );
};

export default FocusAreaField;
