import React from 'react';
import {Button, Card} from 'react-bootstrap';
import Avatar from '../Avatar';
import {ReactComponent as ManufacturerEquipmentIcon} from '../../assets/icons/manufacturer-equipment.svg';
import {ReactComponent as ManufacturerProductsIcon} from '../../assets/icons/manufacturer-products.svg';
import {ReactComponent as PhoneIcon} from '../../assets/icons/phone.svg';
import {ReactComponent as MailIcon} from '../../assets/icons/mail.svg';
import {getInitials} from '../../utils';
import styles from './Manufacturers.module.scss';
import Spinner from '../Spinner';

const ManufacturersList = (props) => {
  if (props.isLoading) {
    return <Spinner />;
  }

  const renderManufacturer = (manufacturer, index) => {
    const orgImage = getInitials(manufacturer.name);
    return (
      <Card key={index} className={styles.itemsCard}>
        <Card.Header className={styles.cardHeader}>
          <div>
            <Card.Title className={styles.cardTitle}>Manufacturer</Card.Title>
            <span>{manufacturer.category}</span>
            <strong>{manufacturer.name}</strong>
          </div>
          <Avatar className={styles.orgLogo}>{orgImage}</Avatar>
        </Card.Header>
        <Card.Body className={styles.cardBody}>
          {manufacturer.equipment && (
            <p>
              <ManufacturerEquipmentIcon className={styles.infoIcon} />
              {manufacturer.equipment}
            </p>
          )}
          {manufacturer.products && (
            <p>
              <ManufacturerProductsIcon className={styles.infoIcon} />
              {manufacturer.products}
            </p>
          )}
          <div>
            {manufacturer.contact && (
              <b>
                <PhoneIcon className={styles.infoIcon} /> {manufacturer.contact}
              </b>
            )}
            {manufacturer.email && (
              <b>
                <MailIcon className={styles.infoIcon} /> {manufacturer.email}
              </b>
            )}
          </div>
          {manufacturer.email && (
            <Button
              href={`mailto:${manufacturer.email}`}
              className={styles.cardButton}
              variant="outline-primary"
            >
              Contact
            </Button>
          )}
        </Card.Body>
      </Card>
    );
  };

  return props.items.map(renderManufacturer);
};

export default ManufacturersList;
