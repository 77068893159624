import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import FileDropZone from '../../FileDropZone';
import Center from '../../Center';
import ErrorMessage from '../ErrorMessage';

import {useApi} from '../../../contexts/ApiContext';
import {useTranslation} from 'react-i18next';
import styles from './FileUpload.module.scss';

const FileUpload = (props) => {
  const {value, onChange, multiple, noRequirementFile} = props;
  const {t} = useTranslation('forms');
  const api = useApi();
  const [isUploading, setIsUploading] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState(0);
  const initialFiles = multiple ? value : value ? [value] : [];
  const [uploadedFiles, setUploadedFiles] = useState(initialFiles);
  const [inputValue, setInputValue] = useState('');
  const [isTooBig, setIsTooBig] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (
      filesToUpload !== 0 &&
      uploadedFiles.length >= 1 &&
      (uploadedFiles.length === filesToUpload ||
        (showError && uploadedFiles.length >= 1))
    ) {
      setIsUploading(false);
      setFilesToUpload(0);
      setInputValue('');
      if (multiple) {
        onChange(uploadedFiles);
      } else {
        onChange(uploadedFiles[0]);
      }
    }
  }, [onChange, filesToUpload, uploadedFiles, multiple, showError]);

  const MAX_FILE_SIZE = 25 * 1000000;
  const uploadFile = async (file, fileData) => {
    if (file.size <= MAX_FILE_SIZE || noRequirementFile) {
      setShowError(false);
      setIsTooBig(false);
      const response = await api.uploadFile(fileData);
      await fetch(response.signed_url, {
        method: 'PUT',
        headers: {
          'x-goog-acl': response.acl,
          'Content-type': fileData.content_type,
        },
        body: file,
      });
      response.size = file.size;
      setUploadedFiles((currentValue) => [...currentValue, response]);
    } else {
      setIsUploading(false);
      setShowError(true);
      setIsTooBig(false);
      fileData.size = file.size;
      setUploadedFiles((currentValue) => [...currentValue, fileData]);
      handleDeleteFile(0);
    }
  };

  const prepareFile = (file) => {
    const fileData = {
      name: file.name,
      content_type: file.type,
    };
    uploadFile(file, fileData);
  };

  const handleFileUpload = (files) => {
    setIsUploading(true);
    files.forEach(prepareFile);
    setFilesToUpload(files.length);
  };
  const handleDeleteFile = (index) => {
    if (!multiple) {
      setUploadedFiles([]);
      onChange(null);
    } else {
      let updatedFileList;

      if (index === 0 && uploadedFiles.length === 1) {
        updatedFileList = [];
      } else {
        updatedFileList = [
          ...uploadedFiles.slice(0, index),
          ...uploadedFiles.slice(index + 1, uploadedFiles.length),
        ];
      }
      setUploadedFiles(updatedFileList);
      onChange(updatedFileList);
    }
  };

  const renderMultipleFiles = () => {
    const content = value.map((file, index) => {
      const onDelete = () => handleDeleteFile(index);

      return (
        <div className={styles.fileContainer} key={index}>
          <div>{file.name}</div>
          <FontAwesomeIcon
            icon="times-circle"
            onClick={onDelete}
            className={styles.removeFileIcon}
          />
        </div>
      );
    });

    return <div>{content}</div>;
  };
  const renderSingleFile = () => {
    if (value) {
      const onDelete = () => handleDeleteFile(0);

      return (
        <Center column>
          {!isTooBig && (
            <React.Fragment>
              <div>{value.name}</div>
              <span onClick={onDelete} className={styles.fileSpan}>
                {t('file.delete')}
              </span>
            </React.Fragment>
          )}
        </Center>
      );
    }
  };
  const fileSpan = () => {
    return <span className={styles.fileSpan}>{t('file.drop')}</span>;
  };

  return (
    <React.Fragment>
      <FileDropZone
        isFileUploaded={uploadedFiles.length > 0}
        renderFileLoaded={multiple ? renderMultipleFiles : renderSingleFile}
        renderPlaceholder={fileSpan}
        onFilesAdded={handleFileUpload}
        isUploading={isUploading}
        multiple={multiple}
        value={inputValue}
        accept="file/*"
      />
      {showError && (
        <ErrorMessage forceDisplay>{t('errors.file')}</ErrorMessage>
      )}
    </React.Fragment>
  );
};

export default FileUpload;
