import React, {useEffect, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {Tab} from 'react-bootstrap';

import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';
import {clientLogoURL} from '../../constants';
import {useLanguages} from '../../hooks/languages';

import LoginForm from './LoginForm';
import LoginV2 from './LoginV2';
import Tabs from '../../components/Tabs';
import RegisterForm from './RegisterForm';
import Spinner from '../../components/Spinner';

import {ReactComponent as LoginBannerGeneric} from '../../assets/login-banner-generic.svg';
import styles from './Login.module.scss';
import EmailVerified from './EmailVerified';
import LayoutLogin from './LayoutLogin';

const Login = ({invitation, children}) => {
  const stores = useStores();
  const api = useApi();
  const {t} = useTranslation('login');
  const [signUpType, setSignUpType] = useState();
  const [isInvitationTokenValid, setIsInvitationTokenValid] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [emailVerified, setEmailVerified] = useState(null);
  const [activeTab, setActiveTab] = useState('login');
  const {setLanguageByQueryParam} = useLanguages();
  const history = useHistory();
  const user = stores.app.user;
  const auth0Enabled = stores.app.clientData.authentication_type === 'auth0';
  const queryStringParams = new URLSearchParams(history.location.search);
  const invitationToken = queryStringParams.get('invitation_token');

  const banner = clientLogoURL ? (
    <img
      className={styles.clientBanner}
      src={clientLogoURL}
      alt="Logo Banner"
    />
  ) : (
    <LoginBannerGeneric />
  );

  const showNewUserTab =
    signUpType === 'public' ||
    (signUpType === 'by_invite' && isInvitationTokenValid) ||
    (signUpType === 'by_invite' && invitation?.token) ||
    signUpType === 'both';
  const {state} = history.location;
  const nextUrl = state && state.nextUrl ? state.nextUrl : '';
  const userChecked = useRef(false);
  const tabs = [
    {eventKey: 'login', title: t('tabs.log-in')},
    {eventKey: 'newUser', title: t('tabs.new-user')},
  ];
  useEffect(() => {
    if (invitationToken) {
      const fetchInvitationTokenValidation = async () => {
        const response = await api.validateInvitationToken({
          invitation_token: invitationToken,
        });
        setIsInvitationTokenValid(response.status === 'valid' ? true : false);
        setIsLoading(false);
      };
      fetchInvitationTokenValidation();
    }
  }, [invitationToken, api]);
  useEffect(() => {
    if (history.location.pathname === '/signup' && signUpType === 'by_invite') {
      history.push('/login');
    }
    if (
      (invitationToken &&
        (signUpType === 'by_invite' || signUpType === 'both')) ||
      history.location.pathname === '/signup' ||
      invitation
    ) {
      setActiveTab('newUser');
    }
  }, [history, signUpType, invitationToken, invitation]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('inactivitySessionExpire')) {
      setSessionExpired(true);
    } else {
      setSessionExpired(false);
    }
  }, []);

  useEffect(() => {
    const fetchSignUpType = async () => {
      const response = await api.getClientData();
      setSignUpType(response.sign_up_type);
      setIsLoading(false);
    };
    stores.app.togglePublicPage(false);

    fetchSignUpType();
  }, [setIsLoading, stores.app, api]);

  useEffect(() => {
    if (sessionExpired !== null) {
      if (user && !userChecked.current && !sessionExpired) {
        history.push('/');
      } else {
        userChecked.current = true;
      }
    }
  }, [user, history, sessionExpired]);

  useEffect(() => {
    setLanguageByQueryParam();
  }, [setLanguageByQueryParam]);

  if (auth0Enabled) {
    return (
      <LoginV2
        isLoading={isLoading}
        signUpType={signUpType}
        isInvitationTokenValid={isInvitationTokenValid}
      />
    );
  }

  const content = isLoading ? (
    <div className={styles.spinnerWrapper}>
      <Spinner />
    </div>
  ) : showNewUserTab ? (
    <div className={styles.formContainer}>
      <Tabs
        activeKey={activeTab}
        onSelect={(k) => {
          setActiveTab(k);
          setEmailVerified(null);
        }}
        id="login-tabs">
        {tabs.map((tab) => (
          <Tab eventKey={tab.eventKey} title={tab.title} key={tab.eventKey}>
            <div className={styles.logoContainer}>{banner}</div>
            {children ? (
              {children}
            ) : emailVerified ? (
              <EmailVerified data={emailVerified} />
            ) : tab.eventKey === 'login' ? (
              <LoginForm
                nextUrl={nextUrl}
                setEmailVerified={setEmailVerified}
              />
            ) : tab.eventKey === 'newUser' ? (
              <RegisterForm
                invitation={invitation}
                invitationToken={invitationToken}
                setEmailVerified={setEmailVerified}
              />
            ) : null}
          </Tab>
        ))}
      </Tabs>
    </div>
  ) : (
    <div className={styles.formContainer}>
      <div className={styles.logoContainer}>{banner}</div>
      <LoginForm />
    </div>
  );

  return <LayoutLogin>{content}</LayoutLogin>;
};

export default observer(Login);
