import React from 'react';
import {useField} from 'formik';
import TextFieldControl from './TextFieldControl';
import Label from '../Helper/Label';
import {useRemoveError} from '../../../hooks/formik';
import {useShouldHideField} from '../hooks/shouldHideField';

const TextField = (props) => {
  const [field, meta] = useField(props);
  const {label, required, ...rest} = props;
  const {removeError} = useRemoveError(field.name, field.onChange);
  const {hideField} = useShouldHideField(props);

  if (hideField) {
    return null;
  }
  return (
    <React.Fragment>
      <Label isRequired={required}>{label}</Label>
      <TextFieldControl
        {...field}
        {...rest}
        error={meta.error}
        onChange={removeError}
        isInvalid={!!meta.error && meta.touched}
      />
    </React.Fragment>
  );
};

export default TextField;
