import React, {useContext, useEffect, useState} from 'react';
import {Row, Col} from 'react-bootstrap';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation, Trans} from 'react-i18next';

import FiltersContext from '../../../components/FiltersProvider/FiltersContext';
import ItemsList from '../../../components/ItemsList';
import SectionTitle from '../SectionTitle';
import Placeholder from '../Placeholder';
import {useItems, useItemFilters} from '../../../hooks/items';
import ItemSearchBar from '../../../components/ItemSearchBar';
import {getItemsFilters, breakpoints} from '../../../constants';
import Button from '../../../components/Button';
import {usePageStore} from '../../../contexts/PageContext';
import ItemFilters from '../../../components/ItemFilters/ItemFilters';
import Responsive from '../../../components/Responsive';
import Paginator from '../../../components/Paginator';
import {useWindowSize} from '../../../hooks/utils';
import {usePaginator} from '../../../hooks/paginator';
import {useApi} from '../../../contexts/ApiContext';
import Dialog from '../../../components/Dialog';
import {ITEM_EDIT, ITEM_CONFIRM, ITEM_EDIT_QUANTITY} from '../../../urls';
import {observer} from 'mobx-react';
import {useStores} from '../../../contexts/StoresContext';
import {usePanelStore} from '../../../contexts/PanelContext';
import styles from './Content.module.scss';

const {organization: _, ...ITEM_FILTERS_NO_ORG} = getItemsFilters();

const PAGE_SIZE_LG = 6;
const PAGE_SIZE_SM = 3;

const MyItems = ({side}) => {
  const api = useApi();
  const stores = useStores();
  const {initFilters} = useContext(FiltersContext);
  const [, pageActions] = usePageStore();
  const {t} = useTranslation('manage');
  const history = useHistory();
  const location = useLocation();
  const windowSize = useWindowSize();
  const {changePage, currentPage} = usePaginator();
  const pageSize =
    windowSize.width > breakpoints.lg ? PAGE_SIZE_LG : PAGE_SIZE_SM;
  const maxPages = windowSize.width > breakpoints.lg ? 10 : 5;
  const [queryString, initialized] = useItemFilters(pageSize);
  const {items, count, isLoading, fetchItems} = useItems(
    queryString,
    initialized,
  );
  const [itemToDelete, setItemToDelete] = useState();
  const {user} = stores.app;
  const itemFilters = user.isAdmin ? getItemsFilters() : ITEM_FILTERS_NO_ORG;
  const [, panelActions] = usePanelStore();

  useEffect(() => {
    initFilters({
      side: [{label: side, value: side}],
      which: [{label: 'mine', value: 'mine'}],
      status: ['open', 'in_progress', 'done', 'expired', 'on_hold'],
    });
  }, [side, initFilters]);

  useEffect(() => {
    if (location.state && location.state.refresh) {
      fetchItems(true);
    }
  }, [location, fetchItems]);

  const openPanel = () => {
    fetchItems(true);
    panelActions.setPanelMount(true);
  };
  const handleConfirmationClick = (item) => {
    openPanel();
    history.push(
      `${ITEM_CONFIRM.replace(':itemId', item.id)}?page=${currentPage}`,
    );
    pageActions.setBreadcrumbs({key: `confirm-${item.side}`});
  };
  const handleEditItemClick = (item) => {
    openPanel();
    history.push(
      `${ITEM_EDIT.replace(':itemId', item.id)}?page=${currentPage}`,
    );
    pageActions.setBreadcrumbs({key: `manage-edit-${item.side}`});
  };
  const handleEditQuantityClick = (item) => {
    openPanel();
    history.push(
      `${ITEM_EDIT_QUANTITY.replace(':itemId', item.id)}?page=${currentPage}`,
    );
    pageActions.setBreadcrumbs({key: `manage-edit-quantity`});
  };
  const handleAddClick = () => {
    history.push(`/add-${side}s`);
  };
  const handlePauseClick = async (item) => {
    await api.pauseToggleItem(item.id);
    fetchItems(true);
  };
  const handleDeleteClick = async (item) => {
    setItemToDelete(item);
  };

  const menuItemsNeed = [
    {
      label: (item) =>
        item.status === 'open' ||
        item.status === 'in_progress' ||
        item.status === 'expired' ||
        item.status === 'on_hold'
          ? t('needs.menu.edit')
          : t('menu.view'),
      onClick: handleEditItemClick,
    },
    {
      label: (item) =>
        item.status === 'in_progress' || item.status === 'done'
          ? t('needs.menu.confirm-delivery')
          : null,
      onClick: handleConfirmationClick,
    },
    {
      label: (item) =>
        item.status === 'on_hold' ? t('menu.resume') : t('menu.pause'),
      onClick: handlePauseClick,
    },
    {
      label: t('menu.delete'),
      onClick: handleDeleteClick,
    },
    stores.app.user.isAdmin && {
      label: (item) => item.status === 'done' && t('menu.edit-quantity'),
      onClick: handleEditQuantityClick,
    },
  ];

  const menuItemsOffer = [
    {
      label: (item) =>
        item.status === 'open' ||
        item.status === 'in_progress' ||
        item.status === 'expired' ||
        item.status === 'on_hold'
          ? t('offers.menu.edit')
          : t('menu.view'),
      onClick: handleEditItemClick,
    },
    {
      label: (item) =>
        user.isAdmin &&
        (item.status === 'in_progress' || item.status === 'done')
          ? t('needs.menu.confirm-delivery')
          : null,
      onClick: handleConfirmationClick,
    },
    {
      label: (item) =>
        item.status === 'on_hold' ? t('menu.resume') : t('menu.pause'),
      onClick: handlePauseClick,
    },
    {
      label: t('menu.delete'),
      onClick: handleDeleteClick,
    },
    stores.app.user.isAdmin && {
      label: (item) => item.status === 'done' && t('menu.edit-quantity'),
      onClick: handleEditQuantityClick,
    },
  ];

  const renderDeleteDialog = () => {
    const handleOnConfirm = async () => {
      await api.deleteItem(itemToDelete.id);
      fetchItems(true);
      setItemToDelete(null);
    };

    const handleOnCancel = () => {
      setItemToDelete(null);
    };

    return (
      <Dialog
        header={t('dialog.header')}
        title={t('dialog.title')}
        message={t('dialog.message', {
          itemSide: itemToDelete.side,
          itemName: itemToDelete.name,
        })}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        confirmText={t('dialog.confirm')}
        cancelText={t('dialog.cancel')}
      />
    );
  };

  return (
    <React.Fragment>
      <div className={styles.filters}>
        <div className={styles.filtersHeader}>
          <ItemSearchBar
            itemFilters={itemFilters}
            className={styles.itemSearchBar}
          />
          <Responsive>
            <ItemFilters itemFilters={itemFilters} min={breakpoints.lg} />
          </Responsive>
          <Button
            onClick={handleAddClick}
            variant="outline-primary"
            responsiveFullWidth
            className={styles.addButton}>
            {t(`${side}s.add`)}
          </Button>
        </div>
        <Responsive>
          <ItemFilters itemFilters={itemFilters} max={breakpoints.lg} />
        </Responsive>
      </div>
      <SectionTitle>{t(`${side}s.title`)}</SectionTitle>
      {items.length === 0 && !isLoading && (
        <Placeholder>
          <Trans i18nKey={`manage:${side}s.placeholder`}>
            text <br /> text
          </Trans>
        </Placeholder>
      )}
      <ItemsList
        isLoading={isLoading}
        items={items}
        menuItems={side === 'offer' ? menuItemsOffer : menuItemsNeed}
        showEdited
        hideErrors
        hideOrganizationName
        showPendingDelivery={
          side === 'need' || (side === 'offer' && user.isAdmin)
        }
        hideLogo
      />
      <Row>
        <Col xs={12}>
          <Paginator
            className={styles.itemsPaginator}
            total={count}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={changePage}
            maxPages={maxPages}
          />
        </Col>
      </Row>
      {itemToDelete && renderDeleteDialog()}
    </React.Fragment>
  );
};

export default observer(MyItems);
