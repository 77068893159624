import React from 'react';
import {Button} from 'react-bootstrap';
import styles from './LinkButton.module.scss';

const LinkButton = (props) => {
  return (
    <Button
      variant="link"
      size="sm"
      className={`${styles.customButton} ${props.className}`}
      disabled={props.disabled}
      onClick={props.onClick}>
      {props.children}
    </Button>
  );
};

export default LinkButton;
