import React, {forwardRef} from 'react';
import {Form} from 'react-bootstrap';
import styles from './Checkbox.module.scss';

const Checkbox = forwardRef((props, ref) => {
  return (
    <Form.Control
      {...props}
      custom
      type="checkbox"
      as={Form.Check}
      ref={ref}
      className={styles.formCheckbox}
    />
  );
});

export default Checkbox;
