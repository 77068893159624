import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {Form, Col} from 'react-bootstrap';
import {useStores} from '../../contexts/StoresContext';
import {RadioField, UnitSelectField, InputField} from '../Fields';
import Label from '../Form/Label';
import styles from './BundleConstructor.module.scss';

const BundleConstructor = ({
  values,
  item,
  setFieldValue,
  setFieldsRefs,
  editQuantity,
}) => {
  const stores = useStores();

  let quantityRef = null;
  let bundleUnitsRef = null;
  const hasTemplate = !!item.itemTemplate;
  const isDone = item.status === 'done';
  const isInProgress = item.status === 'in_progress';
  const {uom} = stores.app.choices;
  const baseUom = uom.find((uom) => uom.id === parseInt(values.baseUom));
  const bundleUom = uom.find(
    (uom) => uom.id === parseInt(values.bundle.uom.id),
  );

  useEffect(() => {
    if ((quantityRef || bundleUnitsRef) && setFieldsRefs) {
      setFieldsRefs({
        quantity: quantityRef,
        'bundle.units': bundleUnitsRef,
      });
    }
  }, [quantityRef, bundleUnitsRef, setFieldsRefs]);

  const {t} = useTranslation(['forms', 'common']);
  const deliveryInBundles = [
    {
      label: t('forms:fields.bundling-not-required'),
      value: 'not_required',
    },
    {
      label: t('forms:fields.delivery-in-bundles'),
      value: 'preferred',
    },
  ];
  const setQuantityInputRef = (ref) => (quantityRef = ref);
  const setBundleUnitsRef = (ref) => (bundleUnitsRef = ref);

  const handleSetBundleOption = (event) => {
    const value = event.target.value;
    setFieldValue('deliveryInBundles', value);
    setFieldValue('bundle', {
      id: values.bundle.id || 0,
      units: 1,
      uom: {
        id: value === 'preferred' ? 8 : 1,
      },
    });
  };

  const renderBundleBuilder = () => {
    return (
      <React.Fragment>
        <Form.Row className={styles.customRow}>
          <Form.Group as={Col} sm={12}>
            <UnitSelectField
              showBundleUnits
              label={t('forms:labels.supply.bundle-uom')}
              name="bundle.uom.id"
              kind={item.kind}
              readOnly={isDone || isInProgress}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row className={styles.customRow}>
          <Form.Group as={Col} sm={12}>
            <InputField
              name="bundle.units"
              label={t('forms:labels.supply.bundle-units', {
                baseUom: baseUom ? baseUom.name : t('common:uom.unit'),
                uom: bundleUom ? bundleUom.name : t('common:uom.box'),
              })}
              placeholder={t('forms:labels.supply.bundle-units-placeholder')}
              inlineLabel={baseUom ? baseUom.name : t('common:uom.box')}
              setRef={setBundleUnitsRef}
              readOnly={isDone || isInProgress}
            />
          </Form.Group>
        </Form.Row>
      </React.Fragment>
    );
  };

  const renderItemWithNoTemplateBundleContent = () => {
    return (
      <React.Fragment>
        <Form.Row className={styles.customRow}>
          <Form.Group as={Col} sm={12}>
            <RadioField
              name="deliveryInBundles"
              label={t('forms:labels.supply.bundling-preference')}
              options={deliveryInBundles}
              onChange={handleSetBundleOption}
              readOnly={isDone || isInProgress}
              isRequired
            />
          </Form.Group>
        </Form.Row>
        {values.deliveryInBundles === 'preferred' && renderBundleBuilder()}
      </React.Fragment>
    );
  };

  const bundleContent = renderItemWithNoTemplateBundleContent();

  return (
    <React.Fragment>
      <Form.Row className={styles.customRow}>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('forms:labels.supply.unit-measurement')}</Label>
          <UnitSelectField
            name="baseUom"
            kind={item.kind}
            readOnly={isDone || isInProgress || hasTemplate}
          />
        </Form.Group>
      </Form.Row>
      {bundleContent}
      <Form.Row className={styles.customRow}>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="quantity"
            placeholder={t('forms:labels.supply.bundle-units-placeholder')}
            label={t(`forms:labels.supply.${item.side}.item-quantity`, {
              itemName: item.name[0].toUpperCase() + item.name.substr(1),
            })}
            inlineLabel={
              hasTemplate || values.deliveryInBundles === 'preferred'
                ? bundleUom
                  ? bundleUom.name
                  : t('common:uom.unit')
                : baseUom
                ? baseUom.name
                : t('common:uom.unit')
            }
            setRef={setQuantityInputRef}
            readOnly={!editQuantity && isDone}
            isRequired
          />
        </Form.Group>
      </Form.Row>
    </React.Fragment>
  );
};

export default observer(BundleConstructor);
