import React from 'react';
import styles from './Visualizations.module.scss';
import theme from '../../../theme';

const ChartLegend = (props) => {
  const {chartData, headerTitle, headerColor, isVertical, isEmpty} = props;

  // TODO: Add dollar value once data is ready.
  return (
    <div>
      {headerTitle && (
        <div
          style={{color: isEmpty ? theme.hitGray : headerColor}}
          className={styles.chartLegendHeader}
        >
          {headerTitle}
        </div>
      )}
      <div
        className={
          isVertical
            ? styles.legendKeysVerticalContainer
            : styles.legendKeysHorizontalContainer
        }
      >
        {chartData &&
          Object.keys(chartData).map((legendKey) => {
            return (
              <div key={legendKey} className={styles.legendKeyContainer}>
                <div
                  style={{
                    background: isEmpty
                      ? theme.hitGray
                      : chartData[legendKey]['color'],
                  }}
                  className={styles.legendKeyCircle}
                ></div>
                <div
                  style={{
                    color: isEmpty
                      ? theme.hitGray
                      : chartData[legendKey]['color'],
                  }}
                  className={styles.legendKeyDisplayText}
                >
                  {chartData[legendKey]['displayText']}
                </div>
                <div
                  style={{
                    color: isEmpty
                      ? theme.hitGray
                      : chartData[legendKey]['color'],
                  }}
                  className={styles.legendKeyItemCountText}
                >
                  {isEmpty ? '-' : chartData[legendKey]['itemCount']}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ChartLegend;
