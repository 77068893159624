import i18n from './i18n';

export const FALLBACK_LANGUAGE = 'en-us';

export const USER_STATUS_CHOICES = {
  active: 'active',
  processing: 'processing',
  approvalPending: 'approval_pending',
};

export const ONGOING_ISSUE = 1;

export const getYesNoOptions = () => [
  {
    label: i18n.t('common:yes'),
    value: true,
  },
  {
    label: i18n.t('common:no'),
    value: false,
  },
];

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const Z_INDEX = {
  aboveOverlay: 3,
  overlay: 2,
  belowOverlay: 1,
};

export const getItemsFilters = () => ({
  issue: {
    label: i18n.t('common:filters.issue'),
    value: 'issue',
    partialMatchValue: 'issueName',
  },
  organization: {
    label: i18n.t('common:filters.member'),
    value: 'organization',
    partialMatchValue: 'organizationName',
  },
  search: {
    label: i18n.t('common:filters.item'),
    value: 'search',
  },
  location: {
    label: i18n.t('common:filters.location'),
    value: 'location',
    removeRelated: {
      commonIdKey: 'placeId',
      relatedFilters: ['postalCode', 'city', 'state', 'country'],
    },
  },
});

export const LOCAL_STORAGE_KEYS = {
  databaseOnboarding: 'SHOW_DATABASE_ONBOARDING',
  manageOnboarding: 'SHOW_MANAGE_ONBOARDING',
  showAddressTooltips: 'SHOW_ADDRESS_TOOLTIPS',
};

export const breakpoints = {
  lg: 991,
  md: 576,
  sm: 360,
};

export const mediaQueries = {
  lg: `@media screen and (max-width: ${breakpoints.lg}px)`,
  md: `@media screen and (max-width: ${breakpoints.md}px)`,
  sm: `@media screen and (max-width: ${breakpoints.sm}px)`,
};

export const CLIENTS = {
  localhost: 'localhost', // local
  sandbox: 'sandbox.app.needslist.co', // sandbox
  test: 'test.app.needslist.co', // sandbox
  demo: 'demo.app.needslist.co', // prod,
  sshco: 'sshco.app.needslist.co', //prod
  volunteernc: 'volunteernc.app.needslist.co', //prod
  ukraine: 'ukraine.needslist.co', //prod-large-001
  ukrainebeta: 'ukrainebeta.app.needslist.co', //prod-large-001
  ukrainesandbox: 'sandbox.ukraine.needslist.co', // prod-01
};

export const sentryEnvironment = () => {
  const domain = window.location.hostname;
  const client = domain.split('.');
  // Disable Sentry for localhost:
  //  - this is consistent with Python configuration
  //  - because Sentry can't access code from localhost, the information is not
  //    as useful
  return client.includes('localhost')
    ? null
    : client.some(
        (cli) => cli === 'test' || cli === 'sandbox' || cli === 'demo',
      )
    ? 'sandbox'
    : 'prod';
};

export const FEATURES = {
  register: 'register',
  invitations: 'invitations',
  footerLinks: 'footer_links',
  hideTestingBanners: 'hide_testing_banners',
  showZendesk: 'show_zendesk',
  mobileAccess: 'mobile_access',
  mapLink: 'map_link',
  showManufacturers: 'show_manufacturers',
  PostOnlyNonProfit: 'post_only_non_profit',
  PostOnlyGoverment: 'post_only_government',
  acceptCustomItems: 'accept_custom_items',
  verifyEmail: 'verify_email',
};

// Value provided by django in production-like environments.
// In dev they won't be replaced since the app is served directly.
// Check if they are not replaced to show generic logo in dev
export const clientLogoURL = window.Needslist.clientLogoURL;
export const SCROLL_Y_SHOW_MODAL = 60;
