import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {ItemPanel} from '../../../components/ItemPanel';
import ProgressIndicator from '../../../components/ProgressIndicator/ProgressIndicator';
import NeedSupplyInformation from '../../../components/ItemClaimForm/NeedSupplyInformation';
import {Title} from '../../../components/OnboardingCarousel';
import {mediaQueries, breakpoints} from '../../../constants';
import Responsive from '../../../components/Responsive';
import Button from '../../../components/Button/Button';

const inOneMonth = new Date();
inOneMonth.setDate(inOneMonth.getDate() + 31);

const Wrapper = styled.div`
  display: flex;

  ${mediaQueries.lg} {
    flex-direction: column-reverse;
  }
`;

const FirstColumn = styled.div`
  flex: 0.7;
  overflow: hidden;

  ${mediaQueries.lg} {
    flex: 1;
    width: 100%;
  }
`;

const SecondColumn = styled.div`
  flex: 0.3;
  margin: 100px 20px 0 0;
  display: flex;
  flex-direction: column;

  ${mediaQueries.lg} {
    flex: 1;
    width: 100%;
    margin: 10px 0 0 0;
  }
`;

const StyledTitle = styled(Title)`
  margin-right: 50px;

  ${mediaQueries.lg} {
    margin: 35px 0 0;
  }
`;

const Text = styled.p`
  margin-right: 100px;

  ${mediaQueries.lg} {
    margin-right: 0;
  }
`;

const FakePanel = styled.div`
  margin: 65px 50px 0 136px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  height: 549px;

  ${mediaQueries.lg} {
    margin: 5px;
    overflow: hidden;
    height: 310px;
  }
`;

const Controls = styled.div`
  margin: auto 0 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  ${mediaQueries.lg} {
    justify-content: flex-start;
    margin: 15px 0 10px;
  }
`;

const Control = styled(Button)`
  &.btn {
    padding: 7px 30px;
  }

  ${mediaQueries.lg} {
    &:first-child {
      margin-right: 10px;
    }
  }
`;

const demoNeed = {
  id: 1,
  name: 'Diapers (S)',
  side: 'need',
  kind: 'supply',
  category: {
    name: 'Baby Items',
  },
  organizationName: 'Helpful Organization',
  issueName: 'Landslide',
  issueLocation: {
    place: 'Eastern Uganda',
  },
  quantity: 1500,
  claimedQuantity: 100,
  dueDate: inOneMonth,
  brand: 'Pampers',
  isPallet: true,
  user: {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '1234556789',
    timezone: 'Newfoundland (GTM -2:30)',
  },
  tag: [],
  acceptingDeliveriesHoursFrom: '09:00',
  acceptingDeliveriesHoursTo: '17:00',
};

const SecondSlide = (props) => {
  const {t} = useTranslation('onboarding');

  return (
    <Wrapper>
      <FirstColumn>
        <FakePanel>
          <ItemPanel item={demoNeed} showOrganizationLogo title="Need Details">
            <ProgressIndicator
              total={demoNeed.quantity}
              progress={demoNeed.claimedQuantity}
            />
            <NeedSupplyInformation item={demoNeed} />
          </ItemPanel>
        </FakePanel>
        <Responsive>
          <Controls min={breakpoints.lg}>
            <Control variant="outline-primary" onClick={props.onPrevious}>
              {t('previous')}
            </Control>
            <Control variant="outline-primary" onClick={props.onNext}>
              {t('next')}
            </Control>
          </Controls>
        </Responsive>
      </FirstColumn>
      <SecondColumn>
        <StyledTitle>{t('database.second-slide.title')}</StyledTitle>
        <Text>{t('database.second-slide.text')}</Text>
        <Responsive>
          <Controls max={breakpoints.lg}>
            <Control variant="outline-primary" onClick={props.onPrevious}>
              {t('previous')}
            </Control>
            <Control variant="outline-primary" onClick={props.onNext}>
              {t('next')}
            </Control>
          </Controls>
        </Responsive>
      </SecondColumn>
    </Wrapper>
  );
};

export default SecondSlide;
