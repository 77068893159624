import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import styled from 'styled-components';
import {useApi} from '../../contexts/ApiContext';

import Breadcrumbs from '../../components/Layout/Breadcrumbs';
import {usePageStore} from '../../contexts/PageContext';
import OrgInformation from './PendingInformation/OrgInformation';

const Header = styled.div`
  padding: 19px 103px 11px 15px;
  border-bottom: 1px solid #e8e8e8;
`;

const PanelTitle = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
`;

const Wrapper = styled.div`
  margin: 0 15px;
`;

const OrganizationDetails = (props) => {
  const api = useApi();
  const {panelTitle, breadcrumb} = props;
  const {slug} = useParams();
  const [, pageActions] = usePageStore();
  const [org, setOrg] = useState(null);

  const fetchOrg = useCallback(async () => {
    const response = await api.getOrg(slug);
    setOrg(response);
  }, [slug, api]);

  useEffect(() => {
    fetchOrg();
  }, [fetchOrg]);

  useEffect(() => {
    pageActions.setBreadcrumbs({key: breadcrumb});
  }, [pageActions, breadcrumb]);

  if (!org) {
    return null;
  }

  return (
    <React.Fragment>
      <Header>
        <Breadcrumbs />
        <PanelTitle>{panelTitle}</PanelTitle>
      </Header>
      <Wrapper>
        <OrgInformation org={org} />
      </Wrapper>
    </React.Fragment>
  );
};

export default OrganizationDetails;
