import React, {useEffect, useState, useCallback} from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Card, Row, Col} from 'react-bootstrap';

import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';
import {observer} from 'mobx-react';
import {mediaQueries} from '../../constants';
import {usePageStore} from '../../contexts/PageContext';
import {usePaginator} from '../../hooks/paginator';
import {
  INVITE_ADMIN,
  MANAGE_ORGANIZATIONS_PAGE,
  INVITE_ADMIN_SUCCESS,
  MANAGE_ORGANIZATION_DETAILS,
  REQUEST_JOIN_INFORMATION,
  HOME_PAGE,
} from '../../urls';

import inviteLogo from '../../assets/invite-user.png';
import Button from '../../components/Button';
import {Route} from '../../components/Layout';
import InviteForm from '../../components/InviteForm/InviteForm';
import ThankYouModal from '../../components/Modals/ThankYouModal';
import OrganizationList from './OrganizationsList';
import InvitationList from './InvitationList';
import Title from '../../components/Title';
import OrganizationDetails from './OrganizationDetails';
import PendingRequests from './PendingRequests';
import RequestJoinInformation from './RequestJoinInformation';
import Panel from '../../components/Panel';
import {usePanelStore} from '../../contexts/PanelContext';

const PAGE_SIZE = 6;

const Wrapper = styled.div`
  margin-bottom: 180px;

  ${mediaQueries.lg} {
    margin-bottom: 30px;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 15px;
  padding: 10px 15px;
  height: 100%;

  ${mediaQueries.lg} {
    height: auto;
    margin-bottom: 30px;
    padding: 10px 35px 35px;
  } ;
`;

const StyledImg = styled.img`
  ${mediaQueries.lg} {
    width: 100%;
    height: auto;
  } ;
`;

const InviteWrapper = styled.div`
  flex-direction: row;
  display: flex;
  overflow: hidden;

  ${mediaQueries.lg} {
    justify-content: center;
    flex-wrap: wrap;
  } ;
`;

const InviteMembersTitle = styled.h2`
  margin: 50px 15px 15px;
  font-size: 40px;
  line-height: 61px;
  font-family: 'Roboto Slab', serif;

  ${mediaQueries.lg} {
    font-size: 30px;
    margin: 20px 15px 15px;
  } ;
`;

const InviteInnerContainer = styled.div`
  ${mediaQueries.lg} {
    text-align: center;
  } ;
`;

const OrganizationAdmin = () => {
  const api = useApi();
  const [, pageActions] = usePageStore();
  const stores = useStores();
  const {t} = useTranslation(['user', 'thank-you']);
  const history = useHistory();
  const {currentPage} = usePaginator('org_page');
  const [, panelActions] = usePanelStore();
  const {currentPage: invitationPage} = usePaginator('inv_page');
  const [organizations, setOrganizations] = useState([]);
  const [organizationsCount, setOrganizationsCount] = useState(null);
  const [invitations, setInvitations] = useState([]);
  const [invitationsCount, setInvitationsCount] = useState(null);
  const [signUpType, setSignUpType] = useState();
  const [approvalRequired, setApprovalRequired] = useState(false);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [isLoadingPendingUsers, setIsLoadingPendingUsers] = useState();

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'manage-organizations'});
  }, [pageActions]);

  const fetchOrganizations = useCallback(async () => {
    const response = await api.getOrgs({
      page_size: PAGE_SIZE,
      page: currentPage,
    });
    setOrganizations(response.results);
    setOrganizationsCount(response.count);
  }, [currentPage, api]);

  const fetchPendingUsers = useCallback(async () => {
    setIsLoadingPendingUsers(true);
    const response = await api.getOrgUsers({status: 'approval_pending'});
    setPendingUsers(response.results);
    setIsLoadingPendingUsers(false);
  }, [api]);

  const fetchInvitations = useCallback(async () => {
    const response = await api.getInvitations({
      page_size: PAGE_SIZE,
      page: invitationPage,
      invitation_type: 'org_admin',
    });
    setInvitations(response.results);
    setInvitationsCount(response.count);
  }, [invitationPage, api]);

  const fetchClientData = useCallback(async () => {
    const response = await api.getClientData();
    setSignUpType(response.sign_up_type);
    setApprovalRequired(response.approval_required);
  }, [api]);

  useEffect(() => {
    fetchOrganizations();
    fetchInvitations();
    fetchPendingUsers();
    fetchClientData();
  }, [
    fetchOrganizations,
    fetchInvitations,
    fetchPendingUsers,
    fetchClientData,
  ]);

  const handleAddUserClick = () => {
    history.push(INVITE_ADMIN);
    panelActions.setPanelMount(true);
  };

  const handleGoToOrganizationList = () => {
    fetchInvitations();
    history.push(MANAGE_ORGANIZATIONS_PAGE);
  };

  const handleRequestInformation = (user) => {
    const slug_or_id = user.org_slug === '' ? user.organization : user.org_slug;
    history.push(
      REQUEST_JOIN_INFORMATION.replace(':slug', slug_or_id).replace(
        ':userUUID',
        user.uuid,
      ),
    );
    panelActions.setPanelMount(true);
  };

  const handleOnSave = () => history.push(INVITE_ADMIN_SUCCESS);
  const handleOnPanelClose = () => {
    history.goBack();
    fetchPendingUsers();
  };
  const handleRequestJoin = () => {
    history.push(MANAGE_ORGANIZATIONS_PAGE);
    panelActions.setPanelMount(false);
    fetchPendingUsers();
    fetchOrganizations();
  };

  const handleResendInvitation = async (token) => {
    await api.resendInvitation({token, invitationType: 'org_admin'});
    history.push(INVITE_ADMIN_SUCCESS);
  };

  const handleDeleteInvitation = async (token) => {
    await api.deleteInvitation(token);
    fetchInvitations();
  };

  if (!stores.app.user.isAdmin) {
    return <Redirect to={HOME_PAGE} />;
  }

  return (
    <Wrapper>
      <Title>{t('user:titles.manage-organizations')}</Title>
      <OrganizationList
        organizations={organizations}
        count={organizationsCount}
        pageSize={PAGE_SIZE}
        showAdmin={signUpType === 'by_invite' || signUpType === 'both'}
      />
      <Row>
        {approvalRequired && (
          <Col sm={4}>
            <PendingRequests
              onRequestInformation={handleRequestInformation}
              isLoading={isLoadingPendingUsers}
              pendingUsers={pendingUsers}
            />
          </Col>
        )}
        <Col sm={approvalRequired ? 8 : 12}>
          <StyledCard>
            <InviteWrapper>
              <div>
                <StyledImg src={inviteLogo} alt="inviteLogo" />
              </div>
              <InviteInnerContainer>
                <InviteMembersTitle>
                  {t('user:titles.onboard-new-organization')}
                </InviteMembersTitle>
                <Button onClick={handleAddUserClick}>
                  {t('user:buttons.invite-admin')}
                </Button>
              </InviteInnerContainer>
            </InviteWrapper>
          </StyledCard>
        </Col>
      </Row>
      <InvitationList
        invitations={invitations}
        count={invitationsCount}
        pageSize={PAGE_SIZE}
        onResendInvitation={handleResendInvitation}
        onDeleteInvitation={handleDeleteInvitation}
      />
      <Route path={[MANAGE_ORGANIZATION_DETAILS, REQUEST_JOIN_INFORMATION]}>
        <Panel onClose={handleOnPanelClose}>
          <Route path={MANAGE_ORGANIZATION_DETAILS} exact>
            <OrganizationDetails
              panelTitle={t('user:titles.organization-details')}
              breadcrumb="manage-organization-details"
            />
          </Route>
          <Route path={REQUEST_JOIN_INFORMATION} exact>
            <RequestJoinInformation onSave={handleRequestJoin} />
          </Route>
        </Panel>
      </Route>
      <Route path={[INVITE_ADMIN]}>
        <Panel onClose={handleGoToOrganizationList}>
          <Route path={INVITE_ADMIN} exact>
            <InviteForm
              panelTitle={t('user:titles.onboard-new-organization')}
              formTitle={t('user:titles.invite-detail')}
              inviteType="org-admin"
              onSave={handleOnSave}
              breadcrumb="organization-invite-form"
            />
          </Route>
        </Panel>
      </Route>
      <Route path={INVITE_ADMIN_SUCCESS} exact>
        <ThankYouModal
          header={t('thank-you:labels.invite.header')}
          titleMessage={t('thank-you:labels.invite.org-admin')}
          onPrimaryClick={handleGoToOrganizationList}
          primaryButtonText={t('thank-you:buttons.go-to-manage-organizations')}
        ></ThankYouModal>
      </Route>
    </Wrapper>
  );
};

export default observer(OrganizationAdmin);
