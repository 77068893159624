import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {useStores} from '../../contexts/StoresContext';
import Select from './Select';

const UnitSelect = (props) => {
  const stores = useStores();
  const {showBundleUnits, isBeneficiariesUom, ...rest} = props;
  const uoms = stores.app.choices.uom.filter((uom) => uom.kind === props.kind);
  let options = uoms.length === 0 ? stores.app.choices.uom : uoms;
  const {t} = useTranslation('forms');

  if (showBundleUnits) {
    options = options.filter((uom) => uom.is_bundle);
  }

  if (isBeneficiariesUom) {
    options = options.filter((uom) => uom.is_beneficiary);
  }

  const renderOption = (option) => (
    <option value={option.id} key={option.id}>
      {option.name}
    </option>
  );

  return (
    <Select {...rest}>
      {isBeneficiariesUom && <option value="">{t('fields.units')}</option>}
      {options.map(renderOption)}
    </Select>
  );
};

export default observer(UnitSelect);
