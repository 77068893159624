import React from 'react';
import {useField, useFormikContext} from 'formik';
import ChoiceControl from './ChoiceControl';
import Label from '../Helper/Label';
import ErrorMessage from '../Helper/ErrorMessage';
import {useRemoveError} from '../../../hooks/formik';

const Choice = (props) => {
  const [field, meta] = useField(props);
  const {setFieldValue} = useFormikContext();

  const handleOnChange = (e) => {
    const choice = parseInt(e.target.value);

    const value = [];
    if (isNaN(choice)) {
      value.push('');
    } else {
      value.push(choice);
    }

    setFieldValue(field.name, value);
  };

  const {removeError} = useRemoveError(field.name, handleOnChange);

  return (
    <React.Fragment>
      <Label isRequired={props.required}>{props.label}</Label>
      <ChoiceControl
        allowOther={props.allow_other}
        choices={props.choices}
        name={field.name}
        value={field.value}
        onChange={removeError}
        isInvalid={!!meta.error && meta.touched}
      />
      <ErrorMessage forceDisplay>{meta.error}</ErrorMessage>
    </React.Fragment>
  );
};

export default Choice;
