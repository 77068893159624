import React, {useEffect, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Card} from 'react-bootstrap';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import moment from 'moment';

import RadialBarChart from './RadialBarChart';
import {mediaQueries, DATE_FORMAT} from '../../../constants';
import theme from '../../../theme';
import ReportHeader from '../ReportHeader';
import {exportCsv} from '../utils';
import Spinner from '../../../components/Spinner';
import {ReactComponent as EmptyTotalNeeds} from '../../../assets/icons/reports-total-empty-needs.svg';
import {ReactComponent as EmptyTotalOffers} from '../../../assets/icons/reports-total-empty-offers.svg';
import EmptyReport from '../EmptyReport';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  margin-bottom: 15px;
  min-height: 450px;
  ${mediaQueries.lg} {
    margin-bottom: 5px;
  }
`;

const CardTitle = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: #979797;
`;

const SpinnerContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TotalNumber = styled.span`
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 30px;
`;

const ChartsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: stretch;

  ${mediaQueries.lg} {
    flex-direction: column;

    & > *:first-child {
      margin-bottom: 20px;
    }
  }
`;

const colors = {
  need: [theme.java, theme.watercourse],
  offer: [theme.rajah, theme.clementine],
};

const TotalProgressReport = (props) => {
  const {
    from,
    to,
    title,
    getData,
    exportData,
    filterByMember,
    filename,
    formatNumber,
    showProgress,
    currency,
  } = props;
  const [totalNumbers, setTotalNumbers] = useState();
  const {t} = useTranslation('reports');
  const dateRange = useMemo(
    () => ({
      from: moment(from).format(DATE_FORMAT),
      to: moment(to).format(DATE_FORMAT),
    }),
    [from, to],
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTotalNumbers = async () => {
      const responseTotalNumbers = await getData({
        ...dateRange,
        currency_id: currency ? currency.id : undefined,
        org_id: filterByMember,
      });
      setTotalNumbers(responseTotalNumbers);
      setIsLoading(false);
    };
    fetchTotalNumbers();
    setIsLoading(true);
  }, [dateRange, getData, filterByMember, currency]);

  const handleExport = async () => {
    const response = await exportData({
      ...dateRange,
      currency_id: currency ? currency.id : undefined,
      org_id: filterByMember,
    });
    exportCsv(response, `${filename}_${Date.now()}.csv`);
  };

  return (
    <React.Fragment>
      <ReportHeader subtitle={title} onButtonClick={handleExport} />
      <StyledCard>
        <CardTitle>{t('labels.needs-met')}</CardTitle>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <React.Fragment>
            <TotalNumber>
              {formatNumber(
                totalNumbers.needs.supply.met + totalNumbers.needs.service.met,
                currency,
              )}
            </TotalNumber>
            <ChartsContainer>
              {(totalNumbers.needs.supply.posted === 0 &&
                totalNumbers.needs.service.posted === 0) ||
              (!showProgress &&
                totalNumbers.needs.supply.met === 0 &&
                totalNumbers.needs.service.met === 0) ? (
                <EmptyReport icon={EmptyTotalNeeds} />
              ) : (
                <React.Fragment>
                  <RadialBarChart
                    title={showProgress ? t('labels.supply-needs') : ''}
                    data={totalNumbers.needs.supply}
                    color={colors.need[0]}
                    footer={
                      showProgress
                        ? t('labels.posted-met-needs')
                        : t('labels.supply-needs-met')
                    }
                    formatNumber={formatNumber}
                    currency={currency}
                    showProgress={showProgress}
                  />
                  <RadialBarChart
                    title={showProgress ? t('labels.service-needs') : ''}
                    data={totalNumbers.needs.service}
                    color={colors.need[1]}
                    footer={
                      showProgress
                        ? t('labels.posted-met-needs')
                        : t('labels.service-needs-met')
                    }
                    formatNumber={formatNumber}
                    currency={currency}
                    showProgress={showProgress}
                  />
                </React.Fragment>
              )}
            </ChartsContainer>
          </React.Fragment>
        )}
      </StyledCard>
      <StyledCard>
        <CardTitle>{t('labels.offers-claimed')}</CardTitle>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <React.Fragment>
            <TotalNumber>
              {formatNumber(
                totalNumbers.offers.supply.met +
                  totalNumbers.offers.service.met +
                  totalNumbers.offers.information.met,
                currency,
              )}
            </TotalNumber>
            <ChartsContainer>
              {totalNumbers.offers.supply.posted === 0 &&
              totalNumbers.offers.service.posted === 0 &&
              totalNumbers.offers.information.met === 0 ? (
                <EmptyReport icon={EmptyTotalOffers} />
              ) : (
                <React.Fragment>
                  <RadialBarChart
                    title={t('labels.supply-offers')}
                    data={totalNumbers.offers.supply}
                    color={colors.offer[0]}
                    footer={t('labels.posted-claimed-offers')}
                    formatNumber={formatNumber}
                    currency={currency}
                  />
                  <RadialBarChart
                    title={t('labels.service-offers')}
                    data={totalNumbers.offers.service}
                    color={colors.offer[1]}
                    footer={t('labels.posted-claimed-offers')}
                    formatNumber={formatNumber}
                    currency={currency}
                  />
                  <RadialBarChart
                    title={t('labels.information-offers')}
                    data={totalNumbers.offers.information}
                    footer={t('labels.information-offers-claimed')}
                    formatNumber={formatNumber}
                    currency={currency}
                    showProgress={false}
                  />
                </React.Fragment>
              )}
            </ChartsContainer>
          </React.Fragment>
        )}
      </StyledCard>
    </React.Fragment>
  );
};

export default observer(TotalProgressReport);
