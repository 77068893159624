import React, {useState, useEffect} from 'react';
import {Card} from 'react-bootstrap';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import {mediaQueries} from '../../constants';
import Carousel from '../Carousel';
import Member from '../Member';
import {useApi} from '../../contexts/ApiContext';
import {useIsMobile} from '../../hooks/utils';
import {MEMBER_PAGE} from '../../urls';

const CAROUSEL_HEIGHT = 260;
const SPACE_BETWEEN_ITEMS = 20;

const Wrapper = styled(Card)`
  padding: 26px 20px 26px 30px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
`;

const StyledMember = styled(Member)`
  flex-grow: 0;
  width: 329px;
  height: ${CAROUSEL_HEIGHT}px;

  ${mediaQueries.lg} {
    flex: 0.8 0 auto;
    width: 100%;
    margin: 10px 0;

    &:first-child {
      margin-left: 0;
    }

    &.card {
      min-width: 250px;
    }
  }
`;

const RelatedMembers = (props) => {
  const {issueId} = props;
  const api = useApi();
  const {t} = useTranslation('related-members');
  const history = useHistory();
  const [orgs, setOrgs] = useState([]);
  const [numberOfCards, setNumberOfCards] = useState(3);
  const isMobile = useIsMobile();

  useEffect(() => {
    isMobile ? setNumberOfCards(1) : setNumberOfCards(3);
  }, [isMobile]);

  useEffect(() => {
    const fetchRelatedOrgs = async () => {
      const response = await api.getIssueRelatedOrgs(issueId);
      setOrgs(response.results);
    };

    fetchRelatedOrgs();
  }, [issueId, api]);

  const handleMemberClick = (member) => {
    history.push(MEMBER_PAGE.replace(':slug', member.slug));
  };

  const renderOrgs = (org) => {
    return (
      <StyledMember key={org.id} member={org} onClick={handleMemberClick} />
    );
  };

  return (
    <Wrapper className={props.className}>
      <Title>{t('carousel.title')}</Title>
      {orgs.length ? (
        <Carousel
          numberOfCards={numberOfCards}
          carouselHeight={CAROUSEL_HEIGHT}
          spaceBetweenItems={SPACE_BETWEEN_ITEMS}
        >
          {orgs.map(renderOrgs)}
        </Carousel>
      ) : (
        <div>{t('carousel.no-issue-found')}</div>
      )}
    </Wrapper>
  );
};

export default RelatedMembers;
