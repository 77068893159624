const STORAGE = window.localStorage;
const STORAGE_KEY_TOKEN = 'nl:v1:token';

export function setLocalToken(token) {
  if (!token) {
    STORAGE.removeItem(STORAGE_KEY_TOKEN);
  } else {
    STORAGE.setItem(STORAGE_KEY_TOKEN, token);
  }
}

export function getLocalToken() {
  return STORAGE.getItem(STORAGE_KEY_TOKEN);
}
