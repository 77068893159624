import React, {useEffect, useState, useRef, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useStores} from '../../contexts/StoresContext';
import IdleTimer from '../../utils/idleTimer';
import SessionExpirationModal from '../SessionExpirationModal';
import styles from './SessionExpirationTimer.module.scss';

const SessionExpirationTimer = () => {
  const stores = useStores();
  const history = useHistory();
  const [showExpirationTimerModal, setShowExpirationTimerModal] =
    useState(false);
  const sessionExpirationTime = 120; // modal time is measured in seconds
  const [countdown, setCountdown] = useState(sessionExpirationTime);
  const countdownIntervalRef = useRef(0);
  const clearCountdownInterval = () =>
    clearInterval(countdownIntervalRef.current);

  const handleOnConfirm = () => {
    clearCountdownInterval();
    setCountdown(sessionExpirationTime);
    setShowExpirationTimerModal(false);
  };

  const handleSessionExpiration = useCallback(() => {
    setShowExpirationTimerModal(true);
  }, []);

  useEffect(() => {
    if (showExpirationTimerModal) {
      countdownIntervalRef.current = setInterval(() => {
        if (countdownIntervalRef.current && countdown > 0) {
          setCountdown(countdown - 1);
        }
      }, 1000);
    }

    return clearCountdownInterval;
  }, [countdown, showExpirationTimerModal]);

  useEffect(() => {
    if (countdown === 0) {
      stores.app.logout();
      window.location.replace('/login?inactivitySessionExpire=true');
    }
  }, [countdown, history, stores.app]);

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 600, // timeout is measured in seconds
      onTimeout: handleSessionExpiration,
    });

    return () => {
      timer.cleanUp();
    };
  }, [history, handleSessionExpiration]);

  return (
    <div className={styles.container}>
      {showExpirationTimerModal && (
        <SessionExpirationModal
          handleOnConfirm={handleOnConfirm}
          countdown={countdown}
        />
      )}
    </div>
  );
};

export default SessionExpirationTimer;
