import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useApi} from '../contexts/ApiContext';
import {useStores} from '../contexts/StoresContext';

export function useLanguages() {
  const api = useApi();
  const {i18n} = useTranslation();
  const history = useHistory();
  const stores = useStores();

  const setLanguage = useCallback(
    async (lang) => {
      if (lang) {
        const languages = await api.getLanguages();
        const exists = languages.find((language) => language.code === lang);
        if (exists) {
          i18n.changeLanguage(lang);
          stores.app.setLanguage(lang);
        }
      }
    },
    [api, i18n, stores.app],
  );

  const setLanguageByQueryParam = useCallback(() => {
    const queryStringParams = new URLSearchParams(history.location.search);
    const language = queryStringParams.get('language');
    setLanguage(language);
    return language;
  }, [history.location.search, setLanguage]);

  return {
    setLanguageByQueryParam,
    setLanguage,
  };
}
