class IdleTimer {
  constructor({timeout, onTimeout}) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.eventHandler = this.updateExpireTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpireTime();
    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem('_expiredTime'), 10);
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
        }
      }
    }, 1000);
  }

  updateExpireTime() {
    localStorage.setItem('_expiredTime', Date.now() + this.timeout * 1000);
  }

  tracker() {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  }

  cleanUp() {
    clearInterval(this.interval);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }
}

export default IdleTimer;
