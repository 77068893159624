import React from 'react';
import {useField} from 'formik';
import {useRemoveError, useTranslatedError} from '../../hooks/formik';
import Select from '../Form/Select';
import Label from '../Form/Label';
import ReadOnlyField from './ReadOnlyField';

const SelectField = (props) => {
  const [field, meta] = useField(props);
  const {label, readOnly, options, isRequired, ...rest} = props;
  const error = useTranslatedError(meta.error);
  const {removeError} = useRemoveError(field.name, field.onChange);

  if (readOnly) {
    if (!field.value) return null;

    const selectedOption = options.find((option) => option.id === field.value);
    return <ReadOnlyField label={label} value={selectedOption.label} />;
  }

  return (
    <React.Fragment>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <Select
        {...field}
        {...rest}
        options={options}
        error={error}
        isInvalid={!!meta.error && meta.touched}
        onChange={removeError}
      >
        {props.children}
      </Select>
    </React.Fragment>
  );
};

export default SelectField;
