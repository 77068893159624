import React from 'react';

import useCountries from '../../hooks/countries';

import CountriesPills from '../../components/Pills/CountriesPills';

const IssueCountryFilter = (props) => {
  const countries = useCountries(props.allIssuesCountries);

  return (
    <CountriesPills
      pills={countries}
      selected={props.selectedCountries}
      onClick={props.onCountrySelect}
    />
  );
};

export default IssueCountryFilter;
