import React from 'react';
import {useField, useFormikContext} from 'formik';
import moment from 'moment';

import CustomDatePicker from '../Form/CustomDatePicker';
import Label from '../Form/Label';
import {useTranslatedError} from '../../hooks/formik';

const DatePickerField = (props) => {
  const [field, meta] = useField(props);
  const {setFieldValue} = useFormikContext();
  const {label, ...rest} = props;
  const error = useTranslatedError(meta.error) || meta.error;

  const handleOnChange = (date) => {
    if (date) {
      const momentDate = moment(date);
      if (momentDate.isValid()) {
        setFieldValue(field.name, momentDate.endOf('day').format('YYYY-MM-DD'));
      }
    } else {
      setFieldValue(field.name, '');
    }
  };

  return (
    <React.Fragment>
      {label && <Label>{label}</Label>}
      <div>
        <CustomDatePicker
          {...rest}
          error={error}
          isInvalid={!!meta.error && meta.touched}
          selected={field.value ? moment(field.value).toDate() : ''}
          onChange={handleOnChange}
        />
      </div>
    </React.Fragment>
  );
};

export default DatePickerField;
