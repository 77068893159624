import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import Pills from '../../components/Pills/Pills';
import {useApi} from '../../contexts/ApiContext';
import {mediaQueries} from '../../constants';

const StyledPills = styled(Pills)`
  ${mediaQueries.lg} {
    flex-wrap: nowrap;
    align-content: center;
    white-space: nowrap;
    overflow-y: auto;
  }
`;

const MembersAlphabeticFilter = (props) => {
  const api = useApi();
  const [organizationInitials, setOrganizationInitials] = useState([]);
  const alphabet = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];

  useEffect(() => {
    const fetchOrganizationInitials = async () => {
      const orgInitials = await api.getOrgInitials();
      setOrganizationInitials(orgInitials);
    };

    fetchOrganizationInitials();
  }, [api]);

  return (
    <StyledPills
      pills={alphabet}
      availableOptions={organizationInitials}
      selected={props.selectedInitials}
      onClick={props.onInitialSelect}
    />
  );
};

export default MembersAlphabeticFilter;
