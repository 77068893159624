import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Row, Col, Container} from 'react-bootstrap';

import {useApi} from '../../../contexts/ApiContext';
import {useStores} from '../../../contexts/StoresContext';
import {useFormatNumber, useLocaleNumber} from '../../../hooks/number';
import theme from '../../../theme';

import Modal from '../../../components/Modals/Modal';
import ActivityDetailTable from './ActivityDetailTable';
import ProgressIndicator from '../../../components/ProgressIndicator/ProgressIndicator';
import PieChart from '../TrendReports/PieChart';
import {capitalize} from '../../../utils/string';
import {activityDetailApiParams} from '../../../components/ItemReports/filterUtils';

const StyledModal = styled(Modal)`
  .modal-content {
    min-height: 522px;
  }
`;

const StyledContainer = styled(Container)`
  background: #fafafa;
  padding: 20px;
  margin: 20px auto;
  border-radius: 8px;
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Kind = styled(({isOffer, ...rest}) => <div {...rest} />)`
  font-weight: 600;
  text-transform: uppercase;
  color: ${(props) =>
    props.isOffer ? theme.secondaryColor : theme.primaryColor};
  line-height: 1rem;
`;

const MuttedText = styled.p`
  margin: 0;
  font-size: 12px;
  color: #979797;
`;

const Name = styled(Kind)`
  text-transform: none;
  color: #000;
`;

const Amount = styled.p`
  margin: 0;
  text-align: right;
  font-size: 20px;
`;

const Current = styled.span`
  font-size: 30px;
`;

const ActivityDetail = ({onClose, filters}) => {
  const api = useApi();
  const stores = useStores();
  const {currency} = stores.app;
  const {itemId, side} = useParams();
  const {t} = useTranslation(['reports', 'item']);
  const formatNumber = useFormatNumber();
  const localeNumber = useLocaleNumber();
  const [item, setItem] = useState(null);
  const [isLoadingClaims, setIsLoadingClaims] = useState(true);
  const [claimedItems, setClaimedItems] = useState([]);
  const [claimItemsCount, setClaimItemsCount] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [header, setHeader] = useState(t('titles.breadcrumb', {side: ''}));

  useEffect(() => {
    const fetchClaimedItem = async () => {
      const response = await api.getItem(itemId);
      setItem(response);
    };

    const fetchClaimedItems = async () => {
      const response = await api.getClaimItems(itemId, {
        currency_id: currency.id,
        source: 'reports',
        ...activityDetailApiParams(filters),
      });
      setClaimedItems(response.results);
      setClaimItemsCount(response.count);
      setIsLoadingClaims(false);
    };

    setHeader(t('titles.breadcrumb', {side: capitalize(side)}));
    fetchClaimedItem();
    fetchClaimedItems();
  }, [currency, itemId, side, t, filters, api]);

  useEffect(() => {
    const amountByStatus = (status) => {
      if (claimedItems) {
        return claimedItems.reduce((total, entry) => {
          return entry.confirmationStatus.key === status
            ? total + entry.quantity
            : total;
        }, 0);
      }

      return 0;
    };

    const data = [
      {
        name: t('reports:status.fulfilled'),
        value: amountByStatus('fulfilled'),
      },
      {
        name: t('reports:status.partially-fulfilled'),
        value: amountByStatus('partially_fulfilled'),
      },
      {
        name: t('reports:status.submitted'),
        value: amountByStatus('submitted'),
      },
      {
        name: t('reports:status.rejected'),
        value: amountByStatus('rejected'),
      },
    ];
    const checkData = data.find((item) => item.value !== 0);

    setChartData(checkData ? data : []);
  }, [claimedItems, t]);

  if (!item) return null;
  const itemType = item.itemType || 'default';

  return (
    <StyledModal onClose={onClose} headerTitle={header}>
      <StyledContainer fluid>
        <Row className="justify-content-between">
          <StyledCol sm={6}>
            <Kind isOffer={item.side === 'offer'}>
              {t(`item:title.${item.side}.${item.kind}.${itemType}`)}
            </Kind>
            <MuttedText>{item.category.name}</MuttedText>
            <Name>{item.name}</Name>
          </StyledCol>
          <StyledCol sm={3}>
            <Amount>
              <Current>{`${localeNumber(item.claimedQuantity)}`}</Current>
              {` / ${
                item.kind !== 'information'
                  ? localeNumber(item.quantity)
                  : t('reports:labels.unlimited')
              }`}
            </Amount>
            <ProgressIndicator
              progress={item.claimedQuantity}
              total={item.quantity}
              hideQuantity
              uomFulfillment={item.uomFulfillment}
            />
          </StyledCol>
        </Row>
      </StyledContainer>
      {item.kind !== 'information' && (
        <Container fluid className="mt-5 mb-5">
          <PieChart
            data={chartData}
            isLoading={isLoadingClaims}
            formatNumber={formatNumber}
          />
        </Container>
      )}
      <ActivityDetailTable
        items={claimedItems}
        count={claimItemsCount}
        isLoading={isLoadingClaims}
      />
    </StyledModal>
  );
};

export default ActivityDetail;
