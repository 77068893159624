import React, {useCallback, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route as ReactRoute,
  useHistory,
} from 'react-router-dom';
import Login from './pages/Login';
import Manage from './pages/Manage';
import {AppContainer, Route} from './components/Layout';
import TopBar from './components/TopBar';
import Footer from './components/Footer';
import {Page} from './components/Layout';
import ZenDesk from './components/ZenDesk';
import SessionExpirationTimer from './components/SessionExpirationTimer';
import IssuePage from './pages/IssuePage';
import IssueResponse from './pages/IssueResponse';
import Home from './pages/Home';
import BrowseIssues from './pages/BrowseIssues';
import Database from './pages/Database/Database';
import StaticFilePage from './pages/StaticFilePage';
import UserProfile from './pages/UserProfile';
import AddNeedsForm from './pages/AddItems/AddNeedsForm';
import AddOffersForm from './pages/AddItems/AddOffersForm';
import {StoresProvider, useStores} from './contexts/StoresContext';
import {SessionProvider} from './contexts/SessionContext';
import {ApiProvider} from './contexts/ApiContext';
import {PanelProvider} from './contexts/PanelContext';
import {SearchAlgoliaProvider} from './contexts/SearchAlgoliaContext';
import {MapAlgoliaProvider} from './contexts/MapAlgoliaContext';
import {ToastProvider} from 'react-toast-notifications';
import {observer} from 'mobx-react';
import {getClient} from './utils/features';
import BrowseMembers from './pages/BrowseMembers';
import MemberPage from './pages/MemberPage';
import MapPage from './pages/MapPage';
import Onboarding from './pages/Onboarding';
import UserAdmin from './pages/UserAdmin';
import OrganizationAdmin from './pages/OrganizationAdmin';
import UserInvitation from './pages/UserInvitation';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import ReportsPage from './pages/ReportsPage/ReportsPage';
import ItemReportsPage from './pages/ItemReportsPage/ItemReportsPage';
import ItemTemplateAdmin from './pages/ItemTemplateAdmin';
import ItemTemplateEdit from './pages/ItemTemplateEdit';
import CustomItemEdit from './pages/CustomItemEdit/CustomItemEdit';
import IssueTemplateAdmin from './pages/IssueTemplateAdmin';
import IssueTemplateEdit from './pages/IssueTemplateEdit';
import * as URLS from './urls';
import ApiLoaderWrapper from './components/AppWrapper';
import Spinner from './components/Spinner';
import {Auth0Provider} from '@auth0/auth0-react';
import TagManager from 'react-gtm-module';
import {StepperProvider} from './contexts/StepperContext';
import EmailVerifiedPage from './pages/Login/EmailVerifiedPage';

const Auth0ProviderWithHistory = ({children}) => {
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={window.AUTH0_DOMAIN}
      clientId={window.AUTH0_CLIENT_ID}
      redirectUri={window.location.origin + '/login'}
      onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};

const App = observer((props) => {
  const stores = useStores();
  const {user, isPublic, loaded, refreshing, language, clientData} = stores.app;
  const client = getClient();

  useEffect(() => {
    if (!clientData || !clientData?.gtm_code) {
      return;
    }
    const tagManagerArgs = {
      gtmId: `GTM-${clientData.gtm_code}`,
    };

    TagManager.initialize(tagManagerArgs);
  }, [clientData]);

  const renderContainer = useCallback(() => {
    const lang = user?.language.code || language;
    if (!loaded || refreshing || !lang) {
      return (
        <div className="d-flex justify-content-center align-items-center h-100 ">
          <Spinner />
        </div>
      );
    }
    return (
      <ApiLoaderWrapper lang={lang}>
        <Switch>
          <ReactRoute index>
            <AppContainer>
              <ZenDesk />
              <TopBar />
              {user && client !== 'localhost' && <SessionExpirationTimer />}
              <Switch>
                <Route
                  path={URLS.LOGIN_PAGE}
                  exact
                  component={Login}
                  isPublic
                />
                <Route
                  path={URLS.SIGNUP_PAGE}
                  exact
                  component={Login}
                  isPublic
                />
                <Route
                  path={URLS.INVITATION_PAGE}
                  component={UserInvitation}
                  isPublic
                />
                <Route
                  path={URLS.EMAIL_VALIDATION_PAGE}
                  component={EmailVerifiedPage}
                  isPublic
                />
                <Page path={URLS.SEARCH_PAGE} isPublic isSearchPage>
                  <Database />
                </Page>
                <Page path={URLS.MANAGE_PAGE}>
                  <Manage />
                </Page>
                <Page useSmallContainer path={URLS.ADD_NEEDS}>
                  <AddNeedsForm />
                </Page>
                <Page useSmallContainer path={URLS.ADD_OFFERS}>
                  <AddOffersForm />
                </Page>
                <Page useSmallContainer path={URLS.ISSUE_RESPONSE_PAGE}>
                  <IssueResponse />
                </Page>
                <Page path={URLS.ISSUE_PAGE} isPublic isSearchPage>
                  <IssuePage />
                </Page>
                <Page path={URLS.BROWSE_ISSUES_PAGE}>
                  <BrowseIssues />
                </Page>
                <Route path={URLS.MEMBER_PAGE}>
                  <MemberPage />
                </Route>
                <Page path={URLS.BROWSE_MEMBERS_PAGE}>
                  <BrowseMembers />
                </Page>
                <Page path={URLS.ONBOARDING_PAGE} isOnboarding>
                  <StepperProvider>
                    <Onboarding />
                  </StepperProvider>
                </Page>
                <Page path={URLS.MANAGE_ADMIN_PAGE}>
                  <UserAdmin />
                </Page>
                <Page path={URLS.MANAGE_ORGANIZATIONS_PAGE}>
                  <OrganizationAdmin />
                </Page>
                <Page exact path={URLS.MANAGE_ITEMS_PAGE}>
                  <ItemTemplateAdmin />
                </Page>
                <Page exact path={URLS.MANAGE_USER_POST_EDIT_PAGE}>
                  <CustomItemEdit />
                </Page>
                <Page exact path={URLS.MANAGE_ITEM_CATALOG_EDIT_PAGE}>
                  <ItemTemplateEdit />
                </Page>
                <Page exact path={URLS.MANAGE_ISSUES_PAGE}>
                  <IssueTemplateAdmin />
                </Page>
                <Page exact path={URLS.MANAGE_ISSUES_EDIT_PAGE}>
                  <IssueTemplateEdit />
                </Page>
                <Page path={URLS.USER_PROFILE}>
                  <UserProfile />
                </Page>
                <Page path={URLS.REPORTS_PAGE}>
                  <ReportsPage />
                </Page>
                <Page path={URLS.ITEM_REPORTS_PAGE}>
                  <ItemReportsPage />
                </Page>
                <Route path={URLS.MAP_PAGE_V1} isPublic>
                  <MapPage />
                </Route>
                <Route
                  path={URLS.RESET_PASSWORD_PAGE}
                  component={ResetPassword}
                  isPublic
                />
                <Route
                  path={URLS.FORGOT_PASSWORD_PAGE}
                  component={ForgotPassword}
                  isPublic
                />
                <Route
                  path={URLS.PRIVACY_PAGE}
                  component={StaticFilePage}
                  isPublic
                />
                <Route
                  path={URLS.TOS_PAGE}
                  component={StaticFilePage}
                  isPublic
                />
                <Route
                  path={URLS.HELP_PAGE}
                  component={StaticFilePage}
                  isPublic
                />
                <Route
                  path={URLS.FAQ_PAGE}
                  component={StaticFilePage}
                  isPublic
                />
                <Route
                  path={URLS.STATIC_FILE_PAGE}
                  component={StaticFilePage}
                  isPublic
                />
                <Page path={URLS.HOME_PAGE}>
                  <Home user={user} />
                </Page>
              </Switch>
            </AppContainer>
            {!user && !isPublic ? null : <Footer />}
          </ReactRoute>
        </Switch>
      </ApiLoaderWrapper>
    );
  }, [loaded, refreshing, user, isPublic, client, language]);

  if (
    stores.app.clientData &&
    stores.app.clientData.authentication_type === 'auth0'
  ) {
    return (
      <Router>
        <Auth0ProviderWithHistory>
          <ToastProvider>{renderContainer()}</ToastProvider>
        </Auth0ProviderWithHistory>
      </Router>
    );
  }
  return (
    <Router>
      <ToastProvider>{renderContainer()}</ToastProvider>
    </Router>
  );
});

const AppWrapper = (props) => {
  return (
    <StoresProvider>
      <SessionProvider>
        <ApiProvider>
          <PanelProvider>
            <MapAlgoliaProvider>
              <SearchAlgoliaProvider>
                <App />
              </SearchAlgoliaProvider>
            </MapAlgoliaProvider>
          </PanelProvider>
        </ApiProvider>
      </SessionProvider>
    </StoresProvider>
  );
};

export default AppWrapper;

// @FIXME NL-1598 (verify the padding is not a problem)
/**
 * Add padding top so it doesn't collide with the nav bar
 */
// function patchToastContainer(current) {
//   const ToastContainerOriginal = current.components.ToastContainer;
//
//   function ToastContainerPatched(props) {
//     const Container = ToastContainerOriginal(props);
//     Container.props.css.top = '60px';
//     return Container;
//   }
//   current.components.ToastContainer = ToastContainerPatched;
// }
