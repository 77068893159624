import React, {useEffect} from 'react';
import styled from 'styled-components';

import {Container} from 'react-bootstrap';
import Toast from '../Toast';
import ScrollOnPageChange from './ScrollOnPageChange';
import Layout from './Layout';
import Breadcrumbs from './Breadcrumbs';
import {PageProvider} from '../../contexts/PageContext';
import {mediaQueries, Z_INDEX} from '../../constants';
import Route from './Route';
import {useStores} from '../../contexts/StoresContext';
import {observer} from 'mobx-react';

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin: 13px 0 0 30px;

  ${mediaQueries.lg} {
    margin: 18px 15px;
  }
`;

const StyledContainer = styled(({useSmallContainer, ...rest}) => (
  <Container {...rest} />
))`
  position: relative;
  z-index: ${Z_INDEX.overlay};
  &.container {
    max-width: 1300px;
  }
  @media (min-width: 1200px) {
    &.container {
      ${(props) => (props.useSmallContainer ? 'max-width: 955px;' : '')}
    }
  }
`;

const Page = (props) => {
  const stores = useStores();
  const {
    useSmallContainer,
    component: Component,
    isPublic,
    isSearchPage,
    ...rest
  } = props;
  const isPublicPage = isPublic && !stores.app.user;

  useEffect(() => {
    stores.app.togglePublicPage(isPublicPage);
  }, [isPublicPage, stores.app]);

  const children = (
    <Layout>
      <Toast />
      {!isPublicPage && <StyledBreadcrumbs showOrganizationName />}
      {stores.app.clientData.use_search_v2 && isSearchPage ? (
        <>{props.children}</>
      ) : (
        <StyledContainer useSmallContainer={useSmallContainer}>
          {props.children}
        </StyledContainer>
      )}
    </Layout>
  );

  return (
    <Route isPublic={isPublic} {...rest}>
      <ScrollOnPageChange>
        <PageProvider>{children}</PageProvider>
      </ScrollOnPageChange>
    </Route>
  );
};

export default observer(Page);
