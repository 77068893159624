import React from 'react';
import styled from 'styled-components';
import {Form} from 'react-bootstrap';

const StyledForm = styled(Form.Label)`
  font-weight: bold;
`;

const StyledLabel = styled.span`
  color: red;
`;

const Label = (props) => {
  const requiredLabel = props.isRequired ? <StyledLabel>*</StyledLabel> : null;
  return (
    <React.Fragment>
      {props.children && (
        <StyledForm>
          {props.children} {requiredLabel}
        </StyledForm>
      )}
    </React.Fragment>
  );
};

export default Label;
