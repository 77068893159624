import React from 'react';
import styled from 'styled-components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Feedback from 'react-bootstrap/Feedback';

const ErrorIcon = styled(FontAwesomeIcon)`
  margin-right: 7px;
`;

const StyledFeedback = styled(Feedback)`
  &.invalid-feedback {
    display: block;
  }
`;

const ErrorMessage = (props) => {
  return (
    <React.Fragment>
      {props.children && (
        <StyledFeedback type="invalid">
          <ErrorIcon icon="exclamation-triangle" /> {props.children}
        </StyledFeedback>
      )}
    </React.Fragment>
  );
};

export default ErrorMessage;
