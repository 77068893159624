import React from 'react';
import {useField, useFormikContext} from 'formik';
import Label from '../Form/Label';
import ReadOnlyField from './ReadOnlyField';
import TimeInput from '../Form/TimeInput';
import {useTranslatedError} from '../../hooks/formik';

const TimeInputField = (props) => {
  const [field, meta] = useField(props);
  const {setFieldValue} = useFormikContext();
  const error = useTranslatedError(meta.error);
  const {label, readOnly, onChange, inlineLabel, ...rest} = props;
  const handleCustomChange = (value) => {
    setFieldValue(field.name, value);
  };

  if (readOnly) {
    if (!field.value) return null;

    const value = getReadOnlyValue(
      field.value,
      inlineLabel,
      rest.inlineLabelPosition,
    );

    return <ReadOnlyField label={label} value={value} />;
  }

  return (
    <React.Fragment>
      {label && <Label>{label}</Label>}
      <TimeInput
        {...field}
        {...rest}
        error={error}
        onChange={onChange || handleCustomChange}
        isInvalid={!!meta.error && meta.touched}
        inlineLabel={inlineLabel}
      />
    </React.Fragment>
  );
};

function getReadOnlyValue(value, inlineLabel, labelPosition = 'right') {
  if (!inlineLabel) return value;

  return `${labelPosition === 'left' ? inlineLabel : ''} ${value} ${
    labelPosition === 'right' ? inlineLabel : ''
  }`;
}

export default TimeInputField;
