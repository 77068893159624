import React from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Card, Button} from 'react-bootstrap';
import styled, {ThemeProvider} from 'styled-components';

import ItemHeader from './ItemHeader';
import ItemLocation from './ItemLocation';
import ProgressIndicator from '../ProgressIndicator';
import OrganizationAward from './OrganizationAward';

import theme from '../../theme';
import styles from './Item.module.scss';

const itemTheme = {
  need: {
    valid: {
      borderColor: theme.primaryColor,
      borderStyle: 'solid',
      headerBorderBottomWidth: '1px',
    },
    invalid: {
      borderColor: theme.danger,
      headerBorderBottomWidth: '1px',
    },
    reduced: {
      borderColor: theme.primaryColor,
      borderStyle: 'dashed',
      minHeight: 'auto',
      height: 'auto',
      borderBottom: '0',
    },
  },
  offer: {
    valid: {
      borderColor: theme.secondaryColor,
      borderStyle: 'dashed',
      headerBorderBottomWidth: '0',
      minHeight: '76px',
      height: '100px',
      borderBottom: '1px solid #E8E8E8',
    },
    invalid: {
      borderColor: theme.danger,
      headerBorderBottomWidth: '0',
      minHeight: '76px',
      height: '100px',
      borderBottom: '1px solid #E8E8E8',
    },
    reduced: {
      borderColor: theme.secondaryColor,
      borderStyle: 'dashed',
      minHeight: 'auto',
      height: 'auto',
      borderBottom: '0',
    },
  },
};

// just for animation without distort the card
const InnerWrapper = styled.div`
  padding: 15px;
  flex-grow: 1;
  border-radius: 13px;
  animation: ${(props) =>
    props.isEdited ? 'changeColorAnimation 3s linear' : ''};

  @keyframes changeColorAnimation {
    0% {
      border-color: ${theme.success};
    }
    100% {
      border-color: transparent;
    }
  }
`;

const Wrapper = styled(({pointerCursor, ...rest}) => <Card {...rest} />)`
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.05);
  cursor: ${(props) => (props.pointerCursor ? 'pointer' : 'default')};

  .card-header {
    background-color: transparent;
    min-height: ${(props) => props.theme.minHeight};
    border-bottom-width: ${(props) => props.theme.headerBorderBottomWidth};
    padding: 0 0 20px 0;
  }

  .card-body {
    padding: 20px 0;
  }

  &.card {
    margin-top: 20px;
    border-color: ${(props) => props.theme.borderColor};
    border-style: ${(props) => props.theme.borderStyle};
  }
`;

const Header = styled(Card.Header)`
  ${(props) => props.theme.height};

  &.card-header {
    border-bottom: ${(props) => props.theme.borderBottom};
  }
`;

const Body = styled(Card.Body)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.card-body {
    justify-content: flex-start;
    padding: 15px auto 10px;
  }
`;

const StyledItemLocation = styled(ItemLocation)`
  margin-bottom: 5px;
`;

const ItemButton = styled(Button)`
  width: 100%;
  margin-bottom: 9px;

  &.btn {
    border-width: 1px;
  }
`;

const Item = (props) => {
  const {item, hideOrganizationName, showPendingDelivery} = props;
  const {t} = useTranslation('search');
  const location = useLocation();
  const isOffer = item.side === 'offer';
  const hideProgressIndicator =
    item.kind === 'discount' ||
    item.kind === 'information' ||
    props.hideProgressIndicator;
  const isInvalid = !props.hideErrors && item.status === 'incomplete';
  const isEdited =
    props.showEdited && location.state && location.state.itemId === item.id;
  const currentTheme = isInvalid
    ? itemTheme[item.side].invalid
    : props.isReduced
    ? itemTheme[item.side].reduced
    : itemTheme[item.side].valid;
  const onWrapperClick = props.onClick ? props.onClick : props.onButtonClick;
  const buttonVariant = isOffer ? 'outline-secondary' : 'outline-primary';
  const isExpired = item.status === 'expired';
  const isDone = item.status === 'done';
  const disabledCTAStatus = isExpired ? 'expired' : isDone ? item.status : null;
  const showPending =
    item.kind === 'information' || item.kind === 'discount'
      ? false
      : showPendingDelivery;
  const itemButton =
    props.buttonText && !isExpired && !isDone ? (
      <ItemButton
        variant={buttonVariant}
        className="text-uppercase"
        disabled={isExpired}
        size="sm">
        {props.buttonText}
      </ItemButton>
    ) : (
      disabledCTAStatus && (
        <div className={styles.itemStatus}>
          {t(`cards.messages.${disabledCTAStatus}.${item.side}`)}
        </div>
      )
    );

  return (
    <ThemeProvider theme={currentTheme}>
      <Wrapper
        onClick={!isExpired && !isDone ? onWrapperClick : null}
        className={props.className}
        pointerCursor={!!(!isExpired && !isDone && itemButton)}>
        <InnerWrapper isEdited={isEdited}>
          <Header>
            <ItemHeader
              showPendingDelivery={showPending}
              isCard
              item={item}
              menuItems={props.menuItems}
            />
          </Header>
          {!props.isReduced && (
            <Body>
              {item.issueLocation && (
                <StyledItemLocation
                  location={item.issueLocation}
                  issueName={item.issueName}
                  extendLocation={isOffer}
                />
              )}
              {item.organizationName && !hideOrganizationName && (
                <OrganizationAward>{item.organizationName}</OrganizationAward>
              )}
              {!hideProgressIndicator && (
                // @REMOVE old bundle
                <ProgressIndicator
                  total={item.quantity || 0}
                  progress={item.claimedQuantity}
                  uomFulfillment={item.uomFulfillment}
                />
              )}
            </Body>
          )}
          {itemButton && <div className={styles.footer}>{itemButton}</div>}
        </InnerWrapper>
      </Wrapper>
    </ThemeProvider>
  );
};

export default Item;
