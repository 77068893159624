import React from 'react';
import {useField, useFormikContext} from 'formik';

import {useRemoveError, useTranslatedError} from '../../hooks/formik';

import Label from '../Form/Label';
import ReadOnlyField from './ReadOnlyField';
import Radio from '../Form/Radio';

const RadioField = (props) => {
  const [field, meta] = useField(props);
  const {setFieldValue} = useFormikContext();
  const error = useTranslatedError(meta.error);
  const {
    label,
    options,
    onChange,
    booleanOptions,
    readOnly,
    isRequired,
    ...rest
  } = props;
  const handleBooleanCustomOnChange = (e) => {
    setFieldValue(field.name, e.target.value === 'true');
  };
  const customChange = booleanOptions
    ? handleBooleanCustomOnChange
    : field.onChange;
  const {removeError} = useRemoveError(field.name, onChange || customChange);

  if (readOnly) {
    if (field.value === null) return null;

    const selectedOption = options.find(
      (option) => option.value === field.value,
    );

    if (selectedOption === undefined) return null;

    return (
      <React.Fragment>
        <ReadOnlyField label={label} value={selectedOption.label} />
        {selectedOption.renderOptionContent &&
          selectedOption.renderOptionContent()}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <Radio
        {...field}
        {...rest}
        error={error}
        onChange={removeError}
        options={options}
        isInvalid={!!meta.error && meta.touched}
      />
    </React.Fragment>
  );
};

export default RadioField;
