import React from 'react';
import {useTranslation} from 'react-i18next';
import {Card} from 'react-bootstrap';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import {getInitials} from '../../utils';
import {Route} from '../../components/Layout';
import Avatar from '../../components/Avatar';
import {ReactComponent as PencilIcon} from '../../assets/icons/pencil.svg';
import {ReactComponent as GearIcon} from '../../assets/icons/gear.svg';
import {ReactComponent as LockIcon} from '../../assets/icons/lock.svg';
import {
  USER_PROFILE,
  USER_PROFILE_EDIT_PROFILE,
  USER_PROFILE_CHANGE_PASSWORD,
  USER_PROFILE_MOBILE_ACCESS,
} from '../../urls';
import Button from '../../components/Button';
import {isFeatureEnabled} from '../../utils/features';
import {FEATURES} from '../../constants';
import {useStores} from '../../contexts/StoresContext';

const StyledCard = styled(Card)`
  margin-bottom: 15px;
  padding: 40px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserName = styled.p`
  margin-top: 15px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
`;

const UserInfo = styled.p`
  color: #93928c;
  margin-bottom: 0;
  font-size: 14px;
`;

const ProfileActions = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const ActionLink = styled(Link)`
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
  margin: 10px 10px;
`;

const ActionText = styled.span`
  color: #1877f2;
  font-size: 14px;
  margin-right: 5px;
`;

const UserCard = (props) => {
  const stores = useStores();
  const {user, isOwnProfile, onToggleUserStatus} = props;
  const {t} = useTranslation('user');
  const userFullName = `${user.first_name || ''} ${user.last_name || ''}`;
  const showMobileAccess = isFeatureEnabled(
    FEATURES.mobileAccess,
    stores.app.features,
  );
  const showChangePassword =
    stores.app.clientData.authentication_type === 'built-in';

  const userNameInitials = getInitials(userFullName);
  // TODO: update to also use user photo
  const userImage = userNameInitials;

  const activateButton =
    user.status === 'active' || user.status === 'inactive' ? (
      <Button variant="link" onClick={onToggleUserStatus}>
        <ActionText>
          {user.status === 'active'
            ? t('buttons.deactivate-user')
            : t('buttons.activate-user')}
        </ActionText>
        <GearIcon />
      </Button>
    ) : null;

  return (
    <StyledCard data-testid="userCard">
      <Avatar>{userImage}</Avatar>
      <UserName>{userFullName}</UserName>
      <UserInfo>{user.organization_role}</UserInfo>
      <UserInfo>{user.email}</UserInfo>
      <Route exact path={USER_PROFILE}>
        <ProfileActions>
          {isOwnProfile ? (
            <React.Fragment>
              <ActionLink
                to={USER_PROFILE_EDIT_PROFILE.replace(':userUUID', user.uuid)}
              >
                <ActionText>{t('buttons.edit-profile')}</ActionText>
                <PencilIcon />
              </ActionLink>
              {showChangePassword && (
                <ActionLink
                  to={USER_PROFILE_CHANGE_PASSWORD.replace(
                    ':userUUID',
                    user.uuid,
                  )}
                >
                  <ActionText>{t('buttons.change-password')}</ActionText>
                  <LockIcon />
                </ActionLink>
              )}
              {showMobileAccess && (
                <ActionLink
                  to={USER_PROFILE_MOBILE_ACCESS.replace(
                    ':userUUID',
                    user.uuid,
                  )}
                >
                  <ActionText>{t('buttons.mobile-access')}</ActionText>
                </ActionLink>
              )}
            </React.Fragment>
          ) : (
            activateButton
          )}
        </ProfileActions>
      </Route>
    </StyledCard>
  );
};

export default UserCard;
