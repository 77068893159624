import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import {Col, Row} from 'react-bootstrap';

import ItemDataTitle from '../ItemForm/ItemDataTitle';
import ItemDataValue from '../ItemForm/ItemDataValue';
import {getMeetBy} from './utils';
import UndisclosedField from './UndisclosedField';

const OfferInformationInformation = (props) => {
  const {item} = props;
  const {t} = useTranslation('forms');

  return (
    <React.Fragment>
      {item.dueDate && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>
              {t('labels.discount.offer.item-available-until')}
            </ItemDataTitle>
            <ItemDataValue>{getMeetBy(item)}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.description && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.item-description')}</ItemDataTitle>
            <ItemDataValue withoutTextStyle>{item.description}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.organization.phone_number && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.phone-number')}</ItemDataTitle>
            <UndisclosedField item={item} field="phone-number" />
          </Col>
        </Row>
      )}
      {item.organization.email && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.email')}</ItemDataTitle>
            <UndisclosedField item={item} field="email" />
          </Col>
        </Row>
      )}
      {item.contactName && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.contact-name')}</ItemDataTitle>
            <ItemDataValue>{item.contactName}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.user && item.user.timezone && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.timezone')}</ItemDataTitle>
            <ItemDataValue>{item.user.timezone}</ItemDataValue>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default observer(OfferInformationInformation);
