import React, {useRef, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Label from '../Form/Label';
import Button from '../Button';
import styles from './Share.module.scss';

const ShareModal = (props) => {
  const {link = window.location.href} = props;
  const [copied, setCopied] = useState(false);
  const urlRef = useRef(null);
  const {t} = useTranslation('common');

  const handleCopyToClipboard = (e) => {
    urlRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopied(true);
  };

  useEffect(() => {
    const id = setTimeout(() => setCopied(false), 2000);
    return () => clearTimeout(id);
  }, [copied]);

  return (
    <div className={styles.inputContainer}>
      <Label>{t('share.modal.share-link')}</Label>
      <div className={styles.innerWrapper}>
        <input
          ref={urlRef}
          value={link}
          readOnly
          className={styles.customInput}
        />
        <Button
          variant="outline-primary"
          size="sm"
          onClick={handleCopyToClipboard}>
          {t('share.modal.copy-link')}
        </Button>
      </div>
      <div className={styles.copiedMsgWrapper}>
        {copied && (
          <span className={styles.copiedMsg}>
            {t('share.modal.copied-clipboard')}
          </span>
        )}
      </div>
    </div>
  );
};

export default ShareModal;
