import React, {useEffect, useState, useCallback} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {usePageStore} from '../../contexts/PageContext';
import Dialog from '../Dialog';
import Title from '../Title';
import Accordion from './Accordion';
import Button from '../Button';
import styles from './TemplateEdit.module.scss';

const TemplateEdit = ({
  breadcrumbsKey,
  getItemById,
  title,
  kind,
  updateTranslationStatus,
  previousPage,
}) => {
  const {t} = useTranslation(['forms', 'common']);
  const [item, setItem] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const {id} = useParams();
  const history = useHistory();
  const [, pageActions] = usePageStore();

  const getItem = useCallback(
    async (id) => {
      const item = await getItemById(id);
      setItem(item);
    },
    [getItemById],
  );

  const handleConfirm = () => {
    setShowDialog(false);
    getItem(id);
  };

  useEffect(() => {
    pageActions.setBreadcrumbs({key: breadcrumbsKey});
  }, [pageActions, breadcrumbsKey]);

  useEffect(() => {
    getItem(id);
  }, [id, getItem]);

  const itemLanguagesCode =
    item &&
    Object.keys(item.name).filter(
      (key) =>
        key !== 'status' &&
        key !== 'need_auto_translate' &&
        key !== 'original_language' &&
        key !== item.name.original_language,
    );

  return (
    <div className={styles.wrapper}>
      <Title>{t(`user:titles.manage-${kind}s`)}</Title>
      {item && (
        <>
          <div className={styles.titleContainer}>
            <h4>{title}</h4>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => history.push(previousPage)}
            >
              {t('forms:buttons.go-back')}
            </Button>
          </div>
          <div className={styles.originalLanguage}>
            <div>
              <h5>{t('forms:labels.original-language')}</h5>
              <span>{item.name['original_language']}</span>
            </div>
            <div>
              <h5>
                {t('forms:fields.name')} ({item.name['original_language']})
              </h5>
              <span>{item.name[item.name['original_language']]}</span>
            </div>
          </div>
          <div className={styles.table}>
            <div className={styles.tableHeaders}>
              <span>{t('common:language')}</span>
              <span>{t('common:translation')}</span>
              <span>{t('common:status')}</span>
            </div>
            {itemLanguagesCode.map((key) => (
              <Accordion
                id={id}
                key={key}
                langCode={key}
                item={item}
                status={item.name.status[key]}
                updateTranslationStatus={updateTranslationStatus}
                setShowDialog={setShowDialog}
              />
            ))}
          </div>
        </>
      )}
      {showDialog && (
        <Dialog
          header={t('common:modal.header.success')}
          title={
            kind === 'item'
              ? t('common:modal.title.item-updated')
              : t('common:modal.title.issue-updated')
          }
          checkIcon
          editModal={false}
          confirmText={t('common:buttons.accept')}
          onConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default TemplateEdit;
