import React, {forwardRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import NavigationLink from '../NavigationLink';
import {Dropdown} from 'react-bootstrap';
import styles from './TopBar.module.scss';
import {breakpoints} from '../../constants';
import {useWindowSize} from '../../hooks/utils';

const MenuLink = (props) => {
  const {children, icon, links, to = '#', header, openOn, className} = props;

  const windowSize = useWindowSize();

  const isOpenOnHover = openOn === 'hover' && windowSize.width > breakpoints.lg;
  const isOpenOnClick = openOn === 'click' || windowSize.width < breakpoints.lg;

  const DropdownToggle = forwardRef((props, ref) => {
    const options = {};

    if (isOpenOnClick) {
      options.onClick = props.onClick;
    }

    return (
      <div
        ref={ref}
        className={`${styles.contentWrapper} ${styles.dropdownToggle}`}
        {...options}
      >
        {icon && <div className={styles.icon}>{icon}</div>}

        {header && header()}

        {links && (
          <div className={styles.dropdownButton}>
            {children}
            <FontAwesomeIcon className={styles.caretIcon} icon="caret-down" />
          </div>
        )}
      </div>
    );
  });

  return links ? (
    <Dropdown
      className={
        className
          ? `${styles.dropdownContainer} ${className}`
          : `${styles.dropdownContainer}`
      }
    >
      <Dropdown.Toggle as={DropdownToggle} />
      <Dropdown.Menu
        show={isOpenOnHover}
        className={`${styles.dropdownMenu} ${
          isOpenOnHover ? styles.dropdownHover : ''
        } dropdown-menu-right`}
      >
        {links
          .filter((link) => link.show)
          .map((link, index) => {
            const {label, icon} = link;

            const options = {};

            if (link.to) {
              options.to = link.to;
            } else if (link.onClick) {
              options.to = '#';
              options.onClick = link.onClick;
            }

            return (
              <NavigationLink
                className={styles.dropdownLink}
                key={index}
                {...options}
              >
                {icon}
                {label}
              </NavigationLink>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <NavigationLink to={to} onClick={props.onClick}>
      <div className={styles.contentWrapper}>
        {icon && <div className={styles.icon}>{icon}</div>}
        {children}
      </div>
    </NavigationLink>
  );
};

export default MenuLink;
