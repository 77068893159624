import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Card, Tab} from 'react-bootstrap';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import moment from 'moment';

import Tabs from '../../../components/Tabs';
import Subtitle from '../../../components/Subtitle';
import {mediaQueries, DATE_FORMAT} from '../../../constants';
import PieChart from './PieChart';
// import Dropdown from './Dropdown';
import ReportHeader from '../ReportHeader';
import {capitalize} from '../../../utils/string';

const StyledCard = styled(Card)`
  padding: 0 0 40px;

  .card-header-tabs {
    border-bottom: 1px solid #e8e8e8;
    margin: 0;
    padding-bottom: 0;
  }

  ${mediaQueries.lg} {
    margin-bottom: 5px;
  }
`;

const ChartFilter = styled(Subtitle)`
  font-size: 22px;
  margin: 20px 30px 5px;
  ${mediaQueries.lg} {
    margin: 20px 0;
    display: flex;
    justify-content: center;
  }
`;

const TrendReport = (props) => {
  const {getData, formatNumber, from, to, currency, filterByMember} = props;
  const {t} = useTranslation('reports');
  const FILTERS = [
    {
      value: 'category',
      label: t('filters.category'),
    },
    {
      value: 'issue',
      label: t('filters.issue'),
      // TODO: remove when endpoint is done
      disabled: true,
    },
    {
      value: 'location',
      label: t('filters.location'),
      // TODO: remove when endpoint is done
      disabled: true,
    },
  ];
  const TABS = [
    {
      value: 'offer',
      tabTitle: t('tabs.headers.our-offers'),
      chartTitle: t('titles.offers-posted-by'),
    },
    {
      value: 'need',
      tabTitle: t('tabs.headers.our-needs'),
      chartTitle: t('titles.needs-posted-by'),
      disabled: !!currency,
    },
  ];
  const [side, setSide] = useState(TABS[0].value);
  // const [filter, setFilter] = useState();
  const [trendData, setTrendData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const filter = FILTERS[0].value;

  useEffect(() => {
    const fetchTrend = async () => {
      const responseTrend = await getData({
        side,
        filter,
        currency_id: currency ? currency.id : undefined,
        org_id: filterByMember,
        from: moment(from).format(DATE_FORMAT),
        to: moment(to).format(DATE_FORMAT),
      });
      setTrendData(responseTrend);
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchTrend();
  }, [getData, side, filter, from, to, filterByMember, currency]);

  const tabMap = ({value, tabTitle, chartTitle, disabled}) => {
    if (disabled) return null;

    return (
      <Tab key={value} eventKey={value} title={tabTitle}>
        <ChartFilter>
          {`${chartTitle} ${capitalize(filter)}`}
          {/* comment this until the api for others filters is ready */}
          {/* &nbsp; */}
          {/* <Dropdown items={FILTERS} currentValue={filter} onChange={(value) => setFilter(value)} /> */}
        </ChartFilter>
        <PieChart
          data={trendData}
          isLoading={isLoading}
          formatNumber={formatNumber}
          currency={currency}
        />
      </Tab>
    );
  };

  return (
    <React.Fragment>
      <ReportHeader subtitle={t('titles.trend-reports')} />
      <StyledCard>
        <Tabs
          mountOnEnter
          transition={false}
          onSelect={(value) => setSide(value)}
          activeKey={side}
        >
          {TABS.map(tabMap)}
        </Tabs>
      </StyledCard>
    </React.Fragment>
  );
};

export default observer(TrendReport);
