import React, {useCallback, useRef, useEffect, useState, useMemo} from 'react';
import {useHistory, useRouteMatch, Redirect} from 'react-router-dom';
import {GoogleMap, Marker, MarkerClusterer} from '@react-google-maps/api';
import styles from './MapContainer.module.scss';
import {useMapContainer} from '../../contexts/MapContainerContext';
import {useSearchStore} from '../../contexts/SearchContext';
import marker from '../../assets/marker.png';
import markerSelected from '../../assets/marker-selected.png';
import markerManufacturer from '../../assets/manufacturer-marker.png';
import markerManufacturerSelected from '../../assets/manufacturer-marker-selected.png';
import {useApi} from '../../contexts/ApiContext';
import ExampleTooltip from '../ExampleTooltip';
import {useSession} from '../../../../contexts/SessionContext';
import {usePanelStore} from '../../../../contexts/PanelContext';
import {usePaginator} from '../../../../hooks/paginator';
import Spinner from '../../../../components/Spinner';
import {LOGIN_PAGE} from '../../../../../src/urls';
import {useSearchIndexApi} from '../../../../hooks/search-index';
import {
  CITY_ZOOM_LEVEL,
  DEFAULT_ZOOM,
  DEFAULT_CENTER,
  ZOOM_LEVEL_MIN,
  ZOOM_LEVEL_MAX,
} from '../../../SearchPage/constants';

const MARKER_SIZE = {
  width: 45,
  height: 52,
  height_selected: 66,
  width_selected: 60,
};

const MARKER_CLUSTER_STYLE = {
  width: 45,
  height: 45,
  url: marker,
  textColor: 'white',
  textSize: 16,
  fontFamily: 'Roboto',
};

const MARKER_CLUSTER_STYLE_MANUFACTURER = {
  width: MARKER_SIZE.width,
  height: MARKER_SIZE.height,
  url: markerManufacturer,
  textColor: 'white',
  textSize: 16,
  fontFamily: 'Roboto',
};

const MINIMUM_CLUSTER_SIZE = 2;
const MINIMUM_CLUSTER_SIZE_MANUFACTURER = 2;

const GRID_SIZE = 1;
const GRID_SIZE_MANUFACTURER = 1;

const MapContainer = () => {
  const api = useApi();
  const [mapContainerState, mapContainerActions] = useMapContainer();
  const [session] = useSession();
  const [searchState, searchActions] = useSearchStore();
  const [, panelActions] = usePanelStore();
  const history = useHistory();
  const match = useRouteMatch();
  const [matchedPresences, setMatchedPresences] = useState([]);
  const searchIndexApi = useSearchIndexApi();
  const {search} = searchIndexApi;
  const clientData = session.data.client_data;

  const [mapping, setMapping] = useState([]);

  const ref = useRef(null);

  const handleMapLoaded = useCallback(
    (map) => {
      ref.current = map;
      mapContainerActions.setMap(ref);
    },
    [mapContainerActions],
  );

  const [center, zoom] = useMemo(() => {
    const organization = session.data.organization;
    const ipLocation = session.locationByIp;

    if (
      clientData &&
      clientData.map_default_location &&
      clientData.map_default_zoom
    ) {
      return [
        clientData.map_default_location.location,
        clientData.map_default_zoom,
      ];
    } else if (organization && organization.location.location) {
      const [lat, lng] = organization.location.location.split(',');
      const zoom = organization.location.city ? CITY_ZOOM_LEVEL : DEFAULT_ZOOM;
      return [{lat: parseFloat(lat), lng: parseFloat(lng)}, zoom];
    } else if (ipLocation.location) {
      const [lat, lng] = ipLocation.location.split(',');
      return [{lat: parseFloat(lat), lng: parseFloat(lng)}, DEFAULT_ZOOM];
    } else {
      return [DEFAULT_CENTER, DEFAULT_ZOOM];
    }
  }, [session, clientData]);

  const [markerIcon, markerManufacturerIcon] = useMemo(() => {
    const markerSizeLabelOrigin = new window.google.maps.Point(
      MARKER_SIZE.width / 2,
      MARKER_SIZE.height,
    );
    const markerSize = new window.google.maps.Size(
      MARKER_SIZE.width,
      MARKER_SIZE.height,
    );

    const selectedMarkerSizeLabelOrigin = new window.google.maps.Point(
      MARKER_SIZE.width_selected / 2,
      MARKER_SIZE.height_selected,
    );
    const selectedMarkerSize = new window.google.maps.Size(
      MARKER_SIZE.width_selected,
      MARKER_SIZE.height_selected,
    );
    return [
      {
        url: marker,
        labelOrigin: markerSizeLabelOrigin,
        scaledSize: markerSize,
      },
      {
        url: markerSelected,
        labelOrigin: selectedMarkerSizeLabelOrigin,
        scaledSize: selectedMarkerSize,
      },
      {
        url: markerManufacturer,
        labelOrigin: markerSizeLabelOrigin,
        scaledSize: markerSize,
      },
      {
        url: markerManufacturerSelected,
        labelOrigin: selectedMarkerSizeLabelOrigin,
        scaledSize: selectedMarkerSize,
      },
    ];
  }, []);

  useEffect(() => {
    mapContainerActions.setCenter(center);
    mapContainerActions.setZoom(zoom);
  }, [center, zoom, mapContainerActions]);

  const {currentPage} = usePaginator();

  const getItemsIds = useCallback(
    async (presences) => {
      let result = [];

      const promises = presences.map(async (presence) => {
        try {
          const items_response = await search(searchState.textFilter, {
            models: ['needslist_item.Item'],
            metaSide: searchState.metaSide,
            exactMatch: true,
            org_id: presence.org_id,
          });
          const ids = items_response.map((x) => x.id);
          result.push(...ids);
        } catch (error) {
          console.error('Error', error);
        }
        return result;
      });

      const results = await Promise.all(promises);
      return [...results][0];
    },
    [search, searchState.textFilter, searchState.metaSide],
  );

  const searchPresences = useCallback(
    async (presences, page) => {
      let presencesData = {ids: await getItemsIds(presences)};
      searchActions.setOrganizationCount(presences.length);
      searchActions.setPresencesLoading(true);

      const response = await api.getPresenceDetails(presencesData, page);

      searchActions.setPresencesLoading(false);
      searchActions.setPresencesCount(response.count);
      searchActions.setPresences(response.results);
    },
    [api, searchActions, getItemsIds],
  );

  const viewDetails = useCallback(
    (hit) => {
      setMatchedPresences(hit);
      history.push(`${match.url}/details`);
      panelActions.setPanelMount(true);
    },
    [history, match.url, panelActions],
  );

  useEffect(() => {
    matchedPresences.length && searchPresences(matchedPresences, currentPage);
  }, [currentPage, searchPresences, matchedPresences]);

  const renderMarker = (isManufacturer) => (clusterer) => (hit) => {
    return (
      <Marker
        key={hit.objectID}
        onClick={() => {
          viewDetails([hit]);
        }}
        onLoad={(marker) => {
          setMapping((prevState) => [[hit, marker], ...prevState]);
        }}
        onUnmount={(marker) => {
          setMapping((prevState) =>
            [...prevState].filter((tuple) => marker !== tuple[1]),
          );
        }}
        clusterer={clusterer}
        icon={isManufacturer ? markerManufacturerIcon : markerIcon}
        position={hit._geoloc}
      />
    );
  };

  const handleClickMarkerCluster = useCallback(
    (cluster) => {
      const markers = cluster.getMarkers();
      if (markers.length >= MINIMUM_CLUSTER_SIZE) {
        const hits = mapping
          .filter((tuple) => markers.indexOf(tuple[1]) !== -1)
          .map((tuple) => tuple[0]);
        viewDetails(hits);
      }
    },
    [mapping, viewDetails],
  );

  if (!session.data.token && clientData.map_access !== 'public_view') {
    return <Redirect to={LOGIN_PAGE} />;
  }

  if (!session.data.client_data) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <GoogleMap
        onLoad={handleMapLoaded}
        center={mapContainerState.center}
        zoom={mapContainerState.zoom}
        onBoundsChanged={() => {
          const bounds = ref.current.getBounds();
          mapContainerActions.setBounds(bounds);
        }}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          maxZoom: ZOOM_LEVEL_MAX,
          minZoom: ZOOM_LEVEL_MIN,
          styles: [
            {
              featureType: 'poi.business',
              stylers: [{visibility: 'off'}],
            },
            {
              featureType: 'transit',
              elementType: 'labels.icon',
              stylers: [{visibility: 'off'}],
            },
          ],
        }}
        mapContainerClassName={styles.container}>
        {!searchState.isLoading && (
          <React.Fragment>
            <MarkerClusterer
              minimumClusterSize={MINIMUM_CLUSTER_SIZE}
              gridSize={GRID_SIZE}
              styles={[MARKER_CLUSTER_STYLE]}
              onClick={handleClickMarkerCluster}>
              {(clusterer) => {
                return (
                  <React.Fragment>
                    {searchState.results
                      .filter(
                        (presence) =>
                          presence.model !== 'needslist_iop.Manufacturer',
                      )
                      .map(renderMarker(false)(clusterer))}
                  </React.Fragment>
                );
              }}
            </MarkerClusterer>
            <MarkerClusterer
              minimumClusterSize={MINIMUM_CLUSTER_SIZE_MANUFACTURER}
              gridSize={GRID_SIZE_MANUFACTURER}
              styles={[MARKER_CLUSTER_STYLE_MANUFACTURER]}
              onClick={handleClickMarkerCluster}>
              {(clusterer) => {
                return (
                  <React.Fragment>
                    {searchState.results
                      .filter(
                        (presence) =>
                          presence.model === 'needslist_iop.Manufacturer',
                      )
                      .map(renderMarker(true)(clusterer))}
                  </React.Fragment>
                );
              }}
            </MarkerClusterer>
          </React.Fragment>
        )}
        <ExampleTooltip map={ref} markerIcon={markerIcon} />
      </GoogleMap>
    </React.Fragment>
  );
};

export default MapContainer;
