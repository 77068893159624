import React from 'react';
import {useField, useFormikContext} from 'formik';
import Label from '../Helper/Label';
import ErrorMessage from '../Helper/ErrorMessage';
import ChoiceMultipleControl from './ChoiceMultipleControl';
import {useRemoveError} from '../../../hooks/formik';

const ChoiceMultiple = (props) => {
  const [field, meta] = useField(props);
  const {setFieldValue} = useFormikContext();

  const handleOnChange = (e) => {
    const choice = parseInt(e.target.value);
    const checked = e.target.checked;

    let value = [...field.value];
    if (isNaN(choice) && checked) {
      value.push('');
    } else if (isNaN(choice) && !checked) {
      value = value.filter((e) => typeof e !== 'string');
    } else if (checked) {
      value.push(choice);
    } else {
      const index = value.indexOf(choice);
      value.splice(index, 1);
    }

    setFieldValue(field.name, value);
  };

  const {removeError} = useRemoveError(field.name, handleOnChange);

  return (
    <React.Fragment>
      <Label isRequired={props.required}>{props.label}</Label>
      <ChoiceMultipleControl
        allowOther={props.allow_other}
        choices={props.choices}
        name={field.name}
        value={field.value}
        onChange={removeError}
        isInvalid={!!meta.error && meta.touched}
      />
      <ErrorMessage>{meta.error}</ErrorMessage>
    </React.Fragment>
  );
};

export default ChoiceMultiple;
