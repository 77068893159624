import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {mediaQueries} from '../../constants';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaQueries.lg} {
    flex-direction: column;
  }
`;

const IconContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  flex: 1;
  margin-right: 40px;
  ${mediaQueries.lg} {
    margin: 0;
    text-align: center;
  }
`;

const NoDataTitle = styled.p`
  font-weight: bold;
  margin: 0;
`;

const NoDataDescription = styled.p`
  margin: 0;
`;

const EmptyReport = (props) => {
  const {icon: EmptyIcon, message} = props;
  const {t} = useTranslation('reports');

  return (
    <Container>
      <IconContainer>
        <EmptyIcon />
      </IconContainer>
      <TextContainer>
        <NoDataTitle>{t('labels.nothing-to-show')}</NoDataTitle>
        <NoDataDescription>
          {message || t('labels.not-enough-data')}
        </NoDataDescription>
      </TextContainer>
    </Container>
  );
};

export default EmptyReport;
