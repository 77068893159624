import React, {useRef, useEffect, useContext} from 'react';
import {Form} from 'react-bootstrap';
import ErrorMessage from '../../Helper/ErrorMessage';
import FormContext from '../../../Form/FormContext';
import styles from './TextAreaFieldControl.module.scss';

const TextAreaFieldControl = (props) => {
  const {setRef, ...rest} = props;
  const inputRef = useRef(null);
  const {setFieldRef} = useContext(FormContext);

  useEffect(() => {
    setFieldRef(props.name, inputRef);
  }, [props.name, setFieldRef]);

  return (
    <div className={styles.wrapper}>
      <Form.Control ref={inputRef} as="textarea" rows="3" {...rest} />
      <ErrorMessage>{props.error}</ErrorMessage>
    </div>
  );
};

export default TextAreaFieldControl;
