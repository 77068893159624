import React from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import styles from './AddressBoxes.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react';
import {useStores} from '../../../contexts/StoresContext';

const AddressBoxes = ({
  onSelectAddress,
  onDeleteAddress,
  onClickAddress,
  addresses,
  addressSelected,
  isFullWidth = false,
  notAddAddress = false,
  notEditAddress = false,
  notDeleteAddress = false,
  readOnly,
}) => {
  const {t} = useTranslation('add-items');
  const stores = useStores();

  const getDisplayLocationType = (address) => {
    const value = address.location_type;
    const choices = stores.app.choices.addressLocationType || {};
    return choices[value];
  };

  if (readOnly) {
    return (
      <React.Fragment>
        <div
          className={
            isFullWidth
              ? `${styles.addressBoxes}`
              : `d-block ${styles.addressBoxes}`
          }
        >
          <div
            className={`card ${styles.addressBox} ${styles.selected}`}
            name={`${addressSelected.location}`}
          >
            <h4 className={styles.addressBoxTitle}>
              {addressSelected.location_label}
            </h4>
            <p className={styles.addressBoxAddress}>{addressSelected.place}</p>
            <span className={styles.addressBoxBadge}>
              {getDisplayLocationType(addressSelected)}
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }

  const renderAddressBoxes = (address, index) => {
    const selected = addressSelected
      ? addressSelected.id === address.id
        ? styles.selected
        : ''
      : '';

    return (
      <div
        key={`${address.location}${index}`}
        className={`card ${styles.addressBox} ${selected}`}
        name={`${address.location}${index}`}
        onClick={() => {
          onClickAddress(address);
        }}
      >
        <div className={styles.addressBoxTools}>
          {!notEditAddress && (
            <Button variant="link" onClick={() => onSelectAddress(address)}>
              <FontAwesomeIcon icon="pen" size="xs" />
            </Button>
          )}
          {!notDeleteAddress && (
            <Button
              variant="link"
              onClick={(e) => {
                e.stopPropagation();
                onDeleteAddress(address);
              }}
            >
              <FontAwesomeIcon icon="trash" size="xs" />
            </Button>
          )}
        </div>
        <div>
          <h4 className={styles.addressBoxTitle}>{address.location_label}</h4>
          <p className={styles.addressBoxAddress}>{address.place}</p>
        </div>
        <span className={styles.addressBoxBadge}>
          {getDisplayLocationType(address)}
        </span>
      </div>
    );
  };

  return (
    <div
      className={
        isFullWidth
          ? `${styles.addressBoxes}`
          : `d-block ${styles.addressBoxes}`
      }
    >
      {addresses.map(renderAddressBoxes)}
      {!notAddAddress && (
        <div
          className={`card ${styles.addressBoxAdd}`}
          onClick={() => {
            onSelectAddress(null);
          }}
        >
          <div className="card-body">
            <FontAwesomeIcon icon="plus" size="lg" />{' '}
            {t('new-address-box.new-address')}
          </div>
        </div>
      )}
      {addresses.length === 0 && (
        <div className={`card ${styles.addressBoxEmpty}`} />
      )}
    </div>
  );
};

export default observer(AddressBoxes);
