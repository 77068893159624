import React from 'react';
import {Col, Row} from 'react-bootstrap';

import {usePaginator} from '../../hooks/paginator';
import Paginator from '../Paginator';
import styles from './CardsList.module.scss';

const CardsList = (props) => {
  const {data, count, columns, pageSize, paginatorParameterName, moreOptions} =
    props;
  const {changePage, currentPage} = usePaginator(paginatorParameterName);

  const dataMap = (data, index) => {
    const clearColumns = columns.filter((column) => !column.newColumn);

    return (
      <div className={styles.rowData} key={index}>
        <Col sm={11}>
          {clearColumns.map((column, index) => (
            <Row key={index}>
              {column.prefix ? column.prefix() : ''}
              {column.renderCell(data) || '---'}
            </Row>
          ))}
        </Col>
        {moreOptions && <Col>{moreOptions(data)}</Col>}
      </div>
    );
  };

  const handlePageChange = (to, page) => changePage(to, page);

  return (
    <div className={styles.wrapper}>
      {data.map(dataMap)}
      <Row>
        <Col xs={12}>
          <Paginator
            className={styles.cardsListPaginator}
            total={count}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CardsList;
