import React from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Col, Row} from 'react-bootstrap';

import {InputField} from '../../../components/Fields';
import FocusAreaField from './FocusAreaField';

const FPFields = (props) => {
  const {t} = useTranslation('create-account');
  const colSize = props.isFullWidth ? 12 : 6;

  return (
    <React.Fragment>
      <Form.Group as={Row}>
        <Col sm={colSize}>
          <InputField
            label={t('labels.tax-number')}
            name="taxNumber"
            placeholder={t('placeholders.tax-number')}
            isRequired
          />
        </Col>
      </Form.Group>
      {props.clientFocusAreaTags === 'enabled' && (
        <FocusAreaField isRequired isFullWidth={props.isFullWidth} />
      )}
    </React.Fragment>
  );
};

export default FPFields;
