import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Card, Dropdown} from 'react-bootstrap';
import DotMenu from '../../../../components/DotMenu';
import Title from '../../../../components/Title';
import Table from '../../../../components/Table';
import {breakpoints} from '../../../../constants';
import CardsList from '../../../../components/CardsList/CardsList';
import Responsive from '../../../../components/Responsive';
import styles from './UsersInvitationsList.module.scss';

const UsersInvitationsList = (props) => {
  const {invitations, count, pageSize, onResendInvitation, onDeleteInvitation} =
    props;
  const {t} = useTranslation('user');

  const renderMoreOptions = useCallback(
    (invitation) => {
      const handleResendInvitation = () => onResendInvitation(invitation.token);
      const handleDeleteInvitation = () => onDeleteInvitation(invitation.token);

      return (
        <DotMenu>
          <Dropdown.Item onClick={handleResendInvitation}>
            {t('buttons.resend-invitation')}
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDeleteInvitation}>
            {t('buttons.delete-invitation')}
          </Dropdown.Item>
        </DotMenu>
      );
    },
    [t, onResendInvitation, onDeleteInvitation],
  );

  const invitationsHeaders = useMemo(
    () => [
      {
        title: t('columns.invitation.full-name'),
        size: 4,
      },
      {
        title: t('columns.invitation.email'),
        size: 4,
      },
      {
        title: t('columns.invitation.expiration-date'),
        size: 3,
      },
      {
        title: t('columns.invitation.more'),
        size: 1,
      },
    ],
    [t],
  );
  const invitationsColumns = useMemo(
    () => [
      {
        renderCell: (invitation) =>
          `${invitation.first_name} ${invitation.last_name}`,
        size: 4,
      },
      {
        renderCell: (invitation) => invitation.email,
        size: 4,
      },
      {
        renderCell: (invitation) => invitation.expiration_date,
        prefix: () => (
          <span className={styles.boldText}>
            {t('titles.expiration-date')}:&nbsp;
          </span>
        ),
        size: 3,
      },
      {
        renderCell: renderMoreOptions,
        size: 1,
        newColumn: true,
        moreOptions: true,
      },
    ],
    [t, renderMoreOptions],
  );

  return (
    <React.Fragment>
      <Title>{t('titles.manage-invitations')}</Title>
      <Card className={styles.customCard}>
        <h2 className={styles.tableTitle}>
          {t('titles.invitations-list')} ({count})
        </h2>
        <Responsive>
          <Table
            max={breakpoints.md}
            headers={invitationsHeaders}
            columns={invitationsColumns}
            data={invitations}
            count={count}
            paginatorParameterName={'inv_page'}
            pageSize={pageSize}
          />
        </Responsive>
        <Responsive>
          <CardsList
            min={breakpoints.md}
            data={invitations}
            columns={invitationsColumns}
            count={count}
            pageSize={pageSize}
            paginatorParameterName={'inv_page'}
            moreOptions={renderMoreOptions}
          />
        </Responsive>
      </Card>
    </React.Fragment>
  );
};

export default UsersInvitationsList;
