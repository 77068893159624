import React, {useEffect, useCallback, useState} from 'react';
import {useField} from 'formik';
import Label from '../../Form/Label';
import {useApi} from '../../../contexts/ApiContext';
import LocationSelectV2 from '../../../components/LocationSelectV2';
import FeedbackInvalid from '../../Form/FeedbackInvalid';
import styles from './AddressSelectField.module.scss';

const AddressSelectField = (props) => {
  const api = useApi();
  const [field, meta, helpers] = useField(props.name);
  const [itemAddress, setItemAddress] = useState({});
  const {label, readOnly, notAddAddress, notDeleteAddress, notEditAddress} =
    props;

  const {setValue} = helpers;

  const handleAddressSelected = useCallback(
    (address) => {
      if (address) {
        setValue(address.id);
      }
    },
    [setValue],
  );

  useEffect(() => {
    if (field.value) {
      const fetchItemAddress = async () => {
        const itemAddress = await api.getAddress(field.value);
        setItemAddress(itemAddress);
      };
      fetchItemAddress();
    }
  }, [field.value, api]);

  return (
    <div className={styles.addressGroup}>
      {label && <Label>{label}</Label>}
      <LocationSelectV2
        apiCommit
        apiFetch
        isFullWidth={
          typeof props.isFullWidth !== 'undefined' ? props.isFullWidth : true
        }
        addressSelected={itemAddress}
        onAddressSelected={handleAddressSelected}
        readOnly={readOnly}
        notAddAddress={notAddAddress}
        notDeleteAddress={notDeleteAddress}
        notEditAddress={notEditAddress}
      />
      <FeedbackInvalid meta={meta} />
    </div>
  );
};

export default AddressSelectField;
