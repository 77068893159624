import React from 'react';
import styled from 'styled-components';
import {Card, Tab} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

import {mediaQueries, DATE_FORMAT} from '../../../constants';
import Tabs from '../../../components/Tabs';
import ItemsTable from './ItemsTable';
import {useApi} from '../../../contexts/ApiContext';
import ReportHeader from '../ReportHeader';
import {exportCsv} from '../utils';
import moment from 'moment';

const ActivityWrapper = styled(Card)`
  padding: 0;
  min-height: 522px;

  .card-header-tabs {
    border-bottom: 1px solid #e8e8e8;
    margin: 0;
    padding-bottom: 0;
  }

  ${mediaQueries.lg} {
    &.card {
      background-color: transparent;
      border-style: none;
      padding: 0;
    }
  }
`;

const ActivityReport = (props) => {
  const api = useApi();
  const {filterByMember, currency} = props;
  const {t} = useTranslation('reports');

  const tabs = [
    {
      title: t('tabs.needs-posted'),
      eventKey: 'needs-posted',
      type: 'posted',
      side: 'need',
      paginatorParam: 'need-posted',
    },
    {
      title: t('tabs.needs-met'),
      eventKey: 'needs-met',
      type: 'met',
      side: 'need',
      paginatorParam: 'need-met',
    },
    {
      title: t('tabs.offers-posted'),
      eventKey: 'offers-posted',
      type: 'posted',
      side: 'offer',
      paginatorParam: 'offer-posted',
    },
    {
      title: t('tabs.offers-met'),
      eventKey: 'offers-met',
      type: 'met',
      side: 'offer',
      paginatorParam: 'offer-met',
    },
  ];

  const mapTabs = (tab) => (
    <Tab key={tab.title} eventKey={tab.eventKey} title={tab.title}>
      <ItemsTable
        type={tab.type}
        side={tab.side}
        paginatorParam={tab.paginatorParam}
        from={props.from}
        to={props.to}
        filterByMember={filterByMember}
      />
    </Tab>
  );

  const handleExport = async () => {
    const response = await api.getOrgActivityReportsCSV({
      org_id: filterByMember,
      from: moment(props.from).format(DATE_FORMAT),
      to: moment(props.to).format(DATE_FORMAT),
      currency_id: currency.id,
    });
    exportCsv(response, `activity_report_${Date.now()}.csv`);
  };

  return (
    <React.Fragment>
      <ReportHeader
        subtitle={t('titles.activity')}
        onButtonClick={handleExport}
      />
      <ActivityWrapper>
        <Tabs mountOnEnter unmountOnExit>
          {tabs.map(mapTabs)}
        </Tabs>
      </ActivityWrapper>
    </React.Fragment>
  );
};

export default ActivityReport;
