import React, {useEffect} from 'react';
import {observer} from 'mobx-react';

const KindWidget = (props) => {
  useEffect(() => {
    if (props.value !== props.store.kind) {
      props.store.setKind(props.value);
    }
  }, [props.value, props.store]);

  const renderChoices = () => {
    return props.choices.map((choice, index) => {
      const [value, label] = choice;
      return (
        <option key={index} value={value}>
          {label}
        </option>
      );
    });
  };

  const errors = props.errors ? (
    <div
      className={props.errors.getAttribute('class')}
      dangerouslySetInnerHTML={{
        __html: props.errors.innerHTML,
      }}
    />
  ) : null;

  return (
    <React.Fragment>
      <label>{props.label}</label>
      <select
        disabled={props.disabled}
        className={props.className}
        onChange={(event) => {
          props.store.setKind(event.target.value);
        }}
        value={props.store.kind}
        name={props.name}
      >
        {renderChoices()}
      </select>
      {errors}
    </React.Fragment>
  );
};

export default observer(KindWidget);
