import React from 'react';
import {useTranslation} from 'react-i18next';

import Message from './Message';
import {Row, Col} from 'react-bootstrap';
import ItemDataTitle from '../ItemForm/ItemDataTitle';
import ItemDataValue from '../ItemForm/ItemDataValue';
import Claim from '../Claim';
import ResponsiveImg from '../ResponsiveImg';

import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import {Division} from '../ItemPanel';
import styles from './ItemConfirmForm.module.scss';

const ClaimInformation = (props) => {
  const {claim, onBack} = props;
  const {t} = useTranslation(['confirm', 'forms']);
  const stores = useStores();

  const userOrganizationLogo = stores.app.organization.logo;

  const deliveryOptions = {
    ship: t('forms:fields.ship'),
    drop_off: t('forms:fields.drop-off'),
    pick_up: t('forms:fields.pick-up'),
    need_deliver: t('forms:fields.need-deliver'),
  };

  const getDeliveryOptionLabel = (key) => {
    return deliveryOptions[key];
  };

  const renderImage = (image, index) => (
    <div className={styles.thumbnail} key={index}>
      <ResponsiveImg src={image.url} />
    </div>
  );

  return (
    <React.Fragment>
      <h3 className={styles.claimInformationTitle}>
        {t('confirm:donation.title')}
      </h3>
      <Row>
        <Col sm="6">
          <ItemDataTitle>{t('confirm:donation.contact-number')}</ItemDataTitle>
          <ItemDataValue>{claim.phoneNumber}</ItemDataValue>
        </Col>
        <Col sm="6">
          <ItemDataTitle>{t('confirm:donation.delivery-option')}</ItemDataTitle>
          <ItemDataValue>
            {getDeliveryOptionLabel(claim.deliveryOptions)}
          </ItemDataValue>
        </Col>
      </Row>
      {claim.pickupLocation && (
        <Row>
          <Col sm="12">
            <ItemDataTitle>
              {t('confirm:donation.pick-up-location')}
            </ItemDataTitle>
            <ItemDataValue withoutTextStyle>
              {claim.pickupLocation.place}
            </ItemDataValue>
          </Col>
        </Row>
      )}
      <Claim
        className={styles.claim}
        actionText={t('confirm:button.go-back')}
        onActionClick={onBack}
        claim={claim}
      />
      <Division className={styles.division} />
      <div>
        {claim.comment && (
          <Message
            className={styles.message}
            msg={claim.comment}
            date={claim.createdAt}
            senderLogo={claim.organizationLogo}>
            <p>{claim.comment}</p>
          </Message>
        )}
        {claim.confirmationComment && (
          <Message
            className={styles.message}
            msg={claim.confirmationComment}
            date={claim.updatedAt}
            senderLogo={userOrganizationLogo}
            invert>
            <p>{claim.confirmationComment}</p>
            <div className={styles.imagesContainer}>
              {claim.images.map(renderImage)}
            </div>
          </Message>
        )}
      </div>
    </React.Fragment>
  );
};

export default observer(ClaimInformation);
