import React from 'react';
import {useTranslation} from 'react-i18next';
import {useApi} from '../../contexts/ApiContext';
import {MANAGE_ISSUES_PAGE} from '../../urls';
import TemplateEdit from '../../components/TemplateEdit/TemplateEdit';

const IssueTemplateEdit = () => {
  const api = useApi();
  const {t} = useTranslation('forms');

  const getIssueById = (id) => api.getIssueTemplateCatalogById(id);

  const updateTranslationStatus = (id, lang, method, values) =>
    api.updateIssueTranslation(id, lang, method, values);

  return (
    <TemplateEdit
      title={t('titles.edit-issue')}
      kind="issue"
      breadcrumbsKey={'edit-issue'}
      getItemById={getIssueById}
      updateTranslationStatus={updateTranslationStatus}
      previousPage={MANAGE_ISSUES_PAGE}
    />
  );
};

export default IssueTemplateEdit;
