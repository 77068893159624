import React, {useState, forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {useSearchLocationApi} from '../../../../../hooks/search-location';
import {handleSelectFirstOption} from '../../../../../utils/selectFirstOption';
import AsyncComponent from '../../../../../components/AsyncComponent';

const LocationField = forwardRef((props, ref) => {
  const {t} = useTranslation('search');
  const {name, value, setFieldValue, isValid, isInvalid} = props;

  const searchLocationApi = useSearchLocationApi();

  const [results, setResults] = useState([]);
  const [openOptions, setOpenOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const onChange = (selected) => {
    setOpenOptions(false);
    const location = selected.length > 0 ? selected[0] : null;
    setResults(selected);
    if (location) {
      searchLocationApi.getPlace(location.place_id).then((place) => {
        // @HINT add more details to the location in case is need it
        location.place = {
          geometry: place.geometry,
          country: null,
        };
        const addressComponent = place.address_components.filter((c) =>
          c.types.includes('country'),
        );
        if (addressComponent) {
          location.place.country = addressComponent[0].short_name;
        }
        setFieldValue(location);
      });
    } else {
      setFieldValue(null);
    }
  };
  const handleSearch = (text) => {
    if (text.length === 0) {
      return;
    }
    setOpenOptions(true);
    setIsLoading((prevState) => true);
    searchLocationApi
      .search(text)
      .then((results) => {
        setResults(results);
      })
      .catch((err) => setResults([]))
      .finally(() => {
        setIsLoading((prevState) => false);
      });
  };

  return (
    <React.Fragment>
      <AsyncTypeahead
        placeholder={t('search-box.location-placeholder')}
        isValid={isValid}
        isInvalid={isInvalid}
        isLoading={isLoading}
        onKeyDown={(e) =>
          handleSelectFirstOption(e, results, activeIndex, onChange)
        }
        onBlur={(e) =>
          handleSelectFirstOption(e, results, activeIndex, onChange)
        }
        labelKey={(option) => `${option.description}`}
        onSearch={handleSearch}
        allowNew={false}
        id={`location-field-${name}`}
        onChange={onChange}
        selected={value ? [value] : []}
        options={results}
        open={openOptions}>
        {({activeIndex}) => (
          <AsyncComponent
            update={() => {
              setActiveIndex(activeIndex);
            }}
          />
        )}
      </AsyncTypeahead>
    </React.Fragment>
  );
});

export default LocationField;
