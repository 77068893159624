import React from 'react';
import {useTranslation} from 'react-i18next';
import {Switch, useParams, useHistory, useRouteMatch} from 'react-router-dom';
import {MANAGE_PAGE} from '../../urls';
import {usePanelStore} from '../../contexts/PanelContext';
import {Route} from '../Layout';
import Panel from '../Panel';
import MapViewPanel from '../../pages/SearchPage/components/MapViewPanel';
import ItemClaimForm from '../ItemClaimForm';
import ClaimThankYouModal from '../Search/ClaimThankYouModal';
import ThankYouModal from '../Modals/ThankYouModal';

const ClaimFlow = ({onSearch, onClose, currentIssue, isMapView, children}) => {
  const match = useRouteMatch();
  const {slug} = useParams();
  const history = useHistory();
  const {t} = useTranslation('search');
  const [, panelActions] = usePanelStore();

  const handleSave = (item) => {
    panelActions.setPanelMount(false);
    history.push(`${match.url}/item/${item.side}/finish`);
  };

  const handleModalManageClick = () => history.push(MANAGE_PAGE);
  const handleModalSearchClick = () => {
    onSearch && onSearch();
    history.push(match.url);
  };
  const handlePreviousOnClick = () => history.push(match.url);

  const renderItemClaimForm = (props) => {
    return (
      <ItemClaimForm
        onSave={handleSave}
        currentIssue={currentIssue}
        itemId={props.match.params.itemId}
      />
    );
  };

  const PanelComponent = isMapView ? MapViewPanel : Panel;

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path={[`${match.url}/details`, `${match.url}/item/:itemId`]}
          isPublic>
          <PanelComponent onClose={onClose}>
            <Switch>
              <Route
                exact
                path={`${match.url}/item/:itemId`}
                render={renderItemClaimForm}
                isPublic
              />
              {children}
            </Switch>
          </PanelComponent>
        </Route>
        <Route
          exact
          path={`${match.url}/item/:side/finish/`}
          isPublic
          render={() => (
            <ClaimThankYouModal
              onPrimaryClick={handleModalManageClick}
              onSecondaryClick={handleModalSearchClick}
              secondaryButtonText={
                !slug
                  ? t('thank-you:buttons.search')
                  : t('thank-you:buttons.return-current-issue')
              }
            />
          )}
        />
        <Route path={`/search/request-join/finish`} isPublic>
          <ThankYouModal
            titleMessage={t('request-join.success')}
            onPrimaryClick={handlePreviousOnClick}
            primaryButtonText={t('request-join.button')}>
            {t('request-join.message')}
          </ThankYouModal>
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default ClaimFlow;
