import React, {useState} from 'react';
import {useRefinementList} from 'react-instantsearch';
import {useTranslation} from 'react-i18next';
import Radio from '../../../../components/Form/Radio';

export default function StatusRefinements({attribute}) {
  const {t} = useTranslation(['search']);
  const [optionSelected, setOptionSelected] = useState('all');
  const {refine} = useRefinementList({
    attribute: attribute,
    defaultRefinement: [],
  });

  const statusOptions = [
    {
      label: t('filter.open-only'),
      value: 'open-only',
    },
    {
      label: t('filter.include-complete-expired'),
      value: 'all',
    },
  ];

  const handleChangeOption = (event) => {
    const value = event.target.value;
    setOptionSelected(value);
    refine('open');
    refine('in_progress');
  };

  return (
    <Radio
      onChange={handleChangeOption}
      options={statusOptions}
      value={optionSelected}
    />
  );
}
