import {useState, useEffect} from 'react';
import {useFormikContext} from 'formik';

export function useShouldHideField({visible, name}) {
  const shouldHideField = (otherField, visible) => {
    if (otherField.length === 0) return true;

    if (visible.operator === 'equal') {
      return !otherField.some((x) => x === visible.value);
    }

    if (visible.operator === 'not_equal') {
      return !otherField.some((x) => x !== visible.value);
    }

    const otherFieldHasValue = otherField.indexOf(visible.value) === -1;
    return visible.operator === 'in' || visible.operator === 'is'
      ? otherFieldHasValue
      : !otherFieldHasValue;
  };

  const {values, setFieldValue} = useFormikContext();
  const [hideField, setHideField] = useState(false);

  useEffect(() => {
    if (visible) {
      // split the field name, originally='extra_attribute.field_id'
      const fieldName = name.split('.')[1];
      const {hide, operator} = visible.reduce((accum, current) => {
        const currentWhen = values.extra_attribute[current.when];

        return {
          hide: currentWhen ? shouldHideField(currentWhen, current) : accum,
          operator: current.operator,
        };
      }, false);

      if (hide) {
        if (values.extra_attribute[fieldName].length !== 0) {
          if (operator === '===') {
            setFieldValue(name, '');
          } else {
            setFieldValue(name, []);
          }
        }
      }
      setHideField(hide);
    }
  }, [name, setFieldValue, values.extra_attribute, visible]);

  return {
    hideField,
  };
}
