function issueFromJSON(data) {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
    image: data.image,
    slug: data.slug,
  };
}

function issueResponseFromJSON(data) {
  return {
    id: data.id,
    name: data.name,
    organization: data.organization,
    deliveryAddressId: data.delivery_address,
    issue: issueFromJSON(data.issue),
    items: data.items.map(itemFromJSON),
  };
}

const itemDeserializersMap = {
  need: {
    supply: needSupplyFromJSON,
    funding: itemNotImplementedKindFromJSON,
    service: needServiceFromJSON,
  },
  offer: {
    discount: offerDiscountFromJSON,
    supply: offerSupplyFromJSON,
    catalog: itemNotImplementedKindFromJSON,
    service: offerServiceFromJSON,
    information: offerInformationFromJSON,
  },
};

function itemFromJSON(data) {
  const specificsDeserializer = itemDeserializersMap[data.side][data.kind];
  const item = {
    id: data.id,
    originalLanguage: data.original_language,
    translationStatus: data.translation_status,
    name: data.name,
    description: data.description,
    side: data.side,
    kind: data.kind,
    category: data.category || '',
    currency: data.currency,
    quantity: data.quantity,
    dueDate: data.due_date,
    location: data.location,
    locationId: data.location_id,
    status: data.status,
    organization: data.organization,
    issueName: data.issue_name,
    issueLocation: data.issue_location,
    issueResponse: data.issue_response,
    claimedQuantity: data.claimed_quantity,
    progress: data.progress,
    organizationLogo: data.organization.logo,
    organizationName: data.organization.name,
    phoneNumber: data.phone_number,
    itemType: data.item_type,
    createdAt: data.created_at,
    user: data.user ? userFromJSON(data.user) : null,
    contactName: data.contact_name,
    contactEmail: data.contact_email,
    bundle: data.bundle,
    deliveryInBundles: data.delivery_in_bundles,
    uomFulfillment: data.uom_fulfillment,
    beneficiaries: data.beneficiaries,
    ui_status: data.ui_status,
    uiStatusTranslated: data.ui_status_translated,
    platformInteraction: data.platform_interaction,
    tag: data.tag || [],
    ...specificsDeserializer(data),
  };

  if (data.item_template && typeof data.item_template === 'object') {
    item.itemTemplate = itemTemplateFromJSON(data.item_template);
  }

  return item;
}

function algoliaItemFromJSON(data) {
  return {
    id: data.id,
    name: data.title['en-us'],
    side: data.meta_side,
    kind: data.meta_kind.toLowerCase(),
    status: data.meta_status,
    issueLocation: {place: data.meta_issue_location},
    issueName: data.meta_issue_name,
    organizationName: data.meta_organization,
    quantity: data.meta_quantity,
    claimedQuantity: data.meta_claimed_quantity,
    uomFulfillment: data.meta_uom_fulfillment,
    tag: (data.meta_tags || []).map((tag, index) => ({
      name: tag,
      tagItem: tag,
    })),
  };
}

// TODO implement when new kinds are available
function itemNotImplementedKindFromJSON(data) {
  return data;
}

function needSupplyFromJSON(data) {
  return {
    size: data.size,
    bundle: {
      ...data.bundle,
      uom_id: data.bundle ? data.bundle.uom.id.toString() : '',
    },
    brand: data.brand,
    isPallet: data.is_pallet,
    acceptingDeliveriesHoursFrom: data.accepting_deliveries_hours_from,
    acceptingDeliveriesHoursTo: data.accepting_deliveries_hours_to,
    wareHouseDelivery: data.warehouse_delivery,
    baseUom: data.base_uom,
    image: data.image,
    imageId: data.image_id,
    phoneNumber: data.phone_number,
    marketValue: data.market_value,
    totalDollarValue: data.dollar_value,
  };
}

function needServiceFromJSON(data) {
  return {
    jobType: data.job_type,
    meetingsRequirements: data.meetings_requirements,
    deliverable: data.deliverable,
    baseUom: data.base_uom,
    image: data.image,
    imageId: data.image_id,
    tags: data.skills,
    availableFor: data.available_for,
    marketValue: data.market_value,
    totalDollarValue: data.dollar_value,
  };
}

function offerServiceFromJSON(data) {
  return {
    jobType: data.job_type,
    baseUom: data.base_uom,
    tags: data.skills,
    marketValue: data.market_value,
    totalDollarValue: data.dollar_value,
    image: data.image,
    imageId: data.image_id,
    availableFor: data.available_for,
    phoneNumber: data.phone_number,
  };
}

function offerInformationFromJSON(data) {
  return {
    marketValue: data.market_value,
    totalDollarValue: data.dollar_value,
    image: data.image,
    imageId: data.image_id,
    files: data.files,
    phoneNumber: data.phone_number,
    url: data.url,
    availableFor: data.available_for,
    contactEmail: data.contact_email,
    description: data.description,
    availableUntil: data.due_date,
  };
}

function offerDiscountFromJSON(data) {
  return {
    percentage: data.percentage,
    textCode: data.code,
    availableUntil: data.due_date,
    availableFor: data.available_for,
    externalURL: data.external_url,
    phoneNumber: data.phone_number,
  };
}

function offerSupplyFromJSON(data) {
  return {
    baseUom: data.base_uom,
    bundle: {
      ...data.bundle,
      uom_id: data.bundle ? data.bundle.uom.id.toString() : '',
    },
    size: data.size,
    brand: data.brand,
    availableUntil: data.due_date,
    isPallet: data.is_pallet,
    specialHandlingValue: data.special_handling,
    customSpecialHandlingValue: data.custom_special_handling,
    coverShippingCost: data.cover_shipping_cost,
    marketValue: data.market_value,
    totalDollarValue: data.dollar_value,
    image: data.image,
    imageId: data.image_id,
    availableFor: data.available_for,
    phoneNumber: data.phone_number,
  };
}

function userFromJSON(data) {
  return {
    id: data.id,
    email: data.email,
    firstName: data.first_name,
    lastName: data.last_name,
    phoneNumber: data.phone_number,
    timezone: data.timezone,
  };
}

function itemFilterFromJSON(data) {
  return {
    label: data.label,
    value: data.value,
  };
}

function itemTemplateFromJSON(data) {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
    kind: data.kind,
    bundles: data.bundles,
  };
}

function claimItemFromJSON(data) {
  return {
    id: data.id,
    quantity: data.quantity,
    itemQuantity: data.item_quantity,
    claimOrganizationName: data.claim_org_name,
    postOrganizationName: data.post_org_name,
    organizationLogo: data.org_logo,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    item: data.item,
    deliveryOptions: data.delivery_options,
    pickupLocation: data.pickup_location,
    phoneNumber: data.phone_number,
    comment: data.comment,
    confirmationComment: data.confirmation_comment,
    images: (data.images || []).map(imageFromJSON),
    confirmationStatus: data.status,
    baseUom: data.base_uom,
    bundle: data.bundle,
    marketValue: data.market_value,
    totalDollarValue: data.dollar_value,
    claimDollarValue: data.claim_dollar_value,
    currency: data.currency,
    uomFulfillment: data.item.uom_fulfillment,
    beneficiariesQuantity: data.beneficiaries_quantity,
    platformInteraction: data.platform_interaction,
  };
}

function imageFromJSON(data) {
  return {
    name: data.name,
    url: data.url,
  };
}

function itemCountFromJSON(data) {
  return {
    needsCount: data.needs_count,
    offersCount: data.offers_count,
  };
}

export {
  issueFromJSON,
  issueResponseFromJSON,
  itemFromJSON,
  algoliaItemFromJSON,
  itemFilterFromJSON,
  itemTemplateFromJSON,
  claimItemFromJSON,
  itemCountFromJSON,
};
