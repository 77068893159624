import styled from 'styled-components';

const Thumbnail = styled.div`
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 7px;
  width: 117px;
  overflow: hidden;
`;

export default Thumbnail;
