import React, {useContext} from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import FiltersContext from '../FiltersProvider/FiltersContext';
import Pill from '../Pills/Pill';
import Radio from '../Form/Radio';
import CheckboxList from '../CheckboxList';
// This is commented in case we need it in the future
// import ItemCategoryFilter from './ItemCategoryFilter';
import styles from './ItemFiltersWithCategories.module.scss';

const ItemFiltersWithCategories = (props) => {
  const {itemFilters, ongoingIssue} = props;
  const history = useHistory();
  const match = useRouteMatch();
  const {t} = useTranslation('search');
  const stores = useStores();

  const showOnGoingIssueFilter = !!stores.app.onGoingIssue && !ongoingIssue;

  const {
    filters,
    removeFilter,
    clearFilters,
    overrideFilters,
    initialized,
    removeRelatedFilters,
  } = useContext(FiltersContext);

  if (!initialized) {
    return null;
  }

  const sortByOptions = [
    {
      label: t('filter.latest'),
      value: '-created_at',
    },
    {
      label: t('filter.oldest'),
      value: 'created_at',
    },
  ];

  const statusOptions = [
    {
      label: t('filter.open-only'),
      value: 'open,in_progress',
    },
    {
      label: t('filter.include-complete-expired'),
      value: 'open,in_progress,done,expired',
    },
  ];

  const includeGlobalIssueOptions = [
    {
      label: t('filter.include-global'),
      value: 'include',
    },
    {
      label: t('filter.exclude-global'),
      value: 'exclude',
    },
  ];

  const itemTypeOptions = [
    {
      label: t('filter.supplies'),
      value: 'supply',
    },
    {
      label: t('filter.services'),
      value: 'service',
    },
    {
      label: t('filter.discounts'),
      value: 'discount',
    },
    {
      label: t('filter.information'),
      value: 'information',
    },
  ];

  const areFiltersApplied = Object.values(itemFilters).some(
    (filterCategory) =>
      filters[filterCategory.value] && filters[filterCategory.value].length > 0,
  );
  const renderPills = (item, category) => {
    const onFilterRemove = () => {
      if (itemFilters[category].partialMatchValue) {
        removeFilter(itemFilters[category].partialMatchValue, item);
      }

      if (itemFilters[category].removeRelated) {
        const removeRelated = itemFilters[category].removeRelated;
        removeRelatedFilters(
          removeRelated.relatedFilters,
          item[removeRelated.commonIdKey],
          removeRelated.commonIdKey,
        );
      }

      removeFilter(category, item);
    };

    return (
      <Pill key={item.value} onRemove={onFilterRemove}>
        {item.label}
      </Pill>
    );
  };
  const handleSortChange = (e) => {
    overrideFilters('sortByDate', e.target.value);
    history.push(`${match.url}`);
  };
  const handleStatusChange = (e) => {
    overrideFilters('status', e.target.value.split(','));
    history.push(`${match.url}`);
  };
  const handleIncludeGlobalIssueChange = (e) => {
    overrideFilters('includeGlobalIssue', e.target.value);
    history.push(`${match.url}`);
  };
  const handleItemTypeChange = (itemTypes) => {
    overrideFilters('itemType', itemTypes);
    history.push(`${match.url}`);
  };
  const handleClear = () => {
    clearFilters(true);
  };
  const pills = Object.values(itemFilters).map((filterCategory, index) => {
    let appliedFilters = filters[filterCategory.value];

    if (filterCategory.partialMatchValue) {
      appliedFilters = appliedFilters.concat(
        filters[filterCategory.partialMatchValue],
      );
    }

    const renderPillWithCategory = (item) =>
      renderPills(item, filterCategory.value);
    const clearButton =
      index === 0 && areFiltersApplied ? (
        <button className={styles.clearButton} onClick={handleClear}>
          {t('clear')}
        </button>
      ) : null;

    return (
      <div className={styles.filterContainer} key={filterCategory.value}>
        <div className={styles.filterCategory}>
          {filterCategory.label}
          {clearButton}
        </div>
        <div className={styles.pìllsContainer}>
          {appliedFilters.length > 0
            ? appliedFilters.map(renderPillWithCategory)
            : t('no-filters')}
        </div>
      </div>
    );
  });

  return (
    <div>
      {pills}
      <div className={styles.filterCategory}>
        {t('filter-label.sort-by-date')}
      </div>
      <Radio
        options={sortByOptions}
        onChange={handleSortChange}
        value={filters.sortByDate}
      />
      <div className={styles.filterCategory}>{t('filter-label.status')}</div>
      <Radio
        options={statusOptions}
        onChange={handleStatusChange}
        value={filters.status.join(',')}
      />
      {showOnGoingIssueFilter && (
        <React.Fragment>
          <div className={styles.filterCategory}>
            {t('filter-label.global-issue')}
          </div>
          <Radio
            options={includeGlobalIssueOptions}
            onChange={handleIncludeGlobalIssueChange}
            value={filters.includeGlobalIssue}
          />
        </React.Fragment>
      )}
      <div className={styles.filterCategory}>{t('filter-label.item-type')}</div>
      <CheckboxList
        options={itemTypeOptions}
        onChange={handleItemTypeChange}
        value={filters.itemType}
      />
    </div>
  );
};

export default observer(ItemFiltersWithCategories);
