import styled from 'styled-components';
import {Z_INDEX} from '../../constants';

const CategoryImage = styled.img`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: ${Z_INDEX.overlay};
`;

export default CategoryImage;
