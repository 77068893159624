import React, {useEffect} from 'react';
import {Card} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import styled from 'styled-components';

import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import {getUserDisplayName} from '../../utils/user';
import Title from '../../components/Title/Title';
import {usePageStore} from '../../contexts/PageContext';
import {breakpoints, mediaQueries, FEATURES} from '../../constants';
import {isFeatureEnabled} from '../../utils/features';
import {
  BROWSE_ISSUES_PAGE,
  ADD_NEEDS,
  ADD_OFFERS,
  SEARCH_PAGE,
  MAP_PAGE_V1,
} from '../../urls';

const HomeTitle = styled(Title)`
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 2.1rem;
  text-align: center;
  margin-top: 3.1rem;

  ${mediaQueries.lg} {
    margin: 0.9rem 0 1.8rem;
    text-align: left;
    font-size: 1.25rem;
  }
`;

const Options = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 3.75rem 0 2.5rem;
  justify-content: center;

  ${mediaQueries.lg} {
    height: auto;
    margin-top: 1.25rem;
  }
`;

const Option = styled(Card)`
  display: flex;
  margin: 0.6rem;
  width: 16rem;
  padding: 2.5rem 1.5rem;
  cursor: pointer;

  ${mediaQueries.lg} {
    width: 100%;
    max-width: 33rem;
    margin: 0.6rem 0;
  }
`;

const OptionTitle = styled(Title).attrs({as: 'h3'})`
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.8rem;
  margin: 0;
  margin-bottom: 1rem;
  ${mediaQueries.lg} {
    font-size: 1.25rem;
    margin-top: 0;
  }
`;

const OptionSubtitle = styled.p`
  font-size: 1rem;
  line-height: 1.25rem;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-weight: normal;
  @media (min-width: ${breakpoints.lg + 209}px) {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
`;

const Home = () => {
  const stores = useStores();
  const user = stores.app.user;

  const history = useHistory();
  const {t} = useTranslation('home');
  const [, pageActions] = usePageStore();
  const title = user ? t('title', {user: getUserDisplayName(user)}) : null;
  const showMapLink = isFeatureEnabled(
    FEATURES.showMapLink,
    stores.app.features,
  );

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'home'});
  }, [pageActions]);

  const homeOptions = [
    {
      title: t('card.browse-issues.title'),
      description: t('card.browse-issues.description'),
      redirectTo: () => history.push(BROWSE_ISSUES_PAGE),
    },
    {
      title: t('card.add-needs.title'),
      description: t('card.add-needs.description'),
      redirectTo: () => history.push(ADD_NEEDS),
    },
    {
      title: t('card.make-an-offer.title'),
      description: t('card.make-an-offer.description'),
      redirectTo: () => history.push(ADD_OFFERS),
    },
    {
      title: showMapLink ? t('card.map.title') : t('card.search.title'),
      description: t('card.search.description'),
      redirectTo: showMapLink
        ? () => history.push(MAP_PAGE_V1)
        : () => history.push(SEARCH_PAGE),
    },
  ];

  const renderHomeOptions = (option, index) => {
    return (
      <Option key={index} onClick={option.redirectTo}>
        <OptionTitle>{option.title}</OptionTitle>
        <OptionSubtitle>{option.description}</OptionSubtitle>
      </Option>
    );
  };

  return (
    <React.Fragment>
      <HomeTitle>{title}</HomeTitle>
      <Options>{homeOptions.map(renderHomeOptions)}</Options>
    </React.Fragment>
  );
};

export default observer(Home);
