import {useRef, useState, useEffect, useCallback} from 'react';
import {breakpoints} from '../constants';
import i18n from '../i18n';
import {useApi} from '../contexts/ApiContext';

const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

const useWindowSize = () => {
  const getSize = useCallback(() => {
    return {
      width: window.innerWidth || undefined,
    };
  }, []);
  const [windowSize, setWindowSize] = useState(getSize);
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getSize]);
  return windowSize;
};

const useClickOutside = (ref, onClickOutside) => {
  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside(event);
      }
    },
    [ref, onClickOutside],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
};

const useIsMobile = () => {
  const windowSize = useWindowSize();
  const [isMobile, setIsMobile] = useState(windowSize.width < breakpoints.lg);

  useEffect(() => {
    setIsMobile(windowSize.width < breakpoints.lg);
  }, [windowSize.width]);

  return isMobile;
};

/**
 * Listens window scroll event and returns the current window.scrollY value.
 * @function
 * @param {boolean} [listenScroll=true] - Indicates whether or not to listen to scroll events.
 * @return {number} Current window.scrollY value.
 */
const useWindowScrollY = (listenScroll = true) => {
  const [scrollY, setScrollY] = useState(window.scrollY);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    if (listenScroll) {
      window.addEventListener('scroll', handleScroll, {
        capture: false,
        passive: true,
      });
    }

    return () => {
      if (listenScroll) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [listenScroll]);

  return scrollY;
};

/**
 * Check if window.scrollY reached given value.
 * @function
 * @param {number} reachY - Value of window.scrollY to reach.
 * @param {boolean} [listenScroll=true] - Indicates whether or not to listen to scroll events.
 * @return {boolean} Whether you reached the scrollY given or not.
 */
const useWindowScrollReachedY = (reachY, listenScroll = true) => {
  const scrollY = useWindowScrollY(listenScroll);

  return scrollY > reachY;
};

const useAvailableForOptions = () => {
  const api = useApi();
  const [availableForOptions, setAvailableForOptions] = useState([])

  const fetchAvailableForOptions = useCallback(async () => {
    const availableOptions = await api.getAvailableForOptions()
    const result = availableOptions.reduce((acc=[], cur) => {
      const newOption = {
        "value": cur.value,
        "label": i18n.t(cur.label)
      }
      return [...acc, newOption]
    }, []);
    setAvailableForOptions(result)
  }, [api, setAvailableForOptions]);

  useEffect(() => {
    fetchAvailableForOptions();
  }, [fetchAvailableForOptions])  
    
  return availableForOptions
}

export {
  usePrevious,
  useWindowSize,
  useClickOutside,
  useIsMobile,
  useWindowScrollY,
  useWindowScrollReachedY,
  useAvailableForOptions
};
