const handleSelectFirstOption = (e, results, activeIndex, onChange) => {
  if (
    results.length &&
    (e.key === 'Enter' || e.type === 'blur') &&
    activeIndex === -1
  ) {
    onChange([results[0]]);
  }
};

export {handleSelectFirstOption};
