import React, {useEffect, useState} from 'react';

import Search from '../../components/Search';
import SearchPage from '../SearchPage';
import {usePageStore} from '../../contexts/PageContext';
import FiltersProvider from '../../components/FiltersProvider';
import Onboarding from './Onboarding';
import {useLocalStorageFlag} from '../../hooks/localstorage';
import {useWindowScrollReachedY} from '../../hooks/utils';
import {LOCAL_STORAGE_KEYS} from '../../constants';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import RequestJoinModal from '../../components/Modals/RequestJoinModal';
import {SCROLL_Y_SHOW_MODAL} from '../../constants';

const Database = () => {
  const stores = useStores();
  const {clientData, requestJoinModal} = stores.app;
  const [pageState, pageActions] = usePageStore();
  // TODO: Set default value to false so users that have cleaned up their local storage don't get
  // the onboarding again. Update once register is in place and we can initialize the key in true
  const [setShowOnboarding, showOnboarding] = useLocalStorageFlag(
    LOCAL_STORAGE_KEYS.databaseOnboarding,
    true,
  );
  const [showModal, setShowModal] = useState(false);
  const windowScrollReachedY = useWindowScrollReachedY(
    SCROLL_Y_SHOW_MODAL,
    requestJoinModal,
  );

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'search'});
  }, [pageActions]);
  const handleClose = () => setShowOnboarding(false);

  useEffect(() => {
    if (windowScrollReachedY) {
      setShowModal(true);
    }
  }, [windowScrollReachedY]);

  const handleOnClose = () => {
    stores.app.toggleRequestJoinModal(false);
    setShowModal(false);
  };

  return (
    <React.Fragment>
      {showOnboarding && <Onboarding onClose={handleClose} />}
      {clientData.use_search_v2 ? (
        <SearchPage />
      ) : (
        <FiltersProvider>
          <Search />
        </FiltersProvider>
      )}
      {pageState.isPublic && showModal && (
        <RequestJoinModal onClose={handleOnClose} />
      )}
    </React.Fragment>
  );
};

export default observer(Database);
