import React from 'react';
import {useWindowSize} from '../../hooks/utils';

const Responsive = (props) => {
  const windowSize = useWindowSize();
  const childrenArray = React.Children.toArray(props.children);

  return childrenArray
    .filter((child) => {
      const {min, max} = child.props;
      return (
        (min && min >= windowSize.width) || (max && max < windowSize.width)
      );
    })
    .map((child, index) => {
      const {min, max, ...rest} = child.props;
      return <child.type key={index} {...rest} />;
    });
};

export default Responsive;
