import {useState, useEffect, useMemo, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Form, Tabs, Tab} from 'react-bootstrap';
import {usePaginator} from '../../hooks/paginator';
import Button from './Button';
import SearchIcon from '../../assets/icons/input-suffix.svg';
import PlusIcon from '../../assets/icons/plus-v3.svg';
import CloseIcon from '../../assets/icons/input-suffix-close.svg';
import sharedStyles from './shared.module.scss';
import {KeyboardAlgoliaControl} from './KeywordAlgoliaControl';

const KeywordFilter = (props) => {
  const {cancelClick, value, queryParam} = props;
  const [selectedTab, setSelectedTab] = useState('is');
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [currentValue, setCurrentValue] = useState('');
  const history = useHistory();
  const {t} = useTranslation('item-reports');
  const {changePage} = usePaginator();

  const tabs = useMemo(() => ['is', 'not', 'contains', 'not_contains'], []);

  const addNewKeyword = useCallback(
    (newKey) => {
      if (selectedKeywords.some((x) => x === newKey)) return;

      setSelectedKeywords([...selectedKeywords, newKey]);
      setCurrentValue('');
    },
    [selectedKeywords],
  );

  const addKeyword = useCallback(() => {
    if (currentValue.trim() !== '') {
      addNewKeyword(currentValue);
    }
  }, [currentValue, addNewKeyword]);

  const removeIndex = useCallback((index, e) => {
    e.stopPropagation();
    setSelectedKeywords((oldSelected) => {
      const array = [...oldSelected];
      array.splice(index, 1);
      return array;
    });
  }, []);

  const handleChange = useCallback((e) => {
    e.preventDefault();
    setCurrentValue(e.target.value);
  }, []);

  const applyFilter = () => {
    changePage(history.location.pathname, 1);
    const value =
      selectedKeywords.length === 0
        ? ''
        : selectedTab + ':' + selectedKeywords.join(';');
    const url = history.location.search;
    const queryStringParams = new URLSearchParams(url);
    let fullValue = queryStringParams
      .getAll(queryParam)
      .filter((v) => !v.startsWith(selectedTab + ':'));
    if (value !== '') {
      fullValue.push(value);
    }
    queryStringParams.delete(queryParam);
    fullValue.forEach((val) => queryStringParams.append(queryParam, val));
    cancelClick();
    history.push({search: queryStringParams.toString()});
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      selectedKeywords.length && currentValue.trim() === ''
        ? applyFilter()
        : addKeyword();
    }
  };

  const handleTabChange = useCallback(
    (tab) => {
      const url = history.location.search;
      const queryStringParams = new URLSearchParams(url);
      const fullValue = queryStringParams
        .getAll(queryParam)
        .filter((v) => v.startsWith(tab + ':'));
      if (fullValue.length > 0) {
        const suffix = fullValue[0].split(':')[1];
        setSelectedKeywords(suffix.split(';'));
      } else {
        setSelectedKeywords([]);
      }
      setSelectedTab(tab);
    },
    [history, queryParam],
  );

  useEffect(() => {
    // Add validation and error handling.
    if (value) {
      const [prefix, suffix] = value.split(':');
      setSelectedTab(prefix);
      setSelectedKeywords(suffix.split(';'));
    }
  }, [value]);

  return (
    <div className={sharedStyles.popupPanel}>
      <div className={sharedStyles.container}>
        <div className={sharedStyles.containerBorders}>
          <div className={sharedStyles.content}>
            <div
              className={
                sharedStyles.contentInner +
                ' ' +
                sharedStyles.contentInnerFixedWith
              }>
              <Tabs activeKey={selectedTab} onSelect={handleTabChange}>
                {tabs.map((value, index) => (
                  <Tab
                    key={value}
                    eventKey={value}
                    title={t('filters.keyword.tabs.' + value)}></Tab>
                ))}
              </Tabs>

              {selectedTab === 'is' || selectedTab === 'not' ? (
                <KeyboardAlgoliaControl
                  className={sharedStyles.input}
                  placeholder={t('filters.keyword.placeholder')}
                  onSelectItem={addNewKeyword}
                  value={currentValue}
                  selectedValues={selectedKeywords}
                  applyFilter={applyFilter}
                  styles={sharedStyles}
                />
              ) : (
                <div className={sharedStyles.inputContainerWithButton}>
                  <div
                    className={sharedStyles.inputContainer}
                    onClick={(e) => e.stopPropagation()}>
                    <Form.Control
                      onKeyUp={handleKeyUp}
                      className={sharedStyles.input}
                      placeholder={t('filters.keyword.placeholder')}
                      onChange={handleChange}
                      value={currentValue}></Form.Control>
                    <img
                      src={SearchIcon}
                      className={sharedStyles.searchIconStyle}
                      alt="search"
                    />
                  </div>
                  <Button
                    onClick={addKeyword}
                    active={true}
                    disabled={currentValue.trim() === ''}>
                    {t('filters.add')} &nbsp;
                    <img
                      src={PlusIcon}
                      className={sharedStyles.addIconStyle}
                      alt="add"
                    />
                  </Button>
                </div>
              )}

              {selectedKeywords.map((value, index) => (
                <div
                  key={'input_' + index}
                  className={sharedStyles.inputContainer}>
                  <Form.Control
                    className={sharedStyles.input}
                    value={value}
                    readOnly></Form.Control>
                  <img
                    src={CloseIcon}
                    className={sharedStyles.searchIconStyle}
                    style={{cursor: 'pointer'}}
                    onClick={(e) => removeIndex(index, e)}
                    alt="remove"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={sharedStyles.divider}>
            <hr className={sharedStyles.dividerLine} />
          </div>
          <div className={sharedStyles.footerContainer}>
            <div className={sharedStyles.footerContainerInner}>
              <Button
                onClick={applyFilter}
                active={true}
                disabled={selectedKeywords.length === 0}>
                {t('filters.apply')}
              </Button>
              <Button onClick={cancelClick}>{t('filters.cancel')}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeywordFilter;
