import React from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShareAlt} from '@fortawesome/free-solid-svg-icons';
import Button from '../Button';
import styles from './Share.module.scss';

const SharePage = (props) => {
  const {buttonLabel, label, className, onClick} = props;
  const {t} = useTranslation('common');

  return (
    <Button
      variant="link"
      onClick={onClick}
      className={`${styles.shareButton} ${className}`}>
      {(buttonLabel || t('share.share-this', {label})).toUpperCase()}
      <FontAwesomeIcon className={styles.shareIcon} icon={faShareAlt} />
    </Button>
  );
};

export default SharePage;
