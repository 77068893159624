import React from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../Modals/Modal';
import {ReactComponent as ShareIcon} from '../../assets/icons/share.svg';
import styles from './Share.module.scss';

const ShareModal = (props) => {
  const {onClose, label, title, subtitle, children, description} = props;
  const {t} = useTranslation('common');

  return (
    <Modal
      className={styles.shareModal}
      onClose={onClose}
      headerTitle={title || t('share.modal.share-label', {label})}>
      <div className={styles.subHeader}>
        <ShareIcon />
        <div className={styles.description}>
          <p className={styles.highlight}>
            {subtitle || t('share.modal.want-to-share', {label})}
          </p>
          {description || t('share.modal.copy-link-share')}
        </div>
      </div>
      {children}
    </Modal>
  );
};

export default ShareModal;
