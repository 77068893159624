import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Row, Col} from 'react-bootstrap';

import {useApi} from '../../contexts/ApiContext';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import {useWindowScrollReachedY} from '../../hooks/utils';

import {usePageStore} from '../../contexts/PageContext';
import SearchPage from '../SearchPage';
import Search from '../../components/Search';
import FiltersProvider from '../../components/FiltersProvider';
import Banner from '../../components/Banner';
import RelatedMembers from '../../components/RelatedMembers';
import RequestJoinModal from '../../components/Modals/RequestJoinModal';
import {SCROLL_Y_SHOW_MODAL} from '../../constants';
import styles from './IssuePage.module.scss';

const StyledRelatedMembers = styled(RelatedMembers)`
  margin-top: 65px;
  margin-bottom: 30px;
`;

const IssuePage = () => {
  const stores = useStores();
  const {use_search_v2} = stores.app.clientData;
  const api = useApi();
  const {slug} = useParams();
  const [issue, setIssue] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [pageState, pageActions] = usePageStore();
  const windowScrollReachedY = useWindowScrollReachedY(
    SCROLL_Y_SHOW_MODAL,
    stores.app.requestJoinModal,
  );

  useEffect(() => {
    if (windowScrollReachedY) {
      setShowModal(true);
    }
  }, [windowScrollReachedY]);

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'issue-page'});
  }, [pageActions]);

  useEffect(() => {
    const fetchIssue = async () => {
      const response = await api.getIssue(slug);
      setIssue(response);
    };
    fetchIssue();

    return () => {
      stores.app.clearFilters();
    };
  }, [slug, api, stores.app]);

  if (!issue) return null;

  const handleOnClose = () => {
    stores.app.toggleRequestJoinModal(false);
    setShowModal(false);
  };

  return (
    <React.Fragment>
      <div
        className={
          use_search_v2 ? styles.bannerWrapperV2 : styles.bannerWrapper
        }>
        <Banner issue={issue} />
      </div>
      <FiltersProvider>
        {use_search_v2 ? (
          <SearchPage currentIssue={issue} />
        ) : (
          <Search currentIssue={issue} />
        )}
        {!pageState.isPublic && (
          <Row className={use_search_v2 && styles.customRow}>
            <Col xs={12}>
              <StyledRelatedMembers issueId={issue.id} />
            </Col>
          </Row>
        )}
      </FiltersProvider>
      {pageState.isPublic && showModal && (
        <RequestJoinModal onClose={handleOnClose} />
      )}
    </React.Fragment>
  );
};

export default observer(IssuePage);
