import React from 'react';
import {useTranslation} from 'react-i18next';

import ItemDataValue from '../ItemForm/ItemDataValue';
import {useStores} from '../../contexts/StoresContext';
import {observer} from 'mobx-react';

const UndisclosedField = (props) => {
  const stores = useStores();
  const {t} = useTranslation('forms');

  const {item} = props;

  if (!item.organization) {
    return null;
  }

  const orgPhoneNumber =
    stores.app.user.email === item.user.email ||
    item.organization.setting_phone === 'public'
      ? item.phoneNumber
      : t('fields.undisclosed');
  const orgEmail =
    stores.app.user.email === item.user.email ||
    item.organization.setting_email === 'public'
      ? item.contactEmail
      : t('fields.undisclosed');

  const field = {
    'phone-number': orgPhoneNumber,
    email: orgEmail,
  };

  return <ItemDataValue withoutTextStyle>{field[props.field]}</ItemDataValue>;
};

export default observer(UndisclosedField);
