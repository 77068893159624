import styled from 'styled-components';
import {mediaQueries} from '../../constants';

const Layout = styled.div`
  border-top: 1px solid white;
  position: relative;

  ${mediaQueries.lg} {
    padding-top: 0;
    border: none;
  }
`;

export default Layout;
