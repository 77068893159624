import React, {useMemo} from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {useStores} from '../../contexts/StoresContext';

import Select from './Select';

const CategorySelect = (props) => {
  const stores = useStores();
  const kind = props.kind || 'supply';
  const {t} = useTranslation('forms');
  const categories = stores.app.choices.category.filter(
    (category) => category.kind === kind,
  );
  const renderCategories = (category) => {
    return (
      <option value={category.id} key={category.id}>
        {category.name}
      </option>
    );
  };
  const sortedCategories = useMemo(
    () => categories.sort((a, b) => a.name.localeCompare(b.name)),
    [categories],
  );

  return (
    <Select {...props}>
      <option value="">{t('select-option')}</option>
      {sortedCategories.map(renderCategories)}
    </Select>
  );
};

export default observer(CategorySelect);
