import React, {useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useClickOutside} from '../../../hooks/utils';
import ExportDropdown from './ExportDropdown';
import sharedStyles from './../shared.module.scss';

const Export = (props) => {
  const {downloadItemReportCsv, downloadExportAllCsv, csvDownloadReady} = props;
  const {t} = useTranslation('item-reports');
  const wrapperRef = useRef(null);
  const [showExportDropdown, setShowExportDropdown] = useState(false);

  const handleExportButtonClick = () => {
    setShowExportDropdown(!showExportDropdown);
  };

  const handleClickOutside = () =>
    showExportDropdown && setShowExportDropdown(false);

  useClickOutside(wrapperRef, handleClickOutside);

  return (
    <div ref={wrapperRef}>
      <div
        className={
          csvDownloadReady
            ? sharedStyles.dropdownButton
            : sharedStyles.disabledDropdownButton
        }
        onClick={handleExportButtonClick}>
        <div
          className={
            csvDownloadReady
              ? sharedStyles.dropdownButtonText
              : sharedStyles.disabledDropdownButtonText
          }>
          {t('buttons.export')}
          <img
            src="https://fonts.gstatic.com/s/i/short-term/release/googlesymbols/arrow_drop_down/default/20px.svg"
            alt="down arrow"
            className={sharedStyles.dropdownIcon}
          />
        </div>
      </div>
      {showExportDropdown && (
        <ExportDropdown
          downloadItemReportCsv={downloadItemReportCsv}
          downloadExportAllCsv={downloadExportAllCsv}
        />
      )}
    </div>
  );
};

export default Export;
