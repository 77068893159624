import sharedStyles from './shared.module.scss';
import Button from './Button';
import {useState, useEffect, useMemo, useCallback} from 'react';
import {Form} from 'react-bootstrap';
import CloseIcon from '../../assets/icons/input-suffix-close.svg';
import PlusIcon from '../../assets/icons/plus-v3.svg';
import {useHistory} from 'react-router-dom';
import {Tab, Tabs} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import Select from 'react-select';

const BaseSelectFilter = ({
  cancelClick,
  value,
  queryParam,
  lookupData = [],
  i18n_key,
  optgroup_field,
  onRemoveUrl,
}) => {
  const [selectedTab, setSelectedTab] = useState('is');
  const [selectedData, setSelectedData] = useState([]);
  const [currentValue, setCurrentValue] = useState('');
  const history = useHistory();
  const {t} = useTranslation('item-reports');

  const tabs = useMemo(() => ['is', 'not'], []);
  const validateDuplicated = useCallback(
    () => selectedData.find((item) => parseInt(item) === currentValue),
    [currentValue, selectedData],
  );
  const addRecord = useCallback(() => {
    setSelectedData((oldSelectedData) =>
      validateDuplicated()
        ? [...oldSelectedData]
        : [...oldSelectedData, currentValue],
    );
    setCurrentValue('');
  }, [currentValue, validateDuplicated]);

  const removeIndex = useCallback((index, e) => {
    e.stopPropagation();
    setSelectedData((oldSelectedData) => {
      const array = [...oldSelectedData];
      array.splice(index, 1);
      return array;
    });
  }, []);

  const handleChange = (e) => {
    setCurrentValue(e.value);
  };
  const queryStringParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );

  const applyFilter = () => {
    const value = selectedTab + ':' + selectedData.join(';');
    const queryStringParams = new URLSearchParams(onRemoveUrl);
    let fullValue = queryStringParams
      .getAll(queryParam)
      .filter((v) => !v.startsWith(selectedTab + ':'));
    if (value !== '') {
      fullValue.push(value);
    }
    queryStringParams.delete(queryParam);
    fullValue.forEach((val) => queryStringParams.append(queryParam, val));
    cancelClick();
    history.push({search: queryStringParams.toString()});
  };

  const handleTabChange = useCallback(
    (tab) => {
      const fullValue = queryStringParams
        .getAll(queryParam)
        .filter((v) => v.startsWith(tab + ':'));
      if (fullValue.length > 0) {
        const suffix = fullValue[0].split(':')[1];
        setSelectedData(suffix.split(';'));
      } else {
        setSelectedData([]);
      }
      setSelectedTab(tab);
    },
    [queryParam, queryStringParams],
  );
  useEffect(() => {
    if (value) {
      // Add validation and error handling.
      const [prefix, suffix] = value.split(':');
      setSelectedTab(prefix);
      setSelectedData(suffix.split(';'));
    }
  }, [value]);
  const optionList = (records) => {
    return records.map((record) => ({
      value: record.id,
      label: record.name,
    }));
  };
  const createOptions = () => {
    if (!optgroup_field) {
      return optionList(lookupData);
    }

    const list_keys = [];
    lookupData.forEach((record) => {
      if (!list_keys.includes(record[optgroup_field])) {
        list_keys.push(record[optgroup_field]);
      }
    });

    const result = list_keys.map((key) => {
      const filtered_records = lookupData.filter(
        (record) => record[optgroup_field] === key,
      );
      return {
        label: t(`filters.${i18n_key}.optgroup.${key}`),
        options: optionList(filtered_records),
      };
    });
    return result;
  };

  return (
    <div className={sharedStyles.popupPanel}>
      <div className={sharedStyles.container}>
        <div className={sharedStyles.containerBorders}>
          <div className={sharedStyles.content}>
            <div
              className={
                sharedStyles.contentInner +
                ' ' +
                sharedStyles.contentInnerFixedWith
              }
            >
              <Tabs
                activeKey={selectedTab}
                onSelect={(k) => handleTabChange(k)}
              >
                {tabs.map((value, index) => (
                  <Tab
                    key={value}
                    eventKey={value}
                    title={t('filters.category.tabs.' + value)}
                  ></Tab>
                ))}
              </Tabs>

              <div className={sharedStyles.inputContainerWithButton}>
                <div
                  className={sharedStyles.inputContainer}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Select
                    options={createOptions()}
                    onChange={handleChange}
                    defaultValue={currentValue}
                    placeholder={t(`filters.${i18n_key}.select`)}
                    classNames={{
                      control: () => `${sharedStyles.input}`,
                      container: () => `${sharedStyles.select}`,
                    }}
                  />
                </div>
                <Button
                  onClick={addRecord}
                  active={true}
                  disabled={currentValue === '' || validateDuplicated()}
                >
                  {t('filters.add')} &nbsp;
                  <img
                    src={PlusIcon}
                    className={sharedStyles.addIconStyle}
                    alt="add"
                  />
                </Button>
              </div>

              {selectedData.map((value, index) => (
                <div
                  key={'input_' + index}
                  className={sharedStyles.inputContainer}
                >
                  <Form.Control
                    className={sharedStyles.input}
                    value={
                      lookupData.find((item) => item.id === parseInt(value))
                        ?.name
                    }
                    readOnly
                  ></Form.Control>
                  <img
                    src={CloseIcon}
                    className={sharedStyles.searchIconStyle}
                    style={{cursor: 'pointer'}}
                    onClick={(e) => removeIndex(index, e)}
                    alt="remove"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={sharedStyles.divider}>
            <hr className={sharedStyles.dividerLine} />
          </div>
          <div className={sharedStyles.footerContainer}>
            <div className={sharedStyles.footerContainerInner}>
              <Button
                onClick={applyFilter}
                active={true}
                disabled={selectedData.length === 0}
              >
                {t('filters.apply')}
              </Button>
              <Button onClick={cancelClick}>{t('filters.cancel')}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseSelectFilter;
