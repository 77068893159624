import React from 'react';
import {useField} from 'formik';
import CategorySelect from '../Form/CategorySelect';
import Label from '../Form/Label';
import ReadOnlyField from './ReadOnlyField';
import {useStores} from '../../contexts/StoresContext';
import {observer} from 'mobx-react';
import {useTranslatedError} from '../../hooks/formik';

const CategorySelectField = (props) => {
  const stores = useStores();
  const [field, meta] = useField(props);
  const {label, readOnly, isRequired, ...rest} = props;
  const error = useTranslatedError(meta.error);

  if (readOnly) {
    const selectedCategory = stores.app.choices.category.find(
      (category) => category.id === field.value,
    );
    return (
      <ReadOnlyField
        label={label}
        value={selectedCategory ? selectedCategory.name : null}
      />
    );
  }

  return (
    <React.Fragment>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <CategorySelect
        {...field}
        {...rest}
        error={error}
        isInvalid={!!meta.error && meta.touched}
      />
    </React.Fragment>
  );
};

export default observer(CategorySelectField);
