import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import {Card, Dropdown, Row, Col} from 'react-bootstrap';

import {mediaQueries, breakpoints} from '../../constants';
import {MANAGE_ORGANIZATION_DETAILS, MEMBER_PAGE} from '../../urls';

import Table from '../../components/Table';
import Responsive from '../../components/Responsive';
import CardsList from '../../components/CardsList/CardsList';
import InviteOrganizations from './InviteOrganizations';
import DotMenu from '../../components/DotMenu';
import Button from '../../components/Button';
import {useApi} from '../../contexts/ApiContext';
import {exportCsv} from '../ReportsPage/utils';
import {usePanelStore} from '../../contexts/PanelContext';

const StyledCard = styled(Card)`
  margin-bottom: 40px;
  padding: 40px 15px 10px;

  ${mediaQueries.lg} {
    &.card {
      background-color: transparent;
      border-style: none;
      padding: 0;
    }
    margin-bottom: 0;
  }
`;

const TableTitle = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  font-family: 'Roboto Slab', serif;

  ${mediaQueries.lg} {
    margin: 15px 15px 15px 0;
    font-size: 18px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const ButtonWrapper = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  ${mediaQueries.md} {
    margin-top: 5px;
    width: 100%;
  }
`;

const StyledRow = styled(Row)`
  margin: 30px 10px 15px;
`;

const OrganizationsList = (props) => {
  const {organizations, count, pageSize, showAdmin} = props;
  const [, panelActions] = usePanelStore();
  const api = useApi();
  const history = useHistory();
  const {t} = useTranslation(['user', 'common']);

  const renderMoreOptions = useCallback(
    (organization) => {
      const handleViewDetails = () => {
        history.push(
          MANAGE_ORGANIZATION_DETAILS.replace(':slug', organization.slug),
        );
        panelActions.setPanelMount(true);
      };

      const handleMemberPage = () => {
        history.push(MEMBER_PAGE.replace(':slug', organization.slug));
      };

      return (
        <DotMenu>
          <Dropdown.Item onClick={handleViewDetails}>
            {t('user:buttons.view-details')}
          </Dropdown.Item>
          <Dropdown.Item onClick={handleMemberPage}>
            {t('user:buttons.member-page')}
          </Dropdown.Item>
        </DotMenu>
      );
    },
    [history, t, panelActions],
  );

  const organizationHeaders = useMemo(
    () => [
      {
        title: t('user:columns.organization.name'),
        size: 3,
      },
      {
        title: t('user:columns.organization.phone-number'),
        size: 3,
      },
      {
        title: t('user:columns.organization.email'),
        size: 3,
      },
      {
        title: t('user:columns.organization.type'),
        size: 2,
      },
      {
        title: t('user:columns.organization.more'),
        size: 1,
      },
    ],
    [t],
  );
  const organizationColumns = useMemo(
    () => [
      {
        renderCell: (organization) => organization.name,
        size: 3,
      },
      {
        renderCell: (organization) => organization.phone_number,
        size: 3,
      },
      {
        renderCell: (organization) => organization.email,
        size: 3,
      },
      {
        renderCell: (organization) =>
          t(`common:organization.${organization.org_kind}`),
        prefix: () => <BoldText>{t('titles.type')}:&nbsp;</BoldText>,
        size: 2,
      },
      {
        renderCell: renderMoreOptions,
        moreOptions: true,
        newColumn: true,
        size: 1,
      },
    ],
    [renderMoreOptions, t],
  );

  const handleExport = async () => {
    const response = await api.getOrgsCsv();
    exportCsv(response, `organizations_${Date.now()}.csv`);
  };

  return (
    <StyledCard>
      {showAdmin && <InviteOrganizations />}
      <StyledRow>
        <Col sm={6}>
          <TableTitle>
            {t('user:titles.organizations-list')} ({count})
          </TableTitle>
        </Col>
        <ButtonWrapper sm={6}>
          <StyledButton
            onClick={handleExport}
            size="sm"
            variant="outline-primary"
          >
            {t('user:buttons.export-csv')}
          </StyledButton>
        </ButtonWrapper>
      </StyledRow>
      <Responsive>
        <Table
          max={breakpoints.md}
          headers={organizationHeaders}
          columns={organizationColumns}
          data={organizations}
          count={count}
          pageSize={pageSize}
          paginatorParameterName={'org_page'}
        />
      </Responsive>
      <Responsive>
        <CardsList
          min={breakpoints.md}
          data={organizations}
          columns={organizationColumns}
          count={count}
          pageSize={pageSize}
          paginatorParameterName={'org_page'}
        />
      </Responsive>
    </StyledCard>
  );
};

export default OrganizationsList;
