import React from 'react';
import styles from './Avatar.module.scss';

const Avatar = ({className, children}) => {
  const isTextAvatar = typeof children === 'string';
  return (
    <div
      className={`${styles.container} ${
        isTextAvatar && styles.textAvatar
      } ${className}`}>
      {children}
    </div>
  );
};

export default Avatar;
