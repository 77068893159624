import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Card} from 'react-bootstrap';

import {UserDetailsForm, UserDetailsFields} from '../../components/UserDetails';
import {mediaQueries} from '../../constants';
import FormFooter from './FormFooter';

const Title = styled.h2`
  font-size: 22px;
  margin-bottom: 40px;
`;

const StyledCard = styled(Card)`
  margin-bottom: 15px;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQueries.lg} {
    &.card {
      background-color: transparent;
      border-style: none;
      padding: 0;
      margin-top: 20px;
    }
  }
`;

const UserDetailsEditCard = (props) => {
  const {t} = useTranslation('user');

  return (
    <StyledCard data-testid="userDetailsEditCard">
      <UserDetailsForm onSubmit={props.onSubmit} user={props.user}>
        <Title>{t('titles.edit-profile')}</Title>
        <UserDetailsFields />
        <FormFooter />
      </UserDetailsForm>
    </StyledCard>
  );
};

export default UserDetailsEditCard;
