import React from 'react';
import Input from '../Input';
import styles from './TimeInput.module.scss';

const TimeInput = (props) => {
  const {value, onChange, ...rest} = props;

  const handleChange = (event) => {
    let newValue = event.target.value;

    if (newValue.length > 5) {
      return;
    }

    if (value.length === 4 && newValue.length === 3) {
      newValue = newValue.slice(0, 2);
    }

    if (value.length === 2 && newValue.length === 3) {
      newValue = newValue.slice(0, 2) + ':' + newValue.slice(2);
    }

    onChange(newValue);
  };

  return (
    <div className={styles.wrapper}>
      <Input
        value={value}
        onChange={handleChange}
        className={styles.timeInput}
        {...rest}
      />
    </div>
  );
};

export default TimeInput;
