import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';
import styles from './Tooltip.module.scss';

const Tooltip = (props) => {
  const {className, title, children, onClose, onNext, isLastTooltip} = props;
  const {t} = useTranslation('common');
  const buttonText = isLastTooltip ? t('done') : t('next');
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`${styles.container} ${className}`}
    >
      {title && (
        <strong className={`${styles.tooltipText} ${styles.title}`}>
          {title}
        </strong>
      )}
      <p
        className={
          title ? `${styles.tooltipText} ${styles.text}` : `${styles.textBold}`
        }
      >
        {children}
      </p>
      {(onNext || isLastTooltip) && (
        <div
          className={styles.button}
          onClick={isLastTooltip ? onClose : onNext}
        >
          {buttonText}
        </div>
      )}
      {onClose && (
        <FontAwesomeIcon
          icon="times"
          onClick={onClose}
          className={styles.icon}
        />
      )}
      <div className={styles.triangle} />
    </div>
  );
};

export default Tooltip;
