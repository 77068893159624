import React, {useEffect, useState, useCallback} from 'react';
import {observer} from 'mobx-react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {withFormik} from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import {Form as BootstrapForm} from 'react-bootstrap';
import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';
import Form from '../../components/ItemForm/Form';
import Footer from '../../components/ItemForm/Footer';
import Button from '../../components/Button';
import PrivacyFields from '../Onboarding/forms/PrivacyFields';
import FPFields from '../Onboarding/forms/FPFields';
import NPFields from '../Onboarding/forms/NPFields';
import GovFields from '../Onboarding/forms/GovFields';
import EditMemberBaseFields from './forms/EditMemberBaseFields';
import EditMemberContactFields from './forms/EditMemberContactFields';
import {SelectField} from '../../components/Fields';
import Subtitle from '../../components/Subtitle';
import {ExtraAttributeForm} from '../../components/ExtraAttribute';

const FieldsContainer = styled.div`
  margin-bottom: 2.5rem;
`;

const EditMemberForm = (props) => {
  const {org, handleSubmit} = props;
  const [countries, setCountries] = useState([]);
  const {t} = useTranslation('member-page');
  const clientFocusAreaTags = props.stores.app.clientData.focus_area_tags;
  const fetchCountries = useCallback(async () => {
    const response = await props.api.getCountries();
    const fetchedcountries = Object.keys(response).map((country) => ({
      id: country,
      name: response[country],
    }));

    setCountries(fetchedcountries);
  }, [props.api]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const mappedCountries = (country) => (
    <option key={country.id} value={country.id}>
      {country.name}
    </option>
  );

  return (
    <Form onSubmit={handleSubmit}>
      <FieldsContainer>
        <EditMemberBaseFields />
        {org.org_kind === 'nporg' && (
          <BootstrapForm.Group>
            <SelectField
              name="registeredCountry"
              label={t('form.label.registered-country')}
              isRequired
            >
              <option value="">{t('select')}</option>
              {countries.map(mappedCountries)}
            </SelectField>
          </BootstrapForm.Group>
        )}
        {org.org_kind === 'fporg' && (
          <BootstrapForm.Group>
            <SelectField
              name="incorporatedCountry"
              label={t('form.label.incorporated-country')}
              isRequired
            >
              <option value="">{t('select')}</option>
              {countries.map(mappedCountries)}
            </SelectField>
          </BootstrapForm.Group>
        )}
        {org.org_kind === 'fporg' && (
          <FPFields clientFocusAreaTags={clientFocusAreaTags} isFullWidth />
        )}
        {org.org_kind === 'nporg' && (
          <NPFields clientFocusAreaTags={clientFocusAreaTags} isFullWidth />
        )}
        {org.org_kind === 'govorg' && <GovFields />}
        <Subtitle>{t('form.subtitle.contact-information')}</Subtitle>
        <EditMemberContactFields />
        <Subtitle>{t('form.subtitle.privacy')}</Subtitle>
        <PrivacyFields org={org} isFullWidth />
        {org.extra_attribute && (
          <React.Fragment>
            <Subtitle>{t('form.subtitle.extra-attributes')}</Subtitle>
            <ExtraAttributeForm modelName={org.org_kind} />
          </React.Fragment>
        )}
        <Footer>
          <Button
            type="submit"
            disabled={props.isSubmitting}
            responsiveFullWidth
          >
            {t('form.buttons.save')}
          </Button>
        </Footer>
      </FieldsContainer>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'EditMemberForm',
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => {
    const {org} = props;
    let focusAreas = [];
    let sdgs = [];
    const clientFocusAreaTags = props.stores.app.clientData.focus_area_tags;

    if (org.org_kind === 'nporg' || org.org_kind === 'fporg') {
      focusAreas = org.categories.filter(
        (category) => category.focus_area_type === 'general',
      );
      sdgs = org.categories.filter(
        (category) => category.focus_area_type === 'sdg',
      );
    }

    const fieldsInitialValues = {
      description: org.description || '',
      logo: org.logo || null,
      locations: org.addresses || [],
      registeredCountry: org.registered_country || '',
      incorporatedCountry: org.incorporated_country || '',
      taxNumber: org.tax_identification || '',
      charity: org.is_registered || '',
      focusArea: focusAreas,
      otherFocusArea: org.other_focus_area || '',
      sdg: sdgs,
      levelGovernment: org.level_of_government || '',
      phone: org.phone_number || '',
      email: org.email || '',
      website: org.web_site || '',
      // vettedBy: '',
      phoneInfo: org.setting_phone,
      emailInfo: org.setting_email,
      addressInfo: org.setting_address,
      orgKind: org.org_kind,
      extra_attribute: org.extra_attribute,
      allowFocusAreaTags: clientFocusAreaTags,
    };

    return fieldsInitialValues;
  },
  validationSchema: yup.object().shape({
    description: yup.string().required(),
    logo: yup.object().nullable(),
    locations: yup.array().min(1).label('location'),
    levelGovernment: yup.string().when('orgKind', {
      is: 'govorg',
      then: yup.string().required(),
    }),
    registeredCountry: yup.string().when('orgKind', {
      is: 'nporg',
      then: yup.string().required(),
    }),
    incorporatedCountry: yup.string().when('orgKind', {
      is: 'fporg',
      then: yup.string().required(),
    }),
    taxNumber: yup
      .string()
      .when('orgKind', {
        is: 'nporg',
        then: yup.string().required(),
      })
      .when('orgKind', {
        is: 'fporg',
        then: yup.string().required(),
      }),
    charity: yup.string().when('orgKind', {
      is: 'nporg',
      then: yup.string().required(),
    }),
    // vettedBy: yup.string()
    //   .when('', {
    //     is: 'Nonprofit',
    //     then: yup.string().required(),
    //   }),
    focusArea: yup
      .array()
      .when('orgKind', {
        is: 'nporg',
        then: yup.array().min(1).label('focus-area'),
      })
      .when('orgKind', {
        is: 'fporg',
        then: yup.array().min(1).label('focus-area'),
      })
      .when('allowFocusAreaTags', {
        is: 'disabled',
        then: yup.array().min(0).label('sdg'),
      }),
    sdg: yup
      .array()
      .when('orgKind', {
        is: 'nporg',
        then: yup.array().min(1).label('sdg'),
      })
      .when('orgKind', {
        is: 'fporg',
        then: yup.array().min(1).label('sdg'),
      })
      .when('allowFocusAreaTags', {
        is: 'disabled',
        then: yup.array().min(0).label('sdg'),
      }),
    otherFocusArea: yup.string(),
    website: yup.string(),
    phone: yup.string(),
    email: yup.string().email(),
    phoneInfo: yup.string().required(),
    emailInfo: yup.string().required(),
    addressInfo: yup.string().required(),
  }),
  validate: async (values, props) => {
    try {
      await props.api.validateExtraAttribute(values.orgKind, {
        extra_attribute: values.extra_attribute,
      });
      return null;
    } catch (errors) {
      return errors;
    }
  },
  handleSubmit: async (values, {props, setSubmitting, setErrors}) => {
    let org = {
      description: values.description,
      logo_id: values.logo ? values.logo.id : null,
      web_site: values.website,
      phone_number: values.phone,
      email: values.email,
      addresses: values.locations,
      setting_email: values.emailInfo,
      setting_phone: values.phoneInfo,
      setting_address: values.addressInfo,
      extra_attribute: values.extra_attribute,
    };

    if (props.org.org_kind === 'govorg') {
      org = {
        ...org,
        level_of_government: values.levelGovernment,
        categories: [],
      };
    } else {
      const categories = [...values.sdg, ...values.focusArea].reduce(
        (categoriesArray, category) => {
          if (category.isOther) return categoriesArray;

          return [...categoriesArray, {id: category.id}];
        },
        [],
      );

      org = {
        ...org,
        tax_identification: values.taxNumber,
        categories: categories,
        other_focus_area: values.otherFocusArea,
      };

      if (props.org.org_kind === 'nporg') {
        org = {
          ...org,
          registered_country: values.registeredCountry,
          is_registered: values.charity,
          // vetted_by: values.vettedBy,
        };
      }

      if (props.org.org_kind === 'fporg') {
        org = {
          ...org,
          incorporated_country: values.incorporatedCountry,
        };
      }
    }

    try {
      await props.api.updateOrg(props.org.slug, org);
      setSubmitting(false);
      props.onEditSuccess();
    } catch (error) {
      if (Object.keys(error).length !== 0) {
        setErrors(error);
        setSubmitting(false);
      }
    }
  },
})(EditMemberForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  const history = useHistory();
  const api = useApi();
  return (
    <EnhancedForm api={api} history={history} stores={stores} {...props} />
  );
};

export default observer(FormWrapper);
