import React from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';

import theme from '../../theme';
import {useStores} from '../../contexts/StoresContext';
import {mediaQueries} from '../../constants';

const types = {
  success: theme.success,
  info: theme.success,
  alert: theme.danger,
  warning: theme.warning,
  testing: theme.testing,
};

const Wrapper = styled.div`
  background-color: ${(props) => types[props.type]};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  color: white;
  display: flex;
  align-items: center;
  padding: ${(props) => (props.type === 'testing' ? '2px 22px' : '10px 22px')};
  ${(props) => (props.type === 'testing' ? `justify-content: center` : '')}

  ${mediaQueries.lg} {
    display: block;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 1rem;
  margin-right: 13px;
  ${(props) => (props.type === 'testing' ? `color:#93989c` : '')}
  ${mediaQueries.lg} {
    font-size: 12px;
  }
`;

const Subtitle = styled.div`
  font-size: 0.8rem;
  ${(props) => (props.type === 'testing' ? `color:#93989c` : '')}

  ${mediaQueries.lg} {
    font-size: 12px;
  }
`;

const Toast = () => {
  const stores = useStores();
  const {toast} = stores.app;
  if (toast.length === 0) return null;

  const toasts = toast.toasts
    .slice()
    .reverse()
    .map((toast, index) => {
      return (
        <Wrapper key={index} type={toast.type}>
          <Title type={toast.type}>{toast.title}</Title>
          <Subtitle type={toast.type}>{toast.subtitle}</Subtitle>
        </Wrapper>
      );
    });

  return <React.Fragment>{toasts}</React.Fragment>;
};

export default observer(Toast);
