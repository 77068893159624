import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Form, Row, Col, Container, Button} from 'react-bootstrap';
import styles from './LocationForm.module.scss';
import {observer} from 'mobx-react';
import {useStores} from '../../../contexts/StoresContext';
import DialogV2 from '../../DialogV2';
import {useLocalStorageFlag} from '../../../hooks/localstorage';
import {LOCAL_STORAGE_KEYS} from '../../../constants';
import LocationAutocomplete from '../../LocationAutocomplete';
import Tooltips from '../Tooltips';
import {GoogleMap, Marker} from '@react-google-maps/api';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import {useSearchLocationApi} from '../../../hooks/search-location';
import {parseGeocodeResult} from '../../../utils/google.api';
import locationIcon from '../../../assets/icons/location.svg';
import LocationFormData from '../LocationFormData';
import LocationConfirm from '../LocationConfirm';
import Label from '../../Form/Label';

const DEFAULT_LOCATION = '0,0';

const LocationForm = ({address, onHide, onSubmit, onAddressAdded}) => {
  const [setShowTooltips, showTooltips] = useLocalStorageFlag(
    LOCAL_STORAGE_KEYS.showAddressTooltips,
    true,
  );
  const mapRef = useRef(null);
  const [zoom, setZoom] = useState(address.country ? 17 : 10);
  const [show, setShow] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [fullAddress, setFullAddress] = useState(address);
  const [showFirstTooltip, setShowFirstTooltip] = useState(true);
  const search = useSearchLocationApi();

  const {t} = useTranslation('add-items');
  const stores = useStores();

  useEffect(() => {
    fullAddress && setShow(true);
  }, [fullAddress]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: fullAddress.id,
      location_label: fullAddress.location_label || '',
      location_type: fullAddress.location_type || '',
      location_landmark: fullAddress.location_landmark || '',
      country: fullAddress.country || '',
      state: fullAddress.state || '',
      city: fullAddress.city || '',
      street: fullAddress.street || '',
      number: fullAddress.number || '',
    },
    validationSchema: Yup.object({
      location_label: Yup.string().test(
        'location-label-check',
        'Required',
        (label) => label && !/^\s*$/.test(label),
      ),
      location_type: Yup.string().required('Required'),
      location_landmark: Yup.string(),
      country: Yup.string(),
      state: Yup.string(),
      city: Yup.string(),
      street: Yup.string(),
      number: Yup.string(),
    }),
    onSubmit: (values) => {
      const street = values.street ? `${values.street}, ` : '';
      const number = values.number ? `${values.number}, ` : '';
      const city = values.city ? `${values.city}, ` : '';
      const state = values.state ? `${values.state}, ` : '';
      const country = values.country ? `${values.country}` : '';
      const place = `${street}${number}${city}${state}${country}`;
      onSubmit({
        ...fullAddress,
        location_label: values.location_label,
        location_type: values.location_type,
        location_landmark: values.location_landmark,
        country: values.country,
        state: values.state,
        city: values.city,
        street: values.street,
        number: values.number,
        place: place,
      });
    },
  });

  const parseLocation = (l) => ({
    lat: Number(l.split(',')[0]),
    lng: Number(l.split(',')[1]),
  });

  const center = useMemo(
    () =>
      parseLocation(
        fullAddress.location ? fullAddress.location : DEFAULT_LOCATION,
      ),
    [fullAddress.location],
  );

  const handleShowTooltips = () => {
    setShowTooltips(true);
    setShowFirstTooltip(true);
  };
  const handleLoad = (map) => {
    mapRef.current = map;
  };
  const handleHide = () => {
    setShow(false);
    setShowTooltips(false);
    onHide();
  };
  const handleAddressAdded = useCallback(
    (address, id) => {
      setZoom(17);
      setShowFirstTooltip(false);
      setFullAddress({
        ...address,
        id: id,
        location_original: address.location,
      });
      onAddressAdded(address, id);
    },
    [onAddressAdded],
  );
  const handleFillForm = useCallback(() => {
    setShow(false);
    setShowForm(true);
  }, []);

  const handleMarker = useCallback(
    async ({latLng}) => {
      let add;
      const response = await search.searchByLatLng(latLng.lat(), latLng.lng());
      const place = response.results[0];
      add = parseGeocodeResult(place);
      add.place = place.formatted_address;
      add.location = `${latLng.lat()},${latLng.lng()}`;
      add.location_original = `${latLng.lat()},${latLng.lng()}`;
      setFullAddress((old) => ({...old, ...add}));
      setZoom(17);
    },
    [search],
  );

  const handleBack = useCallback(() => {
    setShowForm(false);
    setShowConfirm(false);
    setShow(true);
  }, []);

  const handleNext = useCallback(() => {
    setShowForm(false);
    setShow(false);
    setShowConfirm(true);
  }, []);

  const handleZoomChange = useCallback(() => {
    const currentZoom = mapRef.current?.getZoom();
    currentZoom && setZoom(currentZoom);
  }, [mapRef]);

  return (
    <>
      <DialogV2
        show={show}
        onHide={handleHide}
        noHeader
        className={styles.modal}
        footer={() => (
          <>
            <Row className={styles.footerButtons}>
              <Button
                variant="outline-primary"
                onClick={handleHide}
                className={styles.modalButton}>
                {t('add-items:add-address-modal.buttons.back')}
              </Button>
              <Button
                variant="primary"
                disabled={
                  !formik.values.location_label ||
                  !formik.values.location_type ||
                  !formik.values.country
                }
                onClick={handleNext}
                className={styles.modalButton}>
                {t('add-items:add-address-modal.buttons.next')}
              </Button>
            </Row>
          </>
        )}>
        <GoogleMap
          onLoad={handleLoad}
          mapContainerStyle={{
            width: '100%',
            height: '27.5rem',
            borderRadius: '0.3rem 0.3rem 0 0',
          }}
          onDblClick={handleMarker}
          center={center}
          zoom={zoom}
          onZoomChanged={handleZoomChange}
          options={{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDoubleClickZoom: true,
          }}>
          {fullAddress?.country && (
            <Marker
              position={parseLocation(fullAddress.location)}
              draggable
              onDragEnd={handleMarker}
            />
          )}
          <div className={styles.searchControls}>
            <div className={styles.searcher}>
              <div className={styles.searcherInput}>
                <LocationAutocomplete
                  existingAddressId={fullAddress && fullAddress.id}
                  placeholder={t(`search:location-select.placeholder`)}
                  onAddressAdded={handleAddressAdded}
                />
              </div>
            </div>
            <div className={styles.questionIcon} onClick={handleShowTooltips}>
              <FontAwesomeIcon icon={faQuestion} size="sm" />
            </div>
            <div className={styles.closeIcon} onClick={handleHide}>
              <FontAwesomeIcon icon="times" size="1x" />
            </div>
          </div>
          {showTooltips && (
            <Tooltips
              setShowTooltips={setShowTooltips}
              showFirstTooltip={showFirstTooltip}
              setShowFirstTooltip={setShowFirstTooltip}
            />
          )}
        </GoogleMap>
        {showTooltips && <div className={styles.mapOverlay} />}
        <Container fluid>
          {fullAddress.country && (
            <Row className={styles.fullAddress}>
              <img
                src={locationIcon}
                className={styles.locationIcon}
                alt="location icon"
              />
              <div className={styles.fullAddressData}>
                <div className={styles.addressStreet}>
                  {fullAddress.number &&
                    `${fullAddress.number}, ${fullAddress.street}`}
                </div>
                <div>
                  {`${fullAddress.city ? `${fullAddress.city},` : ''} ${
                    fullAddress.state ? `${fullAddress.state},` : ''
                  }
                  ${fullAddress.country}`}
                </div>
              </div>
            </Row>
          )}
          <Form className={styles.locationForm}>
            <Row>
              <Col md="6">
                <Form.Group>
                  <Label isRequired>
                    {t('add-address-modal.form-labels.label')}
                  </Label>
                  <Form.Control
                    name="location_label"
                    onChange={formik.handleChange}
                    type="text"
                    placeholder={t('add-address-modal.form-placeholders.label')}
                    value={formik.values.location_label}
                    isInvalid={formik.errors.location_label}
                    className={styles.editableField}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.location_label}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <Label isRequired>
                    {t('add-address-modal.form-labels.type')}
                  </Label>
                  <Form.Control
                    name="location_type"
                    onChange={formik.handleChange}
                    as="select"
                    value={formik.values.location_type}
                    isInvalid={
                      formik.touched.location_type &&
                      formik.errors.location_type
                    }
                    className={styles.editableField}>
                    <option value="">
                      {t('add-address-modal.form-placeholders.type')}
                    </option>
                    {Object.keys(stores.app.choices.addressLocationType).map(
                      (value) => (
                        <option value={value} key={value}>
                          {stores.app.choices.addressLocationType[value]}
                        </option>
                      ),
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.location_type}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Control
                    name="location_landmark"
                    onChange={formik.handleChange}
                    type="text"
                    placeholder={t(
                      'add-address-modal.form-placeholders.landmark',
                    )}
                    value={formik.values.location_landmark}
                    isInvalid={
                      formik.touched.location_landmark &&
                      formik.errors.location_landmark
                    }
                    className={styles.editableField}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.location_landmark}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {address.location !== fullAddress.location && (
              <Row className={styles.warningAddress}>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className={styles.warningIcon}
                />
                <p>{t('add-address-modal.form-labels.different-address')}</p>
                <p onClick={handleFillForm} className={styles.fillForm}>
                  {t('add-address-modal.form-labels.fill-form')}
                </p>
              </Row>
            )}
          </Form>
        </Container>
      </DialogV2>
      <LocationFormData
        show={showForm}
        formik={formik}
        handleBack={handleBack}
        handleNext={handleNext}
      />
      <LocationConfirm
        show={showConfirm}
        formik={formik}
        handleBack={handleBack}>
        <GoogleMap
          onLoad={handleLoad}
          mapContainerStyle={{
            width: '100%',
            height: '27.5rem',
            borderRadius: '0.3rem 0.3rem 0 0',
          }}
          center={center}
          zoom={zoom}
          options={{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDoubleClickZoom: true,
          }}>
          {fullAddress?.country && (
            <Marker position={parseLocation(fullAddress.location)} />
          )}
          <div className={styles.closeIconContainer}>
            <div className={styles.closeIcon} onClick={handleHide}>
              <FontAwesomeIcon icon="times" size="1x" />
            </div>
          </div>
        </GoogleMap>
      </LocationConfirm>
    </>
  );
};

export default observer(LocationForm);
