import {observer} from 'mobx-react';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import {Form} from 'react-bootstrap';
import Step from './Step';
import {useStores} from '../../contexts/StoresContext';
import {useApi} from '../../contexts/ApiContext';
import Typeahead from './../../components/Form/Typeahead';
import Issue from './../../components/Issue';
import HorizontalScroll from '../../components/HorizontalScroll';
import styles from './IssueSelect.module.scss';
import FeedbackInvalid from '../../components/Form/FeedbackInvalid';

const TOTAL_RECENT_ISSUES = 3;

const IssueSelect = (props) => {
  const {t} = useTranslation('add-items');
  const [field, meta, helpers] = useField(props.name);
  const [options, setOptions] = useState([]);
  const [recentIssues, setRecentIssues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const stores = useStores();
  const api = useApi();

  const {value} = meta;
  const {setValue} = helpers;

  const onGoingIssue = stores.app.onGoingIssue;
  const showOnGoingIssue = props.showOnGoingIssue;

  useEffect(() => {
    const getIssues = async () => {
      const response = await api.getIssues({
        page_size: showOnGoingIssue
          ? TOTAL_RECENT_ISSUES - 1
          : TOTAL_RECENT_ISSUES,
        exclude_ongoing: true,
      });
      const results = response.results;

      if (showOnGoingIssue && onGoingIssue) {
        results.unshift(onGoingIssue);
      }

      setRecentIssues(results);
      setIsLoading(false);
    };

    getIssues();
  }, [showOnGoingIssue, onGoingIssue, api]);

  const handleSearch = async (search) => {
    if (!search) {
      setOptions([]);
    }

    setIsLoading(true);
    const response = await api.getIssues({
      search: search,
      exclude_ongoing: !showOnGoingIssue,
    });
    setOptions(response.results);
    setIsLoading(false);
  };

  const handleChange = (issues) => {
    setValue(issues.length ? issues[0] : null);
  };

  const renderRecentIssues = () => {
    if (value) {
      return (
        <div className={[styles.recentIssue]}>
          <Issue variant="full-width" issue={value} />
        </div>
      );
    }
    return recentIssues.map(renderIssue);
  };

  const renderIssue = (issue) => {
    return (
      <div key={`issue-${issue.id}`} className={styles.recentIssue}>
        <Issue onClick={() => setValue(issue)} issue={issue} variant="small" />
      </div>
    );
  };

  return (
    <Step title={t('add-needs.step-1.title')}>
      <div className={styles.fieldContainer}>
        <Form.Group>
          <Typeahead
            labelKey="name"
            options={options}
            id={`search-for-${field.name}`}
            onSearch={handleSearch}
            isLoading={isLoading}
            onChange={handleChange}
            placeholder={t('add-needs.step-1.placeholder')}
            selected={value ? [value] : []}
            data-hj-allow
          />
        </Form.Group>
      </div>
      <div className={styles.recentIssuesTitle}>{t('add-needs.recent')}</div>
      <HorizontalScroll>
        <div className={styles.recentIssuesContainer}>
          {renderRecentIssues()}
        </div>
      </HorizontalScroll>
      <FeedbackInvalid meta={meta} />
    </Step>
  );
};

export default observer(IssueSelect);
