import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useHistory} from 'react-router-dom';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './SearchInput.module.scss';

const SearchInput = ({initialValue}) => {
  const {t} = useTranslation('user');
  const [search, setSearch] = useState(initialValue);
  const history = useHistory();

  const onChange = (event) => {
    const newValue = event.target.value;
    setSearch(newValue);
    history.push(`?search=${newValue}`);
  };

  return (
    <div className={styles.container}>
      <input
        value={search}
        placeholder={t('placeholders.searchbar')}
        onChange={onChange}
        className={styles.input}
      />
      <FontAwesomeIcon icon="search" className={styles.icon} />
    </div>
  );
};

export default SearchInput;
