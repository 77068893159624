import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './OrganizationAward.module.scss';

const OrganizationAward = (props) => {
  return (
    <div className={styles.award}>
      <FontAwesomeIcon className={styles.awardIcon} icon="award" />
      {props.children}
    </div>
  );
};

export default OrganizationAward;
