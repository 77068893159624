import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {useApi} from '../../contexts/ApiContext';
import {mediaQueries} from '../../constants';

import Breadcrumbs from '../../components/Layout/Breadcrumbs';
import Dialog from '../../components/Dialog';
import Button from '../../components/Button';
import OrgInformation from './PendingInformation/OrgInformation';
import UserInformation from './PendingInformation/UserInformation';

const Header = styled.div`
  padding: 19px 103px 11px 15px;
  border-bottom: 1px solid #e8e8e8;
`;

const PanelTitle = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
`;

const Wrapper = styled.div`
  padding-top: 20px 0;
  margin: 0 15px;

  ${mediaQueries.lg} {
    padding-top: 0;
    margin-bottom: 30px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const RequestJoinInformation = (props) => {
  const api = useApi();
  const {onSave} = props;
  const {slug, userUUID} = useParams();
  const [org, setOrg] = useState(null);
  const [user, setUser] = useState(null);
  const [showAcceptDialog, setShowAcceptDialog] = useState(false);
  const [showDeclineDialog, setShowDeclineDialog] = useState(false);
  const {t} = useTranslation(['user', 'member-page']);

  const fetchOrg = useCallback(async () => {
    const response = await api.getOrg(slug);
    setOrg(response);
  }, [slug, api]);

  const fetchUser = useCallback(async () => {
    const response = await api.getUserDetails(userUUID);
    setUser(response);
  }, [userUUID, api]);

  useEffect(() => {
    fetchOrg();
    fetchUser();
  }, [fetchOrg, fetchUser]);

  const handleAcceptMember = () => {
    setShowAcceptDialog(true);
  };

  const handleDeclineMember = () => {
    setShowDeclineDialog(true);
  };

  const renderAcceptDialog = () => {
    const handleOnConfirm = async () => {
      await api.acceptUserRequest(user.uuid);
      onSave();
    };
    const handleOnCancel = () => setShowAcceptDialog(false);

    return (
      <Dialog
        header={t('user:dialogs.accept-new-member.header')}
        title={t('user:dialogs.accept-new-member.title')}
        message={t('user:dialogs.accept-new-member.message', {org: org.name})}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        confirmText={t('user:dialogs.accept-new-member.confirm')}
        cancelText={t('user:dialogs.accept-new-member.cancel')}
      />
    );
  };

  const renderDeclineDialog = () => {
    const handleOnConfirm = async (reason) => {
      await api.declineUserRequest(user.uuid, {reason});
      onSave();
    };
    const handleOnCancel = () => setShowDeclineDialog(false);

    return (
      <Dialog
        header={t('user:dialogs.decline-new-member.header')}
        title={t('user:dialogs.decline-new-member.title')}
        message={t('user:dialogs.decline-new-member.message', {org: org.name})}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        confirmText={t('user:dialogs.decline-new-member.confirm')}
        cancelText={t('user:dialogs.decline-new-member.cancel')}
        declineReasonTitle={t(
          'user:dialogs.decline-new-member.decline-reason-title',
        )}
        declineReasonPlaceholder={t(
          'user:dialogs.decline-new-member.placeholder',
        )}
        textErrorValue={t('user:dialogs.decline-new-member.error')}
        decline
      />
    );
  };

  if (!org || !user) return null;

  return (
    <React.Fragment>
      <Header>
        <Breadcrumbs />
        <PanelTitle>{t('member-page:titles.pending-member')}</PanelTitle>
      </Header>
      <Wrapper>
        <UserInformation user={user} />
        <OrgInformation org={org} />
        <Footer>
          <Button onClick={handleDeclineMember}>
            {t('member-page:form.buttons.decline')}
          </Button>
          <Button onClick={handleAcceptMember}>
            {t('member-page:form.buttons.approve')}
          </Button>
        </Footer>
      </Wrapper>
      {showAcceptDialog && renderAcceptDialog()}
      {showDeclineDialog && renderDeclineDialog()}
    </React.Fragment>
  );
};

export default RequestJoinInformation;
