import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {observer} from 'mobx-react';

import {mediaQueries} from '../../constants';
import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';
import {getUserOrganization} from '../../utils/user';
import {createTranslationKey} from '../../utils/i18n';
import {usePageStore} from '../../contexts/PageContext';
import {
  ONBOARDING_USER_DETAILS,
  ONBOARDING_USER_CREATION_FINISH,
  ONBOARDING_USER_APPROVAL_PENDING,
  ONBOARDING_ORGANIZATION_ADDITIONAL_INFORMATION,
} from '../../urls';
import {useFields} from '../../hooks/extraAttribute';

import Step from '../AddItems/Step';
import Button from '../../components/Button';
import BaseOrganizationFields from './forms/BaseOrganizationFields';
import PrivacyFields from './forms/PrivacyFields';
import NPFields from './forms/NPFields';
import OrgKindField from './forms/OrgKindField';
import GovFields from './forms/GovFields';
import FPFields from './forms/FPFields';
import Form from '../../components/Form/Form';
import {useStepper} from '../../contexts/StepperContext';

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${mediaQueries.lg} {
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
  ${mediaQueries.lg} {
    margin-left: 0;
    margin-top: 10px;
    width: 230px;
  }
`;

const FormInnerWrapper = styled.div`
  padding: 0 200px;
  ${mediaQueries.lg} {
    padding: 0;
  }
`;

const OrganizationDetails = (props) => {
  const {isSubmitting, handleSubmit, setFieldValue, values} = props;
  const {t} = useTranslation('create-account');
  const [, pageActions] = usePageStore();
  const {initialValues} = useFields(values.orgKind);
  const {setLastStep} = useStepper();
  const user = props.stores.app.user;
  const clientFocusAreaTags = props.stores.app.clientData.focus_area_tags;

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'organization-details'});
  }, [pageActions]);

  useEffect(() => {
    const fetchApprovalRequired = async () => {
      const response = await props.api.getClientData();
      setFieldValue('approvalRequired', response.approval_required);
    };

    fetchApprovalRequired();
  }, [setFieldValue, props.api]);

  useEffect(() => {
    setFieldValue('extra_attribute', initialValues);
  }, [initialValues, setFieldValue]);

  useEffect(() => {
    if (Object.keys(values.extra_attribute).length) {
      setLastStep(3);
    }
  }, [values.extra_attribute, setLastStep]);

  const handlePreviousOnClick = () => {
    props.history.push(ONBOARDING_USER_DETAILS);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Step
        title={t('steps.second', {organization: getUserOrganization(user)})}>
        <FormInnerWrapper>
          <BaseOrganizationFields />
          <OrgKindField />
          {values.orgKind === 'nporg' && (
            <NPFields clientFocusAreaTags={clientFocusAreaTags} />
          )}
          {values.orgKind === 'fporg' && (
            <FPFields clientFocusAreaTags={clientFocusAreaTags} />
          )}
          {values.orgKind === 'govorg' && <GovFields />}
          <PrivacyFields />
        </FormInnerWrapper>
      </Step>
      <FormFooter>
        <StyledButton
          type="button"
          variant="outline-primary"
          onClick={handlePreviousOnClick}>
          {t('buttons.previous')}
        </StyledButton>
        <StyledButton type="submit" disabled={isSubmitting}>
          {Object.keys(values.extra_attribute).length
            ? t('buttons.next-extra')
            : t('buttons.finish')}
        </StyledButton>
      </FormFooter>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'OrganizationDetails',
  mapPropsToValues: (props) => {
    const clientFocusAreaTags = props.stores.app.clientData.focus_area_tags;
    return {
      description: '',
      logo: null,
      website: '',
      phone: '',
      email: '',
      locations: [],
      orgKind: '',
      registeredCountry: '',
      incorporatedCountry: '',
      taxNumber: '',
      charity: '',
      // vettedBy: '',
      focusArea: [],
      sdg: [],
      otherFocusArea: '',
      phoneInfo: 'public',
      emailInfo: 'public',
      addressInfo: 'public',
      levelGovernment: '',
      approvalRequired: '',
      extra_attribute: {},
      allowFocusAreaTags: clientFocusAreaTags,
    };
  },
  validationSchema: yup.object().shape({
    description: yup.string().required(),
    logo: yup
      .object()
      .test(
        'logo',
        createTranslationKey('errors.logo'),
        (value) => !value || (value && value.size / 1024 / 1024 <= 2), // 2 MegaBytes
      )
      .nullable(),
    website: yup.string(),
    phone: yup.string(),
    email: yup.string().email(),
    locations: yup
      .array()
      .required(createTranslationKey('errors.locations'))
      .test(
        'locations',
        createTranslationKey('errors.locations'),
        (value) => value.length >= 1,
      ),
    orgKind: yup.string().required(),
    levelGovernment: yup.string().when('orgKind', {
      is: 'govorg',
      then: yup.string().required(),
    }),
    registeredCountry: yup.string().when('orgKind', {
      is: 'nporg',
      then: yup.string().required(),
    }),
    incorporatedCountry: yup.string().when('orgKind', {
      is: 'fporg',
      then: yup.string().required(),
    }),
    taxNumber: yup
      .string()
      .when('orgKind', {
        is: 'nporg',
        then: yup.string().required(),
      })
      .when('orgKind', {
        is: 'fporg',
        then: yup.string().required(),
      }),
    charity: yup.string().when('orgKind', {
      is: 'nporg',
      then: yup.string().required(),
    }),
    focusArea: yup
      .array()
      .when('orgKind', {
        is: 'nporg',
        then: yup.array().min(1).label('focus-area'),
      })
      .when('orgKind', {
        is: 'fporg',
        then: yup.array().min(1).label('focus-area'),
      })
      .when('allowFocusAreaTags', {
        is: 'disabled',
        then: yup.array().min(0).label('sdg'),
      }),
    sdg: yup
      .array()
      .when('orgKind', {
        is: 'nporg',
        then: yup.array().min(1).label('sdg'),
      })
      .when('orgKind', {
        is: 'fporg',
        then: yup.array().min(1).label('sdg'),
      })
      .when('allowFocusAreaTags', {
        is: 'disabled',
        then: yup.array().min(0).label('sdg'),
      }),
    otherFocusArea: yup.string(),
    phoneInfo: yup.string().required(),
    emailInfo: yup.string().required(),
    addressInfo: yup.string().required(),
  }),
  handleSubmit: async (values, {props, setSubmitting}) => {
    let org = {
      description: values.description,
      web_site: values.website,
      phone_number: values.phone,
      email: values.email,
      logo_id: values.logo ? values.logo.id : null,
      addresses: values.locations,
      org_kind: values.orgKind,
      setting_email: values.emailInfo,
      setting_phone: values.phoneInfo,
      setting_address: values.addressInfo,
      extra_attribute: values.extra_attribute,
    };

    if (values.orgKind === 'govorg') {
      org = {
        ...org,
        level_of_government: values.levelGovernment,
        categories: [],
      };
    } else {
      const categories = [...values.sdg, ...values.focusArea].reduce(
        (categoriesArray, category) => {
          if (category.isOther) return categoriesArray;

          return [...categoriesArray, {id: category.id}];
        },
        [],
      );

      org = {
        ...org,
        tax_identification: values.taxNumber,
        categories: categories,
        other_focus_area: values.otherFocusArea,
      };

      if (values.orgKind === 'nporg') {
        org = {
          ...org,
          registered_country: values.registeredCountry,
          is_registered: values.charity,
        };
      }

      if (values.orgKind === 'fporg') {
        org = {
          ...org,
          incorporated_country: values.incorporatedCountry,
        };
      }
    }

    if (Object.keys(values.extra_attribute).length) {
      setSubmitting(false);
      props.history.push(ONBOARDING_ORGANIZATION_ADDITIONAL_INFORMATION, {
        org: org,
        approvalRequired: values.approvalRequired,
      });
    } else {
      await props.api.createOrg(org);
      let redirectUrl = ONBOARDING_USER_CREATION_FINISH;

      if (values.approvalRequired && !props.stores.app.user.invitation_token) {
        redirectUrl = ONBOARDING_USER_APPROVAL_PENDING;
      }

      setSubmitting(false);
      props.history.push(redirectUrl);
    }
  },
})(OrganizationDetails);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  const history = useHistory();
  const api = useApi();
  return (
    <EnhancedForm api={api} history={history} stores={stores} {...props} />
  );
};

export default observer(FormWrapper);
