import React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {Redirect, useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import {withFormik} from 'formik';

import {mediaQueries} from '../../constants';
import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';
import {
  HOME_PAGE,
  ONBOARDING_ORGANIZATION_DETAILS,
  ONBOARDING_USER_APPROVAL_PENDING,
  ONBOARDING_USER_CREATION_FINISH,
} from '../../urls';

import Button from '../../components/Button';
import Step from '../AddItems/Step';
import {ExtraAttributeForm} from '../../components/ExtraAttribute';
import Form from '../../components/Form/Form';

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${mediaQueries.lg} {
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
  ${mediaQueries.lg} {
    margin-left: 0;
    margin-top: 10px;
    width: 230px;
  }
`;

const FormInnerWrapper = styled.div`
  padding: 0 200px 50px;
  ${mediaQueries.lg} {
    padding: 0;
  }
`;

const OrganizationAdditionalInformation = (props) => {
  const {handleSubmit, isSubmitting, location, values} = props;
  const org = location.state ? location.state.org : null;
  const {t} = useTranslation('create-account');

  const handlePreviousOnClick = () => {
    props.history.push(ONBOARDING_ORGANIZATION_DETAILS);
  };

  if (!org || (org && !org.org_kind)) {
    return <Redirect to={HOME_PAGE} />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Step title={t('steps.third')}>
        <FormInnerWrapper>
          {Object.keys(values.extra_attribute).length && (
            <ExtraAttributeForm modelName={org.org_kind} />
          )}
        </FormInnerWrapper>
      </Step>
      <FormFooter>
        <StyledButton
          type="button"
          variant="outline-primary"
          onClick={handlePreviousOnClick}>
          {t('buttons.previous')}
        </StyledButton>
        <StyledButton type="submit" disabled={isSubmitting}>
          {t('buttons.finish')}
        </StyledButton>
      </FormFooter>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'OrganizationAdditionalInformation',
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => {
    const org = props.location.state ? props.location.state.org : null;

    return {
      extra_attribute: org.extra_attribute,
    };
  },
  handleSubmit: async (values, {props, setSubmitting, setErrors}) => {
    const {org, approvalRequired} = props.location.state;
    const organization = {
      ...org,
      extra_attribute: values.extra_attribute,
    };

    try {
      await props.api.createOrg(organization);
      let redirectUrl = ONBOARDING_USER_CREATION_FINISH;

      if (approvalRequired && !props.stores.app.user.invitation_token) {
        redirectUrl = ONBOARDING_USER_APPROVAL_PENDING;
      }

      setSubmitting(false);
      props.history.push(redirectUrl);
    } catch (error) {
      props.stores.app.removeToasts();
      if (Object.keys(error).length !== 0) {
        setErrors(error);
        setSubmitting(false);
      }
    }
  },
})(OrganizationAdditionalInformation);

const FormWrapper = (props) => {
  const stores = useStores();
  const history = useHistory();
  const api = useApi();
  return (
    <EnhancedForm api={api} history={history} stores={stores} {...props} />
  );
};

export default observer(FormWrapper);
