import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import theme from '../../theme';

const Wrapper = styled.div`
  background: ${(props) => (props.selected ? theme.primaryColor : '#dddddd')};
  color: ${(props) => (props.selected ? '#ffffff' : '#000000')};
  border-radius: 50px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 13px;
  margin: 0 5px 10px 0;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};

  ${(props) => (props.onClick ? 'cursor: pointer;' : '')};

  &:focus {
    outline: none;
  }
`;

const RemoveIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  cursor: pointer;
`;

const Pill = (props) => {
  return (
    <Wrapper
      onClick={props.disabled ? null : props.onClick}
      selected={props.selected}
      tabIndex={props.tabIndex}
      onFocus={props.onFocus}
      onKeyDown={props.onKeyDown}
      disabled={props.disabled}
    >
      {props.children}
      {props.onRemove && (
        <RemoveIcon icon="times" size="xs" onClick={props.onRemove} />
      )}
    </Wrapper>
  );
};

export default Pill;
