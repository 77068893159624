import React from 'react';
import {Col, Row as BootstrapRow} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSortUp, faSortDown, faSort} from '@fortawesome/free-solid-svg-icons';

import Paginator from '../Paginator';
import PaginatorV2 from '../Paginator/PaginatorV2';
import {usePaginator} from '../../hooks/paginator';
import Row from '../../components/Row';
import TotalsRow from '../ItemReports/TotalsRow';
import styles from './Table.module.scss';

const Table = (props) => {
  const {
    paginatorParameterName,
    onRowClick,
    isClickeable,
    onHeaderClick,
    onViewDetails,
    type,
    sortBy,
    sortOrder,
    paginatorV2,
    quantityTotal,
    count,
    showPaginator = true,
    allowedColumns,
  } = props;

  const {changePage, currentPage} = usePaginator(paginatorParameterName);

  const headers = props.headers.map((header, index) => {
    const handleHeaderClick = () => {
      if (onHeaderClick) {
        onHeaderClick(header);
      } else return;
    };

    return (
      <Col
        onClick={handleHeaderClick}
        key={index}
        sm={header.size}
        className={`${header.className || ''} ${styles.headers}`}>
        {header.sortBy && (
          <FontAwesomeIcon
            className={styles.sortIcon}
            icon={
              header.sortBy === sortBy
                ? sortOrder === 'asc'
                  ? faSortUp
                  : faSortDown
                : faSort
            }
            style={{
              cursor: 'pointer',
              color: header.sortBy === sortBy ? 'black' : '#DEE2E6',
            }}
          />
        )}
        {header.title}
      </Col>
    );
  });

  const data = props.data.map((data, index) => {
    const handleOnClick = () => {
      if (onRowClick && data.side) {
        onRowClick(data);
      }
    };

    return (
      <Row
        key={index}
        onRowClick={handleOnClick}
        isClickeable={isClickeable && data.side}
        columns={props.columns}
        index={index}
        data={data}
        onViewDetails={onViewDetails}
        type={type}
      />
    );
  });

  const handlePageChange = (to, page) => changePage(to, page);

  return (
    <div className={styles.wrapper}>
      <div className={styles.columnNames}>
        <BootstrapRow
          style={
            props.headers[props.headers.length - 1].extraPadding > 0
              ? {
                  paddingRight:
                    props.headers[props.headers.length - 1].extraPadding,
                }
              : {}
          }>
          {headers}
        </BootstrapRow>
      </div>
      {data}
      {!!count && !!quantityTotal && (
        <TotalsRow
          itemTotal={count}
          quantityTotal={quantityTotal}
          allowedColumns={allowedColumns}
        />
      )}
      {showPaginator && (
        <>
          <BootstrapRow>
            <Col xs={12}>
              {paginatorV2 && (
                <PaginatorV2
                  total={props.count}
                  pageSize={props.pageSize}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              )}
              {!paginatorV2 && (
                <Paginator
                  className={styles.paginator}
                  total={props.count}
                  pageSize={props.pageSize}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              )}
            </Col>
          </BootstrapRow>
        </>
      )}
    </div>
  );
};

export default Table;
