import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useFormikContext} from 'formik';

import Button from '../../components/Button';
import {mediaQueries} from '../../constants';

const StyledButton = styled(Button)`
  width: auto;
  align-self: flex-end;
  margin-top: 30px;
  ${mediaQueries.lg} {
    align-self: center;
  }
`;

const FormFooter = () => {
  const {isSubmitting} = useFormikContext();
  const {t} = useTranslation('user');

  return (
    <StyledButton type="submit" disabled={isSubmitting}>
      {t('buttons.edit-profile')}
    </StyledButton>
  );
};

export default FormFooter;
