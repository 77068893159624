import React from 'react';
import {useTranslation, Trans} from 'react-i18next';
import {useHistory, Route} from 'react-router-dom';
import {withFormik} from 'formik';
import * as yup from 'yup';

import styled from 'styled-components';
import {Form as BootstrapForm, Col, Card} from 'react-bootstrap';

import {mediaQueries} from '../../constants';
import {InputField} from '../../components/Fields';
import Button from '../../components/Button';
import ThankYouModal from '../../components/Modals/ThankYouModal';
import {observer} from 'mobx-react';
import {useApi} from '../../contexts/ApiContext';
import Title from '../../components/Title/Title';

const Form = styled(BootstrapForm)`
  display: flex;
  padding: 50px 40px 0 40px;
  flex-direction: column;
  justify-content: center;
  width: 954px;
  overflow: hidden;

  ${mediaQueries.lg} {
    padding: 5px;
  }

  .card {
    padding: 30px 40px;

    ${mediaQueries.lg} {
      padding: 10px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${mediaQueries.lg} {
    height: 100vh;
  }
`;

const FieldsContainer = styled.div`
  margin-top: auto;
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;

  ${mediaQueries.lg} {
    justify-content: center;
    flex-direction: column-reverse;
  }
`;

const Intructions = styled.p`
  margin: 50px 0;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`;

const GoBackButton = styled(Button)`
  &.btn:hover {
    text-decoration: none;
  }
  &.btn-link {
    font-weight: 500;
  }
`;

const StyledTitle = styled(Title)`
  font-size: 22px;
  line-height: 27px;
  text-align: center;
`;

const ForgotPassword = (props) => {
  const {handleSubmit, isSubmitting, values} = props;
  const {t} = useTranslation('forgot-password');

  const handlePreviousOnClick = () => {
    props.history.push('/login');
  };

  return (
    <Wrapper>
      <Form noValidate onSubmit={handleSubmit}>
        <FieldsContainer>
          <Card>
            <StyledTitle>{t('title')}</StyledTitle>
            <Intructions>{t('instructions')}</Intructions>
            <Form.Group as={Col} sm={12}>
              <InputField
                type="text"
                placeholder={t('place-holder.email')}
                label={t('label.email')}
                name="email"
              />
            </Form.Group>
            <FormFooter>
              <GoBackButton
                type="button"
                variant="link"
                onClick={handlePreviousOnClick}
              >
                {t('buttons.go-back')}
              </GoBackButton>
              <Button type="submit" disabled={isSubmitting}>
                {t('buttons.reset-password')}
              </Button>
            </FormFooter>
          </Card>
        </FieldsContainer>
      </Form>
      <Route path={'/forgot-password/finish'}>
        <ThankYouModal
          titleMessage={t('label.success')}
          onPrimaryClick={handlePreviousOnClick}
          primaryButtonText={t('buttons.go-to-login')}
        >
          <Trans
            i18nKey="message"
            values={{email: values.email}}
            ns="forgot-password"
          >
            text <br /> text <br /> text
          </Trans>
        </ThankYouModal>
      </Route>
    </Wrapper>
  );
};

const EnhancedForm = withFormik({
  displayName: 'ForgotPassword',
  mapPropsToValues: () => {
    return {
      email: '',
    };
  },
  validationSchema: yup.object().shape({
    email: yup.string().email().required(),
  }),
  handleSubmit: async (values, {props, setSubmitting}) => {
    await props.api.forgotPassword({email: values.email});

    setSubmitting(false);
    props.history.push('/forgot-password/finish');
  },
})(ForgotPassword);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const history = useHistory();
  const api = useApi();
  return <EnhancedForm history={history} api={api} {...props} />;
};

export default observer(FormWrapper);
