import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import SearchBar from '../../components/SearchBar/SearchBar';
import {useApi} from '../../contexts/ApiContext';

const ReportsSearchBar = (props) => {
  const api = useApi();
  const {t} = useTranslation('reports');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtersCategory, setFiltersCategory] = useState({
    value: 'org',
    label: t('labels.view-member'),
  });

  const transformEntitiesToOptions = (labelKey, valueKey, entities) => {
    return entities.map((entity) => ({
      label: entity[labelKey],
      value: entity[valueKey],
    }));
  };

  const handleSearch = async (value) => {
    setLoading(true);
    const response = await api.getOrgs({org: value});
    setOptions(transformEntitiesToOptions('name', 'id', response.results));
    setLoading(false);
  };

  const handleOnChange = async (search) => {
    const val = search.length
      ? {
          label: search[0].label,
          value: search[0].value,
        }
      : null;

    props.onChange(val);
  };

  return (
    <SearchBar
      multiple={false}
      id="search-bar"
      className={props.className}
      isLoading={loading}
      onChange={handleOnChange}
      onSearch={handleSearch}
      onSearchCategoryClick={setFiltersCategory}
      value={filtersCategory}
      options={options}
      dropDownOptions={[]}
      allowNew={false}
      placeholder={t('searchbar-placeholder')}
      selectPartialMatchOnEnter
      persistValue
      allowClean
    />
  );
};

export default ReportsSearchBar;
