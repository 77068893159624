import {useState} from 'react';
import {useSearchAlgoliaStore} from '../../../contexts/SearchAlgoliaContext';
import {useSearchLocationApi} from '../../../hooks/search-location';

export default function useLocationInput() {
  const [searchState, searchActions] = useSearchAlgoliaStore();
  const searchLocationApi = useSearchLocationApi();
  const {places, place} = searchState;
  const {setPlaces, setCurrentPlace} = searchActions;

  const [openOptions, setOpenOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setLocation = async (location, places) => {
    if (location) {
      const place = await searchLocationApi.getPlace(location.place_id);

      location.place = {
        geometry: place.geometry,
        country: null,
      };

      const addressComponent = place.address_components.filter((c) =>
        c.types.includes('country'),
      );

      if (addressComponent) {
        location.place.country = addressComponent[0].short_name;
      }
      setPlaces(places);
      setCurrentPlace(location);
    }
  };

  const handleSearch = async (text) => {
    if (text.length === 0) {
      return;
    }

    setOpenOptions(true);
    setIsLoading(true);

    try {
      const places = await searchLocationApi.search(text);
      setPlaces(places);
    } catch (error) {
      setPlaces([]);
    }

    setIsLoading(false);
  };

  const handleChange = (selected) => {
    setOpenOptions(false);
    const location = selected.length > 0 ? selected[0] : null;
    setLocation(location, selected);
  };

  return {
    place,
    places,
    openOptions,
    isLoading,

    handleChange,
    handleSearch,
  };
}
