import React, {useContext, useEffect, useRef, useState} from 'react';

import {Form} from 'react-bootstrap';
import ErrorMessage from '../Helper/ErrorMessage';
import FormContext from '../../Form/FormContext';
import Label from '../Helper/Label';
import {useTranslation} from 'react-i18next';

const DropDownControl = (props) => {
  const {
    inlineLabel,
    setRef,
    inlineLabelPosition,
    without_special_characters,
    allow_other,
    choices,
    onChange,
    ...rest
  } = props;
  const {t} = useTranslation('common');
  const inputRef = useRef(null);
  const {setFieldRef} = useContext(FormContext);
  const [otherOption, setOtherOption] = useState('');
  const [dropDownOption, setDropDownOption] = useState('0');

  useEffect(() => {
    setFieldRef(props.name, inputRef);
  }, [props.name, setFieldRef]);

  const renderOtherInput = () => (
    <div>
      <Label>{t('name')}</Label>
      <Form.Control
        {...rest}
        value={otherOption}
        ref={inputRef}
        onChange={handleOnChange}
      />
    </div>
  );

  const renderOtherOption = () => (
    <option key="other" value="-1">
      {t('other')}
    </option>
  );

  const handleOnChange = (event) => {
    const newValue = event.target.value;

    if (event.target.localName === 'select') {
      setDropDownOption(newValue);
      setOtherOption('');
    } else {
      setOtherOption(newValue);
    }

    onChange(event);
  };

  const FormDropDown = () => {
    return (
      <Form.Control
        {...rest}
        as="select"
        value={dropDownOption}
        ref={inputRef}
        onChange={handleOnChange}>
        <option value="0">{t('other_placeholder')}</option>
        {choices &&
          choices.map((choice) => (
            <option key={choice.id} value={choice.id}>
              {choice.label}
            </option>
          ))}
        {allow_other && renderOtherOption()}
      </Form.Control>
    );
  };

  return (
    <Form.Group>
      <FormDropDown />
      {dropDownOption === '-1' && renderOtherInput()}
      <ErrorMessage>{props.error}</ErrorMessage>
    </Form.Group>
  );
};

export default DropDownControl;
