import styled from 'styled-components';

const ItemDataTitle = styled.div`
  padding-top: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export default ItemDataTitle;
