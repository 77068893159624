import styled from 'styled-components';
import {Card} from 'react-bootstrap';

const Placeholder = styled(Card)`
  width: 100%;
  padding: 40px 15px;
  font-size: 20px;

  &.card {
    display: block;
  }
`;

export default Placeholder;
