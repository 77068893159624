import {itemFromJSON} from '../../../../utils/deserializers';
import React, {useState, useMemo, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import ItemsList from '../../../../components/ItemsList';
import {useSearchStore} from '../../contexts/SearchContext';
import {usePageStore} from '../../../../contexts/PageContext';
import ManufacturersList from '../../../../components/ManufacturersList';
import Spinner from '../../../../components/Spinner';
import styles from './PanelPresence.module.scss';
import itemListStyles from '../../../../components/ItemsList/ItemsList.module.scss';
import {MAP_PAGE_V1} from '../../../../urls';
import Breadcrumbs from '../../../../components/Layout/Breadcrumbs';
import Paginator from '../../../../components/Paginator';
import {usePaginator} from '../../../../hooks/paginator';

const PanelPresence = () => {
  const history = useHistory();
  const {t} = useTranslation('search');
  const [isLoading] = useState(false);
  const [searchState] = useSearchStore();
  const {changePage, currentPage} = usePaginator();
  const [, pageActions] = usePageStore();

  const isManufacturer =
    searchState.presences.length > 0 &&
    searchState.presences.some((presence) =>
      presence.model?.includes('needslist_iop.Manufacturer'),
    );

  const items = useMemo(() => {
    return searchState.presences.map((presence) => {
      return isManufacturer ? presence.data : itemFromJSON(presence.data);
    });
  }, [searchState.presences, isManufacturer]);

  const handlePageChange = (to, page) => {
    changePage(to, page);
  };

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'search'});
  }, [pageActions]);

  return !searchState.presencesLoading ? (
    isManufacturer ? (
      <ManufacturersList isLoading={isLoading} items={items} />
    ) : (
      <React.Fragment>
        <div className={styles.panelHeader}>
          <Breadcrumbs />
          <h3>
            {t('panel-presence.title')} ({searchState.presencesCount})
          </h3>
          {searchState.organizationCount && (
            <h5 className="mt-3">
              {t('panel-presence.organization-count', {
                count: searchState.organizationCount,
              })}
            </h5>
          )}
        </div>

        <ItemsList
          className={`${itemListStyles.itemListContainer} ${itemListStyles.itemListItem}`}
          items={items}
          showInvalidItems={false}
          isLoading={isLoading}
          buttonText={t('cards.buttons.learn-more')}
          onButtonClick={(item) => {
            history.push(`${MAP_PAGE_V1}/item/${item.id}`);
          }}
        />
        <Paginator
          total={searchState.presencesCount}
          pageSize={10}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          className={styles.paginator}
        />
      </React.Fragment>
    )
  ) : (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  );
};

export default PanelPresence;
