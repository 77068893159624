import React, {useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {mediaQueries, Z_INDEX} from '../../constants';
import CategoryImage from '../CategoryImage';
import {ShareModal, ShareButton, ShareLink} from '../Share';

const BannerWrapper = styled(
  ({backgroundImageSmall, backgroundImageBanner, ...rest}) => <div {...rest} />,
)`
  height: 200px;
  width: 100%;
  overflow: hidden;
  padding-left: 20px;
  padding-top: 25px;
  border-radius: 15px;
  position: relative;
  ${(props) =>
    props.backgroundImageBanner
      ? `background-image: url(${props.backgroundImageBanner});
    background-size: cover;
    `
      : ''};

  ${mediaQueries.lg} {
    height: 258px;
    ${(props) =>
      props.backgroundImageSmall
        ? `background-image: url(${props.backgroundImageSmall});
    background-size: cover;
    `
        : ''};
  }

  ${(props) => (props.onClick ? 'cursor: pointer' : '')};
`;

const BannerTitle = styled.h1`
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: -0.41px;
  color: #ffffff;
  border-radius: 15px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  ${mediaQueries.lg} {
    font-size: 18px;
    line-height: 22px;
  }
`;

const Location = styled.h2`
  font-weight: 600;
  font-size: 22px;
  line-height: 35px;
  color: #ffffff;
  border-radius: 15px;
  text-transform: uppercase;

  ${mediaQueries.lg} {
    font-size: 12px;
    line-height: 10px;
  }
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: 1px solid white;
  font-size: 14px;
  border-radius: 15px;
  z-index: ${Z_INDEX.aboveOverlay};
  align-self: flex-start;
  padding: 5px 20px;
  color: white;
  font-size: 14px;
  outline: none;
  position: absolute;
  bottom: 20px;

  &:active {
    background: none;
    outline: none;
  }
`;

const StyledCategoryImage = styled(CategoryImage)`
  width: 80px;
`;

const StyledShareButton = styled(ShareButton)`
  position: absolute;
  bottom: 10px;
  right: 25px;
  &.btn {
    color: white;
  }
  &.btn:hover {
    color: white;
  }
`;

const Banner = (props) => {
  const {issue, CTAText, onClick} = props;
  const {t} = useTranslation('issue');
  const [showModal, setShowModal] = useState(false);
  const handleToggleModal = () => setShowModal(!showModal);

  return (
    <BannerWrapper
      onClick={onClick}
      backgroundImageBanner={issue.banner}
      backgroundImageSmall={issue.image}
    >
      <BannerTitle>{issue.name}</BannerTitle>
      <Location>
        {issue.location.city && `${issue.location.city}, `}
        {issue.location.country}
      </Location>
      {CTAText && <StyledButton>{CTAText}</StyledButton>}
      {issue.main_category && (
        <StyledCategoryImage src={issue.main_category.image} />
      )}
      {!onClick && (
        <React.Fragment>
          <StyledShareButton onClick={handleToggleModal} label={t('issue')} />
          {showModal && (
            <ShareModal onClose={handleToggleModal} label={t('issue')}>
              <ShareLink />
            </ShareModal>
          )}
        </React.Fragment>
      )}
    </BannerWrapper>
  );
};

export default Banner;
