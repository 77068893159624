import React from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHandPaper} from '@fortawesome/free-regular-svg-icons';
import styles from './Tooltips.module.scss';

const Tooltips = ({setShowTooltips, showFirstTooltip, setShowFirstTooltip}) => {
  const {t} = useTranslation('common');
  return showFirstTooltip ? (
    <div className={styles.firstTooltip}>
      <div className={styles.flapUpTooltip} />
      <div className="d-flex">
        <p className="text-left mr-3">{t('tooltips.address.tooltip-1.text')}</p>
        <button
          className={styles.tooltipCloseButton}
          aria-label="close"
          onClick={() => setShowTooltips(false)}
        >
          <FontAwesomeIcon icon="times" size="sm" />
        </button>
      </div>
      <button
        className={styles.tooltipNextButton}
        onClick={() => setShowFirstTooltip(false)}
      >
        {t('tooltips.address.tooltip-1.button')}
      </button>
    </div>
  ) : (
    <React.Fragment>
      <div className={styles.secondTooltip}>
        <div className="d-flex">
          <p className="text-left mr-3">
            {t('tooltips.address.tooltip-2.text')}
          </p>
          <button
            className={styles.tooltipCloseButton}
            aria-label="close"
            onClick={() => setShowTooltips(false)}
          >
            <FontAwesomeIcon icon="times" size="sm" />
          </button>
        </div>
        <button
          className={styles.tooltipNextButton}
          onClick={() => setShowTooltips(false)}
        >
          {t('tooltips.address.tooltip-2.button')}
        </button>
        <div className={styles.flapDownTooltip} />
      </div>
      <div className={styles.handDrag}>
        <FontAwesomeIcon icon={faHandPaper} size="sm" />
      </div>
    </React.Fragment>
  );
};

export default Tooltips;
