import React, {useState, useEffect, useCallback} from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useParams} from 'react-router-dom';

import {ItemPanel} from '../ItemPanel';
import ProgressIndicator from '../ProgressIndicator/ProgressIndicator';
import ClaimList from '../ClaimList';
import {Route} from '../Layout';
import ClaimDetails from './ClaimDetails';

import {useItem} from '../../hooks/items';
import {useApi} from '../../contexts/ApiContext';
import Spinner from '../Spinner';
import styles from './ItemConfirmForm.module.scss';

const ItemConfirmForm = () => {
  const [claims, setClaims] = useState([]);
  const api = useApi();
  const match = useRouteMatch();
  const {itemId} = useParams();
  const [item, isLoading, fetchItem] = useItem(itemId);
  const history = useHistory();
  const {t} = useTranslation('confirm');
  const queryStringParams = new URLSearchParams(history.location.search);
  const pathname = window.location.pathname;
  const isOurClaims = pathname.includes('claimed-item-confirmation');
  const currentPage = queryStringParams.get('page');

  const title =
    item && item.side === 'offer' ? t('title-offer') : t('title-need');

  const fetchClaims = useCallback(() => {
    const fetchClaims = async () => {
      const response = await api.getClaimItems(
        itemId,
        isOurClaims
          ? {
              is_our_claims: isOurClaims,
            }
          : null,
      );
      setClaims(response.results);
    };
    fetchClaims();
  }, [itemId, isOurClaims, api]);
  const handleViewDetails = (claim) => {
    history.push(`${match.url}/${claim.id}?page=${currentPage}`);
  };
  const handleGoBack = () => {
    history.goBack();
    fetchClaims();
  };
  const onItemUpdated = () => {
    fetchItem();
  };

  useEffect(() => {
    fetchClaims();
  }, [fetchClaims]);

  if (!item || isLoading)
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );

  const progressBar = (
    <ProgressIndicator
      total={item.quantity || 0}
      progress={item.claimedQuantity}
      uomFulfillment={item.uomFulfillment}
    />
  );

  return (
    <React.Fragment>
      <ItemPanel item={item} title={title} showOrganizationLogo>
        {progressBar}
        <Route exact path={match.url}>
          <ClaimList
            className={styles.claimedList}
            side={item.side}
            claims={claims}
            actionText={t('button.view-details')}
            onActionClick={handleViewDetails}
          />
        </Route>
        <Route exact path={`${match.url}/:claimId`}>
          <ClaimDetails
            item={item}
            onBack={handleGoBack}
            onClaimUpdated={onItemUpdated}
          />
        </Route>
      </ItemPanel>
    </React.Fragment>
  );
};

export default ItemConfirmForm;
