import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Tabs from '../../components/Tabs';
import {Tab} from 'react-bootstrap';
import ItemsList from '../ItemsList';
import FiltersContext from '../FiltersProvider/FiltersContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import theme from '../../theme';
import {useLocalStorageFlag} from '../../hooks/localstorage';
import {LOCAL_STORAGE_KEYS} from '../../constants';
import Onboarding from '../../pages/Database/Onboarding';
import {useLocaleNumber} from '../../hooks/number';
import {ONGOING_ISSUE} from '../../constants';
import {useStores} from '../../contexts/StoresContext';
import {useApi} from '../../contexts/ApiContext';

const StyledTabs = styled(Tabs)`
  padding-bottom: 0;
  text-transform: uppercase;

  &.nav {
    margin-bottom: 30px;
  }

  &.nav-tabs {
    .nav-item.nav-link {
      padding-top: 0;
    }
  }
`;

const ContextualHelp = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;

const Items = (props) => {
  const {needsCount, offersCount, currentIssue} = props;
  const api = useApi();
  const stores = useStores();
  const history = useHistory();
  const match = useRouteMatch();
  const localeNumber = useLocaleNumber();
  const [setShowOnboarding, showOnboarding] = useLocalStorageFlag(
    LOCAL_STORAGE_KEYS.databaseOnboarding,
    true,
  );
  const {clearFilter, overrideFilters} = useContext(FiltersContext);
  const [selectedKey, setSelectedKey] = useState('all');
  const {t} = useTranslation('search');

  const handleTabSelected = (activeKey) => {
    history.push(match.url);
    if (activeKey !== 'all') {
      overrideFilters('side', [
        {
          label: activeKey,
          value: activeKey,
        },
      ]);
    } else {
      clearFilter('side');
    }

    setSelectedKey(activeKey);
  };

  const itemsList = (
    <ItemsList
      items={props.items}
      buttonText={t('cards.buttons.learn-more')}
      onButtonClick={props.onItemClick}
      showInvalidItems={false}
      isLoading={props.isLoading}
    />
  );
  const handleToggleOnboarding = () => setShowOnboarding(!showOnboarding);

  useEffect(() => {
    if (currentIssue === ONGOING_ISSUE) {
      setSelectedKey('offer');
      overrideFilters('side', [
        {
          label: selectedKey,
          value: selectedKey,
        },
      ]);
    }
  }, [overrideFilters, currentIssue, selectedKey]);

  useEffect(() => {
    const getAdminEmail = async () => {
      const response = await api.getAdminEmail();
      stores.app.setAdminEmail(response.admin_email);
    };
    getAdminEmail();
  }, [api, stores]);

  return (
    <React.Fragment>
      {showOnboarding && <Onboarding onClose={handleToggleOnboarding} />}
      {currentIssue === ONGOING_ISSUE ? (
        <StyledTabs
          mountOnEnter
          onSelect={handleTabSelected}
          activeKey={selectedKey}>
          <Tab
            eventKey="offer"
            title={t('tabs.offers', {count: localeNumber(offersCount) || 0})}>
            {itemsList}
          </Tab>
        </StyledTabs>
      ) : (
        <StyledTabs
          mountOnEnter
          onSelect={handleTabSelected}
          activeKey={selectedKey}>
          <Tab
            eventKey="all"
            title={t('tabs.all', {
              count: localeNumber(needsCount + offersCount) || 0,
            })}>
            {itemsList}
          </Tab>
          <Tab
            eventKey="need"
            title={t('tabs.needs', {count: localeNumber(needsCount) || 0})}>
            {itemsList}
          </Tab>
          <Tab
            eventKey="offer"
            title={t('tabs.offers', {count: localeNumber(offersCount) || 0})}>
            {itemsList}
          </Tab>
        </StyledTabs>
      )}
      <ContextualHelp
        icon={faQuestionCircle}
        color={theme.info}
        size="lg"
        onClick={handleToggleOnboarding}
      />
    </React.Fragment>
  );
};

export default Items;
