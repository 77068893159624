import {createContext, useContext, useReducer, useMemo} from 'react';
import {useMapAlgoliaStore} from './MapAlgoliaContext';

const initialState = {
  isMapView: false,
  activeTab: 'all',
  currentIssue: null,
  hideFilters: false,
  places: [],
  place: null,
};

const SearchAlgoliaContext = createContext();

const types = {
  SEARCH_SET_IS_MAP_VIEW: 'SEARCH_SET_IS_MAP_VIEW',
  SEARCH_SET_ACTIVE_TAB: 'SEARCH_SET_ACTIVE_TAB',
  SEARCH_SET_HIDE_FILTERS: 'SEARCH_SET_HIDE_FILTERS',
  SEARCH_SET_CURRENT_ISSUE: 'SEARCH_SET_CURRENT_ISSUE',
  SEARCH_SET_PLACES: 'SEARCH_SET_PLACES',
  SEARCH_SET_CURRENT_PLACE: 'SEARCH_SET_CURRENT_PLACE',
};

const reducer = (state, action) => {
  const {type, payload} = action;

  switch (type) {
    case types.SEARCH_SET_IS_MAP_VIEW: {
      return {
        ...state,
        isMapView: payload,
      };
    }
    case types.SEARCH_SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: payload,
      };
    }
    case types.SEARCH_SET_HIDE_FILTERS: {
      return {
        ...state,
        hideFilters: payload,
      };
    }
    case types.SEARCH_SET_CURRENT_ISSUE: {
      return {
        ...state,
        currentIssue: payload,
      };
    }
    case types.SEARCH_SET_PLACES: {
      return {
        ...state,
        places: payload.results,
      };
    }
    case types.SEARCH_SET_CURRENT_PLACE: {
      return {
        ...state,
        place: payload.place,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const SearchAlgoliaProvider = ({children}) => {
  const [, mapAlgoliaActions] = useMapAlgoliaStore();
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const actions = useMemo(() => {
    return {
      setIsMapView: (isMapView) => {
        dispatch({
          type: types.SEARCH_SET_IS_MAP_VIEW,
          payload: isMapView,
        });
      },
      setActiveTab: (activeTab) => {
        dispatch({
          type: types.SEARCH_SET_ACTIVE_TAB,
          payload: activeTab,
        });
      },
      setHideFilters: (hideFilters) => {
        dispatch({
          type: types.SEARCH_SET_HIDE_FILTERS,
          payload: hideFilters,
        });
      },
      setCurrentIssue: (currentIssue) => {
        dispatch({
          type: types.SEARCH_SET_CURRENT_ISSUE,
          payload: currentIssue,
        });
      },
      setPlaces: (results) => {
        dispatch({
          type: types.SEARCH_SET_PLACES,
          payload: {
            results,
          },
        });
      },
      setCurrentPlace: (location) => {
        mapAlgoliaActions.setPlace(location?.place || null);
        dispatch({
          type: types.SEARCH_SET_CURRENT_PLACE,
          payload: {
            place: location,
          },
        });
      },
    };
  }, [dispatch, mapAlgoliaActions]);

  return (
    <SearchAlgoliaContext.Provider value={[state, actions]}>
      {children}
    </SearchAlgoliaContext.Provider>
  );
};

const useSearchAlgoliaStore = () => {
  const context = useContext(SearchAlgoliaContext);
  if (context === undefined) {
    throw new Error(
      '`useSearchAlgoliaStore` must be used within a `SearchAlgoliaContext`.',
    );
  }
  return context;
};

export {SearchAlgoliaContext, SearchAlgoliaProvider, useSearchAlgoliaStore};
