import {useLoadScript} from '@react-google-maps/api';
import React, {useEffect} from 'react';

function ApiLoaderWrapper({lang, children}) {
  const {isLoaded, loadError} = useLoadScript({
    libraries: window.GOOGLE_MAP_LIBRARIES,
    googleMapsApiKey: window.GOOGLE_API_KEY,
    language: lang,
  });
  useEffect(() => {
    return () => {
      window.google = {};
    };
  }, []);

  return isLoaded && !loadError && <React.Fragment>{children}</React.Fragment>;
}

export default ApiLoaderWrapper;
