import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import theme from '../../theme';

const Wrapper = styled.div`
  line-height: 18px;
`;

const DonatedAmount = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

const ClaimStatus = styled.div`
  color: #808080;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
`;

const StatusIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  margin: 0 5px;
`;

const statusBackgroundColorMap = {
  submitted: '#979797',
  fulfilled: theme.success,
  rejected: theme.danger,
};

const ClaimHeader = (props) => {
  const {claim, side} = props;
  const {t} = useTranslation(['claim', 'item']);
  const statusBackgroundColor =
    statusBackgroundColorMap[claim.confirmationStatus.key];
  const donatedLabel =
    side === 'need'
      ? t('claim:quantity-donated', {quantity: claim.quantity})
      : t('claim:quantity-claimed', {quantity: claim.quantity});
  return (
    <Wrapper>
      {claim.claimOrganizationName}
      <DonatedAmount>{donatedLabel}</DonatedAmount>
      <ClaimStatus>
        {claim.confirmationStatus.value}
        <StatusIcon backgroundColor={statusBackgroundColor} />
        {claim.confirmationStatus.key !== 'submitted' &&
          moment(claim.updatedAt).format('MM/DD/YYYY')}
      </ClaimStatus>
    </Wrapper>
  );
};

export default ClaimHeader;
