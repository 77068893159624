import React, {useState} from 'react';

import OnboardingCarousel, {
  OnboardingCarouselItem,
} from '../../../components/OnboardingCarousel';
import FirstSlide from './FirstSlide';
import SecondSlide from './SecondSlide';
import ThirdSlide from './ThirdSlide';
import {useWindowSize} from '../../../hooks/utils';
import {breakpoints} from '../../../constants';

const Onboarding = (props) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const windowSize = useWindowSize();

  const handleSelect = (index) => {
    setActiveSlide(index);
  };
  const handleNext = () => {
    setActiveSlide(activeSlide + 1);
  };
  const handlePrevious = () => {
    setActiveSlide(activeSlide - 1);
  };

  return (
    <OnboardingCarousel
      activeIndex={activeSlide}
      onSelect={handleSelect}
      touch={windowSize.width > breakpoints.lg}
    >
      <OnboardingCarouselItem onClose={props.onClose}>
        <FirstSlide onNext={handleNext} />
      </OnboardingCarouselItem>
      <OnboardingCarouselItem onClose={props.onClose}>
        <SecondSlide onNext={handleNext} onPrevious={handlePrevious} />
      </OnboardingCarouselItem>
      <OnboardingCarouselItem onClose={props.onClose}>
        <ThirdSlide onPrevious={handlePrevious} onClose={props.onClose} />
      </OnboardingCarouselItem>
    </OnboardingCarousel>
  );
};

export default Onboarding;
