import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {withFormik} from 'formik';
import {Form as BootstrapForm, Row, Col, Card} from 'react-bootstrap';
import styled from 'styled-components';
import * as yup from 'yup';

import {mediaQueries} from '../../constants';
import {useApi} from '../../contexts/ApiContext';
import {InputField} from '../../components/Fields';
import {passwordValidationScheme} from '../../utils/yupCustomFunctions';
import Button from '../../components/Button';
import SubTitle from '../../components/Subtitle';

const Form = styled(BootstrapForm)`
  display: flex;
  padding: 50px 0;
  flex: 1;
  flex-direction: column;

  ${mediaQueries.lg} {
    padding: 15px;
  }
`;

const StyledCard = styled(Card)`
  padding: 20px 30px;
  font-size: 14px;
  margin-bottom: 20px;
`;

const RowContainer = styled(Row)`
  margin: 10px 0;
`;

const Footer = styled.div`
  margin: 10px 0;
  text-align: right;

  ${mediaQueries.lg} {
    margin-top: 80px;
    text-align: center;
  }
`;

const StyledSubTitle = styled(SubTitle)`
  margin: 0 0 30px;
`;

const ChangePasswordForm = (props) => {
  const {handleSubmit} = props;
  const {t} = useTranslation('user');

  return (
    <Form noValidate onSubmit={handleSubmit} data-testid="changePasswordForm">
      <StyledCard>
        <StyledSubTitle>{t('titles.change-password')}</StyledSubTitle>
        <RowContainer>
          <Col lg={6}>
            <InputField
              name="currentPassword"
              label={t('form-fields.change-password.current-password')}
              placeholder={t('placeholders.change-password.current-password')}
              type="password"
              isRequired
            />
          </Col>
        </RowContainer>
        <RowContainer>
          <Col lg={6}>
            <InputField
              name="password"
              label={t('form-fields.change-password.new-password')}
              placeholder={t('placeholders.change-password.new-password')}
              type="password"
              isRequired
            />
          </Col>
          <Col lg={6}>
            <InputField
              name="confirmPassword"
              label={t('form-fields.change-password.repeat-new-password')}
              placeholder={t(
                'placeholders.change-password.repeat-new-password',
              )}
              type="password"
              isRequired
            />
          </Col>
        </RowContainer>
        <Footer>
          <Button
            type="submit"
            size="sm"
            disabled={props.isSubmitting}
            responsiveFullWidth
          >
            {t('buttons.change-password')}
          </Button>
        </Footer>
      </StyledCard>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'ChangePasswordForm',
  mapPropsToValues: () => {
    return {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    };
  },
  validationSchema: yup
    .object()
    .shape({
      currentPassword: yup.string().required(),
    })
    .concat(passwordValidationScheme),
  handleSubmit: async (values, {props, setSubmitting, setErrors}) => {
    const {api, updatePasswordToastVisibility} = props;
    const newPass = {
      current_password: values.currentPassword,
      password: values.password,
      repeated_password: values.confirmPassword,
    };

    try {
      await api.changePassword(newPass);
      setSubmitting(false);
      updatePasswordToastVisibility(/* showToast */ true);
    } catch (error) {
      if (error.status || error.password) {
        setErrors({
          currentPassword: error.password || undefined,
          password: error.status || undefined,
        });
      }
      setSubmitting(false);
      updatePasswordToastVisibility(/* showToast */ false);
    }
  },
})(ChangePasswordForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const api = useApi();
  return <EnhancedForm api={api} {...props} />;
};

export default observer(FormWrapper);
