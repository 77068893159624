import React, {useCallback, useContext, useReducer} from 'react';

const defaultStepperState = {
  steps: [],
  currentStep: 0,
};

const types = {
  SET_STEPS: 'SET_STEPS',
  SET_LAST_STEP: 'SET_LAST_STEP',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  INCREMENT_CURRENT_STEP: 'INCREMENT_CURRENT_STEP',
  DECREMENT_CURRENT_STEP: 'DECREMENT_CURRENT_STEP',
};

const reducer = (state = defaultStepperState, action) => {
  const {currentStep, steps} = state;
  const {type, payload} = action;
  switch (type) {
    case types.SET_STEPS:
      return {
        ...state,
        steps: payload.steps,
      };
    case types.SET_LAST_STEP:
      const indexStep = steps.findIndex((step) => Number(step.id) === payload);
      if (indexStep === -1) {
        return {
          ...state,
        };
      }
      const newArray = [...steps];
      const stepToOrder = newArray.splice(indexStep, 1);
      newArray.push(stepToOrder[0]);
      return {
        ...state,
        steps: newArray,
      };
    case types.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: payload,
      };
    case types.INCREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep:
          currentStep < steps.length - 1 ? currentStep + 1 : currentStep,
      };
    case types.DECREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: currentStep > 0 ? currentStep - 1 : currentStep,
      };
    default:
      return state;
  }
};

export const StepperContext = React.createContext();

export const StepperProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, defaultStepperState);

  return (
    <StepperContext.Provider value={[state, dispatch]}>
      {children}
    </StepperContext.Provider>
  );
};

export const useStepper = () => {
  const [state, dispatch] = useContext(StepperContext);
  const {currentStep, steps} = state;

  if (!StepperContext) {
    throw new Error('useStepper should be used inside StepperProvider');
  }

  const incrementCurrentStep = useCallback(() => {
    dispatch({
      type: types.INCREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const decrementCurrentStep = useCallback(() => {
    dispatch({
      type: types.DECREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const setSteps = useCallback(
    (steps) => dispatch({type: types.SET_STEPS, payload: {steps}}),
    [dispatch],
  );
  const setLastStep = useCallback(
    (lastStep) => dispatch({type: types.SET_LAST_STEP, payload: lastStep}),
    [dispatch],
  );
  const setCurrentStep = useCallback(
    (currentStep) => {
      dispatch({type: types.SET_CURRENT_STEP, payload: currentStep});
    },
    [dispatch],
  );

  return {
    incrementCurrentStep,
    decrementCurrentStep,
    setSteps,
    setLastStep,
    setCurrentStep,
    currentStep,
    steps,
  };
};
