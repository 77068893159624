import {useState, useMemo, useCallback, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Form} from 'react-bootstrap';
import {useReportStore} from '../../pages/ItemReportsPage/context/ReportContext';
import Button from './Button';
import ColumnsIcon from '../../assets/icons/columns.svg';
import styles from './CustomizeColumns.module.scss';
import sharedStyles from './shared.module.scss';

export const COLUMNS_QUERY_PARAM_NAME = 'columns';
export const ITEM_COLUMN = 'item';
export const QUANTITY_COLUMN = 'quantity';
export const UNIT_COLUMN = 'unit';
export const TYPE_COLUMN = 'type';
export const STATUS_COLUMN = 'status';
export const CREATION_DATE_COLUMN = 'creation-date';
export const MET_TIMESTAMP = 'met-timestamp';
export const ISSUE_COLUMN = 'issue';
export const PLATFORM_INTERACTION_COLUMN = 'pi';
export const MET_CLAIMED_COLUMN = 'met-claimed-by';
export const OWNER_ORGANIZATION_COLUMN = 'posted-by';
export const VALUE_COLUMN = 'market-value';
export const TOTAL_AMOUNT = 'total-amount';
export const SECTOR_COLUMN = 'sector';
export const CATEGORY_COLUMN = 'category';
export const FULFILMENT_COLUMN = 'fulfilment';
export const MARKET_VALUE_NUMERIC_CSV_COLUMN = 'market-value-numeric';
export const MARKET_VALUE_UNIT_CSV_COLUMN = 'market-value-unit';
export const EXPORT_SEARCH_LINK = 'export-search-link';
export const BENEFICIARIES_COLUMN = 'beneficiaries';
export const INDIVIDUALS_QUANTITY_COLUMN = 'individuals-quantity';
export const GET_DEFAULT_COLUMNS = (isAdmin) => {
  const columns = [
    QUANTITY_COLUMN,
    TOTAL_AMOUNT,
    PLATFORM_INTERACTION_COLUMN,
    STATUS_COLUMN,
    FULFILMENT_COLUMN,
    MET_CLAIMED_COLUMN,
  ];
  isAdmin && columns.push(OWNER_ORGANIZATION_COLUMN);
  return columns;
};

const CustomizeColumns = (props) => {
  const {value, isAdmin} = props;
  const [selected, setSelected] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const history = useHistory();
  const {t} = useTranslation('item-reports');
  const [reportState] = useReportStore();

  const all_columns = useMemo(() => {
    const array = [
      QUANTITY_COLUMN,
      TYPE_COLUMN,
      CATEGORY_COLUMN,
      STATUS_COLUMN,
      CREATION_DATE_COLUMN,
      MET_TIMESTAMP,
      ISSUE_COLUMN,
      PLATFORM_INTERACTION_COLUMN,
      TOTAL_AMOUNT,
      FULFILMENT_COLUMN,
      BENEFICIARIES_COLUMN,
      MET_CLAIMED_COLUMN,
    ];
    if (isAdmin || reportState.activeTab === 'transactions') {
      array.push(OWNER_ORGANIZATION_COLUMN);
    }
    return array;
  }, [isAdmin, reportState.activeTab]);

  const applySelection = () => {
    const value = visibleColumns.join(',');
    const queryStringParams = new URLSearchParams(history.location.search);
    queryStringParams.set(COLUMNS_QUERY_PARAM_NAME, value);
    setSelected(false);
    history.push({search: queryStringParams.toString()});
  };

  const onChange = useCallback(
    (e) => {
      const updatedList = [...visibleColumns];
      if (e.target.checked) {
        updatedList.push(e.target.value);
      } else {
        updatedList.splice(visibleColumns.indexOf(e.target.value), 1);
      }
      setVisibleColumns(updatedList);
    },
    [visibleColumns],
  );

  window.addEventListener('click', (e) => {
    const customizeColumnsDropdown = document.getElementById(
      'customizeColumnsDropdown',
    );
    const customizeColumnsButton = document.getElementById(
      'customizeColumnsButton',
    );
    if (
      customizeColumnsDropdown &&
      !customizeColumnsDropdown.contains(e.target) &&
      !customizeColumnsButton.contains(e.target)
    ) {
      setSelected(false);
    }
  });

  useEffect(() => {
    setVisibleColumns(
      value === null ? GET_DEFAULT_COLUMNS(isAdmin) : value.split(','),
    );
  }, [value, isAdmin]);

  return (
    <div>
      <div
        onClick={() => setSelected(!selected)}
        className={
          styles.iconContainer + (selected ? ' ' + styles.selected : '')
        }
        id="customizeColumnsButton">
        <img className={styles.icon} src={ColumnsIcon} alt="select columns" />
      </div>
      {selected && (
        <div className={styles.popupContainer} id="customizeColumnsDropdown">
          <div className={styles.popupContainerInner}>
            <div className={sharedStyles.content}>
              <div className={sharedStyles.contentInner}>
                <div className={styles.titleContainer}>
                  <span className={styles.title}>
                    {t('filters.columns.title')}
                  </span>
                </div>
                <Form className={sharedStyles.checkboxGroup}>
                  {all_columns.map((name) => (
                    <Form.Check
                      key={'column_' + name}
                      label={t('filters.columns.column-' + name)}
                      type="checkbox"
                      className={sharedStyles.checkbox}
                      onChange={onChange}
                      checked={visibleColumns.includes(name)}
                      value={name}
                      id={'checkbox_' + name}
                    />
                  ))}
                </Form>
              </div>
            </div>

            <div className={sharedStyles.divider}>
              <hr className={sharedStyles.dividerLine} />
            </div>
            <div className={sharedStyles.footerContainer}>
              <div className={sharedStyles.footerContainerInner}>
                <Button onClick={applySelection}>{t('filters.apply')}</Button>
                <Button onClick={() => setSelected(false)}>
                  {t('filters.cancel')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomizeColumns;
