import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';

import {usePaginator} from '../../hooks/paginator';
import {useApi} from '../../contexts/ApiContext';
import Member from '../../components/Member';
import Paginator from '../../components/Paginator';
import {mediaQueries} from '../../constants';
import {MEMBER_PAGE} from '../../urls';

const PAGE_SIZE = 6;

const StyledMember = styled(Member)`
  flex-grow: 0;
  width: 329px;
  height: 260px;
  margin: 10px;

  ${mediaQueries.lg} {
    margin: 10px 0;
  }
`;

const MemberWrapper = styled(Col)`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

const StyledPaginator = styled(Paginator)`
  margin-top: 20px;
`;

const MembersList = (props) => {
  const {categories, countries, initials} = props;
  const history = useHistory();
  const [members, setMembers] = useState([]);
  const [count, setCount] = useState(null);
  const {changePage, currentPage} = usePaginator();
  const api = useApi();

  useEffect(() => {
    const params = {
      categories: categories.map((category) => category.id),
      countries: countries.map((country) => country.value),
      initials: initials,
      page: currentPage,
      page_size: PAGE_SIZE,
    };

    const fetchMembers = async () => {
      const members = await api.getOrgs(params);
      setCount(members.count);
      setMembers(members.results);
    };

    fetchMembers();
  }, [currentPage, categories, countries, initials, api]);

  const handleMemberClick = (member) =>
    history.push(MEMBER_PAGE.replace(':slug', member.slug));

  const renderMember = (member, index) => (
    <MemberWrapper key={`${index}-${member.id}`} sm={12} md={6} xl={4}>
      <StyledMember
        key={member.id}
        member={member}
        onClick={handleMemberClick}
      />
    </MemberWrapper>
  );

  return (
    <Wrapper>
      <Row>
        <Col sm={12}>
          <Row>{members.map(renderMember)}</Row>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <StyledPaginator
            total={count}
            pageSize={PAGE_SIZE}
            currentPage={currentPage}
            onPageChange={changePage}
          />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default MembersList;
