import React, {useState, useContext} from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import SearchBar from '../SearchBar/SearchBar';

import {useApi} from '../../contexts/ApiContext';
import {
  getGoogleAutocompleteResults,
  getGooglePlaceDetails,
} from '../../utils/google.api';
import {getItemsFilters} from '../../constants';
import FiltersContext from '../FiltersProvider/FiltersContext';
import {useStores} from '../../contexts/StoresContext';
import {useSearchIndexApi} from '../../hooks/search-index';
import {FALLBACK_LANGUAGE} from '../../constants';

const ItemSearchBar = (props) => {
  const {t} = useTranslation('search');
  const [filtersCategory, setFiltersCategory] = useState({
    value: 'search',
    label: t('search-bar.label'),
  });
  const api = useApi();
  const [options, setOptions] = useState([]);
  const match = useRouteMatch();
  const history = useHistory();
  const context = useContext(FiltersContext);
  const ITEM_FILTERS = getItemsFilters();
  const stores = useStores();
  const userLanguage = stores.app.language;
  const searchIndexApi = useSearchIndexApi();

  const transformEntitiesToOptions = (labelKey, valueKey, entities) => {
    return entities.map((entity) => ({
      label: entity[labelKey],
      value: entity[valueKey],
    }));
  };
  const handleChange = async (search) => {
    const currentFilterCategory = filtersCategory;
    let currentFilter = currentFilterCategory.value;

    if (search.length === 0) return;

    const newSelection = search[0];

    if (newSelection.customOption) {
      if (!newSelection.value) {
        newSelection.value = newSelection.label;
      }

      if (currentFilterCategory.partialMatchValue) {
        currentFilter = currentFilterCategory.partialMatchValue;
      }
    }

    if (
      newSelection &&
      stores.app.algolia.is_enabled &&
      !newSelection.customOption
    ) {
      searchIndexApi.registerClick(
        newSelection.objectId,
        newSelection.position,
      );
    }

    if (currentFilter === ITEM_FILTERS.location.value) {
      let placeId = newSelection.value;
      const locationComponents = {[currentFilter]: {...newSelection, placeId}};
      if (newSelection.value === 'global') {
        placeId = 0;
        locationComponents.country = {
          label: newSelection.label,
          value: 'any',
          placeId: placeId,
        };
      } else {
        const details = await getGooglePlaceDetails(placeId);
        const postalCode = details.address_components.find(
          (component) => component.types.indexOf('postal_code') !== -1,
        );
        const city = details.address_components.find(
          (component) => component.types.indexOf('locality') !== -1,
        );
        const country = details.address_components.find(
          (component) => component.types.indexOf('country') !== -1,
        );
        const state = details.address_components.find(
          (component) =>
            component.types.indexOf('administrative_area_level_1') !== -1,
        );

        if (postalCode && postalCode.long_name) {
          locationComponents.postalCode = {
            label: newSelection.label,
            value: postalCode.long_name,
            placeId,
          };
        } else {
          if (city && city.long_name) {
            locationComponents.city = {
              label: newSelection.label,
              value: city.long_name,
              placeId,
            };
          }
          if (state && state.long_name) {
            locationComponents.state = {
              label: newSelection.label,
              value: state.long_name,
              placeId,
            };
          }
          if (country && country.long_name) {
            locationComponents.country = {
              label: newSelection.label,
              value: country.short_name,
              placeId,
            };
          }
        }
      }
      context.setMultipleFilters(locationComponents);
    } else {
      context.setFilter(currentFilter, newSelection);
    }
    history.push(match.url);
  };

  const searchInAPI = async (value) => {
    const itemsResponse = await api.getItemFilters({search: value});
    const itemCategoriesResponse = await api.getItemCategoriesFilters({
      search: value,
    });
    const searchResults = itemsResponse.results.concat(
      itemCategoriesResponse.results,
    );

    return searchResults;
  };
  const searchInAlgolia = async (value) => {
    const results = await searchIndexApi.search(value, {
      restrictSearchableAttributes: [`title.${userLanguage}`],
      models: ['needslist_item.Caegory', 'needslist_item.Item'],
      exactMatch: false,
      distict: false,
    });

    const items = results.reduce((accum, hit, index) => {
      const label = hit.title[userLanguage] || hit.title[FALLBACK_LANGUAGE];

      const exists = accum.some(
        (x) => x.label.toLowerCase() === label.toLowerCase(),
      );

      if (!exists) {
        return [
          ...accum,
          {
            id: hit.id,
            label: label,
            value: label,
            kind: hit.meta_kind,
            objectId: hit.objectID,
            position: index + 1,
          },
        ];
      }

      return [...accum];
    }, []);
    return items;
  };

  const handleSearch = async (value) => {
    const currentFilterCategory = filtersCategory.value;

    if (currentFilterCategory === ITEM_FILTERS.organization.value) {
      const response = await api.getOrgs({org: value});
      setOptions(transformEntitiesToOptions('name', 'id', response.results));
    }

    if (currentFilterCategory === ITEM_FILTERS.issue.value) {
      const response = await api.getIssues({search: value});
      setOptions(transformEntitiesToOptions('name', 'id', response.results));
    }

    if (currentFilterCategory === ITEM_FILTERS.location.value) {
      const results = await getGoogleAutocompleteResults(value);
      results.unshift({description: 'Global', place_id: 'global'});
      setOptions(
        transformEntitiesToOptions('description', 'place_id', results),
      );
    }

    if (currentFilterCategory === ITEM_FILTERS.search.value) {
      const results = stores.app.algolia.is_enabled
        ? await searchInAlgolia(value)
        : await searchInAPI(value);
      setOptions(results);
    }
  };

  const shouldAllowNew = () => {
    return filtersCategory.value !== ITEM_FILTERS.location.value;
  };
  const placeholder =
    filtersCategory.value === ITEM_FILTERS.location.value
      ? t('search-bar.placeholder.global')
      : t('search-bar.placeholder.search-by');

  return (
    <SearchBar
      customMenu
      id="search-bar"
      className={props.className}
      isLoading={false}
      onChange={handleChange}
      onSearch={handleSearch}
      onSearchCategoryClick={setFiltersCategory}
      value={filtersCategory}
      options={options}
      dropDownOptions={Object.values(props.itemFilters)}
      allowNew={shouldAllowNew}
      allowClean={false}
      newSelectionPrefix={t('search-bar.prefix')}
      placeholder={placeholder}
      selectPartialMatchOnEnter
      multiple={false}
    />
  );
};

export default ItemSearchBar;
