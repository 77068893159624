import React from 'react';
import {Form} from 'react-bootstrap';
import {useTranslatedError} from '../../hooks/formik';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';

const FeedbackInvalid = (props) => {
  const {meta} = props;

  const error = useTranslatedError(meta.error);
  return (
    <React.Fragment>
      {meta.touched && meta.error && (
        <Form.Control.Feedback
          type="invalid"
          style={{display: 'block', textAlign: 'center'}}
        >
          <Icon icon="exclamation-triangle" /> {error}
        </Form.Control.Feedback>
      )}
    </React.Fragment>
  );
};

export default FeedbackInvalid;
