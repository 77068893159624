import React from 'react';
import {Route} from 'react-router-dom';

const PublicRoute = (props) => {
  const {component: Component, render, children, ...rest} = props;

  return (
    <Route {...rest} component={Component} render={render}>
      {children}
    </Route>
  );
};

export default PublicRoute;
