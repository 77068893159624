import React, {useEffect} from 'react';
import {useFormikContext, withFormik} from 'formik';
import * as yup from 'yup';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import {Col} from 'react-bootstrap';
import Input from '../Form/Input';
import Label from '../Form/Label';
import TextArea from '../Form/TextArea';
import Form from '../ItemForm/Form';
import Footer from '../ItemForm/Footer';
import Button from '../Button';
import {InputField} from '../Fields';

import {useStores} from '../../contexts/StoresContext';
import {useErrorFocus, useTranslatedErrors} from '../../hooks/formik';

const OfferServiceClaimForm = (props) => {
  const {t} = useTranslation('forms');
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    item,
    maxQuantity,
  } = props;
  const {setFieldValue} = useFormikContext();
  const focusOnErrorFields = ['quantity', 'phoneNumber'];
  const fieldRefs = {
    quantity: null,
    phoneNumber: null,
  };
  const translatedErrors = useTranslatedErrors(errors);
  useErrorFocus(isSubmitting, errors, focusOnErrorFields, fieldRefs);

  useEffect(() => {
    setFieldValue('maxQuantity', maxQuantity);
  }, [maxQuantity, setFieldValue]);

  const setRef = (fieldName) => (ref) => (fieldRefs[fieldName] = ref);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('labels.service.offer.phone-number')}</Label>
          <Input
            name="phoneNumber"
            onChange={handleChange}
            value={values.phoneNumber}
            isInvalid={!!errors.phoneNumber && touched.phoneNumber}
            error={translatedErrors.phoneNumber}
            setRef={setRef('phoneNumber')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('labels.service.offer.quantity')}</Label>
          <Input
            name="quantity"
            onChange={handleChange}
            value={values.quantity}
            placeholder={t('labels.item-quantity-placeholder')}
            inlineLabel={item.baseUom.name}
            isInvalid={!!errors.quantity && touched.quantity}
            error={translatedErrors.quantity}
            setRef={setRef('quantity')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label>{t('labels.beneficiaries-quantity')}</Label>
        </Form.Group>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="individualsQuantity"
            placeholder={t('claim:placeholders.item-quantity-placeholder')}
            inlineLabel={t('common:beneficiaries.individual_plural')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label>{t('labels.item-description')}</Label>
          <TextArea
            rows="6"
            name="additionalInformation"
            onChange={handleChange}
            value={values.additionalInformation}
            isInvalid={
              !!errors.additionalInformation && touched.additionalInformation
            }
            error={translatedErrors.additionalInformation}
          />
        </Form.Group>
      </Form.Row>
      <Footer>
        <Button type="submit" variant="primary" className="text-uppercase">
          {t('buttons.offer.claim-offer')}
        </Button>
      </Footer>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'ItemSupplyClaimForm',
  mapPropsToValues: (props) => {
    const {item} = props;
    return {
      quantity: '',
      phoneNumber: props.stores.app.user.phone_number || '',
      additionalInformation: '',
      item: item.id,
      maxQuantity: null,
      individualsQuantity: null,
    };
  },
  validationSchema: yup.object().shape({
    remainingQuantity: yup.number(),
    quantity: yup
      .number()
      .required()
      .positive()
      .label('quantity')
      .max(yup.ref('maxQuantity')),
    phoneNumber: yup.string().required(),
    additionalInformation: yup.string(),
    individualsQuantity: yup
      .number()
      .label('individuals-quantity')
      .positive()
      .nullable(),
  }),
  handleSubmit: (values, {props}) => {
    props.onSubmit(values);
  },
})(OfferServiceClaimForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  return <EnhancedForm stores={stores} {...props} />;
};

export default observer(FormWrapper);
