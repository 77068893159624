import React from 'react';
import Pill from './Pill';
import styles from './Pills.module.scss';

const CountriesPills = ({pills, selected, onClick, className}) => {
  if (pills.length === 0) return null;

  const renderPill = (pill, index) => {
    const selectedPill = selected.some((el) => el.value === pill.value);
    const onPillClick = () => onClick(pill);

    return (
      <Pill key={index} onClick={onPillClick} selected={selectedPill}>
        {pill.label}
      </Pill>
    );
  };

  return (
    <div className={`${styles.pillsContainer} ${className}`}>
      {pills.map(renderPill)}
    </div>
  );
};

export default CountriesPills;
