import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Card, Carousel} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';

import {getInitials} from '../../utils';
import {mediaQueries} from '../../constants';

import Subtitle from '../../components/Subtitle';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import Spinner from '../../components/Spinner';

const StyledCard = styled(Card)`
  margin-bottom: 15px;
  padding: 10px 15px;
  height: 100%;
  align-items: center;
  justify-content: center;

  ${mediaQueries.lg} {
    height: auto;
  }
`;

const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCarousel = styled(Carousel)`
  width: 100%;
`;

const StyledArrow = styled(({isLeftArrow, ...rest}) => (
  <FontAwesomeIcon {...rest} />
))`
  ${(props) => (props.isLeftArrow ? 'left: 1px' : 'right: 1px')}

  path {
    fill: grey;
  }
`;

const CardTitle = styled(Subtitle)`
  margin: 0 0 41px;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0 41px;
`;

const UserDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const UserName = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
`;

const UserInfo = styled.p`
  margin: 0;
  color: #93928c;
  font-size: 14px;
`;

const NonContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 150px 0;
  font-size: 14px;
  line-height: 24px;
`;

const PendingRequests = (props) => {
  const {onRequestInformation, pendingUsers, isLoading} = props;
  const {t} = useTranslation('user');

  const renderPendingRequests = (user) => {
    const userFullName = `${user.first_name || ''} ${user.last_name || ''}`;
    const userNameInitials = getInitials(userFullName);
    // TODO: update to also use user photo
    const userImage = userNameInitials;

    const handleRequestInformation = () => onRequestInformation(user);

    return (
      <Carousel.Item key={user.id}>
        <UserWrapper>
          <UserDetailsWrapper onClick={handleRequestInformation}>
            <Avatar>{userImage}</Avatar>
            <UserDetails>
              <UserName>{userFullName}</UserName>
              <UserInfo>{user.organization_role}</UserInfo>
              <UserInfo>{user.registered_organization_name}</UserInfo>
            </UserDetails>
          </UserDetailsWrapper>
          <Button onClick={handleRequestInformation}>
            {t('buttons.view-application')}
          </Button>
        </UserWrapper>
      </Carousel.Item>
    );
  };

  const Chevron = (isLeftArrow) => (
    <div>
      <StyledArrow
        icon={isLeftArrow ? faChevronLeft : faChevronRight}
        color="blue"
        size="3x"
        isLeftArrow={isLeftArrow}
      />
    </div>
  );

  return (
    <StyledCard>
      {isLoading ? (
        <NonContentContainer>
          <Spinner />
        </NonContentContainer>
      ) : pendingUsers.length !== 0 ? (
        <React.Fragment>
          <CardTitle>{t('titles.approve-request')}</CardTitle>
          <StyledCarousel
            nextIcon={Chevron()}
            prevIcon={Chevron(true)}
            indicators={false}
            controls={true}
            interval={null}
          >
            {pendingUsers.map(renderPendingRequests)}
          </StyledCarousel>
        </React.Fragment>
      ) : (
        <CardTitle>{t('titles.no-pending-request')}</CardTitle>
      )}
    </StyledCard>
  );
};

export default PendingRequests;
