import React from 'react';
import {withFormik} from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {Col} from 'react-bootstrap';
import Label from '../Form/Label';
import Form from '../ItemForm/Form';
import Footer from '../ItemForm/Footer';
import BundleConstructor from '../BundleConstructor/BundleConstructor';
import {
  CategorySelectField,
  InputField,
  RadioField,
  TimeInputField,
  AddressSelectField,
  TextAreaField,
  ImageUploadField,
} from '../Fields';
import Button from '../Button';
import {VALID_24_HOURS_FORMAT} from '../../utils/time';
import {getYesNoOptions} from '../../constants';
import {createTranslationKey} from '../../utils/i18n';
import {useErrorFocus} from '../../hooks/formik';
import {useStores} from '../../contexts/StoresContext';
import TagSelectField from '../Fields/TagSelectField';

const NeedSupplyForm = (props) => {
  const {values, errors, handleSubmit, setFieldValue, isSubmitting, item, t} =
    props;

  const focusOnErrorFields = [
    'category',
    'quantity',
    'bundle.units',
    'meetBy',
    'brand',
    'deliveryHoursFrom',
    'deliveryHoursTo',
    'contactName',
  ];
  const fieldRefs = {
    category: null,
    quantity: null,
    meetBy: null,
    'bundle.units': null,
    brand: null,
    deliveryHoursFrom: null,
    deliveryHoursTo: null,
    contactName: null,
  };
  const yesNoOptions = getYesNoOptions();

  useErrorFocus(isSubmitting, errors, focusOnErrorFields, fieldRefs);

  const handleFieldRefs = (refs) => {
    fieldRefs.quantity = refs.quantity;
    fieldRefs['bundle.units'] = refs['bundle.units'];
  };
  const setFieldRef = (field) => (ref) => {
    fieldRefs[field] = ref;
  };

  const isDone = item.status === 'done';
  const isInProgress = item.status === 'in_progress';

  const shouldShowField = (isFieldEdited) =>
    !(isDone || isInProgress) || isFieldEdited;
  const handleOnBundleChange = (bundle) => {
    setFieldValue('bundle', bundle);
  };

  return (
    <Form onSubmit={handleSubmit}>
      {!item.itemTemplate && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <CategorySelectField
              name="category"
              label={t('labels.category')}
              kind="supply"
              setRef={setFieldRef('category')}
              readOnly={isDone || isInProgress}
              isRequired
            />
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="contactName"
            label={t('labels.supply.need.item-contact-name-label')}
            placeholder={t('labels.supply.need.item-contact-name-label')}
            setRef={setFieldRef('contactName')}
            readOnly={isDone}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <BundleConstructor
        item={item}
        values={values}
        setFieldValue={setFieldValue}
        setFieldsRefs={handleFieldRefs}
        onBundleChange={handleOnBundleChange}
      />
      <Form.Row>
        <Form.Group as={Col} sm={6}>
          <TagSelectField name="age" label={t('labels.age')} />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          <TagSelectField name="sex" label={t('labels.sex')} />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        {values.category && (
          <Form.Row>
            <Form.Group as={Col} sm={12}>
              <TagSelectField
                name="size"
                label={t('labels.size')}
                category={values.category}
              />
            </Form.Group>
          </Form.Row>
        )}
        {shouldShowField(!!values.brand) && (
          <Form.Group as={Col} sm={6}>
            <InputField
              name="brand"
              label={t('labels.supply.need.item-preferred-brand')}
              setRef={setFieldRef('brand')}
              readOnly={isDone || isInProgress}
            />
          </Form.Group>
        )}
      </Form.Row>
      {shouldShowField(values.acceptPalletDelivery !== null) && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <RadioField
              name="acceptPalletDelivery"
              label={t('labels.supply.need.item-pallet')}
              options={yesNoOptions}
              readOnly={isDone || isInProgress}
              booleanOptions
            />
          </Form.Group>
        </Form.Row>
      )}
      {shouldShowField(values.deliveryToWarehouse !== null) && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <RadioField
              name="deliveryToWarehouse"
              options={yesNoOptions}
              label={t('labels.supply.need.item-warehouse')}
              readOnly={isDone || isInProgress}
              booleanOptions
            />
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label>
            {t('labels.supply.need.item-hours-accepting-deliveries')}
          </Label>
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          <TimeInputField
            name="deliveryHoursFrom"
            inlineLabel={t('labels.from')}
            inlineLabelPosition="left"
            readOnly={isDone}
            setRef={setFieldRef('deliveryHoursFrom')}
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          <TimeInputField
            name="deliveryHoursTo"
            inlineLabel={t('labels.to')}
            inlineLabelPosition="left"
            readOnly={isDone}
            setRef={setFieldRef('deliveryHoursTo')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <AddressSelectField
            name="defaultShippingAddress"
            label={t('labels.supply.need.item-default-shipping-address')}
            readOnly={isDone || isInProgress}
            isFullWidth={false}
            notEditAddress
            notDeleteAddress
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <ImageUploadField
            name="image"
            label={t('forms:labels.item-images')}
            readOnly={isDone}
            noRequirementImage
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="phoneNumber"
            label={t('labels.phone-number')}
            readOnly={isDone || isInProgress}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="contactEmail"
            label={t('labels.email')}
            readOnly={isDone || isInProgress}
          />
        </Form.Group>
      </Form.Row>
      {!isDone && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            {item.beneficiaries[0]?.uom === 18 ? (
              <InputField
                label={t('labels.beneficiaries-quantity')}
                name="individualsQuantity"
                placeholder={t('labels.item-quantity-placeholder')}
                inlineLabel={t('common:beneficiaries.family_plural')}
                readOnly
              />
            ) : (
              <InputField
                label={t('labels.beneficiaries-quantity')}
                name="individualsQuantity"
                placeholder={t('labels.item-quantity-placeholder')}
                inlineLabel={t('common:beneficiaries.individual_plural')}
              />
            )}
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <TextAreaField
            rows="6"
            name="description"
            label={t('labels.item-description')}
            placeholder={t('labels.supply.need.item-description-placeholder')}
            readOnly={isDone}
          />
        </Form.Group>
      </Form.Row>
      <Footer>
        {!isDone && (
          <Button type="submit" variant="primary" className="text-uppercase">
            {isInProgress ? t('buttons.need.edit-need') : t('buttons.save')}
          </Button>
        )}
      </Footer>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'NeedSupplyForm',
  mapPropsToValues: (props) => {
    const {item, defaultShippingAddress} = props;
    const bundles = item.itemTemplate ? item.itemTemplate.bundles : [];
    const userFullName = `${props.stores.app.user.first_name || ''} ${
      props.stores.app.user.last_name || ''
    }`;
    const emptyBundle = {
      id: null,
      units: 1,
      uom: {
        id: '',
      },
    };
    const ageTag = item.tag.find((tag) => tag.tag_type === 'age');
    const sexTag = item.tag.find((tag) => tag.tag_type === 'sex');
    const sizeTag = item.tag.find((tag) => tag.tag_type === 'size');

    return {
      baseUom: item.baseUom ? item.baseUom.id.toString() : '1',
      deliveryInBundles: item.deliveryInBundles ? 'preferred' : 'not_required',
      quantity: item.quantity || '',
      bundle: item.bundle.uom_id ? item.bundle : bundles[0] || emptyBundle,
      brand: item.brand || '',
      contactName: item.contactName || userFullName,
      image: item.image ? item.image : null,
      contactEmail: item.contactEmail || '',
      beneficiariesId: item.beneficiaries[0]?.id || null,
      beneficiaries: item.beneficiaries[0]?.uom || 17,
      individualsQuantity: item.beneficiaries[0]?.quantity || null,
      acceptPalletDelivery:
        typeof item.isPallet === 'boolean' ? item.isPallet : null,
      deliveryToWarehouse:
        typeof item.wareHouseDelivery === 'boolean'
          ? item.wareHouseDelivery
          : null,
      deliveryHoursFrom: item.acceptingDeliveriesHoursFrom || '09:00',
      deliveryHoursTo: item.acceptingDeliveriesHoursTo || '17:00',
      defaultShippingAddress: item.location
        ? item.location.id
        : defaultShippingAddress,
      description: item.description || '',
      category: item.category ? item.category.id : '',
      phoneNumber: item.phoneNumber
        ? item.phoneNumber
        : props.stores.app.user.phone_number || '',
      status: item.status,
      claimedQuantity: item.claimedQuantity || 0,
      age: ageTag ? ageTag.id : null,
      sex: sexTag ? sexTag.id : null,
      size: sizeTag ? sizeTag.id : null,
    };
  },
  validationSchema: yup.object().shape({
    baseUom: yup.string().required(),
    quantity: yup
      .number()
      .label('quantity')
      .required()
      .positive()
      .nullable()
      .when('status', {
        is: 'in_progress',
        then: yup
          .number()
          .required()
          .label('quantity')
          .min(yup.ref('claimedQuantity')),
      }),
    bundle: yup.object().shape({
      uom: yup.object().shape({
        id: yup.number(),
      }),
      units: yup.number().label('unit').required().positive(),
    }),
    contactName: yup.string().max(255).required(),
    brand: yup.string().max(50).nullable(),
    meetBy: yup.date().min(new Date()).nullable(),
    acceptPalletDelivery: yup.bool().nullable(),
    deliveryToWarehouse: yup.bool().nullable(),
    category: yup.string().required().nullable(),
    image: yup.object().nullable().nullable(),
    phoneNumber: yup.string().max(30).nullable(),
    contactEmail: yup.string().email(),
    individualsQuantity: yup
      .number()
      .label('individuals-quantity')
      .positive()
      .nullable(),
    // TODO: move this to a custom validation using addMethod
    deliveryHoursFrom: yup
      .string()
      .matches(
        VALID_24_HOURS_FORMAT,
        createTranslationKey('errors.date.invalid-time'),
      ),
    deliveryHoursTo: yup
      .string()
      .matches(
        VALID_24_HOURS_FORMAT,
        createTranslationKey('errors.date.invalid-time'),
      ),
    defaultShippingAddress: yup.number().required().integer(),
    description: yup.string().nullable(),
    age: yup.number().nullable(),
    sex: yup.number().nullable(),
    size: yup.number().nullable(),
  }),
  handleSubmit: async (
    values,
    {props, setSubmitting, setFieldError, setErrors},
  ) => {
    // @FIXME [?] Validate time only on submit since it's a heavy operation
    if (
      moment(values.deliveryHoursTo, 'HH:mm').isSameOrBefore(
        moment(values.deliveryHoursFrom, 'HH:mm'),
      )
    ) {
      setFieldError(
        'deliveryHoursTo',
        createTranslationKey('errors.date.after-field', {
          fieldName: props.t('forms:fields.hours-from'),
        }),
      );
      setSubmitting(false);
      return;
    }

    const uomId = values.bundle.uom.id ? values.bundle.uom.id : values.baseUom;
    const data = {
      ...values,
      bundle: {
        ...values.bundle,
        uom_id: parseInt(uomId),
        uom: {
          ...values.bundle.uom,
        },
      },
    };

    try {
      await props.onSubmit(data);
      setSubmitting(false);
    } catch (error) {
      if (error.quantity) {
        const {toast} = props.stores.app;
        toast.toasts.forEach(
          ({id, type}) =>
            type !== 'testing' && props.stores.app.removeToast(id),
        );
        setErrors({
          quantity: error.quantity ? 'Required' : undefined,
        });
      }
      setSubmitting(false);
    }
  },
})(NeedSupplyForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  const {t} = useTranslation('forms');
  return <EnhancedForm t={t} stores={stores} {...props} />;
};

export default observer(FormWrapper);
