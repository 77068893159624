import React from 'react';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import Form from '../ItemForm/Form';
import Footer from '../ItemForm/Footer';
import Button from '../Button';
import BundleConstructor from '../BundleConstructor/BundleConstructor';
import {useErrorFocus} from '../../hooks/formik';

const EditQuantityForm = ({
  values,
  errors,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  item,
  editQuantity,
  t,
}) => {
  const focusOnErrorFields = ['quantity', 'bundle.units'];
  const fieldRefs = {
    quantity: null,
    'bundle.units': null,
  };

  useErrorFocus(isSubmitting, errors, focusOnErrorFields, fieldRefs);

  const handleFieldRefs = (refs) => {
    fieldRefs.quantity = refs.quantity;
    fieldRefs['bundle.units'] = refs['bundle.units'];
  };

  const handleOnBundleChange = (bundle) => {
    setFieldValue('bundle', bundle);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <BundleConstructor
        editQuantity={editQuantity}
        item={item}
        values={values}
        setFieldValue={setFieldValue}
        setFieldsRefs={handleFieldRefs}
        onBundleChange={handleOnBundleChange}
      />
      <Footer>
        <Button type="submit" variant="primary" className="text-uppercase">
          {t('buttons.save')}
        </Button>
      </Footer>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'EditQuantityForm',
  mapPropsToValues: (props) => {
    const {item} = props;
    const bundles = item.itemTemplate ? item.itemTemplate.bundles : [];
    const emptyBundle = {
      id: null,
      units: 1,
      uom: {
        id: '1',
      },
    };

    return {
      baseUom: item.baseUom ? item.baseUom.id.toString() : '1',
      deliveryInBundles: item.deliveryInBundles ? 'preferred' : 'not_required',
      quantity: item.quantity || '',
      bundle: item.bundle?.uom_id ? item.bundle : bundles[0] || emptyBundle,
      status: item.status,
      claimedQuantity: item.claimedQuantity || 0,
    };
  },
  validationSchema: yup.object().shape({
    baseUom: yup.string().required(),
    quantity: yup
      .number()
      .label('quantity')
      .required()
      .positive()
      .min(yup.ref('claimedQuantity')),
    bundle: yup.object().shape({
      uom: yup.object().shape({
        id: yup.number().required(),
      }),
      units: yup.number().label('unit').required().positive(),
    }),
  }),
  handleSubmit: async (values, {props, setSubmitting, setErrors}) => {
    values.bundle.uom_id = parseInt(values.bundle.uom.id);

    try {
      await props.onSubmit(values);
      setSubmitting(false);
    } catch (error) {
      if (error.quantity) {
        const {toast} = props.stores.app;
        toast.toasts.forEach(
          ({id, type}) =>
            type !== 'testing' && props.stores.app.removeToast(id),
        );
        setErrors({
          quantity: error.quantity ? 'Required' : undefined,
        });
      }
      setSubmitting(false);
    }
  },
})(EditQuantityForm);

const FormWrapper = (props) => {
  const stores = useStores();
  const {t} = useTranslation('forms');
  return <EnhancedForm t={t} stores={stores} {...props} />;
};

export default observer(FormWrapper);
