import React from 'react';
import {Form, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {
  CurrencyField,
  InputField,
  LanguageField,
  TimezoneField,
} from '../../components/Fields';

const UserDetailsFields = (props) => {
  const {t} = useTranslation('create-account');

  return (
    <Row>
      <Form.Group as={Col} lg={6}>
        <InputField
          type="text"
          placeholder={t('placeholders.first-name')}
          label={t('labels.first-name')}
          name="firstName"
          isRequired
        />
      </Form.Group>
      <Form.Group as={Col} lg={6}>
        <InputField
          type="text"
          placeholder={t('placeholders.last-name')}
          label={t('labels.last-name')}
          name="lastName"
          isRequired
        />
      </Form.Group>
      <Form.Group as={Col} lg={12}>
        <InputField
          type="text"
          placeholder={t('placeholders.role')}
          label={t('labels.role')}
          name="role"
          isRequired
        />
      </Form.Group>
      <Form.Group as={Col} lg={6}>
        <InputField
          type="text"
          placeholder={t('placeholders.phone')}
          label={t('labels.phone')}
          name="phone"
          isRequired
        />
      </Form.Group>
      <Form.Group as={Col} lg={6}>
        <TimezoneField
          label={t('labels.timezone')}
          name="timezone"
          isRequired
        />
      </Form.Group>
      <Form.Group as={Col} lg={6}>
        <CurrencyField
          label={t('labels.currency')}
          name="currency"
          isRequired
        />
      </Form.Group>
      <Form.Group as={Col} lg={6}>
        <LanguageField label={t('labels.language')} name="language" />
      </Form.Group>
    </Row>
  );
};

export default observer(UserDetailsFields);
