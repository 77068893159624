import React, {useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Modal as BootstrapModal} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './Modal.module.scss';

const modalRoot = document.getElementById('modal-root');

const Modal = (props) => {
  const {onClose, headerTitle} = props;
  const element = useRef(document.createElement('div'));

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };
    const panelNode = element.current;

    modalRoot.appendChild(panelNode);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      modalRoot.removeChild(panelNode);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  const children = (
    <BootstrapModal
      show
      dialogAs={BootstrapModal.Dialog}
      backdrop="static"
      className={`${styles.customDialog} ${props.className}`}>
      <BootstrapModal.Header className={styles.header}>
        <p className={styles.headerTitle}>{headerTitle || ''}</p>
        <FontAwesomeIcon
          icon="times"
          size="lg"
          onClick={props.onClose}
          className={styles.closeIcon}
        />
      </BootstrapModal.Header>
      <BootstrapModal.Body>{props.children}</BootstrapModal.Body>
    </BootstrapModal>
  );

  return ReactDOM.createPortal(children, element.current);
};

export default Modal;
