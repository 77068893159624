import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {ItemPanel} from '../ItemPanel';
import NeedSupplyForm from './NeedSupplyForm';
import NeedServiceForm from './NeedServiceForm';
import OfferDiscountForm from './OfferDiscountForm';
import OfferSupplyForm from './OfferSupplyForm';
import OfferServiceForm from './OfferServiceForm';
import OfferInformationForm from './OfferInformationForm';

import {useApi} from '../../contexts/ApiContext';
import {useItem} from '../../hooks/items';
import {useParams} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import {usePageStore} from '../../contexts/PageContext';
import Dialog from '../Dialog';
import {useLocaleNumber} from '../../hooks/number';
import Spinner from '../Spinner';
import styles from './ItemEditForm.module.scss';

const MODAL_REQUIRED_SIDE = 'offer';
const MODAL_REQUIRED_KINDS = ['supply', 'service', 'information'];

const itemForms = {
  need: {
    supply: NeedSupplyForm,
    service: NeedServiceForm,
  },
  offer: {
    discount: OfferDiscountForm,
    supply: OfferSupplyForm,
    service: OfferServiceForm,
    information: OfferInformationForm,
  },
};

const ItemEditForm = ({onSave, defaultDeliveryAddress}) => {
  const {itemId} = useParams();
  const api = useApi();
  const localeNumber = useLocaleNumber();
  const stores = useStores();
  const [, pageActions] = usePageStore();
  const [item, isLoading] = useItem(itemId);
  const {t} = useTranslation(['forms', 'add-items']);
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState({});

  const editQuantity = useMemo(
    () => item?.status === 'done' && stores.app.user.isAdmin,
    [item?.status, stores.app.user.isAdmin],
  );

  const fetchUoms = useCallback(async () => {
    const response = await api.getUoms();
    stores.app.setUoms(response.results);
  }, [stores.app, api]);

  const fetchTags = useCallback(async () => {
    const response = await api.getTags();
    stores.app.setTags(response);
  }, [api, stores.app]);

  useEffect(() => {
    fetchUoms();
    fetchTags();
    item && pageActions.setBreadcrumbs({key: `edit-${item.side}`});
  }, [fetchUoms, fetchTags, item, pageActions]);

  const handleSubmit = async (values) => {
    const {id, side, kind, name} = item;
    values.name = name;
    values.id = id;
    values.side = side;
    values.kind = kind;
    values.currency = stores.app.currency.id;

    if (side === 'offer' && kind === 'supply' && !values.pickupLocation) {
      values.pickupLocation = stores.app.organization.location.id;
    }

    if (
      item.side === MODAL_REQUIRED_SIDE &&
      MODAL_REQUIRED_KINDS.includes(item.kind)
    ) {
      setFormValues(values);
      setShowModal(true);
    } else {
      const response = editQuantity
        ? await api.updateItemQuantity(values)
        : await api.updateItem(values);
      if (response.name) values.name = response.name;
      onSave(values);
    }
  };

  const renderMarketValueDialog = () => {
    const handleOnConfirm = async () => {
      setShowModal(false);
      const response = await api.updateItem(formValues);
      if (response.name) formValues.name = response.name;
      onSave(formValues);
    };
    const handleOnCancel = async () => {
      setShowModal(false);
    };

    return (
      <Dialog
        header={t('add-items:add-offers.warning-dialog.header')}
        message={t('add-items:add-offers.warning-dialog.message', {
          offerTitle: item.name,
          currency: stores.app.currency.code,
          marketValue: localeNumber(
            formValues.marketValue ? formValues.marketValue : 0,
          ),
        })}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        confirmText={t('add-items:add-offers.warning-dialog.confirm')}
        cancelText={t('add-items:add-offers.warning-dialog.cancel')}
        noIcon="true"
      />
    );
  };

  const breadcrumbs = {
    need: t('forms:breadcrumbs.need'),
    offer: t('forms:breadcrumbs.offer'),
  };

  if (!item || isLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );
  }

  const FormComponent = itemForms[item.side][item.kind];

  return (
    <ItemPanel
      item={item}
      breadcrumb={breadcrumbs[item.side]}
      title={t('forms:titles.complete-details')}
      isEditableNameItem>
      <FormComponent
        item={item}
        onSubmit={handleSubmit}
        defaultShippingAddress={defaultDeliveryAddress}
      />
      {showModal && renderMarketValueDialog()}
    </ItemPanel>
  );
};

export default observer(ItemEditForm);
