import React from 'react';
import Select from './Select';

const LanguageSelect = (props) => {
  const {value, onChange, languages, ...rest} = props;

  const handleOnChange = (e) => {
    const selectedLanguage = languages.find(
      (language) => language.id === Number(e.target.value),
    );
    onChange(selectedLanguage);
  };

  const mapOptions = (opt) => (
    <option key={opt.id} value={opt.id}>
      {opt.name}
    </option>
  );

  if (!languages.length) return null;

  return (
    <Select {...rest} onChange={handleOnChange} value={value}>
      {languages.map(mapOptions)}
    </Select>
  );
};

export default LanguageSelect;
