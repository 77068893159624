import React from 'react';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {Col} from 'react-bootstrap';
import Form from '../ItemForm/Form';
import Footer from '../ItemForm/Footer';
import {
  InputField,
  RadioField,
  TextAreaField,
  UnitSelectField,
  CategorySelectField,
  ImageUploadField,
  AddressSelectField,
} from '../Fields';
import Button from '../Button';
import {useErrorFocus} from '../../hooks/formik';
import {useStores} from '../../contexts/StoresContext';
import {useAvailableForOptions} from '../../hooks/utils';
import DatePickerField from '../Fields/DatePickerField';
import {normalizeFloatNumber} from '../../utils';
import {useLocaleNumber} from '../../hooks/number';
import {marketValueValidation} from '../../utils/yupCustomFunctions';
import TagSelectField from '../Fields/TagSelectField';

const OfferServiceForm = (props) => {
  const {values, errors, handleSubmit, isSubmitting, item} = props;
  const localeNumber = useLocaleNumber();
  const {t} = useTranslation(['forms', 'add-items']);
  const focusOnErrorFields = [
    'jobType',
    'category',
    'quantity',
    'meetBy',
    'deliverable',
    'contactName',
    'marketValue',
  ];
  const fieldRefs = {
    jobType: null,
    category: null,
    quantity: null,
    meetBy: null,
    contactName: null,
    deliverable: null,
    marketValue: null,
  };

  useErrorFocus(isSubmitting, errors, focusOnErrorFields, fieldRefs);

  const setFieldRef = (field) => (ref) => {
    fieldRefs[field] = ref;
  };

  const isDone = item.status === 'done';
  const isInProgress = item.status === 'in_progress';
  const isEditable =
    item.status === 'open' || item.status === 'on_hold' || isInProgress;
  const isMarketValueEditable =
    item.status === 'complete' ||
    item.status === 'incomplete' ||
    item.status === 'open';
  const baseUomLabel = props.stores.app.choices.uom.find(
    (uom) => uom.id === parseInt(values.baseUom),
  ).name;
  const availableForOptions = useAvailableForOptions();

  const shouldShowField = (isFieldEdited) => {
    return (!isDone && !isInProgress) || isFieldEdited;
  };

  const setJobTypeOptions = [
    {
      label: t('forms:fields.job-type-on-site'),
      value: 'on_site',
    },
    {
      label: t('forms:fields.job-type-remote'),
      value: 'remote',
    },
    {
      label: t('forms:fields.job-type-either'),
      value: 'either',
    },
  ];

  return (
    <Form onSubmit={handleSubmit}>
      {!item.category && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <CategorySelectField
              name="category"
              label={t('forms:labels.category')}
              kind="service"
              setRef={setFieldRef('category')}
              readOnly={isDone || isInProgress}
              isRequired
            />
          </Form.Group>
        </Form.Row>
      )}
      {shouldShowField(values.jobType !== null) && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <RadioField
              name="jobType"
              label={t('forms:labels.service.offer.job-type')}
              options={setJobTypeOptions}
              readOnly={isDone || isInProgress}
              setRef={setFieldRef('jobType')}
              isRequired
            />
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Group as={Col} sm={6}>
          <UnitSelectField
            label={t('forms:labels.service.offer.units')}
            name="baseUom"
            kind="service"
            readOnly={isDone || isInProgress}
            isRequired
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          <InputField
            name="quantity"
            placeholder={t('forms:labels.service.offer.quantity-placeholder')}
            label={t('forms:labels.service.offer.quantity')}
            readOnly={isDone}
            setRef={setFieldRef('quantity')}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="marketValue"
            label={
              item.currency === props.stores.app.currency.id
                ? t('forms:labels.service.offer.item-market-value', {
                    uom:
                      baseUomLabel.charAt(0).toUpperCase() +
                      baseUomLabel.slice(1),
                  })
                : t(
                    'forms:labels.service.offer.item-market-value-different-currency',
                    {
                      uom:
                        baseUomLabel.charAt(0).toUpperCase() +
                        baseUomLabel.slice(1),
                      currency: props.stores.app.currency.code,
                    },
                  )
            }
            placeholder={t(
              'forms:labels.service.offer.item-market-value-placeholder',
            )}
            inlineLabel={`${
              props.stores.app.currency.code
            } / ${baseUomLabel.toUpperCase()}`}
            setRef={setFieldRef('marketValue')}
            readOnly={!isMarketValueEditable}
            format={localeNumber}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="contactName"
            label={t('forms:labels.service.offer.item-contact-name-label')}
            placeholder={t(
              'forms:labels.service.offer.item-contact-name-label',
            )}
            setRef={setFieldRef('contactName')}
            readOnly={isDone}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={6}>
          <TagSelectField name="age" label={t('labels.age')} />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          <TagSelectField name="sex" label={t('labels.sex')} />
        </Form.Group>
      </Form.Row>
      {values.category && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <TagSelectField
              name="size"
              label={t('labels.size')}
              category={values.category}
            />
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        {shouldShowField(!!values.availableUntil) && (
          <Form.Group as={Col} sm={12}>
            <DatePickerField
              name="availableUntil"
              label={t('forms:labels.service.offer.item-available-until')}
              minDate={new Date()}
              showDisabledMonthNavigation
              placeholder={t(
                'forms:labels.service.offer.item-available-until-placeholder',
              )}
              readOnly={isDone}
              setRef={setFieldRef('availableUntil')}
            />
          </Form.Group>
        )}
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <RadioField
            name="availableFor"
            options={availableForOptions}
            label={t('forms:labels.item-available-for')}
            setRef={setFieldRef('availableFor')}
            readOnly={isDone}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <AddressSelectField
            name="defaultShippingAddress"
            label={t('labels.service.offer.item-default-shipping-address')}
            readOnly={isDone || isInProgress}
            isFullWidth={false}
            notEditAddress
            notDeleteAddress
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="phoneNumber"
            label={t('forms:labels.phone-number')}
            readOnly={isDone || isInProgress}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="contactEmail"
            label={t('labels.email')}
            readOnly={isDone || isInProgress}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <ImageUploadField
            name="image"
            label={t('forms:labels.item-images')}
            readOnly={isDone}
            noRequirementImage
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <TextAreaField
            rows="6"
            name="description"
            label={t('forms:labels.item-description')}
            placeholder={t(
              'forms:labels.service.offer.item-description-placeholder',
            )}
            readOnly={isDone}
          />
        </Form.Group>
      </Form.Row>
      <Footer>
        {!isDone && (
          <Button type="submit" variant="primary" className="text-uppercase">
            {isEditable
              ? t('forms:buttons.offer.edit-offer')
              : t('forms:buttons.offer.post-offer')}
          </Button>
        )}
      </Footer>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'OfferServiceForm',
  mapPropsToValues: (props) => {
    const {item, defaultShippingAddress} = props;
    const userFullName = `${props.stores.app.user.first_name || ''} ${
      props.stores.app.user.last_name || ''
    }`;
    const ageTag = item.tag.find((tag) => tag.tag_type === 'age');
    const sexTag = item.tag.find((tag) => tag.tag_type === 'sex');
    const sizeTag = item.tag.find((tag) => tag.tag_type === 'size');

    return {
      baseUom: item.baseUom ? item.baseUom.id.toString() : '11',
      quantity: item.quantity || '',
      availableUntil: item.dueDate || '',
      jobType: item.jobType || '',
      description: item.description || '',
      status: item.status,
      claimedQuantity: item.claimedQuantity || 0,
      category: item.category ? item.category.id : '',
      tags: item.tags || [],
      phoneNumber: item.phoneNumber
        ? item.phoneNumber
        : props.stores.app.user.phone_number || '',
      marketValue: item.bundle ? item.bundle.price : item.marketValue || '',
      contactName: item.contactName || userFullName,
      contactEmail: item.contactEmail || '',
      image: item.image ? item.image : null,
      availableFor: item.availableFor || 'any',
      defaultShippingAddress: item.location
        ? item.location.id
        : defaultShippingAddress,
      age: ageTag ? ageTag.id : null,
      sex: sexTag ? sexTag.id : null,
      size: sizeTag ? sizeTag.id : null,
    };
  },
  validationSchema: yup.object().shape({
    baseUom: yup.string().required(),
    quantity: yup
      .number()
      .label('quantity')
      .required()
      .positive()
      .nullable()
      .when('status', {
        is: 'in_progress',
        then: yup
          .number()
          .required()
          .label('quantity')
          .min(yup.ref('claimedQuantity')),
      }),
    availableUntil: yup.date().min(new Date()).nullable(),
    jobType: yup.string().required(),
    description: yup.string().nullable(),
    category: yup.string().required().nullable(),
    marketValue: marketValueValidation().required().label('market-value'),
    contactName: yup.string().max(255).required(),
    contactEmail: yup.string().email(),
    phoneNumber: yup.string().max(30).nullable(),
    image: yup.object().nullable(),
    availableFor: yup.string().required(),
    defaultShippingAddress: yup.mixed().required(),
    age: yup.number().nullable(),
    sex: yup.number().nullable(),
    size: yup.number().nullable(),
  }),
  handleSubmit: async (values, {props, setSubmitting, setErrors}) => {
    const data = {
      ...values,
      marketValue: normalizeFloatNumber(values.marketValue),
    };

    try {
      await props.onSubmit(data);
      setSubmitting(false);
      props.onSave();
    } catch (error) {
      if (error.quantity || error.market_value) {
        const {toast} = props.stores.app;
        toast.toasts.forEach(
          ({id, type}) =>
            type !== 'testing' && props.stores.app.removeToast(id),
        );
        setErrors({
          quantity: error.quantity ? 'Required' : undefined,
          marketValue: error.market_value ? 'Required' : undefined,
        });
      }
      setSubmitting(false);
    }
  },
})(OfferServiceForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  return <EnhancedForm stores={stores} {...props} />;
};

export default observer(FormWrapper);
