import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {useStores} from '../../../contexts/StoresContext';
import {useApi} from '../../../contexts/ApiContext';
import {DATE_FORMAT} from '../../../constants';
import {REPORTS_ACTIVITY_DETAIL} from '../../../urls';

import Table from '../../../components/Table';
import {usePaginator} from '../../../hooks/paginator';
import Spinner from '../../../components/Spinner';
import {ReactComponent as EmptyActivity} from '../../../assets/icons/reports-activity-empty.svg';
import EmptyReport from '../EmptyReport';
import {useLocaleNumber} from '../../../hooks/number';

const PAGE_SIZE = 6;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 241px 0;
`;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 170px 0;
`;

const ItemsTable = (props) => {
  const api = useApi();
  const localeNumber = useLocaleNumber();
  const stores = useStores();
  const {type, side, paginatorParam, filterByMember, from, to} = props;
  const {t} = useTranslation('reports');
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(null);
  const {currentPage} = usePaginator(paginatorParam);
  const [isLoading, setIsLoading] = useState(true);
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const datetimeFormat = stores.app.datetimeFormat.moment;

  const fetchItem = useCallback(
    async (filter) => {
      const fetchApi = (qs) =>
        type === 'posted'
          ? api.getOrgItemsReport(qs)
          : api.getOrgClaimedItemsReport(qs);
      setIsLoading(true);

      const response = await fetchApi({
        side: side,
        page_size: PAGE_SIZE,
        kind: ['supply', 'service', 'information'],
        org_id: filterByMember,
        page: currentPage,
        from: moment(from).format(DATE_FORMAT),
        to: moment(to).format(DATE_FORMAT),
        sort_by: sortBy,
        sort_order: sortOrder,
      });

      setItems(response.results);
      setCount(response.count);
      setIsLoading(false);
    },
    [currentPage, side, type, to, from, filterByMember, sortBy, sortOrder, api],
  );

  useEffect(() => {
    fetchItem();
  }, [fetchItem]);

  const handleViewDetails = (item) => {
    history.push({
      ...history.location,
      pathname: REPORTS_ACTIVITY_DETAIL.replace(':itemId', item.id).replace(
        ':side',
        item.side,
      ),
    });
  };

  const handleHeaderClick = (header) => {
    if (header.sortBy) {
      if (header.sortBy === 'created_at') {
        if (type === 'posted') {
          setSortBy('created_at');
          sortOrder === 'desc' ? setSortOrder('asc') : setSortOrder('desc');
        } else {
          setSortBy('updated_at');
          sortOrder === 'desc' ? setSortOrder('asc') : setSortOrder('desc');
        }
      } else if (header.sortBy === 'claimed_quantity') {
        setSortBy('claimed_quantity');
        sortOrder === 'desc' ? setSortOrder('asc') : setSortOrder('desc');
      }
      history.push({...history.location});
    }
  };

  const headers = useMemo(() => {
    const headers = [
      {
        title: t('tabs.headers.item'),
        size: 2,
      },
      {
        title: t('tabs.headers.quantity'),
        size: 1,
      },
      {
        title: t('tabs.headers.type'),
        size: 1,
      },
      {
        title: t('tabs.headers.status'),
        size: 1,
      },
      {
        title: t('tabs.headers.date'),
        size: 2,
        sortBy: 'created_at',
      },
      {
        title: t('tabs.headers.issue'),
        size: 2,
      },
      {
        title: t('tabs.headers.posted-by'),
        size: 1,
      },
    ];
    if (type === 'met') {
      headers.push({
        title: t('tabs.headers.met-by'),
        size: 2,
      });
    } else {
      headers.push({
        title: t(`tabs.headers.transactions-${side}`),
        size: 2,
        sortBy: 'claimed_quantity',
      });
    }

    return headers;
  }, [t, type, side]);

  const columns = useMemo(() => {
    const columns = [
      {
        renderCell: (item) => item.name || item.item.name,
        size: 2,
      },
      {
        renderCell: (item) =>
          item.kind === 'information'
            ? t('labels.unlimited')
            : `${localeNumber(item.quantity)} ${
                item.bundle
                  ? item.bundle.uom
                    ? item.bundle.uom.name
                    : item.baseUom.name
                  : item.baseUom.name
              }`,
        size: 1,
      },
      {
        renderCell: (item) => item.kind || item.item.kind,
        size: 1,
      },
      {
        renderCell: (item) =>
          t(`tabs.status.${item.status || item.item.status}`),
        size: 1,
      },
      {
        renderCell: (item) => moment(item.createdAt).format(datetimeFormat),
        size: 2,
      },
      {
        renderCell: (item) => item.issueName || item.item.issue_name,
        size: 2,
      },
      {
        renderCell: (item) =>
          type === 'met' ? item.item.organization_name : item.organizationName,
        size: 1,
      },
    ];
    if (type === 'met') {
      columns.push({
        renderCell: (item) => item.organizationName,
        size: 2,
      });
    } else {
      columns.push({
        renderCell: (item) => localeNumber(item.claimedQuantity),
        size: 2,
      });
    }

    return columns;
  }, [datetimeFormat, t, type, localeNumber]);

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }
  if (!items.length) {
    return (
      <EmptyContainer>
        <EmptyReport
          icon={EmptyActivity}
          message={t('labels.no-transaction')}
        />
      </EmptyContainer>
    );
  }

  return (
    <Table
      headers={headers}
      columns={columns}
      data={items}
      count={count}
      paginatorParameterName={paginatorParam}
      pageSize={PAGE_SIZE}
      onHeaderClick={handleHeaderClick}
      onViewDetails={handleViewDetails}
      type={type}
    />
  );
};

export default observer(ItemsTable);
