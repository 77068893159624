import React from 'react';
import {useTranslation} from 'react-i18next';

import {Form} from 'react-bootstrap';
import {InputField} from '../../../components/Fields';

const EditMemberContactFields = () => {
  const {t} = useTranslation('member-page');

  return (
    <React.Fragment>
      <Form.Group>
        <InputField
          type="text"
          name="phone"
          label={t('form.label.phone')}
          placeholder={t('form.place-holder.phone')}
        />
      </Form.Group>
      <Form.Group>
        <InputField
          type="text"
          name="email"
          label={t('form.label.email')}
          placeholder={t('form.place-holder.email')}
        />
      </Form.Group>
      <Form.Group>
        <InputField
          type="text"
          name="website"
          label={t('form.label.website')}
          placeholder={t('form.place-holder.website')}
        />
      </Form.Group>
    </React.Fragment>
  );
};

export default EditMemberContactFields;
