import React, {useState, useEffect} from 'react';
import {getHeaders} from './utils';
import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const MediaImageWidget = (props) => {
  const parsed = JSON.parse(props.value);

  const [value, setValue] = useState(props.value);
  const [images, setImages] = useState(
    props.multi ? parsed : parsed ? [parsed] : [],
  );
  const [loaded, setLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [file, setFile] = useState('');

  useEffect(() => {
    setValue(JSON.stringify(props.multi ? images : images[0] || null));
  }, [images, props.multi]);

  useEffect(() => {
    setLoaded(true);
  }, [setLoaded]);

  const inputRef = React.createRef();

  const handleChange = (event) => {
    if (!event.target.files.length) {
      return;
    }

    const file = event.target.files[0];
    const reader = new FileReader();
    const img = new Image();

    reader.onload = () => {
      img.src = reader.result;
    };

    img.onload = async () => {
      const result = await upload(file, {
        width: img.naturalWidth,
        height: img.naturalHeight,
      });

      setImages((prevState) => {
        return props.multi ? [...prevState, result] : [result];
      });
      setProcessing((prevState) => false);
      setFile((prevState) => '');
    };

    setProcessing((prevState) => false);
    reader.readAsDataURL(file);
  };

  const upload = async (file, size) => {
    const fileData = {
      name: file.name,
      content_type: file.type,
      width: size.width,
      height: size.height,
      alt: file.name,
    };

    const response = await fetch(props.api, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(fileData),
    });
    const data = await response.json();
    const image = {
      id: data.id,
      name: data.name,
      url: data.url,
    };

    await fetch(data.signed_url, {
      method: 'PUT',
      headers: {
        'x-goog-acl': data.acl,
        'Content-type': file.type,
      },
      body: file,
    });

    return image;
  };

  const icon = processing ? (
    <React.Fragment>
      <FontAwesomeIcon icon="circle-notch" spin />{' '}
    </React.Fragment>
  ) : null;

  const imagesFragment = images.map((image, index) => {
    const changeButton =
      !props.readonly && !props.multi ? (
        <button
          disabled={processing || props.disabled}
          onClick={(event) => {
            event.preventDefault();
            inputRef.current.click();
          }}
          className="btn btn-primary btn-sm"
        >
          {icon}Change
        </button>
      ) : null;

    const removeButton = !props.readonly ? (
      <button
        disabled={processing || props.disabled}
        onClick={(event) => {
          event.preventDefault();

          setImages((prevState) => {
            const images = [...prevState];
            images.splice(index, 1);
            return images;
          });
        }}
        className="btn btn-danger btn-sm"
      >
        Remove
      </button>
    ) : null;

    const footer =
      changeButton || removeButton ? (
        <div className="card-footer">
          {changeButton} {removeButton}
        </div>
      ) : null;

    return (
      <div key={index} className="card">
        <img alt={image.name} src={image.url} className="card-img-top" />
        <div className="card-body">
          <h5 className="card-title nl-truncate">{image.name}</h5>
        </div>
        {footer}
      </div>
    );
  });

  const inputFileFragment = !props.readonly ? (
    <div
      style={{display: images.length && !props.multi ? 'none' : 'inline-block'}}
      className="custom-file"
    >
      <input
        required={props.required && !images.length}
        disabled={processing || props.disabled}
        ref={inputRef}
        onChange={handleChange}
        className="custom-file-input"
        type="file"
        value={file}
      />
      <label className="custom-file-label">{icon}Choose file</label>
    </div>
  ) : null;

  return (
    <React.Fragment>
      <label>{props.label}</label>
      {inputFileFragment}
      <div className="card-columns">{imagesFragment}</div>
      {!props.readonly && loaded && !images.length ? (
        <div className="alert alert-info">No images found</div>
      ) : null}
      {props.readonly && loaded && !images.length ? (
        <div className="alert alert-warning">
          Cannot add images because this field is <strong>read only</strong>
        </div>
      ) : null}
      <textarea
        style={{display: 'none'}}
        onChange={() => {}}
        className="form-control"
        value={value}
        name={props.name}
      />
    </React.Fragment>
  );
};

export default observer(MediaImageWidget);
