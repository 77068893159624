import React from 'react';
import {useHistory} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {useTranslation} from 'react-i18next';
import UserProfile from './UserProfile';
import BaseButton from '../Button';
import {useStores} from '../../contexts/StoresContext';
import {mediaQueries} from '../../constants';
import {LOGIN_PAGE} from '../../urls';

const ButtonsWrapper = styled.div`
  ${mediaQueries.lg} {
    display: flex;
    flex-direction: column;
  }
`;

const Button = styled(({small, noBorder, ...rest}) => <BaseButton {...rest} />)`
  ${(props) =>
    props.noBorder &&
    css`
      &.btn {
        border: none;
      }
    `}

  ${mediaQueries.lg} {
    &.btn {
      padding: 0;
      border: none;
      background: #eeeeee;
      border-radius: 0;
    }
  }
`;

const Authentication = (props) => {
  const {onClickSignUp} = props;
  const history = useHistory();
  const {t} = useTranslation('top-bar');
  const stores = useStores();
  const {isPublic} = stores.app;

  const handleSignIn = () => {
    history.push(LOGIN_PAGE, {
      nextUrl: window.location.pathname,
    });
  };

  return (
    <div>
      {isPublic ? (
        <ButtonsWrapper>
          <Button
            onClick={handleSignIn}
            size="sm"
            variant="outline-primary"
            noBorder
          >
            {t('links.sign-in')}
          </Button>
          <Button onClick={onClickSignUp} size="sm" variant="outline-primary">
            {t('links.sign-up')}
          </Button>
        </ButtonsWrapper>
      ) : (
        <UserProfile />
      )}
    </div>
  );
};

export default Authentication;
