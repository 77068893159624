import React from 'react';

import styled from 'styled-components';
import theme from '../../theme';
import {Container} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

import NavigationLink from '../NavigationLink';

import {useStores} from '../../contexts/StoresContext';
import {ReactComponent as Logo} from '../../assets/logo.svg';
import {mediaQueries, FEATURES} from '../../constants';
import {isFeatureEnabled} from '../../utils/features';
import {PRIVACY_PAGE, TOS_PAGE, HELP_PAGE, FAQ_PAGE} from '../../urls';

const Wrapper = styled.footer`
  background-color: white;
  border-top: 1px solid ${theme.greyBorderColor};
`;

const FooterContainer = styled(Container)`
  display: flex;
  align-items: center;
  height: 72px;
  flex-wrap: wrap;

  ${mediaQueries.lg} {
    height: auto;
    &.container {
      padding: 0;
    }
  }
`;

const FooterItem = styled.div`
  margin-right: 20px;

  ${mediaQueries.lg} {
    margin: auto;
  }
`;

const PoweredBy = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-left: auto;

  ${mediaQueries.lg} {
    width: 100%;
    justify-content: center;
    padding: 15px 0;
  }
`;

const StyledLogo = styled(Logo)`
  margin-left: 20px;
  cursor: pointer;
`;

const LinksWrapper = styled.div`
  display: flex;

  ${mediaQueries.lg}, ${mediaQueries.sm} {
    width: 100%;
    justify-content: center;
    font-size: 12px;
    border-bottom: 1px solid #e8e8e8;
    padding: 15px 0;
  }
  ${mediaQueries.sm} {
    font-size: 10px;
  }
`;

const Footer = () => {
  const {t} = useTranslation('common');
  const stores = useStores();
  const showExtendedLinks = isFeatureEnabled(
    FEATURES.footerLinks,
    stores.app.features,
  );

  const handleOnPoweredByClick = () => {
    window.open('https://needslist.co', '_blank');
  };

  const helpLink = showExtendedLinks ? (
    <NavigationLink to={HELP_PAGE}>{t('footer.help')}</NavigationLink>
  ) : (
    <NavigationLink to="#">{t('footer.help')}</NavigationLink>
  );
  const faqLink = showExtendedLinks ? (
    <NavigationLink to={FAQ_PAGE}>{t('footer.faq')}</NavigationLink>
  ) : (
    <NavigationLink to="#">{t('footer.faq')}</NavigationLink>
  );

  return (
    <Wrapper>
      <FooterContainer>
        <LinksWrapper>
          <FooterItem>{helpLink}</FooterItem>
          <FooterItem>{faqLink}</FooterItem>
          <FooterItem>
            <NavigationLink to={PRIVACY_PAGE}>
              {t('footer.privacy')}
            </NavigationLink>
          </FooterItem>
          <FooterItem>
            <NavigationLink to={TOS_PAGE}>
              {t('footer.terms-of-service')}
            </NavigationLink>
          </FooterItem>
        </LinksWrapper>
        <PoweredBy>
          {t('footer.powered-by')}
          <StyledLogo onClick={handleOnPoweredByClick} />
        </PoweredBy>
      </FooterContainer>
    </Wrapper>
  );
};

export default Footer;
