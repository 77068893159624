import React from 'react';
import {useField, useFormikContext} from 'formik';
import Label from '../Form/Label';
import ImageUpload from '../Form/ImageUpload';
import {useTranslatedError} from '../../hooks/formik';
import Thumbnail from '../Thumbnail';
import ResponsiveImg from '../ResponsiveImg';

const ImageUploadField = (props) => {
  const [field, meta] = useField(props);
  const {setFieldValue} = useFormikContext();
  const {label, readOnly, onChange, ...rest} = props;
  const error = useTranslatedError(meta.error);
  const handleCustomChange = (image) => {
    setFieldValue(field.name, image);
  };

  if (readOnly) {
    if (!field.value) return null;

    return (
      <React.Fragment>
        <Label>{label}</Label>
        <Thumbnail>
          <ResponsiveImg
            src={field.value.url ? field.value.url : field.value}
            alt=""
          />
        </Thumbnail>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {label && <Label>{label}</Label>}
      <ImageUpload
        {...field}
        {...rest}
        onChange={onChange || handleCustomChange}
        error={error}
        isInvalid={!!meta.error && meta.touched}
      />
    </React.Fragment>
  );
};

export default ImageUploadField;
