import React, {useReducer, useMemo} from 'react';
import {useStores} from './StoresContext';

const initialState = {
  breadcrumbs: '',
  breadcrumbsOptions: {},
  isSuggestedItem: false,
  isPublic: false,
};

const PageContext = React.createContext();

const types = {
  PAGE_SET_BREADCRUMBS: 'PAGE_SET_BREADCRUMBS',
  PAGE_SET_IS_PUBLIC: 'PAGE_SET_IS_PUBLIC',
  PAGE_SET_IS_SUGGESTED_ITEM: 'PAGE_SET_IS_SUGGESTED_ITEM',
};

const reducer = (state, action) => {
  const {type, payload} = action;

  switch (type) {
    case types.PAGE_SET_BREADCRUMBS: {
      return {
        ...state,
        breadcrumbs: payload.key,
        breadcrumbsOptions: payload.options,
      };
    }
    case types.PAGE_SET_IS_SUGGESTED_ITEM: {
      return {
        ...state,
        isSuggestedItem: payload,
      };
    }
    case types.PAGE_SET_IS_PUBLIC: {
      return {
        ...state,
        isPublic: payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const PageProvider = ({children}) => {
  const stores = useStores();
  const isPublic = !stores.app.user;
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    isPublic,
  });

  const actions = useMemo(() => {
    return {
      setBreadcrumbs: (payload) => {
        dispatch({
          type: types.PAGE_SET_BREADCRUMBS,
          payload: payload,
        });
      },
      setIsSuggestedItem: (payload) => {
        dispatch({
          type: types.PAGE_SET_IS_SUGGESTED_ITEM,
          payload: payload,
        });
      },
    };
  }, [dispatch]);

  return (
    <PageContext.Provider value={[state, actions]}>
      {children}
    </PageContext.Provider>
  );
};

const usePageStore = () => {
  const context = React.useContext(PageContext);
  if (context === undefined) {
    throw new Error('`usePageStore` must be used within a `PageContext`.');
  }
  return context;
};

export {PageContext, PageProvider, usePageStore};
