export function parsePath(url, params) {
  return Object.keys(params || {}).reduce((accum, current) => {
    return accum.replace(`:${current}`, params[current]);
  }, url);
}

export function getInitials(name) {
  return name
    .split(' ')
    .slice(0, 2)
    .map((value) => value[0])
    .join('');
}

export function importAll(r) {
  return r.keys().reduce(
    (accum, key) => ({
      ...accum,
      [key]: r(key),
    }),
    {},
  );
}

export const normalizeFloatNumber = (number) => {
  if (typeof number === 'string') {
    return parseFloat(number.replace(',', '.'));
  }

  return number;
};

export const getUrlWithLang = (urlBase, lang) => {
  return `${urlBase}?language=${lang}`;
};

export const getLookupDataByKey = (currentFilter, cachedLookupData) => {
  const filter =
    currentFilter === 'met-claimed-by' ? 'organization' : currentFilter;
  return cachedLookupData[filter] || [];
};

export const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
