import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Row, Col} from 'react-bootstrap';
import {Switch, useHistory, useParams} from 'react-router-dom';
import {observer} from 'mobx-react';
import UserCard from './UserCard';
import UserDetailsCard from './UserDetailsCard';
import UserDetailsEditCard from './UserDetailsEditCard';
import {usePageStore} from '../../contexts/PageContext';
import {Route} from '../../components/Layout';
import Title from '../../components/Title';
import {useApi} from '../../contexts/ApiContext';
import {
  USER_PROFILE,
  USER_PROFILE_EDIT_PROFILE,
  USER_PROFILE_CHANGE_PASSWORD,
  USER_PROFILE_MOBILE_ACCESS,
} from '../../urls';
import ChangePasswordForm from './ChangePasswordForm';
import {useStores} from '../../contexts/StoresContext';
import Dialog from '../../components/Dialog';
import SuccessToast from '../../components/SuccessToast';

const UserProfile = () => {
  const stores = useStores();
  const api = useApi();
  const {t} = useTranslation('user');
  const [, pageActions] = usePageStore();
  const {userUUID} = useParams();
  const [user, setUser] = useState(null);
  const [showPasswordChangeToast, setShowPasswordChangeToast] = useState(false);
  const history = useHistory();

  const {uuid, isAdmin} = stores.app.user;

  useEffect(() => {
    if (!isAdmin) {
      return history.push(USER_PROFILE.replace(':userUUID', uuid));
    }
  }, [history, isAdmin, uuid]);

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'my-profile'});
  }, [pageActions]);

  const getUserProfile = useCallback(async () => {
    const userProfileResponse = await api.getUserDetails(userUUID);
    setUser(userProfileResponse);
  }, [userUUID, api]);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  const onEditProfile = () => {
    const url = USER_PROFILE.replace(':userUUID', user.uuid);
    window.location.replace(`${url}`);
  };

  const handleToggleUserStatus = async () => {
    await api.toggleUserStatus(user.uuid);
    getUserProfile();
  };

  const handleCloseModal = () => {
    history.push(USER_PROFILE.replace(':userUUID', user.uuid));
  };

  const handleMobileAccess = async () => {
    await api.mobileAccess();
    history.push(USER_PROFILE.replace(':userUUID', user.uuid));
  };

  const updatePasswordToastVisibility = (showToast) => {
    setShowPasswordChangeToast(showToast);
  };

  if (!user) return null;

  return (
    <React.Fragment>
      <Title>
        {t('titles.my-profile', {user: `${user.first_name} ${user.last_name}`})}
      </Title>
      <Row>
        <Col lg={4}>
          <UserCard
            user={user}
            isOwnProfile={stores.app.user.uuid === userUUID}
            onToggleUserStatus={handleToggleUserStatus}
          />
        </Col>
        <Col lg={8}>
          {showPasswordChangeToast && (
            <SuccessToast
              title={t('toast.change-password-title')}
              text={t('toast.change-password-text')}
            />
          )}
          <Switch>
            <Route exact path={USER_PROFILE}>
              <UserDetailsCard user={user} />
            </Route>
            <Route exact path={USER_PROFILE_EDIT_PROFILE}>
              <UserDetailsEditCard user={user} onSubmit={onEditProfile} />
            </Route>
            <Route exact path={USER_PROFILE_CHANGE_PASSWORD}>
              <UserDetailsCard user={user} />
              {!showPasswordChangeToast && (
                <ChangePasswordForm
                  updatePasswordToastVisibility={updatePasswordToastVisibility}
                />
              )}
            </Route>
            <Route exact path={USER_PROFILE_MOBILE_ACCESS}>
              <Dialog
                header={t('dialogs.mobile-link.header')}
                message={t('dialogs.mobile-link.message')}
                onConfirm={handleMobileAccess}
                confirmText={t('dialogs.mobile-link.confirm')}
                noIcon
                onClose={handleCloseModal}
              />
            </Route>
          </Switch>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default observer(UserProfile);
