import React from 'react';

import ItemDataValue from '../ItemForm/ItemDataValue';
import Label from '../Form/Label';
import ErrorMessage from '../Form/ErrorMessage';

const ReadOnlyField = (props) => {
  const {label, value, withoutTextStyle, error} = props;

  return (
    <React.Fragment>
      {label && <Label>{label}</Label>}
      <ItemDataValue withoutTextStyle={withoutTextStyle}>{value}</ItemDataValue>
      {error && <ErrorMessage forceDisplay>{error}</ErrorMessage>}
    </React.Fragment>
  );
};

export default ReadOnlyField;
