import React, {useState} from 'react';
import {Row as BootstrapRow, Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import styles from './Row.module.scss';

const Row = (props) => {
  const {t} = useTranslation('reports');
  const {onRowClick, isClickeable, columns, index, data, onViewDetails, type} =
    props;
  const [mouseOver, setMouseOver] = useState(false);
  const allowHover = onViewDetails && (!type || type === 'posted');

  const handleOnRowClick = () => {
    if (onRowClick) {
      onRowClick();
    } else return;
  };

  const handleMouseOver = () => {
    if (!allowHover) return;
    setMouseOver(true);
  };

  const handleMouseLeave = () => {
    if (!allowHover) return;
    setMouseOver(false);
  };

  const handleViewDetails = () => {
    onViewDetails(data);
  };

  return (
    <div
      className={
        isClickeable && data.side
          ? `${styles.rowData} ${styles.isClickeable}`
          : styles.rowData
      }
      key={index}
      onClick={handleOnRowClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      style={
        columns[columns.length - 1].extraPadding > 0
          ? {paddingRight: columns[columns.length - 1].extraPadding}
          : {}
      }>
      {mouseOver && (
        <div onClick={handleViewDetails} className={styles.viewDetails}>
          {t('labels.view-details')}
        </div>
      )}
      {!mouseOver && (
        <BootstrapRow>
          {columns.map((column, index) => {
            return (
              <Col
                key={index}
                sm={column.size}
                className={
                  column.moreOptions
                    ? `${styles.moreOptions} ${column.className || ''}`
                    : column.className || ''
                }>
                {column.renderCell(data)}
              </Col>
            );
          })}
        </BootstrapRow>
      )}
    </div>
  );
};

export default Row;
