import React from 'react';
import {Form} from 'react-bootstrap';
import {useField, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';

const ChoiceOther = (props) => {
  const {allowOther, onChange, as, name} = props;
  const {t} = useTranslation('common');
  const [field] = useField(name);
  const {setFieldValue} = useFormikContext();

  if (!allowOther) {
    return null;
  }

  const otherValue = field.value.filter((e) => typeof e === 'string').pop();
  const otherChecked = typeof otherValue === 'string';

  const FormCheck = as;

  const handleOnChange = (e) => {
    let value = [...field.value];
    value = value.filter((e) => typeof e !== 'string');
    value.push(e.target.value);
    setFieldValue(field.name, value);
  };

  return (
    <React.Fragment>
      <FormCheck
        id="other"
        label={t('other')}
        checked={otherChecked}
        value={otherValue}
        onChange={onChange}
      />
      {otherChecked && (
        <Form.Control
          type="text"
          value={otherValue}
          placeholder={t('other_placeholder')}
          onChange={handleOnChange}
        />
      )}
    </React.Fragment>
  );
};

export default ChoiceOther;
