import React from 'react';
import styled from 'styled-components';

import {Title} from '../../../components/OnboardingCarousel';
import {useTranslation} from 'react-i18next';

import placeholder from '../../../assets/onboarding/database/3.jpg';
import {mediaQueries} from '../../../constants';
import Button from '../../../components/Button/Button';

const Wrapper = styled.div`
  padding: 130px 0 32px 27px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-image: url(${placeholder});
  background-repeat: no-repeat;
  background-position: right -10px bottom -10px;

  ${mediaQueries.lg} {
    padding: 45px 0 10px;
    background-position: right -125px bottom -100px;
  }
`;

const Container = styled.div`
  width: 25%;

  ${mediaQueries.lg} {
    width: 100%;
  }
`;

const Controls = styled.div`
  margin-top: auto;
`;

const StyledButton = styled(Button)`
  ${mediaQueries.lg} {
    &.btn {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;

const PreviousButton = styled(StyledButton)`
  margin-right: 15px;
`;

const ThirdSlide = (props) => {
  const {t} = useTranslation('onboarding');

  return (
    <Wrapper>
      <Container>
        <Title>{t('database.third-slide.title')}</Title>
        <p>{t('database.third-slide.text')}</p>
      </Container>
      <Controls>
        <PreviousButton variant="outline-primary" onClick={props.onPrevious}>
          {t('previous')}
        </PreviousButton>
        <StyledButton variant="primary" onClick={props.onClose}>
          {t('database.third-slide.finish')}
        </StyledButton>
      </Controls>
    </Wrapper>
  );
};

export default ThirdSlide;
