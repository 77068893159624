import sharedStyles from './shared.module.scss';
import Button from './Button';
import {useState, useEffect, useMemo, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {Tab, Tabs} from 'react-bootstrap';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {useDatePickerLocale} from './filterUtils';

const DateFilter = (props) => {
  const {cancelClick, value, onRemoveUrl, queryParam} = props;
  const [selectedTab, setSelectedTab] = useState('is');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const history = useHistory();
  const {t} = useTranslation('item-reports');

  const tabs = useMemo(() => ['is', 'not'], []);

  const datePickerLocale = useDatePickerLocale();

  const applyFilter = () => {
    const value =
      selectedTab +
      ':' +
      moment(startDate).format('yyyy-MM-DD') +
      ':' +
      moment(endDate).format('yyyy-MM-DD');
    const queryStringParams = new URLSearchParams(onRemoveUrl);
    queryStringParams.append(queryParam, value);
    cancelClick();
    history.push({search: queryStringParams.toString()});
  };

  const handleChange = useCallback((e) => {
    setStartDate(e.selection.startDate);
    setEndDate(e.selection.endDate);
  }, []);

  useEffect(() => {
    if (value) {
      // Add validation and error handling.
      const [prefix, from, to] = value.split(':');
      setStartDate(new Date(from));
      setEndDate(new Date(to));
      setSelectedTab(prefix);
    }
  }, [value]);

  return (
    <div className={sharedStyles.popupPanel}>
      <div className={sharedStyles.container}>
        <div className={sharedStyles.containerBorders}>
          <div className={sharedStyles.content}>
            <div className={sharedStyles.contentInner}>
              <Tabs activeKey={selectedTab} onSelect={(k) => setSelectedTab(k)}>
                {tabs.map((value, index) => (
                  <Tab
                    key={value}
                    eventKey={value}
                    title={t('filters.dates.tabs.' + value)}
                  ></Tab>
                ))}
              </Tabs>
              <DateRange
                ranges={[
                  {startDate: startDate, endDate: endDate, key: 'selection'},
                ]}
                onChange={handleChange}
                locale={datePickerLocale}
              />
            </div>
          </div>
          <div className={sharedStyles.divider}>
            <hr className={sharedStyles.dividerLine} />
          </div>
          <div className={sharedStyles.footerContainer}>
            <div className={sharedStyles.footerContainerInner}>
              <Button onClick={applyFilter} active={true}>
                {t('filters.apply')}
              </Button>
              <Button onClick={cancelClick}>{t('filters.cancel')}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
