import React from 'react';
import Typeahead from '../Form/Typeahead';
import {ButtonGroup, Dropdown} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import styles from './SearchBar.module.scss';

const SearchBar = (props) => {
  const {customMenu = false} = props;
  const {t} = useTranslation('search');
  let typeaheadRef = null;
  const setRef = (ref) => (typeaheadRef = ref);

  const renderItem = (item) => {
    const onItemClick = () => {
      const instance = typeaheadRef.current;
      instance.clear();

      props.onSearchCategoryClick(item);
    };
    return (
      <Dropdown.Item
        key={item.value}
        onSelect={onItemClick}
        eventKey={item.value}>
        {item.label}
      </Dropdown.Item>
    );
  };

  const onChange = (value) => {
    if (!props.persistValue) {
      const instance = typeaheadRef.current;
      instance.clear();
    }
    props.onChange(value);
  };

  const handleOnClean = () => {
    const instance = typeaheadRef.current;
    instance.clear();
    props.onChange('');
  };

  const dropdown = props.dropDownOptions ? (
    props.dropDownOptions.length !== 0 ? (
      <Dropdown>
        <Dropdown.Toggle as={ButtonGroup} className={styles.dropdownTrigger}>
          {props.value.label}
        </Dropdown.Toggle>
        <Dropdown.Menu>{props.dropDownOptions.map(renderItem)}</Dropdown.Menu>
      </Dropdown>
    ) : (
      <div
        className={`${styles.noOptionsContainer} ${
          !!props.noPadding && styles.noPadding
        }`}>
        <p className={styles.noOptionsLabel}>{props.value.label}</p>
      </div>
    )
  ) : null;

  return (
    <div className={`${styles.wrapper} ${props.className}`}>
      {dropdown}
      <Typeahead
        className={styles.searchInput}
        customMenu={customMenu}
        multiple={props.multiple}
        bsSize="lg"
        placeholder={props.placeholder || t('search-bar.placeholder.search-by')}
        id={props.id}
        onChange={onChange}
        isLoading={props.isLoading}
        onSearch={props.onSearch}
        allowNew={props.allowNew}
        options={props.options}
        setRef={setRef}
        newSelectionPrefix={props.newSelectionPrefix}
        // @FIXME NL-1598 (verify if this is working)
        // selectHintOnEnter={props.selectHintOnEnter}
        onKeyDown={props.onKeyDown}
        selectPartialMatchOnEnter={props.selectPartialMatchOnEnter}
        allowClean={props.allowClean}
        onClean={handleOnClean}
        onInputChange={props.onInputChange}
        data-hj-allow
        filterBy={() => true} //show all options
      />
    </div>
  );
};

export default SearchBar;
