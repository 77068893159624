import React, {useRef, useEffect, useContext} from 'react';
import {Form} from 'react-bootstrap';
import ErrorMessage from '../ErrorMessage';
import FormContext from '../FormContext';
import styles from './TextArea.module.scss';

const TextArea = (props) => {
  const {setRef, ...rest} = props;
  const {setFieldRef} = useContext(FormContext);
  const inputRef = useRef(null);

  // @FIX replace this for context
  useEffect(() => {
    if (setRef) {
      setRef(inputRef);
    }
  }, [setRef, inputRef]);

  useEffect(() => {
    setFieldRef(props.name, inputRef);
  }, [props.name, setFieldRef]);

  return (
    <div className={styles.wrapper}>
      <Form.Control ref={inputRef} as="textarea" {...rest} data-hj-allow />
      <ErrorMessage>{props.error}</ErrorMessage>
    </div>
  );
};

export default TextArea;
