import {useState, useEffect, useCallback} from 'react';
import SavedViewDialog from './SavedViewDialog';
import SavedViewWarning from './SavedViewWarning';
import styles from './SavedView.module.scss';
import {useApi} from '../../../contexts/ApiContext';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CircleExclamation} from '../../../assets/icons/exclamation_icon.svg';

const SavedViewDropdown = (props) => {
  const api = useApi();
  const history = useHistory();
  const {
    setShowSavedViewModal,
    setSavedViewToastText,
    showSavedViewModal,
    showChangeViewWarning,
    setShowChangeViewWarning,
    showDeleteViewWarning,
    setShowDeleteViewWarning,
    onClose,
  } = props;
  const [savedViews, setSavedViews] = useState({});
  const [targetViewId, setTargetViewId] = useState();
  const [targetViewQueryString, setTargetViewQueryString] = useState();
  const {t} = useTranslation('item-reports');

  const updateSavedViewsList = useCallback(async () => {
    const response = await api.getSavedViews();
    setSavedViews(response);
  }, [api]);

  useEffect(() => {
    updateSavedViewsList();
  }, [updateSavedViewsList]);

  const handleSaveNewViewClick = () => {
    // TODO: Show toast when new view successfully saved.
    setShowSavedViewModal(true);
  };

  const handleUpdateViewClick = async () => {
    const viewQueryString = window.location.search;
    const viewId = window.localStorage.getItem('viewId');

    const response = await api.updateSavedView({viewQueryString, viewId});
    if (response.status === 'OK') {
      setSavedViewToastText(t('saved_view.toasts.successful_update'));

      window.localStorage.setItem('viewQueryString', viewQueryString);
      window.localStorage.setItem('viewId', viewId);
      updateSavedViewsList();

      // Hide the toast after 3 seconds.
      setTimeout(() => setSavedViewToastText(''), 3000);
    }
  };

  const maybeApplySavedView = (
    viewQueryString,
    viewId,
    forceApplySavedView,
  ) => {
    const currentViewId = window.localStorage.getItem('viewId');
    if (
      !areFiltersUpdated() &&
      !forceApplySavedView &&
      currentViewId &&
      currentViewId !== viewId
    ) {
      setTargetViewId(viewId);
      setTargetViewQueryString(viewQueryString);
      setShowChangeViewWarning(true);
      return;
    }
    window.localStorage.setItem('viewQueryString', viewQueryString);
    window.localStorage.setItem('viewId', viewId);

    window.location.search = viewQueryString;
  };

  const enableUpdateView = () => {
    const oldViewQueryString = window.localStorage.getItem('viewQueryString');
    const newViewQueryString = window.location.search;

    return (
      window.localStorage.getItem('viewId') &&
      oldViewQueryString !== newViewQueryString
    );
  };

  const isCurrentView = (viewId) => {
    return viewId === window.localStorage.getItem('viewId');
  };

  const areFiltersUpdated = () => {
    return (
      window.location.search === window.localStorage.getItem('viewQueryString')
    );
  };

  const handleDeleteView = async (viewId) => {
    await api.deleteSavedView({viewId});
    updateSavedViewsList();
    if (viewId === window.localStorage.getItem('viewId')) {
      window.localStorage.setItem('viewId', '');
      window.localStorage.setItem('viewQueryString', '');
    }
  };

  const getDropdownItemStyle = (viewId) => {
    if (isCurrentView(viewId)) {
      if (!areFiltersUpdated()) {
        return styles.selectedEditedDropdownListItem;
      }
      return styles.selectedDropdownListItem;
    }
    return styles.dropdownListItem;
  };

  // TODO: Add translations
  return (
    <div className={styles.dropdownContainer} id="savedViewDropdown">
      <div className={styles.dropdownList}>
        <div className={styles.dropdownListHeader}>
          {t('saved_view.dropdown_title')}
          {window.localStorage.getItem('viewId') &&
            !areFiltersUpdated() &&
            window.location.search !== '' && (
              <div className={styles.unsavedChangesContainer}>
                <CircleExclamation className={styles.circleExclamation} />
                <div className={styles.unsavedChanges}>
                  {t('saved_view.unsaved_changes')}
                </div>
              </div>
            )}
        </div>
        {Object.entries(savedViews).map(([id, viewDetails]) => {
          return (
            <div
              className={getDropdownItemStyle(id)}
              key={id}
              onClick={() => {
                maybeApplySavedView(
                  viewDetails['view_query_string'],
                  id,
                  false,
                );
                setShowDeleteViewWarning(false);
              }}
            >
              <div className={styles.dropdownListItemText}>
                {viewDetails['name']}
              </div>
              <img
                src="https://fonts.gstatic.com/s/i/short-term/release/googlesymbols/close/default/24px.svg"
                alt="close icon"
                className={
                  isCurrentView(id)
                    ? styles.selectedViewDeleteIcon
                    : styles.deleteIcon
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setTargetViewId(id);
                  setShowDeleteViewWarning(true);
                  setShowChangeViewWarning(false);
                }}
              />
              {showChangeViewWarning && (
                <SavedViewWarning
                  headerText={t('saved_view.warning.change-view-header')}
                  descriptionText={t(
                    'saved_view.warning.change-view-description',
                  )}
                  actionButtonText={t('saved_view.warning.change-view-button')}
                  actionButtonHandler={(e) => {
                    e.stopPropagation();
                    maybeApplySavedView(
                      targetViewQueryString,
                      targetViewId,
                      true,
                    );
                  }}
                  closeButtonHandler={(e) => {
                    e.stopPropagation();
                    setShowChangeViewWarning(false);
                  }}
                />
              )}
              {showDeleteViewWarning && (
                <SavedViewWarning
                  headerText={t('saved_view.warning.delete-view-header')}
                  descriptionText={t(
                    'saved_view.warning.delete-view-description',
                  )}
                  actionButtonText={t('saved_view.warning.delete-view-button')}
                  actionButtonHandler={(e) => {
                    e.stopPropagation();
                    handleDeleteView(targetViewId);
                    setShowDeleteViewWarning(false);
                  }}
                  closeButtonHandler={(e) => {
                    e.stopPropagation();
                    setShowDeleteViewWarning(false);
                  }}
                />
              )}
            </div>
          );
        })}
        <div>
          <div
            className={
              enableUpdateView()
                ? styles.dropdownListItem
                : styles.disabledDropdownListItem
            }
            onClick={handleUpdateViewClick}
          >
            <img
              src="https://fonts.gstatic.com/s/i/short-term/release/googlesymbols/upload_file/default/24px.svg"
              alt="update icon"
              className={
                enableUpdateView()
                  ? styles.dropdownListIcon
                  : styles.disabledDropdownListIcon
              }
            />
            <div className={styles.dropdownListItemText}>
              {t('saved_view.update_current_view')}
            </div>
          </div>
          <div
            className={
              history.location.search
                ? styles.dropdownListItem
                : styles.disabledDropdownListItem
            }
            onClick={handleSaveNewViewClick}
          >
            <img
              src="https://fonts.gstatic.com/s/i/short-term/release/googlesymbols/add/default/24px.svg"
              alt="plus icon"
              className={
                history.location.search
                  ? styles.dropdownListIcon
                  : styles.disabledDropdownListIcon
              }
            />
            <div className={styles.dropdownListItemText}>
              {t('saved_view.save_new_view')}
            </div>
          </div>
        </div>
      </div>
      {showSavedViewModal && (
        <SavedViewDialog
          onClose={onClose}
          setSavedViewToastText={setSavedViewToastText}
        />
      )}
    </div>
  );
};

export default SavedViewDropdown;
