import {useEffect} from 'react';
import {getNestedField} from '../utils/object';
import {useTranslation} from 'react-i18next';
import {useFormikContext} from 'formik';

const useErrorFocus = (shouldFocus, errors, fields, refs) => {
  useEffect(() => {
    const firstError = findFirstError(fields, errors);

    if (shouldFocus && firstError && refs[firstError]) {
      refs[firstError].current.focus();
    }
  }, [shouldFocus, errors, fields, refs]);
};

const useTranslatedErrors = (errors) => {
  const {t} = useTranslation('forms');
  return translateErrorsObject(t, errors);
};

const useTranslatedError = (error) => {
  const {t} = useTranslation('forms');

  if (!error) return null;

  return t(error.key, error.options);
};

const useRemoveError = (fieldName, event) => {
  const {errors, setErrors} = useFormikContext();
  const newErrors = {...errors};

  const removeError = (e) => {
    if (event) {
      event(e);
    }

    if (errors.extra_attribute) {
      // need the pure name of the field
      const field = fieldName.split('.')[1];

      if (errors.extra_attribute[field]) {
        delete newErrors.extra_attribute[field];
      }
    }

    if (errors[fieldName]) {
      delete newErrors[fieldName];
    }

    setErrors(newErrors);
  };

  return {removeError};
};

const translateErrorsObject = (t, obj) => {
  return Object.keys(obj).reduce((translated, key) => {
    let translatedValue;
    const valueToTranslate = obj[key];

    if (!valueToTranslate) return translated;

    if (
      typeof valueToTranslate === 'object' &&
      !valueToTranslate.customTranslationKey
    ) {
      translatedValue = translateErrorsObject(t, valueToTranslate);
    } else {
      translatedValue = t(valueToTranslate.key, valueToTranslate.options);
    }

    translated[key] = translatedValue;

    return translated;
  }, {});
};

const findFirstError = (fields, errors) => {
  return fields.find((field) => getNestedField(field, errors) !== null);
};

export {useErrorFocus, useTranslatedError, useTranslatedErrors, useRemoveError};
