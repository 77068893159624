import styled from 'styled-components';

const Title = styled.h2`
  font-weight: 600;
  font-size: 40px;
  line-height: 46px;
  font-family: 'Roboto Slab', serif; ;
`;

export default Title;
