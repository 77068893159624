import styled from 'styled-components';

import {Form as BootstrapForm} from 'react-bootstrap';

import theme from '../../theme';

const Form = styled(BootstrapForm)`
  background-color: ${theme.formBorderColor};
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 33px 20px 0 20px;
`;

export default Form;
