import React from 'react';
import {observer} from 'mobx-react';
import {Form as BootstrapForm} from 'react-bootstrap';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';
import styles from './UserDetails.module.scss';

const UserDetailsForm = (props) => {
  const {handleSubmit} = props;

  return (
    <BootstrapForm
      noValidate
      onSubmit={handleSubmit}
      className={styles.userDetailsForm}>
      {props.children}
    </BootstrapForm>
  );
};

const EnhancedForm = withFormik({
  displayName: 'UserDetailsForm',
  mapPropsToValues: (props) => ({
    firstName: props.user.first_name || '',
    lastName: props.user.last_name || '',
    role: props.user.organization_role || '',
    phone: props.user.phone_number || '',
    timezone: props.user.timezone || '',
    currency: props.user.currency || '',
    language: props.user.language.id || props.user.language || null,
  }),
  validationSchema: yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    role: yup.string().required(),
    phone: yup.string().required(),
    timezone: yup.string().required(),
    currency: yup.string().required(),
    language: yup.number(),
  }),
  handleSubmit: async (values, {props, setSubmitting, setErrors}) => {
    const data = {
      first_name: values.firstName,
      last_name: values.lastName,
      phone_number: values.phone,
      timezone: values.timezone,
      organization_role: values.role,
      currency: values.currency,
      language: values.language,
    };
    const selectedLanguage = props.stores.app.languages.find(
      (language) => language.id === values.language,
    );

    props.stores.app.setLanguage(selectedLanguage.code);
    await props.api.updateUserDetails(props.stores.app.user.uuid, data);
    props.onSubmit();
  },
})(UserDetailsForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  const api = useApi();
  return <EnhancedForm api={api} stores={stores} {...props} />;
};

export default observer(FormWrapper);
