import React from 'react';
import Pill from './Pill';
import styles from './Pills.module.scss';

const Pills = (props) => {
  if (props.pills.length === 0) return null;

  const renderPill = (value, index) => {
    const onRemove = props.onPillRemove
      ? () => props.onPillRemove(index)
      : null;
    const onClick = props.onPillRemove ? null : () => props.onClick(value);
    const selected = props.selected && props.selected.includes(value);
    const disabled = props.availableOptions
      ? !props.availableOptions.includes(value)
      : false;

    return (
      <Pill
        key={index}
        onRemove={onRemove}
        onClick={onClick}
        selected={selected}
        disabled={disabled}>
        {value}
      </Pill>
    );
  };

  return (
    <div className={`${styles.pillsContainer} ${props.className}`}>
      {props.pills.map(renderPill)}
    </div>
  );
};

export default Pills;
