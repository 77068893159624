import {useField, useFormikContext} from 'formik';
import React, {useState, useEffect} from 'react';

import {useApi} from '../../contexts/ApiContext';
import SelectField from './SelectField';

const TimezoneField = (props) => {
  const [timezones, setTimezones] = useState([]);
  const api = useApi();
  const {setFieldValue} = useFormikContext();
  const {name} = props;
  const [field] = useField(name);

  useEffect(() => {
    const fetchTimezones = async () => {
      const response = await api.getTimeZones();
      response.unshift(['', 'Select a timezone']);
      setTimezones(response);
      if (!field.value) {
        const date = new Date();
        const timezone = date.toString().split('GMT')[1].split(' (')[0];
        const formattedTimezone =
          timezone.slice(0, 3) + ':' + timezone.slice(3);
        const matchingTimezone = response.find((timezone) =>
          timezone[0].includes(formattedTimezone),
        );
        setFieldValue(name, matchingTimezone[0]);
      }
    };

    fetchTimezones();
  }, [field.value, name, setFieldValue, api]);

  if (!timezones.length) return null;

  const optionsMap = ([id, label]) => (
    <option value={id} key={id}>
      {label}
    </option>
  );

  return (
    <SelectField {...props}>
      {props.childen}
      {timezones.map(optionsMap)}
    </SelectField>
  );
};

export default TimezoneField;
