import React from 'react';
import {useField, useFormikContext} from 'formik';
import Label from '../Form/Label';
import FileUpload from '../Form/FileUpload';
import {useTranslatedError} from '../../hooks/formik';
import Thumbnail from '../Thumbnail';
import ResponsiveImg from '../ResponsiveImg';

const FileUploadField = (props) => {
  const [field, meta] = useField(props);
  const {setFieldValue} = useFormikContext();
  const {label, readOnly, onChange, multiple, ...rest} = props;
  const error = useTranslatedError(meta.error);
  const handleCustomChange = (file) => {
    setFieldValue(field.name, file);
  };

  if (readOnly) {
    if (!field.value) {
      return null;
    }

    return (
      <React.Fragment>
        <Label>{label}</Label>
        <Thumbnail>
          <ResponsiveImg
            src={field.value.url ? field.value.url : field.value}
            alt=""
          />
        </Thumbnail>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {label && <Label>{label}</Label>}
      <FileUpload
        {...field}
        {...rest}
        onChange={onChange || handleCustomChange}
        error={error}
        isInvalid={!!meta.error && meta.touched}
        multiple={multiple}
      />
    </React.Fragment>
  );
};

export default FileUploadField;
