import React, {useState} from 'react';
import styled from 'styled-components';
import OnboardingCarousel, {
  OnboardingCarouselItem,
} from '../../../components/OnboardingCarousel';
import Content from './Content';
import Container from './Container';
import {useTranslation} from 'react-i18next';

import {useWindowSize} from '../../../hooks/utils';
import {breakpoints} from '../../../constants';

import background1 from '../../../assets/onboarding/manage/01.png';
import background2 from '../../../assets/onboarding/manage/02.png';
import background3 from '../../../assets/onboarding/manage/03.png';
import background4 from '../../../assets/onboarding/manage/04.png';
import background5 from '../../../assets/onboarding/manage/05.png';

const StyledItem = styled(OnboardingCarouselItem)`
  height: 554px;
`;

const Onboarding = (props) => {
  const {t} = useTranslation('onboarding');
  const [activeSlide, setActiveSlide] = useState(0);
  const windowSize = useWindowSize();

  const handleSelect = (index) => {
    setActiveSlide(index);
  };
  const handleNext = () => {
    setActiveSlide(activeSlide + 1);
  };
  const handlePrevious = () => {
    setActiveSlide(activeSlide - 1);
  };

  const slides = [
    {
      title: t('manage.first-slide.title'),
      text: t('manage.first-slide.text'),
      isRightAligned: true,
      adaptMobileBackground: false,
      background: background1,
    },
    {
      title: t('manage.second-slide.title'),
      text: t('manage.second-slide.text'),
      isRightAligned: false,
      adaptMobileBackground: false,
      background: background2,
      onPrevious: handlePrevious,
    },
    {
      title: t('manage.third-slide.title'),
      text: t('manage.third-slide.text'),
      isRightAligned: false,
      adaptMobileBackground: false,
      background: background3,
      onPrevious: handlePrevious,
    },
    {
      title: t('manage.forth-slide.title'),
      text: t('manage.forth-slide.text'),
      isRightAligned: true,
      adaptMobileBackground: true,
      background: background4,
      onPrevious: handlePrevious,
    },
    {
      title: t('manage.fifth-slide.title'),
      text: t('manage.fifth-slide.text'),
      isRightAligned: true,
      adaptMobileBackground: true,
      background: background5,
      onPrevious: handlePrevious,
      onNext: props.onClose,
      onNextText: t('manage.fifth-slide.finish'),
    },
  ];

  const renderSlide = (slide, index) => {
    const onNext = slide.onNext || handleNext;

    return (
      <StyledItem key={index} onClose={props.onClose}>
        <Container
          isRightAligned={slide.isRightAligned}
          background={slide.background}
          adaptMobileBackground={slide.adaptMobileBackground}
        >
          <Content
            title={slide.title}
            onNext={onNext}
            onNextText={slide.onNextText}
            onPrevious={slide.onPrevious}
          >
            {slide.text}
          </Content>
        </Container>
      </StyledItem>
    );
  };

  return (
    <OnboardingCarousel
      activeIndex={activeSlide}
      onSelect={handleSelect}
      touch={windowSize.width > breakpoints.lg}
    >
      {slides.map(renderSlide)}
    </OnboardingCarousel>
  );
};

export default Onboarding;
