import {useTranslation} from 'react-i18next';
import {Row, Col} from 'react-bootstrap';
import {useStores} from '../../contexts/StoresContext';
import {useLocaleNumber} from '../../hooks/number';
import styles from './TotalsRow.module.scss';

const TotalsRow = (props) => {
  const {itemTotal, quantityTotal, allowedColumns} = props;
  const {t} = useTranslation('item-reports');
  const localeNumber = useLocaleNumber();
  const stores = useStores();
  const {currency} = stores.app;

  const totalQuantity = `${localeNumber(quantityTotal.total_quantity)} ${
    quantityTotal.total_quantity_unit
  }`;

  const totalAmount = `${localeNumber(quantityTotal.total_amount)} ${
    currency.code
  }`;

  const totalBeneficiaries = `${localeNumber(
    quantityTotal.total_beneficiaries,
  )}`;

  const showBeneficiaries = allowedColumns.find(
    (column) => column === 'beneficiaries',
  );

  return (
    <Row className={styles.totalsRowContainer}>
      <Col className={`${styles.totalsContainer} ${styles.totalsText}`}>
        {t('total.total-items')} <br />
        <span className={styles.totalsData}>{itemTotal}</span>
      </Col>
      {showBeneficiaries && (
        <Col className={`${styles.totalsContainer} ${styles.totalsText}`}>
          {`${t('total.total-beneficiaries')}`} <br />
          <span className={styles.totalsData}>{totalBeneficiaries}</span>
        </Col>
      )}
      {quantityTotal.total_quantity !== -1 && quantityTotal.uom_fulfillment ? (
        <Col className={styles.totalsContainer}>
          {t('total.total-quantity', {
            uom: quantityTotal.uom_fulfillment,
          })}
          <br />
          <span className={styles.totalsData}>{totalQuantity}</span>
        </Col>
      ) : (
        <Col className={styles.totalsContainer}>
          {t('total.total-units')} <br />
          <span className={styles.totalsData}>
            {t('common:not-apply')} <br />
          </span>
          <span className={styles.textOrange}>
            {t('total.multiple-units-types')}
          </span>
        </Col>
      )}
      <Col className={`${styles.totalsContainer} ${styles.totalsAmount}`}>
        {`${t('total.total-amount')}`} <br />
        <span className={styles.totalsData}>
          {quantityTotal.total_amount ? totalAmount : 0}
        </span>
      </Col>
    </Row>
  );
};

export default TotalsRow;
