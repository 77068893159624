import React from 'react';
import {useLocaleNumber} from '../../hooks/number';
import styles from './ProgressIndicator.module.scss';

const ProgressIndicator = ({uomFulfillment, total, progress, hideQuantity}) => {
  const localeNumber = useLocaleNumber();
  const width = total !== 0 ? (progress * 100) / total : 0;

  return (
    <React.Fragment>
      <div
        className={`${styles.quantity} ${
          hideQuantity ? styles.hide : styles.show
        }`}>
        {localeNumber(progress)}/{localeNumber(total)} {uomFulfillment}
      </div>
      <div className={styles.bar}>
        <div className={styles.progress} style={{width: `${width}%`}} />
      </div>
    </React.Fragment>
  );
};

export default ProgressIndicator;
