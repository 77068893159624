import React from 'react';
import {useField} from 'formik';

import {useTranslatedError} from '../../hooks/formik';
import Checkbox from '../Form/Checkbox';
import {useTranslation} from 'react-i18next';
import ReadOnlyField from './ReadOnlyField';
import ErrorMessage from '../Form/ErrorMessage';

const CheckboxField = (props) => {
  const [field, meta] = useField(props);
  const error = useTranslatedError(meta.error);
  const {t} = useTranslation('common');
  const {label, readOnly, onChange, options, isRequired, ...rest} = props;
  const fieldLabel = isRequired ? (
    <React.Fragment>
      {label} <span className="text-danger">*</span>
    </React.Fragment>
  ) : (
    label
  );

  if (readOnly) {
    const value = field.value ? t('yes') : t('no');
    return <ReadOnlyField label={label} value={value} />;
  }

  return (
    <React.Fragment>
      <Checkbox
        {...field}
        {...rest}
        type="checkbox"
        name={field.name}
        id={field.name}
        label={fieldLabel}
        isInvalid={!!meta.error && meta.touched}
        checked={field.value}
        onChange={onChange || field.onChange}
        error={error}
        custom
      />
      {error && <ErrorMessage forceDisplay>{error}</ErrorMessage>}
    </React.Fragment>
  );
};

export default CheckboxField;
