import sharedStyles from './shared.module.scss';
import Button from './Button';
import {useState, useEffect, useCallback} from 'react';
import {Form} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export const STATUS_CHOICES = {
  0: 'fully_met',
  1: 'partially_delivered',
  2: 'active_matches',
  3: 'no_matches',
  4: 'fully_claimed',
  5: 'partially_claimed',
};

const StatusFilter = (props) => {
  const {cancelClick, value, onRemoveUrl, queryParam} = props;
  const [checked, setChecked] = useState([]);
  const history = useHistory();
  const {t} = useTranslation('item-reports');

  // TODO: Verify that the user is an admin to use this component.
  const applyFilter = () => {
    const value = checked.join(';');
    const queryStringParams = new URLSearchParams(onRemoveUrl);
    queryStringParams.append(queryParam, value);
    cancelClick();
    history.push({search: queryStringParams.toString()});
  };

  const onChange = useCallback(
    (e) => {
      let updatedList = [...checked];
      if (e.target.checked) {
        updatedList.push(e.target.value);
      } else {
        updatedList.splice(checked.indexOf(e.target.value), 1);
      }
      setChecked(updatedList);
    },
    [checked],
  );

  useEffect(() => {
    if (value) {
      // Add validation and error handling.
      setChecked(value.split(';'));
    }
  }, [value]);

  return (
    <div className={sharedStyles.popupPanel}>
      <div className={sharedStyles.container}>
        <div className={sharedStyles.containerBorders}>
          <div className={sharedStyles.content}>
            <div className={sharedStyles.contentInner}>
              <Form className={sharedStyles.checkboxGroup}>
                {Object.entries(STATUS_CHOICES).map((item) => (
                  <Form.Check
                    key={'status' + item[0]}
                    label={t('filters.status.options.' + item[1])}
                    type="checkbox"
                    className={sharedStyles.checkbox}
                    onChange={onChange}
                    checked={checked.includes('' + item[0])}
                    value={item[0]}
                    id={'checkbox_' + item[0]}
                  />
                ))}
              </Form>
            </div>
          </div>
          <div className={sharedStyles.divider}>
            <hr className={sharedStyles.dividerLine} />
          </div>
          <div className={sharedStyles.footerContainer}>
            <div className={sharedStyles.footerContainerInner}>
              <Button
                onClick={applyFilter}
                active={true}
                disabled={checked.length === 0}
              >
                {t('filters.apply')}
              </Button>
              <Button onClick={cancelClick}>{t('filters.cancel')}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusFilter;
