import {createContext, useContext, useEffect, useMemo} from 'react';
import {Api} from '../api';
import {useSession} from '../../../contexts/SessionContext';

const ApiContext = createContext();

const ApiProvider = ({children}) => {
  const [session, sessionActions] = useSession();

  const api = useMemo(() => {
    return new Api(session.token);
  }, [session.token]);

  useEffect(() => {
    const fetchSession = async () => {
      console.info(
        `[NL] initializing ${session.token ? '(private)' : '(public)'}`,
      );

      try {
        const payload = await api.getSession();
        sessionActions.setSession(session.token, payload);
        if (
          !session.token ||
          (!payload.client_data.map_default_location?.location &&
            !payload.organization.location?.location)
        ) {
          const ip = await api.getIpLocation();
          sessionActions.setLocationByIp(ip);
        }
        sessionActions.init();
      } catch (e) {
        if (e.getCode() === 'authentication_failed') {
          sessionActions.unsetSession();
        } else {
          // display toast?
        }
      }
    };

    if (!session.loaded) {
      fetchSession();
    }
  }, [api, session.loaded, session.token, sessionActions]);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('`useApi` must be used within a `ApiProvider`');
  }
  return context;
};

export {ApiProvider, ApiContext, useApi};
