import React, {useCallback} from 'react';
import {useField, useFormikContext} from 'formik';

import {useTranslatedError} from '../../hooks/formik';
import Label from '../Form/Label';
import LocationSelectV2 from '../LocationSelectV2';
import ErrorMessage from '../Form/ErrorMessage';

const LocationAutocompleteField = (props) => {
  const [field, meta] = useField(props);
  const {setFieldValue} = useFormikContext();
  const error = useTranslatedError(meta.error);
  const {isFullWidth, label, notEditAddress, notDeleteAddress, isRequired} =
    props;

  const handleAddressesChange = useCallback(
    (addresses) => {
      setFieldValue(field.name, addresses);
    },
    [setFieldValue, field.name],
  );

  return (
    <React.Fragment>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <LocationSelectV2
        isFullWidth={isFullWidth}
        onAddressesChange={handleAddressesChange}
        notEditAddress={notEditAddress}
        notDeleteAddress={notDeleteAddress}
      />
      {error && meta.touched && (
        <ErrorMessage forceDisplay>{error}</ErrorMessage>
      )}
    </React.Fragment>
  );
};

export default LocationAutocompleteField;
