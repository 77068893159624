import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStores} from '../../contexts/StoresContext';
import {getUserDisplayName} from '../../utils/user';
import {breakpoints} from '../../constants';
import Responsive from '../Responsive';
import styles from './TopBar.module.scss';

const UserProfile = () => {
  const stores = useStores();
  const {user} = stores.app;

  if (!user) {
    return null;
  }

  return (
    <div className={styles.profileContainer}>
      <Responsive>
        <span className={styles.profileImage} min={breakpoints.lg}>
          <FontAwesomeIcon icon="user" color="white" />
        </span>
      </Responsive>
      <Responsive>
        <span className={styles.userName} min={breakpoints.lg}>
          {getUserDisplayName(user)}
        </span>
      </Responsive>
    </div>
  );
};

export default UserProfile;
