import React from 'react';
import {withFormik} from 'formik';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Form, Col} from 'react-bootstrap';
import Input from '../../components/Form/Input';
import Button from '../../components/Button';
import {useApi} from '../../contexts/ApiContext';
import NavigationLink from '../../components/NavigationLink';
import Title from '../../components/Title/Title';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import styles from './Login.module.scss';
import InformationToast from '../../components/InformationToast';

const LoginForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    t,
    status,
  } = props;
  return (
    <Form className={styles.mainForm} noValidate onSubmit={handleSubmit}>
      {status && status.error && (
        <InformationToast
          className={styles.toast}
          title={t('toast.title')}
          text={status.error}
        />
      )}
      <Title className={styles.formTitle}>{t('title')}</Title>
      <span className={styles.formSubTitle}>{t('log-in-subtitle')}</span>
      <div className={styles.fieldsContainer}>
        <Form.Group as={Col} sm={12} controlId="email">
          <Input
            type="text"
            placeholder={t('placeholder.email')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            isInvalid={!!errors.email && touched.email}
            error={errors.email}
            name="email"
          />
        </Form.Group>
        <Form.Group as={Col} sm={12} controlId="password">
          <Input
            type="password"
            placeholder={t('placeholder.password')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            isInvalid={!!errors.password && touched.password}
            error={errors.password}
            name="password"
          />
        </Form.Group>
        <NavigationLink className={styles.navLink} to="/forgot-password">
          {t('forgot-password')}
        </NavigationLink>
      </div>
      <div className={styles.footer}>
        <Button type="submit" disabled={props.isSubmitting}>
          {t('button')}
        </Button>
      </div>
    </Form>
  );
};

const EnhancedForm = withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  validate: (values, props) => {
    const errors = {};

    if (!values.email) {
      errors.email = props.t('error.field-required');
    }

    if (!values.password) {
      errors.password = props.t('error.field-required');
    }

    return errors;
  },
  handleSubmit: async (
    values,
    {props, setSubmitting, setErrors, setStatus},
  ) => {
    setStatus({
      approvalPending: false,
      loggingIn: true,
    });

    try {
      const response = await props.api.login(values.email, values.password);
      props.stores.app.login(response);

      if (props.stores.app.user) {
        props.stores.app.setRefreshing(true);
        window.location.replace(props.nextUrl ? props.nextUrl : '/');
      } else {
        setErrors({
          email: props.t('error.login'),
        });
      }
    } catch (e) {
      // Credentials are invalid
      if (e.non_field_errors || e.wrong_password) {
        setErrors({
          email: props.t('error.email-password-incorrect'),
          password: props.t('error.email-password-incorrect'),
        });
        if (
          e.wrong_password &&
          e.wrong_password.length === 1 &&
          e.wrong_password[0] === '1'
        ) {
          setStatus({error: props.t('toast.text-wrong-password')});
        }
      } else if (e.username || e.password) {
        setErrors({
          email: e.username.length ? e.username[0] : undefined,
          password: e.password.length ? e.password[0] : undefined,
        });
      } else if (e.approval_pending) {
        setStatus({
          approvalPending: true,
          error: props.t('toast.text-pending-approval'),
        });
      } else if (e.account_blocked) {
        setStatus({
          error: props.t('toast.text-account-blocked', {
            minutes: e.account_blocked[0],
          }),
        });
      } else if (e.account_disabled) {
        setStatus({error: props.t('toast.text-account-disabled')});
      } else if (e.email_verified_error) {
        props.setEmailVerified(values.email);
      } else {
        throw e;
      }
    }
  },
})(LoginForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const history = useHistory();
  const stores = useStores();
  const {t} = useTranslation('login');
  const api = useApi();
  return (
    <EnhancedForm
      api={api}
      history={history}
      t={t}
      stores={stores}
      {...props}
    />
  );
};

export default observer(FormWrapper);
