import React from 'react';
import {useTranslation} from 'react-i18next';
import {Card, Row, Col} from 'react-bootstrap';
import styled from 'styled-components';

import ReadOnlyField from '../../components/Fields/ReadOnlyField';

const StyledCard = styled(Card)`
  padding: 40px 30px;
  font-size: 14px;
  margin-bottom: 20px;
`;

const StyledCol = styled(Col)`
  margin: 10px 0;
`;

const UserInfoCard = (props) => {
  const {t} = useTranslation('user');

  return (
    <StyledCard data-testid="userDetailsCard">
      <Row>
        <StyledCol lg={6}>
          <ReadOnlyField
            label={t('form-fields.profile.role-in-organization')}
            value={
              props.user.organization_role ||
              t('form-fields.profile.empty-value')
            }
          />
        </StyledCol>
        <StyledCol lg={6}>
          <ReadOnlyField
            label={t('form-fields.profile.time-zone')}
            value={props.user.timezone || t('form-fields.profile.empty-value')}
            withoutTextStyle
          />
        </StyledCol>
        <StyledCol lg={6}>
          <ReadOnlyField
            label={t('form-fields.profile.phone')}
            value={
              props.user.phone_number || t('form-fields.profile.empty-value')
            }
          />
        </StyledCol>
        <StyledCol lg={6}>
          <ReadOnlyField
            label={t('form-fields.profile.platform-role')}
            value={t(`form-fields.profile.${props.user.groups[0]}`)}
          />
        </StyledCol>
      </Row>
    </StyledCard>
  );
};

export default UserInfoCard;
