import {useCallback, useState} from 'react';

const useLocalStorageFlag = (key, defaultValue) => {
  const localStorage = window.localStorage;
  const getValue = useCallback(
    () => localStorage.getItem(key) || defaultValue,
    [localStorage, key, defaultValue],
  );
  const [value, setStateValue] = useState(getValue());
  const setValue = useCallback(
    (value) => {
      localStorage.setItem(key, value);
      setStateValue(value);
    },
    [key, localStorage],
  );

  return [setValue, value === true || value === 'true'];
};

export {useLocalStorageFlag};
