import {useEffect} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {usePrevious} from '../../hooks/utils';

const ScrollOnPageChange = (props) => {
  const match = useRouteMatch();
  const previousMatch = usePrevious(match.path);

  useEffect(() => {
    if (match.isExact && previousMatch !== match.path) {
      window.scrollTo(0, 0);
    }
  }, [match.isExact, match.path, match.params, previousMatch]);

  return props.children;
};

export default ScrollOnPageChange;
