import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {mediaQueries} from '../../constants';
import Subtitle from '../../components/Subtitle';
import Button from '../../components/Button';

const StyledReportHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
  ${mediaQueries.lg} {
    flex-direction: column;
  }
`;

const StyledSubtitle = styled(Subtitle)`
  margin-top: 0;
  margin-bottom: 0;
  ${mediaQueries.lg} {
    margin-bottom: 10px;
  }
`;

const ReportHeader = (props) => {
  const {onButtonClick, subtitle} = props;
  const {t} = useTranslation('reports');

  return (
    <StyledReportHeader>
      <StyledSubtitle>{subtitle}</StyledSubtitle>
      {onButtonClick && (
        <Button onClick={onButtonClick} size="sm" variant="outline-primary">
          {t('buttons.export-csv')}
        </Button>
      )}
    </StyledReportHeader>
  );
};

export default ReportHeader;
