import React from 'react';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import {Col} from 'react-bootstrap';
import Label from '../Form/Label';
import Form from '../ItemForm/Form';
import {LanguageField} from '../Fields';
import Footer from '../ItemForm/Footer';
import Button from '../Button';
import {useApi} from '../../contexts/ApiContext';
import {InputField} from '../Fields';
import {useStores} from '../../contexts/StoresContext';

const InviteOrgAdminForm = (props) => {
  const {t} = useTranslation('user');
  const {handleSubmit} = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>
            {t('form-fields.org-admin.organization-name')}
          </Label>
          <InputField
            type="text"
            name="organizationName"
            placeholder={t('placeholders.org-admin.organization-name')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('form-fields.org-admin.name')}</Label>
          <InputField
            type="text"
            name="firstName"
            placeholder={t('placeholders.org-admin.name')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('form-fields.org-admin.last-name')}</Label>
          <InputField
            type="text"
            name="lastName"
            placeholder={t('placeholders.org-admin.last-name')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('form-fields.org-admin.email')}</Label>
          <InputField
            type="text"
            name="email"
            placeholder={t('placeholders.org-admin.email')}
          />
        </Form.Group>
      </Form.Row>
      <LanguageField label="Language" name="languageId" />
      <Footer>
        <Button
          type="submit"
          variant="primary"
          className="text-uppercase"
          responsiveFullWidth
        >
          {t('buttons.send-invite')}
        </Button>
      </Footer>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'InviteOrgAdminForm',
  mapPropsToValues: (props) => {
    const {user} = props.stores.app;
    return {
      organizationName: '',
      firstName: '',
      lastName: '',
      email: '',
      languageId: user.language.id || null,
    };
  },
  validationSchema: yup.object().shape({
    organizationName: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    languageId: yup.number().required(),
  }),
  handleSubmit: async (values, {props, setSubmitting, setErrors}) => {
    try {
      await props.api.createInvitation({
        ...values,
        invitationType: 'org_admin',
      });
      setSubmitting(false);
      props.onSave();
    } catch (error) {
      if (error.organization_name || error.email) {
        const {toast} = props.stores.app;
        toast.toasts.forEach(
          ({id, type}) =>
            type !== 'testing' && props.stores.app.removeToast(id),
        );
        setErrors({
          organizationName: error.organization_name
            ? error.organization_name[0]
            : undefined,
          email: error.email ? error.email[0] : undefined,
        });
      }
      setSubmitting(false);
    }
  },
})(InviteOrgAdminForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  const api = useApi();
  return <EnhancedForm api={api} stores={stores} {...props} />;
};

export default observer(FormWrapper);
