import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons';

import Button from '../Button';
import {ReactComponent as CircleExclamation} from '../../assets/icons/exclamation_icon.svg';
import ErrorMessage from '../Form/ErrorMessage';
import Typeahead from '../Form/Typeahead';
import {useApi} from '../../contexts/ApiContext';
import styles from './Dialog.module.scss';

const Dialog = (props) => {
  const {
    header,
    title,
    editItemTitle,
    message,
    boldMessage,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
    noIcon,
    onClose,
    decline,
    declineReasonTitle,
    declineReasonPlaceholder,
    params,
    newItemName,
    editModal,
    textErrorValue,
    checkIcon = false,
  } = props;
  const api = useApi();
  const [textError, setTextError] = useState('');
  const [textValue, setTextValue] = useState('');
  const [singleSelections, setSingleSelections] = useState([]);
  const [availableTemplates, setAvailableTemplates] = useState([]);

  useEffect(() => {
    if (editModal) setSingleSelections([{value: newItemName}]);
  }, [editModal, newItemName]);

  const onTemplateSearch = async (value) => {
    setTextValue(value);
    const response = await api.getItemTemplates(value, [params.kind]);
    const matchTemplates = response.results.map((template) => {
      return {
        id: template.id,
        label: template.name,
        value: template.name,
        kind: template.kind,
      };
    });
    matchTemplates.unshift({
      id: null,
      label: value,
      value: value,
      kind: params.kind,
    });
    setAvailableTemplates(matchTemplates);
  };

  const handleOnReasonChange = (e) => {
    setTextValue(e.target.value);
  };

  const handleOnChangeTypeAhead = (value) => {
    setSingleSelections(value);
    setTextValue(value.length ? value[0].value : null);
  };

  const onConfirmClick = () => {
    if (textErrorValue) {
      if (!textValue) {
        setTextError(textErrorValue);
      } else {
        onConfirm(textValue);
      }
    } else {
      onConfirm();
    }
  };

  return (
    <Modal
      className={styles.dialog}
      show
      dialogAs={Modal.Dialog}
      backdrop="static"
    >
      {header && (
        <Modal.Header>
          {header}
          {onClose && (
            <FontAwesomeIcon
              className={styles.closeIcon}
              icon="times"
              size="lg"
              onClick={onClose}
            />
          )}
        </Modal.Header>
      )}
      <Modal.Body>
        {!noIcon && (
          <div>
            {checkIcon ? (
              <FontAwesomeIcon
                className={styles.checkIcon}
                icon={faCheckCircle}
              />
            ) : (
              <CircleExclamation className={styles.circleExclamation} />
            )}
          </div>
        )}
        <h2 className={styles.title}>{title}</h2>
        {editItemTitle && <h2 className={styles.yorkTitle}>{editItemTitle}</h2>}
        {message && <p className={styles.text}>{message}</p>}
        {editModal && (
          <React.Fragment>
            <p className={styles.boldText}>{boldMessage}</p>
            <div className={styles.typeaheadContainer}>
              <Typeahead
                id="editItemName"
                labelKey="value"
                onSearch={onTemplateSearch}
                onChange={handleOnChangeTypeAhead}
                options={availableTemplates}
                selected={singleSelections}
                onEnterConfirm={onConfirmClick}
                allowNew={true}
                selectPartialMatchOnEnter
                newSelectionPrefix=""
              />
            </div>
            <div className={styles.errorMessageContainer}>
              {textError && (
                <ErrorMessage forceDisplay>{textError}</ErrorMessage>
              )}
            </div>
          </React.Fragment>
        )}
        {decline && declineReasonTitle && (
          <React.Fragment>
            <h3 className={styles.label}>{declineReasonTitle}</h3>
            <textarea
              name="declineReason"
              placeholder={
                declineReasonPlaceholder ? declineReasonPlaceholder : ''
              }
              onChange={handleOnReasonChange}
              rows={6}
              className={styles.reasonTextArea}
              data-hj-allow
            />
            {textError && <ErrorMessage forceDisplay>{textError}</ErrorMessage>}
          </React.Fragment>
        )}
      </Modal.Body>
      <div className={styles.footer}>
        {cancelText && (
          <Button
            className={styles.dialogButton}
            variant="outline-primary"
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        )}
        <Button
          className={styles.dialogButton}
          variant="primary"
          onClick={onConfirmClick}
        >
          {confirmText}
        </Button>
      </div>
    </Modal>
  );
};

export default Dialog;
