import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation, Trans} from 'react-i18next';

import {useClaimedItems} from '../../../hooks/items';
import {usePageStore} from '../../../contexts/PageContext';

import SectionTitle from '../SectionTitle';
import Placeholder from '../Placeholder';
import ItemsList from '../../../components/ItemsList';
import Link from '../../../components/Link';
import {CLAIMED_ITEM_CONFIRM, ITEM_DETAIL} from '../../../urls';
import {usePaginator} from '../../../hooks/paginator';
import {usePanelStore} from '../../../contexts/PanelContext';

const ClaimedItems = (props) => {
  const {side} = props;
  const history = useHistory();
  const {t} = useTranslation('manage');
  const [, pageActions] = usePageStore();
  const {items, isLoading, fetchItems} = useClaimedItems(side);
  const {currentPage} = usePaginator();
  const [, panelActions] = usePanelStore();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.refresh) {
      fetchItems(true);
    }
  }, [location, fetchItems]);

  const handleItemDetails = (item) => {
    panelActions.setPanelMount(true);
    history.push(ITEM_DETAIL.replace(':itemId', item.id));
    pageActions.setBreadcrumbs({key: `manage-edit-${item.side}`});
  };

  const handleConfirmationClick = async (item) => {
    panelActions.setPanelMount(true);
    history.push(
      `${CLAIMED_ITEM_CONFIRM.replace(':itemId', item.id)}?page=${currentPage}`,
    );
    pageActions.setBreadcrumbs({key: `confirm-${item.side}`});
  };

  const menuItem = [
    {
      label: t('menu.view'),
      onClick: handleItemDetails,
    },
    {
      label: (item) =>
        item.side === 'offer' &&
        (item.kind === 'supply' || item.kind === 'service')
          ? t('needs.menu.confirm-delivery')
          : null,
      onClick: handleConfirmationClick,
    },
  ];

  return (
    <React.Fragment>
      {items.length !== 0 && (
        <SectionTitle>{t(`${side}s.claimed`)}</SectionTitle>
      )}
      {items.length === 0 && isLoading && (
        <Placeholder>
          <Trans i18nKey={`manage:${side}s.placeholder_claimed`}>
            text <br />
            text <Link to="/search">text</Link> text
          </Trans>
        </Placeholder>
      )}
      <ItemsList
        isLoading={isLoading}
        items={items}
        menuItems={menuItem}
        hideErrors
        hideProgressIndicator
        showPendingDelivery={side === 'offer'}
        hideLogo
      />
    </React.Fragment>
  );
};

export default ClaimedItems;
