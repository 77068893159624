import styles from './Filter.module.scss';
import CloseIcon from '../../assets/icons/icon-close.svg';
import {useHistory} from 'react-router-dom';
import FilterControl from './FilterControl';
import {useTranslation} from 'react-i18next';

const MAX_LENGTH = 50;

const Filter = (props) => {
  const {selected, filter, selectFilter, onRemoveUrl, lookupData} = props;
  const {t} = useTranslation('item-reports');
  const {prefix, middle, suffix, is_positive} = filter.display(t, lookupData);
  const suffixTrimmed =
    suffix.length > MAX_LENGTH
      ? suffix.substr(0, MAX_LENGTH - 3) + '...'
      : suffix;
  const history = useHistory();
  const handleClick = () => {
    selectFilter(selected ? '' : filter.key);
  };
  return (
    <div className={styles.wrapper} name="filterButton">
      <div className={styles.container} onClick={handleClick}>
        <div
          className={
            styles.containerInner + (selected ? ' ' + styles.selected : '')
          }
        >
          <div
            className={styles.textContainer}
            dangerouslySetInnerHTML={{
              __html: t('filters.template', {
                prefix: prefix,
                middle: middle,
                suffix: suffixTrimmed,
                middle_class_name: is_positive
                  ? 'middleTextPositive'
                  : 'middleTextNegative',
              }),
            }}
          ></div>
          <img
            src={CloseIcon}
            alt="Close icon"
            className={styles.icon}
            onClick={(e) => {
              selectFilter('');
              e.stopPropagation();
              history.push({search: onRemoveUrl});
            }}
          />
        </div>
      </div>
      {selected && (
        <FilterControl
          lookupData={lookupData}
          type={filter.constructor.type}
          value={filter.value}
          cancelClick={() => selectFilter('')}
          onRemoveUrl={onRemoveUrl}
        />
      )}
    </div>
  );
};

export default Filter;
