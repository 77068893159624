import React from 'react';
import {useTranslation} from 'react-i18next';

import Dialog from '../Dialog';
import {useLocaleNumber} from '../../hooks/number';

const SessionExpirationModal = (props) => {
  const {handleOnConfirm, countdown} = props;
  const localeNumber = useLocaleNumber();
  const {t} = useTranslation(['common']);

  return (
    <Dialog
      header={t('expiration-timer.header')}
      message={t('expiration-timer.message', {
        countDown: localeNumber(countdown),
      })}
      onConfirm={handleOnConfirm}
      confirmText={t('expiration-timer.button')}
      noIcon
    />
  );
};

export default SessionExpirationModal;
