import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import Item from '../Item';
import Spinner from '../Spinner';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 150px 0;
`;

const StyledItem = styled(Item)`
  margin: 0 15px 15px 15px;
`;

const ItemsList = (props) => {
  const {t} = useTranslation('search');
  if (props.isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  const renderItem = (item, index) => {
    const onItemClick = props.onItemClick
      ? () => props.onItemClick(item)
      : undefined;
    const onButtonClick = () => {
      if (props.onButtonClick) props.onButtonClick(item);
    };

    return (
      <StyledItem
        item={item}
        key={index}
        issueName={props.issueName}
        buttonText={t('cards.buttons.learn-more')}
        location={props.location}
        onClick={onItemClick}
        onButtonClick={onButtonClick}
        menuItems={props.menuItems}
        isReduced
      />
    );
  };

  return <Wrapper>{props.items.map(renderItem)}</Wrapper>;
};

export default ItemsList;
