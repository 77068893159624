import ReactDOM from 'react-dom';
import PanelSearch from './PanelSearch';

const root = document.getElementById('root-panel-search');

const PanelSearchPortal = () => {
  if (!root) {
    return null;
  }
  return ReactDOM.createPortal(<PanelSearch />, root);
};

export default PanelSearchPortal;
