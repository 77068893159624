import React, {useContext, useEffect, useRef} from 'react';
import {Form} from 'react-bootstrap';
import {STATIC_FILE_PAGE} from '../../../../urls';
import FormContext from '../../../Form/FormContext';
import {useStores} from '../../../../contexts/StoresContext';
import ChoiceOther from '../../Helper/ChoiceOther';
import NavigationLink from '../../../NavigationLink';
import styles from './ChoiceMultipleControl.module.scss';

const ChoiceMultipleControl = (props) => {
  const {choices, value, onChange, allowOther, name} = props;
  const inputRef = useRef(null);
  const stores = useStores();
  const {setFieldRef} = useContext(FormContext);

  useEffect(() => {
    setFieldRef(name, inputRef);
  }, [name, setFieldRef]);

  const render = (choice, index) => {
    const isChecked = value.indexOf(choice.id) !== -1;
    let label = choice.label;

    if (choice.options) {
      if (choice.options.url) {
        label = (
          <a
            href={choice.options.url}
            target="_blank"
            rel="noreferrer"
            className={styles.customLink}>
            {choice.label}
          </a>
        );
      } else if (choice.options.file) {
        const fileName = `${choice.options.file.split('.')[0]}?lang=${
          stores.app.language
        }`;
        label = (
          <NavigationLink
            to={STATIC_FILE_PAGE.replace(':fileName', fileName)}
            target="_blank"
            rel="noreferrer"
            className={styles.customNavLink}>
            {choice.label}
          </NavigationLink>
        );
      }
    }

    return (
      <Form.Check
        custom
        id={choice.id}
        key={`choice-${choice.id}`}
        label={label}
        checked={isChecked}
        value={choice.id}
        onChange={onChange}
        ref={index === 0 ? inputRef : undefined}
        className={styles.customCheck}
      />
    );
  };

  return (
    <Form.Group className={styles.choiceMultipleControl}>
      {choices.map(render)}
      <ChoiceOther
        name={name}
        as={(props) => <Form.Check className={styles.customCheck} {...props} />}
        allowOther={allowOther}
        onChange={onChange}
      />
    </Form.Group>
  );
};

export default ChoiceMultipleControl;
