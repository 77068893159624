import React, {useState, useEffect} from 'react';
import {InputGroup, FormControl} from 'react-bootstrap';
import {getHeaders} from './utils';

const MediaVideoWidget = (props) => {
  const [value, setValue] = useState(props.value);
  const [videos, setVideos] = useState(JSON.parse(props.value));
  const [loaded, setLoaded] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    setValue(JSON.stringify(videos));
  }, [videos]);

  useEffect(() => {
    setLoaded(true);
  }, [setLoaded]);

  const getYoutubeVideoId = (url) => {
    let id;
    url = url
      .replace(/(>|<)/gi, '')
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      id = url[2].split(/[^0-9a-z_-]/i)[0];
    } else {
      id = null;
    }
    return id;
  };

  const getYoutubeThumbnail = (url) => {
    const videoId = getYoutubeVideoId(url);
    return `http://img.youtube.com/vi/${videoId}/0.jpg`;
  };

  const validateVideo = (videoUrl) => {
    const videoId = getYoutubeVideoId(videoUrl);
    if (videoId) {
      return {
        url: videoUrl,
      };
    } else {
      setVideoUrl((prevState) => {
        return '';
      });
    }
  };

  const renderVideos = () => {
    return videos.map((video, index) => {
      const removeButton = (
        <button
          onClick={(event) => {
            event.preventDefault();
            setVideos((prevState) => {
              const videos = [...prevState];
              videos.splice(index, 1);
              return videos;
            });
          }}
          className="btn btn-danger btn-sm"
        >
          Remove
        </button>
      );

      const thumbnail = getYoutubeThumbnail(video.url);
      return (
        <div key={index} className="card">
          <img alt={thumbnail} src={thumbnail} className="card-img-top" />
          <div className="card-footer">{removeButton}</div>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <label>{props.label}</label>
      <InputGroup>
        <FormControl
          placeholder="Enter a video url"
          value={videoUrl}
          onChange={(event) => {
            const value = event.target.value;
            setVideoUrl((prevState) => value);
          }}
        />
        <InputGroup.Append>
          <button
            className="btn input-group-text"
            onClick={async (event) => {
              event.preventDefault();

              const validated = validateVideo(videoUrl);
              if (!validated) {
                return alert('Empty or wrong video url');
              }

              const response = await fetch(props.api, {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(validated),
              });
              const result = await response.json();

              setVideos((prevState) => {
                return [...prevState, {id: result.id, url: result.url}];
              });

              setVideoUrl((prevState) => {
                return '';
              });
            }}
          >
            Add
          </button>
        </InputGroup.Append>
      </InputGroup>
      <div className="card-columns">{renderVideos()}</div>
      {loaded && !videos.length ? (
        <div className="alert alert-info">No videos found</div>
      ) : null}
      <textarea
        style={{display: 'none'}}
        onChange={() => {}}
        className="form-control"
        value={value}
        name={props.name}
      />
    </React.Fragment>
  );
};

export default MediaVideoWidget;
