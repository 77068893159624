import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import {useApi} from '../../contexts/ApiContext';
import Carousel from '../../components/Carousel';
import genericFocusArea from '../../assets/generic_focus_area.png';

const CAROUSEL_HEIGHT = 100;
const SPACE_BETWEEN_ITEMS = 5;

const StyledCard = styled.div`
  color: white;
  text-align: center;
  font-weight: bold;
  flex-grow: 0;
  height: ${CAROUSEL_HEIGHT}px;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  width: 100px;
  cursor: pointer;
  ${(props) => (props.selected ? '' : 'opacity: 0.3; &:hover{opacity: 1;}')}
`;

const CategoryList = (props) => {
  const [categories, setCategories] = useState([]);
  const numberOfCards = props.isMobile ? 3 : 10;
  const searchByType = !!props.type;
  const api = useApi();

  useEffect(() => {
    const fetchIssueCategories = async () => {
      const params = {};
      if (searchByType) {
        params.type = props.type;
      }
      const response = await api.getIssueCategories(params);
      setCategories(response.results);
    };

    fetchIssueCategories();
  }, [props.type, searchByType, api]);

  const renderCategories = (category) => {
    const selected =
      props.selectedCategories.length === 0 ||
      props.selectedCategories.some((c) => category.id === c.id);
    const hasImage = !!category.image;
    const backgroundImage = hasImage ? category.image : genericFocusArea;

    return (
      <StyledCard
        backgroundImage={backgroundImage}
        key={category.id}
        selected={selected}
        onClick={() => props.onCategorySelect(category)}
        data-category-id={category.id}
        data-category-name={category.name}
      >
        {!hasImage && category.name}
      </StyledCard>
    );
  };

  return (
    <Carousel
      numberOfCards={numberOfCards}
      carouselHeight={CAROUSEL_HEIGHT}
      spaceBetweenItems={SPACE_BETWEEN_ITEMS}
    >
      {categories.map(renderCategories)}
    </Carousel>
  );
};

export default CategoryList;
