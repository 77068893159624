import React from 'react';
import styled from 'styled-components';
import {Title} from '../../../components/OnboardingCarousel';
import {useTranslation} from 'react-i18next';
import Responsive from '../../../components/Responsive';
import {breakpoints, mediaQueries} from '../../../constants';
import Button from '../../../components/Button/Button';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 0.33;
  margin-right: 30px;
  padding-bottom: 32px;

  ${mediaQueries.lg} {
    flex: 1;
    width: 100%;
    margin-right: 0;
    padding-bottom: 10px;
  }
`;

const Text = styled.p`
  padding-right: 100px;

  ${mediaQueries.lg} {
    padding-right: 0;
  }
`;

const StyledTitle = styled(Title)`
  ${mediaQueries.lg} {
    margin-top: 35px;
  }
`;

const Controls = styled.div`
  display: flex;
`;

const Control = styled(Button)`
  &.btn {
    padding: 7px 30px;
  }

  ${mediaQueries.lg} {
    &.btn {
      ${(props) =>
        props.variant !== 'primary' ? 'background-color: white;' : ''}
    }
  }
`;

const NextButton = styled(Control)`
  margin-left: auto;

  ${mediaQueries.lg} {
    margin-left: 10px;
  }
`;

const Content = (props) => {
  const {t} = useTranslation('onboarding');
  const nextVariant = props.onNextText ? 'primary' : 'outline-primary';

  return (
    <Wrapper>
      <Responsive>
        <div max={breakpoints.lg} />
      </Responsive>
      <div>
        <StyledTitle>{props.title}</StyledTitle>
        <Text>{props.children}</Text>
      </div>
      <Controls>
        {props.onPrevious && (
          <Control variant="outline-primary" onClick={props.onPrevious}>
            {t('previous')}
          </Control>
        )}
        <NextButton variant={nextVariant} onClick={props.onNext}>
          {props.onNextText || t('next')}
        </NextButton>
      </Controls>
    </Wrapper>
  );
};

export default Content;
