// 00:00 to 24:00
const VALID_24_HOURS_FORMAT = /^([01]\d|2[0-3]):([0-5]\d)$/;

function getTimeZoneOffset() {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? '+' : '-') +
    ('00' + Math.floor(o / 60)).slice(-2) +
    ':' +
    ('00' + (o % 60)).slice(-2)
  );
}

function getTimeZoneName() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function getCurrentTimeZone() {
  return `GMT${getTimeZoneOffset()} ${getTimeZoneName()}`;
}

export {getCurrentTimeZone, VALID_24_HOURS_FORMAT};
