import React from 'react';
import HSBar from 'react-horizontal-stacked-bar-chart';
import ChartLegend from './ChartLegend';
import styles from './Visualizations.module.scss';
import theme from '../../../theme';
import {useTranslation} from 'react-i18next';
import {PieChart, Pie, Label} from 'recharts';

const ItemsByFulfillmentAndTypeCard = (props) => {
  const {
    itemsByFulfillmentAndType,
    itemsByFulfillment,
    headerText,
    side,
    isEmpty,
  } = props;
  const {t} = useTranslation('item-reports');

  const getItemsByFulfillmentAndTypeCardData = () => {
    if (isEmpty) {
      return [{name: 'emptyData', value: 10, color: theme.hitGray}];
    }

    let data = [];
    if (
      itemsByFulfillmentAndType['metSupply'] &&
      itemsByFulfillmentAndType['metSupply']['itemCount'] !== 0
    ) {
      data.push({
        value: itemsByFulfillmentAndType['metSupply']['itemCount'],
        color: itemsByFulfillmentAndType['metSupply']['color'],
      });
    }
    if (
      itemsByFulfillmentAndType['postedSupply'] &&
      itemsByFulfillmentAndType['postedSupply']['itemCount'] !== 0
    ) {
      data.push({
        value: itemsByFulfillmentAndType['postedSupply']['itemCount'],
        color: itemsByFulfillmentAndType['postedSupply']['color'],
      });
    }
    if (
      itemsByFulfillmentAndType['metService'] &&
      itemsByFulfillmentAndType['metService']['itemCount'] !== 0
    ) {
      data.push({
        value: itemsByFulfillmentAndType['metService']['itemCount'],
        color: itemsByFulfillmentAndType['metService']['color'],
      });
    }
    if (
      itemsByFulfillmentAndType['postedService'] &&
      itemsByFulfillmentAndType['postedService']['itemCount'] !== 0
    ) {
      data.push({
        value: itemsByFulfillmentAndType['postedService']['itemCount'],
        color: itemsByFulfillmentAndType['postedService']['color'],
      });
    }
    if (side === 'offer') {
      if (
        itemsByFulfillmentAndType['metInformation'] &&
        itemsByFulfillmentAndType['metInformation']['itemCount'] !== 0
      ) {
        data.push({
          value: itemsByFulfillmentAndType['metInformation']['itemCount'],
          color: itemsByFulfillmentAndType['metInformation']['color'],
        });
      }
      if (
        itemsByFulfillmentAndType['postedInformation'] &&
        itemsByFulfillmentAndType['postedInformation']['itemCount'] !== 0
      ) {
        data.push({
          value: itemsByFulfillmentAndType['postedInformation']['itemCount'],
          color: itemsByFulfillmentAndType['postedInformation']['color'],
        });
      }
    }
    return data;
  };

  const getDataForPieChart = () => {
    if (isEmpty) {
      return [{name: 'emptyData', value: 10, fill: theme.hitGray}];
    }

    let pieChartData = [];
    for (const [key, value] of Object.entries(itemsByFulfillment)) {
      pieChartData.push({
        name: key,
        value: value['itemCount'],
        fill: value['color'],
      });
    }
    return pieChartData;
  };

  const getTotalCountForPieChart = () => {
    if (isEmpty) {
      return '-';
    }
    let totalCount = 0;
    for (const [key] of Object.entries(itemsByFulfillment)) {
      totalCount += itemsByFulfillment[key]['itemCount'];
    }
    return totalCount;
  };

  const getItemsFilteredByType = (typeName) => {
    return Object.keys(itemsByFulfillmentAndType)
      .filter((key) => key.includes(typeName))
      .reduce((cur, key) => {
        return Object.assign(cur, {[key]: itemsByFulfillmentAndType[key]});
      }, {});
  };

  return (
    <div className={styles.visualizationCardOuterContainer}>
      <div className={styles.visualizationCardDividedContainer}>
        <div
          style={{
            background:
              isEmpty || side === 'need' ? theme.aquaHaze : theme.vistaWhite,
          }}
          className={styles.visualizationCardLeftInnerContainer}
        >
          <div
            style={{
              color: isEmpty
                ? theme.hitGray
                : side === 'need'
                ? theme.tealBlue
                : theme.crusta,
            }}
            className={styles.visualizationCardHeader}
          >
            {side === 'need'
              ? t('visualizations.header.all-needs')
              : t('visualizations.header.all-offers')}
          </div>
          <div className={styles.pieChartContainer}>
            <PieChart width={180} height={180}>
              <Pie
                outerRadius="100%"
                innerRadius="70%"
                data={getDataForPieChart()}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
              >
                <Label
                  value={getTotalCountForPieChart()}
                  fontSize="40px"
                  position="center"
                  fill={
                    isEmpty
                      ? theme.hitGray
                      : side === 'need'
                      ? theme.astronautBlue
                      : theme.crusta
                  }
                />
              </Pie>
            </PieChart>
          </div>
          <ChartLegend
            chartData={itemsByFulfillment}
            isVertical={true}
            isEmpty={isEmpty}
          />
        </div>
        <div className={styles.visualizationVerticalDivider}></div>
        <div className={styles.visualizationCardRightInnerContainer}>
          <div
            style={{
              color: isEmpty
                ? theme.hitGray
                : side === 'need'
                ? theme.astronautBlue
                : theme.crusta,
            }}
            className={styles.visualizationCardHeader}
          >
            {headerText}
          </div>
          <div className={styles.barChartContainer}>
            {itemsByFulfillmentAndType && (
              <HSBar
                id={styles.barChart}
                height={25}
                data={getItemsByFulfillmentAndTypeCardData()}
              />
            )}
          </div>
          <ChartLegend
            headerTitle={t('visualizations.type.supply')}
            headerColor={side === 'need' ? theme.tealBlue : theme.korma}
            chartData={getItemsFilteredByType('Supply')}
            isEmpty={isEmpty}
          />
          <div className={styles.chartLegendDivider}></div>
          <ChartLegend
            headerTitle={t('visualizations.type.services')}
            headerColor={side === 'need' ? theme.blueChill : theme.meteor}
            chartData={getItemsFilteredByType('Service')}
            isEmpty={isEmpty}
          />
          {side === 'offer' && (
            <div>
              <div className={styles.chartLegendDivider}></div>
              <ChartLegend
                headerTitle={t('visualizations.type.information')}
                headerColor={
                  side === 'need' ? theme.cerulean : theme.neonCarrot
                }
                chartData={getItemsFilteredByType('Information')}
                isEmpty={isEmpty}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemsByFulfillmentAndTypeCard;
