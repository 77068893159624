import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react';

import {Typeahead} from 'react-bootstrap-typeahead';
import {useStores} from '../contexts/StoresContext';

const TypeaheadCategoryWidget = (props) => {
  const [value, setValue] = useState(JSON.parse(props.value || '[]'));

  const stores = useStores();
  const [categories, setCategories] = useState(value);

  useEffect(() => {
    setValue(JSON.stringify(categories));
  }, [categories]);

  return (
    <React.Fragment>
      <label>{props.label}</label>
      {stores.backoffice.categories.length > 0 && (
        <Typeahead
          labelKey="name"
          placeholder="Search issue categories by name..."
          id={`typeahead-${props.id}`}
          options={stores.backoffice.categories}
          multiple
          allowNew={false}
          onChange={setCategories}
          selected={categories}
        />
      )}
      <textarea
        style={{display: 'none'}}
        value={value}
        id={`textarea-${props.id}`}
        name={props.name}
        readOnly
      />
    </React.Fragment>
  );
};

export default observer(TypeaheadCategoryWidget);
