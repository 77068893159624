import styles from './Button.module.scss';

const Button = (props) => {
  const {children, active, disabled, onClick} = props;
  return (
    <div
      className={
        styles.buttonContainer +
        (active ? ' ' + styles.active : '') +
        (disabled ? ' ' + styles.disabled : '')
      }
      onClick={disabled ? () => undefined : onClick}
    >
      <div className={styles.buttonContents}>
        <span className={styles.text}>{children}</span>
      </div>
    </div>
  );
};

export default Button;
