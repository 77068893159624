import React from 'react';
import styled from 'styled-components';
import {Button as BootstrapButton} from 'react-bootstrap';
import {mediaQueries} from '../../constants';

const Button = styled(({responsiveFullWidth, ...rest}) => (
  <BootstrapButton {...rest} />
))`
  ${mediaQueries.lg} {
    &.btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      ${(props) =>
        props.responsiveFullWidth
          ? `
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      `
          : ''};
    }
  }
`;

export default Button;
