import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

const Label = styled.h3`
  font-size: 1rem;
  font-weight: bold;
`;

const InformationField = (props) => {
  const {label, description} = props;
  const {t} = useTranslation('member-page');

  return (
    <React.Fragment>
      {label && <Label>{label}</Label>}
      <p>{description || t('no-information')}</p>
    </React.Fragment>
  );
};

export default InformationField;
