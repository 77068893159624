import React from 'react';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons';
import Button from '../Button';
import styles from './ThankYouModal.module.scss';

const ThankYouModal = (props) => {
  const {t} = useTranslation('thank-you');
  const {onPrimaryClick, onSecondaryClick, header} = props;
  const headerTitle = header ? header : t('labels.header');

  return (
    <Modal
      show
      dialogAs={Modal.Dialog}
      backdrop="static"
      className={styles.customDialog}>
      <Modal.Header className={styles.header}>{headerTitle}</Modal.Header>
      <Modal.Body className={styles.body}>
        <FontAwesomeIcon icon={faCheckCircle} className={styles.checkIcon} />
        <h2 className={styles.title}>{props.titleMessage}</h2>
        <div className={styles.content}>{props.children}</div>
        <div className={styles.footer}>
          {onPrimaryClick && (
            <Button
              variant="outline-primary"
              onClick={onPrimaryClick}
              className={styles.primaryButton}
              responsiveFullWidth>
              {props.primaryButtonText}
            </Button>
          )}
          {onSecondaryClick && (
            <Button
              variant="outline-primary"
              onClick={onSecondaryClick}
              responsiveFullWidth>
              {props.secondaryButtonText}
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(ThankYouModal);
