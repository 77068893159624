import React, {useContext} from 'react';
import FiltersContext from '../FiltersProvider/FiltersContext';
import Pill from '../Pills/Pill';
import styles from './ItemFilters.module.scss';

const ItemFilters = ({itemFilters, className}) => {
  const {filters, initialized, removeFilter, removeRelatedFilters} =
    useContext(FiltersContext);

  if (!initialized) return null;

  const renderPills = (item, category) => {
    const onFilterRemove = () => {
      if (itemFilters[category].partialMatchValue) {
        removeFilter(itemFilters[category].partialMatchValue, item);
      }

      if (itemFilters[category].removeRelated) {
        const removeRelated = itemFilters[category].removeRelated;
        removeRelatedFilters(
          removeRelated.relatedFilters,
          item[removeRelated.commonIdKey],
          removeRelated.commonIdKey,
        );
      }

      removeFilter(category, item);
    };

    return (
      <Pill key={item.value} onRemove={onFilterRemove}>
        {item.label}
      </Pill>
    );
  };

  const pills = Object.values(itemFilters).map((filterCategory, index) => {
    let appliedFilters = filters[filterCategory.value];

    if (filterCategory.partialMatchValue) {
      appliedFilters = appliedFilters.concat(
        filters[filterCategory.partialMatchValue],
      );
    }

    const renderPillWithCategory = (item) =>
      renderPills(item, filterCategory.value);

    return (
      <React.Fragment key={filterCategory.value}>
        {appliedFilters.length > 0
          ? appliedFilters.map(renderPillWithCategory)
          : null}
      </React.Fragment>
    );
  });

  return (
    <div
      className={
        className
          ? `${styles.pillsContainer} ${className}`
          : `${styles.pillsContainer}`
      }>
      {pills}
    </div>
  );
};

export default ItemFilters;
