import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import {useApi} from '../../contexts/ApiContext';
import {useItem} from '../../hooks/items';

import NeedSupplyInformation from './NeedSupplyInformation';
import NeedServiceInformation from './NeedServiceInformation';
import OfferSupplyInformation from './OfferSupplyInformation';
import OfferServiceInformation from './OfferServiceInformation';
import OfferDiscountInformation from './OfferDiscountInformation';
import OfferInformationInformation from './OfferInformationInformation';

import NeedSupplyClaimForm from './NeedSupplyClaimForm';
import NeedServiceClaimForm from './NeedServiceClaimForm';
import OfferSupplyClaimForm from './OfferSupplyClaimForm';
import OfferServiceClaimForm from './OfferServiceClaimForm';
import OfferDiscountClaimForm from './OfferDiscountClaimForm';
import OfferInformationClaimForm from './OfferInformationClaimForm';

import {Division, ItemPanel} from '../ItemPanel';
import ProgressIndicator from '../ProgressIndicator/ProgressIndicator';
import RequestJoinForm from './RequestJoinForm';
import ResponsiveImg from '../ResponsiveImg';
import Thumbnail from '../Thumbnail';
import Center from '../Center';
import {usePageStore} from '../../contexts/PageContext';
import {ShareButton, ShareModal, ShareLink} from '../Share';
import {MEMBER_PAGE} from '../../urls';
import Dialog from '../Dialog';
import {useLocaleNumber} from '../../hooks/number';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import ItemDataTitle from '../ItemForm/ItemDataTitle';
import Spinner from '../Spinner';
import styles from './ItemClaimForm.module.scss';

const MODAL_REQUIRED_SIDE = 'need';
const MODAL_REQUIRED_KINDS = ['supply', 'service'];

const itemInformationForms = {
  need: {
    supply: NeedSupplyInformation,
    service: NeedServiceInformation,
  },
  offer: {
    discount: OfferDiscountInformation,
    supply: OfferSupplyInformation,
    service: OfferServiceInformation,
    information: OfferInformationInformation,
  },
};

const itemClaimForms = {
  need: {
    supply: NeedSupplyClaimForm,
    service: NeedServiceClaimForm,
  },
  offer: {
    discount: OfferDiscountClaimForm,
    supply: OfferSupplyClaimForm,
    service: OfferServiceClaimForm,
    information: OfferInformationClaimForm,
  },
};

const ItemClaimForm = (props) => {
  const stores = useStores();
  const [pageState, pageActions] = usePageStore();
  const api = useApi();
  const localeNumber = useLocaleNumber();
  const {onSave, currentIssue, itemId, relatedItemId} = props;
  const history = useHistory();
  const [relatedItem, setRelatedItem] = useState(null);
  const [maxQuantity, setMaxQuantity] = useState(null);
  const {t} = useTranslation(['claim', 'common', 'add-items']);
  const [item, isLoading] = useItem(itemId);
  const [showModal, setShowModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [itemClaimFormValues, setItemClaimFormValues] = useState();
  const handleToggleModal = () => setShowModal(!showModal);

  useEffect(() => {
    let breadcrumb = null;
    let previousBreadcrumb = null;

    if (item && !isLoading) {
      if (currentIssue && !pageState.isSuggestedItem) {
        breadcrumb = {
          key: `issue-page-claim-${item.side}`,
          options: {issueName: currentIssue.name},
        };
        previousBreadcrumb = {
          key: `issue-page`,
          options: {issueName: currentIssue.name},
        };
      }
      if (currentIssue && pageState.isSuggestedItem) {
        breadcrumb = {
          key: `suggested-${item.side}-details`,
        };
      }
      if (!currentIssue && !pageState.isSuggestedItem) {
        breadcrumb = {
          key: `claim-${item.side}`,
        };
        previousBreadcrumb = {
          key: 'search',
        };
      }

      pageActions.setBreadcrumbs({
        key: breadcrumb.key,
        options: breadcrumb.options,
      });
    }

    return () => {
      if (previousBreadcrumb)
        pageActions.setBreadcrumbs({
          key: previousBreadcrumb.key,
          options: previousBreadcrumb.options,
        });
    };
  }, [item, isLoading, pageActions, currentIssue, pageState.isSuggestedItem]);

  useEffect(() => {
    if (relatedItemId) {
      const fetchRelatedItem = async () => {
        const response = await api.getItem(relatedItemId);
        setRelatedItem(response);
      };
      fetchRelatedItem();
    }
  }, [relatedItemId, api]);

  const handleSubmit = async (values) => {
    const {id, side, kind, name, status} = item;
    values.name = name;
    values.id = id;
    values.side = side;
    values.kind = kind;

    // @FIXME remove this[?]
    values.status = status;

    values.currency = stores.app.user.currency;

    if (
      item.side === MODAL_REQUIRED_SIDE &&
      MODAL_REQUIRED_KINDS.includes(item.kind)
    ) {
      setItemClaimFormValues(values);
      setShowWarningModal(true);
    } else {
      const response = await api.createItemClaim(values);
      onSave(item, values, response.id);
    }
  };

  useEffect(() => {
    let maxQuantity = item ? item.quantity - item.claimedQuantity : null;
    if (relatedItem) {
      const relatedItemRemainingQuantity =
        relatedItem.quantity - relatedItem.claimedQuantity;
      maxQuantity = Math.min(relatedItemRemainingQuantity, maxQuantity);
    }
    setMaxQuantity(maxQuantity);
  }, [item, relatedItem]);

  if (!item)
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );

  const isOffer = item.side === 'offer';
  const progressBar =
    item.kind !== 'discount' && item.kind !== 'information' ? (
      <ProgressIndicator
        total={item.quantity || 0}
        progress={item.claimedQuantity}
        uomFulfillment={item.uomFulfillment}
      />
    ) : null;
  const InformationFormComponent = itemInformationForms[item.side][item.kind];
  const ClaimFormComponent = itemClaimForms[item.side][item.kind];
  const title = isOffer
    ? t('claim:form-title.claim-offer')
    : t('claim:form-title.meet-need');
  const imageSource = item.image ? item.image.url : '';
  const filesSource = item.files;

  const handleOrganizationClick = () => {
    if (item.organization) {
      history.push(MEMBER_PAGE.replace(':slug', item.organization.slug));
    }
  };

  const handleOnSubmit = () => {
    history.push('/search/request-join/finish');
  };

  const renderMarketValueDialog = () => {
    const handleOnConfirm = async () => {
      await api.createItemClaim(itemClaimFormValues);
      setShowWarningModal(false);
      onSave(item, itemClaimFormValues);
    };
    const handleOnCancel = async () => {
      setShowWarningModal(false);
    };

    return (
      <Dialog
        header={t('add-items:add-needs.warning-dialog.header')}
        message={t('add-items:add-needs.warning-dialog.message', {
          needTitle: item.name,
          currency: stores.app.currency.code,
          marketValue: localeNumber(
            itemClaimFormValues.marketValue
              ? itemClaimFormValues.marketValue
              : 0,
          ),
        })}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        confirmText={t('add-items:add-needs.warning-dialog.confirm')}
        cancelText={t('add-items:add-needs.warning-dialog.cancel')}
        noIcon="true"
      />
    );
  };

  const renderFiles = (file, key) => (
    <React.Fragment key={key}>
      <Division />
      <Center className={styles.center} column>
        <React.Fragment>{file.name}</React.Fragment>
        <a className={styles.link} href={file.url} target="blank">
          {t('claim:download')}
        </a>
      </Center>
      <Division />
    </React.Fragment>
  );

  return (
    <ItemPanel
      item={item}
      title={title}
      onOrganizationClick={handleOrganizationClick}>
      {progressBar}
      {imageSource && (
        <React.Fragment>
          <Division />
          <Center className={styles.center} column>
            <Thumbnail>
              <ResponsiveImg src={imageSource} alt="" />
            </Thumbnail>
            <a className={styles.link} href={imageSource} target="blank">
              {t('claim:full-size-image')}
            </a>
          </Center>
          <Division />
        </React.Fragment>
      )}
      {filesSource && (
        <React.Fragment>
          <ItemDataTitle>{t('claim:files-title')}</ItemDataTitle>
          {filesSource.map(renderFiles)}
        </React.Fragment>
      )}
      {stores.app.user && (
        <div className={styles.informationWrapper}>
          <InformationFormComponent item={item} relatedItem={relatedItem} />
        </div>
      )}
      <div className={styles.shareContainer}>
        <ShareButton
          onClick={handleToggleModal}
          label={t(`common:${item.side}`)}
        />
        {showModal && (
          <ShareModal
            onClose={handleToggleModal}
            label={t(`common:${item.side}`)}>
            <ShareLink />
          </ShareModal>
        )}
      </div>
      {pageState.isPublic ? (
        <RequestJoinForm isFromItem onSubmit={handleOnSubmit} />
      ) : (
        stores.app.user && (
          <ClaimFormComponent
            item={item}
            onSubmit={handleSubmit}
            maxQuantity={maxQuantity}
          />
        )
      )}
      {showWarningModal && renderMarketValueDialog()}
    </ItemPanel>
  );
};

export default observer(ItemClaimForm);
