import React, {useState} from 'react';
import styled from 'styled-components';

import ItemsCarousel from 'react-items-carousel';
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';

const StyledCarousel = styled.div`
  flex-flow: row wrap;
  margin: 0 0 32px;

  .arrow-right,
  .arrow-left {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .arrow-right {
    right: -1px;
    background: linear-gradient(
      to left,
      rgba(50, 50, 50, 1),
      rgba(50, 50, 50, 0.3) 30%,
      rgba(50, 50, 50, 0) 100%
    );
  }

  .arrow-left {
    left: -1px;
    background: linear-gradient(
      to right,
      rgba(50, 50, 50, 1),
      rgba(50, 50, 50, 0.3) 30%,
      rgba(50, 50, 50, 0) 100%
    );
  }
`;

const StyledArrow = styled(({isLeftArrow, ...rest}) => <ArrowIcon {...rest} />)`
  ${(props) => (props.isLeftArrow ? `transform: rotate(-180deg);` : '')}

  path {
    fill: #fff;
  }
`;

const Carousel = (props) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 79;
  const Chevron = (isLeftArrow) => {
    return (
      <div>
        <StyledArrow isLeftArrow={isLeftArrow} />
      </div>
    );
  };

  return (
    <StyledCarousel height={props.carouselHeight}>
      <ItemsCarousel
        infiniteLoop={true}
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={props.numberOfCards}
        gutter={props.spaceBetweenItems}
        firstAndLastGutter={true}
        rightChevron={Chevron()}
        leftChevron={Chevron(true)}
        outsideChevron={true}
        chevronWidth={chevronWidth}
        classes={{
          itemWrapper: 'd-flex',
          rightChevronWrapper: 'arrow-right',
          leftChevronWrapper: 'arrow-left',
        }}
        showSlither
      >
        {props.children}
      </ItemsCarousel>
    </StyledCarousel>
  );
};

export default Carousel;
