import React, {useEffect, useState} from 'react';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import {Col} from 'react-bootstrap';
import Label from '../Form/Label';
import Form from '../ItemForm/Form';
import {LanguageField} from '../Fields';
import Footer from '../ItemForm/Footer';
import Button from '../Button';
import {useApi} from '../../contexts/ApiContext';
import {InputField, SelectField} from '../Fields';
import Spinner from '../Spinner';
import {useStores} from '../../contexts/StoresContext';
import styles from './InviteForm.module.scss';

const InviteOrgUserForm = (props) => {
  const {t} = useTranslation(['user', 'forms']);
  const {handleSubmit} = props;
  const {user} = props.stores.app;
  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    if (user.isAdmin) {
      const fetchOrgs = async () => {
        const response = await props.api.getOrgs({page_size: 0});
        setOrgs(response);
      };
      fetchOrgs();
    }
  }, [user, props.api]);

  const renderOrgs = (org) => {
    return (
      <option value={org.id} key={org.id}>
        {org.name}
      </option>
    );
  };

  if (user.isAdmin && !orgs.length) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        {user.isAdmin && (
          <Form.Group as={Col} sm={12}>
            <SelectField
              name="organizationId"
              label={t('user:form-fields.org-user.organization')}
              isRequired>
              <option value="">{t('forms:select-option')}</option>
              {orgs.map(renderOrgs)}
            </SelectField>
          </Form.Group>
        )}
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('user:form-fields.org-user.name')}</Label>
          <InputField
            type="text"
            name="firstName"
            placeholder={t('user:placeholders.org-user.name')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('user:form-fields.org-user.last-name')}</Label>
          <InputField
            type="text"
            name="lastName"
            placeholder={t('user:placeholders.org-user.last-name')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('user:form-fields.org-user.email')}</Label>
          <InputField
            type="text"
            name="email"
            placeholder={t('user:placeholders.org-user.email')}
          />
        </Form.Group>
      </Form.Row>
      <LanguageField label="Language" name="languageId" />
      <Footer>
        <Button
          type="submit"
          variant="primary"
          className="text-uppercase"
          responsiveFullWidth>
          {t('user:buttons.invite-user')}
        </Button>
      </Footer>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'InviteOrgUserForm',
  mapPropsToValues: (props) => {
    const {user, organization} = props.stores.app;
    return {
      organizationId: user.isAdmin ? '' : organization.id,
      firstName: '',
      lastName: '',
      email: '',
      languageId: user.language.id || null,
    };
  },
  validationSchema: yup.object().shape({
    organizationId: yup.number().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    languageId: yup.number().required(),
  }),
  handleSubmit: async (values, {props, setSubmitting, setErrors}) => {
    try {
      await props.api.createInvitation({...values, invitationType: 'org_user'});
      setSubmitting(false);
      props.onSave();
    } catch (error) {
      if (error.email) {
        const {toast} = props.stores.app;
        toast.toasts.forEach(
          ({id, type}) =>
            type !== 'testing' && props.stores.app.removeToast(id),
        );
        setErrors({
          email: error.email ? error.email[0] : undefined,
        });
      }
      setSubmitting(false);
    }
  },
})(InviteOrgUserForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  const api = useApi();
  return <EnhancedForm api={api} stores={stores} {...props} />;
};

export default observer(FormWrapper);
