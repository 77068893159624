import React, {useRef, useState} from 'react';

import styled from 'styled-components';

import Spinner from '../Spinner';
import theme from '../../theme';

const DropArea = styled.div`
  border: 1px dashed #d8d8d8;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 15px;
  cursor: ${(props) => (props.isFileUploaded ? 'default' : 'pointer')};
  background-color: ${(props) =>
    props.hightlight ? theme.greyBorderColor : ''};
  text-align: center;
`;

const StyledInput = styled.input`
  display: none;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FileDropZone = (props) => {
  const inputImage = useRef(null);
  const [hightlight, setHightlight] = useState(false);
  const {accept} = props;

  const openFileDialog = () => {
    if (props.isFileUploaded) return;

    inputImage.current.click();
  };

  const fileListToArray = (list) => {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  };

  const onFilesAdded = (event) => {
    if (props.isFileUploaded) return;

    const files = event.target.files;
    const array = fileListToArray(files);

    if (props.onFilesAdded) {
      props.onFilesAdded(array);
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
    if (props.isFileUploaded) return;

    setHightlight(true);
  };

  const onDragLeave = () => {
    setHightlight(false);
  };

  const onDrop = (event) => {
    event.preventDefault();
    if (props.isFileUploaded) return;

    const files = event.dataTransfer.files;

    if (props.onFilesAdded) {
      const array = fileListToArray(files);
      props.onFilesAdded(array);
    }
    setHightlight(false);
  };

  const dropZoneContent = props.isFileUploaded ? (
    props.renderFileLoaded()
  ) : props.isUploading ? (
    <SpinnerWrapper>
      <Spinner />
    </SpinnerWrapper>
  ) : (
    props.renderPlaceholder()
  );

  return (
    <DropArea
      hightlight={hightlight}
      onClick={openFileDialog}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      isFileUploaded={props.isFileUploaded}
    >
      <StyledInput
        ref={inputImage}
        type="file"
        multiple={props.multiple}
        onChange={onFilesAdded}
        value={props.value}
        accept={accept || '*'}
      />
      {dropZoneContent}
    </DropArea>
  );
};

export default FileDropZone;
