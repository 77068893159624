import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {setLocale} from 'yup';
import {importAll} from './utils';
import {createTranslationKey} from './utils/i18n';
import moment from 'moment';

// Only import the locales for the languages we actually use.
// Importing all would add >100 Kb to our webpack bundle size.
import {enUS, es, uk, pl, ru} from 'date-fns/esm/locale';

export const dateFnsLocales = {
  'en-us': enUS,
  es,
  uk,
  pl,
  ru,
};

const translationFiles = importAll(
  process.env.NODE_ENV !== 'production'
    ? require.context(`./translations-local/`, true, /\.json$/)
    : require.context(`./translations/`, true, /\.json$/),
);

const resources = Object.keys(translationFiles).reduce((accum, current) => {
  // current './<namespace>/<lang>.json
  // eslint-disable-next-line no-useless-escape
  const [, namespace, lang] = current.match(
    /\.\/([a-zA-Z-]+)\/([a-zA-Z-]+)\.json/,
  );

  return {
    ...accum,
    [lang]: {
      ...accum[lang],
      [namespace]: translationFiles[current],
    },
  };
}, {});

// TODO: once we have some loading mechanism
// implement xhr backend
// import Backend from 'i18next-xhr-backend';
i18n
  .use(initReactI18next)
  // .use(Backend)
  .init({
    // backend: {
    //   loadPath: `${process.env.PUBLIC_URL}/translations/{{ns}}/{{lng}}.json`,
    //   crossDomain: true,
    // },
    resources,
    lng: 'en-us',
    appendNamespaceToMissingKey: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

i18n.on('languageChanged', function (lng) {
  moment.locale(lng);
});

// Setup i18n in yup to translate form errors
setLocale({
  mixed: {
    required: createTranslationKey('errors.required'),
    notType: (_ref) => createTranslationKey(`errors.${_ref.type}.typeError`),
  },
  number: {
    min: ({min, label}) =>
      createTranslationKey('errors.number.min', {
        min,
        fieldName: i18n.t(`forms:fields.${label}`),
      }),
    max: ({max, label}) =>
      createTranslationKey('errors.number.max', {
        max,
        fieldName: i18n.t(`forms:fields.${label}`),
      }),
    positive: ({label}) =>
      createTranslationKey('errors.number.positive', {
        fieldName: i18n.t(`forms:fields.${label}`),
      }),
  },
  string: {
    min: ({min, label}) =>
      createTranslationKey('errors.string.min', {
        min,
        fieldName: i18n.t(`forms:fields.${label}`),
      }),
    max: ({max, label}) =>
      createTranslationKey('errors.string.max', {
        max,
        fieldName: i18n.t(`forms:fields.${label}`),
      }),
  },
  date: {
    min: createTranslationKey('errors.date.min'),
  },
  array: {
    min: ({min, label}) =>
      createTranslationKey('errors.array.min', {
        min,
        fieldName: i18n.t(`forms:fields.${label}`),
      }),
  },
});

export default i18n;
