import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {useHistory} from 'react-router-dom';
import {Form as BootstrapForm, Col} from 'react-bootstrap';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import styles from './LoginV2.module.scss';
import {createTranslationKey} from '../../utils/i18n';
import Button from '../../components/Button';
import {NavLink} from 'react-router-dom';
import {InputField, CheckboxField} from '../../components/Fields';
import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';
import {ONBOARDING_USER_DETAILS, TOS_PAGE, PRIVACY_PAGE} from '../../urls';
import {useAuth0} from '@auth0/auth0-react';
import {clientLogoURL} from '../../constants';
import {getUrlWithLang} from '../../utils';

const RegisterFormV2 = (props) => {
  const {setFieldValue, handleSubmit, invitation} = props;
  const {t} = useTranslation('new-user');
  const {user, isAuthenticated, isLoading} = useAuth0();

  const langStaticFiles = invitation
    ? invitation.language.code
    : window.navigator.language.toLowerCase();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      setFieldValue('email', user.email);
    }
  }, [isLoading, isAuthenticated, user, setFieldValue]);

  const handleTACChange = (event) => {
    setFieldValue('termsAndConditions', event.target.checked);
  };

  const content = (
    <BootstrapForm
      className={styles.register_form}
      noValidate
      onSubmit={handleSubmit}>
      <div className={styles.register_form_fields_container}>
        <BootstrapForm.Group as={Col} lg={12}>
          <InputField
            type="text"
            placeholder={t('place-holder.member-name')}
            label={t('label.member-name')}
            name="memberName"
            isRequired
            readOnly={!!invitation}
          />
        </BootstrapForm.Group>
        <BootstrapForm.Group as={Col} lg={12}>
          <InputField
            type="text"
            placeholder={t('place-holder.email')}
            label={t('label.email')}
            name="email"
            isRequired
            readOnly
            withoutTextStyle
          />
        </BootstrapForm.Group>
        <BootstrapForm.Group as={Col} lg={12}>
          <CheckboxField
            label={
              <React.Fragment>
                <NavLink
                  to={getUrlWithLang(TOS_PAGE, langStaticFiles)}
                  target="_blank">
                  {t('label.tos')}
                </NavLink>
                &nbsp;{t('label.and')}&nbsp;
                <NavLink
                  to={getUrlWithLang(PRIVACY_PAGE, langStaticFiles)}
                  target="_blank">
                  {t('label.privacy')}
                </NavLink>
              </React.Fragment>
            }
            onChange={handleTACChange}
            name="termsAndConditions"
            isRequired
          />
        </BootstrapForm.Group>
      </div>
      <div className={styles.register_form_footer}>
        <Button type="submit" disabled={props.isSubmitting} responsiveFullWidth>
          {t('button')}
        </Button>
      </div>
    </BootstrapForm>
  );

  return (
    <>
      <div className={styles.main_auth_div}>
        <div className={styles.main_auth_div_2}>
          <div className={styles.main_auth_div_3}>
            <div className={styles.main_auth_header}>
              <img
                src={clientLogoURL}
                className={styles.main_auth_header_logo}
                alt=""
              />
              <div className={styles.main_auth_header_text}>
                <span className={styles.main_auth_header_text_line1}>
                  {t('auth0.title')}
                </span>
                <div className={styles.main_auth_header_subtitle}>
                  <span className={styles.main_auth_header_subtitle_text}>
                    {t('auth0.description')}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.auth_form}>{content}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const EnhancedForm = withFormik({
  displayName: 'RegisterFormV2',
  mapPropsToValues: (props) => {
    const {invitation} = props;
    const {state} = props.history.location;

    const initialValues = {
      memberName: invitation ? invitation.organization_name : '',
      email: invitation ? invitation.email : '',
      password: '',
      confirmPassword: '',
      termsAndConditions: false,
    };

    if (state && state.newRequest) {
      initialValues.memberName = state.newRequest.organization_name;
      initialValues.email = state.newRequest.email;
    }

    return initialValues;
  },
  validationSchema: (props) => {
    var data = yup.object().shape({
      memberName: yup.string().required(),
      email: yup.string().email().required(),
      termsAndConditions: yup
        .bool()
        .oneOf([true], createTranslationKey('errors.terms-and-conditions')),
    });
    return data;
  },
  handleSubmit: async (values, {props, setSubmitting, setErrors}) => {
    const {invitation, invitationToken} = props;
    const {state} = props.history.location;

    let newUser = {
      email: values.email,
      password: '',
      repeated_password: '',
      registered_organization_name: values.memberName,
    };

    if (invitationToken) {
      newUser = {
        ...newUser,
        invitation_token: invitationToken,
      };
    }

    if (invitation) {
      newUser = {
        ...newUser,
        token: invitation.token,
        first_name: invitation.first_name,
        last_name: invitation.last_name,
      };
    }

    if (state && state.newRequest) {
      newUser = {
        ...newUser,
        first_name: state.newRequest.first_name,
        last_name: state.newRequest.last_name,
      };
    }

    try {
      const user = await props.api.createNewUser(newUser);
      props.stores.app.login({...user, invitationToken: !!invitation});
      setSubmitting(false);

      if (props.stores.app.user) {
        props.history.push(ONBOARDING_USER_DETAILS);
      } else {
        setErrors({
          email: 'Login Error',
        });
      }
    } catch (error) {
      if (error.email || error.error) {
        setErrors({
          email: error.email
            ? error.email[0]
            : error.error
            ? error.error
            : undefined,
        });
      }
      setSubmitting(false);
    }
  },
})(RegisterFormV2);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  const history = useHistory();
  const api = useApi();
  return (
    <EnhancedForm api={api} history={history} stores={stores} {...props} />
  );
};

export default observer(FormWrapper);
