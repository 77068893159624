import React, {useEffect, useState} from 'react';
import styles from './Login.module.scss';
import InformationToast from '../../components/InformationToast';
import {Trans, useTranslation} from 'react-i18next';
import needslistLogo from '../../assets/needslist_logo.svg';
import {Card} from 'react-bootstrap';

const LayoutLogin = ({children}) => {
  const [sessionHasExpired, setSessionHasExpired] = useState(null);
  const {t} = useTranslation('login');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setSessionHasExpired(params.get('inactivitySessionExpire'));
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.mainBanner}>
        {sessionHasExpired && (
          <InformationToast
            title={t('toast.title')}
            text={t('toast.text-inactivity')}
            className={styles.informationToast}
          />
        )}
        <div className={styles.textContainer}>
          <h1>
            <Trans t={t} i18nKey="banner.main-title" />
          </h1>
          <p>
            <Trans t={t} i18nKey="banner.main-description-line-1" />{' '}
            <Trans t={t} i18nKey="banner.main-description-line-2" />
          </p>
        </div>
        <div className={styles.backgroundImage} />
      </div>
      <div className={styles.needslistLogo}>
        <span>
          <Trans t={t} i18nKey="common:footer.powered-by" />
        </span>
        <img alt="Needslist logo" src={needslistLogo} />
      </div>
      <div className={styles.blueStrip}></div>
      <Card className={styles.cardContainer}>{children}</Card>
    </div>
  );
};

export default LayoutLogin;
