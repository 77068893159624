import React from 'react';

import {useFields} from '../../hooks/extraAttribute';
import Subtitle from '../../components/Subtitle';
import Label from './Helper/Label';
import InformationField from '../../pages/MemberPage/InformationField';

const ExtraAttributeDetails = (props) => {
  const {extraAttribute, modelName} = props;
  const {fields: groups} = useFields(modelName);

  const fieldsMapper = (field) => {
    if (field.kind === 'choice') {
      if (field.options.visible) {
        if (field.options.visible[0].when === 'never') {
          return null;
        }
        const visible = field.options.visible.filter((opt) => {
          return extraAttribute[opt.when].indexOf(opt.value) !== -1;
        });

        if (visible.length !== field.options.visible.length) {
          return null;
        }
      }

      const choices = field.choices.reduce((accum, current) => {
        return {
          ...accum,
          [current.id]: current.label,
        };
      }, {});

      const mapChoices = (choice) => {
        const description =
          typeof choice === 'string' ? choice : choices[choice];
        return <InformationField key={choice} description={description} />;
      };

      return (
        <React.Fragment key={field.id}>
          <Label>{field.label}</Label>
          {extraAttribute.hasOwnProperty(field.name) ? (
            extraAttribute[field.name].map(mapChoices)
          ) : (
            <InformationField />
          )}
        </React.Fragment>
      );
    }

    return (
      <InformationField
        key={field.id}
        label={field.label}
        description={extraAttribute[field.name]}
      />
    );
  };

  const groupsMapper = (group) => {
    return (
      <React.Fragment key={`extra_attribute_group.${group.id}`}>
        {group.title && <Subtitle>{group.title}</Subtitle>}
        {group.subtitle && <Label>{group.subtitle}</Label>}
        {group.fields && group.fields.map(fieldsMapper)}
      </React.Fragment>
    );
  };

  return groups && groups.map(groupsMapper);
};

export default ExtraAttributeDetails;
