import React from 'react';

import styled from 'styled-components';
import {CarouselItem} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const StyledItem = styled(CarouselItem)`
  height: 615px;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 24px;
  right: 25px;
  color: #838383;
  cursor: pointer;
`;

const OnboardingCarouselItem = (props) => {
  return (
    <StyledItem className={props.className}>
      <CloseIcon icon="times" size="lg" onClick={props.onClose} />
      {props.children}
    </StyledItem>
  );
};

export default OnboardingCarouselItem;
