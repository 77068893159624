import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import EditMemberForm from './EditMemberForm';

const Header = styled.div`
  padding: 19px 103px 11px 15px;
  border-bottom: 1px solid #e8e8e8;
`;

const PanelTitle = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
`;

const FormTitle = styled.h3`
  padding: 15px 15px;
  font-size: 22px;
`;

const EditMemberFormWrapper = styled.div`
  margin: 0 15px;
`;

const EditMember = (props) => {
  const {org} = props;
  const {t} = useTranslation('member-page');

  return (
    <React.Fragment>
      <Header>
        <PanelTitle>{t('edit.header', {org: org.name})}</PanelTitle>
      </Header>
      <FormTitle>{t('edit.title', {org: org.name})}</FormTitle>
      <EditMemberFormWrapper>
        <EditMemberForm {...props} />
      </EditMemberFormWrapper>
    </React.Fragment>
  );
};

export default EditMember;
