import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import App from './App';
import BackOffice from './AppBackOffice';
import {library} from '@fortawesome/fontawesome-svg-core';
import {sentryEnvironment} from './constants';

import './index.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import {
  faAngleLeft,
  faCheck,
  faTimesCircle,
  faTimes,
  faSearch,
  faCircleNotch,
  faPlus,
  faPlusCircle,
  faMinusCircle,
  faUser,
  faUserCircle,
  faArrowRight,
  faThumbsUp,
  faThumbsDown,
  faClipboardList,
  faCaretDown,
  faMapMarkerAlt,
  faEnvelope,
  faExclamationTriangle,
  faAward,
  faCircle,
  faEllipsisV,
  faInfoCircle,
  faPen,
  faTrash,
  faSpinner,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faAngleLeft,
  faCheck,
  faTimesCircle,
  faTimes,
  faSearch,
  faCircleNotch,
  faPlus,
  faPlusCircle,
  faMinusCircle,
  faUser,
  faArrowRight,
  faThumbsDown,
  faThumbsUp,
  faClipboardList,
  faUserCircle,
  faCaretDown,
  faMapMarkerAlt,
  faEnvelope,
  faExclamationTriangle,
  faAward,
  faCircle,
  faEllipsisV,
  faInfoCircle,
  faPen,
  faTrash,
  faSpinner,
  faArrowDown,
);

const sentryEnv = sentryEnvironment();
if (sentryEnv) {
  Sentry.init({
    dsn: 'https://02fd24ed294847c08974b03ce1dfb599@o312292.ingest.sentry.io/1818880',
    integrations: [new BrowserTracing(), new Sentry.Replay({})],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: sentryEnv,
  });
}

/**
 * Back Office
 */
if (document.getElementById('root-back-office')) {
  BackOffice.init();
}

/**
 * Client
 */
if (document.getElementById('root')) {
  ReactDOM.render(<App />, document.getElementById('root'));
}
