import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {useHistory, useRouteMatch, Prompt} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';
import {usePageStore} from '../../contexts/PageContext';
import {usePanelStore} from '../../contexts/PanelContext';
import {useSearchIndexApi} from '../../hooks/search-index';
import {useItem} from '../../hooks/items';
import {parsePath} from '../../utils';
import Step from '../AddItems/Step';
import {Route} from '../../components/Layout';
import ItemsList from '../../components/ItemsList';
import ItemEditForm from '../../components/ItemEditForm';
import SuggestedItemsPanel from '../../components/SuggestedItemsPanel';
import ThankYouModal from '../../components/Modals/ThankYouModal';
import Button from '../../components/Button';
import Title from '../../components/Title';
import ItemClaimForm from '../../components/ItemClaimForm';
import Panel from '../../components/Panel';
import Dialog from '../../components/Dialog';
import {
  ISSUE_RESPONSE,
  ISSUE_RESPONSE_ITEM_CLAIM,
  ISSUE_RESPONSE_SUGGESTED_ITEMS,
  ISSUE_RESPONSE_FINISH,
  ISSUE_RESPONSE_EDIT,
} from '../../urls';
import styles from './IssueResponse.module.scss';

const IssueResponse = () => {
  const [, pageActions] = usePageStore();
  const [, panelActions] = usePanelStore();
  const {t} = useTranslation(['common', 'add-items', 'thank-you', 'search']);
  const history = useHistory();
  const stores = useStores();
  const api = useApi();
  const match = useRouteMatch();
  const searchIndexApi = useSearchIndexApi();

  const [isLoading, setIsLoading] = useState(true);
  const [issueResponse, setIssueResponse] = useState(null);
  const [address, setAddress] = useState(null);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [suggestedItems, setSuggestedItems] = useState([]);
  const [toastId, setToastId] = useState(null);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [nextLocation, setNextLocation] = useState('');
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const [item] = useItem(match.params.itemId);

  const getSide = useCallback(() => {
    return match.params.side;
  }, [match.params.side]);

  const setInitialBreadcrumb = useCallback(() => {
    const breadcrumb = getSide() === 'needs' ? 'need-detail' : 'offer-detail';
    pageActions.setBreadcrumbs({key: breadcrumb});
  }, [pageActions, getSide]);

  const fetchIssueResponse = useCallback(async () => {
    const issueResponse = await api.getIssueResponse(
      match.params.issueResponseId,
    );
    const address = issueResponse.deliveryAddressId
      ? await api.getAddress(issueResponse.deliveryAddressId)
      : null;
    setIssueResponse(issueResponse);
    setAddress(address);
    setIsLoading(false);
  }, [api, match.params.issueResponseId]);

  const areItemsCompleted = useMemo(
    () =>
      issueResponse &&
      !issueResponse.items.some((item) => item.status === 'incomplete'),
    [issueResponse],
  );

  const unfinishedChanges = useMemo(
    () =>
      issueResponse?.items.some(
        (item) => item.status === 'complete' || item.status === 'incomplete',
      ),
    [issueResponse],
  );

  const handleBlockedNavigation = (location) => {
    if (confirmedNavigation || location.pathname.includes('/issue-response'))
      return true;

    setNextLocation(location.pathname);
    setShowWarningModal(true);
    return false;
  };

  const handleClose = useCallback(() => {
    const path = parsePath('/issue-response/:issueResponseId/:side/', {
      issueResponseId: match.params.issueResponseId,
      side: match.params.side,
    });
    return history.replace(path);
  }, [history, match.params.issueResponseId, match.params.side]);

  useEffect(() => {
    fetchIssueResponse();
    setInitialBreadcrumb();
    if (match.params.itemId) {
      pageActions.setBreadcrumbs({
        key: `edit-${match.params.side.replace('s', '')}`,
      });
    }
    return () => {
      stores.app.removeToast(toastId);
    };
  }, [
    fetchIssueResponse,
    setInitialBreadcrumb,
    toastId,
    match.params.itemId,
    match.params.side,
    stores.app,
    pageActions,
  ]);

  useEffect(() => {
    if (submitFailed && stores.app.toast.show && areItemsCompleted) {
      stores.app.removeToast(toastId);
      setSubmitFailed(false);
    }
  }, [toastId, stores.app, areItemsCompleted, submitFailed]);

  useEffect(() => {
    if (confirmedNavigation) {
      history.push(nextLocation);
      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation, history, nextLocation]);

  const getParamsForAlgolia = async (searchValues, item) => {
    const items_id = [];
    const side = item.side === 'offer' ? 'need' : 'offer';

    for (const valueSearch of searchValues) {
      const userLanguage = stores.app.language;
      const results = await searchIndexApi.search(valueSearch, {
        restrictSearchableAttributes: [
          `title.${userLanguage}`,
          'meta_description',
        ],
        models: ['needslist_item.Item'],
        exactMatch: false,
        metaStatus: ['open', 'in_progress'],
        metaKinds: [item.kind],
        metaIssue: issueResponse.issue.id,
        metaSide: [side],
        distict: false,
      });

      results.forEach((hit) => items_id.push(hit.id));
    }

    if (items_id.length === 0) return null;

    return {
      ids: items_id,
    };
  };

  const getParamsForApi = (searchValues, item) => {
    return {
      search: searchValues,
      side: item.side === 'offer' ? 'need' : 'offer',
      status: ['open', 'in_progress'],
      kind: [item.kind],
      issue_id: [issueResponse.issue.id],
      suggested: true,
    };
  };

  const fetchSuggestedItems = async () => {
    const searchValues = [item.name];
    if (item.description) searchValues.push(item.description);

    const params = stores.app.algolia.is_enabled
      ? await getParamsForAlgolia(searchValues, item)
      : getParamsForApi(searchValues, item);

    if (!params) return [];

    const response = await api.getItems(params);
    setSuggestedItems(response.results);
    return response.results;
  };

  const getIssueResponseId = () => {
    return match.params.issueResponseId;
  };

  const handleFormClose = () => {
    fetchIssueResponse();
    history.push(`/issue-response/${getIssueResponseId()}/${getSide()}`);
    setInitialBreadcrumb();
  };

  const handleItemClick = (item) => {
    panelActions.setPanelMount(true);
    history.push(
      `/issue-response/${getIssueResponseId()}/${item.side}s/${item.id}`,
    );
    pageActions.setBreadcrumbs({key: `edit-${item.side}`});
  };

  const handleFinishButton = async () => {
    if (!areItemsCompleted) {
      const toastMessage =
        getSide() === 'offers'
          ? t('add-items:toast.title.offer')
          : t('add-items:toast.title.need');

      const toastId = stores.app.addToast({
        type: 'alert',
        title: toastMessage,
        subtitle: t('add-items:toast.subtitle'),
      });
      setSubmitFailed(true);
      setToastId(toastId);

      window.scrollTo(0, 0);
      return;
    }

    setInitialBreadcrumb();
    const issueResponseId = getIssueResponseId();
    await api.submitIssueResponse(issueResponseId);
    setConfirmedNavigation(true);

    if (!nextLocation) {
      history.push(`/issue-response/${issueResponseId}/${getSide()}/finish`);
    }
  };

  const handleItemSave = async (item) => {
    fetchIssueResponse();
    const results = await fetchSuggestedItems();

    if (results.length > 0) {
      history.push(
        `/issue-response/${getIssueResponseId()}/${item.side}s/${
          item.id
        }/suggested-items`,
      );
    } else {
      handleFormClose();
      panelActions.setPanelMount(false);
    }
  };

  const handleItemClaim = async (item, claimedSuggestedItem, claimItemId) => {
    const {user} = stores.app;
    const side = claimedSuggestedItem.side === 'need' ? 'offer' : 'need';

    const relatedItem = {
      ...claimedSuggestedItem,
      side: side,
      individualsQuantity:
        side === 'offer'
          ? (claimedSuggestedItem.quantity / item.quantity) *
            item.beneficiaries[0].quantity
          : null,
      item: Number(match.params.itemId),
      marketValue: claimedSuggestedItem.marketValue || item.marketValue,
      submittedBy: item.user.id,
      relatedClaimItem: claimItemId,
      currency: user.currency,
    };
    if (claimedSuggestedItem.deliveryOptions === 'need_deliver') {
      relatedItem.deliveryOptions = 'ship';
    }
    await api.createItemClaim(relatedItem);
    await fetchIssueResponse();
    const suggestedItems = await fetchSuggestedItems();
    const updatedItem = issueResponse.items.find(
      (item) => item.id === Number(match.params.itemId),
    );

    if (
      suggestedItems.length &&
      updatedItem.quantity - updatedItem.claimedQuantity > 0
    ) {
      history.push(
        `/issue-response/${getIssueResponseId()}/needs/${match.params.itemId}` +
          `/suggested-items?success=true&claimed_item=${claimedSuggestedItem.item}`,
      );
    } else {
      handleFormClose();
    }
  };

  const handleModalSearchClick = () => {
    history.push(`/issues/${issueResponse.issue.slug}`);
  };

  const handleModalManageClick = () => {
    history.push(`/manage`);
  };

  if (!issueResponse || isLoading) {
    return null;
  }

  const location = address ? `${address.city}, ${address.country}` : null;
  const items = issueResponse.items;

  const title =
    issueResponse.items[0].side === 'offer'
      ? t('add-items:add-offers.step-3.title')
      : t('add-items:add-needs.step-4.title');

  const subtitle =
    issueResponse.items[0].side === 'offer'
      ? t('add-items:add-offers.step-3.subtitle', {count: items.length})
      : t('add-items:add-needs.step-4.subtitle', {count: items.length});

  const pageTitle =
    match.params.side === 'needs'
      ? t('add-items:add-needs.title')
      : t('add-items:add-offers.title');

  const renderItemsList = () => {
    return (
      <ItemsList
        items={items}
        buttonText={t('search:cards.buttons.learn-more')}
        onItemClick={handleItemClick}
        showInvalidItems={submitFailed}
      />
    );
  };

  const renderItemEditForm = (props) => {
    if (isNaN(match.params.itemId)) return null;
    return (
      <ItemEditForm
        defaultDeliveryAddress={issueResponse.deliveryAddressId}
        onSave={handleItemSave}
      />
    );
  };

  const renderItemClaimForm = (props) => {
    return (
      <ItemClaimForm
        onSave={handleItemClaim}
        currentIssue={issueResponse.issue}
        itemId={props.match.params.suggestedItemId}
        relatedItemId={props.match.params.itemId}
        {...props}
      />
    );
  };

  const renderSuggestedItems = (props) => {
    return (
      <SuggestedItemsPanel
        issueResponse={issueResponse}
        suggestedItems={suggestedItems}
        {...props}
      />
    );
  };

  const renderThankYouModal = () => {
    const thankYouMessage =
      getSide() === 'needs'
        ? t('thank-you:labels.need.add-submessage', {
            count: issueResponse.items.length,
            location: location,
            issueName: issueResponse.issue.name,
          })
        : t('thank-you:labels.offer.add-submessage', {
            count: issueResponse.items.length,
            issueName: issueResponse.issue.name,
          });

    return (
      <ThankYouModal
        titleMessage={t('thank-you:labels.message.thank-you')}
        onPrimaryClick={handleModalManageClick}
        primaryButtonText={t('thank-you:buttons.manage')}
        onSecondaryClick={handleModalSearchClick}
        secondaryButtonText={t('thank-you:buttons.return-current-issue')}>
        {thankYouMessage}
      </ThankYouModal>
    );
  };

  const renderWarningDialog = () => {
    const handleOnConfirm = () => {
      setShowWarningModal(false);
    };
    const handleOnCancel = () => {
      setShowWarningModal(false);
      setConfirmedNavigation(true);
    };

    return (
      <Dialog
        header={t('add-items:warning-dialog.header')}
        message={t('add-items:warning-dialog.leave-warning')}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        confirmText={t('add-items:warning-dialog.finalize-post')}
        cancelText={t('add-items:warning-dialog.cancel-post')}
        noIcon="true"
      />
    );
  };

  return (
    <React.Fragment>
      <Prompt when={unfinishedChanges} message={handleBlockedNavigation} />
      {showWarningModal && renderWarningDialog()}
      <div className={styles.wrapper}>
        <Title>{pageTitle}</Title>
        <Step title={title} subtitle={subtitle}>
          <div className={styles.container}>
            <Route path={ISSUE_RESPONSE} render={renderItemsList} />
            <Route
              exact
              path={ISSUE_RESPONSE_FINISH}
              render={renderThankYouModal}
            />
          </div>
        </Step>
        <div className={styles.footer}>
          <Button variant="primary" onClick={handleFinishButton}>
            {t('add-items:add-needs.finish')}
          </Button>
        </div>
      </div>
      <Route
        exact
        path={[
          ISSUE_RESPONSE_EDIT,
          ISSUE_RESPONSE_ITEM_CLAIM,
          ISSUE_RESPONSE_SUGGESTED_ITEMS,
        ]}>
        <Panel onClose={handleClose}>
          <Route exact path={ISSUE_RESPONSE_EDIT} render={renderItemEditForm} />
          <Route
            exact
            path={ISSUE_RESPONSE_ITEM_CLAIM}
            render={renderItemClaimForm}
          />
          <Route
            exact
            path={ISSUE_RESPONSE_SUGGESTED_ITEMS}
            render={renderSuggestedItems}
          />
        </Panel>
      </Route>
    </React.Fragment>
  );
};

export default IssueResponse;
