import React from 'react';
import {configure, action, observable, makeObservable} from 'mobx';
import {setLocalToken, getLocalToken} from '../auth';

import {flattenChoices} from '../utils/choices';

configure({enforceActions: 'observed'});

const DEV_USERS = [
  'daniel+admin@needslist.co',
  'lsalemi@kindalab.co',
  'lrivero@kindalab.co',
  'daniel+support@needslist.co',
  'cdevigili@kindalab.co',
];

const USER_GROUPS = {
  admin_group_id: 1,
  org_admin_group_id: 2,
  org_user_group_id: 3,
  super_admin_group_id: 4,
  editor_group_id: 5,
};

/**
 * App Store
 */
class Claim {
  items = [];

  constructor() {
    makeObservable(this, {
      items: observable,
    });
  }
}

class ClaimModal {
  show = false;
  need = null;
  quantity = '';
  validated = false;
  errors = {
    quantity: [],
  };
  comment = '';

  constructor() {
    makeObservable(this, {
      show: observable,
      need: observable,
      quantity: observable,
      validated: observable,
      errors: observable,
      comment: observable,
    });
  }
}

class Toast {
  toasts = [];

  constructor() {
    makeObservable(this, {
      toasts: observable,
    });
  }
}

class User {
  email = '';
  first_name = '';
  id = '';
  last_name = '';
  organization_role = '';
  phone_number = '';
  registered_organization_name = '';
  status = '';
  timezone = '';
  currency = '';
  language = '';
  groups = null;
}

class ItemFilters {
  search = [];
  organization = [];
  organizationName = [];
  issue = [];
  issueName = [];
  issueSlug = [];
  location = [];
  sortByDate = '-created_at';
  side = [];

  constructor() {
    makeObservable(this, {
      search: observable,
      issue: observable,
      issueName: observable,
      organization: observable,
      organizationName: observable,
      location: observable,
      sortByDate: observable,
      side: observable,
    });
  }
}

class AppStore {
  isPublic = false;
  loaded = false;
  refreshing = false;
  user = null;
  organization = null;
  token = getLocalToken();
  requestJoinModal = true;
  choices = {
    category: [],
    kind: [],
    uom: [],
    tag: [],
    specialHandling: [],
    addressLocationType: [],
  };
  onGoingIssue = null;
  suggestedIssue = null;
  language = null;
  currency = null;
  dateFormat = null;
  datetimeFormat = null;
  languages = [];
  features = [];
  adminEmail = null;

  claimModal = new ClaimModal();
  claim = new Claim();
  toast = new Toast();
  itemFilters = new ItemFilters();

  constructor() {
    makeObservable(this, {
      // state
      loaded: observable,
      refreshing: observable,
      user: observable,
      organization: observable,
      token: observable,
      choices: observable,
      onGoingIssue: observable,
      suggestedIssue: observable,
      isPublic: observable,
      requestJoinModal: observable,
      language: observable,
      currency: observable,
      dateFormat: observable,
      datetimeFormat: observable,
      languages: observable,
      features: observable,
      adminEmail: observable,

      // actions
      init: action,
      login: action,
      setSession: action,
      logout: action,
      togglePublicPage: action,
      toggleRequestJoinModal: action,
      setLanguage: action,
      setLanguages: action,
      setRefreshing: action,
      setAdminEmail: action,

      // Claim
      claim: observable,
      claimModal: observable,
      openClaimModal: action,
      closeClaimModal: action,
      setClaimQty: action,
      setClaimComment: action,
      setClaimSubmitted: action,
      setClaimError: action,

      // Toast
      toast: observable,
      addToast: action,
      removeToast: action,
      removeToasts: action,

      // ItemFilters
      itemFilters: observable,
      setFilter: action,
      removeFilter: action,
      setSortBy: action,
      clearFilter: action,
      clearFilters: action,

      // Uoms
      setUoms: action,
      setTags: action,
    });
  }

  togglePublicPage(isPublic) {
    this.isPublic = isPublic;
  }

  toggleRequestJoinModal(show) {
    this.requestJoinModal = show;
  }

  openClaimModal(need) {
    this.claimModal = {
      ...this.claimModal,
      show: true,
      need: need,
      quantity: '1',
    };
  }

  closeClaimModal(item) {
    if (item) {
      this.claim.items.push(item);
    }
    this.claimModal = new ClaimModal();
  }

  setClaimQty(quantity) {
    this.claimModal = {
      ...this.claimModal,
      quantity: quantity,
    };
  }

  setClaimComment(comment) {
    this.claimModal = {
      ...this.claimModal,
      comment: comment,
    };
  }

  setClaimError(errors) {
    this.claimModal = {
      ...this.claimModal,
      errors: errors,
    };
  }

  setClaimSubmitted(quantity) {
    this.claim = new Claim();
  }

  login(payload) {
    setLocalToken(payload.token);

    this.setSession(payload);
  }

  logout() {
    setLocalToken(null);

    this.setSession({
      token: null,
      user: null,
      organization: null,
      choices: {
        category: [],
        kind: [],
        uom: [],
        special_handling: [],
        address_location_type: [],
      },
      onGoingIssue: null,
      suggestedIssue: null,
    });
  }

  init() {
    this.loaded = true;
  }

  setSession(payload) {
    this.token = payload.token;
    this.organization = payload.organization;
    this.choices = flattenChoices(payload.choices);
    this.onGoingIssue = payload.ongoing_issue;
    this.suggestedIssue = payload.suggested_issue;
    this.language = payload.language?.code;
    this.features = payload.features;
    this.currency = payload.currency;
    this.dateFormat = payload.date_format;
    this.datetimeFormat = payload.datetime_format;
    this.clientData = payload.client_data;
    this.algolia = {
      search_api_key: payload.search_api_key,
      search_index: payload.search_index,
      is_enabled: payload.search_api_key !== null,
    };

    if (payload.user) {
      this.user = new User();
      this.user.email = payload.user.email;
      this.user.first_name = payload.user.first_name;
      this.user.id = payload.user.id;
      this.user.uuid = payload.user.uuid;
      this.user.last_name = payload.user.last_name;
      this.user.organization_role = payload.user.organization_role;
      this.user.phone_number = payload.user.phone_number;
      this.user.registered_organization_name =
        payload.user.registered_organization_name;
      this.user.status = payload.user.status;
      this.user.timezone = payload.user.timezone;
      this.user.groups = payload.user.groups;
      this.user.organization_id = payload.user.organization;
      this.user.organization_slug = payload.organization
        ? payload.organization.slug
        : '';
      this.user.invitation_token = payload.invitation_token;
      this.user.isAdmin = payload.user.groups.some(
        (roleId) =>
          roleId === USER_GROUPS.admin_group_id ||
          roleId === USER_GROUPS.super_admin_group_id,
      );
      this.user.isOrgAdmin = payload.user.groups.includes(
        USER_GROUPS.org_admin_group_id,
      );
      this.user.isDev = DEV_USERS.includes(payload.user.email);
      this.user.isEditor = payload.user.groups.includes(
        USER_GROUPS.editor_group_id,
      );
      this.user.currency = payload.user.currency;
      this.user.language = payload.user.language;

      this.toast.toasts.forEach(
        ({id, type}) => type !== 'testing' && this.removeToast(id),
      );
    } else {
      this.user = null;
    }

    if (payload.claim) {
      this.claim = new Claim();
      this.claim.items = payload.claim.items;
    } else {
      this.claim = new Claim();
    }
  }

  addToast(toastData) {
    const randomId = Math.floor(Math.random() * 100);
    toastData.id = randomId;
    this.toast = {
      toasts: [...this.toast.toasts, toastData],
    };
    return randomId;
  }

  removeToast(toastId) {
    this.toast.toasts = this.toast.toasts.filter(
      (toast) => toast.id !== toastId,
    );
  }

  removeToasts() {
    this.toast.toasts = [];
  }

  setFilters(filters) {
    this.itemFilters = {
      ...this.itemFilters,
      ...filters,
    };
  }

  setFilter(category, newFilter) {
    const currentFilters = this.itemFilters[category];
    if (currentFilters.some((filter) => filter.value === newFilter.value))
      return;

    this.itemFilters[category] = [...currentFilters, newFilter];
  }

  removeFilter(category, filterToRemove) {
    this.itemFilters[category] = this.itemFilters[category].filter(
      (filter) => filter.value !== filterToRemove.value,
    );
  }

  setSortBy(sortByDate) {
    this.itemFilters.sortByDate = sortByDate;
  }

  clearFilter(filter) {
    this.itemFilters[filter] = [];
  }

  clearFilters() {
    this.itemFilters.search = [];
    this.itemFilters.issue = [];
    this.itemFilters.location = [];
    this.itemFilters.organization = [];
  }

  updateUser(payload) {
    this.user.first_name = payload.user.first_name;
    this.user.last_name = payload.user.last_name;
    this.user.phone_number = payload.user.phone_number;
    this.user.timezone = payload.user.timezone;
    this.user.currency = payload.user.currency;
  }

  setUoms(uoms) {
    this.choices.uom = [...uoms];
  }
  setTags(tags) {
    this.choices.tag = [...tags];
  }

  setAdminEmail(adminEmail) {
    this.adminEmail = adminEmail;
  }

  setLanguage(language) {
    this.language = language;
  }

  setLanguages(languages) {
    this.languages = languages;
  }

  setRefreshing(state) {
    this.refreshing = state;
  }
}

/**
 * Back Office Store
 */
export class BackOfficeStore {
  kind = '';
  isAdmin = '';
  categories = [];

  constructor() {
    makeObservable(this, {
      kind: observable,
      isAdmin: observable,
      categories: observable,
      setIsAdmin: action,
      setKind: action,
      setCategories: action,
    });
  }

  setIsAdmin(value) {
    this.isAdmin = value;
  }

  setKind(value) {
    this.kind = value;
  }

  setCategories(categories) {
    this.categories = categories;
  }
}

const StoresContext = React.createContext();

const StoresProvider = ({children}) => {
  return (
    <StoresContext.Provider
      value={{
        app: new AppStore(),
        backoffice: new BackOfficeStore(),
      }}>
      {children}
    </StoresContext.Provider>
  );
};

const useStores = () => {
  const context = React.useContext(StoresContext);
  if (context === undefined) {
    throw new Error('`useStores` must be used within a `StoresContext`.');
  }
  return context;
};

export {USER_GROUPS, StoresContext, StoresProvider, useStores};
