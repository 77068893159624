import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {breakpoints} from '../../../constants';
import Responsive from '../../Responsive';
import Paragraph from '../../Paragraph';
import styles from './ItemLocation.module.scss';

const ItemLocation = (props) => {
  const locationIcon = (
    <FontAwesomeIcon icon="map-marker-alt" className={styles.locationIcon} />
  );
  if (!props.location || !props.issueName) return null;

  return (
    <div className={props.className}>
      <Responsive>
        <Paragraph
          max={breakpoints.lg}
          lines={props.extendLocation ? 2 : 1}
          text={`${props.location.place}. ${props.issueName}`}
          className={`${styles.location} ${styles.locationStyles}`}>
          {locationIcon}
        </Paragraph>
        <div min={breakpoints.lg} className={styles.locationStyles}>
          {locationIcon}
          {`${props.location.place}. ${props.issueName}`}
        </div>
      </Responsive>
    </div>
  );
};

export default ItemLocation;
