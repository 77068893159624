const itemSerializersMap = {
  need: {
    supply: needSupplyToJSON,
    service: needServiceToJSON,
  },
  offer: {
    discount: offerDiscountToJSON,
    supply: offerSupplyToJSON,
    service: offerServiceToJSON,
    information: offerInformationToJSON,
  },
};

function itemToJSON(data) {
  const serializer = itemSerializersMap[data.side][data.kind];
  const tag_item = [];
  data.age && tag_item.push(parseInt(data.age));
  data.sex && tag_item.push(parseInt(data.sex));
  data.size && tag_item.push(parseInt(data.size));
  const itemData = {...data, tag_item};
  return serializer(itemData);
}

function needSupplyToJSON(data) {
  return {
    name: data.name || null,
    name_edit: data.nameEdit,
    bundle: data.bundle,
    delivery_in_bundles: data.deliveryInBundles === 'preferred' ? true : false,
    quantity: data.quantity || null,
    size: data.size ? data.size : null,
    brand: data.brand || null,
    due_date: data.meetBy || null,
    is_pallet: data.acceptPalletDelivery,
    accepting_deliveries_hours_from: data.deliveryHoursFrom,
    accepting_deliveries_hours_to: data.deliveryHoursTo,
    description: data.description || null,
    warehouse_delivery: data.deliveryToWarehouse,
    base_uom_id: data.baseUom ? parseInt(data.baseUom) : null,
    contact_name: data.contactName,
    image_id: data.image ? data.image.id : null,
    location_id: data.defaultShippingAddress,
    category_id: data.category ? parseInt(data.category) : null,
    tag_item: data.tag_item,
    phone_number: data.phoneNumber,
    contact_email: data.contactEmail,
    beneficiaries: data.individualsQuantity
      ? [
          {
            id: parseInt(data.beneficiariesId),
            uom: parseInt(data.beneficiaries),
            quantity: parseInt(data.individualsQuantity),
          },
        ]
      : [],
    market_value: data.marketValue || null,
  };
}

function needServiceToJSON(data) {
  return {
    name: data.name || null,
    name_edit: data.nameEdit,
    quantity: data.quantity || null,
    job_type: data.jobType || null,
    meetings_requirements: data.meetingsRequirements || null,
    deliverable: data.deliverable || null,
    due_date: data.meetBy || null,
    description: data.description || null,
    contact_name: data.contactName,
    image_id: data.image ? data.image.id : null,
    base_uom_id: data.baseUom ? parseInt(data.baseUom) : null,
    category_id: data.category ? parseInt(data.category) : null,
    skills: data.tags || null,
    market_value: data.marketValue || null,
    tag_item: data.tag_item,
    phone_number: data.phoneNumber,
    contact_email: data.contactEmail,
    beneficiaries: data.individualsQuantity
      ? [
          {
            id: parseInt(data.beneficiariesId),
            uom: parseInt(data.beneficiaries),
            quantity: parseInt(data.individualsQuantity),
          },
        ]
      : [],
    location_id: data.defaultShippingAddress,
  };
}

function offerServiceToJSON(data) {
  return {
    name: data.name || null,
    name_edit: data.nameEdit,
    quantity: data.quantity || null,
    job_type: data.jobType || null,
    due_date: data.availableUntil || null,
    description: data.description || null,
    base_uom_id: data.baseUom ? parseInt(data.baseUom) : null,
    category_id: data.category ? parseInt(data.category) : null,
    skills: data.tags || null,
    phone_number: data.phoneNumber,
    contact_email: data.contactEmail,
    item_type: data.itemType,
    market_value: data.marketValue || null,
    contact_name: data.contactName,
    image_id: data.image ? data.image.id : null,
    available_for: data.availableFor,
    currency: data.currency || null,
    location_id: data.defaultShippingAddress,
    tag_item: data.tag_item,
  };
}

function offerDiscountToJSON(data) {
  return {
    name: data.name || null,
    name_edit: data.nameEdit,
    category_id: data.category ? parseInt(data.category) : null,
    percentage: data.percentage || null,
    code: data.textCode || null,
    due_date: data.availableUntil || null,
    available_for: data.availableFor,
    external_url: data.externalURL || null,
    description: data.description || null,
    phone_number: data.phoneNumber,
    contact_email: data.contactEmail,
    contact_name: data.contactName,
  };
}

function offerSupplyToJSON(data) {
  return {
    name: data.name || null,
    name_edit: data.nameEdit,
    base_uom_id: data.baseUom ? parseInt(data.baseUom) : null,
    bundle: data.bundle,
    delivery_in_bundles: data.deliveryInBundles === 'preferred' ? true : false,
    size: data.size ? data.size : null,
    brand: data.brand || null,
    due_date: data.availableUntil || null,
    is_pallet: data.isPallet,
    special_handling: data.specialHandlingValue || null,
    custom_special_handling: data.customSpecialHandlingValue || null,
    cover_shipping_cost: data.coverShippingCost,
    market_value: data.marketValue || null,
    contact_name: data.contactName,
    image_id: data.image ? data.image.id : null,
    available_for: data.availableFor,
    location_id: data.pickupLocation,
    description: data.description || null,
    quantity: data.quantity || null,
    category_id: data.category ? parseInt(data.category) : null,
    phone_number: data.phoneNumber,
    contact_email: data.contactEmail,
    item_type: data.itemType,
    currency: data.currency || null,
    tag_item: data.tag_item,
  };
}

function offerInformationToJSON(data) {
  return {
    name: data.name || null,
    name_edit: data.nameEdit,
    category_id: data.category ? parseInt(data.category) : null,
    market_value: data.marketValue || null,
    description: data.additionalInformation || null,
    contact_email: data.contactEmail || null,
    contact_name: data.contactName,
    phone_number: data.phoneNumber,
    url: data.url || null,
    image_id: data.image ? data.image.id : null,
    files: data.files ? data.files : null,
    due_date: data.availableUntil || null,
    available_for: data.availableFor,
    currency: data.currency || null,
  };
}

function claimToJSON(data) {
  return {
    submitted_by: data.submittedBy || null,
    phone_number: data.phoneNumber,
    status: data.status || null,
    // TODO change items for claimed_item
    items: [
      {
        currency: data.currency,
        comment: data.additionalInformation,
        bundle: data.bundle,
        delivery_options: data.deliveryOptions,
        quantity: data.quantity,
        market_value: data.marketValue || null,
        pickup_location: data.pickupLocation || null,
        item: data.item,
        related_claim_item: data.relatedClaimItem,
        // Only send true/false/null
        cover_shipping_costs:
          typeof data.coverCosts === 'boolean' ? data.coverCosts : null,
      },
    ],
    beneficiaries:
      data.side !== 'need'
        ? [
            {
              quantity: parseInt(data.individualsQuantity),
            },
          ]
        : [],
  };
}

function claimItemToJSON(data) {
  return {
    status: data.confirmationStatus,
    confirmation_comment: data.confirmationComment,
    images: data.images,
  };
}

function InvitationToJSON(data) {
  return {
    email: data.email || null,
    first_name: data.firstName || null,
    last_name: data.lastName || null,
    invitation_type: data.invitationType,
    organization_name: data.organizationName || null,
    organization_id: data.organizationId || null,
    language_id: data.languageId || null,
  };
}

export {itemToJSON, claimToJSON, claimItemToJSON, InvitationToJSON};
