function getGoogleAutocompleteResults(input) {
  const autocompleteService =
    new window.google.maps.places.AutocompleteService();
  return new Promise((resolve, reject) => {
    const handlePredictionResults = (predictions, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        reject('Error when fetching locations from google');
      }

      resolve(predictions);
    };

    autocompleteService.getPlacePredictions(
      {input, types: ['geocode']},
      handlePredictionResults,
    );
  });
}

function getGooglePlaceDetails(placeId) {
  const google = window.google;
  const htmlMap = document.createElement('div');

  const map = new google.maps.Map(htmlMap, {});
  const service = new google.maps.places.PlacesService(map);
  return new Promise((resolve, reject) => {
    const handleServiceDetail = (details, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        reject('Error when fetching locations from google');
      }

      resolve(details);
    };

    service.getDetails({placeId}, handleServiceDetail);
  });
}

async function getNormalizedPlaceDetails(place) {
  if (place.types.includes('street_address')) {
    const {address_components} = place;

    const city = address_components.find((component) =>
      component.types.includes('locality'),
    );
    const state = address_components.find((component) =>
      component.types.includes('administrative_area_level_1'),
    );
    const country = address_components.find((component) =>
      component.types.includes('country'),
    );

    const cityResults = await getGoogleAutocompleteResults(
      `${city && city.long_name}, ${state && state.long_name}, ${
        country && country.long_name
      }`,
    );
    const normalizedPlace = await getGooglePlaceDetails(
      cityResults[0].place_id,
    );
    const normalizedCity = normalizedPlace.address_components.find(
      (component) => component.types.includes('locality'),
    );
    const normalizedState = normalizedPlace.address_components.find(
      (component) => component.types.includes('administrative_area_level_1'),
    );

    const normalizedAddressComponents = place.address_components.filter(
      (component) => {
        return (
          !component.types.includes('locality') &&
          !component.types.includes('administrative_area_level_1')
        );
      },
    );
    normalizedAddressComponents.push(normalizedCity);
    normalizedAddressComponents.push(normalizedState);

    place.address_components = normalizedAddressComponents;
  }

  return place;
}

const parseGeocodeResult = (result) => {
  return result.address_components.reduce(
    (accum, component) => {
      const ct = component.types;

      if (!accum.number && ct.indexOf('street_number') !== -1) {
        accum.number = component.long_name;
      }
      if (!accum.street && ct.indexOf('route') !== -1) {
        accum.street = component.long_name;
      }

      if (!accum.state && ct.indexOf('administrative_area_level_1') !== -1) {
        accum.state = component.long_name;
        accum.state_short = component.short_name;
      }

      if (!accum.city && ct.indexOf('administrative_area_level_2') !== -1) {
        accum.city = component.long_name;
        accum.city_short = component.short_name;
      }

      if (!accum.city && ct.indexOf('locality') !== -1) {
        accum.city = component.long_name;
        accum.city_short = component.short_name;
      }

      if (!accum.country && ct.indexOf('country') !== -1) {
        accum.country = component.long_name;
        accum.country_short = component.short_name;
      }
      if (!accum.postal_code && ct.indexOf('postal_code') !== -1) {
        accum.postal_code = component.long_name;
      }
      return accum;
    },
    {
      location: `${result.geometry.location.lat()},${result.geometry.location.lng()}`,
      place_id: `${result.place_id}`,
    },
  );
};

export {
  getGoogleAutocompleteResults,
  getGooglePlaceDetails,
  getNormalizedPlaceDetails,
  parseGeocodeResult,
};
