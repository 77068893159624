import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Dropdown} from 'react-bootstrap';
import styles from './DotMenu.module.scss';

const Trigger = React.forwardRef((props, ref) => (
  <div ref={ref} {...props}>
    <FontAwesomeIcon className={styles.dots} icon="ellipsis-v" />
  </div>
));

const DotMenu = (props) => {
  return (
    <Dropdown className={styles.wrapper}>
      <Dropdown.Toggle as={Trigger} className={styles.menuDots} />
      <Dropdown.Menu alignRight>{props.children}</Dropdown.Menu>
    </Dropdown>
  );
};

export default DotMenu;
