import React from 'react';
import {useField} from 'formik';

import {useRemoveError, useTranslatedError} from '../../../hooks/formik';

import Label from '../../Form/Label';
import ReadOnlyField from '../ReadOnlyField';
import TextArea from '../../Form/TextArea';
import styles from './TextAreaField.module.scss';

const TextAreaField = (props) => {
  const [field, meta] = useField(props);
  const {removeError} = useRemoveError(field.name, field.onChange);
  const {label, readOnly, showCharCounter, isRequired, ...rest} = props;
  const error = useTranslatedError(meta.error);

  if (readOnly) {
    if (!field.value) return null;

    return <ReadOnlyField label={label} value={field.value} />;
  }

  return (
    <React.Fragment>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <TextArea
        {...field}
        {...rest}
        error={error}
        onChange={removeError}
        isInvalid={!!meta.error && meta.touched}
      />
      {showCharCounter && (
        <div className={styles.counterWrapper}>
          <div>{`${field.value.length} / ${props.maxLength}`}</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TextAreaField;
