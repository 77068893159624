import ReactDOM from 'react-dom';
import {useSearchStore} from '../../contexts/SearchContext';

const HelpStep = ({children, parent, step}) => {
  const [searchState] = useSearchStore();
  if (!parent.current || searchState.tooltipStep !== step) {
    return null;
  }
  return ReactDOM.createPortal(children, parent.current);
};

export default HelpStep;
