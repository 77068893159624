import React, {useEffect} from 'react';
import {Form} from 'react-bootstrap';
import {useHistory, useLocation} from 'react-router-dom';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {parsePath} from '../../utils';
import {useApi} from '../../contexts/ApiContext';
import IssueSelect from './IssueSelect';
import Step from './Step';
import Radio from '../../components/Form/Radio';
import Button from '../../components/Button';
import ItemName from './ItemName';
import TemplateSelect from './TemplateSelect';
import {usePageStore} from '../../contexts/PageContext';
import styles from './AddOffersForm.module.scss';
import {createTranslationKey} from '../../utils/i18n';

const AddOffersForm = (props) => {
  const {t} = useTranslation('add-items');
  const [, pageActions] = usePageStore();
  const queryStringParams = new URLSearchParams(props.location.search);
  const selectedIssueSlug = queryStringParams.get('selected_issue');
  const {values, setFieldValue, handleSubmit, isSubmitting} = props;

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'add-offers'});
  }, [pageActions]);

  useEffect(() => {
    if (selectedIssueSlug) {
      const fetchSelectedIssue = async () => {
        const selectedIssue = await props.api.getIssue(selectedIssueSlug);
        setFieldValue('issue', selectedIssue);
      };

      fetchSelectedIssue();
    }
  }, [selectedIssueSlug, setFieldValue, props.api]);

  const offerOptions = [
    {
      label: t('add-offers.step-2.offer-options.supply'),
      value: 'supply',
    },
    {
      label: t('add-offers.step-2.offer-options.information'),
      value: 'information',
    },
    {
      label: t('add-offers.step-2.offer-options.service'),
      value: 'service',
    },
    {
      label: t('add-offers.step-2.offer-options.discount'),
      value: 'discount',
    },
  ];

  const handleOfferKindChange = (event) => {
    if (event.target.value === 'catalog') {
      return;
    } else {
      setFieldValue('offerKind', event.target.value);
      setFieldValue('offers', []);
    }
  };

  const offerOption =
    values.offerKind === 'discount' || values.offerKind === 'information' ? (
      <div className={styles.fieldOffers}>
        <ItemName
          placeholder={t('add-offers.step-2.placeholder-discount')}
          name="offers"
        />
      </div>
    ) : (
      <TemplateSelect
        name="offers"
        selectedTemplates={values.offers}
        placeholder={t(`add-offers.step-2.placeholder-${values.offerKind}`)}
        kinds={[values.offerKind]}
        side="offer"
      />
    );

  return (
    <React.Fragment>
      <Form className={styles.form} onSubmit={handleSubmit} noValidate>
        <IssueSelect showOnGoingIssue name="issue" />
        <Step title={t('add-offers.step-2.title')}>
          <div className={styles.fieldOfferKind}>
            <Radio
              name="offerKind"
              isHorizontal
              onChange={handleOfferKindChange}
              value={values.offerKind}
              options={offerOptions}
            />
          </div>
          {offerOption}
        </Step>
        <div className={styles.footer}>
          <Button
            type="submit"
            disabled={isSubmitting}
            variant="outline-primary"
            className="text-uppercase">
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}

            {t('add-offers.next')}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

const EnhancedForm = withFormik({
  displayName: 'AddOffersForm',
  mapPropsToValues: (props) => {
    return {
      issue: '',
      offerKind: 'supply',
      offers: [],
    };
  },
  validationSchema: yup.object().shape({
    issue: yup
      .object()
      .required(createTranslationKey('errors.add-offers.issue')),
    offerKind: yup.string().required(),
    offers: yup
      .array()
      .min(1, createTranslationKey('errors.add-offers.offers')),
  }),
  handleSubmit: async (values, {props}) => {
    const offers = values.offers.map((offer) => ({
      name: offer.value,
      item_template_id: offer.id || null,
      side: 'offer',
      kind: values.offerKind,
    }));

    const issueResponse = {
      issue: values.issue.id,
      name: values.issue.name,
      items: offers,
    };
    const response = await props.api.createIssueResponse(issueResponse);
    let path;
    if (values.offers.length > 1) {
      path = parsePath('/issue-response/:issueResponseId/:side', {
        issueResponseId: response.id,
        side: 'offers',
      });
    } else {
      path = parsePath('/issue-response/:issueResponseId/:side/:itemId', {
        issueResponseId: response.id,
        side: 'offers',
        itemId: response.items[0].id,
      });
    }
    return props.history.push(path);
  },
})(AddOffersForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const history = useHistory();
  const location = useLocation();
  const api = useApi();
  return (
    <EnhancedForm location={location} api={api} history={history} {...props} />
  );
};

export default observer(FormWrapper);
