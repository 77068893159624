import React from 'react';
import {useTranslation} from 'react-i18next';
import {useApi} from '../../contexts/ApiContext';
import {MANAGE_ITEMS_PAGE} from '../../urls';
import TemplateEdit from '../../components/TemplateEdit/TemplateEdit';

const CustomItemEdit = () => {
  const api = useApi();
  const {t} = useTranslation('forms');

  const getItemById = (id) => api.getCustomItemById(id);

  const updateTranslationStatus = (id, lang, method, values) =>
    api.updateCustomItemTranslation(id, lang, method, values);

  return (
    <TemplateEdit
      title={t('titles.edit-user-post')}
      kind="item"
      breadcrumbsKey={'edit-user-post'}
      getItemById={getItemById}
      updateTranslationStatus={updateTranslationStatus}
      previousPage={MANAGE_ITEMS_PAGE}
    />
  );
};

export default CustomItemEdit;
