import React, {useState, useEffect} from 'react';

import {withFormik} from 'formik';
import * as yup from 'yup';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import {Col} from 'react-bootstrap';
import Input from '../Form/Input';
import Label from '../Form/Label';
import Radio from '../Form/Radio';
import TextArea from '../Form/TextArea';
import Form from '../ItemForm/Form';
import Footer from '../ItemForm/Footer';
import Button from '../Button';

import {useStores} from '../../contexts/StoresContext';
import {getQuantityLabel} from '../../utils/form';
import {useErrorFocus, useTranslatedErrors} from '../../hooks/formik';
import {marketValueValidation} from '../../utils/yupCustomFunctions';
import {normalizeFloatNumber} from '../../utils';
import AddressSelectField from '../Fields/AddressSelectField';

const NeedSupplyClaimForm = (props) => {
  const {t} = useTranslation('forms');
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    item,
    maxQuantity,
  } = props;

  const deliveryOptions = [
    {
      label: t('fields.ship'),
      value: 'ship',
    },
    {
      label: t('fields.drop-off'),
      value: 'drop_off',
    },
    {
      label: t('fields.pick-up'),
      value: 'pick_up',
    },
  ];

  const [primaryAddress] = useState('');
  const focusOnErrorFields = ['quantity', 'phoneNumber'];
  const fieldRefs = {
    quantity: null,
    phoneNumber: null,
    marketValue: null,
  };

  useEffect(() => {
    setFieldValue('maxQuantity', maxQuantity);
  }, [maxQuantity, setFieldValue]);

  const translatedErrors = useTranslatedErrors(errors);
  useErrorFocus(isSubmitting, errors, focusOnErrorFields, fieldRefs);
  const uom = item.deliveryInBundles ? item.bundle.uom.name : item.baseUom.name;

  const setRef = (fieldName) => (ref) => (fieldRefs[fieldName] = ref);

  const handleDeliveryOptionsChange = (event) => {
    const choice = event.target.value;
    setFieldValue('deliveryOptions', choice);
    if (choice === 'ship' || choice === 'drop_off') {
      setFieldValue('pickupLocation', '');
    } else {
      setFieldValue('pickupLocation', primaryAddress);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>
            {t('labels.supply.need.item-claim-quantity')}
          </Label>
          <Input
            name="quantity"
            onChange={handleChange}
            value={values.quantity}
            placeholder={t('labels.item-quantity-placeholder')}
            isInvalid={!!errors.quantity && touched.quantity}
            inlineLabel={getQuantityLabel(
              item.baseUom,
              item.bundle,
              item.deliveryInBundles,
            )}
            error={translatedErrors.quantity}
            setRef={setRef('quantity')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('labels.total-value', {uom: uom})}</Label>
          <Input
            name="marketValue"
            onChange={handleChange}
            value={values.marketValue}
            placeholder={t('labels.total-value-placeholder')}
            isInvalid={!!errors.marketValue && touched.marketValue}
            inlineLabel={`${
              props.stores.app.currency.code
            } / ${uom.toUpperCase()}`}
            error={translatedErrors.marketValue}
            setRef={setRef('marketValue')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('labels.supply.need.item-phone-number')}</Label>
          <Input
            name="phoneNumber"
            onChange={handleChange}
            value={values.phoneNumber}
            isInvalid={!!errors.phoneNumber && touched.phoneNumber}
            error={translatedErrors.phoneNumber}
            setRef={setRef('phoneNumber')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>
            {t('labels.supply.need.item-delivery-options')}
          </Label>
          <Radio
            name="deliveryOptions"
            options={deliveryOptions}
            onChange={handleDeliveryOptionsChange}
            value={values.deliveryOptions}
            isInvalid={!!errors.deliveryOptions && touched.deliveryOptions}
            error={translatedErrors.deliveryOptions}
          />
        </Form.Group>
      </Form.Row>
      {values.deliveryOptions === 'pick_up' && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <AddressSelectField
              name="pickupLocation"
              label={t('labels.supply.need.item-pick-up-location')}
              // readOnly={isDone || isInProgress}
              isFullWidth={false}
              notEditAddress
              notDeleteAddress
            />
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label>{t('labels.item-description')}</Label>
          <TextArea
            rows="6"
            name="additionalInformation"
            onChange={handleChange}
            placeholder={t(
              'labels.supply.need.item-description-claim-placeholder',
            )}
            value={values.additionalInformation}
            isInvalid={
              !!errors.additionalInformation && touched.additionalInformation
            }
            error={translatedErrors.additionalInformation}
          />
        </Form.Group>
      </Form.Row>
      <Footer>
        <Button type="submit" variant="primary" className="text-uppercase">
          {t('buttons.need.meet-need')}
        </Button>
      </Footer>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'ItemSupplyClaimForm',
  mapPropsToValues: (props) => {
    const {item, relatedItem} = props;

    return {
      quantity: '',
      bundle: item.bundle.id,
      phoneNumber: props.stores.app.user.phone_number || '',
      deliveryOptions: '',
      additionalInformation: '',
      pickupLocation: '',
      item: item.id,
      maxQuantity: null,
      marketValue: relatedItem
        ? relatedItem.marketValue
        : item.marketValue || '',
    };
  },
  validationSchema: yup.object().shape({
    remainingQuantity: yup.number(),
    quantity: yup
      .number()
      .required()
      .positive()
      .label('quantity')
      .max(yup.ref('maxQuantity')),
    marketValue: marketValueValidation().required().label('market-value'),
    bundle: yup.string().required(),
    phoneNumber: yup.string().required(),
    deliveryOptions: yup.string().required(),
    additionalInformation: yup.string(),
    pickupLocation: yup.string().when('deliveryOptions', {
      is: 'pick_up',
      then: yup.string().required(),
    }),
  }),
  handleSubmit: (values, {props}) => {
    const data = {
      ...values,
      marketValue: normalizeFloatNumber(values.marketValue),
    };

    props.onSubmit(data);
  },
})(NeedSupplyClaimForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  return <EnhancedForm stores={stores} {...props} />;
};

export default observer(FormWrapper);
