import {getLocalToken} from './auth';
import {
  issueResponseFromJSON,
  itemFromJSON,
  itemFilterFromJSON,
  itemTemplateFromJSON,
  claimItemFromJSON,
  itemCountFromJSON,
} from './utils/deserializers';
import {
  itemToJSON,
  claimItemToJSON,
  claimToJSON,
  InvitationToJSON,
} from './utils/serializers';
import {LOGIN_PAGE} from './urls';

const API_URL = '/api/v1';

function APICallError(err) {
  this.__json__ = err;
}

function NonFieldError(errors) {
  this.non_field_errors = errors;
}

class Api {
  constructor(stores) {
    this.stores = stores;
    this.token = stores.app.token;
    this.dispatch = null;
  }

  async request(method, path, data) {
    const options = {
      method: method,
      headers: this.getHeaders(),
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    try {
      const res = await fetch(`${API_URL}${path}`, options);
      if (res.status >= 200 && res.status < 300) {
        return res.status === 204 ? null : res.json();
      } else {
        const err = await res.json();
        throw new APICallError(err);
      }
    } catch (err) {
      const error_auth = err.__json__
        ? err.__json__.code === 'authentication_failed' ||
          err.__json__.code === 'permission_denied'
        : false;
      if (error_auth) {
        await this.logout();
        this.stores.app.logout();
        window.location.replace(LOGIN_PAGE);

        throw new NonFieldError(['Invalid session']);
      } else if (err.__json__) {
        this.showError(err.__json__);
        throw err.__json__;
      } else {
        const errorMessage = err.toString();
        this.showError(errorMessage);
        throw new NonFieldError([errorMessage]);
      }
    }
  }

  showError(errorMessage) {
    let errorSubtitle =
      'Oops, it looks like something went wrong at our end. Please try again later. ';

    if (process.env.NODE_ENV !== 'production') {
      errorSubtitle += errorMessage;
    }

    const toastId = this.stores.app.addToast({
      type: 'alert',
      title: 'SOMETHING WENT WRONG',
      subtitle: errorSubtitle,
    });

    setTimeout(() => this.stores.app.removeToast(toastId), 5000);
  }

  post(path, data) {
    return this.request('post', path, data);
  }

  put(path, data) {
    return this.request('put', path, data);
  }

  patch(path, data) {
    return this.request('PATCH', path, data);
  }

  delete(path, data) {
    return this.request('delete', path, data);
  }

  getQs(params) {
    return Object.keys(params || {}).reduce((accum, key) => {
      const value = params[key];
      if (typeof value == 'undefined') {
        return accum;
      }
      if (Array.isArray(value)) {
        value.forEach((v) => accum.append(key, v));
      } else {
        accum.append(key, value);
      }
      return accum;
    }, new URLSearchParams());
  }

  get(path, params) {
    const qs = this.getQs(params);
    return this.request('get', params ? `${path}?${qs}` : path);
  }

  getHeaders() {
    const headers = {
      'Content-Type': 'application/json',
    };

    const token = getLocalToken();

    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return headers;
  }

  parseResponse(response, parser) {
    return {
      results: response.results.map(parser),
      count: response.count,
    };
  }

  /**
   * Public
   */
  getSession(token) {
    return this.post(`/auth/tokens/session/`, {
      token: token,
    });
  }

  getPublicSession() {
    return this.get(`/auth/public/`);
  }

  login(user, password) {
    return this.post(`/auth/tokens/`, {
      username: user,
      password: password,
    });
  }

  logout() {
    return Promise.resolve();
  }

  login_auth0(auth0_token) {
    return this.post(`/auth/tokens_auth0/`, {
      auth0_token: auth0_token,
    });
  }

  getOrgs(queryString) {
    return this.get('/org/', queryString);
  }

  createSavedView(queryString) {
    return this.post('/report/new-saved-view/', queryString);
  }

  getSavedViews() {
    return this.get('/report/get-saved-views/');
  }

  updateSavedView(queryString) {
    return this.put('/report/update-saved-view/', queryString);
  }

  deleteSavedView(queryString) {
    return this.delete('/report/delete-saved-view/', queryString);
  }

  getItemCategoriesFilters(queryString) {
    return this.get('/item-categories-filters/', queryString);
  }

  getOrgsCountries() {
    return this.get('/org-countries/');
  }

  getOrgInitials() {
    return this.get('/org-initials/');
  }

  getOrg(slug) {
    return this.get(`/org/${slug}/`);
  }

  updateOrg(slug, queryString) {
    return this.put(`/org/${slug}/`, queryString);
  }

  createOrg(org) {
    return this.post('/org/', org);
  }

  getIpLocation() {
    return this.get('/ip-location/');
  }

  getAvailableForOptions() {
    return this.get('/items/availableforoptions/');
  }

  async getItemTemplates(search, kind) {
    const params = {};
    let response = null;

    if (search) {
      params.search = search;
    }

    if (kind) {
      params.kind = kind;
    }

    if (params) {
      response = await this.get(`/item-template/`, params);
    } else {
      response = await this.get(`/item-template/`);
    }

    return this.parseResponse(response, itemTemplateFromJSON);
  }
  getItemTemplateCatalog(queryString) {
    return this.get('/item-template-catalog/', queryString);
  }
  getItemTemplateCatalogById(id) {
    return this.get(`/item-template-catalog/${id}/`);
  }
  updateItemTemplateCatalog(id, values) {
    return this.put(`/item-template-catalog/${id}/`, values);
  }
  updateItemTemplateTranslation(id, lang, method, values) {
    return this.put(`/item-template-catalog/${id}/${lang}/${method}/`, values);
  }
  deleteItemTemplateCatalog(id) {
    return this.delete(`/item-template-catalog/${id}/`);
  }

  getCustomItems(queryString) {
    return this.get('/custom-items/', queryString);
  }

  getCustomItemById(id) {
    return this.get(`/custom-items/${id}/`);
  }

  updateCustomItem(id, values) {
    return this.put(`/custom-items/${id}/`, values);
  }
  updateCustomItemTranslation(id, lang, method, values) {
    return this.put(`/custom-items/${id}/${lang}/${method}/`, values);
  }
  deleteCustomItem(id) {
    return this.delete(`/custom-items/${id}/`);
  }

  getIssues(queryString) {
    return this.get(`/issue/`, queryString);
  }
  getIssueTemplateCatalog(queryString) {
    return this.get('/issue-template-catalog/', queryString);
  }
  getIssueTemplateCatalogById(id) {
    return this.get(`/issue-template-catalog/${id}/`);
  }

  updateIssueTemplateCatalog(id, values) {
    return this.put(`/issue-template-catalog/${id}/`, values);
  }
  updateIssueTranslation(id, lang, method, values) {
    return this.put(`/issue-template-catalog/${id}/${lang}/${method}/`, values);
  }
  deleteIssueTemplateCatalog(id) {
    return this.delete(`/issue-template-catalog/${id}/`);
  }

  getIssuesCountries() {
    return this.get(`/issue-countries/`);
  }

  getIssueCategories(queryString) {
    return this.get(`/issue-category/`, queryString);
  }

  getIssueRelatedOrgs(issueId) {
    return this.get(`/issue/${issueId}/organizations/`);
  }

  getOrgUsers(queryString) {
    return this.get(`/auth/`, queryString);
  }

  getOrgUsersCsv(queryString) {
    const qs = this.getQs(queryString);
    const options = {
      method: 'get',
      headers: this.getHeaders(),
    };
    return fetch(`${API_URL}/auth/export/?${qs}`, options);
  }

  getOrgsCsv() {
    const options = {
      method: 'get',
      headers: this.getHeaders(),
    };
    return fetch(`${API_URL}/org-export/`, options);
  }

  getBrowseOrgsCsv(queryString) {
    const qs = this.getQs(queryString);
    const options = {
      method: 'get',
      headers: this.getHeaders(),
    };
    return fetch(`${API_URL}/org-browse-export/?${qs}`, options);
  }

  async getIssueResponse(issueResponseId) {
    const response = await this.get(`/issue-response/${issueResponseId}/`);
    return issueResponseFromJSON(response);
  }

  async getItem(itemId) {
    const response = await this.get(`/item/${itemId}/`);
    return itemFromJSON(response);
  }

  pauseToggleItem(itemId) {
    return this.put(`/item/${itemId}/pause-toggle/`);
  }

  updateItem(item) {
    return this.put(`/item/${item.id}/`, itemToJSON(item));
  }

  updateItemQuantity(item) {
    return this.put(`/item-quantity/${item.id}/`, itemToJSON(item));
  }

  deleteItem(itemId) {
    return this.put(`/item/${itemId}/delete/`);
  }

  async getItems(queryString) {
    const response = await this.get(`/item/`, queryString);
    return this.parseResponse(response, itemFromJSON);
  }

  async getItemsShort(queryString) {
    const response = await this.get(`/item-short/`, queryString);
    return response;
  }

  getTags(queryString) {
    return this.get(`/tag/`, queryString);
  }

  async countItems(queryString) {
    const response = await this.get(`/item/count/`, queryString);
    return itemCountFromJSON(response);
  }

  async getItemFilters(queryString) {
    const response = await this.get(`/item-filter/`, queryString);
    return this.parseResponse(response, itemFilterFromJSON);
  }

  async getClaimedItems(queryString) {
    const response = await this.get(`/item-claimed/`, queryString);
    return this.parseResponse(response, itemFromJSON);
  }

  createItemClaim(claim) {
    return this.post(`/claim/`, claimToJSON(claim));
  }

  createIssueResponse(issueResponse) {
    return this.post(`/issue-response/`, issueResponse);
  }

  submitIssueResponse(issueResponseId) {
    return this.post(`/issue-response/${issueResponseId}/submit`);
  }

  getAddresses() {
    return this.get(`/address/`);
  }

  getReportUsersAndMembers(queryString) {
    return this.get('/org/reports/summary-users-members/', queryString);
  }

  getAddress(addressId) {
    return this.get(`/address/${addressId}/`);
  }

  createAddress(address) {
    return this.post(`/address/`, address);
  }

  updateAddress(addressId, address) {
    return this.put(`/address/${addressId}/`, address);
  }

  deleteAddress(addressId) {
    return this.delete(`/address/${addressId}/`);
  }

  createMediaImage(mediaImage) {
    return this.post('/media-image/', mediaImage);
  }

  createClaimItem(data) {
    return this.post(`/claim-item/`, data);
  }

  // @FIXME remove this[?]
  createClaim(data) {
    return this.post(`/claim/`, data);
  }

  async getClaimItems(itemId, data) {
    const response = await this.get(`/item/${itemId}/claim-item/`, data);
    return this.parseResponse(response, claimItemFromJSON);
  }

  async getClaimItem(itemId) {
    const response = await this.get(`/claim-item/${itemId}/`);
    return claimItemFromJSON(response);
  }

  async updateClaimItem(data) {
    const response = await this.patch(
      `/claim-item/${data.claimId}/`,
      claimItemToJSON(data),
    );
    return claimItemFromJSON(response);
  }

  getIssue(slug) {
    return this.get(`/issue/${slug}/`);
  }

  uploadImage(image) {
    return this.post(`/media-image/`, image);
  }

  getImage(imageId) {
    return this.get(`/media-image/${imageId}/`);
  }

  uploadFile(file) {
    return this.post(`/media-file/`, file);
  }

  getGcloudStaticFiles(queryString) {
    return this.get(`/static-files/`, queryString);
  }

  createNewUser(data) {
    return this.post('/auth/register/', data);
  }

  verifyEmail(data) {
    return this.post(`/verify-email/`, data);
  }

  resendEmail(data) {
    return this.post(`/resend-email/`, data);
  }

  getTimeZones() {
    return this.get('/timezones/');
  }

  getLanguages() {
    return this.get('/languages/');
  }

  getClientCurrencies() {
    return this.get('/currency-list/');
  }

  getFocusAreas() {
    return this.get('/issue-category/');
  }

  getCountries() {
    return this.get('/countries/');
  }

  getUoms() {
    return this.get('/uom/');
  }

  getVettedByList() {
    return this.get('/vetted-by/');
  }

  getExtraAttributeFields(modelName) {
    /**
     * @FIXME remove this after refactoring `org_kind`
     */
    modelName =
      {
        fporg: 'needslist_organization.FPOrg',
        nporg: 'needslist_organization.NPOrg',
        govorg: 'needslist_organization.GOVOrg',
      }[modelName] || modelName;

    return this.get(`/extra-attribute/group/${modelName}/`);
  }

  validateExtraAttribute(modelName, data) {
    /**
     * @FIXME remove this after refactoring `org_kind`
     */
    modelName =
      {
        fporg: 'needslist_organization.FPOrg',
        nporg: 'needslist_organization.NPOrg',
        govorg: 'needslist_organization.GOVOrg',
      }[modelName] || modelName;

    return this.post(`/extra-attribute/validate/${modelName}/`, data);
  }

  createInvitation(invitation) {
    return this.post(`/auth/invitation/`, InvitationToJSON(invitation));
  }

  getInvitation(token) {
    return this.get(`/auth/invitation/${token}/`);
  }

  getInvitationToken(email) {
    return this.post(`/auth/invitation/token/`, email);
  }

  getInvitations(queryString) {
    return this.get('/auth/invitation/', queryString);
  }

  resendInvitation(queryString) {
    return this.post(
      `/auth/invitation/${queryString.token}/resend/`,
      InvitationToJSON(queryString),
    );
  }

  deleteInvitation(token) {
    return this.delete(`/auth/invitation/${token}/`);
  }

  forgotPassword(email) {
    return this.post('/auth/forgot-password/', email);
  }

  setNewPassword(queryString) {
    return this.post(`/auth/reset-password/`, queryString);
  }

  changePassword(queryString) {
    return this.post(`/auth/change-password/`, queryString);
  }

  requestJoin(queryString) {
    return this.post('/auth/request-access/', queryString);
  }

  getUserDetails(userUUID) {
    return this.get(`/auth/user-details/${userUUID}/`);
  }

  updateUserDetails(userUUID, data) {
    return this.put(`/auth/user-details/${userUUID}/`, data);
  }

  toggleUserStatus(userUUID) {
    return this.patch(`/auth/user-details/${userUUID}/toggle-status/`);
  }

  mobileAccess() {
    return this.post(`/auth/user-details/mobile-access/`);
  }

  acceptUserRequest(userUUID) {
    return this.patch(`/auth/user-details/${userUUID}/accept-request/`);
  }

  declineUserRequest(userUUID, data) {
    return this.post(`/auth/user-details/${userUUID}/decline-request/`, data);
  }

  getAdminEmail() {
    return this.get(`/auth/admin-email/`);
  }

  getOrgTotalNumberItemsReports(queryString) {
    return this.get(`/org/reports/total-number-items/`, queryString);
  }

  getSummaryForVisualizations(queryString) {
    return this.get(`/org/reports/summary-for-visualizations/`, queryString);
  }

  getOrgTotalNumberItemsReportsCSV(queryString) {
    const qs = this.getQs(queryString);
    const options = {
      method: 'get',
      headers: this.getHeaders(),
    };
    return fetch(
      `${API_URL}/org/reports/total-number-items/export/?${qs}`,
      options,
    );
  }

  getOrgTotalValueItemsReports(queryString) {
    return this.get(`/org/reports/total-value-items/`, queryString);
  }

  getOrgTotalValueItemsReportsCSV(queryString) {
    const qs = this.getQs(queryString);
    const options = {
      method: 'get',
      headers: this.getHeaders(),
    };
    return fetch(
      `${API_URL}/org/reports/total-value-items/export/?${qs}`,
      options,
    );
  }

  getOrgActivityReportsCSV(queryString) {
    const qs = this.getQs(queryString);
    const options = {
      method: 'get',
      headers: this.getHeaders(),
    };
    return fetch(`${API_URL}/org/reports/activity/export/?${qs}`, options);
  }

  async getOrgItemsReport(queryString) {
    const response = await this.get(`/org/reports/items/`, queryString);
    return this.parseResponse(response, itemFromJSON);
  }

  async getOrgClaimedItemsReport(queryString) {
    const response = await this.get(`/org/reports/claimed-items/`, queryString);
    return this.parseResponse(response, claimItemFromJSON);
  }

  getOrgTrendNumberReports(queryString) {
    return this.get(`/org/reports/trend-number/`, queryString);
  }

  getOrgTrendValueReports(queryString) {
    return this.get(`/org/reports/trend-value/`, queryString);
  }

  getOrgSummaryReports(queryString) {
    return this.get('/org/reports/summary/', queryString);
  }

  getClientData() {
    return this.get('/client/');
  }

  createClientInvitationLink() {
    return this.post('/client/invitation-link/');
  }

  getClientInvitationLink() {
    return this.get('/client/invitation-link/');
  }

  validateInvitationToken(invitationToken) {
    return this.post('/client/invitation-link/validate/', invitationToken);
  }
}

export {Api};
