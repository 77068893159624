import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/free-solid-svg-icons';

import {useApi} from '../../../contexts/ApiContext';
import styles from './ItemHeader.module.scss';

import {getInitials} from '../../../utils';
import Paragraph from '../../Paragraph/Paragraph';
import ResponsiveImg from '../../ResponsiveImg';
import DotMenu, {MenuItem} from '../../DotMenu';
import Dialog from '../../Dialog';
import Avatar from '../../../components/Avatar';

const ItemHeader = (props) => {
  const {item, menuItems, showPendingDelivery, isCard} = props;
  const api = useApi();
  const [showPendingLabel, setShowPendingLabel] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [itemName, setItemName] = useState('');
  const isOffer = item.side === 'offer';
  const {t} = useTranslation('item');
  const itemType = item.itemType || 'default';

  const handleToggleEditItemNameModal = () => setShowModal(!showModal);
  const renderMenuItems = (menuItem, index) => {
    const onClick = () => menuItem.onClick(item);
    const label =
      typeof menuItem.label === 'function'
        ? menuItem.label(item)
        : menuItem.label;
    return (
      <MenuItem onClick={onClick} key={index}>
        {label}
      </MenuItem>
    );
  };

  useEffect(() => {
    const fetchClaimItems = async () => {
      const response = await api.getClaimItems(item.id);
      const pendingItems = response.results.filter(
        (claim) => claim.confirmationStatus.key === 'submitted',
      );

      setShowPendingLabel(pendingItems.length !== 0);
    };

    if (showPendingDelivery) fetchClaimItems();
  }, [showPendingDelivery, item, api]);

  useEffect(() => {
    setItemName(item.name);
  }, [item.name]);

  const renderEditItemNameDialog = () => {
    const handleOnConfirm = async (value) => {
      const itemEdited = {
        name: value,
        nameEdit: true,
        id: item.id,
        side: item.side,
        kind: item.kind,
      };

      if (itemEdited.name) await api.updateItem(itemEdited);
      handleToggleEditItemNameModal();

      const response = await api.getItem(item.id);
      setItemName(response.name);
    };
    const handleOnCancel = async () => {
      handleToggleEditItemNameModal();
    };

    return (
      <Dialog
        header={t('edit-item-name.header')}
        editItemTitle={t('edit-item-name.title')}
        boldMessage={t('edit-item-name.message')}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
        confirmText={t('edit-item-name.confirm-button')}
        cancelText={t('edit-item-name.cancel-button')}
        noIcon="true"
        params={item}
        newItemName={itemName ? itemName : item.name}
        editModal
        textErrorValue={t('edit-item-name.error')}
      />
    );
  };

  const orgImage = item.organization ? (
    item.organization.logo ? (
      <ResponsiveImg src={item.organization.logo} />
    ) : item.organizationName ? (
      getInitials(item.organizationName)
    ) : null
  ) : null;

  return (
    <div className={props.className}>
      <div className={styles.wrapper}>
        <div className={styles.textWrapper}>
          <div
            className={`${styles.kind} ${
              isOffer ? styles.secondaryColor : styles.primaryColor
            }`}>
            {t(`title.${item.side}.${item.kind}.${itemType}`)}
          </div>
          {item.category && (
            <div className={styles.category}>{item.category.name}</div>
          )}
          <div className={styles.editContainer}>
            <Paragraph className={styles.name} text={itemName} lines={2} />
          </div>
          <div className={styles.tagContainer}>
            {item.tag &&
              item.tag.length !== 0 &&
              item.tag.map((tag) => (
                <div className={styles.tagItem} key={tag.name}>
                  {tag.name}
                </div>
              ))}
          </div>
          {showModal && renderEditItemNameDialog()}
          {item.textCode && (
            <div className={styles.code}>
              {t('code', {code: item.textCode})}
            </div>
          )}
          {showPendingLabel && (
            <p className={styles.deliveryPending}>
              {t('delivery-pending')}{' '}
              <FontAwesomeIcon className={styles.dot} icon={faCircle} />
            </p>
          )}
        </div>
        {/* {showTooltip && (
          <div className={styles.translateIconContainer}>
            <TranslationInProgressIcon
              className={styles.translateIcon}
              onClick={(e) => e.stopPropagation()}
            />
            <Tooltip title={tooltipInfo.title} className={styles.tooltip}>
              {tooltipInfo.text}{' '}
              <a href={`mailto:${stores.app.adminEmail}`}>
                {t('tooltip.questions')}
              </a>
            </Tooltip>
          </div>
        )} */}
        {menuItems && menuItems.length > 0 && (
          <div className={styles.menu}>
            <DotMenu>{menuItems.map(renderMenuItems)}</DotMenu>
          </div>
        )}
        {!isCard && (
          <Avatar className={styles.organizationLogo}>{orgImage}</Avatar>
        )}
      </div>
    </div>
  );
};

export default ItemHeader;
