import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Card, Row, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';

import Content from './Content';
import OrgProfile from './OrgProfile';
import {usePageStore} from '../../contexts/PageContext';
import Onboarding from './Onboarding';
import {useLocalStorageFlag} from '../../hooks/localstorage';
import {LOCAL_STORAGE_KEYS, mediaQueries, breakpoints} from '../../constants';
import Responsive from '../../components/Responsive';
import theme from '../../theme';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 0.5rem;
`;

const ContextualHelp = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const ContentWrapper = styled(Card)`
  padding: 0;

  .card-header-tabs {
    border-bottom: 1px solid #e8e8e8;
    margin: 0;
    padding-bottom: 0;
  }

  ${mediaQueries.lg} {
    &.card {
      background-color: transparent;
      border-style: none;
      padding: 0;
    }
  }
`;

const Wrapper = styled.div`
  margin-bottom: 180px;

  ${mediaQueries.lg} {
    margin-bottom: 30px;
  }
`;

const Manage = () => {
  const [, pageActions] = usePageStore();
  // TODO: Set default value to false so users that have cleaned up their local storage don't get
  // the onboarding again. Update once register is in place and we can initialize the key in true
  const [setShowOnboarding, showOnboarding] = useLocalStorageFlag(
    LOCAL_STORAGE_KEYS.manageOnboarding,
    true,
  );

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'manage'});
  }, [pageActions]);

  const handleToggleOnboarding = () => setShowOnboarding(!showOnboarding);

  return (
    <Wrapper>
      {showOnboarding && <Onboarding onClose={handleToggleOnboarding} />}
      <Row>
        <Col sm={9}>
          <TitleWrapper>
            <ContextualHelp
              icon={faQuestionCircle}
              color={theme.info}
              size="lg"
              onClick={handleToggleOnboarding}
            />
          </TitleWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={9}>
          <ContentWrapper>
            <Content />
          </ContentWrapper>
        </Col>
        <Responsive>
          <Col max={breakpoints.lg} sm={3}>
            <OrgProfile />
          </Col>
        </Responsive>
      </Row>
    </Wrapper>
  );
};

export default Manage;
