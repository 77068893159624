import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import ItemDataTitle from '../ItemForm/ItemDataTitle';
import ItemDataValue from '../ItemForm/ItemDataValue';

import {getMeetBy} from './utils';
import {useStores} from '../../contexts/StoresContext';
import UndisclosedField from './UndisclosedField';
import ItemDeliveryInformation from '../ItemDeliveryInformation/ItemDeliveryInformation';
import {useLocaleNumber} from '../../hooks/number';

const Info = styled.div`
  color: #454545;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
  display: flex;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  margin: 3px 5px 0 0;
`;

const OfferSupplyInformation = (props) => {
  const {item, relatedItem} = props;
  const stores = useStores();
  const {t} = useTranslation('forms');
  const localeNumber = useLocaleNumber();
  const specialHandling =
    stores.app.choices.specialHandling[item.specialHandlingValue];
  const customSpecialHandling = item.customSpecialHandlingValue;
  const unit = item.bundle.uom.name;
  const deliveryOptions =
    item.coverShippingCost !== null
      ? t('fields.we-can-deliver')
      : t('fields.pick-up-at-our-location');
  const noLabel = t('labels.no');
  const yesLabel = t('labels.yes');
  const showBundleInfo =
    item.bundle && item.bundle.uom.id !== 1 && item.bundle.units !== 1;
  const uom = showBundleInfo ? item.bundle.uom.name : item.baseUom.name;

  return (
    <React.Fragment>
      <Row>
        {item.dueDate && (
          <Col sm={6}>
            <ItemDataTitle>
              {t('labels.supply.offer.item-available-until')}
            </ItemDataTitle>
            <ItemDataValue>{getMeetBy(item)}</ItemDataValue>
          </Col>
        )}
        {item.customSpecialHandlingValue && (
          <Col sm={8}>
            <ItemDataTitle>
              {t('labels.supply.offer.item-special-handling')}
            </ItemDataTitle>
            <ItemDataValue>
              {customSpecialHandling
                ? customSpecialHandling
                : specialHandling || noLabel}
            </ItemDataValue>
          </Col>
        )}
      </Row>
      {(item.size || (relatedItem && relatedItem.size)) && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.supply.item-size')}</ItemDataTitle>
            <ItemDataValue>{item.size ? item.size : '-'}</ItemDataValue>
            {relatedItem && relatedItem.size && (
              <Info>
                <InfoIcon icon="info-circle" />
                <ItemDataValue>
                  {t('labels.supply.related-item-size', {
                    size: relatedItem.size,
                  })}
                </ItemDataValue>
              </Info>
            )}
          </Col>
        </Row>
      )}
      {/* {item.brand &&
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.supply.offer.item-brand')}</ItemDataTitle>
            <ItemDataValue>{item.brand}</ItemDataValue>
          </Col>
        </Row>
      } */}
      <Row>
        {showBundleInfo && (
          <Col sm={6}>
            <ItemDataTitle>{t('labels.supply.bundle')}</ItemDataTitle>
            <ItemDataValue>{`${item.bundle.units} x ${unit}`}</ItemDataValue>
          </Col>
        )}
        {item.isPallet && (
          <Col sm={6}>
            <ItemDataTitle>
              {t('labels.supply.offer.item-pallet')}
            </ItemDataTitle>
            <ItemDataValue>{item.isPallet ? yesLabel : noLabel}</ItemDataValue>
          </Col>
        )}
      </Row>
      {stores.app.user && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>
              {t('labels.supply.offer.item-delivery-options')}
            </ItemDataTitle>
            <ItemDataValue>{deliveryOptions}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.coverShippingCost !== null && stores.app.user && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>
              {t('labels.supply.offer.item-claim-cover-shipping-cost')}
            </ItemDataTitle>
            <ItemDataValue>
              {item.coverShippingCost ? yesLabel : noLabel}
            </ItemDataValue>
          </Col>
        </Row>
      )}
      {item.location && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>
              {t('labels.supply.offer.item-location-supplies')}
            </ItemDataTitle>
            <ItemDataValue>
              <ItemDeliveryInformation item={item} />
            </ItemDataValue>
          </Col>
        </Row>
      )}
      {item.marketValue && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>
              {t('labels.supply.offer.item-market-value', {uom: uom})}
            </ItemDataTitle>
            <ItemDataValue withoutTextStyle>
              {stores.app.currency.symbol} {localeNumber(item.marketValue)} (
              {stores.app.currency.code})
            </ItemDataValue>
          </Col>
        </Row>
      )}
      {item.description && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.item-description')}</ItemDataTitle>
            <ItemDataValue withoutTextStyle>{item.description}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.phoneNumber && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.phone-number')}</ItemDataTitle>
            <UndisclosedField item={item} field="phone-number" />
          </Col>
        </Row>
      )}
      {item.contactEmail && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.email')}</ItemDataTitle>
            <UndisclosedField item={item} field="email" />
          </Col>
        </Row>
      )}
      {item.contactName && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.contact-name')}</ItemDataTitle>
            <ItemDataValue>{item.contactName}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.user && item.user.timezone && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.timezone')}</ItemDataTitle>
            <ItemDataValue>{item.user.timezone}</ItemDataValue>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default observer(OfferSupplyInformation);
