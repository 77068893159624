import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from 'react';
import {Form, Col} from 'react-bootstrap';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import Checkbox from '../Form/Checkbox';
import Input from '../Form/Input';
import {mediaQueries} from '../../constants';
import FormContext from '../Form/FormContext';

const ChecksContainers = styled.div`
  display: flex;
`;

const StyledCheck = styled(Checkbox)`
  flex: 0.2;
`;

const StyledFormGroup = styled(Form.Group)`
  ${mediaQueries.lg} {
    margin-bottom: 0;
  }
`;

const MultipleChecks = (props) => {
  const {
    items,
    allowOther,
    allowNumeration,
    itemsSelected,
    onChange,
    onBlur,
    onOtherChange,
    otherValue,
    otherName,
    isFullWidth,
  } = props;
  const [collection, setCollection] = useState([]);
  const {t} = useTranslation('common');
  const otherId = useRef(`other${Math.random()}`);
  const perColumn = useMemo(
    () =>
      Math.ceil(
        (allowOther ? items.length + 1 : items.length) / (isFullWidth ? 1 : 2),
      ),
    [isFullWidth, items, allowOther],
  );
  const inputRef = useRef(null);
  const {setFieldRef} = useContext(FormContext);

  useEffect(() => {
    setFieldRef(props.name, inputRef);
  }, [props.name, setFieldRef]);

  const createChuck = useCallback((collectionItems, perCol) => {
    const itemsCollection = [];

    while (collectionItems.length) {
      itemsCollection.push(collectionItems.splice(0, perCol));
    }

    setCollection(itemsCollection);
  }, []);

  useEffect(() => {
    const itemsCopy = [...items];

    if (allowOther) {
      itemsCopy.push({
        id: otherId.current,
        isOther: true,
        label: t('other'),
      });
    }

    createChuck(itemsCopy, perColumn);
  }, [createChuck, perColumn, allowOther, items, t]);

  const itemsMap = (items, itemsIndex) => {
    const checkboxesMap = (item, index) => {
      const isChecked = !!itemsSelected.find((val) => val.id === item.id);
      const label = allowNumeration
        ? item.isOther
          ? item.label
          : `${itemsIndex * perColumn + index + 1}: ${item.label}`
        : item.label;

      const handleOnChange = (e) => {
        const checked = e.target.checked;
        onChange(item, checked);

        if (item.isOther && !checked) onOtherChange(otherName)('');
      };

      return (
        <ChecksContainers key={item.id}>
          <StyledCheck
            custom
            id={`${item.id}.${item.label}`}
            label={label}
            checked={isChecked}
            onChange={handleOnChange}
            onBlur={onBlur}
            ref={index === 0 ? inputRef : undefined}
          />
          {item.isOther && (
            <Input
              disabled={!isChecked}
              name={otherName}
              onChange={onOtherChange}
              value={otherValue}
            />
          )}
        </ChecksContainers>
      );
    };

    return (
      <StyledFormGroup
        as={Col}
        key={`group ${itemsIndex}`}
        lg={isFullWidth ? 12 : 6}
      >
        {items.map(checkboxesMap)}
      </StyledFormGroup>
    );
  };

  return collection.length && collection.map(itemsMap);
};

export default MultipleChecks;
