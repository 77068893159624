import {useCallback, useEffect, useState} from 'react';
import {useApi} from '../contexts/ApiContext';

const useFields = (modelName) => {
  const [fields, setFields] = useState(null);
  const api = useApi();
  const [initialValues, setInitialValues] = useState({});

  const fetchFields = useCallback(async () => {
    const response = await api.getExtraAttributeFields(modelName);
    setFields(response.results || []);

    const initialValues = response.results
      .reduce((accum, current) => {
        return [...accum, ...current.fields];
      }, [])
      .reduce((accum, current) => {
        return {
          ...accum,
          [current.name]: current.kind === 'choice' ? [] : '',
        };
      }, {});

    setInitialValues(initialValues);
  }, [modelName, api]);

  useEffect(() => {
    if (modelName) {
      fetchFields();
    } else {
      setInitialValues({});
      setFields(null);
    }
  }, [fetchFields, modelName]);

  return {fields, initialValues};
};

export {useFields};
