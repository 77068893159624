import React from 'react';
import {Form as BootstrapForm} from 'react-bootstrap';
import {useFormikContext} from 'formik';
import {useErrorFocus} from '../../../hooks/formik';
import FormContext from '../FormContext';
import styles from './Form.module.scss';

const Form = (props) => {
  const {values, errors, isSubmitting} = useFormikContext();

  let focusOnErrorFields = Object.keys(values);
  if (values.extra_attribute && Object.keys(values.extra_attribute).length) {
    const extraAttributesFields = Object.keys(values.extra_attribute).map(
      (extraAttribute) => {
        return `extra_attribute.${extraAttribute}`;
      },
    );

    focusOnErrorFields = focusOnErrorFields
      .filter((val) => val !== 'extra_attribute')
      .concat(extraAttributesFields);
  }

  const fieldsRefs = {};
  const setRef = (fieldName, ref) => (fieldsRefs[fieldName] = ref);

  useErrorFocus(isSubmitting, errors, focusOnErrorFields, fieldsRefs);

  return (
    <FormContext.Provider value={{fieldsRefs, setFieldRef: setRef}}>
      <BootstrapForm
        className={`${styles.customForm} ${props.className}`}
        onSubmit={props.onSubmit}>
        {props.children}
      </BootstrapForm>
    </FormContext.Provider>
  );
};

export default Object.assign(Form, {
  Group: BootstrapForm.Group,
  Row: BootstrapForm.Row,
});
