import React, {useState, useEffect, useRef} from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import {observer} from 'mobx-react';
import {useLocation} from 'react-router-dom';
import i18n from '../../i18n';

import {USER_STATUS_CHOICES} from '../../constants';
import {useClickOutside} from '../../hooks/utils';
import {useStores} from '../../contexts/StoresContext';
import RequestJoinModal from '../Modals/RequestJoinModal';
import Logo from './Logo';
import Links from './Links';
import Authentication from './Authentication';
import HamburguerToggle from './HamburguerToggle';
import styles from './TopBar.module.scss';

const TopBar = () => {
  const stores = useStores();
  const {isPublic, user} = stores.app;
  const [showI18nKeys, setShowI18nKeys] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const showSwapLang = user && user.isDev;

  const wrapperRef = useRef(null);
  const handleClickOutside = () => expanded && setExpanded(false);
  useClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    setExpanded(false);
  }, [location]);

  if (!user && !isPublic) {
    return null;
  }

  const handleNavbarToggle = () => setExpanded(!expanded);
  const handleShowRequestJoinModal = () => {
    setExpanded(false);
    setShowModal(true);
  };
  const handleOnCloseModal = () => {
    setShowModal(false);
    stores.app.toggleRequestJoinModal(false);
  };

  const hanldeSwapLang = () => {
    setShowI18nKeys(!showI18nKeys);
    i18n.changeLanguage(showI18nKeys ? 'dev' : stores.app.language);
  };

  return (
    <div className={styles.topBar}>
      <Navbar
        bg="white"
        expand="lg"
        onToggle={handleNavbarToggle}
        expanded={expanded}
        ref={wrapperRef}>
        {(!user || user.status !== USER_STATUS_CHOICES.processing) && (
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <HamburguerToggle expanded={expanded} />
          </Navbar.Toggle>
        )}
        <Logo />
        {showSwapLang && <button onClick={hanldeSwapLang}>swap keys</button>}
        {(!user || user.status !== USER_STATUS_CHOICES.processing) && (
          <Navbar.Collapse
            className={styles.navBarCollapse}
            id="responsive-navbar-nav">
            <Nav>
              {user && <Links />}
              <Authentication onClickSignUp={handleShowRequestJoinModal} />
            </Nav>
          </Navbar.Collapse>
        )}
      </Navbar>
      {isPublic && showModal && (
        <RequestJoinModal onClose={handleOnCloseModal} />
      )}
    </div>
  );
};

export default observer(TopBar);
