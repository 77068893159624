import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useApi} from '../../contexts/ApiContext';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import {useAuth0} from '@auth0/auth0-react';
import {ReactComponent as NeedslistLogo} from '../../assets/logo.svg';
import styles from './LoginV2.module.scss';
import RegisterFormV2 from './RegisterFormV2';
import LinkButton from '../../components/Button/LinkButton';

const LoginV2 = (props) => {
  const stores = useStores();
  const api = useApi();
  const {t} = useTranslation('login');
  const [isVerifyEmailFlow, setIsVerifyEmailFlow] = useState(false);
  const [invitationToken, setInvitationToken] = useState(null);
  const auth0Enabled = stores.app.clientData.authentication_type === 'auth0';
  const {invitation, signUpType, isInvitationTokenValid} = props;
  const [message, setMessage] = useState(null);
  const [showNewUserForm, setShowNewUserForm] = useState(false);
  const [loginHint, setLoginHint] = useState(
    invitation ? invitation.email : '',
  );
  const {
    user: userAuth0,
    isAuthenticated,
    isLoading: isLoadingAuth0,
    loginWithRedirect,
    logout,
    getIdTokenClaims,
  } = useAuth0();
  const screenHint =
    (invitation || invitationToken) && !isVerifyEmailFlow ? 'signup' : 'login';

  useEffect(() => {
    if (!invitation && userAuth0 && signUpType === 'by_invite') {
      const fetchInvitationToken = async () => {
        try {
          const response = await api.getInvitationToken({
            email: userAuth0.email,
          });
          setInvitationToken(response.token);
        } catch (error) {
          setInvitationToken(null);
        }
      };
      fetchInvitationToken();
    }
  }, [invitationToken, userAuth0, invitation, signUpType, api]);

  useEffect(() => {
    // Wait for Auth0 to load.
    if (auth0Enabled && !isLoadingAuth0) {
      // Check if the user was authenticated with Auth0.
      if (isAuthenticated) {
        // If the email is not verified, we do not allow access to the platform.
        if (!userAuth0.email_verified) {
          setLoginHint(userAuth0.email);
          setIsVerifyEmailFlow(true);
          setMessage(t('toast.text-verify-email-auth0'));
        } else {
          // isSubscribed fixes a race condition that happens when calling async functions inside useEffect.
          let isSubscribed = true;

          const backendLogin = async () => {
            // Get the ID Token to pass to our backend for verification.
            const idtoken = await getIdTokenClaims();
            try {
              const response = await api.login_auth0(idtoken.__raw);
              if (isSubscribed) {
                if (response) {
                  stores.app.login(response);
                  stores.app.setRefreshing(true);
                  window.location.replace('/');
                }
              }
            } catch (e) {
              if (isSubscribed && e.non_field_errors) {
                if (e.non_field_errors[0] === 'mfa_required') {
                  loginWithRedirect({scope: 'admin'});
                } else {
                  if (e.non_field_errors[0] === 'no_user') {
                    if (
                      invitation &&
                      userAuth0.email.toLowerCase() !==
                        invitation.email.toLowerCase()
                    ) {
                      setMessage(
                        t('toast.text-user-invitation-wrong-email', {
                          email: invitation.email,
                        }),
                      );
                    } else if (
                      (signUpType === 'by_invite' || signUpType === 'both') &&
                      invitationToken
                    ) {
                      window.location.replace(`/invitation/${invitationToken}`);
                    } else if (
                      signUpType === 'by_invite' &&
                      !invitation &&
                      !invitationToken
                    ) {
                      setMessage(t('toast.text-registration-by-invite-only'));
                    } else {
                      setShowNewUserForm(true);
                    }
                  } else if (e.non_field_errors[0] === 'account_disabled') {
                    setMessage(t('toast.text-account-disabled'));
                  } else if (e.non_field_errors[0] === 'approval_pending') {
                    setMessage(t('toast.text-pending-approval'));
                  } else if (e.non_field_errors[0] === 'account_deleted') {
                    setMessage(t('toast.text-account-deleted'));
                  } else {
                    setMessage(t('toast.text-generic-error'));
                  }
                }
              }
            }
          };
          backendLogin().catch(console.error);
          return () => (isSubscribed = false);
        }
      } else {
        loginWithRedirect({
          screen_hint: screenHint,
          login_hint: loginHint,
          appState: {
            returnTo: window.location.pathname + window.location.search,
          },
        });
      }
    }
  }, [
    t,
    api,
    isAuthenticated,
    isLoadingAuth0,
    stores.app,
    auth0Enabled,
    userAuth0,
    invitationToken,
    invitation,
    isInvitationTokenValid,
    signUpType,
    loginHint,
    loginWithRedirect,
    getIdTokenClaims,
    screenHint,
    setIsVerifyEmailFlow,
  ]);

  if (!auth0Enabled) {
    return <></>;
  }
  if (showNewUserForm) {
    return (
      <RegisterFormV2
        invitationToken={invitationToken}
        invitation={invitation}
      />
    );
  }
  return (
    <div className={styles.container}>
      <NeedslistLogo className={styles.logo} />
      {!isLoadingAuth0 && isAuthenticated && message ? (
        <div className={styles.error_message_div}>
          <span className={styles.error_message_header}>
            {!isVerifyEmailFlow && t('error-message-title')}
          </span>
          {message ? (
            <span className={styles.error_message}>{message}</span>
          ) : (
            <></>
          )}
          <LinkButton onClick={() => logout()}>
            {t('auth0.go-back-to-login')}
          </LinkButton>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default observer(LoginV2);
