import {useRef, useState} from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {useTranslation} from 'react-i18next';
import {useClearRefinements, useSearchBox} from 'react-instantsearch';
import {useSearchAlgoliaStore} from '../../../../contexts/SearchAlgoliaContext';
import {handleSelectFirstOption} from '../../../../utils/selectFirstOption';
import useLocationInput from '../../hooks/useLocationInput';
import AsyncComponent from '../../../../components/AsyncComponent';
import {ReactComponent as SearchIcon} from '../../../../assets/icons/search.svg';
import styles from './SearchInput.module.scss';
import {useMapAlgoliaStore} from '../../../../contexts/MapAlgoliaContext';
import {usePanelStore} from '../../../../contexts/PanelContext';
import {DEFAULT_ZOOM} from '../../constants';

export const SearchInput = ({currentIssue}) => {
  const {t} = useTranslation('search-page');
  const {refine} = useSearchBox();
  const [searchState, searchActions] = useSearchAlgoliaStore();
  const [, mapAlgoliaActions] = useMapAlgoliaStore();
  const {
    handleChange: handleLocationChange,
    handleSearch: handleLocationSearch,
    places,
    openOptions,
    isLoading,
  } = useLocationInput();
  const [activeIndex, setActiveIndex] = useState(-1);
  const typeaheadRef = useRef(null);
  const [, panelActions] = usePanelStore();
  const {refine: refineClearRefinements} = useClearRefinements({
    includedAttributes: ['meta_position'],
  });

  const handleSearch = (query) => {
    searchState.place ? refine(query) : handleLocationSearch(query);
  };

  const handleChange = (selected) => {
    handleLocationChange(selected);
    if (!searchState.place) typeaheadRef.current.value = '';
  };

  const handleChangePlace = () => {
    searchActions.setCurrentPlace(null);
    searchActions.setPlaces([]);
    mapAlgoliaActions.setZoom(DEFAULT_ZOOM);
    handleLocationChange([]);
    refineClearRefinements();
    panelActions.setPanelMount(false);
  };

  return (
    <>
      <div className={styles.inputLabel}>
        <SearchIcon />
        {searchState.place ? (
          <div>
            <span>{t('input.label.searching-in')} </span>
            <b>{searchState.place.description} </b>
            {!currentIssue && (
              <b onClick={handleChangePlace} className={styles.changePlace}>
                {t('input.change')}
              </b>
            )}
          </div>
        ) : (
          <label htmlFor="location-field-algolia-search">
            {t('input.label.search')}
          </label>
        )}
      </div>
      {searchState.place ? (
        <div className={styles.customInput}>
          <input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder={t('input.text-filter')}
            type="text"
          />
          <SearchIcon />
        </div>
      ) : (
        <AsyncTypeahead
          id="location-field-algolia-search"
          ref={typeaheadRef}
          className={styles.searchInput}
          placeholder={t('input.location-placeholder')}
          isLoading={isLoading}
          onKeyDown={(e) =>
            handleSelectFirstOption(e, places, activeIndex, handleChange)
          }
          onBlur={(e) =>
            handleSelectFirstOption(e, places, activeIndex, handleChange)
          }
          labelKey={(option) => `${option.description}`}
          onSearch={handleSearch}
          allowNew={false}
          onChange={handleChange}
          options={places}
          inputProps={{id: 'location-field-algolia-search'}}
          open={openOptions}>
          <AsyncComponent
            update={() => {
              setActiveIndex(activeIndex);
            }}
          />
          <SearchIcon />
        </AsyncTypeahead>
      )}
    </>
  );
};
