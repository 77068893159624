import {Tabs as BootstrapTabs} from 'react-bootstrap';

import styled from 'styled-components';

import theme from '../../theme';
import {mediaQueries} from '../../constants';

const Tabs = styled(BootstrapTabs)`
  padding-bottom: 10px;
  font-size: 14px;

  &.nav-tabs {
    .nav-item.nav-link {
      color: #979797;
      padding: 30px 30px 10px;

      &.active {
        border: none;
        border-bottom: 4px solid ${theme.primaryColor};
        color: #000000;
        font-weight: 600;
      }
    }
  }

  ${mediaQueries.lg} {
    &.nav {
      overflow-x: auto;
      flex-wrap: nowrap;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &.nav-tabs {
      .nav-item {
        flex: 0 0 auto;
        &.nav-link {
          padding: 15px 15px 10px;
        }
      }
    }
  }
`;

export default Tabs;
