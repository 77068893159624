import React, {useMemo} from 'react';
import {Col, Row} from 'react-bootstrap';
import styled, {css} from 'styled-components';
import {PieChart as BasePieChart, Pie, Cell} from 'recharts';

import theme from '../../../theme';
import LegendLine from './LegendLine';
import {mediaQueries} from '../../../constants';
import Spinner from '../../../components/Spinner';
import {ReactComponent as EmptyTrend} from '../../../assets/icons/reports-trend-empty.svg';
import EmptyReport from '../EmptyReport';

const ColPieContainer = styled(Col)`
  display: flex;
  justify-content: center;
`;

const ChartContainer = styled(({noData, ...rest}) => <Row {...rest} />)`
  align-items: center;
  min-height: 320px;

  ${(props) =>
    props.noData &&
    css`
      justify-content: center;
    `}
`;

const ColLegendContainer = styled(Col)`
  ${mediaQueries.lg} {
    margin-top: 20px;
    justify-content: center;
  }
`;

const StyledLegendLine = styled(LegendLine)`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const PIE_COLORS = [
  theme.astronautBlue,
  theme.smaltBlue,
  theme.crusta,
  theme.atomicTangerine,
];

const PieChart = (props) => {
  const {data = [], isLoading, formatNumber, currency} = props;
  const noData = Array.isArray(data) && data.length === 0;
  const total = useMemo(
    () =>
      data.reduce(
        (totalAccumulator, entry) => totalAccumulator + entry.value,
        0,
      ),
    [data],
  );

  const cellMap = (_, index) => (
    <Cell key={`cell-${index}`} fill={PIE_COLORS[index]} stroke="" />
  );
  const legendMap = (entry, index) => {
    const legendData = {
      ...entry,
      value: formatNumber(entry.value, currency),
      percentage: ((entry.value / total) * 100).toFixed(2),
    };

    return (
      <StyledLegendLine
        key={legendData.name}
        color={PIE_COLORS[index]}
        data={legendData}
      />
    );
  };

  if (isLoading) {
    return (
      <ChartContainer noData>
        <Spinner />
      </ChartContainer>
    );
  }

  if (noData) {
    return (
      <ChartContainer noData>
        <Col>
          <EmptyReport icon={EmptyTrend} />
        </Col>
      </ChartContainer>
    );
  }

  return (
    <ChartContainer>
      <ColPieContainer sm={12} lg={{span: 5, offset: 1}}>
        <BasePieChart width={280} height={280}>
          <Pie data={data} dataKey="value" nameKey="name" outerRadius="100%">
            {data.map(cellMap)}
          </Pie>
        </BasePieChart>
      </ColPieContainer>
      <ColLegendContainer sm={12} lg={6}>
        {data.map(legendMap)}
      </ColLegendContainer>
    </ChartContainer>
  );
};

export default PieChart;
