import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './EmailVerified.module.scss';
import Button from '../../components/Button';
import {useApi} from '../../contexts/ApiContext';

const EmailVerified = ({data}) => {
  const {t} = useTranslation('login');
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const resendEmail = useCallback(async () => {
    setIsLoading(true);
    const response = await api.resendEmail({user_email: data, encoded: false});
    setMessage(response);
  }, [api, data]);
  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <div className={styles.container}>
      <h1 className={styles.verifyTitle}>
        {t('email-validation.verify-title')}
      </h1>
      <FontAwesomeIcon icon="envelope" className={styles.emailIcon} />
      <p className={styles.verifyText}>{t('email-validation.verify-text')}</p>
      <Button onClick={resendEmail} disabled={isLoading || message}>
        {t('email-validation.resend-email')}
      </Button>
      {message && <p className={styles.verifyText}>{message}</p>}
      <p className={styles.emailWarning}>
        <FontAwesomeIcon
          icon="exclamation-triangle"
          className={styles.emailWarningIcon}
        />
        {t('email-validation.verify-warning')}
      </p>
    </div>
  );
};

export default EmailVerified;
