import React, {useState} from 'react';
import {observer} from 'mobx-react';

const CategoryWidget = (props) => {
  const [value, setValue] = useState(props.value || '');

  const renderChoices = () => {
    const {choices, store} = props;
    return (choices[store.kind] || []).map((choice) => {
      const {id, name} = choice;
      return (
        <option key={id} value={id}>
          {name}
        </option>
      );
    });
  };

  const errors = props.errors ? (
    <div
      className={props.errors.getAttribute('class')}
      dangerouslySetInnerHTML={{
        __html: props.errors.innerHTML,
      }}
    />
  ) : null;

  return (
    <React.Fragment>
      <label>{props.label}</label>
      <select
        className={props.className}
        onChange={(event) => setValue(event.target.value)}
        value={value}
        name={props.name}
      >
        <option value="">
          {!value ? 'No category selected' : 'Select category'}
        </option>
        {renderChoices()}
      </select>
      {errors}
    </React.Fragment>
  );
};

export default observer(CategoryWidget);
