import styled from 'styled-components';

const Footer = styled.div`
  position: sticky;
  z-index: 2;
  bottom: 0;
  padding: 1rem 0 1.5rem;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(250, 250, 250, 0.8) 100%,
    rgba(237, 231, 235, 1) 100%
  );
`;

export default Footer;
