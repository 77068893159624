import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import isoCountries from 'i18n-iso-countries';
import english from 'i18n-iso-countries/langs/en.json';
import spanish from 'i18n-iso-countries/langs/es.json';

isoCountries.registerLocale(english);
isoCountries.registerLocale(spanish);

const useCountries = (countriesCodes) => {
  const [countries, setCountries] = useState([]);
  const {t, i18n} = useTranslation('common');

  useEffect(() => {
    const langCode = i18n.language.split('-')[0];
    const countries = countriesCodes.map((code) => {
      let label = code;
      if (langCode !== 'dev') {
        label =
          code === ''
            ? t('country-global')
            : isoCountries.getName(code, langCode, {select: 'official'});
      }

      return {
        label: label,
        value: code,
      };
    });

    setCountries(countries);
  }, [countriesCodes, i18n.language, t]);

  return countries;
};

export default useCountries;
