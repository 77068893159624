import React from 'react';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';

const ItemDeliveryInformation = (props) => {
  const stores = useStores();

  const {item} = props;
  const hasStreet = item.location.street && item.location.number;
  const isAbleToSeeStreet =
    stores.app.user.email === item.user.email ||
    item.organization.setting_address === 'public';
  const renderConditionally = (value) => {
    if (value) {
      return (
        <React.Fragment>
          {value} <br />
        </React.Fragment>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      <b className="text-capitalize">
        {renderConditionally(
          `- ${
            stores.app.choices.addressLocationType[item.location.location_type]
          } -`,
        )}
      </b>
      {hasStreet &&
        isAbleToSeeStreet &&
        `${item.location.number} ${item.location.street}, `}
      {renderConditionally(item.location.city)}
      {renderConditionally(item.location.state_short)}
      {renderConditionally(item.location.country)}
      {renderConditionally(
        item.location.location_landmark &&
          `(${item.location.location_landmark})`,
      )}
    </React.Fragment>
  );
};

export default observer(ItemDeliveryInformation);
