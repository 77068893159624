import React, {useEffect} from 'react';
import {Switch, useHistory, Redirect} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import {USER_STATUS_CHOICES} from '../../constants';
import {getLocalToken} from '../../auth';
import {useApi} from '../../contexts/ApiContext';
import {Route} from '../../components/Layout';
import {
  ONBOARDING_USER_DETAILS,
  ONBOARDING_USER_CREATION_FINISH,
  ONBOARDING_ORGANIZATION_DETAILS,
  ONBOARDING_USER_APPROVAL_PENDING,
  HOME_PAGE,
  ONBOARDING_ORGANIZATION_ADDITIONAL_INFORMATION,
  LOGIN_PAGE,
} from '../../urls';

import UserDetails from './UserDetails';
import OrganizationDetails from './OrganizationDetails';
import OrganizationAdditionalInformation from './OrganizationAdditionalInformation';
import ThankYouModal from '../../components/Modals/ThankYouModal';
import Stepper from '../../components/Stepper/Stepper';
import {useStepper} from '../../contexts/StepperContext';

const ModalContent = styled.p`
  white-space: pre-wrap;
`;

const Onboarding = () => {
  const {t} = useTranslation('thank-you');
  const api = useApi();
  const {setLastStep} = useStepper();
  const history = useHistory();
  const stores = useStores();

  const handleModalExploreClick = async () => {
    const token = getLocalToken();
    const response = await api.getSession(token);
    stores.app.setSession(response);
    history.push(HOME_PAGE);
  };

  const handleModalHomeClick = () => {
    stores.app.logout();
    window.location.replace(LOGIN_PAGE);
  };

  useEffect(() => {
    if (stores.app.user.organization_id && stores.app.user.invitation_token) {
      setLastStep(1);
    } else {
      setLastStep(2);
    }
  }, [stores.app.user, setLastStep]);

  if (stores.app.user.status === USER_STATUS_CHOICES.active) {
    return <Redirect to={HOME_PAGE} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Stepper title={t('new-user:registration-title')}>
          <Stepper.Steps>
            <Stepper.Step id="1" url={ONBOARDING_USER_DETAILS}>
              <Route path={ONBOARDING_USER_DETAILS} isOnboarding>
                <UserDetails />
              </Route>
            </Stepper.Step>
            <Stepper.Step id="2" url={ONBOARDING_ORGANIZATION_DETAILS}>
              <Route path={ONBOARDING_ORGANIZATION_DETAILS} isOnboarding>
                <OrganizationDetails />
              </Route>
            </Stepper.Step>
            <Stepper.Step
              id="3"
              url={ONBOARDING_ORGANIZATION_ADDITIONAL_INFORMATION}>
              <Route
                path={ONBOARDING_ORGANIZATION_ADDITIONAL_INFORMATION}
                component={OrganizationAdditionalInformation}
                isOnboarding
              />
            </Stepper.Step>
          </Stepper.Steps>
        </Stepper>
      </Switch>
      <Switch>
        <Route path={ONBOARDING_USER_CREATION_FINISH} isOnboarding>
          <ThankYouModal
            titleMessage={t('labels.message.success')}
            onPrimaryClick={handleModalExploreClick}
            primaryButtonText={t('buttons.go-to-dashboard')}>
            {t('labels.user.new-user')}
          </ThankYouModal>
        </Route>
        <Route path={ONBOARDING_USER_APPROVAL_PENDING} isOnboarding>
          <ThankYouModal
            titleMessage={t('labels.approval-pending.title')}
            onPrimaryClick={handleModalHomeClick}
            primaryButtonText={t('buttons.accept')}>
            <ModalContent>{t('labels.approval-pending.text-1')}</ModalContent>
            <ModalContent>{t('labels.approval-pending.text-2')}</ModalContent>
          </ThankYouModal>
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default observer(Onboarding);
