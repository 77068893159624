import React, {useEffect, useState} from 'react';
import styles from './Visualizations.module.scss';
import theme from '../../../theme';
import ItemsByFulfillmentCard from './ItemsByFulfillmentCard';
import ItemsByFulfillmentAndTypeCard from './ItemsByFulfillmentAndTypeCard';
import {useApi} from '../../../contexts/ApiContext';
import {useTranslation} from 'react-i18next';
import {itemApiParams} from '../filterUtils';

const Visualizations = (props) => {
  const {filters, memberId} = props;
  const {t} = useTranslation('item-reports');
  const api = useApi();
  const [areNeedsEmpty, setAreNeedsEmpty] = useState(false);
  const [areOffersEmpty, setAreOffersEmpty] = useState(false);
  const [offerItemsByFulfillment, setOfferItemsByFulfillment] = useState({});
  const [needItemsByFulfillment, setNeedItemsByFulfillment] = useState({});
  const [offerItemsByFulfillmentAndType, setOfferItemsByFulfillmentAndType] =
    useState({});
  const [needItemsByFulfillmentAndType, setNeedItemsByFulfillmentAndType] =
    useState({});

  useEffect(() => {
    const setVisualizationData = async () => {
      let needItemDetailsByFulfillment = {
        delivered: {
          itemCount: 0,
          totalValue: 0,
          color: theme.astronautBlue,
          displayText: t('visualizations.fulfillment.delivered'),
          side: 'need',
        },
        met: {
          itemCount: 0,
          totalValue: 0,
          color: theme.smaltBlue,
          displayText: t('visualizations.fulfillment.met'),
          side: 'need',
        },
        posted: {
          itemCount: 0,
          totalValue: 0,
          color: theme.heather,
          displayText: t('visualizations.fulfillment.posted'),
          side: 'need',
        },
      };
      let offerItemDetailsByFulfillment = {
        delivered: {
          itemCount: 0,
          totalValue: 0,
          color: theme.crusta,
          displayText: t('visualizations.fulfillment.delivered'),
          side: 'offer',
        },
        met: {
          itemCount: 0,
          totalValue: 0,
          color: theme.hitPink,
          displayText: t('visualizations.fulfillment.claimed'),
          side: 'offer',
        },
        posted: {
          itemCount: 0,
          totalValue: 0,
          color: theme.lightApricot,
          displayText: t('visualizations.fulfillment.posted'),
          side: 'offer',
        },
      };
      let needItemDetailsByType = {
        deliveredSupply: {
          itemCount: 0,
          totalValue: 0,
          color: theme.tealBlue,
          displayText: t('visualizations.fulfillment.delivered'),
        },
        metSupply: {
          itemCount: 0,
          totalValue: 0,
          color: theme.bismark,
          displayText: t('visualizations.fulfillment.met'),
        },
        postedSupply: {
          itemCount: 0,
          totalValue: 0,
          color: theme.baliHai,
          displayText: t('visualizations.fulfillment.posted'),
        },
        deliveredService: {
          itemCount: 0,
          totalValue: 0,
          color: theme.blueChill,
          displayText: t('visualizations.fulfillment.delivered'),
        },
        metService: {
          itemCount: 0,
          totalValue: 0,
          color: theme.steelBlue,
          displayText: t('visualizations.fulfillment.met'),
        },
        postedService: {
          itemCount: 0,
          totalValue: 0,
          color: theme.glacier,
          displayText: t('visualizations.fulfillment.posted'),
        },
      };
      let offerItemDetailsByType = {
        deliveredSupply: {
          itemCount: 0,
          totalValue: 0,
          color: theme.korma,
          displayText: t('visualizations.fulfillment.delivered'),
        },
        metSupply: {
          itemCount: 0,
          totalValue: 0,
          color: theme.santaFe,
          displayText: t('visualizations.fulfillment.claimed'),
        },
        postedSupply: {
          itemCount: 0,
          totalValue: 0,
          color: theme.tan,
          displayText: t('visualizations.fulfillment.posted'),
        },
        deliveredService: {
          itemCount: 0,
          totalValue: 0,
          color: theme.meteor,
          displayText: t('visualizations.fulfillment.delivered'),
        },
        metService: {
          itemCount: 0,
          totalValue: 0,
          color: theme.rawSienna,
          displayText: t('visualizations.fulfillment.claimed'),
        },
        postedService: {
          itemCount: 0,
          totalValue: 0,
          color: theme.goldSand,
          displayText: t('visualizations.fulfillment.posted'),
        },
        deliveredInformation: {
          itemCount: 0,
          totalValue: 0,
          color: theme.neonCarrot,
          displayText: t('visualizations.fulfillment.delivered'),
        },
        metInformation: {
          itemCount: 0,
          totalValue: 0,
          color: theme.macaroniAndCheese,
          displayText: t('visualizations.fulfillment.claimed'),
        },
        postedInformation: {
          itemCount: 0,
          totalValue: 0,
          color: theme.flesh,
          displayText: t('visualizations.fulfillment.posted'),
        },
      };

      const response = await api.getSummaryForVisualizations({
        org_id: memberId,
        ...itemApiParams(filters),
      });
      const visualizationData = response;
      visualizationData.forEach((entry) => {
        const fulfillmentKey = entry.fulfilment;
        const typeKey =
          entry.kind.charAt(0).toUpperCase() + entry.kind.slice(1);
        const itemDetailsKey = fulfillmentKey + typeKey;
        if (entry.side === 'need') {
          needItemDetailsByType[itemDetailsKey]['itemCount'] =
            entry.total_count;
          needItemDetailsByType[itemDetailsKey]['totalValue'] = parseFloat(
            entry.total_value,
          );
        } else {
          offerItemDetailsByType[itemDetailsKey]['itemCount'] =
            entry.total_count;
          offerItemDetailsByType[itemDetailsKey]['totalValue'] = parseFloat(
            entry.total_value,
          );
        }
      });

      ['delivered', 'met', 'posted'].forEach((fulfillment) => {
        needItemDetailsByFulfillment[fulfillment]['itemCount'] =
          needItemDetailsByType[fulfillment + 'Supply']['itemCount'] +
          needItemDetailsByType[fulfillment + 'Service']['itemCount'];
        needItemDetailsByFulfillment[fulfillment]['totalValue'] =
          needItemDetailsByType[fulfillment + 'Supply']['totalValue'] +
          needItemDetailsByType[fulfillment + 'Service']['totalValue'];
        offerItemDetailsByFulfillment[fulfillment]['itemCount'] =
          offerItemDetailsByType[fulfillment + 'Supply']['itemCount'] +
          offerItemDetailsByType[fulfillment + 'Service']['itemCount'] +
          offerItemDetailsByType[fulfillment + 'Information']['itemCount'];
        offerItemDetailsByFulfillment[fulfillment]['totalValue'] =
          offerItemDetailsByType[fulfillment + 'Supply']['totalValue'] +
          offerItemDetailsByType[fulfillment + 'Service']['totalValue'] +
          offerItemDetailsByType[fulfillment + 'Information']['totalValue'];
      });

      setAreNeedsEmpty(
        !visualizationData.some((entry) => entry.side === 'need'),
      );
      setAreOffersEmpty(
        !visualizationData.some((entry) => entry.side === 'offer'),
      );

      setNeedItemsByFulfillment(needItemDetailsByFulfillment);
      setOfferItemsByFulfillment(offerItemDetailsByFulfillment);
      setNeedItemsByFulfillmentAndType(needItemDetailsByType);
      setOfferItemsByFulfillmentAndType(offerItemDetailsByType);
    };
    setVisualizationData();
  }, [api, filters, memberId, t]);

  return (
    <div>
      <div className={styles.visualizationsDivider}></div>
      <ItemsByFulfillmentCard
        needItemsByFulfillment={needItemsByFulfillment}
        offerItemsByFulfillment={offerItemsByFulfillment}
        isEmpty={areNeedsEmpty && areOffersEmpty}
      />
      <div className={styles.visualizationsDivider}></div>
      <ItemsByFulfillmentAndTypeCard
        headerText={t('visualizations.header.needs-by-type')}
        itemsByFulfillmentAndType={needItemsByFulfillmentAndType}
        itemsByFulfillment={needItemsByFulfillment}
        side="need"
        isEmpty={areNeedsEmpty}
      />
      <div className={styles.visualizationsDivider}></div>
      <ItemsByFulfillmentAndTypeCard
        headerText={t('visualizations.header.offers-by-type')}
        itemsByFulfillmentAndType={offerItemsByFulfillmentAndType}
        itemsByFulfillment={offerItemsByFulfillment}
        side="offer"
        isEmpty={areOffersEmpty}
      />
      <div className={styles.visualizationsDivider}></div>
    </div>
  );
};

export default Visualizations;
