import React from 'react';
import {useTranslation} from 'react-i18next';

import {Form} from 'react-bootstrap';
import {
  TextAreaField,
  LocationAutocompleteField,
  ImageUploadField,
} from '../../../components/Fields';

const BaseEditMemberFields = () => {
  const {t} = useTranslation('member-page');

  return (
    <React.Fragment>
      <Form.Group>
        <TextAreaField
          showCharCounter
          rows="3"
          maxLength={350}
          name="description"
          label={t('form.label.description')}
          placeholder={t('form.place-holder.description')}
          isRequired
        />
      </Form.Group>
      <Form.Group>
        <ImageUploadField name="logo" label={t('label.logo')} />
      </Form.Group>
      <Form.Group>
        <LocationAutocompleteField
          name="locations"
          label={t('form.label.location')}
          placeholder={t('form.place-holder.location')}
          multiple
          isRequired
          isFullWidth={false}
          notDeleteAddress
        />
      </Form.Group>
    </React.Fragment>
  );
};

export default BaseEditMemberFields;
