import React from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import ItemHeader from '../Item/ItemHeader';
import ItemLocation from '../Item/ItemLocation';
import OrganizationAward from '../Item/OrganizationAward';
import Breadcrumbs from '../Layout/Breadcrumbs';
import styles from './ItemPanel.module.scss';

const ItemPanel = (props) => {
  const history = useHistory();
  const {
    title,
    item,
    showOrganizationLogo,
    onOrganizationClick,
    isEditableNameItem = false,
  } = props;
  const handleOrganizationClick = () => {
    if (onOrganizationClick) onOrganizationClick(item);
  };

  const {t} = useTranslation('common');

  const handleClick = () => {
    history.goBack();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.goBack} onClick={handleClick}>
          {`< ${t(`common:buttons.back`)}`}
        </button>
        <Breadcrumbs />
        <h3 className={styles.title}>{title}</h3>
      </div>
      <div className={styles.wrapper}>
        <ItemHeader
          className={styles.itemHeader}
          item={item}
          showOrganizationLogo={showOrganizationLogo}
          isEditableNameItem={isEditableNameItem}
        />
        {item.issueLocation && (
          <ItemLocation
            className={styles.itemLocation}
            location={item.issueLocation}
            issueName={item.issueName}
            extendLocation
          />
        )}
        {item.organizationName && (
          <div
            className={
              onOrganizationClick &&
              item.organization &&
              styles.organizationWrapper
            }
            onClick={handleOrganizationClick}>
            <OrganizationAward>{item.organizationName}</OrganizationAward>
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
};

export default ItemPanel;
