import React, {useCallback, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {Card, Form, Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useFormikContext, withFormik} from 'formik';
import * as yup from 'yup';
import {observer} from 'mobx-react';
import requestJoinLogo from '../../assets/request-join.png';
import Subtitle from '../Subtitle';
import {InputField} from '../Fields';
import ResponsiveImg from '../ResponsiveImg';
import Button from '../Button';
import {mediaQueries} from '../../constants';
import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';
import {SIGNUP_PAGE} from '../../urls';

const Wrapper = styled(({isModal, ...rest}) => <Card {...rest} />)`
  display: flex;
  justify-content: center;

  ${(props) =>
    props.isModal &&
    css`
      &.card {
        border: none;
      }
    `}
`;

const Header = styled(({isModal, ...rest}) => <div {...rest} />)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) =>
    props.isModal &&
    css`
      align-items: flex-start;
    `}
`;

const Footer = styled.div`
  margin: 30px 0;
  text-align: center;

  ${mediaQueries.lg} {
    margin-top: 80px;
  }
`;

const Message = styled.p`
  padding: 0px 5px;
`;

const RequestJoinForm = (props) => {
  const {handleSubmit, isModal} = props;
  const {t} = useTranslation('request-join');
  const {setFieldValue} = useFormikContext();

  const fetchClientData = useCallback(async () => {
    const response = await props.api.getClientData();
    setFieldValue('signUpType', response.sign_up_type);
  }, [setFieldValue, props.api]);

  useEffect(() => {
    fetchClientData();
  }, [fetchClientData]);

  return (
    <Wrapper isModal={isModal}>
      <Header isModal={isModal}>
        <Subtitle>{t('label.title')}</Subtitle>
        <Message>{t('label.subtitle')}</Message>
      </Header>
      {!isModal && (
        <ResponsiveImg src={requestJoinLogo} alt="RequestJoinLogo" />
      )}
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group as={Col} sm={12}>
          <InputField
            type="text"
            placeholder={t('place-holder.first-name')}
            label={t('label.first-name')}
            name="firstName"
            isRequired
          />
        </Form.Group>
        <Form.Group as={Col} sm={12}>
          <InputField
            type="text"
            placeholder={t('place-holder.last-name')}
            label={t('label.last-name')}
            name="lastName"
            isRequired
          />
        </Form.Group>
        <Form.Group as={Col} sm={12}>
          <InputField
            type="text"
            placeholder={t('place-holder.email')}
            label={t('label.email')}
            name="email"
            isRequired
          />
        </Form.Group>
        <Form.Group as={Col} sm={12}>
          <InputField
            type="text"
            placeholder={t('place-holder.organization-name')}
            label={t('label.organization-name')}
            name="organizationName"
            isRequired
          />
        </Form.Group>
        <Footer>
          <Button
            type="submit"
            variant="outline-primary"
            disabled={props.isSubmitting}
            responsiveFullWidth
          >
            {t('buttons.submit')}
          </Button>
        </Footer>
      </Form>
    </Wrapper>
  );
};

const EnhancedForm = withFormik({
  displayName: 'RequestJoinForm',
  mapPropsToValues: () => {
    return {
      firstName: '',
      lastName: '',
      email: '',
      organizationName: '',
      signUpType: '',
    };
  },
  validationSchema: yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    organizationName: yup.string().required(),
    email: yup.string().email().required(),
  }),
  handleSubmit: async (values, {props, setSubmitting, setErrors}) => {
    const newRequestJoin = {
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      organization_name: values.organizationName,
      url: props.match.url,
      is_from_item: props.isFromItem,
    };

    if (values.signUpType !== 'by_invite') {
      setSubmitting(false);
      if (props.onClose) {
        props.onClose();
      }
      return props.history.push(SIGNUP_PAGE, {
        newRequest: newRequestJoin,
      });
    }

    try {
      await props.api.requestJoin(newRequestJoin);
      setSubmitting(false);
      props.stores.app.removeToasts();
      if (props.onClose) {
        props.onClose();
      }
      props.onSubmit();
    } catch (error) {
      if (error.email || error.organization_name) {
        setErrors({
          email: error.email ? error.email[0] : undefined,
          organizationName: error.organization_name
            ? error.organization_name[0]
            : undefined,
        });
      }
      setSubmitting(false);
    }
  },
})(RequestJoinForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const api = useApi();
  const history = useHistory();
  const stores = useStores();
  const match = useRouteMatch();
  return (
    <EnhancedForm
      api={api}
      history={history}
      match={match}
      stores={stores}
      {...props}
    />
  );
};

export default observer(FormWrapper);
