import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useContext,
} from 'react';
import styled from 'styled-components';

import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import ErrorMessage from './ErrorMessage';
import FormContext from './FormContext';

import theme from '../../theme';

const StylesWrapper = styled.div`
  .form-control {
    border-width: 0 0 2px 0;
    flex-basis: 45%;
    flex-grow: 1;
    text-align: ${(props) =>
      props.labelPosition === 'right' ? 'left' : 'right'};

    :focus {
      box-shadow: none;
      border-width: 0 0 2px 0;
    }

    &:disabled {
      border-style: dotted;
    }

    &.is-invalid {
      border-width: 0 0 2px 0;
      background: none;
      padding-right: 0.75rem;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .form-control:focus {
    box-shadow: none;
    border-width: 0 0 2px 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InlineLabelText = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const InlineLabel = styled(Form.Control)`
  &.form-control {
    ${(props) =>
      props.isFocused ? `border-color: ${theme.primaryColor};` : ''}
    ${(props) => (props.isInvalid ? `border-color: ${theme.danger};` : '')}
    color: #000;
    text-align: ${(props) => props.labelPosition};
    flex: 0;
    max-width: 35%;
  }
`;

const Input = forwardRef((props, ref) => {
  const {inlineLabel, setRef, inlineLabelPosition, format, value, ...rest} =
    props;
  const inputRef = useRef(null);
  const {setFieldRef} = useContext(FormContext);
  const [isFocused, setFocus] = useState(false);
  const labelPosition = inlineLabelPosition || 'right';

  useEffect(() => {
    if (setRef) {
      setRef(inputRef);
    }
  }, [setRef, inputRef]);

  useEffect(() => {
    setFieldRef(props.name, inputRef);
  }, [props.name, setFieldRef]);

  const onInlineLabelClick = () => {
    inputRef.current.focus();
  };
  const inlineLabelContent = props.inlineLabel ? (
    <InlineLabel
      isFocused={isFocused}
      isInvalid={rest.isInvalid}
      onClick={onInlineLabelClick}
      as="div"
      className="form-control"
      labelPosition={labelPosition}
    >
      <OverlayTrigger
        delay={{hide: 450, show: 300}}
        overlay={(props) => <Tooltip {...props}>{inlineLabel}</Tooltip>}
        placement="bottom"
      >
        <InlineLabelText>{inlineLabel}</InlineLabelText>
      </OverlayTrigger>
    </InlineLabel>
  ) : null;
  const onFocus = () => {
    setFocus(true);

    if (rest.onFocus) {
      rest.onFocus();
    }
  };
  const onBlur = () => {
    setFocus(false);

    if (rest.onFocus) {
      rest.onBlur();
    }
  };

  return (
    <StylesWrapper labelPosition={labelPosition}>
      <Container>
        {labelPosition === 'left' && inlineLabelContent}
        <Form.Control
          {...rest}
          value={value || ''}
          onBlur={onBlur}
          onFocus={onFocus}
          ref={inputRef}
          data-hj-allow
        />
        {labelPosition === 'right' && inlineLabelContent}
        <ErrorMessage>{props.error}</ErrorMessage>
      </Container>
    </StylesWrapper>
  );
});

export default Input;
