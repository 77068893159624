import React, {useEffect} from 'react';
import {withFormik} from 'formik';
import {observer} from 'mobx-react';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';

import Form from '../ItemForm/Form';
import {Col} from 'react-bootstrap';
import Label from '../Form/Label';
import Input from '../Form/Input';
import Checkbox from '../Form/Checkbox';
import Radio from '../Form/Radio';
import TextArea from '../Form/TextArea';
import Footer from '../ItemForm/Footer';
import Button from '../Button';
import {getQuantityLabel} from '../../utils/form';
import {useErrorFocus, useTranslatedErrors} from '../../hooks/formik';
import AddressSelectField from '../Fields/AddressSelectField';
import {useStores} from '../../contexts/StoresContext';
import {InputField} from '../Fields';

const OfferSupplyClaimForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    item,
    maxQuantity,
  } = props;
  const {t} = useTranslation('claim');
  const focusOnErrorFields = ['quantity', 'phoneNumber', 'deliveryOptions'];
  const translatedErrors = useTranslatedErrors(errors);
  const fieldRefs = {
    phoneNumber: null,
    quantity: null,
    deliveryOptions: null,
  };
  const setRef = (fieldName) => (ref) => {
    fieldRefs[fieldName] = ref;
  };

  useEffect(() => {
    setFieldValue('maxQuantity', maxQuantity);
  }, [maxQuantity, setFieldValue]);

  useErrorFocus(isSubmitting, errors, focusOnErrorFields, fieldRefs);

  const handleDeliveryOptionsChange = (event) => {
    const selectedOption = event.target.value;
    setFieldValue('deliveryOptions', selectedOption);

    if (selectedOption === 'need_deliver') {
      setFieldValue('coverCosts', false);
      setFieldValue('pickupLocation', '');
    } else {
      setFieldValue('coverCosts', '');
    }
  };
  const handleSetCoverCosts = (event) => {
    setFieldValue('coverCosts', event.target.checked);
  };

  const renderCoverCosts = () => {
    return (
      <Form.Group as={Col} sm={12}>
        <Checkbox
          custom
          label={t('labels.offer.cover-costs')}
          checked={values.coverCosts}
          onChange={handleSetCoverCosts}
          type="checkbox"
          id="coverCosts"
        />
        <AddressSelectField
          name="pickupLocation"
          label={t('labels.offer.delivery-location')}
          isFullWidth={false}
          notEditAddress
          notDeleteAddress
        />
      </Form.Group>
    );
  };

  const deliveryOptions = [
    {
      label: t('labels.offer.pick-up'),
      value: 'pick_up',
    },
    {
      label: t('labels.offer.need-deliver'),
      value: 'need_deliver',
      renderOptionContent: renderCoverCosts,
    },
  ];

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('labels.offer.delivery-options')}</Label>
          <Radio
            name="deliveryOptions"
            options={deliveryOptions}
            value={values.deliveryOptions}
            onChange={handleDeliveryOptionsChange}
            isInvalid={!!errors.deliveryOptions && touched.deliveryOptions}
            error={translatedErrors.deliveryOptions}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('labels.offer.phone-number')}</Label>
          <Input
            name="phoneNumber"
            onChange={handleChange}
            value={values.phoneNumber}
            isInvalid={!!errors.phoneNumber && touched.phoneNumber}
            error={translatedErrors.phoneNumber}
            setRef={setRef('phoneNumber')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label isRequired>{t('labels.offer.quantity')}</Label>
          <Input
            name="quantity"
            placeholder={t('placeholders.item-quantity-placeholder')}
            onChange={handleChange}
            value={values.quantity}
            isInvalid={!!errors.quantity && touched.quantity}
            error={translatedErrors.quantity}
            inlineLabel={getQuantityLabel(
              item.baseUom,
              item.bundle,
              item.deliveryInBundles,
            )}
            setRef={setRef('quantity')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label>{t('forms:labels.beneficiaries-quantity')}</Label>
        </Form.Group>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="individualsQuantity"
            placeholder={t('placeholders.item-quantity-placeholder')}
            inlineLabel={t('common:beneficiaries.individual_plural')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <Label>{t('labels.offer.additional-information')}</Label>
          <TextArea
            rows="6"
            name="additionalInformation"
            placeholder={t('placeholders.offer.additional-information')}
            onChange={handleChange}
            value={values.additionalInformation}
            isInvalid={
              !!errors.additionalInformation && touched.additionalInformation
            }
            error={translatedErrors.additionalInformation}
          />
        </Form.Group>
      </Form.Row>
      <Footer>
        <Button type="submit" variant="primary" className="text-uppercase">
          {t('buttons.offer.claim')}
        </Button>
      </Footer>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'OfferSupplyClaimForm',
  mapPropsToValues: (props) => {
    return {
      quantity: '',
      phoneNumber: props.stores.app.user.phone_number || '',
      additionalInformation: '',
      item: props.item.id,
      coverCosts: '',
      deliveryOptions: '',
      pickupLocation: '',
      maxQuantity: null,
      individualsQuantity: null,
    };
  },
  validationSchema: yup.object().shape({
    quantity: yup
      .number()
      .required()
      .positive()
      .label('quantity')
      .max(yup.ref('maxQuantity')),
    phoneNumber: yup.string().required(),
    additionalInformation: yup.string(),
    coverCosts: yup.boolean(),
    deliveryOptions: yup.string().required(),
    pickupLocation: yup.string().when('deliveryOptions', {
      is: 'need_deliver',
      then: yup.string().required(),
    }),
    individualsQuantity: yup
      .number()
      .label('individuals-quantity')
      .positive()
      .nullable(),
  }),
  handleSubmit: (values, {props}) => {
    props.onSubmit(values);
  },
})(OfferSupplyClaimForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  return <EnhancedForm stores={stores} {...props} />;
};

export default observer(FormWrapper);
