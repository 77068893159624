import styled from 'styled-components';
import {mediaQueries, Z_INDEX} from '../../constants';

const Title = styled.h1`
  z-index: ${Z_INDEX.overlay};
  position: relative;
  margin-top: 40px;
  font-size: 1.75rem;
  font-weight: 600;
  font-family: 'Roboto Slab', serif;

  ${mediaQueries.lg} {
    margin-top: 15px;
    font-size: 20px;
  }
`;

export default Title;
