function flattenChoices(choices) {
  return {
    category: choices.category,
    kind: flattenChoice(choices.kind),
    uom: choices.uom,
    specialHandling: flattenChoice(choices.special_handling),
    addressLocationType: flattenChoice(choices.address_location_type),
  };
}

function flattenChoice(choices) {
  const flattened = {};

  for (const choice of choices) {
    let values = {};
    const choiceName = choice[0];
    const tuples = choice[1];
    if (Array.isArray(tuples)) {
      for (const tuple of tuples) {
        values[tuple[0]] = tuple[1];
      }
    } else {
      values = tuples;
    }
    flattened[choiceName] = values;
  }

  return flattened;
}

export {flattenChoices};
