import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {useItem} from '../../hooks/items';

import {Division, ItemPanel} from '../ItemPanel';
import Center from '../Center';
import Thumbnail from '../Thumbnail';
import ResponsiveImg from '../ResponsiveImg';
import NeedSupplyInformation from '../ItemClaimForm/NeedSupplyInformation';
import NeedServiceInformation from '../ItemClaimForm/NeedServiceInformation';
import OfferDiscountInformation from '../ItemClaimForm/OfferDiscountInformation';
import OfferSupplyInformation from '../ItemClaimForm/OfferSupplyInformation';
import OfferServiceInformation from '../ItemClaimForm/OfferServiceInformation';
import OfferInformationInformation from '../ItemClaimForm/OfferInformationInformation';
import ProgressIndicator from '../ProgressIndicator/ProgressIndicator';
import Spinner from '../Spinner';
import styles from './ItemViewDetails.module.scss';

const ClaimItemDetail = {
  need: {
    supply: NeedSupplyInformation,
    service: NeedServiceInformation,
  },
  offer: {
    discount: OfferDiscountInformation,
    supply: OfferSupplyInformation,
    service: OfferServiceInformation,
    information: OfferInformationInformation,
  },
};

const ItemViewDetails = (props) => {
  const {itemId} = useParams();
  const {defaultDeliveryAddress} = props;
  const [item, isLoading] = useItem(itemId);
  const {t} = useTranslation('forms');

  const breadcrumbs = {
    need: t('breadcrumbs.need'),
    offer: t('breadcrumbs.offer'),
  };

  if (!item || isLoading)
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );
  const DetailsComponent = ClaimItemDetail[item.side][item.kind];
  const progressBar =
    item.kind !== 'discount' && item.kind !== 'information' ? (
      <ProgressIndicator
        total={item.quantity || 0}
        progress={item.claimedQuantity}
        uomFulfillment={item.uomFulfillment}
      />
    ) : null;
  const imageSource = item.image ? item.image.url : '';

  return (
    <ItemPanel
      item={item}
      breadcrumb={breadcrumbs[item.side]}
      title={t('titles.complete-details')}
    >
      {progressBar}
      {imageSource && (
        <React.Fragment>
          <Division />
          <Center column>
            <Thumbnail>
              <ResponsiveImg src={imageSource} alt="" />
            </Thumbnail>
            <a className={styles.link} href={imageSource} target="blank">
              {t('full-size-image')}
            </a>
          </Center>
          <Division />
        </React.Fragment>
      )}
      <DetailsComponent
        item={item}
        defaultShippingAddress={defaultDeliveryAddress}
      />
    </ItemPanel>
  );
};

export default ItemViewDetails;
