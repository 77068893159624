import {useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useClickOutside} from '../../../hooks/utils';
import SavedViewDropdown from './SavedViewDropdown';
import styles from './../shared.module.scss';

const SavedViewButton = (props) => {
  const {setSavedViewToastText} = props;
  const {t} = useTranslation('item-reports');
  const wrapperRef = useRef(null);
  const [showSavedViewDropdown, setShowSavedViewDropdown] = useState(false);
  const [showSavedViewModal, setShowSavedViewModal] = useState(false);
  const [showChangeViewWarning, setShowChangeViewWarning] = useState(false);
  const [showDeleteViewWarning, setShowDeleteViewWarning] = useState(false);

  const onClose = () => {
    setShowSavedViewModal(false);
    setShowSavedViewDropdown(false);
    setShowChangeViewWarning(false);
    setShowDeleteViewWarning(false);
  };

  const handleSavedViewButtonClick = () => {
    setShowSavedViewDropdown(!showSavedViewDropdown);
  };

  const handleClickOutside = () =>
    showSavedViewDropdown && setShowSavedViewDropdown(false);

  useClickOutside(wrapperRef, handleClickOutside);

  return (
    <div ref={wrapperRef}>
      <div
        className={styles.dropdownButton}
        onClick={handleSavedViewButtonClick}>
        <div className={styles.dropdownButtonText}>
          {t('saved_view.button')}
        </div>
        <img
          src="https://fonts.gstatic.com/s/i/short-term/release/googlesymbols/arrow_drop_down/default/20px.svg"
          alt="down arrow"
          className={styles.dropdownIcon}
        />
      </div>
      {showSavedViewDropdown && (
        <SavedViewDropdown
          onClose={onClose}
          showSavedViewModal={showSavedViewModal}
          showChangeViewWarning={showChangeViewWarning}
          showDeleteViewWarning={showDeleteViewWarning}
          setShowSavedViewModal={setShowSavedViewModal}
          setSavedViewToastText={setSavedViewToastText}
          setShowChangeViewWarning={setShowChangeViewWarning}
          setShowDeleteViewWarning={setShowDeleteViewWarning}
        />
      )}
    </div>
  );
};

export default SavedViewButton;
