import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import ClaimInformation from './ClaimInformation';
import ClaimConfirmationForm from './ClaimConfirmationForm';
import {useApi} from '../../contexts/ApiContext';
import styles from './ItemConfirmForm.module.scss';

const ClaimDetails = (props) => {
  const {onClaimUpdated} = props;
  const [claim, setClaim] = useState(null);
  const {claimId} = useParams();
  const api = useApi();

  useEffect(() => {
    const fetchClaim = async () => {
      const response = await api.getClaimItem(claimId);
      setClaim(response);
    };
    fetchClaim();
  }, [claimId, api]);

  const handleSubmit = async (values) => {
    const response = await api.updateClaimItem(values);
    setClaim(response);
    if (onClaimUpdated) onClaimUpdated();
  };

  if (!claim) return null;

  return (
    <div className={styles.claimDetailsWrapper}>
      <ClaimInformation onBack={props.onBack} claim={claim} />
      {claim.confirmationStatus === 'submitted' && (
        <ClaimConfirmationForm
          item={props.item}
          claim={claim}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default ClaimDetails;
