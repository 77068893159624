import {useStores} from '../../../contexts/StoresContext';
import {useSearchIndexApi} from '../../../hooks/search-index';
import {FALLBACK_LANGUAGE} from '../../../constants';

export function useAlgoliaSearchTemplate({kinds}) {
  const stores = useStores();
  const userLanguage = stores.app.language;
  const searchIndexApi = useSearchIndexApi();

  const searchAlgoliaTemplates = async (value) => {
    const results = await searchIndexApi.search(value, {
      restrictSearchableAttributes: [
        `title.${userLanguage}`,
        'meta_sectors',
        'meta_categories',
      ],
      models: ['needslist_item.ItemTemplate'],
      exactMatch: false,
      distict: false,
      metaKinds: kinds,
    });

    const availableTemplates = results.reduce((accum, hit, index) => {
      const label = (
        hit.title[userLanguage] || hit.title[FALLBACK_LANGUAGE]
      ).trim();

      const exists = accum.some(
        (x) => x.label.toLowerCase() === label.toLowerCase(),
      );

      if (!exists) {
        return [
          ...accum,
          {
            id: hit.id,
            label: label,
            value: label,
            kind: hit.meta_kind,
            position: index + 1,
            objectId: hit.objectID,
          },
        ];
      }

      return [...accum];
    }, []);

    return availableTemplates;
  };

  return {
    searchAlgoliaTemplates,
    searchIndexApi,
  };
}
