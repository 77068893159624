import {useEffect} from 'react';
import {useMenu, useClearRefinements} from 'react-instantsearch';
import {useTranslation} from 'react-i18next';
import {useSearchAlgoliaStore} from '../../../../contexts/SearchAlgoliaContext';
import {ReactComponent as ListIcon} from '../../../../assets/icons/list.svg';
import {ReactComponent as LocationIcon} from '../../../../assets/icons/location.svg';
import styles from './SearchTabs.module.scss';
import {useStores} from '../../../../contexts/StoresContext';

export const SearchTabs = () => {
  const {t} = useTranslation('search');
  const [searchState, searchActions] = useSearchAlgoliaStore();
  const stores = useStores();
  const hideMapView =
    !stores.app.token && stores.app.clientData.map_access !== 'public_view';
  const {items, refine} = useMenu({attribute: 'meta_side'});
  const {refine: refineClearRefinements} = useClearRefinements({
    includedAttributes: ['meta_side'],
  });

  const computeCount = (tab) => {
    if (items.length === 0) return 0;
    if (tab === 'all') {
      let totAll = 0;
      items.forEach((record) => (totAll += record.count));
      return totAll;
    }

    const item = items.filter((record) => record.value === tab);
    return item.length > 0 ? item[0].count : 0;
  };

  const tabs = [
    {
      eventKey: 'all',
      title: t('tabs.all', {
        count: computeCount('all'),
      }),
    },
    {
      eventKey: 'need',
      title: t('tabs.needs', {
        count: computeCount('need'),
      }),
    },
    {
      eventKey: 'offer',
      title: t('tabs.offers', {
        count: computeCount('offer'),
      }),
    },
  ];

  useEffect(() => {
    if (searchState.activeTab === 'all') {
      return refineClearRefinements();
    }
    refine(searchState.activeTab);
  }, [searchState.activeTab, refine, refineClearRefinements]);

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <button
            key={tab.eventKey}
            onClick={() => searchActions.setActiveTab(tab.eventKey)}
            className={
              searchState.activeTab === tab.eventKey ? styles.isActive : ''
            }>
            {tab.title}
          </button>
        ))}
      </div>
      <div className={styles.menuOptions}>
        {searchState.isMapView ? (
          <span onClick={() => searchActions.setIsMapView(false)}>
            {t('search-page:views.list')} <ListIcon />
          </span>
        ) : !hideMapView ? (
          <span onClick={() => searchActions.setIsMapView(true)}>
            {t('search-page:views.map')} <LocationIcon />
          </span>
        ) : null}
      </div>
    </div>
  );
};
