import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {usePageStore} from '../../contexts/PageContext';
import {useStores} from '../../contexts/StoresContext';
import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = (props) => {
  const [pageState] = usePageStore();
  const {t} = useTranslation('common');
  const stores = useStores();

  const organizationName =
    props.showOrganizationName && stores.app.organization
      ? `${stores.app.organization.name} > `
      : '';

  if (!pageState.breadcrumbs) return null;

  return (
    <div className={`${styles.wrapper} ${props.className}`}>
      {`${organizationName}${t(
        `breadcrumbs.${pageState.breadcrumbs}`,
        pageState.breadcrumbsOptions,
      )}`}
    </div>
  );
};

export default observer(Breadcrumbs);
