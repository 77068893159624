import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useParams, useHistory, useRouteMatch} from 'react-router-dom';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {Row, Col} from 'react-bootstrap';
import ItemFiltersWithCategories from '../ItemFiltersWithCategories';
import ItemSearchBar from '../ItemSearchBar';
import Paginator from '../Paginator';
import Items from './Items';
import {getItemsFilters, mediaQueries, breakpoints} from '../../constants';
import {useItemsCount, useItemFilters, useItems} from '../../hooks/items';
import FiltersContext from '../FiltersProvider/FiltersContext';
import {usePageStore} from '../../contexts/PageContext';
import {usePanelStore} from '../../contexts/PanelContext';
import Responsive from '../Responsive';
import ItemFilters from '../ItemFilters/ItemFilters';
import {usePaginator} from '../../hooks/paginator';
import Button from '../Button';
import {ONGOING_ISSUE} from '../../constants';
import {ShareModal, ShareButton, ShareLink} from '../Share';
import ClaimFlow from '../ClaimFlow/ClaimFlow';

const PAGE_SIZE = 15;

const SearchHeader = styled.div`
  padding: 41px 0 20px;
  display: flex;

  ${mediaQueries.lg} {
    padding: 15px 0;
    flex-direction: column-reverse;
  }
`;

const StyledSearchBar = styled(ItemSearchBar)`
  width: ${(props) => (props.isSmall ? '50%' : '85%')};
  margin: ${(props) => (props.isSmall ? '0' : '0 auto')};

  ${mediaQueries.lg} {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  margin: 30px 0 100px;

  ${mediaQueries.lg} {
    margin: 15px 0 30px;
  }
`;

const StyledPaginator = styled(Paginator)`
  margin-top: 40px;

  ${mediaQueries.lg} {
    margin-top: 15px;
    justify-content: center;
  }
`;

const StyledItemFilters = styled(ItemFilters)`
  margin-bottom: 10px;
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;

  ${mediaQueries.lg} {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
`;

const StyledLeftButton = styled(Button)`
  margin-right: 20px;
`;

const ShareContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Search = (props) => {
  const {slug} = useParams();
  const history = useHistory();
  const match = useRouteMatch();
  const {t} = useTranslation('search');
  const {initFilters} = useContext(FiltersContext);
  const [pageState, pageActions] = usePageStore();
  const [, panelActions] = usePanelStore();
  const {changePage, currentPage} = usePaginator();
  const [queryString, initialized] = useItemFilters();
  const {items, count, isLoading, fetchItems} = useItems(
    queryString,
    initialized,
  );
  const {needsCount, offersCount} = useItemsCount(queryString);
  const [showModal, setShowModal] = useState(false);
  const handleToggleModal = () => setShowModal(!showModal);

  useEffect(() => {
    if (!props.currentIssue) {
      pageActions.setBreadcrumbs({key: 'search'});
    }
  }, [pageActions, props.currentIssue]);

  useEffect(() => {
    initFilters({
      issueSlug: slug ? [{label: slug, value: slug}] : [],
    });
  }, [slug, initFilters]);

  const handlePageChange = (to, page) => {
    window.scrollTo(0, 0);
    changePage(to, page);
  };

  const handleItemClick = (item) => {
    changePage(`${match.url}/item/${item.id}`, currentPage);
    panelActions.setPanelMount(true);
  };
  const handleSearch = useCallback(() => {
    fetchItems(true);
  }, [fetchItems]);

  const handleClose = useCallback(() => {
    changePage(match.url, currentPage);
  }, [changePage, currentPage, match.url]);

  const handleAddNeedsClick = () =>
    history.push(`/add-needs?selected_issue=${props.currentIssue.slug}`);
  const handlePostOfferClick = () =>
    history.push(`/add-offers?selected_issue=${props.currentIssue.slug}`);

  const filters = {...getItemsFilters()};
  if (slug) delete filters.issue;

  return (
    <React.Fragment>
      <SearchHeader>
        {!pageState.isPublic && (
          <StyledSearchBar itemFilters={filters} isSmall={props.currentIssue} />
        )}
        {props.currentIssue && !pageState.isPublic && (
          <StyledButtons>
            {props.currentIssue.id !== ONGOING_ISSUE && (
              <StyledLeftButton
                variant="outline-primary"
                className="text-uppercase"
                size="sm"
                onClick={handleAddNeedsClick}
              >
                {t('buttons.add-needs')}
              </StyledLeftButton>
            )}
            <Button
              variant="outline-primary"
              className="text-uppercase"
              size="sm"
              onClick={handlePostOfferClick}
            >
              {t('buttons.post-offers')}
            </Button>
          </StyledButtons>
        )}
      </SearchHeader>
      {!props.currentIssue && (
        <ShareContainer>
          <ShareButton onClick={handleToggleModal} label={t('share-label')} />
          {showModal && (
            <ShareModal onClose={handleToggleModal} label={t('share-label')}>
              <ShareLink />
            </ShareModal>
          )}
        </ShareContainer>
      )}
      <Wrapper>
        <Row>
          <Col lg={3}>
            <Responsive>
              <StyledItemFilters itemFilters={filters} min={breakpoints.lg} />
              <ItemFiltersWithCategories
                max={breakpoints.lg}
                itemFilters={filters}
                ongoingIssue={
                  props.currentIssue && props.currentIssue.id === ONGOING_ISSUE
                }
              />
            </Responsive>
          </Col>
          <Col lg={9}>
            <Items
              items={items}
              onItemClick={handleItemClick}
              isLoading={isLoading}
              needsCount={needsCount}
              offersCount={offersCount}
              currentIssue={props.currentIssue && props.currentIssue.id}
              onGoingIssue={ONGOING_ISSUE}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <StyledPaginator
              total={count}
              pageSize={PAGE_SIZE}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
      </Wrapper>
      <ClaimFlow
        currentIssue={props.currentIssue}
        onClose={handleClose}
        onSearch={handleSearch}
      />
    </React.Fragment>
  );
};

export default observer(Search);
