import React from 'react';
import {useField, useFormikContext} from 'formik';

import CurrencySelect from '../Form/CurrencySelect';
import Label from '../Form/Label';
import {useRemoveError, useTranslatedError} from '../../hooks/formik';

const CurrencyField = (props) => {
  const {name, label, isRequired, readOnly, ...rest} = props;
  const [field, meta] = useField(name);
  const {setFieldValue} = useFormikContext();
  const error = useTranslatedError(meta.error);
  const handleOnChange = (currency) => setFieldValue(field.name, currency.id);
  const {removeError} = useRemoveError(field.name, handleOnChange);

  return (
    <React.Fragment>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <CurrencySelect
        {...field}
        {...rest}
        error={error}
        isInvalid={!!meta.error && meta.touched}
        onChange={removeError}
      />
    </React.Fragment>
  );
};

export default CurrencyField;
