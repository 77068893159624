import React from 'react';
import Checkbox from '../Form/Checkbox';
import styles from './CheckboxList.module.scss';

const CheckboxList = (props) => {
  const {value, options, onChange} = props;

  const renderOption = (option) => {
    const containsOption =
      value.find((value) => value === option.value) || false;

    const handleChange = () => {
      const newValue = containsOption
        ? value.filter((value) => value !== option.value)
        : [...value, option.value];
      onChange(newValue);
    };

    return (
      <div className={styles.option} key={option.value}>
        <Checkbox
          id={`checkbox-list-${option.value}`}
          value={option.value}
          checked={containsOption}
          onChange={handleChange}
          label={option.label}
          custom
        />
      </div>
    );
  };

  return <div className={styles.wrapper}>{options.map(renderOption)}</div>;
};

export default CheckboxList;
