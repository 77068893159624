import styles from './AddFilterButton.module.scss';
import PlusIcon from '../../assets/icons/plus-v2.svg';
import {useState, useCallback, useMemo} from 'react';
import FilterControl, {FILTERS} from './FilterControl';
import {useTranslation} from 'react-i18next';
import {DateFilterImpl} from './filterUtils';
import {getLookupDataByKey} from '../../utils';

const AddFilterButton = (props) => {
  // TODO: check why the corners in the UI are not very nice.
  const {t} = useTranslation('item-reports');
  const {selected, selectFilter, url, isAdmin, cachedLookupData} = props;
  const [currentFilter, setCurrentFilter] = useState('');
  const queryStringParams = useMemo(() => new URLSearchParams(url), [url]);

  const cancelClick = useCallback(() => {
    selectFilter('');
    setCurrentFilter('');
  }, [selectFilter, setCurrentFilter]);

  window.addEventListener('click', (e) => {
    const filterContainer = document.getElementById('addFilterContainer');
    const filterButtons = Array.from(
      document.getElementsByName('filterButton'),
    );

    if (
      filterContainer &&
      !filterContainer.contains(e.target) &&
      filterButtons &&
      !filterButtons.some((filterButton) => filterButton.contains(e.target)) &&
      !window.location.search.includes(e.target.getAttribute('type'))
    ) {
      cancelClick();
    }
  });

  const addFilterClick = useCallback(
    (type) => {
      if (type !== DateFilterImpl.type) {
        if (queryStringParams.get(type)) {
          selectFilter(type + '_0');
          return;
        }
      }
      setCurrentFilter(type);
    },
    [queryStringParams, selectFilter],
  );

  return (
    <div className={styles.wrapper} id="addFilterContainer">
      <div className={styles.addFilterContainer}>
        <div
          className={
            styles.addFilterInnerContainer +
            (selected ? ' ' + styles.selected : '')
          }
          onClick={() => {
            selectFilter(selected ? '' : 'addFilter');
            setCurrentFilter('');
          }}
        >
          <div className={styles.addFilterTextContainer}>
            <span className={styles.addFilterText}>
              {t('filters.add-filter')}
            </span>
          </div>
          <img src={PlusIcon} className={styles.icon} alt="Filter icon" />
        </div>
        {selected && (
          <div className={styles.popupContainer}>
            <div className={styles.popupContainerInner}>
              <div className={styles.popupList}>
                {Object.keys(FILTERS)
                  .filter(
                    (value) =>
                      value !== 'exclude_ids' &&
                      (isAdmin || value !== 'organization'),
                  )
                  .map((type) => (
                    <div
                      key={'add_filter_' + type}
                      type={type}
                      className={styles.popupListItem}
                      onClick={() => addFilterClick(type)}
                    >
                      <div>
                        <span type={type}>{t('filters.options.' + type)}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {currentFilter && (
              <div className={styles.subFilterContainer}>
                <FilterControl
                  type={currentFilter}
                  cancelClick={cancelClick}
                  onRemoveUrl={url}
                  lookupData={getLookupDataByKey(
                    currentFilter,
                    cachedLookupData,
                  )}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddFilterButton;
