import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRouteMatch, useHistory} from 'react-router-dom';

import {usePageStore} from '../../contexts/PageContext';
import SuggestedItemsList from './SuggestedItemsList';
import Breadcrumbs from '../Layout/Breadcrumbs';
import {useApi} from '../../contexts/ApiContext';
import Item from '../Item';
import ThankYou from './ThankYou';
import Spinner from '../Spinner';
import styles from './SuggestedItemsPanel.module.scss';

const SuggestedItemsPanel = (props) => {
  const queryStringParams = new URLSearchParams(props.location.search);
  const api = useApi();
  const success = queryStringParams.get('success');
  const claimedItemId = parseInt(queryStringParams.get('claimed_item'), 10);
  const match = useRouteMatch();
  const history = useHistory();
  const [item, setItem] = useState(null);
  const [suggestedItems, setSuggestedItems] = useState(props.suggestedItems);
  const [claimedItem, setClaimedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(props.suggestedItems.length === 0);
  const [, pageActions] = usePageStore();
  const {t} = useTranslation(['forms', 'search']);
  const isOffer = item && item.side === 'offer';
  const title = t(`forms:titles.suggested-${isOffer ? 'needs' : 'offers'}`);

  useEffect(() => {
    pageActions.setBreadcrumbs({
      key: `suggested-${isOffer ? 'needs' : 'offers'}`,
    });
  }, [pageActions, isOffer]);

  useEffect(() => {
    const fetchItem = async () => {
      const response = await api.getItem(props.match.params.itemId);
      setItem(response);
    };
    fetchItem();
  }, [props.match.params.itemId, api]);

  useEffect(() => {
    if (suggestedItems.length === 0 && props.issueResponse && item) {
      const fetchSuggestedItems = async () => {
        const response = await api.getItems({
          search: [item.name, item.description],
          side: item.side === 'offer' ? 'need' : 'offer',
          status: ['open', 'in_progress'],
          kind: [item.kind],
          issue_id: [props.issueResponse.issue.id],
          suggested: true,
          // category: [item.category],
        });
        setSuggestedItems(response.results);
        setIsLoading(false);
      };
      fetchSuggestedItems();
    }
  }, [item, suggestedItems.length, claimedItemId, props.issueResponse, api]);

  useEffect(() => {
    if (suggestedItems.length > 0 && claimedItemId) {
      setIsLoading(true);
      const fetchClaimedItem = async () => {
        const existingClaimedItem = suggestedItems.splice(
          suggestedItems.findIndex((item) => item.id === claimedItemId),
          1,
        );
        if (existingClaimedItem.length > 0) {
          setClaimedItem(existingClaimedItem[0]);
        } else {
          const claimedItem = await api.getItem(claimedItemId);
          setClaimedItem(claimedItem);
        }
        setIsLoading(false);
      };

      fetchClaimedItem();
    }
  }, [claimedItemId, suggestedItems, api]);

  const handleButtonClick = (item) => {
    history.push(`${match.url}/item/${item.id}`);
    pageActions.setIsSuggestedItem(true);
  };

  const handleClaimedItemClick = () => {
    history.push(`${match.url}/item/${claimedItem.id}`);
  };

  if (!item)
    return (
      <div className={styles.spinnerContainer}>
        <Spinner />
      </div>
    );

  return (
    <React.Fragment>
      <div className={styles.header}>
        <Breadcrumbs />
        <h3 className={styles.title}>{title}</h3>
      </div>
      {success && claimedItem && (
        <div className={styles.claimedItemWrapper}>
          <ThankYou side={isOffer ? 'need' : 'offer'} />
          <Item
            className={styles.item}
            item={claimedItem}
            onButtonClick={handleClaimedItemClick}
            buttonText={t('search:cards.buttons.learn-more')}
            hideErrors={false}
            isReduced
          />
        </div>
      )}
      {suggestedItems.length > 0 && (
        <React.Fragment>
          <h3 className={styles.suggestedItemsTitle}>
            {item &&
              item.side &&
              (item.side === 'offer'
                ? t('search:subtitle.suggested-needs')
                : t('search:subtitle.suggested-offers'))}
          </h3>
          <SuggestedItemsList
            items={suggestedItems}
            showInvalidItems={false}
            isLoading={isLoading}
            onButtonClick={handleButtonClick}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SuggestedItemsPanel;
