import React, {useState, useEffect, useCallback} from 'react';
import {useField, useFormikContext} from 'formik';
import {useRemoveError} from '../../hooks/formik';
import Label from '../Form/Label';
import LanguageSelect from '../Form/LanguageSelect';
import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';

const LanguageField = (props) => {
  const {name, label, ...rest} = props;
  const [languages, setLanguages] = useState([]);
  const api = useApi();
  const stores = useStores();
  const [field] = useField(name);
  const {setFieldValue} = useFormikContext();
  const handleOnChange = useCallback(
    (language) => {
      setFieldValue(field.name, language.id);
    },
    [field.name, setFieldValue],
  );
  const {removeError} = useRemoveError(field.name, handleOnChange);

  const fetchLanguages = useCallback(async () => {
    const response = await api.getLanguages();
    stores.app.setLanguages(response);
    setLanguages(response);
  }, [api, stores.app]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  useEffect(() => {
    const language = languages.find(
      (language) => language.code === stores.app.language,
    );
    language && handleOnChange(language);
  }, [languages, stores.app.language, handleOnChange]);

  return (
    <>
      {label && <Label>{label}</Label>}
      <LanguageSelect
        {...field}
        {...rest}
        languages={languages}
        onChange={removeError}
      />
    </>
  );
};

export default LanguageField;
