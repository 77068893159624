import React, {useEffect, useState} from 'react';
import {useStores} from '../../contexts/StoresContext';
import SelectField from './SelectField';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

const TagSelectField = ({name, label, category = null}) => {
  const stores = useStores();
  const {t} = useTranslation('forms');
  const [tagsOptions, setTagsOptions] = useState(null);
  useEffect(() => {
    const tags = category
      ? stores.app.choices.tag?.filter(
          (tag) =>
            tag.tag_type === name &&
            tag.categories.some((t) => parseInt(t.id) === parseInt(category)),
        )
      : stores.app.choices.tag?.filter((tag) => tag.tag_type === name);
    setTagsOptions(tags);
  }, [category, stores.app.choices.tag, name]);

  if (!tagsOptions || !tagsOptions.length) return null;

  return (
    <React.Fragment>
      <SelectField name={name} label={label} options={stores.app.choices.tag}>
        <option value="">{t('select-option')}</option>
        {tagsOptions.map((tag) => {
          return (
            <option key={tag.id} value={tag.id}>
              {tag.name}
            </option>
          );
        })}
      </SelectField>
    </React.Fragment>
  );
};

export default observer(TagSelectField);
