import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {
  Card,
  Dropdown,
  Tabs,
  Tab,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import {
  faCheckCircle,
  faExclamationTriangle,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {breakpoints} from '../../constants';
import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';
import DotMenu from '../DotMenu';
import Dialog from '../Dialog';
import Table from '../Table';
import CardsList from '../CardsList/CardsList';
import Responsive from '../Responsive';
import styles from './TemplateList.module.scss';

const TemplateList = ({
  extraHeaders,
  extraColumns,
  pageSize,
  tabs,
  onTabSelected,
  onDelete,
  editPage,
}) => {
  const {t} = useTranslation(['user', 'common', 'item']);
  const history = useHistory();
  const [itemToDelete, setItemToDelete] = useState(null);
  const [clientLanguages, setClientLanguages] = useState([]);
  const stores = useStores();
  const api = useApi();

  useEffect(() => {
    const fetchData = async () => {
      const clientData = await api.getClientData();
      setClientLanguages(clientData.languages);
    };
    fetchData();
  }, [api]);

  const renderMoreOptions = useCallback(
    (item) => {
      const handleEdit = () => {
        history.push(editPage.replace(':id', item.id));
      };

      return (
        <DotMenu>
          <Dropdown.Item onClick={handleEdit}>
            <FontAwesomeIcon icon={faPencilAlt} /> {t('user:buttons.edit')}
          </Dropdown.Item>
        </DotMenu>
      );
    },
    [t, history, editPage],
  );

  const renderDeleteDialog = () => {
    const handleOnCancel = () => {
      setItemToDelete(null);
    };

    const handleOnConfirm = async () => {
      try {
        window.scrollTo(0, 0);
        await onDelete(itemToDelete.id);
        setItemToDelete(null);
        stores.app.addToast({
          type: 'success',
          title: t('item:item-template.item-deleted'),
        });
      } catch (error) {
        stores.app.removeToasts();
        stores.app.addToast({
          type: 'alert',
          title: error.error,
        });
        setItemToDelete(null);
      }

      setTimeout(() => stores.app.removeToasts(), 5000);
    };

    return (
      <Dialog
        header={t('common:modal.header.warning')}
        title={t('common:modal.title.warning')}
        message={t('item:item-template.modal-delete.message', {
          itemName:
            itemToDelete.name[stores.app.user.language.code] !== ''
              ? itemToDelete.name[stores.app.user.language.code]
              : itemToDelete.name[itemToDelete.name.original_language],
        })}
        editModal={false}
        confirmText={t('common:buttons.accept')}
        cancelText={t('common:buttons.cancel')}
        onConfirm={handleOnConfirm}
        onCancel={handleOnCancel}
      />
    );
  };

  const templateHeaders = useMemo(
    () => [
      {
        title: t('user:columns.template-list.id-hash'),
        size: 1,
        className: styles.columnText,
      },
      {
        title: t('user:columns.template-list.name'),
        size: 5,
        className: styles.columnText,
      },
      ...extraHeaders,
      {
        title: t('user:columns.template-list.status'),
        size: 2,
      },
    ],
    [t, extraHeaders],
  );

  const templateColumns = useMemo(
    () => [
      {
        renderCell: (item) => item.id,
        prefix: () => (
          <strong>{t('user:columns.template-list.id')}:&nbsp;</strong>
        ),
        size: 1,
        className: styles.columnText,
      },
      {
        renderCell: (item) =>
          item.name[stores.app.user.language.code] !== ''
            ? item.name[stores.app.user.language.code]
            : item.name[item.name.original_language],
        prefix: () => (
          <strong>{t('user:columns.template-list.name')}:&nbsp;</strong>
        ),
        size: 5,
        className: styles.columnText,
      },
      ...extraColumns,
      {
        renderCell: (item) => {
          const status = item.name.status;
          const languages = clientLanguages.filter((language) =>
            Object.keys(status).includes(language.code),
          );
          const renderValues = (language, index) => (
            <span key={index} className={styles.columnText}>
              {`${language.code} = ${t(
                'item:translation.status.' + status[language.code],
              )}`}
            </span>
          );

          return (
            <>
              <span className={styles.langStatus}>
                {languages.map(renderValues)}
              </span>
              <div className={styles.overlay}>
                <OverlayTrigger
                  delay={{hide: 450, show: 300}}
                  overlay={(props) => (
                    <Tooltip {...props}>{languages.map(renderValues)}</Tooltip>
                  )}
                  placement="bottom">
                  {Object.values(status).find((i) => i !== 'approved') ? (
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      color="#ffcc00"
                    />
                  ) : (
                    <FontAwesomeIcon icon={faCheckCircle} color="#339900" />
                  )}
                </OverlayTrigger>
              </div>
            </>
          );
        },
        prefix: () => (
          <strong>{t('user:columns.template-list.status')}:&nbsp;</strong>
        ),
        size: 2,
        className: styles.columnIcon,
      },
      {
        renderCell: renderMoreOptions,
        moreOptions: true,
        className: styles.columnMoreOptions,
        newColumn: true,
        size: 1,
      },
    ],
    [
      renderMoreOptions,
      stores.app.user.language.code,
      extraColumns,
      clientLanguages,
      t,
    ],
  );

  return (
    <div className={styles.container}>
      <Tabs
        mountOnEnter
        unmountOnExit
        onSelect={onTabSelected}
        className={styles.tabs}>
        {tabs.map((tab) => (
          <Tab key={tab.title} eventKey={tab.eventKey} title={tab.title}>
            <h4>{tab.title}</h4>
            <Card className={styles.card}>
              <Responsive>
                <Table
                  max={breakpoints.md}
                  headers={templateHeaders}
                  columns={templateColumns}
                  data={tab.data}
                  count={tab.count}
                  pageSize={pageSize}
                  paginatorV2={true}
                />
              </Responsive>
              <Responsive>
                <CardsList
                  min={breakpoints.md}
                  data={tab.data}
                  columns={templateColumns}
                  count={tab.count}
                  pageSize={pageSize}
                  moreOptions={renderMoreOptions}
                />
              </Responsive>
            </Card>
          </Tab>
        ))}
      </Tabs>
      {itemToDelete && renderDeleteDialog()}
    </div>
  );
};

export default TemplateList;
