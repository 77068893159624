import React, {useMemo} from 'react';
import styled from 'styled-components';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {Col} from 'react-bootstrap';
import {useErrorFocus} from '../../hooks/formik';
import {useAvailableForOptions} from '../../hooks/utils';
import {useStores} from '../../contexts/StoresContext';
import {createTranslationKey} from '../../utils/i18n';
import Form from '../ItemForm/Form';
import Footer from '../ItemForm/Footer';
import Button from '../Button';
import {
  CategorySelectField,
  InputField,
  CheckboxField,
  RadioField,
  TextAreaField,
  ImageUploadField,
  FileUploadField,
} from '../Fields';
import DatePickerField from '../Fields/DatePickerField';
import {normalizeFloatNumber} from '../../utils';
import {useLocaleNumber} from '../../hooks/number';
import {marketValueValidation} from '../../utils/yupCustomFunctions';

const BottomAlignedGroup = styled(Form.Group)`
  ${(props) =>
    props.readOnly
      ? `
    display: flex;
    align-items: flex-end;
  `
      : ''};
`;

const OfferInformationForm = (props) => {
  const {values, errors, setFieldValue, handleSubmit, isSubmitting, item} =
    props;

  const focusOnErrorFields = [
    'category',
    'availableFor',
    'additionalInformation',
    'contactEmail',
    'marketValue',
    'contactName',
    'url',
  ];
  const fieldRefs = {
    category: null,
    availableFor: null,
    additionalInformation: null,
    contactEmail: null,
    marketValue: null,
    contactName: null,
    url: null,
  };

  const {t} = useTranslation(['forms', 'add-items']);
  const localeNumber = useLocaleNumber();
  const setFieldRef = (field) => (ref) => (fieldRefs[field] = ref);
  const isDone = useMemo(
    () => item.status === 'done' || moment(item.availableUntil).isBefore(),
    [item.status, item.availableUntil],
  );
  const isInProgress = item.status === 'in_progress';
  const isEditable =
    item.status === 'open' || item.status === 'on_hold' || isInProgress;
  const isMarketValueEditable =
    item.status === 'complete' ||
    item.status === 'incomplete' ||
    item.status === 'open';
  const availableForOptions = useAvailableForOptions();

  useErrorFocus(isSubmitting, errors, focusOnErrorFields, fieldRefs);

  const handleSetExpiration = (event) => {
    const checked = event.target.checked;
    setFieldValue('setExpiration', event.target.checked);

    if (!checked) {
      setFieldValue('availableUntil', '');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <CategorySelectField
            name="category"
            setRef={setFieldRef('category')}
            label={t('forms:labels.category')}
            readOnly={isDone}
            kind={item.kind}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <TextAreaField
            name="additionalInformation"
            rows="6"
            placeholder={t(
              'forms:labels.information.offer.additional-information-placeholder',
            )}
            label={t('forms:labels.information.offer.additional-information')}
            setRef={setFieldRef('additionalInformation')}
            readOnly={isDone}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="marketValue"
            label={
              item.currency === props.stores.app.currency.id
                ? t('forms:labels.information.offer.item-market-value')
                : t(
                    'forms:labels.information.offer.item-market-value-different-currency',
                    {currency: props.stores.app.currency.code},
                  )
            }
            placeholder={t(
              'forms:labels.information.offer.item-market-value-placeholder',
            )}
            setRef={setFieldRef('marketValue')}
            inlineLabel={props.stores.app.currency.code}
            readOnly={!isMarketValueEditable}
            format={localeNumber}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="contactName"
            label={t('forms:labels.information.offer.item-contact-name-label')}
            placeholder={t(
              'forms:labels.information.offer.item-contact-name-label',
            )}
            setRef={setFieldRef('contactName')}
            readOnly={isDone}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="contactEmail"
            label={t('labels.email')}
            readOnly={isDone || isInProgress}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="phoneNumber"
            label={t('forms:labels.phone-number')}
            readOnly={isDone || isInProgress}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="url"
            label={t('forms:labels.information.offer.url')}
            setRef={setFieldRef('url')}
            readOnly={isDone}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <ImageUploadField
            name="image"
            label={t('forms:labels.information.offer.image')}
            readOnly={isDone}
            noRequirementImage
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <FileUploadField
            name="files"
            label={t('forms:labels.information.offer.file')}
            readOnly={isDone}
            multiple
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <BottomAlignedGroup as={Col} sm={6} className="form-group">
          <CheckboxField
            custom
            name="setExpiration"
            label={t('forms:labels.information.offer.set-expiration')}
            onChange={handleSetExpiration}
            readOnly={isDone}
          />
        </BottomAlignedGroup>
        {values.setExpiration && (
          <Form.Group as={Col} sm={6}>
            <DatePickerField
              name="availableUntil"
              label={t('forms:labels.information.offer.item-available-until')}
              disabled={!values.setExpiration}
              minDate={new Date()}
              showDisabledMonthNavigation
              placeholder={t(
                'forms:labels.information.offer.item-available-until-placeholder',
              )}
              readOnly={isDone}
            />
          </Form.Group>
        )}
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <RadioField
            name="availableFor"
            label={t('forms:labels.information.offer.item-available-for')}
            options={availableForOptions}
            setRef={setFieldRef('availableFor')}
            readOnly={isDone}
          />
        </Form.Group>
      </Form.Row>
      <Footer>
        {!isDone && (
          <Button type="submit" variant="primary" className="text-uppercase">
            {isEditable
              ? t('forms:buttons.offer.edit-offer')
              : t('forms:buttons.offer.post-offer')}
          </Button>
        )}
      </Footer>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'OfferInformationForm',
  mapPropsToValues: (props) => {
    const item = props.item;
    const {user} = props.stores.app;
    const userFullName = `${user.first_name || ''} ${user.last_name || ''}`;

    return {
      category: item.category ? item.category.id : '',
      additionalInformation: item.description || '',
      contactName: item.contactName || userFullName,
      marketValue: item.marketValue || '',
      contactEmail: item.email || '',
      url: item.url || '',
      image: item.image ? item.image : null,
      files: item.files ? item.files : [],
      setExpiration: !!item.availableUntil,
      availableUntil: item.availableUntil || '',
      availableFor: item.availableFor || 'any',
      phoneNumber: item.phoneNumber
        ? item.phoneNumber
        : user.phone_number || '',
    };
  },
  validationSchema: yup.object().shape({
    category: yup.string().required(),
    additionalInformation: yup.string().required(),
    contactName: yup.string().max(255).required(),
    marketValue: marketValueValidation().label('market-value'),
    contactEmail: yup.string().email(),
    phoneNumber: yup.string().max(30).nullable(),
    url: yup
      .string()
      .url(createTranslationKey('errors.url'))
      .max(512)
      .nullable(),
    image: yup.object().nullable(),
    files: yup.array().min(0),
    setExpiration: yup.bool(),
    availableUntil: yup
      .date()
      .min(new Date())
      .when('setExpiration', {
        is: true,
        then: yup.date().min(new Date()).nullable().required(),
      }),
    availableFor: yup.string().required(),
  }),
  handleSubmit: (values, {props}) => {
    const data = {
      ...values,
      marketValue: normalizeFloatNumber(values.marketValue),
    };

    props.onSubmit(data);
  },
})(OfferInformationForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  return <EnhancedForm stores={stores} {...props} />;
};

export default observer(FormWrapper);
