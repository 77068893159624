import React from 'react';
import styled, {css} from 'styled-components';
import {Card, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

import {ReactComponent as TelephoneIcon} from '../../assets/icons/phone.svg';
import {ReactComponent as MailIcon} from '../../assets/icons/mail.svg';
import {ReactComponent as LocationIcon} from '../../assets/icons/location.svg';
import {Z_INDEX} from '../../constants';
import Paragraph from '../Paragraph';
import CategoryImage from '../CategoryImage';
import ResponsiveImg from '../ResponsiveImg';

const Wrapper = styled(({...rest}) => <Card {...rest} />)`
  padding: 26px 20px 37px 20px;
  overflow: hidden;
  justify-content: space-between;
  color: #000000;
  &.card {
    padding-bottom: 26px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.05);
    border-radius: 15px 0px 15px 15px;
  }
  cursor: pointer;
`;

const TextWrapper = styled.div`
  z-index: ${Z_INDEX.aboveOverlay};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

const HeadWrapper = styled(TextWrapper)`
  flex: 0.5;
  min-height: 61px;
`;

const BodyWrapper = styled(HeadWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Overlay = styled.div`
  position: absolute;
  background-color: #ffffff;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${Z_INDEX.overlay};
`;

const OrganizationType = styled.h3.attrs({as: 'p'})`
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  color: #000000;
  margin: 0;
  z-index: ${Z_INDEX.aboveOverlay};
`;

const Name = styled(Paragraph)`
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  color: #000000;
  margin: 0 0 5px;
  overflow: hidden;
  max-height: 44px;
  z-index: ${Z_INDEX.aboveOverlay};
  letter-spacing: -0.41px;
  padding-right: 20px;
`;

const logoStyles = css`
  margin-right: 5px;
  min-width: 20px;
`;

const StyledTelephoneIcon = styled(TelephoneIcon)`
  ${logoStyles}
`;

const StyledMailIcon = styled(MailIcon)`
  ${logoStyles}
`;

const StyledLocationIcon = styled(LocationIcon)`
  ${logoStyles}
`;

const MemberButton = styled(Button)`
  max-width: 150px;
  margin: 15px 0 9px;

  &.btn {
    border-width: 1px;
  }
`;

const StyledCategoryImage = styled(CategoryImage)`
  width: 50px;
`;

const InfoWrapper = styled.div`
  margin-top: 40px;
  min-height: 50px;
`;

const CompanyLogo = styled.div`
  width: 55px;
  height: 55px;
  border: 4px solid #eeeeee;
  border-radius: 50%;
  padding: 8px;
  overflow: hidden;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Member = (props) => {
  const {t} = useTranslation(['related-members', 'common']);
  const member = props.member;
  const firstCategory = member.categories[0] ? member.categories[0].image : '';
  const location = member.location
    ? member.location.city
      ? `${member.location.city}, ${member.location.country || ''}`
      : `${member.location.country || ''}`
    : '';

  const onClick = () => {
    props.onClick(member);
  };

  return (
    <Wrapper className={props.className} onClick={onClick}>
      <Overlay />
      <HeadWrapper>
        <Name lines={2} text={member.name} />
        <StyledCategoryImage src={firstCategory} />
        <OrganizationType>
          {t(`common:organization.${member.org_kind}`)}
        </OrganizationType>
      </HeadWrapper>
      <BodyWrapper>
        <InfoWrapper>
          {member.location && (
            <TextWrapper>
              <StyledLocationIcon />
              {location}
            </TextWrapper>
          )}
          {member.email && (
            <TextWrapper>
              <StyledMailIcon />
              {member.email}
            </TextWrapper>
          )}
          {member.phone_number && (
            <TextWrapper>
              <StyledTelephoneIcon />
              {member.phone_number}
            </TextWrapper>
          )}
        </InfoWrapper>
        <FooterWrapper>
          <MemberButton
            variant="outline-primary"
            className="text-uppercase"
            onClick={onClick}
            size="sm"
          >
            {t('related-members:buttons.learn-more')}
          </MemberButton>
          {member.logo && (
            <CompanyLogo>
              <ResponsiveImg src={member.logo} />
            </CompanyLogo>
          )}
        </FooterWrapper>
      </BodyWrapper>
    </Wrapper>
  );
};

export default Member;
