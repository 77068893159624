import {useState, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {FormikProvider, useFormik} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faDownload,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import Form from '../ItemForm/Form';
import Dialog from '../Dialog';
import {InputField, TextAreaField} from '../Fields';
import styles from './TemplateEdit.module.scss';

const Accordion = ({
  id,
  langCode,
  item,
  status,
  updateTranslationStatus,
  setShowDialog,
}) => {
  const {t} = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: item.name[langCode],
      description: item.description[langCode],
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(`${t('forms:errors.required')}`),
      description: yup.string(),
    }),
    onSubmit: async (values) => {
      const formValues = {
        ...item,
        name: {...item.name, [langCode]: values['name']},
        description: {...item.description, [langCode]: values['description']},
      };
      try {
        await updateTranslationStatus(id, langCode, 'approve', formValues);
        setShowDialog(true);
        setIsActive(false);
        setApproveModal(false);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {values, initialValues, resetForm, handleSubmit} = formik;

  const approveDisabled =
    status === 'approved' &&
    initialValues.name === values.name &&
    initialValues.description === values.description;

  const handleCancel = useCallback(() => {
    setIsActive(false);
    resetForm();
    setCancelModal(false);
  }, [resetForm]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit} className={styles.accordionForm}>
        <div
          onClick={() => !isActive && setIsActive(!isActive)}
          className={
            isActive ? `${styles.tableRow} ${styles.isActive}` : styles.tableRow
          }
        >
          <span className={styles.tableColumn}>
            {langCode ? t(`common:languages.${langCode}`) : '-'}
          </span>
          {isActive ? (
            <>
              <div className={styles.actionButtons}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setCancelModal(true);
                  }}
                >
                  {t('common:buttons.cancel')}
                  <FontAwesomeIcon icon="times" size="lg" />
                </button>
                <button
                  disabled={approveDisabled}
                  onClick={(e) => {
                    e.preventDefault();
                    setApproveModal(true);
                  }}
                >
                  {t('forms:buttons.approve')}
                  <FontAwesomeIcon icon={faDownload} />
                </button>
              </div>
              <div
                className={styles.arrowContainer}
                onClick={() => setIsActive(!isActive)}
              >
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={styles.arrowUp}
                />
              </div>
            </>
          ) : (
            <>
              <span className={styles.tableColumn}>{item.name[langCode]}</span>
              <span className={styles.tableColumn}>
                {t('item:translation:status:' + status)}
              </span>
              <div className={`${styles.tableColumn} ${styles.arrowContainer}`}>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </>
          )}
        </div>
        {isActive && (
          <div className={styles.formFields}>
            {status === 'pending_approval' && (
              <div className={styles.suggestedTranslation}>
                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                {t('manage-items:suggested-translation')}
              </div>
            )}
            <div className={styles.nameInputContainer}>
              <Form.Label>{`${t('forms:fields.name')}:`}</Form.Label>
              <InputField
                name="name"
                placeholder={t('forms:fields.name')}
                language={langCode}
              />
            </div>
            <div className={styles.descriptionInputContainer}>
              <Form.Label>{`${t('forms:fields.description')}:`}</Form.Label>
              <TextAreaField
                name="description"
                rows={6}
                as="textarea"
                placeholder={t('forms:fields.description')}
                language={langCode}
              />
            </div>
          </div>
        )}
      </Form>
      {(approveModal || cancelModal) && (
        <Dialog
          title={
            approveModal
              ? t('manage-items:warning-dialog.approve')
              : t('manage-items:warning-dialog.discard')
          }
          confirmText={t('common:yes')}
          cancelText={t('common:no')}
          onConfirm={approveModal ? handleSubmit : handleCancel}
          onCancel={() =>
            approveModal ? setApproveModal(false) : setCancelModal(false)
          }
        />
      )}
    </FormikProvider>
  );
};

export default Accordion;
