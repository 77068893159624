import {useState, useEffect, useCallback, useContext} from 'react';
import {useLocation} from 'react-router-dom';

import {useApi} from '../contexts/ApiContext';
import {removeEmptyKeys, isEqual} from '../utils/object';
import {getValueFromQS} from '../utils/querystring';
import {usePrevious} from './utils';
import FiltersContext from '../components/FiltersProvider/FiltersContext';

const useItems = (queryString, filtersInitialized) => {
  const api = useApi();
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const previousQueryString = usePrevious(queryString);
  const fetchItems = useCallback(
    (forceFetch) => {
      const doRequest = async () => {
        setIsLoading(true);
        const response = await api.getItems(queryString);
        setItems(response.results);
        setCount(response.count);
        setIsLoading(false);
      };
      // Comparing objects using JSON.stringify
      if (
        (!isEqual(queryString, previousQueryString) || forceFetch) &&
        filtersInitialized
      ) {
        doRequest();
      }
    },
    [filtersInitialized, queryString, previousQueryString, api],
  );

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return {items, count, isLoading, fetchItems};
};

const useClaimedItems = (side = 'need') => {
  const api = useApi();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchItems = useCallback(() => {
    const doRequest = async () => {
      setIsLoading(true);
      const response = await api.getClaimedItems({side});
      setItems(response.results);
      setIsLoading(false);
    };
    doRequest();
  }, [side, api]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  return {items, isLoading, fetchItems};
};

const useItem = (itemId) => {
  const [item, setItem] = useState(null);
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);

  const fetchItem = useCallback(() => {
    const doRequest = async () => {
      setIsLoading(true);
      const response = await api.getItem(itemId);
      setItem(response);
      setIsLoading(false);
    };
    doRequest();
  }, [itemId, api]);

  useEffect(() => {
    fetchItem();
  }, [fetchItem]);

  return [item, isLoading, fetchItem];
};

const useItemFilters = (pageSize = 15) => {
  const location = useLocation();
  const {filters, initialized} = useContext(FiltersContext);
  const [queryString, setQueryString] = useState(
    getQueryStringByFilters(filters, pageSize),
  );

  const updateQueryString = useCallback(
    (filters) => {
      setQueryString(getQueryStringByFilters(filters, pageSize));
    },
    [setQueryString, pageSize],
  );

  useEffect(() => {
    updateQueryString(filters, pageSize);
  }, [filters, updateQueryString, pageSize]);

  useEffect(() => {
    updateQueryString(filters, pageSize);
  }, [filters, updateQueryString, pageSize, location.search]);

  useEffect(() => {
    updateQueryString(filters);
  }, [filters, updateQueryString]);

  return [queryString, initialized];
};

const useItemsCount = (queryString) => {
  const api = useApi();
  const [itemsCount, setItemsCount] = useState({});
  const previousQueryString = usePrevious(queryString);

  useEffect(() => {
    const cleanedQueryString = cleanUpFiltersForCount(queryString);
    const previousCleanedQueryString =
      cleanUpFiltersForCount(previousQueryString);

    const getItemsCount = async () => {
      const response = await api.countItems(cleanedQueryString);
      setItemsCount(response);
    };
    if (!isEqual(cleanedQueryString, previousCleanedQueryString)) {
      getItemsCount();
    }
  }, [queryString, previousQueryString, api]);

  return {
    needsCount: itemsCount.needsCount,
    offersCount: itemsCount.offersCount,
  };
};

function cleanUpFiltersForCount(filters) {
  const ignoredKeys = ['side', 'page', 'page_size'];
  return Object.keys(filters || {}).reduce((cleaned, key) => {
    if (ignoredKeys.find((ignoredKey) => key === ignoredKey)) {
      return cleaned;
    }

    return {
      ...cleaned,
      [key]: filters[key],
    };
  }, {});
}

function getQueryStringByFilters(itemFilters, pageSize) {
  if (!itemFilters) return {};

  const getValueFromFilters = (filters) =>
    filters && filters.length ? filters.map((filter) => filter.value) : '';

  const parsedFilters = {
    search: getValueFromFilters(itemFilters.search),
    organization_id: getValueFromFilters(itemFilters.organization),
    organization_name: getValueFromFilters(itemFilters.organizationName),
    issue_id: getValueFromFilters(itemFilters.issue),
    issue_name: getValueFromFilters(itemFilters.issueName),
    issue_slug: getValueFromFilters(itemFilters.issueSlug),
    postal_code: getValueFromFilters(itemFilters.postalCode),
    city: getValueFromFilters(itemFilters.city),
    country: getValueFromFilters(itemFilters.country),
    state: getValueFromFilters(itemFilters.state),
    side: getValueFromFilters(itemFilters.side),
    which: getValueFromFilters(itemFilters.which),
    category: itemFilters.category || '',
    sort_by: itemFilters.sortByDate || '',
    include_global_issue: itemFilters.includeGlobalIssue || 'include',
    status: itemFilters.status || '',
    item_type: itemFilters.itemType || '',
    page: parseInt(getValueFromQS('page', 1)),
    page_size: pageSize,
  };

  return removeEmptyKeys(parsedFilters);
}

export {useItems, useClaimedItems, useItem, useItemFilters, useItemsCount};
