import React from 'react';
import {useField, useFormikContext} from 'formik';
import {Col} from 'react-bootstrap';

import {useRemoveError, useTranslatedError} from '../../hooks/formik';

import MultipleChecks from '../MultipleChecks/MultipleChecks';
import Label from '../Form/Label';
import ErrorMessage from '../Form/ErrorMessage';

const MultiCheckField = (props) => {
  const [field, meta] = useField(props);
  const [otherField] = useField(props.otherName);
  const error = useTranslatedError(meta.error) || meta.error;
  const {setFieldValue} = useFormikContext();
  const {removeError} = useRemoveError(field.name);

  const handleChecksOnChange = (item, checked) => {
    if (checked) {
      setFieldValue(field.name, [...field.value, item]);
    } else {
      const newSelected = field.value.filter((val) => val.id !== item.id);
      setFieldValue(field.name, newSelected);
    }
    removeError();
  };

  return (
    <React.Fragment>
      {props.label && (
        <Col lg={12}>
          <Label isRequired={props.isRequired}>{props.label}</Label>
        </Col>
      )}
      <MultipleChecks
        {...props}
        name={field.name}
        id={field.name}
        checked={field.value}
        onChange={handleChecksOnChange}
        isInvalid={!!meta.error && meta.touched}
        itemsSelected={field.value}
        onOtherChange={otherField.onChange}
        otherValue={otherField.value}
      />
      {error && (
        <Col lg={12}>
          <ErrorMessage forceDisplay>{error}</ErrorMessage>
        </Col>
      )}
    </React.Fragment>
  );
};

export default MultiCheckField;
