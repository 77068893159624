import {useCallback, useEffect} from 'react';
import {useRouteMatch, useLocation, matchPath} from 'react-router-dom';
import {Pagination, useClearRefinements} from 'react-instantsearch';
import {useSearchAlgoliaStore} from '../../../../contexts/SearchAlgoliaContext';
import {usePanelStore} from '../../../../contexts/PanelContext';
import {usePaginator} from '../../../../hooks/paginator';
import {useWindowSize} from '../../../../hooks/utils';
import {breakpoints} from '../../../../constants';
import AlgoliaItem from '../AlgoliaItem';
import ClaimFlow from '../../../../components/ClaimFlow';
import styles from './ListItems.module.scss';

export const ListItems = ({items}) => {
  const [searchState, searchActions] = useSearchAlgoliaStore();
  const [, panelActions] = usePanelStore();
  const windowSize = useWindowSize();
  const {refine: refineClearRefinements} = useClearRefinements({
    includedAttributes: ['meta_position'],
  });
  const match = useRouteMatch();
  const location = useLocation();

  const showPanel = matchPath(location.pathname, {
    path: '/search/item/:id',
    exact: true,
  });

  const {changePage, currentPage} = usePaginator();

  const handleClose = useCallback(() => {
    changePage(match.url, currentPage);
  }, [changePage, currentPage, match.url]);

  const handleClosePresence = useCallback(() => {
    refineClearRefinements();
    handleClose();
  }, [refineClearRefinements, handleClose]);

  useEffect(() => {
    if (windowSize.width > breakpoints.lg) {
      searchActions.setHideFilters(false);
    }
  }, [searchActions, windowSize]);

  useEffect(() => {
    if (!showPanel) {
      panelActions.setPanelMount(false);
    }
  }, [panelActions, showPanel]);

  return (
    <div className={styles.container}>
      <ul className={styles.hitsWrapper}>
        {items.map((hit) => (
          <li key={hit.id}>
            <AlgoliaItem hit={hit} />
          </li>
        ))}
      </ul>
      <div className={styles.pagination}>
        <Pagination />
      </div>
      <ClaimFlow
        currentIssue={searchState.currentIssue}
        onClose={handleClosePresence}
      />
    </div>
  );
};
