import {useCallback} from 'react';
import {useStores} from '../contexts/StoresContext';

function useFormatNumber() {
  const localeNumber = useLocaleNumber();

  const formatNumber = useCallback(
    (number) => {
      const integerNumber = Math.round(Math.abs(number));
      return localeNumber(integerNumber);
    },
    [localeNumber],
  );

  return formatNumber;
}

function useFormatMoney() {
  const stores = useStores();
  const formatNumber = useFormatNumber();

  const formatMoney = useCallback(
    (number, currency) => {
      const symbol = currency ? currency.symbol : stores.app.currency.symbol;
      return `${symbol} ${formatNumber(number)}`;
    },
    [formatNumber, stores.app.currency.symbol],
  );

  return formatMoney;
}

function useLocaleNumber() {
  const stores = useStores();

  const localeNumber = useCallback(
    (number) => {
      return new Intl.NumberFormat(stores.app.language).format(number);
    },
    [stores.app.language],
  );

  return localeNumber;
}

export {useFormatNumber, useFormatMoney, useLocaleNumber};
