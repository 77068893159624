import React from 'react';
import {useTranslation} from 'react-i18next';
import {usePaginator} from '../../../../hooks/paginator';
import {algoliaItemFromJSON} from '../../../../utils/deserializers';
import {usePanelStore} from '../../../../contexts/PanelContext';
import {useStores} from '../../../../contexts/StoresContext';
import {useSearchAlgoliaStore} from '../../../../contexts/SearchAlgoliaContext';
import Item from '../../../../components/Item';
import {SEARCH_PAGE, ISSUE_PAGE} from '../../../../urls';
import styles from './AlgoliaItem.module.scss';

const AlgoliaItem = ({hit}) => {
  const {t} = useTranslation('search');
  const item = algoliaItemFromJSON(hit);
  const store = useStores();
  const [searchState] = useSearchAlgoliaStore();
  const [, panelActions] = usePanelStore();
  const {changePage, currentPage} = usePaginator();

  const handleItemClick = () => {
    changePage(
      `${
        searchState.currentIssue
          ? ISSUE_PAGE.replace(':slug', searchState.currentIssue.slug)
          : SEARCH_PAGE
      }/item/${item.id}`,
      currentPage,
    );
    panelActions.setPanelMount(true);
  };

  if (!store.app.user) {
    item.organizationName = item.organizationName[0] + '**********';
  }

  if (hit.model !== 'needslist_item.Item') return <></>;

  return (
    <Item
      item={item}
      onClick={handleItemClick}
      className={styles.item}
      buttonText={t('cards.buttons.learn-more')}
      hideErrors={true}
    />
  );
};

export default AlgoliaItem;
