import React from 'react';
import {breakpoints} from '../../constants';
import {getInitials} from '../../utils';
import Responsive from '../Responsive';
import ResponsiveImg from '../ResponsiveImg';
import ClaimHeader from './ClaimHeader';
import styles from './Claim.module.scss';

const Claim = (props) => {
  const {claim, actionText, onActionClick, side} = props;
  const orgNameInitials = getInitials(claim.postOrganizationName);
  const handleOnClick = (event) => {
    event.preventDefault();
    onActionClick(claim);
  };
  const action = (
    <a className={styles.action} href="/" onClick={handleOnClick}>
      {actionText}
    </a>
  );
  const orgImage = claim.organizationLogo ? (
    <div className={styles.organizationLogo}>
      <ResponsiveImg src={claim.organizationLogo} />
    </div>
  ) : (
    <div className={`${styles.organizationLogo} ${styles.orgInitials}`}>
      {orgNameInitials}
    </div>
  );

  return (
    <div className={`${styles.wrapper} ${props.className}`}>
      <div className={styles.logoContainer}>{orgImage}</div>
      <div className={styles.headerContainer}>
        <ClaimHeader side={side} claim={claim} />
        <Responsive>
          <div className={styles.actionContainer} min={breakpoints.lg}>
            {action}
          </div>
        </Responsive>
      </div>
      <Responsive>
        <div className={styles.actionContainer} max={breakpoints.lg}>
          {action}
        </div>
      </Responsive>
    </div>
  );
};

export default Claim;
