import React, {useState, useEffect} from 'react';
import {Form, Row, Col, Button, Table} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react';

const BundleWidget = (props) => {
  const form = React.createRef();

  const [loaded, setLoaded] = useState(false);
  const [value, setValue] = useState(props.value);
  const [bundles, setBundles] = useState(JSON.parse(props.value || 'null'));
  const [bundle, setBundle] = useState({
    uom: {
      id: '',
      name: '',
      kind: '',
    },
    units: '',
    price: '',
  });
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setValue(JSON.stringify(bundles));
  }, [bundles]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const handleAdd = (event) => {
    if (form.current.checkValidity()) {
      setValidated((prevState) => false);
      setBundles((prevState) => [...prevState, bundle]);
      setBundle((prevState) => {
        return {
          uom: {
            id: '',
            name: '',
            kind: '',
          },
          units: '',
          price: '',
        };
      });
    } else {
      setValidated((prevState) => true);
    }
  };

  const textarea = (
    <textarea
      style={{display: 'none'}}
      onChange={() => {}}
      className="form-control"
      value={value}
      name={props.name}
    />
  );

  const kind = props.store.kind;

  if (!kind) {
    return (
      <React.Fragment>
        <div className="alert alert-warning">
          You need to select a <strong>need type</strong> before adding bundles
        </div>
        {textarea}
      </React.Fragment>
    );
  }

  const uoms = props.uoms
    .filter((choice) => choice[2] === kind)
    .map((choice, index) => {
      const [id, name] = choice;
      return (
        <option key={`${index}-${id}`} value={id}>
          {name}
        </option>
      );
    });

  const renderBundles = () => {
    return (bundles || []).map((bundle, index) => {
      return (
        <tr key={index}>
          <td>{bundle.uom ? bundle.uom.name : ''}</td>
          <td>{bundle.units}</td>
          <td>
            {bundle.price}
            <FontAwesomeIcon
              icon="times-circle"
              style={{cursor: 'pointer'}}
              className="fa-pull-right"
              onClick={() => {
                const copy = [...bundles];
                copy.splice(index, 1);
                setBundles(copy);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  return (
    <React.Fragment>
      <label>{props.label}</label>
      <Form noValidate ref={form} validated={validated} className="mb-3">
        <Row>
          <Col>
            <Form.Control
              required
              as="select"
              value={bundle.uom.id}
              onChange={(event) => {
                const options = event.target.options;
                const name = options[options.selectedIndex].text;
                const value = event.target.value;
                setBundle({
                  ...bundle,
                  uom: {
                    id: value,
                    name: name,
                    kind: kind,
                  },
                });
              }}
            >
              <option value="">-</option>
              {uoms}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please provide a valid uom.
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Control
              required
              placeholder="Units"
              type="number"
              value={bundle.units}
              onChange={(event) => {
                const value = event.target.value;
                setBundle({
                  ...bundle,
                  units: value,
                });
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please provide valid units.
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Control
              required
              type="number"
              step="0.01"
              placeholder="Price"
              value={bundle.price}
              onChange={(event) => {
                setBundle({
                  ...bundle,
                  price: event.target.value,
                });
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid price.
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Button variant="primary" type="button" onClick={handleAdd}>
              Add
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        striped
        bordered
        hover
        style={{display: bundles.length !== 0 ? 'table' : 'none'}}
      >
        <thead>
          <tr>
            <th>Uom</th>
            <th>Units</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>{renderBundles()}</tbody>
      </Table>
      {loaded && !bundles.length ? (
        <div className="alert alert-info">No bundles found</div>
      ) : null}
      {textarea}
    </React.Fragment>
  );
};

export default observer(BundleWidget);
