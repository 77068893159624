import React from 'react';
import styles from './SavedView.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation} from 'react-i18next';

const SavedViewWarning = (props) => {
  const {
    headerText,
    descriptionText,
    actionButtonText,
    actionButtonHandler,
    closeButtonHandler,
  } = props;
  const {t} = useTranslation('item-reports');

  return (
    <dialog className={styles.warningDialog}>
      <div className={styles.warningContainer}>
        <FontAwesomeIcon
          className={styles.warningIcon}
          icon="exclamation-triangle"
        />
        <div className={styles.warningTextContainer}>
          <div className={styles.warningHeader}>{headerText}</div>
          <div className={styles.warningDescription}>{descriptionText}</div>
        </div>
      </div>
      <div className={styles.dialogButtonContainer}>
        <div
          onClick={actionButtonHandler}
          className={styles.dialogActionButton}
        >
          <div className={styles.dialogActionButtonText}>
            {actionButtonText}
          </div>
        </div>
        <div onClick={closeButtonHandler} className={styles.dialogCloseButton}>
          <div className={styles.dialogCloseButtonText}>
            {t('saved_view.warning.cancel-button')}
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default SavedViewWarning;
