import React from 'react';
import styles from './SuccessToast.module.scss';

const SuccessToast = ({title, text}) => {
  return (
    <div className={styles.toastContainer} data-testid="successToast">
      <div className={styles.toastTitle}>{title}</div>
      <div className={styles.toastText}>{text}</div>
    </div>
  );
};

export default SuccessToast;
