const theme = {
  primaryColor: '#014358',
  secondaryColor: '#FA812F',
  backgroundColor: '#F6F9FC',
  greyBorderColor: '#E6E9F1',
  cardBorderRadius: '15px',
  cardBorderColor: 'rgba(0, 0, 0, 0.125)',
  formBorderColor: '#F8F8F8',
  success: '#8BC34A',
  info: '#3794FC',
  danger: '#FC381D',
  warning: '#F2C200',
  testing: '#E5E2CA',
  // http://chir.ag/projects/name-that-color/
  smaltBlue: '#4d7a8a',
  astronautBlue: '#014358',
  atomicTangerine: '#fba76d',
  crusta: '#fa812f',
  java: '#1DD1A1',
  watercourse: '#04645B',
  rajah: '#FBA96D',
  clementine: '#EC5F00',
  aquaHaze: '#F7F9FA',
  vistaWhite: '#FCFAF8',
  tealBlue: '#055160',
  korma: '#984C0C',
  blueChill: '#087990',
  meteor: '#CA6510',
  cerulean: '#0AA2C0',
  neonCarrot: '#FD9843',
  heather: '#B3C7CD',
  hitPink: '#FCB382',
  lightApricot: '#FDD9C1',
  baliHai: '#82A8AF',
  glacier: '#83BCC7',
  aquamarineBlue: '#84D1DF',
  tan: '#CBA585',
  goldSand: '#E5B287',
  flesh: '#FECBA1',
  bismark: '#447D88',
  steelBlue: '#469AAC',
  shakespeare: '#47B9D0',
  santaFe: '#B27949',
  rawSienna: '#D78B4C',
  macaroniAndCheese: '#FDB272',
  hitGray: '#ADB5BD',
};

export default theme;
