import styled, {css} from 'styled-components';

const ItemDataValue = styled.div`
  font-size: 14px;
  line-height: 22px;
  ${(props) =>
    !props.withoutTextStyle &&
    css`
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    `}
`;

export default ItemDataValue;
