import React from 'react';
import styled from 'styled-components';
import {useTranslation, Trans} from 'react-i18next';
import Item from '../../../components/Item';
import {Row, Col} from 'react-bootstrap';
import {Title} from '../../../components/OnboardingCarousel';
import {mediaQueries, breakpoints} from '../../../constants';
import Responsive from '../../../components/Responsive';
import Button from '../../../components/Button/Button';

const Wrapper = styled.div`
  display: flex;
  padding: 40px 30px 32px;
  flex-wrap: wrap;

  ${mediaQueries.lg} {
    padding: 10px 0;
    flex-direction: column;
  }
`;

const FirstColumn = styled.div`
  flex: 0.3;
  margin-right: 70px;
  display: flex;
  flex-direction: column;

  ${mediaQueries.lg} {
    flex: 1;
    width: 100%;
    margin-right: 0;
  }
`;

const SecondColumn = styled.div`
  flex: 0.7;

  ${mediaQueries.lg} {
    flex: 1;
    width: 100%;
  }
`;

const StyledTitle = styled(Title)`
  margin-top: 28px;
`;

const Text = styled.p`
  margin-right: 50px;

  ${mediaQueries.lg} {
    margin-right: 0;
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${mediaQueries.lg} {
    margin: 10px 0;
  }
`;

const Subtitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

const OfferSubtitle = styled(Subtitle)`
  text-align: right;

  ${mediaQueries.lg} {
    text-align: left;
  }
`;

const OfferText = styled.p`
  text-align: right;

  ${mediaQueries.lg} {
    text-align: left;
  }
`;

const NextButton = styled(Button)`
  margin: auto auto 0 0;

  ${mediaQueries.lg} {
    margin: 15px 0 10px;
  }
`;

const demoNeed = {
  id: 1,
  name: 'Diapers (S)',
  side: 'need',
  kind: 'supply',
  category: {
    name: 'Baby Items',
  },
  organizationName: 'Helpful Organization',
  issueName: 'Disaster Relief. Earthquake',
  issueLocation: {
    place: 'Canada, Toronto',
  },
  quantity: 700,
  tag: [],
  claimedQuantity: 0,
  dueDate: null,
};
const demoOffer = {
  id: 1,
  name: '350 Diapers are available in your area',
  side: 'offer',
  kind: 'supply',
  category: {
    name: 'Baby Items',
  },
  organizationName: 'JCPenney',
  issueName: 'Disaster Relief. Earthquake',
  issueLocation: {
    place: 'Canada, Toronto',
  },
  quantity: 700,
  tag: [],
  claimedQuantity: 0,
  dueDate: null,
};

const FirstSlide = (props) => {
  const {t} = useTranslation('onboarding');

  return (
    <Wrapper>
      <FirstColumn>
        <StyledTitle>{t('database.first-slide.title')}</StyledTitle>
        <Trans i18nKey="onboarding:database.first-slide.text">
          <Text>text</Text>
          <Text>text</Text>
        </Trans>
        <Responsive>
          <NextButton
            max={breakpoints.lg}
            variant="outline-primary"
            onClick={props.onNext}>
            {t('next')}
          </NextButton>
        </Responsive>
      </FirstColumn>
      <SecondColumn>
        <Row>
          <Responsive>
            <Col sm={6} max={breakpoints.lg}>
              <Item
                item={demoNeed}
                buttonText={t('database.first-slide.need.button')}
              />
            </Col>
          </Responsive>
          <StyledCol sm={6}>
            <Subtitle>{t('database.first-slide.need.title')}</Subtitle>
            <p>{t('database.first-slide.need.text')}</p>
            <Responsive>
              <Item
                min={breakpoints.lg}
                item={demoNeed}
                buttonText={t('database.first-slide.need.button')}
              />
            </Responsive>
          </StyledCol>
        </Row>
        <Row>
          <StyledCol sm={6}>
            <OfferSubtitle>
              {t('database.first-slide.offer.title')}
            </OfferSubtitle>
            <OfferText>{t('database.first-slide.offer.text')}</OfferText>
          </StyledCol>
          <Col sm={6}>
            <Item
              item={demoOffer}
              buttonText={t('database.first-slide.offer.button')}
            />
          </Col>
        </Row>
        <Responsive>
          <NextButton
            min={breakpoints.lg}
            variant="outline-primary"
            onClick={props.onNext}
            responsiveFullWidth>
            {t('next')}
          </NextButton>
        </Responsive>
      </SecondColumn>
    </Wrapper>
  );
};

export default FirstSlide;
