import React from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Col, Row} from 'react-bootstrap';

import {getYesNoOptions} from '../../../constants';
import {InputField, RadioField} from '../../../components/Fields';
import FocusAreaField from './FocusAreaField';

const NPFields = (props) => {
  // const [vettedBy, setVettedBy] = useState([]);
  const {t} = useTranslation('create-account');
  const colSize = props.isFullWidth ? 12 : 6;
  const yesNoOptions = getYesNoOptions();

  // const fetchVettedBy = async () => {
  //   const response = await api.getVettedByList();

  //   const fetchedVettedByList = Object.keys(response).map((vettedBy) => ({
  //     id: vettedBy,
  //     name: response[vettedBy],
  //   }));

  //   setVettedBy(fetchedVettedByList);
  // };

  // useEffect(() => {
  //   fetchVettedBy();
  // }, []);

  // const mappedVettedBy = (vetted) => (
  //   <option key={vetted.id} value={vetted.id}>{vetted.name}</option>
  // );

  return (
    <React.Fragment>
      <Row>
        <Form.Group as={Col} lg={colSize}>
          <InputField
            label={t('labels.tax-number')}
            name="taxNumber"
            placeholder={t('placeholders.tax-number')}
            isRequired
          />
        </Form.Group>
        <Form.Group as={Col} lg={colSize}>
          <RadioField
            label={t('labels.charity')}
            name="charity"
            isHorizontal
            horizontalMargin
            booleanOptions
            options={yesNoOptions}
            isRequired
          />
        </Form.Group>
      </Row>
      {/* <Form.Group as={Col} sm={colSize}>
        <SelectField
          label={t('labels.vetted-by')}
          name="vettedBy"
        >
          <option value="">{t('select')}</option>
          {vettedBy.map(mappedVettedBy)}
        </SelectField>
      </Form.Group> */}
      {props.clientFocusAreaTags === 'enabled' && (
        <FocusAreaField isRequired isFullWidth={props.isFullWidth} />
      )}
    </React.Fragment>
  );
};

export default NPFields;
