import {
  QUANTITY_COLUMN,
  UNIT_COLUMN,
  VALUE_COLUMN,
  TOTAL_AMOUNT,
  PLATFORM_INTERACTION_COLUMN,
  TYPE_COLUMN,
  STATUS_COLUMN,
  ISSUE_COLUMN,
  MET_CLAIMED_COLUMN,
  OWNER_ORGANIZATION_COLUMN,
  CREATION_DATE_COLUMN,
  ITEM_COLUMN,
  SECTOR_COLUMN,
  CATEGORY_COLUMN,
  MARKET_VALUE_NUMERIC_CSV_COLUMN,
  MARKET_VALUE_UNIT_CSV_COLUMN,
  EXPORT_SEARCH_LINK,
  BENEFICIARIES_COLUMN,
  INDIVIDUALS_QUANTITY_COLUMN,
} from '../../components/ItemReports/CustomizeColumns';

export const getTotalAmount = (item, localeNumber, currency) =>
  `${localeNumber(item.totalDollarValue)} ${currency.code}`;

export const getClaimValue = (claimedItem, localeNumber, currency) =>
  `${localeNumber(claimedItem.claimDollarValue)} ${currency.code}`;

export const getMarketValue = (item, localeNumber, currency) => {
  return item.uomFulfillment
    ? `${localeNumber(item.marketValue)} ${currency.code} / ${
        item.uomFulfillment
      }`
    : `${localeNumber(item.marketValue)} ${currency.code}`;
};

export const getItemQuantity = (item, localeNumber, t) => {
  return item.kind === 'information'
    ? t('labels.unlimited')
    : localeNumber(item.quantity);
};

export const getItemUnit = (item) => {
  return item.kind !== 'information' ? item.uomFulfillment : '';
};

export const getClaimedItemQuantity = (claimedItem, localeNumber, t) => {
  return claimedItem.item.kind === 'information'
    ? t('labels.unlimited')
    : `${localeNumber(claimedItem.quantity)} ${claimedItem.uomFulfillment}`;
};

export const getBeneficiaries = (claim, t) => {
  const individualsUomName = getBeneficiariesName(
    claim.beneficiariesQuantity,
    t,
  );
  return `${claim.beneficiariesQuantity || 0} ${individualsUomName}`;
};

const getBeneficiariesName = (quantity, t) =>
  quantity === 1
    ? t('common:beneficiaries.individual')
    : t('common:beneficiaries.individual_plural');

export const getColumnTitleFromKey = (key, t, currencyCode = '') => {
  if (key === MARKET_VALUE_NUMERIC_CSV_COLUMN) {
    return currencyCode
      ? t('tabs.headers.market-value-currency', {currency: currencyCode})
      : t('tabs.headers.market-value');
  }
  if (key === TOTAL_AMOUNT) {
    return currencyCode
      ? t('tabs.headers.total-amount-currency', {currency: currencyCode})
      : t('tabs.headers.total-amount');
  }
  return t('tabs.headers.' + key);
};

// Escapes the value according to the rules in RFC 4180 if necessary
const formatCsvValue = (value) => {
  if (
    typeof value === 'string' &&
    (value.includes(',') || value.includes('"') || value.includes('\n'))
  ) {
    return `"${value.replaceAll('"', '""')}"`;
  } else {
    return value;
  }
};

export const executeCsvDownload = (csvContents, fileName) => {
  const formattedCsvContents = csvContents
    .map((e) => e.map(formatCsvValue).join(','))
    .join('\n');

  const blob = new Blob([formattedCsvContents], {
    type: 'text/csv;charset=utf-8,',
  });
  const objUrl = URL.createObjectURL(blob);

  let link = document.createElement('a');
  link.setAttribute('href', objUrl);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const getCsvHeaders = (columnList, t, currencyCode) => {
  return columnList.map((column) =>
    getColumnTitleFromKey(column, t, currencyCode),
  );
};

export const getItemsCsvData = (
  items,
  columnList,
  t,
  currency,
  localeNumber,
  moment,
) => {
  return items.map((item) => {
    return columnList.map((column) => {
      if (column === ITEM_COLUMN) {
        return item.name;
      }
      if (column === QUANTITY_COLUMN) {
        return getItemQuantity(item, localeNumber, t);
      }
      if (column === UNIT_COLUMN) {
        return getItemUnit(item);
      }
      if (column === TYPE_COLUMN) {
        return item.kind;
      }
      if (column === STATUS_COLUMN) {
        return t(`tabs.status.${item.ui_status}`);
      }
      if (column === CREATION_DATE_COLUMN) {
        return moment(item.createdAt).format('L');
      }
      if (column === ISSUE_COLUMN) {
        return item.issueName;
      }
      if (column === PLATFORM_INTERACTION_COLUMN) {
        return item.platformInteraction;
      }
      if (column === OWNER_ORGANIZATION_COLUMN) {
        return item.organizationName;
      }
      if (column === TOTAL_AMOUNT) {
        return localeNumber(item.totalDollarValue);
      }
      if (column === VALUE_COLUMN) {
        return getMarketValue(item, localeNumber, currency);
      }
      if (column === MARKET_VALUE_NUMERIC_CSV_COLUMN) {
        return localeNumber(item.marketValue);
      }
      if (column === MARKET_VALUE_UNIT_CSV_COLUMN) {
        return item.uomFulfillment
          ? item.uomFulfillment
          : t('common:empty-value');
      }
      if (column === SECTOR_COLUMN) {
        return item.category?.sector?.name;
      }
      if (column === CATEGORY_COLUMN) {
        return item.category?.name;
      }
      if (column === EXPORT_SEARCH_LINK) {
        return `${window.location.origin}/search/item/${item.id}`;
      }
      if (column === BENEFICIARIES_COLUMN) {
        const individualsUomId = 17;
        if (
          !item.beneficiaries[0] ||
          item.side === 'offer' ||
          item.beneficiaries[0].uom !== individualsUomId
        )
          return t('common:not-apply');

        return t('common:beneficiaries.individual_plural');
      }
      if (column === INDIVIDUALS_QUANTITY_COLUMN) {
        const individualsUomId = 17;
        return item.side === 'need' &&
          item.beneficiaries.length &&
          item.beneficiaries[0].uom === individualsUomId
          ? item.beneficiaries[0].quantity
          : null;
      }
      return '';
    });
  });
};

export const getClaimedItemsCsvData = (
  claimedItems,
  columnList,
  t,
  currency,
  localeNumber,
  moment,
) => {
  return claimedItems.map((claimedItem) => {
    return columnList.map((column) => {
      if (column === ITEM_COLUMN) {
        return claimedItem.item.name;
      }
      if (column === QUANTITY_COLUMN) {
        return getItemQuantity(claimedItem, localeNumber, t);
      }
      if (column === UNIT_COLUMN) {
        return getItemUnit(claimedItem);
      }
      if (column === TYPE_COLUMN) {
        return claimedItem.item.kind;
      }
      if (column === STATUS_COLUMN) {
        return t(`tabs.status.${claimedItem.item.ui_status}`);
      }
      if (column === CREATION_DATE_COLUMN) {
        return moment(claimedItem.createdAt).format('L');
      }
      if (column === ISSUE_COLUMN) {
        return claimedItem.item.issue_name;
      }
      if (column === PLATFORM_INTERACTION_COLUMN) {
        return claimedItem.platformInteraction;
      }
      if (column === MET_CLAIMED_COLUMN) {
        return claimedItem.organizationName;
      }
      if (column === OWNER_ORGANIZATION_COLUMN) {
        return claimedItem.item.organization_name;
      }
      if (column === VALUE_COLUMN) {
        return getMarketValue(claimedItem, localeNumber, currency);
      }
      if (column === MARKET_VALUE_NUMERIC_CSV_COLUMN) {
        return localeNumber(claimedItem.marketValue);
      }
      if (column === MARKET_VALUE_UNIT_CSV_COLUMN) {
        return claimedItem.uomFulfillment
          ? claimedItem.uomFulfillment
          : t('common:empty-value');
      }
      if (column === SECTOR_COLUMN) {
        return claimedItem.item.category?.sector?.name;
      }
      if (column === CATEGORY_COLUMN) {
        return claimedItem.item.category?.name;
      }
      if (column === BENEFICIARIES_COLUMN) {
        if (!claimedItem.beneficiariesQuantity) return t('common:not-apply');

        return t('common:beneficiaries.individual_plural');
      }
      if (column === INDIVIDUALS_QUANTITY_COLUMN) {
        return claimedItem.beneficiariesQuantity;
      }
      return '';
    });
  });
};

export const getCsvColumns = (hideMetClaimColumn) => {
  const columns = [
    ITEM_COLUMN,
    QUANTITY_COLUMN,
    UNIT_COLUMN,
    TYPE_COLUMN,
    SECTOR_COLUMN,
    CATEGORY_COLUMN,
    STATUS_COLUMN,
    CREATION_DATE_COLUMN,
    ISSUE_COLUMN,
    PLATFORM_INTERACTION_COLUMN,
    OWNER_ORGANIZATION_COLUMN,
    VALUE_COLUMN,
    MARKET_VALUE_NUMERIC_CSV_COLUMN,
    MARKET_VALUE_UNIT_CSV_COLUMN,
    TOTAL_AMOUNT,
    EXPORT_SEARCH_LINK,
    BENEFICIARIES_COLUMN,
    INDIVIDUALS_QUANTITY_COLUMN,
  ];
  !hideMetClaimColumn && columns.push(MET_CLAIMED_COLUMN);
  return columns;
};
