import React from 'react';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

const Route = (props) => {
  const {isPublic, ...rest} = props;
  const RouteComponent = isPublic ? PublicRoute : PrivateRoute;

  return <RouteComponent {...rest} />;
};

export default Route;
