import sharedStyles from './shared.module.scss';
import Button from './Button';
import {useState, useEffect, useCallback} from 'react';
import {Form} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {Tabs, Tab} from 'react-bootstrap';
import {useApi} from '../../contexts/ApiContext';
import {useTranslation} from 'react-i18next';

const ItemFilter = (props) => {
  const {cancelClick, value, onRemoveUrl, queryParam} = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const history = useHistory();
  const api = useApi();
  const {t} = useTranslation('item-reports');

  const removeIndex = useCallback(
    (index) => {
      let array = [...selectedItems];
      array.splice(index, 1);

      const value = array.map((item) => item.id).join(',');
      const queryStringParams = new URLSearchParams(onRemoveUrl);
      if (value) {
        queryStringParams.append(queryParam, value);
      } else {
        queryStringParams.delete(queryParam);
        cancelClick();
      }
      history.push({search: queryStringParams.toString()});
      setSelectedItems(array);
    },
    [selectedItems, cancelClick, history, onRemoveUrl, queryParam],
  );

  const restoreAllClick = () => {
    cancelClick();
    history.push({search: onRemoveUrl});
  };

  useEffect(() => {
    // Add validation and error handling.
    if (value) {
      const fetchSuggestedItems = async (ids) => {
        const response = await api.getItemsShort({
          ids: ids,
        });
        setSelectedItems(response.results);
      };
      const ids = value.split(',');
      fetchSuggestedItems(ids);
    }
  }, [value, api]);

  return (
    <div className={sharedStyles.popupPanel}>
      <div className={sharedStyles.container}>
        <div className={sharedStyles.containerBorders}>
          <div className={sharedStyles.content}>
            <div
              className={
                sharedStyles.contentInner +
                ' ' +
                sharedStyles.contentInnerFixedWith
              }
            >
              <Tabs activeKey="">
                <Tab key="" eventKey="" title={t('filters.item.tab')}></Tab>
              </Tabs>
              {selectedItems.length === 0 && (
                <span>{t('filters.item.loading')}</span>
              )}
              {selectedItems.map((value, index) => (
                <div
                  key={'input_' + index}
                  className={sharedStyles.inputContainer}
                >
                  <Form.Control
                    className={sharedStyles.input}
                    value={
                      value.name +
                      ', ' +
                      value.quantity +
                      ', ' +
                      value.organization_name +
                      ', ' +
                      value.issue_name +
                      ' (...)'
                    }
                    readOnly
                  ></Form.Control>
                  <img
                    src="https://fonts.gstatic.com/s/i/short-term/release/googlesymbols/keyboard_return/default/24px.svg"
                    alt="down left arrow"
                    className={
                      sharedStyles.searchIconStyle +
                      ' ' +
                      sharedStyles.iconPointer
                    }
                    onClick={() => removeIndex(index)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={sharedStyles.divider}>
            <hr className={sharedStyles.dividerLine} />
          </div>
          <div className={sharedStyles.footerContainer}>
            <div className={sharedStyles.footerContainerInner}>
              <Button onClick={restoreAllClick}>
                {t('filters.item.restore')}
              </Button>
              <Button onClick={cancelClick}>{t('filters.cancel')}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemFilter;
