import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useRouteMatch, useHistory} from 'react-router-dom';

import {usePageStore} from '../../contexts/PageContext';
import Subtitle from '../../components/Subtitle';
import {mediaQueries, breakpoints} from '../../constants';
import SearchBar from '../../components/SearchBar/SearchBar';
import {useApi} from '../../contexts/ApiContext';
import Banner from '../../components/Banner';
import IssueList from './IssueList';
import IssueCountryFilter from './IssueCountryFilter';
import CategoryList from './CategoryList';
import {useIsMobile} from '../../hooks/utils';
import Responsive from '../../components/Responsive';
import LinkButton from '../../components/Button/LinkButton';
import {ISSUE_PAGE} from '../../urls';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';

const Wrapper = styled.div`
  margin-bottom: 180px;

  ${mediaQueries.lg} {
    margin-bottom: 30px;
  }
`;

const StyledSearchBar = styled(SearchBar)`
  width: 85%;
  margin: 35px auto 0;

  ${mediaQueries.lg} {
    width: 100%;
  }
`;

const SdgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const StyledLinkButton = styled(LinkButton)`
  margin-bottom: 15px;
`;

const BrowseIssues = (props) => {
  const stores = useStores();
  const api = useApi();
  const [, pageActions] = usePageStore();
  const {t} = useTranslation(['browse-issues', 'common']);
  const history = useHistory();
  const match = useRouteMatch();
  const [options, setOptions] = useState([]);
  const [urgentIssue, setUrgentIssue] = useState(null);
  const [countries, setCountries] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const isMobile = useIsMobile();
  const placeholder = t('common:placeholder');
  const clientFocusAreaTags = stores.app.clientData.focus_area_tags;

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'browse-issues'});
  }, [pageActions]);

  useEffect(() => {
    const fetchUrgentIssue = async () => {
      const urgentIssue = await api.getIssues({is_urgent: true});
      setUrgentIssue(urgentIssue.results[0]);
    };
    const fetchCountries = async () => {
      const response = await api.getIssuesCountries();
      setAllCountries(response);
    };

    fetchUrgentIssue();
    fetchCountries();
  }, [api]);

  const handleSearch = async (value) => {
    const response = await api.getIssues({search: value});
    const options = response.results.map((option) => ({
      label: option['name'],
      value: option['slug'],
    }));

    setOptions(options);
  };

  const handleChange = (options) =>
    history.push(ISSUE_PAGE.replace(':slug', options[0].value));
  const handleBannerClick = () =>
    history.push(ISSUE_PAGE.replace(':slug', urgentIssue.slug));
  const handleIssueClick = (issue) =>
    history.push(ISSUE_PAGE.replace(':slug', issue.slug));

  const handleCountrySelect = (country) => {
    const newCountries = countries.some((el) => el.value === country.value)
      ? countries.filter((el) => el.value !== country.value)
      : [...countries, country];

    setCountries(newCountries);

    if (isMobile) history.push(`${match.url}`);
  };

  const handleCategorySelect = (category) => {
    categories.includes(category)
      ? setCategories(categories.filter((el) => el !== category))
      : setCategories([...categories, category]);

    if (isMobile) history.push(`${match.url}`);
  };

  const handleOnCategoriesClear = () => setCategories([]);

  return (
    <Wrapper>
      <StyledSearchBar
        id="search-bar"
        className={props.className}
        isLoading={false}
        onChange={handleChange}
        onSearch={handleSearch}
        options={options}
        allowNew={false}
        placeholder={placeholder}
        selectPartialMatchOnEnter
      />
      {urgentIssue && (
        <React.Fragment>
          <Subtitle>{t('browse-issues:subtitle.most-urgent')}</Subtitle>
          <Banner
            issue={urgentIssue}
            onClick={handleBannerClick}
            CTAText={t('browse-issues:cta')}
          />
        </React.Fragment>
      )}
      {allCountries.length > 1 && (
        <React.Fragment>
          <Subtitle>{t('browse-issues:subtitle.filter-by-countries')}</Subtitle>
          <IssueCountryFilter
            onCountrySelect={handleCountrySelect}
            selectedCountries={countries}
            allIssuesCountries={allCountries}
          />
        </React.Fragment>
      )}
      {clientFocusAreaTags === 'enabled' && (
        <React.Fragment>
          <SdgWrapper>
            <Subtitle>
              {t('browse-issues:subtitle.filter-by-focus-areas')}
            </Subtitle>
            <StyledLinkButton
              disabled={!!(categories.length === 0)}
              onClick={handleOnCategoriesClear}
            >
              {t('common:buttons.clear-all')}
            </StyledLinkButton>
          </SdgWrapper>
          <CategoryList
            isMobile={isMobile}
            onCategorySelect={handleCategorySelect}
            selectedCategories={categories}
          />
        </React.Fragment>
      )}
      <Responsive>
        <IssueList
          max={breakpoints.lg}
          subtitle={t('browse-issues:subtitle.recent-issues')}
          categories={categories}
          countries={countries}
          onClick={handleIssueClick}
          hasOnlyRecent
        />
      </Responsive>
      <IssueList
        subtitle={t('browse-issues:subtitle.all-issues')}
        categories={categories}
        countries={countries}
        onClick={handleIssueClick}
      />
    </Wrapper>
  );
};

export default observer(BrowseIssues);
