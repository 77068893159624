import {NavLink} from 'react-router-dom';
import styles from './NavigationLink.module.scss';

const NavigationLink = ({children, className, ...props}) => {
  return (
    <NavLink className={`${styles.navLink} ${className || ''}`} {...props}>
      {children}
    </NavLink>
  );
};

export default NavigationLink;
