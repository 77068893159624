import React, {useReducer, useMemo} from 'react';
import {MAP_PAGE_V1} from '../urls';

const initialState = {
  panelMount: true,
  panelHide: false,
};

const PanelContext = React.createContext();

const types = {
  PANEL_SET_MOUNT: 'PANEL_SET_MOUNT',
  PANEL_SET_HIDE: 'PANEL_SET_HIDE',
};

const reducer = (state, action) => {
  const {type, payload} = action;

  switch (type) {
    case types.PANEL_SET_MOUNT: {
      return {
        ...state,
        panelMount: payload,
        panelHide: false,
      };
    }
    case types.PANEL_SET_HIDE: {
      return {
        ...state,
        panelHide: payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

const PanelProvider = ({children}) => {
  const isMapPage = window.location.href.includes(MAP_PAGE_V1); //TODO this is a temporal solution. Change this when the Map can manage data based on the url
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    panelMount: !isMapPage,
  });

  const actions = useMemo(() => {
    return {
      setPanelMount: (mount) => {
        dispatch({
          type: types.PANEL_SET_MOUNT,
          payload: mount,
        });
      },
      setPanelHide: (hide) => {
        dispatch({
          type: types.PANEL_SET_HIDE,
          payload: hide,
        });
      },
    };
  }, [dispatch]);

  return (
    <PanelContext.Provider value={[state, actions]}>
      {children}
    </PanelContext.Provider>
  );
};

const usePanelStore = () => {
  const context = React.useContext(PanelContext);
  if (context === undefined) {
    throw new Error('`usePanelStore` must be used within a `PanelContext`.');
  }
  return context;
};

export {PanelContext, PanelProvider, usePanelStore};
