import {useRefinementList} from 'react-instantsearch';
import {useAvailableFor} from '../../../../hooks/search-index';
import {useEffect} from 'react';

export const AvailableForRefinements = () => {
  const availableForOptions = useAvailableFor();
  // That component exists to filter always by meta_available_for
  const {refine} = useRefinementList({
    attribute: 'meta_available_for',
  });

  useEffect(() => {
    availableForOptions.forEach((record) => {
      refine(record);
    });
  }, [availableForOptions, refine]);

  return <></>;
};
