import React, {useReducer, useMemo, useCallback, useEffect} from 'react';

const initialState = {
  map: null,
  zoom: null,
  center: null,
  location: null,
  locationBounds: null,
  bounds: null,
};

const MapContainerContext = React.createContext();

const types = {
  SET_MAP: 'SET_MAP',
  SET_CENTER: 'SET_CENTER',
  SET_ZOOM: 'SET_ZOOM',
  SET_LOCATION: 'SET_LOCATION',
  SET_LOCATION_BOUNDS: 'SET_LOCATION_BOUNDS',
  SET_BOUNDS: 'SET_BOUNDS',
};

const reducer = (state, action) => {
  const {type, payload} = action;
  switch (type) {
    case types.SET_MAP: {
      return {
        ...state,
        map: payload.map,
      };
    }
    case types.SET_CENTER: {
      return {
        ...state,
        center: payload.center,
      };
    }
    case types.SET_ZOOM: {
      return {
        ...state,
        zoom: payload.zoom,
      };
    }
    case types.SET_LOCATION: {
      return {
        ...state,
        location: payload.location,
      };
    }
    case types.SET_LOCATION_BOUNDS: {
      return {
        ...state,
        locationBounds: payload.locationBounds,
      };
    }
    case types.SET_BOUNDS: {
      return {
        ...state,
        bounds: payload.bounds,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const MapContainerProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });
  const centerMap = useCallback(() => {
    if (!state.location || !state.map) {
      return;
    }
    const place = state.location.place;
    const bounds = place.geometry.viewport || place.geometry.bounds || null;
    if (bounds) {
      dispatch({
        type: types.SET_LOCATION_BOUNDS,
        payload: {locationBounds: bounds},
      });
      state.map.current.fitBounds(bounds);
    }
  }, [state.map, state.location]);

  useEffect(() => {
    centerMap();
  }, [state.location, centerMap]);

  const actions = useMemo(() => {
    return {
      setMap: (map) => {
        dispatch({
          type: types.SET_MAP,
          payload: {map},
        });
      },
      setZoom: (zoom) => {
        dispatch({
          type: types.SET_ZOOM,
          payload: {zoom},
        });
      },
      setCenter: (center) => {
        dispatch({
          type: types.SET_CENTER,
          payload: {center},
        });
      },
      setLocation: (location) => {
        dispatch({
          type: types.SET_LOCATION,
          payload: {
            location,
          },
        });
      },
      setLocationBounds: (locationBounds) => {
        dispatch({
          type: types.SET_LOCATION_BOUNDS,
          payload: {
            locationBounds,
          },
        });
      },
      setBounds: (bounds) => {
        dispatch({
          type: types.SET_BOUNDS,
          payload: {bounds},
        });
      },
      centerMap: centerMap,
    };
  }, [dispatch, centerMap]);

  return (
    <MapContainerContext.Provider value={[state, actions]}>
      {children}
    </MapContainerContext.Provider>
  );
};

const useMapContainer = () => {
  const context = React.useContext(MapContainerContext);
  if (context === undefined) {
    throw new Error(
      '`useMapContainer` must be used within a `MapContainerContext`.',
    );
  }
  return context;
};

export {MapContainerContext, MapContainerProvider, useMapContainer};
