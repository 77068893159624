import React, {useState, useCallback} from 'react';
import FiltersContext from './FiltersContext';

const FiltersProvider = (props) => {
  const [initialized, setInitialized] = useState(false);
  const [filters, setFilters] = useState({});
  const initFilters = useCallback((initialValue) => {
    setFilters({
      search: [],
      organization: [],
      organizationName: [],
      issue: [],
      issueName: [],
      issueSlug: [],
      location: [],
      postalCode: [],
      city: [],
      country: [],
      state: [],
      sortByDate: '-created_at',
      includeGlobalIssue: 'include',
      status: ['open', 'in_progress', 'done', 'expired'],
      itemType: [],
      category: [],
      side: [],
      which: [],
      ...initialValue,
    });
    setInitialized(true);
  }, []);
  const setFilterOnActiveFilter = useCallback(
    (category, newFilter) => {
      const currentCategoryFilters = filters[category] || [];

      if (
        currentCategoryFilters.some(
          (filter) => filter.value === newFilter.value,
        )
      )
        return;
      setFilters((currentFilters) => ({
        ...currentFilters,
        [category]: [...currentCategoryFilters, newFilter],
      }));
    },
    [filters],
  );
  const removeFilter = useCallback(
    (category, filterToRemove) => {
      const currentCategoryFilters = filters[category] || [];

      setFilters((currentFilters) => ({
        ...currentFilters,
        [category]: [
          ...currentCategoryFilters.filter(
            (filter) => filter.value !== filterToRemove.value,
          ),
        ],
      }));
    },
    [filters],
  );
  const setMultipleFilters = useCallback((newFilters) => {
    setFilters((currentFilters) => {
      const updatedFilters = Object.keys(newFilters).reduce(
        (acumFilters, newFilter) => {
          return {
            ...acumFilters,
            [newFilter]: [...currentFilters[newFilter], newFilters[newFilter]],
          };
        },
        {},
      );
      return {
        ...currentFilters,
        ...updatedFilters,
      };
    });
  }, []);
  const overrideFilters = useCallback((category, newFilters) => {
    setFilters((filters) => ({
      ...filters,
      [category]: newFilters,
    }));
  }, []);
  const removeRelatedFilters = useCallback(
    (relatedFilters, commonId, commonIdKey) => {
      setFilters((currentFilters) => {
        const updateRelatedFilters = relatedFilters.reduce(
          (acumFilters, relatedFilter) => {
            return {
              ...acumFilters,
              [relatedFilter]: currentFilters[relatedFilter].filter(
                (filterValue) => filterValue[commonIdKey] !== commonId,
              ),
            };
          },
          {},
        );
        return {
          ...currentFilters,
          ...updateRelatedFilters,
        };
      });
    },
    [],
  );
  const clearFilters = useCallback(
    (keepSide) => {
      const keptFilters = {
        issueSlug: filters.issueSlug,
      };

      if (keepSide) {
        keptFilters.side = filters.side;
      }

      initFilters(keptFilters);
    },
    [filters, initFilters],
  );
  const clearFilter = useCallback(
    (category) => {
      overrideFilters(category, []);
    },
    [overrideFilters],
  );

  const contextValue = {
    filters,
    initFilters,
    removeFilter,
    clearFilters,
    clearFilter,
    initialized,
    overrideFilters,
    setMultipleFilters,
    setFilter: setFilterOnActiveFilter,
    removeRelatedFilters,
  };

  return (
    <FiltersContext.Provider value={contextValue}>
      {props.children}
    </FiltersContext.Provider>
  );
};

export default FiltersProvider;
