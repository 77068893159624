import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';

import {
  getGoogleAutocompleteResults,
  getGooglePlaceDetails,
  getNormalizedPlaceDetails,
  parseGeocodeResult,
} from '../../utils/google.api';
import Typeahead from '../Form/Typeahead';
import LinkButton from '../Button/LinkButton';
import {useTranslation} from 'react-i18next';
import CustomAddressForm from '../CustomAddressForm/CustomAddressForm';
import {useRemoveError} from '../../hooks/formik';
import styles from './LocationAutocomplete.module.scss';

const LocationAutocomplete = (props) => {
  const {
    multiple,
    value,
    isInvalid,
    error,
    allowCustomAddress,
    existingAddressId,
    ...rest
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showNewLocation, setShowNewLocation] = useState(false);
  const [showCustomAddressButton, setShowCustomAddressButton] = useState(false);
  const {t} = useTranslation('create-account');
  let typeahedRef = null;

  const buttonText = showNewLocation
    ? t('new-address-button.clear')
    : t('new-address-button.new-address');
  const emptyLabelMessage = allowCustomAddress
    ? t('new-address-error.custom-address')
    : t('new-address-error.no-match');

  const handleShowCustomAddress = () => {
    setShowNewLocation(!showNewLocation);
    if (showNewLocation) setShowCustomAddressButton(false);

    const instance = typeahedRef.current.getInstance();
    instance.clear();
  };

  const setTypeaheadRef = (ref) => (typeahedRef = ref);

  const onLocationSearch = async (value) => {
    try {
      setIsLoading(true);
      const results = await getGoogleAutocompleteResults(value);
      const options = results.map((result) => ({
        label: result.description,
        value: result.description,
        placeId: result.place_id,
      }));

      setOptions(options);
      setShowCustomAddressButton(false);
    } catch (e) {
      if (allowCustomAddress) {
        setShowCustomAddressButton(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnChange = async (places) => {
    setIsSelecting(true);
    let address;
    if (places.length) {
      const details = await getGooglePlaceDetails(places[0].placeId);
      const normalizedPlaceDetails = await getNormalizedPlaceDetails(details);
      address = parseGeocodeResult(normalizedPlaceDetails);
      address.place = details.formatted_address;
    }
    if (existingAddressId) {
      props.onAddressAdded(address, existingAddressId);
    } else {
      props.onAddressAdded(address);
    }
    setIsSelecting(false);
    setSelected([]);
  };
  const {removeError} = useRemoveError(props.name, handleOnChange);

  const valueMap = (address) => {
    return (
      <div className={styles.addressWrapper} key={address.location}>
        <div>{address.place}</div>
        <FontAwesomeIcon
          icon={faTimesCircle}
          color="#FC381D"
          onClick={props.onAddressRemoved}
        />
      </div>
    );
  };

  const handleAddCustomAddress = async (location) => {
    const newLocation = {
      city: location.city,
      state: location.state,
      country: location.country,
      country_short: location.countryShort,
      postal_code: location.zipCode,
      number: location.streetNumber,
      street: location.street,
      location: '0,0',
      place:
        `${location.streetNumber}, ${location.streetName}, ${location.city}, ` +
        `${location.zipCode}, ${location.state}, ${location.country}`,
    };

    if (existingAddressId) {
      props.onAddressAdded(newLocation, existingAddressId);
    } else {
      props.onAddressAdded(newLocation);
    }
    setShowNewLocation(false);
  };

  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        <Typeahead
          {...rest}
          className={styles.customTypeahead}
          bsSize="lg"
          id={'location-autocomplete'}
          emptyLabel={emptyLabelMessage}
          onChange={removeError}
          isLoading={isLoading}
          disabled={showNewLocation || isSelecting}
          onSearch={onLocationSearch}
          selected={selected}
          allowNew={false}
          options={options}
          // @FIXME NL-1598 (verify if this is working)
          // selectHintOnEnter
          selectPartialMatchOnEnter
          isInvalid={isInvalid}
          error={error}
          data-hj-allow
          setRef={setTypeaheadRef}
        />
        {showCustomAddressButton && (
          <LinkButton
            className={styles.customLink}
            onClick={handleShowCustomAddress}>
            {buttonText}
          </LinkButton>
        )}
      </div>
      {multiple && !!value.length && (
        <div className={styles.listWrapper}>{value.map(valueMap)}</div>
      )}
      {showNewLocation && (
        <CustomAddressForm onAddAddress={handleAddCustomAddress} />
      )}
    </React.Fragment>
  );
};

export default LocationAutocomplete;
