import React from 'react';

import styled, {ThemeProvider} from 'styled-components';
import theme from '../../theme';
import {Card} from 'react-bootstrap';

import Paragraph from '../Paragraph';
import CategoryImage from '../CategoryImage';
import {mediaQueries, Z_INDEX} from '../../constants';

const Wrapper = styled(({backgroundImage, backgroundImageSmall, ...rest}) => (
  <Card {...rest} />
))`
  padding: ${(props) => props.theme.padding};
  cursor: pointer;
  overflow: hidden;
  justify-content: space-between;

  &.card {
    padding-bottom: 26px;
    ${(props) =>
      props.backgroundImage
        ? `background-image: url(${props.backgroundImage});
    background-size: cover;
    `
        : `background-color: ${props.theme.backgroundColor};`};

    ${mediaQueries.lg} {
      height: 258px;
      ${(props) =>
        props.backgroundImageSmall
          ? `background-image: url(${props.backgroundImageSmall});
      background-size: cover;
      `
          : `background-color: ${props.theme.backgroundColor};`};
    }
  }
`;

const Category = styled.h3`
  font-size: ${(props) => props.theme.subtitleFontSize};
  font-weight: 600;
  color: #ffffff;
  margin: 0;
  z-index: ${Z_INDEX.aboveOverlay};
  line-height: 18px;
`;

const TextWrapper = styled.div`
  z-index: ${Z_INDEX.aboveOverlay};
  height: 5rem;
`;

const Location = styled(Category).attrs({as: 'p'})`
  text-transform: uppercase;
  color: #ffffff;
  z-index: ${Z_INDEX.aboveOverlay};
`;

const Name = styled(Paragraph)`
  font-size: ${(props) => props.theme.descriptionFontSize};
  line-height: ${(props) => props.theme.descriptionLineHeight}px;
  color: #ffffff;
  margin: 0;
  font-weight: bold;
  overflow: hidden;
  max-height: ${(props) => props.theme.descriptionLineHeight * 2}px;
  z-index: ${Z_INDEX.aboveOverlay};
  letter-spacing: -0.41px;
  ${(props) => (props.hasMinHeight ? 'min-height: 27px;' : '')}
  ${(props) => (props.hasMinHeight ? 'padding-right 15px;' : '')}
`;

const Overlay = styled.div`
  position: absolute;
  background-color: black;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${Z_INDEX.overlay};
  opacity: 0.5;
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: 1px solid white;
  font-size: 14px;
  border-radius: 15px;
  z-index: ${Z_INDEX.aboveOverlay};
  align-self: flex-start;
  padding: 5px 20px;
  color: white;
  font-size: 14px;
  outline: none;

  &:active {
    background: none;
    outline: none;
  }
`;

const issueThemes = {
  small: {
    maxHeight: 100,
    backgroundColor: 'white',
    color: theme.primaryColor,
    descriptionColor: '#42434C',
    descriptionFontSize: '18px',
    descriptionLineHeight: 22,
    subtitleFontSize: '12px',
    padding: '26px 20px 37px',
    categoryWidth: '40px',
  },
  large: {
    maxHeight: 100,
    backgroundColor: 'white',
    color: theme.primaryColor,
    descriptionColor: '#42434C',
    descriptionFontSize: '30px',
    descriptionLineHeight: 35,
    subtitleFontSize: '16px',
    padding: '26px 20px 37px',
    categoryWidth: '50px',
  },
  'full-width': {
    maxHeight: 100,
    backgroundColor: theme.primaryColor,
    color: 'white',
    descriptionColor: 'white',
    descriptionFontSize: '30px',
    descriptionLineHeight: 46,
    subtitleFontSize: '12px',
    padding: '26px 20px 15px',
    categoryWidth: '50px',
  },
};

const StyledCategoryImage = styled(CategoryImage)`
  width: ${(props) => props.theme.categoryWidth};
`;

const Issue = (props) => {
  const issue = props.issue;
  const variant = props.variant || 'small';
  const backgroundImage =
    props.variant === 'full-width' ? issue.banner : issue.image;

  return (
    <ThemeProvider theme={issueThemes[variant]}>
      <Wrapper
        className={props.className}
        onClick={props.onClick}
        backgroundImage={backgroundImage}
        backgroundImageSmall={issue.image}
      >
        <Overlay />
        <TextWrapper>
          <Name
            lines={variant === 'full-width' ? 1 : 2}
            text={issue.name}
            hasMinHeight={props.CTAText}
          />
          <Location>
            {issue.location.city && `${issue.location.city}, `}
            {issue.location.country}
          </Location>
        </TextWrapper>
        {props.CTAText && <StyledButton>{props.CTAText}</StyledButton>}
        {issue.main_category && (
          <StyledCategoryImage src={issue.main_category.image} />
        )}
      </Wrapper>
    </ThemeProvider>
  );
};

export default Issue;
