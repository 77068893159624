import React, {useReducer, useMemo} from 'react';
import {setLocalToken, getLocalToken} from '../pages/MapPage/auth';

const initialState = {
  token: getLocalToken(),
  loaded: false,
  data: {},
  locationByIp: null,
};

const SessionContext = React.createContext();

const types = {
  SESSION_INIT: 'SESSION_INIT',
  SESSION_SET: 'SESSION_SET',
  SESSION_UNSET: 'SESSION_UNSET',
  SESSION_SET_LOCATION_BY_IP: 'SESSION_SET_LOCATION_BY_IP',
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SESSION_INIT: {
      return {
        ...state,
        loaded: true,
      };
    }
    case types.SESSION_SET: {
      return {
        ...state,
        token: action.payload.token,
        data: action.payload.data,
      };
    }
    case types.SESSION_UNSET: {
      return {
        ...state,
        token: null,
        data: null,
      };
    }
    case types.SESSION_SET_LOCATION_BY_IP: {
      return {
        ...state,
        locationByIp: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const SessionProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    token: getLocalToken(),
  });

  const actions = useMemo(() => {
    return {
      init: () => {
        return dispatch({
          type: types.SESSION_INIT,
        });
      },
      setSession: (token, payload) => {
        dispatch({
          type: types.SESSION_SET,
          payload: {
            data: payload,
            token: token,
          },
        });
      },
      unsetSession: () => {
        setLocalToken(null);
        dispatch({
          type: types.SESSION_UNSET,
        });
      },
      setLocationByIp: (payload) => {
        dispatch({
          payload,
          type: types.SESSION_SET_LOCATION_BY_IP,
        });
      },
    };
  }, [dispatch]);

  return (
    <SessionContext.Provider value={[state, actions]}>
      {children}
    </SessionContext.Provider>
  );
};

const useSession = () => {
  const context = React.useContext(SessionContext);
  if (context === undefined) {
    throw new Error('`useSession` must be used within a `SessionContext`.');
  }
  return context;
};

export {SessionContext, SessionProvider, useSession};
