import React, {useEffect, useCallback} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {Route} from '../../components/Layout';
import {MapContainer} from './components/MapContainer';
import {PanelSearchPortal} from './components/PanelSearch';
import {ApiProvider} from './contexts/ApiContext';
import {SessionProvider, useSession} from '../../contexts/SessionContext';
import {SearchProvider} from './contexts/SearchContext';
import {MapContainerProvider} from './contexts/MapContainerContext';
import {PageProvider} from '../../contexts/PageContext';
import {useItemFilters, useItems} from '../../hooks/items';
import ClaimFlow from '../../components/ClaimFlow/ClaimFlow';
import {PanelPresence} from './components/PanelPresence';
import {useStores} from '../../contexts/StoresContext';
import {useSearchStore} from './contexts/SearchContext';
import {useLocalStorageFlag} from '../../hooks/localstorage';
import styles from './MapPage.module.scss';

const MapPage = () => {
  const stores = useStores();
  const [sessionState] = useSession();
  const match = useRouteMatch();
  const history = useHistory();
  const [searchState, searchActions] = useSearchStore();
  const [queryString, initialized] = useItemFilters();
  const [setShowOnboarding, showOnboarding] = useLocalStorageFlag(
    'MAP_ONBOARDING',
    true,
  );
  const {fetchItems} = useItems(queryString, initialized);
  const isPublicPage = !stores.app.user;

  const handleSearch = useCallback(() => {
    fetchItems(true);
  }, [fetchItems]);

  const handleClose = useCallback(() => {
    history.push(match.url);
  }, [history, match.url]);

  useEffect(() => {
    stores.app.togglePublicPage(isPublicPage);
  }, [isPublicPage, stores.app]);

  useEffect(
    () => (showOnboarding ? searchActions.setTooltipStep('first-step') : null),
    [showOnboarding, searchActions],
  );

  useEffect(() => {
    if (searchState.tooltipStep === null) {
      setShowOnboarding(false);
    }
  }, [searchState.tooltipStep, setShowOnboarding]);
  return (
    sessionState.loaded && (
      <React.Fragment>
        <MapContainerProvider>
          <div className={styles.container}>
            <MapContainer />
            <PanelSearchPortal />
          </div>
        </MapContainerProvider>
        <ClaimFlow onClose={handleClose} onSearch={handleSearch}>
          <Route
            exact
            path={`${match.url}/details`}
            render={() => <PanelPresence />}
            isPublic
          />
        </ClaimFlow>
      </React.Fragment>
    )
  );
};

const MapPageWrapper = () => {
  return (
    <SessionProvider>
      <ApiProvider>
        <SearchProvider>
          <PageProvider>
            <MapPage />
          </PageProvider>
        </SearchProvider>
      </ApiProvider>
    </SessionProvider>
  );
};

export default MapPageWrapper;
