import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Card, Form, Button} from 'react-bootstrap';
import styles from './PanelSearch.module.scss';
import tooltipStyles from '../../../../components/Tooltip/Tooltip.module.scss';
import {useSearchStore} from '../../contexts/SearchContext';
import {LocationField} from '../Fields/LocationField';
import {SearchIndexField} from '../Fields/SearchIndexField';
import {useMapContainer} from '../../contexts/MapContainerContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faSquare,
  faCheckSquare,
  faQuestionCircle,
  faEdit,
} from '@fortawesome/free-regular-svg-icons';
import {useFormik} from 'formik';
import Tooltip from '../../../../components/Tooltip';
import HelpStep from '../HelpStep';
import * as Yup from 'yup';
import {isFeatureEnabled} from '../../../../utils/features';
import {FEATURES} from '../../../../constants';
import {createTranslationKey} from '../../../../utils/i18n';
import ErrorMessage from '../../../../components/Form/ErrorMessage';
import Label from '../../../../components/Form/Label';
import {useTranslatedErrors} from '../../../../hooks/formik';
import {useSearchLocationApi} from '../../../../hooks/search-location';
import {useStores} from '../../../../contexts/StoresContext';
import {usePanelStore} from '../../../../contexts/PanelContext';
import {useIsMobile} from '../../../../hooks/utils';

const CHECKBOX_CLASSNAME_CHOICES = {
  'needslist_item.Need': styles.needCheckbox,
  'needslist_item.Offer': styles.offerCheckbox,
  'needslist_iop.Manufacturer': styles.manufacturerCheckbox,
};

const PanelSearch = () => {
  const {t} = useTranslation(['search', 'thank-you']);
  const stores = useStores();
  const [searchState, searchActions] = useSearchStore();
  const [mapContainerState, mapContainerActions] = useMapContainer();
  const [, panelActions] = usePanelStore();
  const searchLocationApi = useSearchLocationApi();
  const showManufacturers = isFeatureEnabled(
    FEATURES.showManufacturers,
    stores.app.features,
  );
  const [isVisible, setIsVisible] = useState(true);
  const isMobile = useIsMobile();

  const {values, setFieldValue, errors, touched, handleSubmit} = useFormik({
    initialValues: {
      location: null,
      textFilter: '',
      countryFilter: '',
      modelFilter: ['needslist_item.Need', 'needslist_item.Offer'],
    },
    validationSchema: Yup.object().shape({
      location: Yup.object()
        .nullable()
        .required(createTranslationKey('errors.map-search.location')),
      textFilter: Yup.string().notRequired(),
      countryFilter: Yup.string().notRequired(),
      modelFilter: Yup.array().min(
        1,
        createTranslationKey('errors.map-search.map-filters'),
      ),
    }),
    onSubmit: (values) => {
      panelActions.setPanelMount(false);
      isMobile && setIsVisible(false);
      mapContainerActions.centerMap();
      searchActions.submit({
        textFilter: values.textFilter,
        countryFilter: values.location.place.country,
        modelFilter: values.modelFilter,
        bounds: mapContainerState.locationBounds,
      });
    },
  });

  const translatedErrors = useTranslatedErrors(errors);

  const helpStepRef1 = useRef();
  const helpStepRef2 = useRef();

  const MODEL_CHOICES = useMemo(
    () => ({
      'needslist_item.Need': t('search:search-box.side-filters.needs'),
      'needslist_item.Offer': t('search:search-box.side-filters.offers'),
      'needslist_iop.Manufacturer': t(
        'search:search-box.side-filters.manufacturers',
      ),
    }),
    [t],
  );

  useEffect(() => {
    if (!values.location) {
      setFieldValue('textFilter', '');
    } else {
      mapContainerActions.setLocation(values.location);
    }
  }, [values.location, setFieldValue, mapContainerActions]);

  const handleChangeModelFilter = (model) => {
    const update = [...values.modelFilter];
    const index = update.indexOf(model);
    if (~index) {
      update.splice(index, 1);
    } else {
      update.push(model);
    }
    setFieldValue('modelFilter', update);
  };

  const handleTooltipNext = (step) => () => searchActions.setTooltipStep(step);

  const handleCloseTooltip = () => {
    searchActions.setTooltipStep(null);
  };

  const setFieldLocation = useCallback(
    (val) => setFieldValue('location', val),
    [setFieldValue],
  );

  useEffect(() => {
    const searchLocation = async () => {
      if (mapContainerState.center) {
        const {lat, lng} = mapContainerState.center;
        const locationByLatLng = await searchLocationApi.searchByLatLng(
          lat,
          lng,
        );
        const location =
          locationByLatLng.results[locationByLatLng.results.length - 1];
        if (location) {
          const place = await searchLocationApi.getPlace(location.place_id);
          location.place = {
            geometry: place.geometry,
            country: null,
          };
          if (!location.description) {
            location.description = place.formatted_address;
          }
          const addressComponent = place.address_components.filter((c) =>
            c.types.includes('country'),
          );
          if (addressComponent) {
            location.place.country = addressComponent[0].short_name;
          }
          setFieldLocation(location);
          handleSubmit();
        }
      }
    };
    try {
      searchLocation();
    } catch {
      setFieldLocation(null);
    }
  }, [
    handleSubmit,
    mapContainerState.center,
    searchLocationApi,
    setFieldLocation,
  ]);

  const editForm = () => {
    setIsVisible(!isVisible);
  };

  return (
    <React.Fragment>
      <Card className={styles.container}>
        <Card.Body>
          <Card.Title>{t('search:search-box.title')}</Card.Title>
          <FontAwesomeIcon
            icon={faEdit}
            className={styles.iconEdit}
            onClick={editForm}
            hidden={isVisible}
          />
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className={styles.icon}
            onClick={() => searchActions.setTooltipStep('first-step')}
          />
          <Form noValidate onSubmit={handleSubmit} hidden={!isVisible}>
            <Form.Group>
              <Label isRequired>{t('search:search-box.location-filter')}</Label>
              <LocationField
                name="location"
                setFieldValue={setFieldLocation}
                value={values.location}
                isInvalid={touched.location && !!errors.location}
              />
              {touched.location && translatedErrors.location && (
                <ErrorMessage forceDisplay>
                  {translatedErrors.location}
                </ErrorMessage>
              )}
            </Form.Group>

            <Form.Group
              ref={helpStepRef1}
              className={
                searchState.tooltipStep === 'first-step' && styles.activeTooltip
              }>
              <Form.Label>{t('search:search-box.text-filter')}</Form.Label>
              <SearchIndexField
                disabled={!values.location}
                name="textFilter"
                country={values.location && values.location.place.country}
                setFieldValue={(val) => setFieldValue('textFilter', val)}
                value={values.textFilter}
                isInvalid={touched.textFilter && !!errors.textFilter}
              />
            </Form.Group>

            <Form.Group
              ref={helpStepRef2}
              className={
                searchState.tooltipStep === 'second-step' &&
                styles.activeTooltip
              }>
              <Label isRequired>
                {t('search:search-box.side-filters.title')}
              </Label>
              {Object.keys(MODEL_CHOICES).map((model) => {
                if (
                  !showManufacturers &&
                  model === 'needslist_iop.Manufacturer'
                ) {
                  return null;
                }

                const checkboxClassName = CHECKBOX_CLASSNAME_CHOICES[model];

                return (
                  <Form.Check key={`model-filter-${model}`} type="checkbox">
                    <Form.Check.Input
                      id={model}
                      checked={values.modelFilter.indexOf(model) !== -1}
                      type="checkbox"
                      onChange={() => handleChangeModelFilter(model)}
                    />
                    <FontAwesomeIcon
                      icon={
                        values.modelFilter.indexOf(model) !== -1
                          ? faCheckSquare
                          : faSquare
                      }
                      onClick={() => handleChangeModelFilter(model)}
                      size="lg"
                      className={`${styles.checkbox} ${checkboxClassName}`}
                    />
                    <Form.Check.Label
                      htmlFor={model}
                      className={checkboxClassName}>
                      {MODEL_CHOICES[model]}
                    </Form.Check.Label>
                  </Form.Check>
                );
              })}
              {touched.modelFilter && translatedErrors.modelFilter && (
                <ErrorMessage forceDisplay>
                  {translatedErrors.modelFilter}
                </ErrorMessage>
              )}
            </Form.Group>
            <div className="d-grid gap-2">
              <Button
                variant="primary"
                type="submit"
                disabled={searchState.isLoading}>
                {searchState.isLoading && (
                  <FontAwesomeIcon icon="spinner" className="fa-spin" />
                )}{' '}
                {t('search:search-box.submit')}
              </Button>
            </div>
          </Form>
          <Card.Footer hidden={isVisible}>
            {values && (
              <span className={styles.cardFooter}>
                Location <b>{values.location?.description}</b>
                <br />
                I'm looking for <b>{values.textFilter}</b>
              </span>
            )}
          </Card.Footer>
        </Card.Body>
      </Card>

      <HelpStep parent={helpStepRef1} step="first-step">
        <Tooltip
          className={tooltipStyles.firstTooltip}
          onClose={handleCloseTooltip}
          onNext={handleTooltipNext('second-step')}>
          {t('search:tooltips.first-step')}
        </Tooltip>
      </HelpStep>
      <HelpStep parent={helpStepRef2} step="second-step">
        <Tooltip
          className={tooltipStyles.secondTooltip}
          onClose={handleCloseTooltip}
          onNext={handleTooltipNext('third-step')}>
          {t('search:tooltips.second-step')}
        </Tooltip>
      </HelpStep>
    </React.Fragment>
  );
};

export default PanelSearch;
