const API_URL = '/api/v1';

class JsonError extends Error {
  constructor(data) {
    super();
    this.__json__ = data;
  }

  getCode() {
    return this.__json__.code;
  }
}

class Api {
  constructor(token) {
    this.token = token;
  }

  async request(method, path, data) {
    const options = {
      method: method,
      headers: this.getHeaders(),
    };

    if (data) {
      options.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(`${API_URL}${path}`, options);
      const result = await response.json();
      if (response.status >= 200 && response.status < 300) {
        return result;
      } else {
        throw new JsonError(result);
      }
    } catch (e) {
      if (e.__json__ && e.__json__.code) {
        throw e;
      } else if (e.__json__ && !e.__json__.code) {
        e.__json__.code = 'other';
        throw e;
      } else {
        throw new JsonError({
          __json__: {
            code: 'other',
            message: e.toString(),
          },
        });
      }
    }
  }

  post(path, data) {
    return this.request('post', path, data);
  }

  put(path, data) {
    return this.request('put', path, data);
  }

  patch(path, data) {
    return this.request('PATCH', path, data);
  }

  delete(path, data) {
    return this.request('delete', path, data);
  }

  getQs(params) {
    return Object.keys(params || {}).reduce((accum, key) => {
      const value = params[key];
      if (typeof value == 'undefined') {
        return accum;
      }
      if (Array.isArray(value)) {
        value.forEach((v) => accum.append(key, v));
      } else {
        accum.append(key, value);
      }
      return accum;
    }, new URLSearchParams());
  }

  get(path, params) {
    const qs = this.getQs(params);
    return this.request('get', params ? `${path}?${qs}` : path);
  }

  getHeaders() {
    const headers = {
      'Content-Type': 'application/json',
    };

    if (this.token) {
      headers['Authorization'] = `Token ${this.token}`;
    }

    return headers;
  }

  /**
   * Public
   */
  getSession() {
    if (this.token) {
      return this.post(`/auth/tokens/session/`, {
        token: this.token,
      });
    } else {
      return this.get(`/auth/public/`);
    }
  }

  login(user, password) {
    return this.post(`/auth/tokens/`, {
      username: user,
      password: password,
    });
  }

  logout() {
    return Promise.resolve();
  }

  getPresenceDetails(presencesData, page) {
    return this.post(`/search/presence/?page=${page || 1}`, presencesData);
  }

  getIpLocation() {
    return this.get('/ip-location/');
  }

  getLanguages() {
    return this.get('/languages/');
  }
}

export {Api};
