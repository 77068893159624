import React from 'react';
import styles from './SavedView.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-regular-svg-icons';

const SavedViewToast = (props) => {
  const {text, onClose} = props;

  return (
    <div className={styles.toastContainer}>
      <FontAwesomeIcon className={styles.checkIconToast} icon={faCheckCircle} />
      <div className={styles.toastText}>{text}</div>
      <img
        src="https://fonts.gstatic.com/s/i/short-term/release/googlesymbols/close/default/24px.svg"
        alt="close icon"
        className={styles.closeIcon}
        onClick={onClose}
      />
    </div>
  );
};

export default SavedViewToast;
