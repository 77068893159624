import React, {useReducer, useMemo} from 'react';
import {useStores} from '../../../contexts/StoresContext';
import {useSearchIndexApi} from '../../../hooks/search-index';

const initialState = {
  isLoading: false,
  results: [],
  presencesLoading: false,
  presences: [],
  presencesCount: null,
  organizationCount: null,
  modelFilter: [],
  bounds: null,
  boundsTimer: null,
  tooltipStep: null,
};

const SearchContext = React.createContext();

const types = {
  SEARCH_SET_QUERY: 'SEARCH_SET_QUERY',
  SEARCH_SET_IS_LOADING: 'SEARCH_SET_IS_LOADING',
  SEARCH_SET_PRESENCES_LOADING: 'SEARCH_SET_PRESENCES_LOADING',
  SEARCH_SET_RESULTS: 'SEARCH_SET_RESULTS',
  SEARCH_SET_PARAMS: 'SEARCH_SET_PARAMS',

  SEARCH_SET_BOUNDS: 'SEARCH_SET_BOUNDS',
  SEARCH_SET_BOUNDS_TIMER: 'SEARCH_SET_BOUNDS_TIMER',

  SEARCH_SET_TOOLTIP_STEP: 'SEARCH_SET_TOOLTIP_STEP',
};

const reducer = (state, action) => {
  const {type, payload} = action;

  switch (type) {
    case types.SEARCH_SET_IS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case types.SEARCH_SET_PRESENCES_LOADING: {
      return {
        ...state,
        presencesLoading: payload,
      };
    }
    case types.SEARCH_SET_RESULTS: {
      return {
        ...state,
        results: payload.results,
      };
    }
    case types.SEARCH_SET_PARAMS: {
      return {
        ...state,
        ...payload,
      };
    }
    case types.SEARCH_SET_BOUNDS: {
      if (state.boundsTimer) {
        clearTimeout(state.boundsTimer);
      }
      return {
        ...state,
        bounds: payload.bounds,
        boundsTimer: null,
      };
    }
    case types.SEARCH_SET_BOUNDS_TIMER: {
      if (state.boundsTimer) {
        clearTimeout(state.boundsTimer);
      }
      return {
        ...state,
        boundsTimer: payload.boundsTimer,
      };
    }
    case types.SEARCH_SET_TOOLTIP_STEP: {
      return {
        ...state,
        tooltipStep: payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const SearchProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });
  const stores = useStores();
  const searchIndexApi = useSearchIndexApi();

  const actions = useMemo(() => {
    return {
      setOrganizationCount: (count) => {
        dispatch({
          type: types.SEARCH_SET_PARAMS,
          payload: {organizationCount: count},
        });
      },
      setPresencesLoading: (loading) => {
        dispatch({
          type: types.SEARCH_SET_PRESENCES_LOADING,
          payload: loading,
        });
      },
      setPresences: (presences) => {
        dispatch({
          type: types.SEARCH_SET_PARAMS,
          payload: {
            presences: presences,
          },
        });
      },
      setPresencesCount: (count) => {
        dispatch({
          type: types.SEARCH_SET_PARAMS,
          payload: {
            presencesCount: count,
          },
        });
      },
      setTooltipStep: (step) => {
        dispatch({
          type: types.SEARCH_SET_TOOLTIP_STEP,
          payload: step,
        });
      },
      submit: async ({textFilter, bounds, countryFilter, modelFilter = []}) => {
        const models = [...modelFilter];
        const metaSide = modelFilter.map((record, index) =>
          record.split('.')[1].toLowerCase(),
        );

        dispatch({
          type: types.SEARCH_SET_PARAMS,
          payload: {
            textFilter: textFilter,
            modelFilter: modelFilter,
            countryFilter: countryFilter,
            metaSide,
          },
        });
        dispatch({
          type: types.SEARCH_SET_IS_LOADING,
          payload: true,
        });
        try {
          const res = await searchIndexApi.search(textFilter, {
            bounds: bounds,
            models: ['needslist_item.Item'],
            metaCountry: countryFilter,
            limit: searchIndexApi.MAX,
            distinct: true,
            metaSide,
          });
          dispatch({
            type: types.SEARCH_SET_RESULTS,
            payload: {
              results: res,
              lang: stores.app.language,
              filter: textFilter,
              models: models,
            },
          });
        } catch (err) {
          dispatch({
            type: types.SEARCH_SET_RESULTS,
            payload: {
              results: [],
            },
          });
        } finally {
          dispatch({
            type: types.SEARCH_SET_IS_LOADING,
            payload: false,
          });
        }
      },
      setBounds: (bounds) => {
        const boundsTimer = setTimeout(() => {
          dispatch({
            type: types.SEARCH_SET_BOUNDS,
            payload: {
              bounds,
            },
          });
        }, 1000);

        dispatch({
          type: types.SEARCH_SET_BOUNDS_TIMER,
          payload: {
            boundsTimer,
          },
        });
      },
    };
  }, [searchIndexApi, dispatch, stores.app.language]);

  return (
    <SearchContext.Provider value={[state, actions]}>
      {children}
    </SearchContext.Provider>
  );
};

const useSearchStore = () => {
  const context = React.useContext(SearchContext);
  if (context === undefined) {
    throw new Error('`useSearchStore` must be used within a `SearchContext`.');
  }
  return context;
};

export {SearchContext, SearchProvider, useSearchStore};
