import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';

import Modal from './Modal';
import {ReactComponent as RequestJoinImg} from '../../assets/icons/request-join.svg';
import RequestJoinForm from '../ItemClaimForm/RequestJoinForm';

const RequestJoinModal = (props) => {
  const {onClose} = props;
  const history = useHistory();

  const handleOnSubmit = () => {
    history.push('/search/request-join/finish');
  };

  return (
    <Modal onClose={onClose}>
      <Row>
        <Col sm={6}>
          <RequestJoinImg />
        </Col>
        <Col sm={6}>
          <RequestJoinForm
            isModal
            onSubmit={handleOnSubmit}
            onClose={onClose}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default RequestJoinModal;
