export const LOGIN_PAGE = '/login';
export const SIGNUP_PAGE = '/signup';
export const RESET_PASSWORD_PAGE = '/reset-password/:uid';
export const FORGOT_PASSWORD_PAGE = '/forgot-password';
export const PRIVACY_PAGE = '/privacy';
export const HELP_PAGE = '/help';
export const FAQ_PAGE = '/faq';
export const TOS_PAGE = '/terms-of-service';
export const STATIC_FILE_PAGE = '/static-file/:fileName';

export const HOME_PAGE = '/';
export const SEARCH_PAGE = '/search';
export const MANAGE_PAGE = '/manage';
export const ITEM_CONFIRM = '/manage/item-confirmation/:itemId';
export const CLAIMED_ITEM_CONFIRM = '/manage/claimed-item-confirmation/:itemId';
export const ITEM_EDIT = '/manage/edit/:itemId';
export const ITEM_EDIT_QUANTITY = '/manage/edit-quantity/:itemId';
export const ITEM_DETAIL = '/manage/detail/:itemId';
export const ADD_NEEDS = '/add-needs';
export const ADD_OFFERS = '/add-offers';

export const MAP_PAGE = '/map';
export const MAP_PAGE_V1 = '/map-v1';

export const ISSUE_PAGE = '/issues/:slug';
export const ISSUE_PAGE_REQUEST_JOIN = `${ISSUE_PAGE}/request-join`;
export const ISSUE_RESPONSE = '/issue-response/:issueResponseId';
export const ISSUE_RESPONSE_PAGE =
  '/issue-response/:issueResponseId/:side/:itemId?';
export const ISSUE_RESPONSE_ITEM_CLAIM =
  '/issue-response/:issueResponseId/:side/:itemId/suggested-items/item/:suggestedItemId';
export const ISSUE_RESPONSE_SUGGESTED_ITEMS =
  '/issue-response/:issueResponseId/:side/:itemId/suggested-items';
export const ISSUE_RESPONSE_FINISH =
  '/issue-response/:issueResponseId/:side/finish';
export const ISSUE_RESPONSE_EDIT =
  '/issue-response/:issueResponseId/:side/:itemId';
export const BROWSE_ISSUES_PAGE = '/browse-issues';

export const BROWSE_MEMBERS_PAGE = '/browse-members';
export const MEMBER_PAGE = '/browse-members/:slug';
export const MEMBER_PAGE_EDIT = '/browse-members/:slug/edit';

export const USER_PROFILE = '/:userUUID/user-profile';
export const USER_PROFILE_EDIT_PROFILE = `${USER_PROFILE}/edit-profile`;
export const USER_PROFILE_CHANGE_PASSWORD = `${USER_PROFILE}/change-password`;
export const USER_PROFILE_MOBILE_ACCESS = `${USER_PROFILE}/mobile-access`;

export const INVITATION_PAGE = '/invitation/:token/:lang?';
export const MANAGE_ADMIN_PAGE = '/manage-users';
export const INVITE_USER = '/manage-users/invite-user';
export const INVITE_USER_SUCCESS = '/manage-users/invite-user/success';
export const MANAGE_ORGANIZATIONS_PAGE = '/manage-organizations';
export const MANAGE_ITEMS_PAGE = '/manage-items';
export const MANAGE_ISSUES_PAGE = '/manage-issues';
export const MANAGE_ITEM_CATALOG_EDIT_PAGE =
  '/manage-items/edit-item-catalog/:id';
export const MANAGE_USER_POST_EDIT_PAGE = '/manage-items/edit-user-post/:id';
export const MANAGE_ISSUES_EDIT_PAGE = '/manage-issues/edit-issue/:id';

export const MANAGE_ORGANIZATION_DETAILS = '/manage-organizations/:slug';
export const REQUEST_JOIN_INFORMATION =
  '/manage-organizations/:slug/:userUUID/request-join-information';
export const INVITE_ADMIN = '/manage-organizations/invite-admin/invite';
export const INVITE_ADMIN_SUCCESS =
  '/manage-organizations/invite-admin/success';

export const ONBOARDING_PAGE = '/onboarding';
export const ONBOARDING_USER_DETAILS = '/onboarding/user-details';
export const ONBOARDING_ORGANIZATION_DETAILS =
  '/onboarding/organization-details';
export const ONBOARDING_ORGANIZATION_ADDITIONAL_INFORMATION =
  '/onboarding/organization-additional-information';
export const ONBOARDING_ORGANIZATION_DETAILS_FINISH =
  '/onboarding/organization-details/finish';
export const ONBOARDING_USER_CREATION_FINISH =
  '/onboarding/user-creation/finish';
export const ONBOARDING_USER_APPROVAL_PENDING =
  '/onboarding/user-creation/approval-pending';

export const REPORTS_PAGE = '/reports';
export const REPORTS_ACTIVITY_DETAIL = `${REPORTS_PAGE}/:side/:itemId/activity-detail`;
export const ITEM_REPORTS_PAGE = '/item-reports';
export const ITEM_REPORTS_ACTIVITY_DETAIL = `${ITEM_REPORTS_PAGE}/:side/:itemId/activity-detail`;

export const EMAIL_VALIDATION_PAGE = `/verification/verify-email/:encoded_email/:token/`;
