import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {Row, Col} from 'react-bootstrap';

import {mediaQueries} from '../../constants';
import {usePageStore} from '../../contexts/PageContext';
import MembersList from './MembersList';
import {useApi} from '../../contexts/ApiContext';
import SearchBar from '../../components/SearchBar/SearchBar';
import CategoryList from '../BrowseIssues/CategoryList';
import {useIsMobile} from '../../hooks/utils';
import {usePaginator} from '../../hooks/paginator';
import LinkButton from '../../components/Button/LinkButton';
import {MEMBER_PAGE} from '../../urls';
import MembersCountryFilter from './MembersCountryFilter';
import MembersAlphabeticFilter from './MembersAlphabeticFilter';
import Button from '../../components/Button';
import {exportCsv} from '../ReportsPage/utils';
import Subtitle from '../../components/Subtitle/Subtitle';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';

const Wrapper = styled.div`
  margin-bottom: 180px;

  ${mediaQueries.lg} {
    margin-bottom: 30px;
  }
`;

const StyledSearchBar = styled(SearchBar)`
  width: 85%;
  margin: 35px auto 0;

  ${mediaQueries.lg} {
    width: 100%;
  }
`;

const SdgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const StyledLinkButton = styled(LinkButton)`
  margin-bottom: 15px;
`;

const ButtonWrapper = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  ${mediaQueries.md} {
    margin-top: 5px;
    width: 100%;
  }
`;

const BrowseMembers = () => {
  const stores = useStores();
  const api = useApi();
  const {t} = useTranslation(['browse-members', 'common']);
  const isMobile = useIsMobile();
  const match = useRouteMatch();
  const [options, setOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [initials, setInitials] = useState([]);
  const history = useHistory();
  const [, pageActions] = usePageStore();
  const {changePage} = usePaginator();
  const clientFocusAreaTags = stores.app.clientData.focus_area_tags;

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'browse-members'});
  }, [pageActions]);

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await api.getOrgsCountries();
      setAllCountries(response);
    };
    fetchCountries();
  }, [api]);

  const handleSearch = async (value) => {
    const response = await api.getOrgs({search: value});
    const options = response.results.map((option) => ({
      label: option['name'],
      value: option['slug'],
    }));

    setOptions(options);
  };

  const handleCountrySelect = (country) => {
    const newCountries = countries.some((el) => el.value === country.value)
      ? countries.filter((el) => el.value !== country.value)
      : [...countries, country];

    setCountries(newCountries);

    changePage(match.url, 1);
    if (isMobile) history.push(`${match.url}`);
  };

  const handleCategorySelect = (category) => {
    categories.some((c) => category.id === c.id)
      ? setCategories(categories.filter((el) => el !== category))
      : setCategories([...categories, category]);

    changePage(match.url, 1);
    if (isMobile) history.push(`${match.url}`);
  };

  const handleInitialSelect = (initial) => {
    initials.includes(initial)
      ? setInitials(initials.filter((el) => el !== initial))
      : setInitials([...initials, initial]);
    changePage(match.url, 1);
    if (isMobile) history.push(`${match.url}`);
  };

  const handleChange = (options) =>
    history.push(MEMBER_PAGE.replace(':slug', options[0].value));
  const handleOnSdgCategoriesClear = () =>
    setCategories(categories.filter((el) => el.focus_area_type !== 'sdg'));
  const handleOnGeneralCategoriesClear = () =>
    setCategories(categories.filter((el) => el.focus_area_type !== 'general'));

  const handleExport = async () => {
    const response = await api.getBrowseOrgsCsv({
      categories: categories.map((category) => category.id),
      countries: countries.map((country) => country.value),
      initials,
    });
    exportCsv(response, `organizations_${Date.now()}.csv`);
  };

  return (
    <Wrapper>
      <StyledSearchBar
        id="search-bar"
        isLoading={false}
        onChange={handleChange}
        onSearch={handleSearch}
        options={options}
        allowNew={false}
        placeholder={t('common:placeholder')}
        selectPartialMatchOnEnter
      />
      <Subtitle>{t('browse-members:subtitle.filter-by-alphabet')}</Subtitle>
      <MembersAlphabeticFilter
        onInitialSelect={handleInitialSelect}
        selectedInitials={initials}
      />
      {allCountries.length > 1 && (
        <React.Fragment>
          <Subtitle>
            {t('browse-members:subtitle.filter-by-countries')}
          </Subtitle>
          <MembersCountryFilter
            onCountrySelect={handleCountrySelect}
            selectedCountries={countries}
            clientFocusAreaTags={clientFocusAreaTags}
            allMembersCountries={allCountries}
          />
        </React.Fragment>
      )}
      {clientFocusAreaTags === 'enabled' && (
        <React.Fragment>
          <SdgWrapper>
            <Subtitle>
              {t('browse-members:subtitle.filter-by-general')}
            </Subtitle>
            <StyledLinkButton
              disabled={
                !!(
                  categories.filter((el) => el.focus_area_type !== 'sdg')
                    .length === 0
                )
              }
              onClick={handleOnGeneralCategoriesClear}>
              {t('common:buttons.clear-all')}
            </StyledLinkButton>
          </SdgWrapper>
          <CategoryList
            isMobile={isMobile}
            onCategorySelect={handleCategorySelect}
            selectedCategories={categories}
            type="general"
          />
          <SdgWrapper>
            <Subtitle>{t('browse-members:subtitle.filter-by-sdg')}</Subtitle>
            <StyledLinkButton
              disabled={
                !!(
                  categories.filter((el) => el.focus_area_type !== 'general')
                    .length === 0
                )
              }
              onClick={handleOnSdgCategoriesClear}>
              {t('common:buttons.clear-all')}
            </StyledLinkButton>
          </SdgWrapper>
          <CategoryList
            isMobile={isMobile}
            onCategorySelect={handleCategorySelect}
            selectedCategories={categories}
            type="sdg"
          />
        </React.Fragment>
      )}
      <Row>
        <Col sm={6}>
          <Subtitle withoutMargins>
            {t('browse-members:subtitle.members')}
          </Subtitle>
        </Col>
        <ButtonWrapper sm={6}>
          <StyledButton
            onClick={handleExport}
            size="sm"
            variant="outline-primary">
            {t('user:buttons.export-csv')}
          </StyledButton>
        </ButtonWrapper>
      </Row>
      <MembersList
        categories={categories}
        countries={countries}
        initials={initials}
      />
    </Wrapper>
  );
};

export default observer(BrowseMembers);
