import React from 'react';
import styled from 'styled-components';

import {mediaQueries} from '../../constants';
import useCountries from '../../hooks/countries';

import CountriesPills from '../../components/Pills/CountriesPills';

const StyledPills = styled(({clientFocusAreaTags, ...rest}) => (
  <CountriesPills {...rest} />
))`
  ${(props) =>
    props.clientFocusAreaTags === 'disabled' ? 'margin-bottom: 32px;' : ''}
  ${mediaQueries.lg} {
    flex-wrap: nowrap;
    align-content: center;
    white-space: nowrap;
    overflow-y: auto;
  }
`;

const MembersCountryFilter = (props) => {
  const countries = useCountries(props.allMembersCountries);

  return (
    <StyledPills
      pills={countries}
      selected={props.selectedCountries}
      onClick={props.onCountrySelect}
      clientFocusAreaTags={props.clientFocusAreaTags}
    />
  );
};

export default MembersCountryFilter;
