import React from 'react';
import {useField} from 'formik';
import {observer} from 'mobx-react';
import Label from '../Form/Label';
import ReadOnlyField from './ReadOnlyField';
import UnitSelect from '../Form/UnitSelect';
import {useTranslatedError} from '../../hooks/formik';
import {useStores} from '../../contexts/StoresContext';

const UnitSelectField = (props) => {
  const stores = useStores();
  const [field, meta] = useField(props);
  const {label, readOnly, isRequired, ...rest} = props;
  const error = useTranslatedError(meta.error);

  if (readOnly) {
    const uom = stores.app.choices.uom.find(
      (uom) => parseInt(field.value) === uom.id,
    );
    return <ReadOnlyField label={label} value={uom.name} />;
  }

  return (
    <React.Fragment>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <UnitSelect
        {...field}
        {...rest}
        error={error}
        isInvalid={!!meta.error && meta.touched}
      />
    </React.Fragment>
  );
};

export default observer(UnitSelectField);
