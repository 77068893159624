import PlatformInteractionFilter, {
  PLATFORM_INTERACTION_CHOICES,
} from './PlatformInteractionFilter';
import PostTypeFilter, {POST_TYPE_CHOICES} from './PostTypeFilter';
import StatusFilter, {STATUS_CHOICES} from './StatusFilter';
import moment from 'moment';
import DateFilter from './DateFilter';
import KeywordFilter from './KeywordFilter';
import ItemFilter from './ItemFilter';
import {useStores} from '../../contexts/StoresContext';
import {useMemo} from 'react';
import {dateFnsLocales} from '../../i18n';
import BaseSelectFilter from './BaseSelectFilter';

const UI_DATE_FORMAT = 'YYYY-MM-DD';

const apiParams = (filters) => {
  let params = {};
  // Merge all the filters, concatenating all the arrays
  for (const filter of filters) {
    const filterParams = filter.apiParams();
    for (const [key, value] of Object.entries(filterParams)) {
      if (!(key in params)) {
        params[key] = [];
      }
      params[key].push(...value);
    }
  }

  return params;
};

export const itemApiParams = (filters) => {
  let params = apiParams(filters);
  // If there are no non-negative filters for "kind", filter for:
  // supply, service, information
  // TODO: understand and document why. This is keeping the logic of the code
  // I'm changing.
  if (!params['kind'] || !params['kind'].find((k) => !k.startsWith('!'))) {
    if (!params['kind']) {
      params.kind = [];
    }
    params.kind.push(...['supply', 'service', 'information']);
  }
  // Exclude on-hold items, as dictated by requirements.
  // Because such items are not covered by https://docs.google.com/spreadsheets/d/1FM4ovh6AgT-Y1Z6jOcTftZElNIudQwfdT-fPt1LX8lI/edit?usp=sharing
  params['status'] = ['!on_hold'];
  return params;
};

export const summaryApiParams = apiParams;

export const activityDetailApiParams = (filters) => {
  return apiParams(filters.filter((f) => f.type === 'date'));
};

export class DateFilterImpl {
  static type = 'date';
  static component = DateFilter;
  value; // Looks like this could be refactored out?
  include;
  key;
  from;
  to;
  constructor(key, value) {
    this.key = key;
    this.value = value;
    const [op, from, to] = value.split(':');
    // TODO: add explicit invalid value handling
    this.include = op === 'is';
    this.from = moment(from, UI_DATE_FORMAT, true).toDate();
    // "from" and "to" in the API represent timestamps not dates, so to
    // include all the items on the end date itself, we need "end of day" timestamp
    this.to = moment(to, UI_DATE_FORMAT, true).endOf('day').toDate();
  }
  display(t) {
    const prefix = t('filters.dates.summary.label');
    const middle = t(
      'filters.dates.summary.middle.' + (this.include ? 'is' : 'not'),
    );
    const suffix =
      moment(this.from).format('L') + ' - ' + moment(this.to).format('L');
    const is_positive = this.include;
    return {prefix, middle, suffix, is_positive};
  }
  apiParams() {
    return {
      // Using default date format preserves the current browser timezone,
      // so the output is consistent with how dates are displayed in the
      // items table.
      from: [moment(this.from).format()],
      to: [moment(this.to).format()],
      include_range: [this.include],
    };
  }
  static fromDateRange(from, to) {
    // This exists to support legacy reports.
    return new DateFilterImpl(
      '',
      `is:${moment(from).format(UI_DATE_FORMAT)}:${moment(to).format(
        UI_DATE_FORMAT,
      )}`,
    );
  }
}

export class KeywordFilterImpl {
  static type = 'keyword';
  static component = KeywordFilter;
  key;
  value;
  op;
  keywords;
  constructor(key, value) {
    this.key = key;
    this.value = value;
    const [op, keywords] = value.split(':');
    this.op = op;
    this.keywords = keywords === '' ? [] : keywords.split(';');
  }
  display(t) {
    const prefix = t('filters.keyword.summary.label');
    const middle = t('filters.keyword.summary.middle.' + this.op);
    const is_positive = this.op === 'is' || this.op === 'contains';
    return {prefix, middle, suffix: this.keywords.join(', '), is_positive};
  }
  apiParams() {
    return {
      keyword: this.keywords,
      keyword_op: Array(this.keywords.length).fill(this.op),
    };
  }
}

// Base class for all filters that work by letting a user pick a set of entity
// (organizations, issues, ...) ids to include or exclude.
class EntityIdFilterImpl {
  // Required from descendant classes:
  // static type = ...; - type
  // static i18n_key = ...; - key to use for i18n strings
  // static component = ...; - react component
  // static apiParam = ...; - API param to use
  key;
  value;
  include;
  values;
  constructor(key, value) {
    this.key = key;
    this.value = value;
    const [op, values] = value.split(':');
    this.include = op === 'is';
    // Currently UI supports creation of empty filters.
    this.values =
      values === '' ? [] : values.split(';').map((value) => parseInt(value));
  }
  display(t, lookupData) {
    const prefix = t(`filters.${this.constructor.i18n_key}.summary.label`);
    const middle = t(
      `filters.${this.constructor.i18n_key}.summary.middle.${
        this.include ? 'is' : 'not'
      }`,
    );
    const suffix = this.values
      .map(
        (value) => lookupData.find((item) => item.id === parseInt(value))?.name,
      )
      .join(', ');
    const is_positive = this.include;
    return {prefix, middle, suffix, is_positive};
  }
  apiParams() {
    return {
      [this.constructor.apiParam]: this.values.map(
        (value) => (this.include ? '' : '!') + value,
      ),
    };
  }
}

export class PostedByFilterImpl extends EntityIdFilterImpl {
  static type = 'organization';
  static i18n_key = 'org';
  static component = BaseSelectFilter;
  static apiParam = 'organization';
}

export class MetClaimedByFilterImpl extends EntityIdFilterImpl {
  static type = 'met-claimed-by';
  static i18n_key = 'met-claimed-by';
  static component = BaseSelectFilter;
  static apiParam = 'met-claimed-by';
}

export class IssuesFilterImpl extends EntityIdFilterImpl {
  static type = 'issue';
  static i18n_key = 'issue';
  static component = BaseSelectFilter;
  static apiParam = 'issue';
}

// Base class for all the filters where there's a fixed list of options to
// choose from.
class ChoicesFilterImpl {
  // Required from descendant classes:
  // static type = ...; - type
  // static i18n_key = ...; - key to use for i18n strings
  // static component = ...; - react component
  // static apiParam = ...; - API param to use
  // static choices = ...; - an array of possible choices
  key;
  value;
  values;
  constructor(key, value) {
    // TODO: implement validation
    this.key = key;
    this.value = value;
    this.values = value.split(';').map((value) => parseInt(value));
  }
  display(t) {
    const prefix = t(`filters.${this.constructor.i18n_key}.summary.label`);
    const middle = t(`filters.${this.constructor.i18n_key}.summary.middle.is`);
    const suffix = this.values
      .map((value) =>
        t(
          `filters.${this.constructor.i18n_key}.options.${this.constructor.choices[value]}`,
        ),
      )
      .join(', ');
    const is_positive = true;
    return {prefix, middle, suffix, is_positive};
  }
  apiParams() {
    return {
      [this.constructor.apiParam]: this.values.map(
        (s) => this.constructor.choices[s],
      ),
    };
  }
}

export class StatusFilterImpl extends ChoicesFilterImpl {
  static type = 'status';
  static component = StatusFilter;
  static i18n_key = 'status';
  static apiParam = 'ui_status';
  static choices = STATUS_CHOICES;
}

export class PlatformInteractionFilterImpl extends ChoicesFilterImpl {
  static type = 'pi';
  static component = PlatformInteractionFilter;
  static i18n_key = 'pi';
  static choices = PLATFORM_INTERACTION_CHOICES;
  apiParams() {
    return {
      side: this.values.map((s) =>
        this.constructor.choices[s] === 'needs_posted' ? 'need' : 'offer',
      ),
    };
  }
}

export class PostTypeFilterImpl extends ChoicesFilterImpl {
  static type = 'post_type';
  static component = PostTypeFilter;
  static i18n_key = 'type';
  static apiParam = 'kind';
  static choices = POST_TYPE_CHOICES;
}

export class CategoryFilterImpl extends EntityIdFilterImpl {
  static type = 'category';
  static component = BaseSelectFilter;
  static i18n_key = 'category';
  static apiParam = 'category';
  static optgroup_field = 'kind';
}

export class UomFilterImpl extends EntityIdFilterImpl {
  static type = 'uom';
  static component = BaseSelectFilter;
  static i18n_key = 'uom';
  static apiParam = 'uom';
  static optgroup_field = 'kind';
}

export class ItemFilterImpl {
  static type = 'exclude_ids';
  static component = ItemFilter;
  key;
  value;
  ids;
  constructor(key, value) {
    // TODO: implement validation
    this.key = key;
    this.value = value;
    this.ids = value.split(',');
  }
  display(t) {
    const prefix = this.ids.length;
    const middle = t('filters.item.summary.middle');
    const suffix = t('filters.item.summary.suffix');
    const is_positive = true;
    return {prefix, middle, suffix, is_positive};
  }
  apiParams() {
    return {
      id: this.ids.map((id) => `!${id}`),
    };
  }
}

export const removeQueryParam = (url, name, value) => {
  const queryStringParams = new URLSearchParams(url);
  const filters = queryStringParams.getAll(name);
  queryStringParams.delete(name);
  filters.forEach((val) => {
    if (val !== value) {
      queryStringParams.append(name, val);
    }
  });
  return queryStringParams.toString();
};

export const useDatePickerLocale = () => {
  const stores = useStores();

  return useMemo(() => {
    return dateFnsLocales[stores.app.language];
  }, [stores.app.language]);
};
