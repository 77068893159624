import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Form, Col, Row} from 'react-bootstrap';

import Label from '../../../components/Form/Label';
import {RadioField} from '../../../components/Fields';

const FlexCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const PrivacyFields = (props) => {
  const {t} = useTranslation('create-account');
  const labelSize = props.isFullWidth ? 12 : 4;
  const optionsSize = props.isFullWidth ? 12 : 8;

  const setPrivacyOptions = [
    {
      label: t('options.yes'),
      value: 'public',
    },
    {
      label: t('options.no'),
      value: 'private',
    },
  ];

  return (
    <Form.Group>
      <Row>
        <FlexCol sm={labelSize}>
          <Label>{t('labels.phone-info')}</Label>
        </FlexCol>
        <FlexCol sm={optionsSize}>
          <RadioField
            name="phoneInfo"
            isHorizontal
            horizontalMargin
            options={setPrivacyOptions}
          />
        </FlexCol>
      </Row>
      <Row>
        <FlexCol sm={labelSize}>
          <Label>{t('labels.email-info')}</Label>
        </FlexCol>
        <FlexCol sm={optionsSize}>
          <RadioField
            name="emailInfo"
            isHorizontal
            horizontalMargin
            options={setPrivacyOptions}
          />
        </FlexCol>
      </Row>
      <Row>
        <FlexCol sm={labelSize}>
          <Label>{t('labels.address-info')}</Label>
        </FlexCol>
        <FlexCol sm={optionsSize}>
          <RadioField
            name="addressInfo"
            isHorizontal
            horizontalMargin
            options={setPrivacyOptions}
          />
        </FlexCol>
      </Row>
    </Form.Group>
  );
};

export default PrivacyFields;
