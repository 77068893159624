import {useEffect, useMemo} from 'react';
import {RefinementList, useRefinementList} from 'react-instantsearch';
import {Accordion, Card} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useParams} from 'react-router-dom';
import {
  faChevronDown,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {useSearchAlgoliaStore} from '../../../../contexts/SearchAlgoliaContext';
import {AvailableForRefinements, ModelRefinements} from '../Refinements';
import {CurrentFilters} from '../CurrentFilters';
import {breakpoints} from '../../../../constants';
import StatusRefinements from '../Refinements/StatusRefinements';
import Responsive from '../../../../components/Responsive';
import styles from './SearchFiltersPanel.module.scss';
import {useApi} from '../../../../contexts/ApiContext';

export const SearchFiltersPanel = () => {
  const [searchState, searchActions] = useSearchAlgoliaStore();
  const api = useApi();
  const {slug} = useParams();
  const {refine: refineIssue} = useRefinementList({
    attribute: 'meta_issue_name',
  });

  useEffect(() => {
    const fetchIssue = async () => {
      const response = await api.getIssue(slug);
      refineIssue(response.name);
    };
    if (slug) {
      fetchIssue();
    }
  }, [slug, api, refineIssue]);

  const filters = useMemo(
    () => [
      {
        id: 2,
        header: 'categories',
        limit: 8,
        attribute: 'meta_categories',
      },
      {
        id: 3,
        header: 'member',
        limit: 8,
        attribute: 'meta_organization',
      },
      {
        id: 4,
        header: 'tag',
        limit: 8,
        attribute: 'meta_tags',
      },
      {
        id: 5,
        header: 'status',
        limit: 5,
        attribute: 'meta_status',
        customControl: StatusRefinements,
      },
      {
        id: 6,
        header: 'item type',
        limit: 5,
        attribute: 'meta_kind',
      },
    ],
    [],
  );

  useEffect(() => {
    !slug &&
      filters.unshift({
        id: 1,
        header: 'issue',
        limit: 5,
        attribute: 'meta_issue_name',
      });
  }, [slug, filters]);

  const hideCursor = searchState.hideFilters ? (
    <FontAwesomeIcon icon={faChevronRight} size="1x" />
  ) : (
    <FontAwesomeIcon icon={faChevronLeft} size="1x" />
  );

  return (
    <div
      className={`${styles.container} ${
        searchState.hideFilters && styles.hidePanel
      } ${!searchState.isMapView && styles.listViewContainer}`}>
      <div className={styles.innerWrapper}>
        <CurrentFilters />
        <AvailableForRefinements />
        <ModelRefinements />
        {filters.map((filter) => (
          <Accordion
            key={filter.id}
            className={styles.customAccordion}
            defaultActiveKey={1}>
            <Card>
              <Accordion.Collapse eventKey={filter.id}>
                <Card.Body>
                  {filter.customControl ? (
                    <filter.customControl key={filter.attribute} {...filter} />
                  ) : (
                    <RefinementList
                      limit={filter.limit}
                      attribute={filter.attribute}
                      sortBy={['name']}
                      transformItems={filter.transformItems}
                      showMore={true}
                    />
                  )}
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle as={Card.Header} eventKey={filter.id}>
                {filter.header}
                <FontAwesomeIcon icon={faChevronDown} />
              </Accordion.Toggle>
            </Card>
          </Accordion>
        ))}
      </div>
      {searchState.isMapView && (
        <Responsive>
          <div
            max={breakpoints.lg}
            onClick={() =>
              searchActions.setHideFilters(!searchState.hideFilters)
            }
            className={styles.hideButton}>
            {hideCursor}
          </div>
        </Responsive>
      )}
      <Responsive>
        {searchState.hideFilters && (
          <div
            min={breakpoints.lg}
            onClick={() => searchActions.setHideFilters(false)}
            className={`${styles.hideButton} ${
              !searchState.isMapView && styles.listViewHideButton
            }`}>
            <FontAwesomeIcon icon={faChevronRight} size="1x" />
          </div>
        )}
      </Responsive>
    </div>
  );
};
