import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {useStores} from '../../../contexts/StoresContext';
import {ReactComponent as EmptyActivity} from '../../../assets/icons/reports-activity-empty.svg';
import Spinner from '../../../components/Spinner';
import Table from '../../../components/Table';
import EmptyReport from '../EmptyReport';
import {useLocaleNumber} from '../../../hooks/number';

const PAGE_SIZE = 6;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 241px 0;
`;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 170px 0;
`;

const ActivityDetailTable = ({isLoading, count, items}) => {
  const stores = useStores();
  const localeNumber = useLocaleNumber();
  const {t} = useTranslation(['reports', 'common']);
  const {currency, datetimeFormat} = stores.app;

  const itemsHeaders = useMemo(
    () => [
      {
        title: t('tabs.headers.met-by-member'),
        size: 3,
      },
      {
        title: t('tabs.headers.quantity'),
        size: 2,
      },
      {
        title: t('tabs.headers.status'),
        size: 2,
      },
      {
        title: t('tabs.headers.market-value', {currency: currency.code}),
        size: 2,
      },
      {
        title: t('tabs.headers.met-timestamp'),
        size: 3,
      },
    ],
    [t, currency.code],
  );

  const itemsColumns = useMemo(
    () => [
      {
        renderCell: (item) => item.organizationName,
        size: 3,
      },
      {
        renderCell: (item) => {
          const uom = item.bundle ? item.bundle.uom.name : item.baseUom.name;
          if (item.itemQuantity) {
            return `${localeNumber(item.quantity)} ${uom} /
              ${Math.round((item.quantity / item.itemQuantity) * 100)}%`;
          }
          return `${localeNumber(item.quantity)} ${uom}`;
        },
        size: 2,
      },
      {
        renderCell: (item) => item.confirmationStatus.value,
        size: 2,
      },
      {
        renderCell: (item) => {
          if (item.marketValue) {
            const uom = item.bundle ? item.bundle.uom.name : item.baseUom.name;
            return `${item.currency.symbol} ${localeNumber(
              item.marketValue,
            )} / ${uom}`;
          }
          return t('common:empty-value');
        },
        size: 2,
      },
      {
        renderCell: (item) =>
          moment(item.createdAt).format(datetimeFormat.moment),
        size: 3,
      },
    ],
    [datetimeFormat.moment, t, localeNumber],
  );

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  if (count === 0) {
    return (
      <EmptyContainer>
        <EmptyReport icon={EmptyActivity} />
      </EmptyContainer>
    );
  }

  return (
    <Table
      headers={itemsHeaders}
      columns={itemsColumns}
      data={items}
      count={count}
      pageSize={PAGE_SIZE}
      showPaginator={false}
    />
  );
};

export default observer(ActivityDetailTable);
