import React, {useRef, useEffect, useContext} from 'react';
import styled from 'styled-components';
import {Form} from 'react-bootstrap';

import theme from '../../theme';
import ErrorMessage from './ErrorMessage';
import FormContext from './FormContext';

const StylesWrapper = styled.div`
  .form-control:focus {
    box-shadow: none;
    border-color: ${theme.primaryColor};
    border-width: 0 0 2px 0;
  }

  .form-control {
    border-width: 0 0 2px 0;
    border-radius: 0;
    outline: none;

    &:disabled {
      border-style: dotted;
    }

    &.is-invalid {
      // Default background
      background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px;
    }
  }
`;

const Select = (props) => {
  const {setRef, error, ...rest} = props;
  const {setFieldRef} = useContext(FormContext);
  const inputRef = useRef(null);

  useEffect(() => {
    if (setRef) {
      setRef(inputRef);
    }
  }, [setRef, inputRef]);

  useEffect(() => {
    setFieldRef(props.name, inputRef);
  }, [setFieldRef, inputRef, props.name]);

  return (
    <StylesWrapper>
      <Form.Control
        ref={inputRef}
        as="select"
        {...rest}
        className="custom-select"
        data-hj-allow
      >
        {props.children}
      </Form.Control>
      <ErrorMessage>{error}</ErrorMessage>
    </StylesWrapper>
  );
};

export default Select;
