import React, {useEffect} from 'react';
import {usePageStore} from '../../contexts/PageContext';
import Breadcrumbs from '../Layout/Breadcrumbs';
import InviteOrgAdminForm from './InviteOrgAdminForm';
import InviteOrgUserForm from './InviteOrgUserForm';
import styles from './InviteForm.module.scss';

const InviteFormType = {
  'org-admin': InviteOrgAdminForm,
  'org-user': InviteOrgUserForm,
};

const InviteForm = (props) => {
  const {panelTitle, formTitle, inviteType, onSave, breadcrumb} = props;
  const InviteFormComponent = InviteFormType[inviteType];
  const [, pageActions] = usePageStore();

  useEffect(() => {
    pageActions.setBreadcrumbs({key: breadcrumb});
  }, [pageActions, breadcrumb]);

  const handleSave = () => {
    onSave();
  };

  return (
    <React.Fragment>
      <div className={styles.header}>
        <Breadcrumbs />
        <h3 className={styles.panelTitle}>{panelTitle}</h3>
      </div>
      <h3 className={styles.formTitle}>{formTitle}</h3>
      <div className={styles.formWrapper}>
        <InviteFormComponent onSave={handleSave} />
      </div>
    </React.Fragment>
  );
};

export default InviteForm;
