import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useFormikContext} from 'formik';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import {mediaQueries} from '../../constants';
import Button from '../../components/Button';

const FormFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;

  ${mediaQueries.lg} {
    justify-content: center;
  }
`;

const FormFooter = () => {
  const stores = useStores();
  const {isSubmitting} = useFormikContext();
  const {t} = useTranslation('create-account');

  return (
    <FormFooterWrapper>
      <Button
        type="submit"
        disabled={isSubmitting}
        variant="outline-primary"
        responsiveFullWidth
      >
        {stores.app.user.organization_id
          ? t('buttons.finish')
          : t('buttons.next')}
      </Button>
    </FormFooterWrapper>
  );
};

export default observer(FormFooter);
