import React from 'react';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {Col} from 'react-bootstrap';
import Form from '../ItemForm/Form';
import Footer from '../ItemForm/Footer';
import {
  InputField,
  RadioField,
  TextAreaField,
  ImageUploadField,
  UnitSelectField,
  CategorySelectField,
  AddressSelectField,
} from '../Fields';
import Button from '../Button';
import {useErrorFocus} from '../../hooks/formik';
import {useStores} from '../../contexts/StoresContext';
import TagSelectField from '../Fields/TagSelectField';

const NeedServiceForm = (props) => {
  const {values, errors, handleSubmit, isSubmitting, item} = props;
  const {t} = useTranslation('forms');
  const focusOnErrorFields = [
    'category',
    'jobType',
    'quantity',
    'meetBy',
    'deliverable',
    'contactName',
  ];
  const fieldRefs = {
    category: null,
    jobType: null,
    quantity: null,
    meetBy: null,
    deliverable: null,
    contactName: null,
  };

  useErrorFocus(isSubmitting, errors, focusOnErrorFields, fieldRefs);

  const setFieldRef = (field) => (ref) => {
    fieldRefs[field] = ref;
  };

  const isDone = item.status === 'done';
  const isInProgress = item.status === 'in_progress';

  const shouldShowField = (isFieldEdited) =>
    !(isDone || isInProgress) || isFieldEdited;

  const setJobTypeOptions = [
    {
      label: t('forms:fields.job-type-on-site'),
      value: 'on_site',
    },
    {
      label: t('forms:fields.job-type-remote'),
      value: 'remote',
    },
    {
      label: t('forms:fields.job-type-either'),
      value: 'either',
    },
  ];

  const setMeetingsRequirementsOptions = [
    {
      label: t('forms:fields.meetings-requirements-requires-discussion'),
      value: 'requires_discussion',
    },
    {
      label: t('forms:fields.meetings-requirements-ready-to-start'),
      value: 'ready_to_start',
    },
  ];

  return (
    <Form onSubmit={handleSubmit}>
      {!item.itemTemplate && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <CategorySelectField
              name="category"
              label={t('labels.category')}
              kind="service"
              setRef={setFieldRef('category')}
              readOnly={isDone || isInProgress}
              isRequired
            />
          </Form.Group>
        </Form.Row>
      )}
      {shouldShowField(!!values.jobType) && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <RadioField
              name="jobType"
              label={t('labels.service.need.job-type')}
              options={setJobTypeOptions}
              readOnly={isDone || isInProgress}
              setRef={setFieldRef('jobType')}
              isRequired
            />
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Group as={Col} sm={6}>
          <UnitSelectField
            label={t('labels.service.need.units')}
            name="baseUom"
            kind="service"
            readOnly={isDone || isInProgress}
            isRequired
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          <InputField
            name="quantity"
            placeholder={t('labels.service.need.quantity-placeholder')}
            label={t('labels.service.need.quantity')}
            readOnly={isDone}
            setRef={setFieldRef('quantity')}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="contactName"
            label={t('labels.service.need.item-contact-name-label')}
            placeholder={t('labels.service.need.item-contact-name-label')}
            setRef={setFieldRef('contactName')}
            readOnly={isDone}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={6}>
          <TagSelectField name="age" label={t('labels.age')} />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          <TagSelectField name="sex" label={t('labels.sex')} />
        </Form.Group>
      </Form.Row>
      {values.category && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <TagSelectField
              name="size"
              label={t('labels.size')}
              category={values.category}
            />
          </Form.Group>
        </Form.Row>
      )}
      {shouldShowField(!!values.meetingsRequirements) && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <RadioField
              name="meetingsRequirements"
              label={t('labels.service.need.meetings-requirements')}
              options={setMeetingsRequirementsOptions}
              readOnly={isDone || isInProgress}
            />
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <TextAreaField
            rows="6"
            name="deliverable"
            label={t('labels.service.need.deliverable')}
            placeholder={t('labels.service.need.item-deliverable-placeholder')}
            readOnly={isDone}
            setRef={setFieldRef('deliverable')}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <AddressSelectField
            name="defaultShippingAddress"
            label={t('labels.service.need.item-default-shipping-address')}
            readOnly={isDone || isInProgress}
            isFullWidth={false}
            notEditAddress
            notDeleteAddress
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <ImageUploadField
            name="image"
            label={t('forms:labels.item-images')}
            readOnly={isDone}
            noRequirementImage
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="phoneNumber"
            label={t('labels.phone-number')}
            readOnly={isDone || isInProgress}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="contactEmail"
            label={t('labels.email')}
            readOnly={isDone || isInProgress}
          />
        </Form.Group>
      </Form.Row>
      {!isDone && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            {item.beneficiaries[0]?.uom === 18 ? (
              <InputField
                label={t('labels.beneficiaries-quantity')}
                name="individualsQuantity"
                placeholder={t('labels.item-quantity-placeholder')}
                inlineLabel={t('common:beneficiaries.family_plural')}
                readOnly
              />
            ) : (
              <InputField
                label={t('labels.beneficiaries-quantity')}
                name="individualsQuantity"
                placeholder={t('labels.item-quantity-placeholder')}
                inlineLabel={t('common:beneficiaries.individual_plural')}
              />
            )}
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <TextAreaField
            rows="6"
            name="description"
            label={t('labels.item-description')}
            placeholder={t('labels.supply.need.item-description-placeholder')}
            readOnly={isDone}
          />
        </Form.Group>
      </Form.Row>
      <Footer>
        {!isDone && (
          <Button type="submit" variant="primary" className="text-uppercase">
            {isInProgress ? t('buttons.need.edit-need') : t('buttons.save')}
          </Button>
        )}
      </Footer>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'NeedServiceForm',
  mapPropsToValues: (props) => {
    const {item, defaultShippingAddress} = props;
    const userFullName = `${props.stores.app.user.first_name || ''} ${
      props.stores.app.user.last_name || ''
    }`;
    const ageTag = item.tag.find((tag) => tag.tag_type === 'age');
    const sexTag = item.tag.find((tag) => tag.tag_type === 'sex');
    const sizeTag = item.tag.find((tag) => tag.tag_type === 'size');
    return {
      baseUom: item.baseUom ? item.baseUom.id.toString() : '11',
      quantity: item.quantity || '',
      jobType: item.jobType || '',
      contactName: item.contactName || userFullName,
      image: item.image ? item.image : null,
      contactEmail: item.contactEmail || '',
      beneficiariesId: item.beneficiaries[0]?.id || null,
      beneficiaries: item.beneficiaries[0]?.uom || 17,
      individualsQuantity: item.beneficiaries[0]?.quantity || null,
      phoneNumber: item.phoneNumber
        ? item.phoneNumber
        : props.stores.app.user.phone_number || '',
      meetingsRequirements: item.meetingsRequirements || '',
      description: item.description || '',
      deliverable: item.deliverable || '',
      status: item.status,
      claimedQuantity: item.claimedQuantity || 0,
      category: item.category ? item.category.id : '',
      tags: item.tags || [],
      age: ageTag ? ageTag.id : null,
      sex: sexTag ? sexTag.id : null,
      size: sizeTag ? sizeTag.id : null,
      defaultShippingAddress: item.location
        ? item.location.id
        : defaultShippingAddress,
    };
  },
  validationSchema: yup.object().shape({
    baseUom: yup.string().required(),
    quantity: yup
      .number()
      .label('quantity')
      .required()
      .positive()
      .nullable()
      .when('status', {
        is: 'in_progress',
        then: yup
          .number()
          .required()
          .label('quantity')
          .min(yup.ref('claimedQuantity')),
      }),
    contactName: yup.string().max(255).required(),
    phoneNumber: yup.string().max(30).nullable(),
    contactEmail: yup.string().email(),
    individualsQuantity: yup
      .number()
      .label('individuals-quantity')
      .positive()
      .nullable(),
    meetBy: yup.date().min(new Date()).nullable(),
    jobType: yup.string().required(),
    meetingsRequirements: yup.string(),
    description: yup.string().nullable(),
    deliverable: yup.string(),
    category: yup.string().required().nullable(),
    image: yup.object().nullable().nullable(),
    age: yup.number().nullable(),
    sex: yup.number().nullable(),
    size: yup.number().nullable(),
    defaultShippingAddress: yup.number().required().integer(),
  }),
  handleSubmit: async (
    values,
    {props, setSubmitting, setFieldError, setErrors},
  ) => {
    try {
      await props.onSubmit(values);
      setSubmitting(false);
    } catch (error) {
      if (error.quantity) {
        const {toast} = props.stores.app;
        toast.toasts.forEach(
          ({id, type}) =>
            type !== 'testing' && props.stores.app.removeToast(id),
        );
        setErrors({
          quantity: error.quantity ? 'Required' : undefined,
        });
      }
      setSubmitting(false);
    }
  },
})(NeedServiceForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  return <EnhancedForm stores={stores} {...props} />;
};

export default observer(FormWrapper);
