import React, {useState, useEffect} from 'react';
import {useLocation, useParams} from 'react-router';
import {useApi} from '../../contexts/ApiContext';
import {Card} from 'react-bootstrap';
import {observer} from 'mobx-react';
import {useStores} from '../../contexts/StoresContext';
import styles from './StaticFilePage.module.scss';

const PAGES = {
  '/privacy': 'privacy',
  '/terms-of-service': 'terms_of_service',
  '/help': 'help',
  '/faq': 'faq',
};

const StaticFilePage = () => {
  const stores = useStores();
  const location = useLocation();
  const {fileName} = useParams();
  const api = useApi();

  const [urlContent, setUrlContent] = useState(null);

  useEffect(() => {
    const fetchGCloudFile = async () => {
      const queryStringParams = new URLSearchParams(location.search);
      const lang = queryStringParams.get('language') || undefined;
      const {
        gcloud_file_url,
        gcloud_bucket_path,
        database,
        gcloud_bucket_name,
      } = await api.getGcloudStaticFiles({
        file: `${fileName || PAGES[location.pathname]}.html`,
        lang,
      });
      const response = await fetch(gcloud_file_url);
      const content = await response.text();
      const parsedContent = content
        .replaceAll('{{ env }}', gcloud_bucket_path)
        .replaceAll('{{ client }}', database)
        .replaceAll('{{ bucket_name }}', gcloud_bucket_name)
        .replaceAll('{{ language }}', stores.app.language);

      setUrlContent(parsedContent);
    };

    fetchGCloudFile();
  }, [location.pathname, fileName, api, stores.app.language, location.search]);

  return (
    <Card className={styles.cardContainer}>
      {urlContent && <div dangerouslySetInnerHTML={{__html: urlContent}} />}
    </Card>
  );
};

export default observer(StaticFilePage);
