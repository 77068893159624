import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {Api} from '../api';
import {useStores} from './StoresContext';
import {FEATURES} from '../constants';
import {isFeatureEnabled} from '../utils/features';
import {useTranslation} from 'react-i18next';

const ApiContext = createContext();

const ApiProvider = ({children}) => {
  const [api, setApi] = useState(null);
  const {i18n} = useTranslation();
  const stores = useStores();

  useEffect(() => {
    const api = new Api(stores);
    setApi(api);
  }, [stores]);
  const fetchLanguages = useCallback(async () => {
    if (api) {
      const response = await api.getLanguages();
      stores.app.setLanguages(response);
    }
  }, [api, stores.app]);

  useEffect(() => {
    stores.app.user && fetchLanguages();
  }, [fetchLanguages, stores.app.user]);

  useEffect(() => {
    const fetchSession = async () => {
      console.info(`[NL] initializing ${stores.app.token ? '(token)' : ''}`);

      const api = new Api(stores);
      const sessionApi = stores.app.token
        ? api.getSession(stores.app.token)
        : api.getPublicSession();
      const session = await sessionApi;

      if (stores.app.token) {
        stores.app.login(session);
      } else {
        stores.app.setSession(session);
      }

      stores.app.init();
      i18n.changeLanguage(stores.app.language);

      if (!isFeatureEnabled(FEATURES.hideTestingBanners, stores.app.features)) {
        stores.app.addToast({
          type: 'testing',
          title: "We're in testing mode!",
          subtitle:
            'Thanks for your patience and excuse any glitches you might encounter,' +
            "we're fixing things as fast as we can.",
        });
      }
    };

    if (!stores.app.loaded) {
      fetchSession();
    }
  }, [stores, i18n]);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('`useApi` must be used within a `ApiProvider`');
  }
  return context;
};

export {ApiProvider, ApiContext, useApi};
