import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import InformationField from '../../MemberPage/InformationField';
import Title from '../../../components/Title';

const FieldWrapper = styled.div`
  padding-left: 10px;
`;

const UserInformation = (props) => {
  const {user} = props;
  const {t} = useTranslation('member-page');

  return (
    <React.Fragment>
      <Title>{t('titles.user-information')}</Title>
      <FieldWrapper>
        <InformationField
          label={t('label.first-name')}
          description={user.first_name}
        />
        <InformationField
          label={t('label.last-name')}
          description={user.last_name}
        />
        <InformationField label={t('label.email')} description={user.email} />
        <InformationField
          label={t('label.role-in-organization')}
          description={user.organization_role}
        />
      </FieldWrapper>
    </React.Fragment>
  );
};

export default UserInformation;
