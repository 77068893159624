import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {Tabs, Tab} from 'react-bootstrap';
import {useApi} from '../../../contexts/ApiContext';
import {usePaginator} from '../../../hooks/paginator';
import {
  itemApiParams,
  summaryApiParams,
} from '../../../components/ItemReports/filterUtils';
import {GET_DEFAULT_COLUMNS} from '../../../components/ItemReports/CustomizeColumns';
import TabsTable from './TabsTable';
import EmptyReport from '../../ReportsPage/EmptyReport';
import Spinner from '../../../components/Spinner';
import {ReactComponent as EmptyActivity} from '../../../assets/icons/reports-activity-empty.svg';
import styles from '../ItemReportsPage.module.scss';
import {useStores} from '../../../contexts/StoresContext';
import {useReportStore} from '../context/ReportContext';

const ItemReportsTable = ({
  filterByMember,
  filters,
  columnFilter,
  handleHeaderClick,
  isAdmin,
  sortBy,
  sortOrder,
}) => {
  const match = useRouteMatch();
  const api = useApi();
  const stores = useStores();
  const {currency} = stores.app;
  const [reportState, reportActions] = useReportStore();
  const {t} = useTranslation('item-reports');
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [claimedItems, setClaimedItems] = useState([]);
  const [claimedItemsCount, setClaimedItemsCount] = useState(0);
  const [quantityTotal, setQuantityTotal] = useState({});
  const {currentPage, changePage, pageSize} = usePaginator();
  const [isLoading, setIsLoading] = useState(true);
  const allowedColumns = useMemo(
    () =>
      columnFilter === null
        ? GET_DEFAULT_COLUMNS(isAdmin)
        : columnFilter.split(','),
    [columnFilter, isAdmin],
  );

  const fetchItem = useCallback(
    async (page) => {
      setIsLoading(true);

      const response = await api.getOrgItemsReport({
        page_size: pageSize,
        org_id: filterByMember,
        page: page || currentPage,
        sort_by: sortBy,
        sort_order: sortOrder,
        ...itemApiParams(filters),
      });
      const fetchSummary = async () => {
        const responseSummary = await api.getOrgSummaryReports({
          ...summaryApiParams(filters),
          currency_id: currency ? currency.id : undefined,
          org_id: filterByMember,
        });
        setQuantityTotal({
          total_quantity: responseSummary.total_quantity_value,
          total_quantity_unit: responseSummary.total_quantity_unit,
          uom_fulfillment: responseSummary.uom_fulfillment,
          total_amount: responseSummary.total_amount,
          total_beneficiaries: responseSummary.total_beneficiaries,
        });
      };

      fetchSummary();
      setItems(response.results);
      setCount(response.count);
      setIsLoading(false);
    },
    [
      api,
      currency,
      currentPage,
      filters,
      filterByMember,
      sortBy,
      sortOrder,
      pageSize,
    ],
  );

  const fetchClaimedItems = useCallback(
    async (page) => {
      setIsLoading(true);
      const response = await api.getOrgClaimedItemsReport({
        page_size: pageSize,
        org_id: filterByMember,
        page: page || currentPage,
        sort_by: sortBy,
        sort_order: sortOrder,
        ...itemApiParams(filters),
      });
      const fetchSummary = async () => {
        const responseSummary = await api.getOrgSummaryReports({
          ...summaryApiParams(filters),
          currency_id: currency ? currency.id : undefined,
          org_id: filterByMember,
        });
        setQuantityTotal({
          total_quantity: responseSummary.total_quantity_value,
          total_quantity_unit: responseSummary.total_quantity_unit,
          uom_fulfillment: responseSummary.uom_fulfillment,
          total_amount: responseSummary.total_amount,
          total_beneficiaries: responseSummary.total_beneficiaries,
        });
      };
      fetchSummary();
      setClaimedItems(response.results);
      setClaimedItemsCount(response.count);
      setIsLoading(false);
    },
    [
      api,
      currency,
      currentPage,
      filterByMember,
      filters,
      pageSize,
      sortBy,
      sortOrder,
    ],
  );

  const handleSelectTab = useCallback(
    (key) => {
      reportActions.setActiveTab(key);
      if (key === 'posts') {
        changePage(match.url, 1);
        fetchItem(1);
      }
      if (key === 'transactions') {
        changePage(match.url, 1);
        fetchClaimedItems(1);
      }
    },
    [fetchItem, fetchClaimedItems, changePage, reportActions, match.url],
  );

  useEffect(() => {
    if (reportState.activeTab === 'posts') fetchItem();
    if (reportState.activeTab === 'transactions') fetchClaimedItems();
  }, [reportState.activeTab, fetchItem, fetchClaimedItems]);

  if (!items.length) {
    return (
      <div className={styles.emptyTableContainer}>
        <EmptyReport icon={EmptyActivity} message={t('empty-report')} />
      </div>
    );
  }

  const tabs = [
    {
      title: t('tabs.headers.posts'),
      eventKey: 'posts',
      data: items,
      count: count,
    },
    {
      title: t('tabs.headers.transactions'),
      eventKey: 'transactions',
      data: claimedItems,
      count: claimedItemsCount,
    },
  ];

  return (
    <Tabs className={styles.tableTabs} onSelect={handleSelectTab}>
      {tabs.map((tab) => (
        <Tab key={tab.eventKey} eventKey={tab.eventKey} title={tab.title}>
          {isLoading ? (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          ) : (
            <TabsTable
              data={tab.data}
              count={tab.count}
              pageSize={pageSize}
              onHeaderClick={handleHeaderClick}
              sortBy={sortBy}
              sortOrder={sortOrder}
              quantityTotal={quantityTotal}
              allowedColumns={allowedColumns}
              isAdmin={isAdmin}
              currency={currency}
            />
          )}
        </Tab>
      ))}
    </Tabs>
  );
};

export default observer(ItemReportsTable);
