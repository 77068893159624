import React from 'react';
import {useTranslation} from 'react-i18next';
import {useApi} from '../../contexts/ApiContext';
import {MANAGE_ITEMS_PAGE} from '../../urls';
import TemplateEdit from '../../components/TemplateEdit/TemplateEdit';

const ItemTemplateEdit = () => {
  const api = useApi();
  const {t} = useTranslation('forms');

  const getItemById = (id) => api.getItemTemplateCatalogById(id);

  const updateTranslationStatus = (id, lang, method, values) =>
    api.updateItemTemplateTranslation(id, lang, method, values);

  return (
    <TemplateEdit
      title={t('titles.edit-item-catalog')}
      kind="item"
      breadcrumbsKey={'edit-item-catalog'}
      getItemById={getItemById}
      updateTranslationStatus={updateTranslationStatus}
      previousPage={MANAGE_ITEMS_PAGE}
    />
  );
};

export default ItemTemplateEdit;
