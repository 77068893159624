import React, {useRef, useState, forwardRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import {useSearchIndexApi} from '../../../../../hooks/search-index';
import {useStores} from '../../../../../contexts/StoresContext';
import {handleSelectFirstOption} from '../../../../../utils/selectFirstOption';
import AsyncComponent from '../../../../../components/AsyncComponent';
import {FALLBACK_LANGUAGE} from '../../../../../constants';
const MIN_LENGTH = 3;

const SearchIndexField = forwardRef((props, ref) => {
  const {t} = useTranslation('search');
  const {name, value, setFieldValue, isValid, isInvalid, disabled} = props;

  const searchIndexApi = useSearchIndexApi();

  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [openOptions, setOpenOptions] = useState(false);

  const stores = useStores();
  const userLanguage = stores.app.language;

  const typeahead = useRef();

  const onChange = useCallback(
    (selected) => {
      setOpenOptions(false);
      setResults(selected);
      setFieldValue(selected.length > 0 ? selected[0].label : '');
      if (selected.length > 0) {
        searchIndexApi.registerClick(selected[0].objectId, selected[0].id);
      }
    },
    [setFieldValue, searchIndexApi],
  );

  const handleSearch = (text) => {
    if (text.length === 0) {
      return;
    }

    setIsLoading(true);
    setOpenOptions(true);
    searchIndexApi
      .search(text, {
        restrictSearchableAttributes: [`title.${userLanguage}`],
        models: [
          'needslist_item.Category',
          'needslist_item.Sector',
          'needslist_item.Item',
        ],
        exactMatch: false,
        distict: false,
      })
      .then((results) => {
        const titles = results.reduce((accum, hit, index) => {
          const label = (
            hit.title[userLanguage] || hit.title[FALLBACK_LANGUAGE]
          ).trim();

          const exists = accum.some(
            (x) => x.label.toLowerCase() === label.toLowerCase(),
          );

          if (!exists) {
            return [
              ...accum,
              {
                id: index + 1,
                label,
                ...hit.title,
                objectId: hit.objectID,
              },
            ];
          }

          return [...accum];
        }, []);

        setResults(titles);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <AsyncTypeahead
        placeholder={t('search-box.text-filter-placeholder')}
        ref={typeahead}
        disabled={disabled}
        isValid={isValid}
        isInvalid={isInvalid}
        isLoading={isLoading}
        onKeyDown={(e) => {
          handleSelectFirstOption(e, results, activeIndex, onChange);
        }}
        onBlur={(e) => {
          const inputValue = typeahead.current.getInput().value;
          if (results.length === 0 && inputValue.length) {
            setFieldValue(inputValue);
          } else {
            handleSelectFirstOption(e, results, activeIndex, onChange);
          }
        }}
        onSearch={handleSearch}
        minLength={MIN_LENGTH}
        allowNew={false}
        id={`search-index-field-${name}`}
        onChange={onChange}
        useCache={false}
        selected={value ? [value] : []}
        options={results}
        open={openOptions}
        filterBy={() =>
          stores.app.languages.map((lang) => lang.code.toString())
        }>
        {({activeIndex}) => (
          <AsyncComponent
            update={() => {
              setActiveIndex(activeIndex);
            }}
          />
        )}
      </AsyncTypeahead>
    </React.Fragment>
  );
});

export default SearchIndexField;
