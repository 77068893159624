import React from 'react';
import HSBar from 'react-horizontal-stacked-bar-chart';
import ChartLegend from './ChartLegend';
import styles from './Visualizations.module.scss';
import theme from '../../../theme';
import {useTranslation} from 'react-i18next';

const ItemsByFulfillmentCard = (props) => {
  const {needItemsByFulfillment, offerItemsByFulfillment, isEmpty} = props;
  const {t} = useTranslation('item-reports');

  const getItemsByFulfillmentCardData = () => {
    if (isEmpty) {
      return [{name: 'emptyData', value: 10, color: theme.hitGray}];
    }

    let data = [];
    if (
      needItemsByFulfillment['delivered'] &&
      needItemsByFulfillment['delivered']['itemCount'] !== 0
    ) {
      data.push({
        value: needItemsByFulfillment['delivered']['itemCount'],
        color: needItemsByFulfillment['delivered']['color'],
      });
    }
    if (
      needItemsByFulfillment['posted'] &&
      needItemsByFulfillment['posted']['itemCount'] !== 0
    ) {
      data.push({
        value: needItemsByFulfillment['posted']['itemCount'],
        color: needItemsByFulfillment['posted']['color'],
      });
    }
    if (
      needItemsByFulfillment['met'] &&
      needItemsByFulfillment['met']['itemCount'] !== 0
    ) {
      data.push({
        value: needItemsByFulfillment['met']['itemCount'],
        color: needItemsByFulfillment['met']['color'],
      });
    }
    if (
      offerItemsByFulfillment['delivered'] &&
      offerItemsByFulfillment['delivered']['itemCount'] !== 0
    ) {
      data.push({
        value: offerItemsByFulfillment['delivered']['itemCount'],
        color: offerItemsByFulfillment['delivered']['color'],
      });
    }
    if (
      offerItemsByFulfillment['met'] &&
      offerItemsByFulfillment['met']['itemCount'] !== 0
    ) {
      data.push({
        value: offerItemsByFulfillment['met']['itemCount'],
        color: offerItemsByFulfillment['met']['color'],
      });
    }
    if (
      offerItemsByFulfillment['posted'] &&
      offerItemsByFulfillment['posted']['itemCount'] !== 0
    ) {
      data.push({
        value: offerItemsByFulfillment['posted']['itemCount'],
        color: offerItemsByFulfillment['posted']['color'],
      });
    }
    return data;
  };

  return (
    <div className={styles.visualizationCardOuterContainer}>
      <div className={styles.visualizationCardInnerContainer}>
        <div
          style={{color: isEmpty ? theme.hitGray : theme.astronautBlue}}
          className={styles.visualizationCardHeader}
        >
          {t('visualizations.header.needs-and-offers')}
        </div>
        <div className={styles.barChartContainer}>
          {(needItemsByFulfillment || offerItemsByFulfillment) && (
            <HSBar
              id={styles.barChart}
              height={25}
              data={getItemsByFulfillmentCardData()}
            />
          )}
        </div>
        <ChartLegend
          headerTitle={t('visualizations.header.needs')}
          headerColor={theme.astronautBlue}
          chartData={needItemsByFulfillment}
          isEmpty={isEmpty}
        />
        <div className={styles.chartLegendDivider}></div>
        <ChartLegend
          headerTitle={t('visualizations.header.offers')}
          headerColor={theme.crusta}
          chartData={offerItemsByFulfillment}
          isEmpty={isEmpty}
        />
      </div>
    </div>
  );
};

export default ItemsByFulfillmentCard;
