import styled from 'styled-components';
import theme from '../../theme';

const MenuItem = styled.div`
  padding: 0 13px;
  font-size: 12px;
  line-height: 20px;

  &:hover {
    background-color: ${theme.primaryColor};
    color: white;
  }
`;

export default MenuItem;
