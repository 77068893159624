import styled from 'styled-components';
import {mediaQueries} from '../../constants';

const SectionTitle = styled.h2`
  font-weight: 600;
  font-size: 22px;
  margin: 5px 0;

  ${mediaQueries.lg} {
    margin: 15px 0;
    font-weight: 500;
    font-size: 18px;
  }
`;

export default SectionTitle;
