import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {useHistory} from 'react-router-dom';
import {Form, Col} from 'react-bootstrap';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';

import {createTranslationKey} from '../../utils/i18n';
import Button from '../../components/Button';
import {NavLink} from 'react-router-dom';
import {InputField, CheckboxField} from '../../components/Fields';
import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';
import {passwordValidationScheme} from '../../utils/yupCustomFunctions';
import {ONBOARDING_USER_DETAILS, TOS_PAGE, PRIVACY_PAGE} from '../../urls';
import {getUrlWithLang} from '../../utils';
import Title from '../../components/Title/Title';
import styles from './Login.module.scss';
import {isFeatureEnabled} from '../../utils/features';
import {FEATURES} from '../../constants';

const RegisterForm = (props) => {
  const {setFieldValue, handleSubmit, invitation} = props;
  const {t} = useTranslation('new-user');
  const stores = useStores();
  const [tosPage, setTosPage] = useState('');
  const [policityPage, setPolicityPage] = useState('');

  useEffect(() => {
    setTosPage(getUrlWithLang(TOS_PAGE, stores.app.language));
    setPolicityPage(getUrlWithLang(PRIVACY_PAGE, stores.app.language));
  }, [stores.app, setTosPage, setPolicityPage]);

  const handleTACChange = (event) => {
    setFieldValue('termsAndConditions', event.target.checked);
  };

  return (
    <Form className={styles.mainForm} noValidate onSubmit={handleSubmit}>
      <Title className={styles.formTitle}>{t('title')}</Title>
      <span className={styles.formSubTitle}>{t('new-user-subtitle')}</span>
      <div className={styles.fieldsContainer}>
        <Form.Group as={Col} lg={12}>
          <InputField
            type="text"
            placeholder={t('placeholder.member-name')}
            name="memberName"
            isRequired
            readOnly={!!invitation}
          />
        </Form.Group>
        <Form.Group as={Col} lg={12}>
          <InputField
            type="text"
            placeholder={t('placeholder.email')}
            name="email"
            isRequired
            readOnly={!!invitation}
            withoutTextStyle
          />
        </Form.Group>
        <Form.Group as={Col} lg={12}>
          <InputField
            type="password"
            placeholder={t('placeholder.password')}
            name="password"
            isRequired
          />
        </Form.Group>
        <Form.Group as={Col} lg={12}>
          <InputField
            type="password"
            placeholder={t('placeholder.confirmPassword')}
            name="confirmPassword"
            isRequired
          />
        </Form.Group>
        <CheckboxField
          label={
            <React.Fragment>
              <NavLink to={tosPage} target="_blank">
                {t('label.tos')}
              </NavLink>
              &nbsp;{t('label.and')}&nbsp;
              <NavLink to={policityPage} target="_blank">
                {t('label.privacy')}
              </NavLink>
            </React.Fragment>
          }
          onChange={handleTACChange}
          name="termsAndConditions"
          isRequired
        />
      </div>
      <div className={styles.footer}>
        <Button type="submit" disabled={props.isSubmitting} responsiveFullWidth>
          {t('button')}
        </Button>
      </div>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'RegisterForm',
  mapPropsToValues: (props) => {
    const {invitation} = props;
    const {state} = props.history.location;

    const initialValues = {
      memberName: invitation ? invitation.organization_name : '',
      email: invitation ? invitation.email : '',
      password: '',
      confirmPassword: '',
      termsAndConditions: false,
    };

    if (state && state.newRequest) {
      initialValues.memberName = state.newRequest.organization_name;
      initialValues.email = state.newRequest.email;
    }

    return initialValues;
  },
  validationSchema: yup
    .object()
    .shape({
      memberName: yup.string().required(),
      email: yup.string().email().required(),
      termsAndConditions: yup
        .bool()
        .oneOf([true], createTranslationKey('errors.terms-and-conditions')),
    })
    .concat(passwordValidationScheme),
  handleSubmit: async (values, {props, setSubmitting, setErrors}) => {
    const {invitation, invitationToken, setEmailVerified} = props;
    const {state} = props.history.location;

    let newUser = {
      email: values.email,
      password: values.password,
      repeated_password: values.confirmPassword,
      registered_organization_name: values.memberName,
    };

    if (invitationToken) {
      newUser = {
        ...newUser,
        invitation_token: invitationToken,
      };
    }

    if (invitation) {
      newUser = {
        ...newUser,
        token: invitation.token,
        first_name: invitation.first_name,
        last_name: invitation.last_name,
      };
    }

    if (state && state.newRequest) {
      newUser = {
        ...newUser,
        first_name: state.newRequest.first_name,
        last_name: state.newRequest.last_name,
      };
    }

    try {
      const user = await props.api.createNewUser(newUser);
      if (isFeatureEnabled(FEATURES.verifyEmail, props.stores.app.features)) {
        setEmailVerified(values.email);
      } else {
        props.stores.app.login({...user, invitationToken: !!invitation});
      }
      setSubmitting(false);

      if (props.stores.app.user) {
        props.history.push(ONBOARDING_USER_DETAILS);
      } else {
        setErrors({
          email: 'Login Error',
        });
      }
    } catch (error) {
      if (
        error.email ||
        error.password ||
        error.repeated_password ||
        error.error
      ) {
        setErrors({
          email: error.email
            ? error.email[0]
            : error.error
            ? error.error
            : undefined,
          password: error.password ? error.password[0] : undefined,
          repeated_password: error.repeated_password
            ? error.repeated_password[0]
            : undefined,
        });
      }
      setSubmitting(false);
    }
  },
})(RegisterForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  const history = useHistory();
  const api = useApi();
  return (
    <EnhancedForm api={api} history={history} stores={stores} {...props} />
  );
};

export default observer(FormWrapper);
