import React, {useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useFormikContext} from 'formik';
import {Form, Col, Row} from 'react-bootstrap';

import {SelectField} from '../../../components/Fields';
import {useApi} from '../../../contexts/ApiContext';

const OrgKindField = () => {
  const api = useApi();
  const [countries, setCountries] = useState([]);
  const {t} = useTranslation(['create-account', 'common']);
  const {values} = useFormikContext();

  const fetchCountries = useCallback(async () => {
    const response = await api.getCountries();

    const fetchedcountries = Object.keys(response).map((country) => ({
      id: country,
      name: response[country],
    }));

    setCountries(fetchedcountries);
  }, [api]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const mappedCountries = (country) => (
    <option key={country.id} value={country.id}>
      {country.name}
    </option>
  );

  return (
    <Row>
      <Form.Group as={Col} lg={6}>
        <SelectField
          name="orgKind"
          label={t('create-account:labels.org-type')}
          isRequired
        >
          <option value="">{t('create-account:select')}</option>
          <option value="nporg">{t('common:organization.nporg')}</option>
          <option value="fporg">{t('common:organization.fporg')}</option>
          <option value="govorg">{t('common:organization.govorg')}</option>
        </SelectField>
      </Form.Group>
      {values.orgKind === 'nporg' && (
        <Form.Group as={Col} lg={6}>
          <SelectField
            name="registeredCountry"
            label={t('create-account:labels.registered-country')}
            isRequired
          >
            <option value="">{t('create-account:select')}</option>
            {countries.map(mappedCountries)}
          </SelectField>
        </Form.Group>
      )}
      {values.orgKind === 'fporg' && (
        <Form.Group as={Col} lg={6}>
          <SelectField
            name="incorporatedCountry"
            label={t('create-account:labels.incorporated-country')}
            isRequired
          >
            <option value="">{t('create-account:select')}</option>
            {countries.map(mappedCountries)}
          </SelectField>
        </Form.Group>
      )}
    </Row>
  );
};

export default OrgKindField;
