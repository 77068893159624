import React, {useState} from 'react';
import styles from './SavedView.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useApi} from '../../../contexts/ApiContext';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const SavedViewDialog = (props) => {
  const {onClose, setSavedViewToastText} = props;
  const [errorText, setErrorText] = useState('');
  const api = useApi();
  const history = useHistory();
  const {t} = useTranslation('item-reports');

  const addSavedView = async () => {
    const viewName = document.getElementById('savedViewName').value;

    if (viewName === '') {
      setErrorText(t('saved_view.toasts.empty_name'));
      return;
    }
    const viewQueryString = history.location.search;
    try {
      const response = await api.createSavedView({viewName, viewQueryString});

      setErrorText('');
      setSavedViewToastText(t('saved_view.toasts.success'));
      window.localStorage.setItem('viewQueryString', viewQueryString);
      window.localStorage.setItem('viewId', response.viewId);
      // Hide the dialog after 1 seconds.
      setTimeout(() => onClose(), 1000);

      // Hide the toast after 3 seconds.
      setTimeout(() => setSavedViewToastText(''), 3000);
    } catch (error) {
      if (error.status === 'ERROR - Name already in use') {
        setErrorText(t('saved_view.toasts.unique_name'));
      } else if (error.status === 'ERROR - User already has 5+ saved views.') {
        setErrorText(t('saved_view.toasts.limit_exceeded'));
      } else {
        setErrorText(t('saved_view.toasts.generic_error'));
      }
    }
  };

  //TODO: Replace font awesome icon.
  return (
    <dialog className={styles.saveNewViewDialog}>
      <div className={styles.saveNewViewDialogContainer}>
        <div className={styles.dialogTextContainer}>
          <div className={styles.dialogHeaderContainer}>
            <div className={styles.dialogHeader}>
              {t('saved_view.dialog.title')}
            </div>
          </div>
          <input
            className={
              errorText === '' ? styles.dialogInput : styles.invalidDialogInput
            }
            placeholder={t('saved_view.dialog.placeholder')}
            id="savedViewName"
            autoComplete="off"
          />
          {errorText && (
            <div className={styles.dialogErrorMessage}>
              <FontAwesomeIcon
                className={styles.errorIcon}
                icon="exclamation-triangle"
              />
              <div className={styles.dialogErrorText}>{errorText}</div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.dialogButtonContainer}>
        <div className={styles.dialogActionButton} onClick={addSavedView}>
          {t('saved_view.dialog.save')}
        </div>
        <div className={styles.dialogCloseButton} onClick={onClose}>
          <div className={styles.dialogCloseButtonText}>
            {t('saved_view.dialog.cancel')}
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default SavedViewDialog;
