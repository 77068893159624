import React, {useEffect, useState, useCallback} from 'react';
import {Col} from 'react-bootstrap';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {InputField, SelectField} from '../Fields';
import Button from '../Button';
import {useApi} from '../../contexts/ApiContext';
import styles from './CustomAddressForm.module.scss';

const CustomAddressForm = (props) => {
  const {handleChange, values, handleSubmit, isSubmitting, setFieldValue} =
    props;
  const [countries, setCountries] = useState([]);
  const {t} = useTranslation('custom-address');

  const fetchCountries = useCallback(async () => {
    const response = await props.api.getCountries();
    const fetchedcountries = Object.keys(response).map((country) => ({
      id: country,
      name: response[country],
    }));

    setCountries(fetchedcountries);
  }, [props.api]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const handleSelectCountry = (event) => {
    const {value} = event.target;
    setFieldValue('countryShort', value);
    setFieldValue('country', countries.find((c) => c.id === value).name);
  };

  const mappedCountries = (country) => {
    return (
      <option key={country.id} value={country.id}>
        {country.name}
      </option>
    );
  };

  return (
    <div className={styles.wrapper}>
      <Col>
        <InputField
          onChange={handleChange}
          value={values.streetNumber}
          type="text"
          name="streetNumber"
          label={t('labels.streetNumber')}
          placeholder={t('placeholders.streetNumber')}
          isRequired
        />
      </Col>
      <Col>
        <InputField
          onChange={handleChange}
          value={values.streetName}
          type="text"
          name="streetName"
          label={t('labels.streetName')}
          placeholder={t('placeholders.streetName')}
          isRequired
        />
      </Col>
      <Col>
        <InputField
          onChange={handleChange}
          value={values.city}
          type="text"
          name="city"
          label={t('labels.city')}
          placeholder={t('placeholders.city')}
          isRequired
        />
      </Col>
      <Col>
        <InputField
          onChange={handleChange}
          value={values.state}
          type="text"
          name="state"
          label={t('labels.state')}
          placeholder={t('placeholders.state')}
          isRequired
        />
      </Col>
      <Col>
        <InputField
          onChange={handleChange}
          value={values.zipCode}
          type="text"
          name="zipCode"
          label={t('labels.zipCode')}
          placeholder={t('placeholders.zipCode')}
          isRequired
        />
      </Col>
      <Col>
        <SelectField
          name="countryShort"
          label={t('labels.country')}
          isRequired
          onChange={handleSelectCountry}>
          <option value="">{t('select')}</option>
          {countries.map(mappedCountries)}
        </SelectField>
      </Col>
      <div className={styles.formFooter}>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={handleSubmit}
          disabled={isSubmitting}>
          {t('buttons.finish')}
        </Button>
      </div>
    </div>
  );
};

const EnhancedForm = withFormik({
  mapPropsToValues: () => ({
    streetNumber: '',
    streetName: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    countryShort: '',
  }),
  validationSchema: yup.object().shape({
    streetNumber: yup.string().required(),
    streetName: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    zipCode: yup.string().required(),
    country: yup.string().required(),
  }),
  handleSubmit: (values, {props, setSubmitting}) => {
    setSubmitting(false);
    props.onAddAddress(values);
  },
})(CustomAddressForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const api = useApi();
  return <EnhancedForm api={api} {...props} />;
};

export default observer(FormWrapper);
