import {
  CategoryFilterImpl,
  DateFilterImpl,
  ItemFilterImpl,
  IssuesFilterImpl,
  KeywordFilterImpl,
  PostedByFilterImpl,
  MetClaimedByFilterImpl,
  StatusFilterImpl,
  PlatformInteractionFilterImpl,
  PostTypeFilterImpl,
  UomFilterImpl,
} from '../../components/ItemReports/filterUtils';

export const FILTERS = Object.fromEntries(
  [
    DateFilterImpl,
    KeywordFilterImpl,
    PostedByFilterImpl,
    MetClaimedByFilterImpl,
    StatusFilterImpl,
    CategoryFilterImpl,
    PlatformInteractionFilterImpl,
    IssuesFilterImpl,
    PostTypeFilterImpl,
    ItemFilterImpl,
    UomFilterImpl,
  ].map((filterImpl) => [filterImpl.type, filterImpl]),
);

const FilterControl = (props) => {
  const {value, type, cancelClick, onRemoveUrl, lookupData} = props;
  const filterData = FILTERS[type];
  if (filterData) {
    const FilterComponent = filterData.component;
    return (
      <FilterComponent
        value={value}
        cancelClick={cancelClick}
        onRemoveUrl={onRemoveUrl}
        queryParam={type}
        lookupData={lookupData}
        i18n_key={filterData.i18n_key}
        optgroup_field={filterData.optgroup_field}
      />
    );
  }
  return <></>;
};

export default FilterControl;
