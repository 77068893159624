import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ShareModal, ShareButton, ShareLink} from '../../components/Share';
import Button from '../../components/Button';
import {useApi} from '../../contexts/ApiContext';
import LanguageSelect from '../../components/Form/LanguageSelect';
import {useStores} from '../../contexts/StoresContext';
import Label from '../../components/Form/Label';
import styles from './InviteOrganizations.module.scss';

const InviteOrganizations = () => {
  const api = useApi();
  const {t} = useTranslation(['common', 'create-account']);
  const [showModal, setShowModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const handleToggleModal = () => setShowModal(!showModal);
  const [invitationURL, setInvitationURL] = useState('');
  const [languages, setLanguages] = useState([]);
  const [generatedUrl, setGeneratedUrl] = useState(false);
  const [invitationLink, setInvitationLink] = useState('');
  const stores = useStores();
  const [languageSelected, setLanguageSelected] = useState();

  useEffect(() => {
    const fetchLink = async () => {
      const response = await api.getClientInvitationLink();
      setInvitationLink(response.invitation_link);
    };

    fetchLink();
  }, [api]);

  const fetchLanguages = useCallback(async () => {
    const response = await api.getLanguages();
    setLanguages(response);
  }, [api]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  const onChangeLanguage = (lang) => {
    setLanguageSelected(lang);
  };

  useEffect(() => {
    setLanguageSelected(stores.app.user.language);
  }, [stores.app.user.language]);

  useEffect(() => {
    setInvitationURL(invitationLink + '&language=' + languageSelected?.code);
    setGeneratedUrl(true);
  }, [languageSelected, invitationLink]);

  useEffect(() => {
    const id = setTimeout(() => setGeneratedUrl(false), 1000);
    return () => clearTimeout(id);
  }, [generatedUrl]);

  const handleGenerateClick = async () => {
    const response = await api.createClientInvitationLink();
    setInvitationLink(response.invitation_link);
    setShowConfirm(false);
  };

  return (
    <React.Fragment>
      <ShareButton
        className={styles.shareButton}
        onClick={handleToggleModal}
        buttonLabel={t('share.invite-organizations')}
      />
      {showModal && (
        <ShareModal
          onClose={handleToggleModal}
          title={t('share.modal.add-new-members')}
          subtitle={t('share.modal.want-new-members')}
          description={t('share.modal.send-invite')}
        >
          <Label>{t('create-account:labels.language')}</Label>
          <LanguageSelect
            name="Language"
            languages={languages}
            value={languageSelected.id}
            onChange={onChangeLanguage}
          />
          <ShareLink link={invitationURL} />
          <div className={styles.copiedMsgWrapper}>
            {generatedUrl && (
              <span className={styles.copiedMsg}>
                {t('share.modal.generated-url')}
              </span>
            )}
          </div>
          <div className={styles.newLinkContainer}>
            {!showConfirm ? (
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => setShowConfirm(true)}
              >
                {t('share.modal.generate-link').toUpperCase()}
              </Button>
            ) : (
              <React.Fragment>
                <p className={styles.warning}>{t('share.modal.warning')}</p>
                <div className={styles.confirmButtonsContainer}>
                  <Button
                    className={styles.confirmButton}
                    variant="outline-primary"
                    size="sm"
                    onClick={() => setShowConfirm(false)}
                  >
                    {t('share.modal.cancel').toUpperCase()}
                  </Button>
                  <Button
                    className={styles.confirmButton}
                    variant="outline-primary"
                    size="sm"
                    onClick={handleGenerateClick}
                  >
                    {t('share.modal.yes-generate-link').toUpperCase()}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </div>
        </ShareModal>
      )}
    </React.Fragment>
  );
};

export default InviteOrganizations;
