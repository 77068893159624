import React from 'react';
import {Col, Row} from 'react-bootstrap';
import styled from 'styled-components';

const Square = styled.div`
  align-self: center;
  margin-left: auto;
  margin-right: 0;
  width: 28px;
  height: 28px;
  background-color: ${(props) => props.color || '#000'};
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const Name = styled.span`
  color: #979797;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TotalNumber = styled.span`
  font-weight: 500;
  font-size: 36px;
  margin-right: 12px;
  line-height: 44px;
`;

const Percentage = styled.span`
  font-weight: 500;
  font-size: 24px;
`;

const LegendLine = (props) => {
  const {
    data: {name, value, percentage},
    color,
  } = props;

  return (
    <div className={props.className}>
      <Row>
        <StyledCol xs={{span: 9, offset: 3}} sm={{span: 10, offset: 2}}>
          <Name>{name}</Name>
        </StyledCol>
      </Row>
      <Row>
        <StyledCol xs={{span: 2, offset: 1}} sm={{span: 2, offset: 0}}>
          <Square color={color} />
        </StyledCol>
        <StyledCol xs={9} sm={10}>
          <TotalNumber>{value}</TotalNumber>
          <Percentage>{percentage}%</Percentage>
        </StyledCol>
      </Row>
    </div>
  );
};

export default LegendLine;
