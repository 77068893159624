import React from 'react';
import {observer} from 'mobx-react';
import {Route, Redirect} from 'react-router-dom';
import {useStores} from '../../contexts/StoresContext';
import {USER_STATUS_CHOICES} from '../../constants';
import {LOGIN_PAGE, ONBOARDING_USER_DETAILS} from '../../urls';

const PrivateRoute = (props) => {
  const stores = useStores();
  const {component: Component, render, children, isOnboarding, ...rest} = props;

  if (
    !stores.app.user ||
    stores.app.user.status === USER_STATUS_CHOICES.approvalPending
  ) {
    stores.app.logout();
    stores.app.setRefreshing(true);
    window.location.replace(LOGIN_PAGE);
  }

  if (
    stores.app.user &&
    stores.app.user.status === USER_STATUS_CHOICES.processing &&
    !isOnboarding
  ) {
    return <Redirect to={ONBOARDING_USER_DETAILS} />;
  }

  return (
    <Route {...rest} component={Component} render={render}>
      {children}
    </Route>
  );
};

export default observer(PrivateRoute);
