import React, {useEffect} from 'react';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {Form} from 'react-bootstrap';
import {useHistory, useLocation} from 'react-router-dom';
import {parsePath} from '../../utils';
import {useApi} from '../../contexts/ApiContext';
import IssueSelect from './IssueSelect';
import TemplateSelect from './TemplateSelect';
import Step from './Step';
import Button from '../../components/Button';
import AddressSelectField from '../../components/Fields/AddressSelectField';
import {usePageStore} from '../../contexts/PageContext';
import styles from './AddNeedsForm.module.scss';
import {createTranslationKey} from '../../utils/i18n';

const AddNeedsForm = (props) => {
  const {t} = useTranslation('add-items');
  const [, pageActions] = usePageStore();

  const queryStringParams = new URLSearchParams(props.location.search);
  const selectedIssueSlug = queryStringParams.get('selected_issue');
  const {values, setFieldValue, handleSubmit, isSubmitting} = props;

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'add-needs'});
  }, [pageActions]);

  useEffect(() => {
    if (selectedIssueSlug) {
      const fetchSelectedIssue = async () => {
        const selectedIssue = await props.api.getIssue(selectedIssueSlug);
        setFieldValue('issue', selectedIssue);
      };

      fetchSelectedIssue();
    }
  }, [selectedIssueSlug, setFieldValue, props.api]);

  return (
    <Form className={styles.form} onSubmit={handleSubmit} noValidate>
      <IssueSelect name="issue" />

      <Step
        title={t('add-needs.step-2.title')}
        subtitle={t('add-needs.step-2.subtitle')}
        className="pb-4">
        <AddressSelectField notDeleteAddress notEditAddress name="location" />
      </Step>

      <Step title={t('add-needs.step-3.title')}>
        <TemplateSelect
          name="needs"
          selectedTemplates={values.needs}
          placeholder={t('add-needs.step-3.placeholder')}
          kinds={['supply', 'service']}
          side="need"
        />
      </Step>
      <div className={styles.footer}>
        <Button
          type="submit"
          variant="outline-primary"
          disabled={isSubmitting}
          className="text-uppercase">
          {isSubmitting && (
            <span className="spinner-border spinner-border-sm mr-1"></span>
          )}
          {t('add-needs.next')}
        </Button>
      </div>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'AddNeedsForm',
  mapPropsToValues: (props) => {
    return {
      issue: '',
      location: '',
      needs: [],
    };
  },
  validationSchema: yup.object().shape({
    issue: yup
      .object()
      .required(createTranslationKey('errors.add-needs.issue')),
    location: yup
      .string()
      .required(createTranslationKey('errors.add-needs.location')),
    needs: yup.array().min(1, createTranslationKey('errors.add-needs.needs')),
  }),
  handleSubmit: async (values, {props}) => {
    const items = values.needs.map((template) => ({
      name: template.value,
      item_template_id: template.id,
      side: 'need',
      kind: template.kind,
    }));

    const issueResponse = {
      issue: values.issue.id,
      name: values.issue.name,
      delivery_address: values.location,
      items: items,
    };

    const response = await props.api.createIssueResponse(issueResponse);
    const path = parsePath('/issue-response/:issueResponseId/:side/', {
      issueResponseId: response.id,
      side: 'needs',
    });
    return props.history.replace(path);
  },
})(AddNeedsForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const api = useApi();
  const history = useHistory();
  const location = useLocation();
  return (
    <EnhancedForm location={location} history={history} api={api} {...props} />
  );
};

export default observer(FormWrapper);
