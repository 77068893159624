import React from 'react';
import styled, {css} from 'styled-components';

const Toggle = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #9f9f9f;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 4px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 9px;
  }

  span:nth-child(4) {
    top: 14px;
  }

  ${(props) =>
    props.expanded &&
    css`
      span:nth-child(1) {
        top: 9px;
        width: 0%;
        left: 50%;
      }

      span:nth-child(2) {
        transform: rotate(45deg);
      }

      span:nth-child(3) {
        transform: rotate(-45deg);
      }

      span:nth-child(4) {
        top: 9px;
        width: 0%;
        left: 50%;
      }
    `}
`;

const HamburguerToggle = (props) => (
  <Toggle expanded={props.expanded}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </Toggle>
);

export default HamburguerToggle;
