function getNestedField(field, errors) {
  const keys = field.split('.');
  return keys.reduce((x, k) => (x && x[k] ? x[k] : null), errors);
}

function removeEmptyKeys(o) {
  return Object.keys(o).reduce((cleaned, k) => {
    if (o[k]) {
      cleaned[k] = o[k];
    }

    return cleaned;
  }, {});
}

function isEqual(a, b) {
  // Cheap deep equal comparision
  return JSON.stringify(a) === JSON.stringify(b);
}

export {getNestedField, removeEmptyKeys, isEqual};
