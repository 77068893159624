import styled, {css} from 'styled-components';
import {mediaQueries, Z_INDEX} from '../../constants';

const Subtitle = styled.h2`
  z-index: ${Z_INDEX.overlay};
  position: relative;

  font-size: 20px;
  font-weight: 600;
  font-family: 'Roboto Slab', serif;

  ${(props) =>
    !props.withoutMargins &&
    css`
      margin-top: 40px;
      margin-bottom: 15px;
    `}

  ${mediaQueries.lg} {
    margin-top: 15px;
    font-size: 18px;
  }
`;

export default Subtitle;
