import React, {useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import queryString from 'query-string';
import {Redirect, useLocation} from 'react-router-dom';
import Title from '../../components/Title';
import TemplateList from '../../components/TemplateList';
import {useApi} from '../../contexts/ApiContext';
import {usePageStore} from '../../contexts/PageContext';
import {useStores} from '../../contexts/StoresContext';
import {HOME_PAGE, MANAGE_ISSUES_EDIT_PAGE} from '../../urls';
import {usePaginator} from '../../hooks/paginator';
import {useRouteMatch} from 'react-router-dom';
import styles from './IssueTemplateAdmin.module.scss';
import stylesColumns from '../../components/TemplateList/TemplateList.module.scss';
import SearchInput from '../../components/SearchInput';
const PAGE_SIZE = 6;

const IssueTemplateAdmin = () => {
  const stores = useStores();
  const api = useApi();
  const {t} = useTranslation('user');
  const [, pageActions] = usePageStore();
  const {changePage, currentPage} = usePaginator();
  const match = useRouteMatch();

  const [issuesTemplate, setIssuesTemplate] = useState([]);
  const [issuesTemplateCount, setIssuesTemplateCount] = useState(null);

  const location = useLocation();
  const {search = ''} = queryString.parse(location.search);

  useEffect(() => {
    pageActions.setBreadcrumbs({key: 'manage-issues'});
  }, [pageActions]);

  const handleIssues = useCallback(async () => {
    const response = await api.getIssueTemplateCatalog({
      page_size: PAGE_SIZE,
      page: currentPage,
      search,
    });
    setIssuesTemplate(response.results);
    setIssuesTemplateCount(response.count);
  }, [api, currentPage, search]);

  const handleDeleteIssue = async (issueId) => {
    await api.deleteIssueTemplateCatalog(issueId);
    handleIssues();
  };

  const handleSelectTab = () => {
    changePage(match.url, 1);
    handleIssues();
  };

  const tabs = [
    {
      title: t('user:titles.issues'),
      eventKey: 'issues',
      data: issuesTemplate,
      count: issuesTemplateCount,
    },
  ];

  const extraHeaders = [
    {
      title: t('user:columns.template-list.description'),
      size: 3,
      className: stylesColumns.columnText,
    },
  ];

  const extraColumns = [
    {
      renderCell: (issue) =>
        issue.description[stores.app.user.language.code] !== ''
          ? issue.description[stores.app.user.language.code]
          : issue.description[issue.description.original_language],
      prefix: () => (
        <strong>{t('user:columns.template-list.description')}:&nbsp;</strong>
      ),
      size: 3,
      className: stylesColumns.columnText,
    },
  ];

  useEffect(() => {
    handleIssues();
  }, [handleIssues]);

  if (!stores.app.user.isEditor && !stores.app.user.isAdmin) {
    return <Redirect to={HOME_PAGE} />;
  }

  return (
    <div className={styles.wrapper}>
      <Title>{t('titles.manage-issues')}</Title>
      <SearchInput id="search-bar-issues" initialValue={search} />
      <TemplateList
        extraHeaders={extraHeaders}
        extraColumns={extraColumns}
        pageSize={PAGE_SIZE}
        tabs={tabs}
        onTabSelected={handleSelectTab}
        onDelete={handleDeleteIssue}
        editPage={MANAGE_ISSUES_EDIT_PAGE}
      />
    </div>
  );
};

export default IssueTemplateAdmin;
