import React, {useEffect} from 'react';
import styles from './Stepper.module.scss';
import {StepperStep, StepperSteps} from './StepperSteps';
import {useStepper} from '../../contexts/StepperContext';
import {useHistory} from 'react-router-dom';

const Stepper = ({title, children}) => {
  const {currentStep, steps, setCurrentStep} = useStepper();
  const history = useHistory();
  useEffect(() => {
    if (steps.length) {
      const current = steps.findIndex(
        (step) => step.url === history.location.pathname,
      );
      setCurrentStep(current);
    }
  }, [steps, setCurrentStep, history.location.pathname]);
  return (
    <div className={styles.stepperContainer}>
      {title && <div className={styles.stepperTitle}>{title}</div>}
      <div className={styles.stepperHeader}>
        {steps.length
          ? steps.map((step, index) => {
              return (
                <div
                  key={step.id}
                  className={`${
                    currentStep > index
                      ? styles.completed
                      : currentStep === index
                      ? styles.processing
                      : ''
                  } ${styles.stepperHeaderItem}`}>
                  <div className={styles.stepCounter}>
                    {currentStep === index && (
                      <div className={styles.stepCounterProcessing}></div>
                    )}
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <div>{children}</div>
    </div>
  );
};

Stepper.Step = StepperStep;
Stepper.Steps = StepperSteps;

export default Stepper;
