import {useEffect} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {usePanelStore} from '../../../../contexts/PanelContext';
import {PageProvider} from '../../../../contexts/PageContext';
import styles from './MapViewPanel.module.scss';

const MapViewPanel = ({children, onClose}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const [panelState, panelActions] = usePanelStore();

  const handleClick = () => {
    panelActions.setPanelMount(false);
    onClose && onClose();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        panelActions.setPanelMount(false);
        onClose && onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [history, match.url, onClose, panelActions]);

  if (!panelState.panelMount) {
    return null;
  }

  return (
    <PageProvider>
      <div className={styles.wrapper}>
        <div className={styles.closeIcon} onClick={handleClick}>
          <FontAwesomeIcon icon="times" size="1x" />
        </div>
        <div
          className={`${styles.innerWrapper} ${
            panelState.panelHide && styles.hidePanel
          }`}>
          <div className={styles.container}>{children}</div>
        </div>
      </div>
    </PageProvider>
  );
};

export default MapViewPanel;
