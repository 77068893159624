import React from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import ThankYouModal from '../Modals/ThankYouModal';

const ClaimThankYouModal = (props) => {
  const {side} = useParams();
  const {t} = useTranslation('thank-you');
  const thankYouMessage =
    side === 'offer'
      ? t('labels.offer.claim-submessage')
      : t('labels.need.claim-submessage');

  return (
    <ThankYouModal
      titleMessage={t('labels.message.thank-you')}
      onPrimaryClick={props.onPrimaryClick}
      primaryButtonText={t('buttons.manage')}
      onSecondaryClick={props.onSecondaryClick}
      secondaryButtonText={props.secondaryButtonText}
    >
      {thankYouMessage}
    </ThankYouModal>
  );
};

export default ClaimThankYouModal;
