import React, {useRef} from 'react';
import {OverlayView, Marker} from '@react-google-maps/api';
import {useTranslation} from 'react-i18next';
import {useSearchStore} from '../../contexts/SearchContext';
import HelpStep from '../HelpStep';
import Tooltip from '../../../../components/Tooltip';
import tooltipStyles from '../../../../components/Tooltip/Tooltip.module.scss';

const ExampleTooltip = ({map, markerIcon}) => {
  const {t} = useTranslation('search');
  const helpStepRef3 = useRef();
  const [searchState, searchActions] = useSearchStore();

  const handleCloseTooltip = () => {
    searchActions.setTooltipStep(null);
  };

  if (!map.current) {
    return null;
  }

  return (
    <React.Fragment>
      <OverlayView
        position={map.current.getCenter()}
        mapPaneName="floatPane"
        getPixelPositionOffset={() => ({x: 60, y: -70})}
      >
        <div ref={helpStepRef3} />
      </OverlayView>
      {searchState.tooltipStep === 'third-step' && (
        <Marker
          icon={markerIcon}
          label={{
            text: 'Example',
            className: tooltipStyles.marker,
            color: '#014358',
          }}
          position={map.current.getCenter()}
          title="Example"
        />
      )}
      <HelpStep parent={helpStepRef3} step="third-step">
        <Tooltip
          className={tooltipStyles.lastTooltip}
          isLastTooltip
          onClose={handleCloseTooltip}
        >
          {t('tooltips.third-step')}
        </Tooltip>
      </HelpStep>
    </React.Fragment>
  );
};

export default ExampleTooltip;
