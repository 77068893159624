import React from 'react';
import {Form} from 'react-bootstrap';

import {useFields} from '../../hooks/extraAttribute';

import TextField from './Fields/TextField';
import TextAreaField from './Fields/TextAreaField';
import ChoiceField from './Fields/ChoiceField';
import Subtitle from '../../components/Subtitle';
import Label from './Helper/Label';
import DropDownField from './Fields/DropDownField';

const FIELD_COMPONENTS = {
  TextField: TextField,
  NumberField: TextField,
  ChoiceField: ChoiceField,
  TextAreaField: TextAreaField,
  DropDownField: DropDownField,
};

const ExtraAttributeForm = (props) => {
  const {fields: groups} = useFields(props.modelName);

  const groupsMapper = (group) => {
    return (
      <React.Fragment key={`extra_attribute_group.${group.id}`}>
        {group.title && <Subtitle>{group.title}</Subtitle>}
        {group.subtitle && <Label>{group.subtitle}</Label>}
        {group.fields && group.fields.map(fieldsMapper)}
      </React.Fragment>
    );
  };

  const fieldsMapper = (field) => {
    if (field.kind === 'choice') {
      if (field.options.visible) {
        if (field.options.visible[0].when === 'never') {
          return null;
        }
      }
    }
    const FieldComponent = FIELD_COMPONENTS[field.widget_kind];

    return (
      <Form.Group key={`extra_attribute.${field.name}`}>
        <FieldComponent
          label={field.label}
          name={`extra_attribute.${field.name}`}
          choices={field.choices}
          placeholder={field.placeholder || ''}
          {...field.options}
        />
      </Form.Group>
    );
  };

  return <React.Fragment>{groups && groups.map(groupsMapper)}</React.Fragment>;
};

export default ExtraAttributeForm;
