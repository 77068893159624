import React from 'react';
import styled from 'styled-components';
import {Carousel, Modal} from 'react-bootstrap';
import theme from '../../theme';
import {mediaQueries} from '../../constants';

const Wrapper = styled(Modal)`
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 950px;
    }
  }

  .modal-content {
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
    border-radius: 15px;
  }

  .carousel-indicators {
    bottom: -55px;

    ${mediaQueries.lg} {
      bottom: -70px;
    }

    li {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #bdbdbd;
      opacity: 1;

      &.active {
        background-color: ${theme.secondaryColor};
      }
    }
  }

  ${mediaQueries.lg} {
    .carousel-inner {
      overflow-y: auto;
    }
  }
`;

const Body = styled(Modal.Body)`
  &.modal-body {
    padding: 0;

    ${mediaQueries.lg} {
      padding: 10px;
    }
  }
`;

const OnboardingCarousel = (props) => {
  const {children, ...rest} = props;

  return (
    <Wrapper show centered size="lg">
      <Body>
        <Carousel {...rest} interval={null} controls={false}>
          {children}
        </Carousel>
      </Body>
    </Wrapper>
  );
};

export default OnboardingCarousel;
