import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'react-bootstrap';

import ItemDataTitle from '../ItemForm/ItemDataTitle';
import ItemDataValue from '../ItemForm/ItemDataValue';

import {getMeetBy} from './utils';
import UndisclosedField from './UndisclosedField';
import {useStores} from '../../contexts/StoresContext';
import {useLocaleNumber} from '../../hooks/number';
import ItemDeliveryInformation from '../ItemDeliveryInformation/ItemDeliveryInformation';

const OfferServiceInformation = (props) => {
  const stores = useStores();
  const {item} = props;
  const localeNumber = useLocaleNumber();
  const {t} = useTranslation('forms');
  const jobTypeSerializer = {
    remote: t('fields.job-type-remote'),
    on_site: t('fields.job-type-on-site'),
    either: t('fields.job-type-either'),
  };

  return (
    <React.Fragment>
      {item.jobType && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.service.offer.job-type')}</ItemDataTitle>
            <ItemDataValue>{jobTypeSerializer[item.jobType]}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.tags && !!item.tags.length && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.service.offer.skills')}</ItemDataTitle>
            <ItemDataValue>
              {item.tags.map((skill) => skill.name).join(', ')}
            </ItemDataValue>
          </Col>
        </Row>
      )}
      {item.marketValue && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>
              {t('labels.service.offer.item-market-value', {
                uom: item.baseUom.name,
              })}
            </ItemDataTitle>
            <ItemDataValue>
              {stores.app.currency.symbol} {localeNumber(item.marketValue)}
            </ItemDataValue>
          </Col>
        </Row>
      )}
      {item.dueDate && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>
              {t('labels.service.offer.item-available-until')}
            </ItemDataTitle>
            <ItemDataValue>{getMeetBy(item)}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.description && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.item-description')}</ItemDataTitle>
            <ItemDataValue withoutTextStyle>{item.description}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.location && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.location')}</ItemDataTitle>
            <ItemDataValue>
              <ItemDeliveryInformation item={item} />
            </ItemDataValue>
          </Col>
        </Row>
      )}
      {item.phoneNumber && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.phone-number')}</ItemDataTitle>
            <UndisclosedField item={item} field="phone-number" />
          </Col>
        </Row>
      )}
      {item.contactEmail && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.email')}</ItemDataTitle>
            <UndisclosedField item={item} field="email" />
          </Col>
        </Row>
      )}
      {item.contactName && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.contact-name')}</ItemDataTitle>
            <ItemDataValue>{item.contactName}</ItemDataValue>
          </Col>
        </Row>
      )}
      {item.user && item.user.timezone && (
        <Row>
          <Col sm={12}>
            <ItemDataTitle>{t('labels.timezone')}</ItemDataTitle>
            <ItemDataValue>{item.user.timezone}</ItemDataValue>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default observer(OfferServiceInformation);
