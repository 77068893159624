function getQueryString() {
  return new URLSearchParams(window.location.search);
}

function getValueFromQS(key, defaultValue) {
  const queryString = getQueryString();
  return queryString.get(key) || defaultValue;
}

export {getValueFromQS};
