import React from 'react';
import styled from 'styled-components';
import {breakpoints, mediaQueries} from '../../constants';
import {useRouteMatch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useLocaleNumber} from '../../hooks/number';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${mediaQueries.lg} {
    flex-direction: column-reverse;
  }
`;

const Page = styled.div`
  color: #979797;
  font-size: 14px;
  margin-right: 10px;

  ${mediaQueries.lg} {
    display: flex;
    justify-content: center;
  }
`;

const PageSelector = styled.button`
  background: none;
  border: none;

  &:focus {
    outline: none;
  }

  ${mediaQueries.lg} {
    padding: 1px 5px 2px;
  }

  ${(props) =>
    props.active
      ? `
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 2px;
    padding: 3px 13px;

    ${mediaQueries.lg} {
      padding: 1px 5px;
    }
  `
      : ''};
`;

const Paginator = (props) => {
  const {total, pageSize, currentPage} = props;
  const localeNumber = useLocaleNumber();
  const numberOfPages = Math.ceil(total / pageSize);
  const morePages = '...';
  let pages = [...new Array(numberOfPages + 1).keys()].slice(1);
  const match = useRouteMatch();
  const {t} = useTranslation('common');

  if (pages.length > (props.maxPages || 10)) {
    if (currentPage === 1) {
      pages = [1, 2, morePages, pages[numberOfPages - 1]];
    } else if (currentPage === numberOfPages) {
      pages = [1, morePages, numberOfPages - 1, numberOfPages];
    } else {
      pages = [1];
      if (currentPage - 1 > 2) pages.push(morePages);
      if (currentPage - 1 !== 1) pages.push(currentPage - 1);
      pages.push(currentPage);
      if (currentPage + 1 !== numberOfPages) pages.push(currentPage + 1);
      if (currentPage + 2 < numberOfPages) pages.push(morePages);
      pages.push(numberOfPages);
    }
  }

  const onPageSelectorClick = (page) => () => {
    props.onPageChange(match.url, page);
  };
  const renderItem = (page, index) => {
    if (page === morePages) {
      return (
        <PageSelector key={index + morePages} active={false} disabled>
          {page}
        </PageSelector>
      );
    }

    return (
      <PageSelector
        key={page}
        active={page === currentPage}
        onClick={onPageSelectorClick(page)}
      >
        {page}
      </PageSelector>
    );
  };

  const next =
    currentPage + 1 <= numberOfPages ? (
      <PageSelector onClick={onPageSelectorClick(currentPage + 1)}>
        {t('paginator.next')}
      </PageSelector>
    ) : null;
  const previous =
    currentPage - 1 > 0 ? (
      <PageSelector onClick={onPageSelectorClick(currentPage - 1)}>
        {t('paginator.previous')}
      </PageSelector>
    ) : null;

  return (
    <Wrapper className={props.className}>
      <Page max={breakpoints.lg}>
        {t('paginator.page-count', {
          current: localeNumber(currentPage),
          total: localeNumber(numberOfPages) || 1,
        })}
      </Page>
      <div>
        {previous}
        {numberOfPages > 1 && pages.map(renderItem)}
        {next}
      </div>
    </Wrapper>
  );
};

export default Paginator;
