import {useMemo, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import HSBar from 'react-horizontal-stacked-bar-chart';
import moment from 'moment';
import {useReportStore} from '../context/ReportContext';
import {useLocaleNumber} from '../../../hooks/number';
import {ITEM_REPORTS_ACTIVITY_DETAIL} from '../../../urls';
import {
  getTotalAmount,
  getClaimValue,
  getItemQuantity,
  getItemUnit,
  getColumnTitleFromKey,
  getBeneficiaries,
} from '../itemReportUtils';
import {
  QUANTITY_COLUMN,
  TYPE_COLUMN,
  CATEGORY_COLUMN,
  STATUS_COLUMN,
  ISSUE_COLUMN,
  PLATFORM_INTERACTION_COLUMN,
  TOTAL_AMOUNT,
  BENEFICIARIES_COLUMN,
  CREATION_DATE_COLUMN,
  OWNER_ORGANIZATION_COLUMN,
  FULFILMENT_COLUMN,
  MET_TIMESTAMP,
  MET_CLAIMED_COLUMN,
} from '../../../components/ItemReports/CustomizeColumns';
import Table from '../../../components/Table';
import DotMenu from '../../../components/DotMenu';
import styles from '../ItemReportsPage.module.scss';

const getFulfilmentCell = (item, t) => {
  let data = [];
  if (item.quantity === null) {
    data.push({value: 1, color: '#C8DDEE'});
  } else {
    if (item.claimedQuantity > 0) {
      data.push({value: item.claimedQuantity, color: '#FA812F'});
    }
    if (item.quantity - item.claimedQuantity > 0) {
      data.push({
        value: item.quantity - item.claimedQuantity,
        color: '#EEEEEE',
      });
    }
  }
  return (
    <div className={styles.fulfilmentCell}>
      <span className={styles.fulfilmentText}>
        {item.quantity === null
          ? '###/∞'
          : item.claimedQuantity + '/' + item.quantity}{' '}
        {t('units')}
      </span>
      <div className={styles.barChartContainer}>
        <HSBar id={styles.barChart} height={6} data={data} />
      </div>
    </div>
  );
};

const TabsTable = ({
  allowedColumns,
  data,
  count,
  pageSize,
  onHeaderClick,
  sortBy,
  sortOrder,
  quantityTotal,
  currency,
  isAdmin,
}) => {
  const {t} = useTranslation('item-reports');
  const history = useHistory();
  const localeNumber = useLocaleNumber();
  const [reportState] = useReportStore();
  const isTransactionsTab = reportState.activeTab === 'transactions';

  const handleDeleteFromReport = useCallback(
    (id) => {
      const url = history.location.search;
      const queryStringParams = new URLSearchParams(url);
      let ids = queryStringParams.get('exclude_ids');
      if (ids) {
        ids = ids + ',' + id;
      } else {
        ids = id;
      }
      queryStringParams.set('exclude_ids', ids);
      history.push({search: queryStringParams.toString()});
    },
    [history],
  );

  const handleViewDetails = useCallback(
    (item) => {
      history.push({
        ...history.location,
        pathname: ITEM_REPORTS_ACTIVITY_DETAIL.replace(
          ':itemId',
          item.id,
        ).replace(':side', item.side),
      });
    },
    [history],
  );

  const renderMoreOptions = useCallback(
    (item) => {
      return (
        <DotMenu>
          <Dropdown.Item onClick={() => handleViewDetails(item)}>
            {t('visualizations.detail.see_details')}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleDeleteFromReport(item.id)}>
            {t('visualizations.detail.exclude_report')}
          </Dropdown.Item>
        </DotMenu>
      );
    },
    [handleDeleteFromReport, handleViewDetails, t],
  );

  const headers = useMemo(() => {
    const headers = [];
    headers.push({
      title: getColumnTitleFromKey('item', t),
      sortBy: 'item_name',
    });
    if (allowedColumns.includes(QUANTITY_COLUMN)) {
      headers.push({
        title: getColumnTitleFromKey(QUANTITY_COLUMN, t),
        sortBy: 'quantity',
      });
    }
    if (allowedColumns.includes(TYPE_COLUMN)) {
      headers.push({
        title: getColumnTitleFromKey(TYPE_COLUMN, t),
        sortBy: 'type',
      });
    }
    if (allowedColumns.includes(CATEGORY_COLUMN)) {
      headers.push({
        title: getColumnTitleFromKey(CATEGORY_COLUMN, t),
        sortBy: 'category',
      });
    }
    if (allowedColumns.includes(STATUS_COLUMN)) {
      headers.push({
        title: getColumnTitleFromKey(STATUS_COLUMN, t),
        sortBy: isTransactionsTab ? 'status' : 'ui_status',
      });
    }
    if (allowedColumns.includes(ISSUE_COLUMN)) {
      headers.push({
        title: getColumnTitleFromKey(ISSUE_COLUMN, t),
        sortBy: 'issue',
      });
    }
    if (allowedColumns.includes(PLATFORM_INTERACTION_COLUMN)) {
      headers.push({
        title: getColumnTitleFromKey(PLATFORM_INTERACTION_COLUMN, t),
        sortBy: 'side',
      });
    }
    if (allowedColumns.includes(TOTAL_AMOUNT)) {
      headers.push({
        title: getColumnTitleFromKey(TOTAL_AMOUNT, t),
        sortBy: 'total',
      });
    }
    if (isTransactionsTab && allowedColumns.includes(BENEFICIARIES_COLUMN)) {
      headers.push({
        title: getColumnTitleFromKey(BENEFICIARIES_COLUMN, t),
        sortBy: 'beneficiaries',
      });
    }
    if (!isTransactionsTab && allowedColumns.includes(CREATION_DATE_COLUMN)) {
      headers.push({
        title: getColumnTitleFromKey(CREATION_DATE_COLUMN, t),
        sortBy: 'created_at',
      });
    }
    if (
      (isTransactionsTab || isAdmin) &&
      allowedColumns.includes(OWNER_ORGANIZATION_COLUMN)
    ) {
      headers.push({
        title: getColumnTitleFromKey(OWNER_ORGANIZATION_COLUMN, t),
        sortBy: 'organization',
      });
    }
    if (!isTransactionsTab && allowedColumns.includes(FULFILMENT_COLUMN)) {
      headers.push({
        title: getColumnTitleFromKey(FULFILMENT_COLUMN, t),
        sortBy: 'fulfillment',
      });
    }
    if (isTransactionsTab && allowedColumns.includes(MET_TIMESTAMP)) {
      headers.push({
        title: getColumnTitleFromKey(MET_TIMESTAMP, t),
        sortBy: 'met_timestamp',
      });
    }
    if (isTransactionsTab && allowedColumns.includes(MET_CLAIMED_COLUMN)) {
      headers.push({
        title: getColumnTitleFromKey(MET_CLAIMED_COLUMN, t),
        sortBy: 'claimed_by',
      });
    }
    if (!isTransactionsTab) {
      headers.push({
        title: '',
        size: 0.5, // some invalid value so that the column becomes really small
        extraPadding: 25,
      });
    }
    return headers;
  }, [t, allowedColumns, isAdmin, isTransactionsTab]);

  const columns = useMemo(() => {
    const columns = [];
    columns.push({
      renderCell: (item) => (isTransactionsTab ? item.item?.name : item.name),
    });
    if (allowedColumns.includes(QUANTITY_COLUMN)) {
      columns.push({
        renderCell: (item) => {
          const quantity = getItemQuantity(item, localeNumber, t);
          const uom = getItemUnit(item);

          if (!item.deliveryInBundles) {
            return `${quantity} ${uom}`;
          }

          return (
            <OverlayTrigger
              placement="bottom"
              trigger={['hover', 'focus']}
              overlay={
                <Tooltip>{`${quantity} ${uom} (${item.bundle.units} ${item.baseUom.name}/${uom})`}</Tooltip>
              }>
              <p>
                {`${quantity} ${uom}`} <br />
                {`(${item.bundle.units} ${item.baseUom.name}/${uom})`}
              </p>
            </OverlayTrigger>
          );
        },
      });
    }
    if (allowedColumns.includes(TYPE_COLUMN)) {
      columns.push({
        renderCell: (item) => (isTransactionsTab ? item.item?.kind : item.kind),
      });
    }
    if (allowedColumns.includes(CATEGORY_COLUMN)) {
      columns.push({
        renderCell: (item) =>
          isTransactionsTab ? item.item?.category?.name : item.category?.name,
      });
    }
    if (allowedColumns.includes(STATUS_COLUMN)) {
      columns.push({
        renderCell: (item) =>
          isTransactionsTab
            ? item.confirmationStatus?.value
            : t(`tabs.status.${item.ui_status}`),
      });
    }
    if (allowedColumns.includes(ISSUE_COLUMN)) {
      columns.push({
        renderCell: (item) =>
          isTransactionsTab ? item.item?.issue_name : item.issueName,
      });
    }
    if (allowedColumns.includes(PLATFORM_INTERACTION_COLUMN)) {
      columns.push({
        renderCell: (item) => item.platformInteraction,
      });
    }
    if (allowedColumns.includes(TOTAL_AMOUNT)) {
      columns.push({
        renderCell: (item) =>
          isTransactionsTab
            ? getClaimValue(item, localeNumber, currency, t)
            : getTotalAmount(item, localeNumber, currency, t),
      });
    }
    if (isTransactionsTab && allowedColumns.includes(BENEFICIARIES_COLUMN)) {
      columns.push({
        renderCell: (item) => {
          const label = getBeneficiaries(item, t);
          return (
            label && (
              <OverlayTrigger
                placement="bottom"
                trigger={['hover', 'focus']}
                overlay={<Tooltip>{label}</Tooltip>}>
                <p>{label}</p>
              </OverlayTrigger>
            )
          );
        },
      });
    }
    if (!isTransactionsTab && allowedColumns.includes(CREATION_DATE_COLUMN)) {
      columns.push({
        renderCell: (item) => moment(item.createdAt).format('L'),
      });
    }
    if (
      (isTransactionsTab || isAdmin) &&
      allowedColumns.includes(OWNER_ORGANIZATION_COLUMN)
    ) {
      columns.push({
        renderCell: (item) =>
          isTransactionsTab ? item.postOrganizationName : item.organizationName,
      });
    }
    if (!isTransactionsTab && allowedColumns.includes(FULFILMENT_COLUMN)) {
      columns.push({
        renderCell: (item) => getFulfilmentCell(item, t),
      });
    }
    if (isTransactionsTab && allowedColumns.includes(MET_TIMESTAMP)) {
      columns.push({
        renderCell: (item) => moment(item.createdAt).format('L'),
      });
    }
    if (isTransactionsTab && allowedColumns.includes(MET_CLAIMED_COLUMN)) {
      columns.push({
        renderCell: (item) => item.claimOrganizationName,
      });
    }
    if (!isTransactionsTab) {
      columns.push({
        renderCell: renderMoreOptions,
        newColumn: true,
        moreOptions: true,
        size: 0.5, // some invalid value so that the column becomes really small
        extraPadding: 25,
      });
    }
    return columns;
  }, [
    t,
    localeNumber,
    allowedColumns,
    currency,
    isAdmin,
    isTransactionsTab,
    renderMoreOptions,
  ]);

  return (
    <Table
      headers={headers}
      columns={columns}
      data={data}
      count={count}
      pageSize={pageSize}
      onHeaderClick={onHeaderClick}
      sortBy={sortBy}
      sortOrder={sortOrder}
      paginatorV2={true}
      quantityTotal={quantityTotal}
      allowedColumns={allowedColumns}
    />
  );
};

export default TabsTable;
