import React, {useCallback, useRef, useMemo, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {
  useClearRefinements,
  useInstantSearch,
  useRefinementList,
  InfiniteHits,
} from 'react-instantsearch';
import {GoogleMap, Marker, MarkerClusterer} from '@react-google-maps/api';
import {Button} from 'react-bootstrap';
import {usePaginator} from '../../../../hooks/paginator';
import {usePanelStore} from '../../../../contexts/PanelContext';

import {Route} from '../../../../components/Layout';
import ClaimFlow from '../../../../components/ClaimFlow';
import {ReactComponent as SearchIcon} from '../../../../assets/icons/search.svg';
import marker from '../../../../assets/marker.png';
import markerSelected from '../../../../assets/marker-selected.png';
import markerManufacturer from '../../../../assets/manufacturer-marker.png';
import markerManufacturerSelected from '../../../../assets/manufacturer-marker-selected.png';
import styles from './MapAlgoliaContainer.module.scss';
import {useMapAlgoliaStore} from '../../../../contexts/MapAlgoliaContext';
import Spinner from '../../../../components/Spinner';
import AlgoliaItem from '../AlgoliaItem';

const ZOOM_LEVEL_MIN = 4;
const ZOOM_LEVEL_MAX = 17;

const MARKER_SIZE = {
  width: 45,
  height: 52,
  height_selected: 66,
  width_selected: 60,
};

const MARKER_CLUSTER_STYLE = {
  width: 45,
  height: 45,
  url: marker,
  textColor: 'white',
  textSize: 16,
  fontFamily: 'Roboto',
};

const MARKER_CLUSTER_SELECTED_STYLE = {
  width: 60,
  height: 60,
  url: markerSelected,
  textColor: 'white',
  textSize: 16,
  fontFamily: 'Roboto',
};

const MINIMUM_CLUSTER_SIZE = 2;

const GRID_SIZE = 20;

export const MapAlgoliaContainer = () => {
  const {t} = useTranslation('search-page');
  const {items, refine: refinePosition} = useRefinementList({
    attribute: 'meta_position',
    limit: 1500,
  });
  const {status} = useInstantSearch();
  const [mapAlgoliaState, mapAlgoliaActions] = useMapAlgoliaStore();
  const [, panelActions] = usePanelStore();
  const history = useHistory();
  const match = useRouteMatch();
  const [mapping, setMapping] = useState([]);
  const [selectedHits, setSelectedHits] = useState([]);

  const ref = useRef(null);
  const {refine: refineClearRefinements} = useClearRefinements({
    includedAttributes: ['meta_position'],
  });
  const {changePage, currentPage} = usePaginator();

  const itemsFilters = items.filter(
    (item) => !selectedHits.some((record) => record.label === item.label),
  );

  const handleSearchHere = () => {
    const bounds = ref.current.getBounds();
    mapAlgoliaActions.setBounds(bounds);
    ref.current.fitBounds(bounds);
  };

  const handleMapLoaded = useCallback((map) => {
    ref.current = map;
  }, []);

  const [markerIcon, markerManufacturerIcon] = useMemo(() => {
    const markerSizeLabelOrigin = new window.google.maps.Point(
      MARKER_SIZE.width / 2,
      MARKER_SIZE.height,
    );
    const markerSize = new window.google.maps.Size(
      MARKER_SIZE.width,
      MARKER_SIZE.height,
    );

    const selectedMarkerSizeLabelOrigin = new window.google.maps.Point(
      MARKER_SIZE.width_selected / 2,
      MARKER_SIZE.height_selected,
    );
    const selectedMarkerSize = new window.google.maps.Size(
      MARKER_SIZE.width_selected,
      MARKER_SIZE.height_selected,
    );
    return [
      {
        url: marker,
        labelOrigin: markerSizeLabelOrigin,
        scaledSize: markerSize,
      },
      {
        url: markerSelected,
        labelOrigin: selectedMarkerSizeLabelOrigin,
        scaledSize: selectedMarkerSize,
      },
      {
        url: markerManufacturer,
        labelOrigin: markerSizeLabelOrigin,
        scaledSize: markerSize,
      },
      {
        url: markerManufacturerSelected,
        labelOrigin: selectedMarkerSizeLabelOrigin,
        scaledSize: selectedMarkerSize,
      },
    ];
  }, []);

  const viewDetails = useCallback(
    (hit) => {
      let lastRefine = '';
      let arrPositions = [];
      refineClearRefinements();
      hit.forEach((record) => {
        if (record.value !== lastRefine) {
          lastRefine = record.value;
          refinePosition(lastRefine);
          arrPositions.push(record);
        }
      });
      history.push(`${match.url}/details`);
      panelActions.setPanelMount(true);
      setSelectedHits(arrPositions);
    },
    [history, panelActions, match.url, refinePosition, refineClearRefinements],
  );

  const handleClose = useCallback(() => {
    changePage(match.url, currentPage);
  }, [changePage, currentPage, match.url]);

  const handleClosePresence = useCallback(() => {
    refineClearRefinements();
    setSelectedHits([]);
    handleClose();
  }, [refineClearRefinements, handleClose]);

  const renderMarker = (isManufacturer) => (clusterer) => (hit) => {
    const [lat, lng] = hit.label.split(',');
    const markers = Array.from({length: hit.count}, (record, i) => ({
      record: record,
      id: i,
    }));
    return (
      <div key={hit.value}>
        {markers.map(({record, id}) => {
          const key = `${hit.value}${id}`;
          return (
            <Marker
              key={key}
              onClick={() => {
                viewDetails([hit]);
              }}
              onLoad={(marker) => {
                setMapping((prevState) => [[hit, marker], ...prevState]);
              }}
              onUnmount={(marker) => {
                setMapping((prevState) =>
                  [...prevState].filter((tuple) => marker !== tuple[1]),
                );
              }}
              clusterer={clusterer}
              icon={isManufacturer ? markerManufacturerIcon : markerIcon}
              position={{lat: parseFloat(lat), lng: parseFloat(lng)}}
            />
          );
        })}
      </div>
    );
  };

  const handleClickMarkerCluster = useCallback(
    (cluster) => {
      const center = {lat: cluster.center.lat(), lng: cluster.center.lng()};
      const markers = cluster.getMarkers();
      if (markers.length >= MINIMUM_CLUSTER_SIZE) {
        const hits = mapping
          .filter((tuple) => markers.indexOf(tuple[1]) !== -1)
          .map((tuple) => tuple[0]);
        viewDetails(hits);
      }
      mapAlgoliaActions.setCenterZoom(center, 15);
    },
    [mapping, viewDetails, mapAlgoliaActions],
  );

  const handleZoom = useCallback(() => {
    if (ref.current) {
      const zoom = ref.current.zoom;
      mapAlgoliaActions.setZoom(zoom);
    }
  }, [mapAlgoliaActions]);

  useEffect(() => {
    if (ref.current && mapAlgoliaState.bounds) {
      ref.current.fitBounds(mapAlgoliaState.bounds);
    }
  }, [mapAlgoliaState.bounds]);

  return (
    <div className={styles.container}>
      <div className={styles.refreshButton}>
        <Button variant="light" onClick={handleSearchHere}>
          {t('refresh-search')}
          <SearchIcon />
        </Button>
      </div>
      {status !== 'idle' && (
        <div className={styles.loadingDiv}>
          <Spinner />
        </div>
      )}
      <GoogleMap
        onLoad={handleMapLoaded}
        zoom={mapAlgoliaState.zoom}
        onZoomChanged={handleZoom}
        center={mapAlgoliaState.center}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          maxZoom: ZOOM_LEVEL_MAX,
          minZoom: ZOOM_LEVEL_MIN,
          styles: [
            {
              featureType: 'poi.business',
              stylers: [{visibility: 'off'}],
            },
            {
              featureType: 'transit',
              elementType: 'labels.icon',
              stylers: [{visibility: 'off'}],
            },
          ],
        }}
        mapContainerClassName={styles.mapContainer}>
        {status === 'idle' && (
          <>
            <MarkerClusterer
              minimumClusterSize={MINIMUM_CLUSTER_SIZE}
              gridSize={GRID_SIZE}
              styles={[MARKER_CLUSTER_SELECTED_STYLE]}
              onClick={handleClickMarkerCluster}>
              {(clusterer) => {
                return <>{selectedHits.map(renderMarker(false)(clusterer))}</>;
              }}
            </MarkerClusterer>
            <MarkerClusterer
              minimumClusterSize={MINIMUM_CLUSTER_SIZE}
              gridSize={GRID_SIZE}
              styles={[MARKER_CLUSTER_STYLE]}
              onClick={handleClickMarkerCluster}>
              {(clusterer) => {
                return <>{itemsFilters.map(renderMarker(false)(clusterer))}</>;
              }}
            </MarkerClusterer>
          </>
        )}
      </GoogleMap>
      <ClaimFlow isMapView onClose={handleClosePresence}>
        <Route
          exact
          path={`${match.url}/details`}
          render={() => (
            <InfiniteHits
              classNames={{
                root: styles.hitsWrapper,
                list: styles.hitsList,
                item: styles.hitsItem,
                loadMore: styles.hitsButton,
                emptyRoot: styles.hitsEmpty,
              }}
              showPrevious={false}
              hitComponent={AlgoliaItem}
            />
          )}
          isPublic
        />
      </ClaimFlow>
    </div>
  );
};
