import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {useStores} from '../contexts/StoresContext';

const MainCategoryWidget = (props) => {
  const stores = useStores();

  const [value, setValue] = useState(props.value);

  const renderCategories = () => {
    return stores.backoffice.categories.map((category, index) => {
      return (
        <option key={index} value={category.id}>
          {category.name}
        </option>
      );
    });
  };

  return (
    <React.Fragment>
      <label>{props.label}</label>
      <select
        disabled={stores.backoffice.categories.length === 0}
        className={props.className}
        onChange={(event) => setValue(event.target.value)}
        value={value}
        name={props.name}
      >
        <option value="">
          {!value ? 'No categories selected' : 'Select main category'}
        </option>
        {renderCategories()}
      </select>
    </React.Fragment>
  );
};

export default observer(MainCategoryWidget);
