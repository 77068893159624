import React from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Col, Row} from 'react-bootstrap';

import {
  TextAreaField,
  InputField,
  LocationAutocompleteField,
  ImageUploadField,
} from '../../../components/Fields';
import styled from 'styled-components';

const Column = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const BaseOrganizationFields = () => {
  const {t} = useTranslation('create-account');

  return (
    <React.Fragment>
      <Form.Group as={Row}>
        <Col>
          <TextAreaField
            showCharCounter
            rows="3"
            maxLength={350}
            name="description"
            label={t('labels.description')}
            placeholder={t('placeholders.description')}
            isRequired
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col>
          <ImageUploadField name="logo" label={t('labels.logo')} />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col>
          <InputField
            type="text"
            name="website"
            label={t('labels.website')}
            placeholder={t('placeholders.website')}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col>
          <InputField
            type="text"
            name="phone"
            label={t('labels.phone')}
            placeholder={t('placeholders.phone')}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col>
          <InputField
            type="text"
            name="email"
            label={t('labels.email')}
            placeholder={t('placeholders.email')}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Column>
          <LocationAutocompleteField
            name="locations"
            label={t('labels.location')}
            placeholder={t('placeholders.location')}
            isRequired
            multiple
            allowCustomAddress
            className="mb-2"
            notEditAddress
            notDeleteAddress
          />
        </Column>
      </Form.Group>
    </React.Fragment>
  );
};

export default BaseOrganizationFields;
