import React from 'react';
import DialogV2 from '../../DialogV2';
import {Form, Col, Row, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import styles from './LocationFormData.module.scss';
import informationIcon from '../../../assets/icons/information.svg';
import {useStores} from '../../../contexts/StoresContext';
import Label from '../../Form/Label';

function LocationFormData({show, formik, handleBack, handleNext}) {
  const {t} = useTranslation('add-items');
  const stores = useStores();

  return (
    <DialogV2 show={show} onHide={handleBack} noHeader className={styles.modal}>
      <div className={styles.modalInfo}>
        <img src={informationIcon} alt="information icon" />
        <div className={styles.modalInfoText}>
          <p>{t('add-address-modal.info.complete-form')}</p>
          <p>{t('add-address-modal.info.info-published')}</p>
        </div>
      </div>
      <Form onSubmit={formik.handleSubmit} className={styles.locationForm}>
        <Row>
          <Col md="6">
            <Form.Group>
              <Label isRequired>
                {t('add-address-modal.form-labels.label')}
              </Label>
              <Form.Control
                name="location_label"
                onChange={formik.handleChange}
                type="text"
                placeholder={t('add-address-modal.form-placeholders.label')}
                value={formik.values.location_label}
                isInvalid={
                  formik.touched.location_label && formik.errors.location_label
                }
                className={styles.editableField}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.location_label}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Label isRequired>
                {t('add-address-modal.form-labels.type')}
              </Label>
              <Form.Control
                name="location_type"
                onChange={formik.handleChange}
                as="select"
                value={formik.values.location_type}
                isInvalid={
                  formik.touched.location_type && formik.errors.location_type
                }
                className={styles.editableField}>
                <option value="">
                  {t('add-address-modal.form-placeholders.type')}
                </option>
                {Object.keys(stores.app.choices.addressLocationType).map(
                  (value) => (
                    <option value={value} key={value}>
                      {stores.app.choices.addressLocationType[value]}
                    </option>
                  ),
                )}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.errors.location_type}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Form.Group>
              <Label>{t('add-address-modal.form-placeholders.number')}</Label>
              <Form.Control
                name="number"
                onChange={formik.handleChange}
                type="text"
                placeholder={t('add-address-modal.form-placeholders.number')}
                value={formik.values.number}
                className={styles.editableField}
                isInvalid={formik.touched.number && formik.errors.number}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.number}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Label>{t('add-address-modal.form-placeholders.street')}</Label>
              <Form.Control
                name="street"
                onChange={formik.handleChange}
                type="text"
                placeholder={t('add-address-modal.form-placeholders.street')}
                value={formik.values.street}
                className={styles.editableField}
                isInvalid={formik.touched.street && formik.errors.street}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.street}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Form.Group>
              <Label>{t('add-address-modal.form-placeholders.city')}</Label>
              <Form.Control
                name="city"
                onChange={formik.handleChange}
                type="text"
                disabled
                placeholder={t('add-address-modal.form-placeholders.city')}
                value={formik.values.city}
                isInvalid={formik.touched.city && formik.errors.city}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.city}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Label>{t('add-address-modal.form-placeholders.state')}</Label>
              <Form.Control
                name="state"
                onChange={formik.handleChange}
                type="text"
                disabled
                placeholder={t('add-address-modal.form-placeholders.state')}
                value={formik.values.state}
                isInvalid={formik.touched.state && formik.errors.state}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.state}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Form.Group>
              <Label>{t('add-address-modal.form-placeholders.country')}</Label>
              <Form.Control
                name="country"
                onChange={formik.handleChange}
                type="text"
                disabled
                placeholder={t('add-address-modal.form-placeholders.country')}
                value={formik.values.country}
                isInvalid={formik.touched.country && formik.errors.country}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.country}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Label>{t('add-address-modal.form-labels.landmark')}</Label>
              <Form.Control
                name="location_landmark"
                onChange={formik.handleChange}
                type="text"
                placeholder={t('add-address-modal.form-placeholders.landmark')}
                value={formik.values.location_landmark}
                isInvalid={
                  formik.touched.location_landmark &&
                  formik.errors.location_landmark
                }
                className={styles.editableField}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.location_landmark}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <div className={styles.footerButtons}>
          <Button
            variant="outline-primary"
            onClick={handleBack}
            className={styles.modalButton}>
            {t('add-items:add-address-modal.buttons.back')}
          </Button>

          <Button
            variant="primary"
            disabled={
              !formik.values.location_label || !formik.values.location_type
            }
            onClick={handleNext}
            className={styles.modalButton}>
            {t('add-items:add-address-modal.buttons.next')}
          </Button>
        </div>
      </Form>
    </DialogV2>
  );
}

export default LocationFormData;
