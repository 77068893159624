import React, {useMemo} from 'react';
import DialogV2 from '../../DialogV2';
import {Form, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import styles from './LocationConfirm.module.scss';
import informationIcon from '../../../assets/icons/information.svg';
import {useStores} from '../../../contexts/StoresContext';

const LocationConfirm = ({show, formik, handleBack, children}) => {
  const {t} = useTranslation('add-items', 'confirm');
  const stores = useStores();

  const locationType = useMemo(
    () => stores.app.choices.addressLocationType[formik.values.location_type],
    [stores.app.choices, formik.values.location_type],
  );

  return (
    <DialogV2 show={show} onHide={handleBack} noHeader className={styles.modal}>
      {children}
      <div className={styles.informationRow}>
        <img src={informationIcon} alt="information icon" />
        <p>{t('add-items:add-address-modal.info.info-confirm')}</p>
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <div className={styles.dataConfirm}>
          <div className={styles.typePill}>{locationType}</div>
          <p className={styles.dataLabel}>{formik.values.location_label}</p>
          <div className={styles.dataLocation}>
            <p className={styles.dataStreet}>
              {formik.values.number &&
                `${formik.values.number}, ${formik.values.street}`}
            </p>
            <p>
              {`${formik.values.city ? `${formik.values.city},` : ''} ${
                formik.values.state ? `${formik.values.state},` : ''
              }
                  ${formik.values.country}`}
            </p>
          </div>
          {formik.values.location_landmark && (
            <p className={styles.dataLandmark}>
              {t('add-items:add-address-modal.info.location-landmark')}{' '}
              {formik.values.location_landmark}
            </p>
          )}
        </div>
        <div className={styles.footerButtons}>
          <Button
            variant="outline-primary"
            onClick={handleBack}
            className={styles.modalButton}>
            {t('add-items:add-address-modal.buttons.back')}
          </Button>

          <Button
            variant="primary"
            type="submit"
            className={styles.modalButton}>
            {t('add-items:add-address-modal.buttons.confirm')}
          </Button>
        </div>
      </Form>
    </DialogV2>
  );
};

export default LocationConfirm;
