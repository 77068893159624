import React, {useMemo} from 'react';
import {flushSync} from 'react-dom';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {breakpoints, FEATURES, mediaQueries} from '../../constants';
import {isFeatureEnabled} from '../../utils/features';
import {useStores} from '../../contexts/StoresContext';
import {useApi} from '../../contexts/ApiContext';
import {useSearchAlgoliaStore} from '../../contexts/SearchAlgoliaContext';
import MenuLink from './MenuLink';
import Responsive from './../Responsive';

import {ReactComponent as ManageIcon} from '../../assets/icons/manage.svg';
import {ReactComponent as SettingsIcon} from '../../assets/icons/gear.svg';
import {ReactComponent as OffersIcon} from '../../assets/icons/offers.svg';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
import {ReactComponent as PostsIcon} from '../../assets/icons/needs-and-offers.svg';
import {ReactComponent as BrowseIssuesIcon} from '../../assets/icons/browse-issues.svg';
import {ReactComponent as ReportsIcon} from '../../assets/icons/chart.svg';
import {ReactComponent as LocationIcon} from '../../assets/icons/location.svg';
import {ReactComponent as MyProfileIcon} from '../../assets/icons/my-profile.svg';
import {ReactComponent as ManageUsersIcon} from '../../assets/icons/manage-users.svg';
import {ReactComponent as ManageOrganizationsIcon} from '../../assets/icons/manage-organizations.svg';
import {ReactComponent as ManageItemsIcon} from '../../assets/icons/manage-items.svg';
import {ReactComponent as ManageIssuesIcon} from '../../assets/icons/issues-catalog.svg';
import {ReactComponent as MyOrganizationIcon} from '../../assets/icons/organizations.svg';
import {ReactComponent as BrowseMembersIcon} from '../../assets/icons/browse-members.svg';
import {ReactComponent as LogoutIcon} from '../../assets/icons/logout.svg';
import {
  SEARCH_PAGE,
  BROWSE_ISSUES_PAGE,
  ADD_NEEDS,
  ADD_OFFERS,
  MANAGE_PAGE,
  ITEM_REPORTS_PAGE,
  USER_PROFILE,
  MEMBER_PAGE,
  MANAGE_ADMIN_PAGE,
  BROWSE_MEMBERS_PAGE,
  MANAGE_ORGANIZATIONS_PAGE,
  MAP_PAGE_V1,
  MANAGE_ITEMS_PAGE,
  MANAGE_ISSUES_PAGE,
} from '../../urls';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useWindowSize} from '../../hooks/utils';
import styles from './TopBar.module.scss';
import {useAuth0} from '@auth0/auth0-react';

const LinksContainer = styled.div`
  flex: 1;
  @media screen and (min-width: ${breakpoints.lg + 1}px) {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.83rem;
    > * {
      margin-right: 12px;
    }
  }
  @media screen and (min-width: ${breakpoints.lg + 200}px) {
    font-size: 1rem;
    > * {
      margin-right: 1.8rem;
    }
  }
  ${mediaQueries.lg} {
    background-color: white;
  }
`;

const Links = () => {
  const {t} = useTranslation(['top-bar', 'user']);
  const stores = useStores();
  const [, searchActions] = useSearchAlgoliaStore();
  const showMapLink = isFeatureEnabled(FEATURES.mapLink, stores.app.features);
  const api = useApi();
  const windowSize = useWindowSize();
  const {user, clientData} = stores.app;
  const isOrgAdmin = user && user.isOrgAdmin;
  const isAdmin = user && user.isAdmin;
  const isEditor = user && user.isEditor;
  const auth0Enabled = clientData.authentication_type === 'auth0';
  const {logout} = useAuth0();

  const handleLogOut = async () => {
    await api.logout();

    flushSync(() => {
      stores.app.logout();
      stores.app.setRefreshing(true);
    });
    if (auth0Enabled) {
      logout({returnTo: window.location.origin + '/login'});
    } else {
      window.location.replace('/login');
    }
  };

  const handleSetMapView = () => {
    searchActions.setIsMapView(true);
  };

  const handleSetSearchView = () => {
    searchActions.setIsMapView(false)
  }

  // @FIXME replace -> getUserDisplayName
  const userName = useMemo(() => {
    return user.first_name && user.last_name
      ? `${user.first_name} ${user.last_name}`
      : user.email;
  }, [user.first_name, user.last_name, user.email]);

  const topBarLinks = [
    {
      to: BROWSE_ISSUES_PAGE,
      label: t('top-bar:links.browse-issues'),
      icon: <BrowseIssuesIcon />,
      show: true,
    },
    {
      to: SEARCH_PAGE,
      label: t('top-bar:links.search'),
      icon: <SearchIcon />,
      show: true,
      onClick: clientData.use_search_v2 && handleSetSearchView
    },
    {
      label: t('top-bar:links.needs-and-offers.title'),
      icon: <PostsIcon />,
      show: true,
      openOn: 'hover',
      links: [
        {
          to: ADD_NEEDS,
          label: t('top-bar:links.needs-and-offers.add-needs'),
          icon: <PlusIcon />,
          show: true,
        },
        {
          to: ADD_OFFERS,
          label: t('top-bar:links.needs-and-offers.make-an-offer'),
          icon: <OffersIcon />,
          show: true,
        },
        {
          to: MANAGE_PAGE,
          label: t('top-bar:links.needs-and-offers.manage-needs-offers'),
          icon: <ManageIcon />,
          show: true,
        },
      ],
    },
    {
      to: clientData.use_search_v2 ? SEARCH_PAGE : MAP_PAGE_V1,
      label: t('top-bar:links.map'),
      icon: <LocationIcon />,
      show: showMapLink,
      onClick: clientData.use_search_v2 && handleSetMapView,
    },
    {
      to: ITEM_REPORTS_PAGE,
      label: t('top-bar:links.reports'),
      icon: <ReportsIcon />,
      show: true,
    },
    {
      label:
        windowSize.width > breakpoints.lg
          ? userName
          : t('user:buttons.account-settings.title'),
      icon:
        windowSize.width > breakpoints.lg ? (
          <span className={styles.profileImage} max={breakpoints.lg}>
            <FontAwesomeIcon icon="user" color="white" />
          </span>
        ) : (
          <SettingsIcon />
        ),
      show: true,
      className: styles.profileMenu,
      openOn: 'click',
      header: () => {
        return (
          <Responsive>
            <span className={styles.profileImage} max={breakpoints.lg}>
              <FontAwesomeIcon icon="user" color="white" />
            </span>
            <div className={styles.userName} max={breakpoints.lg}>
              {userName}
              <FontAwesomeIcon className={styles.caretIcon} icon="caret-down" />
            </div>
          </Responsive>
        );
      },
      links: [
        {
          to: USER_PROFILE.replace(':userUUID', user.uuid),
          label: t('user:buttons.account-settings.my-profile'),
          icon: <MyProfileIcon />,
          show: true,
        },
        {
          to: MEMBER_PAGE.replace(':slug', user.organization_slug),
          label: t('user:buttons.account-settings.my-organization'),
          icon: <MyOrganizationIcon />,
          show: true,
        },
        {
          to: BROWSE_MEMBERS_PAGE,
          label: t('user:buttons.account-settings.browse-members'),
          icon: <BrowseMembersIcon />,
          show: true,
        },
        {
          to: MANAGE_ADMIN_PAGE,
          label: t('user:buttons.account-settings.manage-users'),
          icon: <ManageUsersIcon />,
          show: isAdmin || isOrgAdmin,
        },
        {
          to: MANAGE_ORGANIZATIONS_PAGE,
          label: t('user:buttons.account-settings.manage-organizations'),
          icon: <ManageOrganizationsIcon />,
          show: isAdmin,
        },
        {
          to: MANAGE_ITEMS_PAGE,
          label: t('user:buttons.account-settings.manage-items'),
          icon: <ManageItemsIcon />,
          show: isEditor || isAdmin,
        },
        {
          to: MANAGE_ISSUES_PAGE,
          label: t('user:buttons.account-settings.manage-issues'),
          icon: <ManageIssuesIcon />,
          show: isEditor || isAdmin,
        },
        {
          label: t('user:buttons.account-settings.logout'),
          icon: <LogoutIcon />,
          show: true,
          onClick: handleLogOut,
        },
      ],
    },
  ];

  const mapTopBarLinks = (link, index) => {
    const {label, icon, to, links, className, openOn, onClick} = link;

    const options = {};

    if (to) {
      options.to = to;
      if (onClick) {
        options.onClick = onClick;
      }
    }

    if (!to && onClick) {
      options.to = '#';
      options.onClick = onClick;
    }

    return (
      <MenuLink
        key={`${index}-link-key`}
        icon={icon}
        activeStyle={{color: 'black'}}
        exact
        links={links}
        className={className}
        openOn={openOn}
        {...options}>
        {label}
      </MenuLink>
    );
  };

  return (
    <LinksContainer>
      {topBarLinks.filter((link) => link.show).map(mapTopBarLinks)}
    </LinksContainer>
  );
};

export default Links;
