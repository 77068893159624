import React, {useReducer, useMemo} from 'react';

const initialState = {
  activeTab: 'posts',
};

const ReportContext = React.createContext();

const types = {
  REPORT_SET_ACTIVE_TAB: 'REPORT_SET_ACTIVE_TAB',
};

const reducer = (state, action) => {
  const {type, payload} = action;

  switch (type) {
    case types.REPORT_SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

const ReportProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(() => {
    return {
      setActiveTab: (tab) => {
        dispatch({
          type: types.REPORT_SET_ACTIVE_TAB,
          payload: tab,
        });
      },
    };
  }, [dispatch]);

  return (
    <ReportContext.Provider value={[state, actions]}>
      {children}
    </ReportContext.Provider>
  );
};

const useReportStore = () => {
  const context = React.useContext(ReportContext);
  if (context === undefined) {
    throw new Error('`useReportStore` must be used within a `ReportContext`.');
  }
  return context;
};

export {ReportProvider, useReportStore};
