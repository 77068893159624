import React, {useState} from 'react';
import {Alert} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import styles from './AlertLinkReport.module.scss';
import {REPORTS_PAGE} from '../../urls';

export function AlertLinkReport() {
  const {t} = useTranslation('item-reports');
  const history = useHistory();
  const [visible, setVisible] = useState(true);

  const onHandleButton = () => {
    setVisible(false);
  };

  return visible ? (
    <Alert variant="info" className={styles.linkMessage}>
      <span>
        <b>{t('link-old-report.welcome')} </b>
        {t('link-old-report.message')}{' '}
        <span
          className={styles.here}
          onClick={() => history.push(REPORTS_PAGE)}
        >
          {t('link-old-report.here')}
        </span>
      </span>

      <span className={styles.buttonClose} onClick={onHandleButton}>
        x
      </span>
    </Alert>
  ) : (
    <></>
  );
}
