import moment from 'moment';

export const getMeetBy = (item) => {
  const dueDate = moment(item.dueDate);
  const diff = dueDate.diff(moment(), 'days');
  const endsIn =
    diff === 0 ? 'ends today' : `ends in ${diff} ${diff > 1 ? 'days' : 'day'}`;

  return `${dueDate.format('MM/DD/YYYY')} (${endsIn})`;
};

export const getCreatedAt = (item) => {
  const date = moment(item.createdAt);
  const diff = moment().diff(date, 'days');
  const postedOn =
    diff === 0 ? 'Today' : `${diff} ${diff > 1 ? 'days' : 'day'} Ago`;

  return `${date.format('MM/DD/YYYY')} (${postedOn})`;
};
