import React, {useState} from 'react';
import {useField, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {Form} from 'react-bootstrap';
import Typeahead from '../../components/Form/Typeahead';
import Pills from '../../components/Pills/Pills';
import styles from './TemplateSelect.module.scss';
import FeedbackInvalid from '../../components/Form/FeedbackInvalid';
import {useAlgoliaSearchTemplate, useApiSearchTemplate} from './hooks';
import {useStores} from '../../contexts/StoresContext';
import {isFeatureEnabled} from '../../utils/features';
import {FEATURES} from '../../constants';

const TemplateSelect = (props) => {
  const {t} = useTranslation(['forms', 'add-items']);
  const [field, meta] = useField(props);
  const {setFieldValue} = useFormikContext();
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [maxCharactersError, setMaxCharactersError] = useState('');
  const stores = useStores();
  const pills = props.selectedTemplates.map((template) => template.value);
  const hasError = maxCharactersError !== '';
  const {searchAlgoliaTemplates, searchIndexApi} =
    useAlgoliaSearchTemplate(props);
  const {searchApiTemplates} = useApiSearchTemplate(props);
  const acceptCustomItems = isFeatureEnabled(
    FEATURES.acceptCustomItems,
    stores.app.features,
  );

  const handleTemplateSearch = async (value) => {
    if (maxCharactersError) return;

    setIsLoadingTemplates(true);

    let availableTemplates = [];

    if (acceptCustomItems) {
      availableTemplates = props.kinds.map((kind) => {
        return {
          id: null,
          label: `${t(
            `add-items:template-select.new-selection-prefix-${props.side}-${kind}`,
          )} ${value}`,
          value: value,
          kind: kind,
        };
      });
    }

    const matchTemplates = stores.app.algolia.is_enabled
      ? await searchAlgoliaTemplates(value)
      : await searchApiTemplates(value);

    if (matchTemplates.length) {
      availableTemplates.unshift(...matchTemplates);
    }
    setTemplates(availableTemplates);

    // const availableTemplates = stores.app.algolia.is_enabled
    //   ? await searchAlgoliaTemplates(value)
    //   : await searchApiTemplates(value);

    // setTemplates(availableTemplates);
    setIsLoadingTemplates(false);
  };

  const handleInputChange = (value) => {
    if (value.length > 25) {
      setMaxCharactersError(t('forms:errors.string.max', {max: 25}));
      return;
    }
    setMaxCharactersError('');
  };

  const handleTemplateSelect = (selectedTemplates) => {
    if (selectedTemplates.length > 0 && stores.app.algolia.is_enabled) {
      const lastAdded = selectedTemplates[selectedTemplates.length - 1];
      searchIndexApi.registerClick(lastAdded.objectId, lastAdded.position);
    }
    setFieldValue(field.name, selectedTemplates);
  };

  const handleRemoveSelectedTemplate = (index) => {
    const updatedSelectedTemplates = [...props.selectedTemplates];
    updatedSelectedTemplates.splice(index, 1);

    setFieldValue(field.name, updatedSelectedTemplates);
  };

  return (
    <React.Fragment>
      <div className={styles.fieldContainer}>
        <Form.Group controlId="item_template_id">
          <Typeahead
            multiple
            useCustomPills
            // selectHintOnEnter
            selectPartialMatchOnEnter
            labelKey="label"
            options={templates}
            id="item_template_id"
            onSearch={handleTemplateSearch}
            isLoading={isLoadingTemplates}
            onChange={handleTemplateSelect}
            selected={props.selectedTemplates}
            placeholder={props.placeholder}
            onInputChange={handleInputChange}
            isInvalid={hasError}
            open={hasError ? false : undefined}
            error={maxCharactersError}
            data-hj-allow
            filterBy={() => true}
          />
          <FeedbackInvalid meta={meta} />
        </Form.Group>
      </div>
      <div className={styles.pills}>
        <Pills pills={pills} onPillRemove={handleRemoveSelectedTemplate} />
      </div>
    </React.Fragment>
  );
};

export default TemplateSelect;
