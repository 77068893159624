import React from 'react';
import {useField} from 'formik';
import {useRemoveError, useTranslatedError} from '../../hooks/formik';

import Label from '../Form/Label';
import ReadOnlyField from './ReadOnlyField';
import Input from '../Form/Input';

const InputField = (props) => {
  const [field, meta] = useField(props);
  const {removeError} = useRemoveError(field.name, field.onChange);
  const {
    label,
    readOnly,
    inlineLabel,
    isRequired,
    withoutTextStyle,
    onChange,
    format,
    ...rest
  } = props;

  const error = useTranslatedError(meta.error) || meta.error;

  if (readOnly) {
    if (!field.value) return null;

    const value = getReadOnlyValue(
      field.value,
      inlineLabel,
      rest.labelPosition,
      format,
    );

    return (
      <ReadOnlyField
        label={label}
        value={value}
        error={error}
        withoutTextStyle={withoutTextStyle}
      />
    );
  }
  const inputOnChange = (e) => {
    removeError(e);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <React.Fragment>
      {label && <Label isRequired={isRequired}>{label}</Label>}
      <Input
        {...field}
        {...rest}
        error={error}
        isInvalid={!!meta.error && meta.touched}
        inlineLabel={inlineLabel}
        onChange={inputOnChange}
      />
    </React.Fragment>
  );
};

function getReadOnlyValue(value, inlineLabel, labelPosition = 'right', format) {
  if (!inlineLabel) return value;

  return `${labelPosition === 'left' ? inlineLabel : ''}
    ${format ? format(value) : value}
    ${labelPosition === 'right' ? inlineLabel : ''}
  `;
}

export default InputField;
