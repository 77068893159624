import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {getInitials} from '../../../utils';
import ResponsiveImg from '../../../components/ResponsiveImg';
import InformationField from '../../MemberPage/InformationField';
import Subtitle from '../../../components/Subtitle';
import {ExtraAttributeDetails} from '../../../components/ExtraAttribute';
import Avatar from '../../../components/Avatar';
import Title from '../../../components/Title';
import {observer} from 'mobx-react';
import {useStores} from '../../../contexts/StoresContext';

const FieldWrapper = styled.div`
  padding-left: 10px;
`;

const UserAvatar = styled(Avatar)`
  margin-bottom: 20px;
`;

const LocationWrapper = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.h3`
  font-size: 1rem;
  font-weight: bold;
`;

const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #e9e5e5;
`;

const OrgInformation = (props) => {
  const {org} = props;
  const stores = useStores();
  const clientFocusAreaTags = stores.app.clientData.focus_area_tags;
  const {t} = useTranslation(['member-page', 'common']);
  const orgNameInitials = org ? getInitials(org.name) : null;

  const orgImage = org.logo ? (
    <ResponsiveImg src={org.logo} />
  ) : (
    orgNameInitials
  );

  const mapAddresses = (address) => {
    let place = address.place;
    if (!address.place) {
      place = address.city ? `${address.city}, ` : '';
      place += address.state ? `${address.state}, ` : '';
      place += address.country ? `${address.country}` : '';
    }

    return <AddressWrapper key={address.id}>{place}</AddressWrapper>;
  };

  const categories = org.categories.reduce(
    (categories, category) => ({
      ...categories,
      [category.focus_area_type]: [
        ...categories[category.focus_area_type],
        category,
      ],
    }),
    {
      sdg: [],
      general: [],
    },
  );

  const mapCategories = (category) => <p key={category.id}>{category.name}</p>;

  return (
    <React.Fragment>
      <Title>{t('member-page:titles.org-information', {org: org.name})}</Title>
      <UserAvatar>{orgImage}</UserAvatar>
      <FieldWrapper>
        <InformationField
          label={t('member-page:label.description')}
          description={org.description}
        />
        {org.addresses.length !== 0 && (
          <LocationWrapper>
            <Label>{t('member-page:label.location')}</Label>
            {org.addresses.map(mapAddresses)}
          </LocationWrapper>
        )}
        <InformationField
          label={t('member-page:label.organization-type')}
          description={t(`common:organization.${org.org_kind}`)}
        />
        {org.org_kind === 'govorg' && (
          <InformationField
            label={t('member-page:label.level-of-government')}
            description={t(
              `common:government-level.${org.level_of_government}`,
            )}
          />
        )}
        {(org.org_kind === 'fporg' || org.org_kind === 'nporg') && (
          <React.Fragment>
            <InformationField
              label={t('member-page:label.tax-number')}
              description={org.tax_identification}
            />
            {org.org_kind === 'nporg' && (
              <InformationField
                label={t('member-page:label.charity')}
                description={
                  org.is_registered
                    ? t('member-page:options.yes')
                    : t('member-page:options.no')
                }
              />
            )}
            {clientFocusAreaTags === 'enabled' && (
              <React.Fragment>
                <Label>{t('member-page:label.focus-area')}</Label>
                {categories.general.map(mapCategories)}
                {org.other_focus_area && <p>{org.other_focus_area}</p>}
                <Label>{t('member-page:label.sdg')}</Label>
                {categories.sdg.map(mapCategories)}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </FieldWrapper>
      <Subtitle>{t('member-page:form.subtitle.contact-information')}</Subtitle>
      <FieldWrapper>
        <InformationField
          label={t('member-page:label.website')}
          description={org.website || t('member-page:error.no-website')}
        />
        <InformationField
          label={t('member-page:label.phone')}
          description={org.phone || t('member-page:error.no-phone')}
        />
        <InformationField
          label={t('member-page:label.email')}
          description={org.phone || t('member-page:error.no-email')}
        />
      </FieldWrapper>
      <Subtitle>{t('member-page:form.subtitle.privacy')}</Subtitle>
      <FieldWrapper>
        <InformationField
          label={t('member-page:label.share-my', {
            field: t('member-page:label.phone'),
          })}
          description={t(`common:privacy.${org.setting_phone}`)}
        />
        <InformationField
          label={t('member-page:label.share-my', {
            field: t('member-page:label.email'),
          })}
          description={t(`common:privacy.${org.setting_email}`)}
        />
        <InformationField
          label={t('member-page:label.share-my', {
            field: t('member-page:label.address'),
          })}
          description={t(`common:privacy.${org.setting_address}`)}
        />
      </FieldWrapper>
      {org.extra_attribute && (
        <React.Fragment>
          <Subtitle>{t('member-page:form.subtitle.extra-attributes')}</Subtitle>
          <ExtraAttributeDetails
            modelName={org.org_kind}
            extraAttribute={org.extra_attribute}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default observer(OrgInformation);
