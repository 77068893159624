import React from 'react';
import {Modal} from 'react-bootstrap';

const DialogV2 = (props) => {
  const {title, show, onHide, footer, children, className, noHeader} = props;
  const Footer = footer;

  return (
    <Modal show={show} onHide={onHide} backdrop="static" className={className}>
      {!noHeader && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      {footer && (
        <Modal.Footer>
          <Footer />
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default DialogV2;
