import React from 'react';
import {useShouldHideField} from '../hooks/shouldHideField';

import ChoiceMultiple from './ChoiceMultiple';
import Choice from './Choice';

const ChoiceField = (props) => {
  const {multiple, visible, ...rest} = props;
  const {hideField} = useShouldHideField(props);

  if (hideField) {
    return null;
  }

  const Component = multiple ? ChoiceMultiple : Choice;
  return props.choices.length ? <Component {...rest} /> : null;
};

export default ChoiceField;
