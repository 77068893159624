import {createContext, useContext, useReducer, useMemo, useEffect} from 'react';
import {Api} from '../pages/MapPage/api';
import {useSession} from './SessionContext';
import {DEFAULT_CENTER, DEFAULT_ZOOM} from '../pages/SearchPage/constants';

const initialState = {
  bounds: null,
  center: DEFAULT_CENTER,
  zoom: DEFAULT_ZOOM,
};

const MapAlgoliaContext = createContext();

const types = {
  SET_BOUNDS: 'SET_BOUNDS',
  SET_CENTER_ZOOM: 'SET_CENTER_ZOOM',
  SET_ZOOM: 'SET_ZOOM',
  SET_PLACE: 'SET_PLACE',
};

const reducer = (state, action) => {
  const {type, payload} = action;

  switch (type) {
    case types.SET_BOUNDS: {
      return {
        ...state,
        bounds: payload,
      };
    }
    case types.SET_CENTER_ZOOM: {
      return {
        ...state,
        zoom: payload.zoom,
        center: payload.center,
      };
    }
    case types.SET_ZOOM: {
      return {
        ...state,
        zoom: payload.zoom,
      };
    }
    case types.SET_PLACE: {
      return {
        ...state,
        center: payload.center,
        bounds: payload.bounds,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const MapAlgoliaProvider = ({children}) => {
  const [session, sessionActions] = useSession();
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const actions = useMemo(() => {
    return {
      setBounds: (bounds) => {
        dispatch({
          type: types.SET_BOUNDS,
          payload: bounds,
        });
      },
      setCenterZoom: (center, zoom) => {
        dispatch({
          type: types.SET_CENTER_ZOOM,
          payload: {
            center,
            zoom,
          },
        });
      },
      setZoom: (zoom) => {
        dispatch({
          type: types.SET_ZOOM,
          payload: {
            zoom,
          },
        });
      },
      setPlace: (place) => {
        if (!place) return;

        const location = place.geometry.location;
        dispatch({
          type: types.SET_PLACE,
          payload: {
            center: {lat: location.lat(), lng: location.lng()},
            bounds: place.geometry.viewport,
          },
        });
      },
    };
  }, [dispatch]);

  const api = useMemo(() => {
    return new Api(session.token);
  }, [session.token]);

  useEffect(() => {
    const fetchSession = async () => {
      console.info(
        `[NL] initializing ${session.token ? '(private)' : '(public)'}`,
      );

      try {
        const payload = await api.getSession();
        sessionActions.setSession(session.token, payload);
        if (
          !session.token ||
          (!payload.client_data.map_default_location?.location &&
            !payload.organization.location?.location)
        ) {
          const ip = await api.getIpLocation();
          sessionActions.setLocationByIp(ip);
        }
        sessionActions.init();
      } catch (e) {
        if (e.getCode() === 'authentication_failed') {
          sessionActions.unsetSession();
        }
      }
    };

    if (!session.loaded) {
      fetchSession();
    }
  }, [api, session.loaded, session.token, sessionActions]);

  return (
    <MapAlgoliaContext.Provider value={[state, actions]}>
      {children}
    </MapAlgoliaContext.Provider>
  );
};

const useMapAlgoliaStore = () => {
  const context = useContext(MapAlgoliaContext);
  if (context === undefined) {
    throw new Error(
      '`useMapAlgoliaStore` must be used within a `MapAlgoliaContext`.',
    );
  }
  return context;
};

export {MapAlgoliaContext, MapAlgoliaProvider, useMapAlgoliaStore};
