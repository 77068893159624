import styles from './ItemPanel.module.scss';

const Division = ({className, children}) => (
  <div
    className={
      className ? `${styles.division} ${className}` : `${styles.division}`
    }>
    {children}
  </div>
);

export default Division;
