import React, {useMemo} from 'react';
import styled from 'styled-components';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {Col} from 'react-bootstrap';
import {useErrorFocus} from '../../hooks/formik';
import {useStores} from '../../contexts/StoresContext';
import {createTranslationKey} from '../../utils/i18n';
import Form from '../ItemForm/Form';
import Footer from '../ItemForm/Footer';
import Button from '../Button';
import {
  CategorySelectField,
  InputField,
  CheckboxField,
  RadioField,
  TextAreaField,
} from '../Fields';
import DatePickerField from '../Fields/DatePickerField';
import {useAvailableForOptions} from '../../hooks/utils';

const BottomAlignedGroup = styled(Form.Group)`
  ${(props) =>
    props.readOnly
      ? `
    display: flex;
    align-items: flex-end;
  `
      : ''};
`;

const OfferDiscountForm = (props) => {
  const {values, errors, setFieldValue, handleSubmit, isSubmitting, item} =
    props;

  const focusOnErrorFields = [
    'category',
    'percentage',
    'textCode',
    'availableFor',
    'description',
    'contactName',
  ];
  const fieldRefs = {
    category: null,
    percentage: null,
    textCode: null,
    availableFor: null,
    description: null,
    contactName: null,
  };

  const {t} = useTranslation('forms');
  const setFieldRef = (field) => (ref) => (fieldRefs[field] = ref);
  const isDone = useMemo(
    () => item.status === 'done' || moment(item.availableUntil).isBefore(),
    [item.status, item.availableUntil],
  );
  const isInProgress = item.status === 'in_progress';
  const isEditable =
    item.status === 'open' || item.status === 'on_hold' || isInProgress;
  const availableForOptions = useAvailableForOptions();

  useErrorFocus(isSubmitting, errors, focusOnErrorFields, fieldRefs);

  const handleSetExpiration = (event) => {
    const checked = event.target.checked;
    setFieldValue('setExpiration', event.target.checked);

    if (!checked) {
      setFieldValue('availableUntil', '');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <CategorySelectField
            name="category"
            setRef={setFieldRef('category')}
            label={t('labels.category')}
            readOnly={isDone}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={6}>
          <InputField
            name="percentage"
            placeholder={t('labels.discount.offer.item-percentage-placeholder')}
            setRef={setFieldRef('percentage')}
            label={t('labels.discount.offer.item-percentage')}
            readOnly={isDone || isInProgress}
            isRequired
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          <InputField
            name="textCode"
            placeholder={t('labels.discount.offer.item-text-code-placeholder')}
            setRef={setFieldRef('textCode')}
            label={t('labels.discount.offer.item-text-code')}
            readOnly={isDone || isInProgress}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <TextAreaField
            name="description"
            rows="6"
            placeholder={t(
              'labels.discount.offer.item-description-placeholder',
            )}
            label={t('labels.item-description')}
            setRef={setFieldRef('description')}
            readOnly={isDone}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="contactName"
            setRef={setFieldRef('contactName')}
            label={t('labels.discount.offer.item-contact-name-label')}
            readOnly={isDone}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <BottomAlignedGroup as={Col} sm={6} className="form-group">
          <CheckboxField
            custom
            name="setExpiration"
            label={t('labels.discount.offer.set-expiration')}
            onChange={handleSetExpiration}
            readOnly={isDone}
          />
        </BottomAlignedGroup>
        {values.setExpiration && (
          <Form.Group as={Col} sm={6}>
            <DatePickerField
              name="availableUntil"
              label={t('labels.discount.offer.item-available-until')}
              disabled={!values.setExpiration}
              minDate={new Date()}
              showDisabledMonthNavigation
              placeholder={t(
                'labels.discount.offer.item-available-until-placeholder',
              )}
              readOnly={isDone}
            />
          </Form.Group>
        )}
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="externalURL"
            placeholder={t(
              'labels.discount.offer.item-external-url-placeholder',
            )}
            label={t('labels.discount.offer.item-external-url')}
            readOnly={isDone}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <RadioField
            name="availableFor"
            label={t('labels.item-available-for')}
            options={availableForOptions}
            setRef={setFieldRef('availableFor')}
            readOnly={isDone}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="phoneNumber"
            label={t('labels.phone-number')}
            readOnly={isDone || isInProgress}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="contactEmail"
            label={t('labels.email')}
            readOnly={isDone || isInProgress}
          />
        </Form.Group>
      </Form.Row>
      <Footer>
        {!isDone && (
          <Button type="submit" variant="primary" className="text-uppercase">
            {isEditable
              ? t('buttons.offer.edit-offer')
              : t('buttons.offer.post-offer')}
          </Button>
        )}
      </Footer>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'OfferDiscountForm',
  validationSchema: yup.object().shape({
    category: yup.string().required().nullable(),
    percentage: yup
      .number()
      .required()
      .max(100, createTranslationKey('errors.percentage'))
      .nullable(),
    textCode: yup.string().required().max(50).nullable(),
    setExpiration: yup.bool(),
    availableUntil: yup
      .date()
      .min(new Date())
      .nullable()
      .when('setExpiration', {
        is: true,
        then: yup.date().min(new Date()).nullable().required(),
      }),
    availableFor: yup.string().required(),
    contactName: yup.string().max(255).required(),
    phoneNumber: yup.string().max(30).nullable(),
    contactEmail: yup.string().email(),
    externalURL: yup
      .string()
      .url(createTranslationKey('errors.url'))
      .max(512)
      .nullable(),
    description: yup.string().required().nullable(),
  }),
  mapPropsToValues: (props) => {
    const item = props.item;
    const {user} = props.stores.app;
    const userFullName = `${user.first_name || ''} ${user.last_name || ''}`;

    return {
      category: item.category ? item.category.id : '',
      percentage: item.percentage || '',
      textCode: item.textCode || '',
      setExpiration: !!item.availableUntil,
      availableUntil: item.availableUntil || '',
      availableFor: item.availableFor || 'any',
      contactName: item.contactName || userFullName,
      externalURL: item.externalURL || '',
      description: item.description || '',
      phoneNumber: item.phoneNumber
        ? item.phoneNumber
        : user.phone_number || null,
      contactEmail: item.contactEmail || '',
    };
  },
  handleSubmit: async (values, {props, setSubmitting, setErrors}) => {
    try {
      await props.onSubmit(values);
      setSubmitting(false);
    } catch (error) {
      if (
        error.category_id ||
        error.percentage ||
        error.description ||
        error.contact_name ||
        error.available_for ||
        error.code
      ) {
        const {toast} = props.stores;
        toast.toasts.forEach(
          ({id, type}) => type !== 'testing' && props.stores.removeToast(id),
        );
        setErrors({
          category: error.category_id ? 'Required' : undefined,
          percentage: error.percentage ? 'Required' : undefined,
          description: error.description ? 'Required' : undefined,
          contactName: error.contact_name ? 'Required' : undefined,
          availableFor: error.available_for ? 'Required' : undefined,
          textCode: error.code ? 'Required' : undefined,
        });
      }
      setSubmitting(false);
    }
  },
})(OfferDiscountForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  return <EnhancedForm stores={stores} {...props} />;
};

export default observer(FormWrapper);
