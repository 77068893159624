import React, {useRef, useEffect, useContext} from 'react';
import styled from 'styled-components';

import {Form} from 'react-bootstrap';
import theme from '../../theme';
import ErrorMessage from './ErrorMessage';
import FormContext from './FormContext';

const Option = styled(({isHorizontal, horizontalMargin, ...rest}) => (
  <div {...rest} />
))`
  display: flex;
  ${(props) => (props.horizontalMargin ? 'margin-right: 40px;' : '')}
`;

const Label = styled(Form.Label)`
  cursor: pointer;
  display: flex;
  line-height: 22px;
`;

const RadioIcon = styled.span`
  border: 2px solid rgba(1, 1, 1, 0.54);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 9px;

  ~ input:checked {
    background-color: black;
  }
`;

const Circle = styled.span`
  background-color: ${theme.primaryColor};
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: none;
`;

const StyledRadio = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  :checked ~ ${RadioIcon} {
    border-color: ${theme.primaryColor};

    ${Circle} {
      display: block;
    }
  }
`;

const Wrapper = styled(({isHorizontal, horizontalMargin, ...rest}) => (
  <Form.Control as="div" {...rest} />
))`
  flex-direction: ${(props) => (props.isHorizontal ? 'row' : 'column')};
  ${(props) => (props.horizontalMargin ? '' : 'justify-content: space-around;')}

  &.form-control {
    height: auto;
    border: none;
    display: flex;

    &.is-invalid {
      background: none;
    }
  }
`;

const Radio = (props) => {
  const {
    setRef,
    label,
    name,
    isHorizontal,
    horizontalMargin,
    children,
    options,
    error,
    isInvalid,
    ...rest
  } = props;
  const {setFieldRef} = useContext(FormContext);
  const inputRef = useRef(null);

  useEffect(() => {
    if (setRef) {
      setRef(inputRef);
    }
  }, [setRef, inputRef]);

  useEffect(() => {
    setFieldRef(name, inputRef);
  }, [name, inputRef, setFieldRef]);

  const renderOption = (option, index) => {
    return (
      <React.Fragment key={index}>
        <Option horizontalMargin={horizontalMargin} key={index}>
          <Label>
            <StyledRadio
              {...rest}
              type="radio"
              name={name}
              value={option.value}
              checked={option.value === props.value}
              ref={index === 0 ? inputRef : undefined}
            />
            <RadioIcon>
              <Circle />
            </RadioIcon>
            {option.label}
          </Label>
        </Option>
        {option.value === props.value &&
          option.renderOptionContent &&
          option.renderOptionContent()}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Wrapper
        isHorizontal={isHorizontal}
        horizontalMargin={horizontalMargin}
        isInvalid={isInvalid}
      >
        {options.map(renderOption)}
      </Wrapper>
      <ErrorMessage>{error}</ErrorMessage>
    </React.Fragment>
  );
};

export default Radio;
