import React, {useState} from 'react';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {Col} from 'react-bootstrap';
import {useStores} from '../../../contexts/StoresContext';
import {useErrorFocus} from '../../../hooks/formik';
import {getYesNoOptions} from '../../../constants';
import {normalizeFloatNumber} from '../../../utils';
import {useLocaleNumber} from '../../../hooks/number';
import {useAvailableForOptions} from '../../../hooks/utils';
import {marketValueValidation} from '../../../utils/yupCustomFunctions';
import Form from '../../ItemForm/Form';
import Footer from '../../ItemForm/Footer';
import BundleConstructor from '../../BundleConstructor/BundleConstructor';
import {
  CategorySelectField,
  InputField,
  RadioField,
  TextAreaField,
  ImageUploadField,
  AddressSelectField,
  SelectField,
} from '../../Fields';
import DatePickerField from '../../Fields/DatePickerField';
import TagSelectField from '../../Fields/TagSelectField';
import Button from '../../Button';
import styles from './OfferSupplyForm.module.scss';

const OfferSupplyForm = (props) => {
  const {values, errors, setFieldValue, isSubmitting, item, handleSubmit} =
    props;
  const localeNumber = useLocaleNumber();
  const {t} = useTranslation(['forms', 'add-items']);
  const specialHandlingOptions = props.stores.app.choices.specialHandling;
  const {uom} = props.stores.app.choices;
  const baseUom = uom.find((uom) => uom.id === parseInt(values.baseUom));
  const bundleUom = uom.find(
    (uom) => uom.id === parseInt(values.bundle.uom.id),
  );
  const yesNoOptions = getYesNoOptions();
  const availableForOptions = useAvailableForOptions();

  const focusOnErrorFields = [
    'itemType',
    'category',
    'quantity',
    'bundle.units',
    'specialHandlingValue',
    'customSpecialHandlingValue',
    'deliveryOptions',
    'coverShippingCost',
    'pickupLocation',
    'availableFor',
    'size',
    'marketValue',
  ];

  const fieldRefs = {
    itemType: null,
    category: null,
    quantity: null,
    'bundle.units': null,
    deliveryOptions: null,
    coverShippingCost: null,
    pickupLocation: null,
    availableFor: null,
    specialHandlingValue: null,
    customSpecialHandlingValue: null,
    size: null,
    marketValue: null,
  };

  const isDone = item.status === 'done';
  const isInProgress = item.status === 'in_progress';
  const isEditable =
    item.status === 'open' || item.status === 'on_hold' || isInProgress;
  const isMarketValueEditable =
    item.status === 'complete' ||
    item.status === 'incomplete' ||
    item.status === 'open';

  const shouldShowField = (isFieldEdited) => {
    return (!isDone && !isInProgress) || isFieldEdited;
  };

  const [primaryAddress, setPrimaryAddress] = useState('');
  useErrorFocus(isSubmitting, errors, focusOnErrorFields, fieldRefs);

  const setFieldRef = (field) => (ref) => {
    fieldRefs[field] = ref;
  };

  const handleFieldRefs = (refs) => {
    fieldRefs.quantity = refs.quantity;
    fieldRefs['bundle.units'] = refs['bundle.units'];
  };

  const handlePrimaryAddress = (addresses, defaultDeliveryAddress) => {
    const addressToSelect = defaultDeliveryAddress
      ? defaultDeliveryAddress
      : addresses[0];

    if (!values.pickupLocation)
      setFieldValue('pickupLocation', addressToSelect.id);
    setPrimaryAddress(addressToSelect.id);
  };

  const handleDeliveryOptionsChange = (event) => {
    const choice = event.target.value;
    setFieldValue('deliveryOptions', choice);
    if (choice === 'deliver') {
      setFieldValue('pickupLocation', '');
    } else {
      setFieldValue('pickupLocation', primaryAddress);
      setFieldValue('coverShippingCost', '');
    }
  };

  const handleSpecialHandlingChange = (event) => {
    setFieldValue('specialHandling', event.target.value === 'true');
    setFieldValue('specialHandlingValue', '');
  };

  const renderShippingCostOptions = () => {
    const readOnly = isDone || isInProgress;
    const field = (
      <RadioField
        name="coverShippingCost"
        options={yesNoOptions}
        label={t('forms:labels.supply.offer.item-cover-shipping-cost')}
        setRef={setFieldRef('coverShippingCost')}
        readOnly={readOnly}
        isHorizontal
        horizontalMargin
        booleanOptions
      />
    );

    if (isDone || isInProgress)
      return (
        <Form.Row>
          <Form.Group className={styles.customCol} as={Col} sm={12}>
            {field}
          </Form.Group>
        </Form.Row>
      );

    return (
      <Form.Group as={Col} sm={12}>
        {field}
      </Form.Group>
    );
  };

  const renderSuppliesLocation = () => {
    const readOnly = isDone || isInProgress;
    const field = (
      <React.Fragment>
        <AddressSelectField
          name="pickupLocation"
          label={t('forms:labels.supply.offer.item-location-supplies')}
          onAddressesLoaded={handlePrimaryAddress}
          setRef={setFieldRef('pickupLocation')}
          readOnly={readOnly}
          isFullWidth={false}
          notEditAddress
          notDeleteAddress
        />
      </React.Fragment>
    );

    if (readOnly) {
      return (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            {field}
          </Form.Group>
        </Form.Row>
      );
    }

    return (
      <Form.Group as={Col} sm={12}>
        {field}
      </Form.Group>
    );
  };

  const deliveryOptions = [
    {
      label: t('forms:fields.we-can-deliver'),
      value: 'deliver',
      renderOptionContent: renderShippingCostOptions,
    },
    {
      label: t('forms:fields.pick-up-at-our-location'),
      value: 'pickUp',
      renderOptionContent: renderSuppliesLocation,
    },
  ];

  const itemTypeOptions = [
    {
      label: t('forms:fields.item-free'),
      value: 'free',
    },
    {
      label: t('forms:fields.item-for-purchase'),
      value: 'for_purchase',
    },
  ];

  const renderSpecialHandlingOptions = ([id, label]) => {
    return (
      <option value={id} key={id}>
        {label}
      </option>
    );
  };

  const bundleUOM =
    (item.itemTemplate && values.bundle.uom.id) ||
    values.deliveryInBundles === 'preferred'
      ? bundleUom
        ? bundleUom.name
        : ''
      : baseUom
      ? baseUom.name
      : '';

  const handleOnBundleChange = (bundle) => {
    setFieldValue('bundle', bundle);
    setFieldValue('marketValue', bundle.price);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <RadioField
            name="itemType"
            label={t('forms:labels.supply.offer.type')}
            options={itemTypeOptions}
            readOnly={isDone || isInProgress}
            setRef={setFieldRef('itemType')}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <RadioField
            name="deliveryOptions"
            label={t('forms:labels.supply.offer.item-delivery-options')}
            options={deliveryOptions}
            onChange={handleDeliveryOptionsChange}
            setRef={setFieldRef('deliveryOptions')}
            readOnly={isDone || isInProgress}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      {!item.itemTemplate && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <CategorySelectField
              name="category"
              label={t('forms:labels.category')}
              kind="supply"
              setRef={setFieldRef('category')}
              readOnly={isDone || isInProgress}
              isRequired
            />
          </Form.Group>
        </Form.Row>
      )}
      <BundleConstructor
        item={item}
        values={values}
        setFieldValue={setFieldValue}
        setFieldsRefs={handleFieldRefs}
        onBundleChange={handleOnBundleChange}
      />
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="marketValue"
            label={
              item.currency === props.stores.app.currency.id
                ? t('forms:labels.supply.offer.item-market-value', {
                    uom: bundleUOM.charAt(0).toUpperCase() + bundleUOM.slice(1),
                  })
                : t(
                    'forms:labels.supply.offer.item-market-value-different-currency',
                    {
                      uom:
                        bundleUOM.charAt(0).toUpperCase() + bundleUOM.slice(1),
                      currency: props.stores.app.currency.code,
                    },
                  )
            }
            placeholder={t(
              'forms:labels.supply.offer.item-market-value-placeholder',
            )}
            setRef={setFieldRef('marketValue')}
            inlineLabel={`${
              props.stores.app.currency.code
            } / ${bundleUOM.toUpperCase()}`}
            readOnly={!isMarketValueEditable}
            format={localeNumber}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="contactName"
            label={t('forms:labels.supply.offer.item-contact-name-label')}
            placeholder={t('forms:labels.supply.offer.item-contact-name-label')}
            setRef={setFieldRef('contactName')}
            readOnly={isDone}
            isRequired
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={6}>
          <TagSelectField name="age" label={t('labels.age')} />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          <TagSelectField name="sex" label={t('labels.sex')} />
        </Form.Group>
      </Form.Row>
      {values.category && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <TagSelectField
              name="size"
              label={t('labels.size')}
              category={values.category}
            />
          </Form.Group>
        </Form.Row>
      )}
      {shouldShowField(!!values.availableUntil) && (
        <Form.Row>
          <Form.Group as={Col} sm={8}>
            <DatePickerField
              name="availableUntil"
              label={t('forms:labels.supply.offer.item-available-until')}
              minDate={new Date()}
              showDisabledMonthNavigation
              placeholder={t(
                'forms:labels.supply.offer.item-available-until-placeholder',
              )}
              readOnly={isDone}
              setRef={setFieldRef('availableUntil')}
            />
          </Form.Group>
        </Form.Row>
      )}
      {shouldShowField(values.isPallet !== null) && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <RadioField
              name="isPallet"
              label={t('forms:labels.supply.offer.item-pallet')}
              options={yesNoOptions}
              readOnly={isDone || isInProgress}
              booleanOptions
            />
          </Form.Group>
        </Form.Row>
      )}
      {shouldShowField(values.specialHandling !== null) && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <RadioField
              name="specialHandling"
              label={t('forms:labels.supply.offer.item-special-handling')}
              onChange={handleSpecialHandlingChange}
              options={yesNoOptions}
              readOnly={isDone}
            />
            {values.specialHandling && (
              <SelectField
                name="specialHandlingValue"
                setRef={setFieldRef('specialHandlingValue')}
                options={Object.entries(specialHandlingOptions).map(
                  ([id, label]) => ({id, label}),
                )}
                readOnly={isDone}>
                <option value="">{t('forms:fields.special-handling')}</option>
                {Object.entries(specialHandlingOptions).map(
                  renderSpecialHandlingOptions,
                )}
              </SelectField>
            )}
          </Form.Group>
        </Form.Row>
      )}
      {values.specialHandlingValue === 'other' && (
        <Form.Row>
          <Form.Group as={Col} sm={12}>
            <InputField
              name="customSpecialHandlingValue"
              placeholder={t(
                'forms:labels.supply.offer.item-custom-special-handling-placeholder',
              )}
              setRef={setFieldRef('customSpecialHandlingValue')}
              readOnly={isDone}
            />
          </Form.Group>
        </Form.Row>
      )}
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <RadioField
            name="availableFor"
            options={availableForOptions}
            label={t('forms:labels.item-available-for')}
            setRef={setFieldRef('availableFor')}
            readOnly={isDone}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <ImageUploadField
            name="image"
            label={t('forms:labels.item-images')}
            readOnly={isDone}
            noRequirementImage
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="phoneNumber"
            label={t('forms:labels.phone-number')}
            readOnly={isDone || isInProgress}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <InputField
            name="contactEmail"
            label={t('labels.email')}
            readOnly={isDone || isInProgress}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12}>
          <TextAreaField
            rows="6"
            name="description"
            placeholder={t(
              'forms:labels.supply.offer.item-description-placeholder',
            )}
            label={t('forms:labels.item-description')}
            readOnly={isDone}
          />
        </Form.Group>
      </Form.Row>
      <Footer>
        {!isDone && (
          <Button type="submit" variant="primary" className="text-uppercase">
            {isEditable
              ? t('forms:buttons.offer.edit-offer')
              : t('forms:buttons.offer.post-offer')}
          </Button>
        )}
      </Footer>
    </Form>
  );
};

const EnhancedForm = withFormik({
  displayName: 'OfferSupplyForm',
  mapPropsToValues: (props) => {
    const {item} = props;
    const bundles = item.itemTemplate ? item.itemTemplate.bundles : [];
    const emptyBundle = {
      id: null,
      units: 1,
      uom: {
        id: '',
      },
    };
    const userFullName = `${props.stores.app.user.first_name || ''} ${
      props.stores.app.user.last_name || ''
    }`;
    const deliveryOptions =
      item.coverShippingCost !== null
        ? 'deliver'
        : item.location
        ? 'pickUp'
        : '';
    const wasUpdated = !!item.quantity;
    const marketValue = bundles.length ? bundles[0].price : '';

    const ageTag = item.tag.find((tag) => tag.tag_type === 'age');
    const sexTag = item.tag.find((tag) => tag.tag_type === 'sex');
    const sizeTag = item.tag.find((tag) => tag.tag_type === 'size');

    return {
      baseUom: item.baseUom ? item.baseUom.id.toString() : '1',
      deliveryInBundles: item.deliveryInBundles ? 'preferred' : 'not_required',
      quantity: item.quantity || '',
      bundle: item.bundle.uom_id ? item.bundle : bundles[0] || emptyBundle,
      availableUntil: item.availableUntil || '',
      isPallet: item.isPallet === null ? null : item.isPallet,
      description: item.description || '',
      deliveryOptions: deliveryOptions,
      specialHandling:
        item.specialHandlingValue !== null ? true : wasUpdated ? false : null,
      specialHandlingValue: item.specialHandlingValue || '',
      customSpecialHandlingValue: item.customSpecialHandlingValue || '',
      coverShippingCost:
        item.coverShippingCost === null ? null : item.coverShippingCost,
      pickupLocation: item.location ? item.location.id : null,
      marketValue: item.marketValue || marketValue,
      contactName: item.contactName || userFullName,
      contactEmail: item.contactEmail || '',
      availableFor: item.availableFor || 'any',
      image: item.image ? item.image : null,
      category: item.category ? item.category.id : '',
      phoneNumber: item.phoneNumber
        ? item.phoneNumber
        : props.stores.app.user.phone_number || '',
      status: item.status,
      claimedQuantity: item.claimedQuantity || 0,
      itemType: item.itemType || '',
      age: ageTag ? ageTag.id : null,
      sex: sexTag ? sexTag.id : null,
      size: sizeTag ? sizeTag.id : null,
    };
  },
  validationSchema: yup.object().shape({
    baseUom: yup.string().required(),
    quantity: yup
      .number()
      .label('quantity')
      .required()
      .positive()
      .nullable()
      .when('status', {
        is: 'in_progress',
        then: yup
          .number()
          .required()
          .label('quantity')
          .min(yup.ref('claimedQuantity')),
      }),
    bundle: yup.object().shape({
      uom: yup.object().shape({
        id: yup.number(),
      }),
      units: yup.number().label('unit').required().positive(),
    }),
    contactEmail: yup.string().email(),
    availableUntil: yup.date().min(new Date()).nullable(),
    isPallet: yup.bool().nullable(),
    description: yup.string().nullable(),
    image: yup.object().nullable().nullable(),
    deliveryOptions: yup.string().required(),
    specialHandling: yup.string().nullable(),
    availableFor: yup.string().required(),
    itemType: yup.string().required(),
    marketValue: marketValueValidation().required().label('market-value'),
    contactName: yup.string().max(255).required(),
    category: yup.string().required().nullable(),
    phoneNumber: yup.string().max(30).nullable(),
    specialHandlingValue: yup
      .string()
      .when('specialHandling', {is: 'true', then: yup.string().required()})
      .nullable(),
    customSpecialHandlingValue: yup
      .string()
      .when('specialHandlingValue', {
        is: 'other',
        then: yup.string().required(),
      })
      .nullable(),
    coverShippingCost: yup
      .string()
      .when('deliveryOptions', {is: 'deliver', then: yup.string().required()})
      .nullable(),
    pickupLocation: yup
      .number()
      .when('deliveryOptions', {
        is: 'pickUp',
        then: yup.number().required().integer(),
      })
      .nullable(),
    age: yup.number().nullable(),
    sex: yup.number().nullable(),
    size: yup.number().nullable(),
  }),
  handleSubmit: async (values, {props, setSubmitting, setErrors}) => {
    const uomId = values.bundle.uom.id ? values.bundle.uom.id : values.baseUom;
    const data = {
      ...values,
      marketValue: normalizeFloatNumber(values.marketValue),
      bundle: {
        ...values.bundle,
        uom: {
          ...values.bundle.uom,
          id: uomId,
        },
      },
    };

    if (!props.item.itemTemplate && data.marketValue)
      data.bundle.price = data.marketValue;
    data.bundle.uom_id = parseInt(data.bundle.uom.id);

    try {
      await props.onSubmit(data);
      setSubmitting(false);
    } catch (error) {
      if (error.quantity || error.market_value) {
        const {toast} = props.stores.app;
        toast.toasts.forEach(
          ({id, type}) =>
            type !== 'testing' && props.stores.app.removeToast(id),
        );
        setErrors({
          quantity: error.quantity ? 'Required' : undefined,
          marketValue: error.market_value ? 'Required' : undefined,
        });
      }
      setSubmitting(false);
    }
  },
})(OfferSupplyForm);

// @FIXME NL-1598 (verify)
const FormWrapper = (props) => {
  const stores = useStores();
  return <EnhancedForm stores={stores} {...props} />;
};

export default observer(FormWrapper);
