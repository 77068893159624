import React, {useEffect} from 'react';
import {useGeoSearch} from 'react-instantsearch';
import {useSearchAlgoliaStore} from '../../../../contexts/SearchAlgoliaContext';
import {ListItems} from '../ListItems';
import {MapAlgoliaContainer} from '../MapAlgoliaContainer';
import {useMapAlgoliaStore} from '../../../../contexts/MapAlgoliaContext';

export const SearchItems = () => {
  const {items, refine, clearMapRefinement} = useGeoSearch();
  const [mapAlgoliaState] = useMapAlgoliaStore();
  const [searchState] = useSearchAlgoliaStore();

  useEffect(() => {
    if (!mapAlgoliaState.bounds) {
      clearMapRefinement();
      return;
    }
    const ne = mapAlgoliaState.bounds.getNorthEast();
    const sw = mapAlgoliaState.bounds.getSouthWest();
    const algoliaBound = {
      northEast: {lat: ne.lat(), lng: ne.lng()},
      southWest: {lat: sw.lat(), lng: sw.lng()},
    };
    refine(algoliaBound);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapAlgoliaState.bounds]);

  return (
    <>
      {searchState.isMapView ? (
        <MapAlgoliaContainer />
      ) : (
        <ListItems items={items} />
      )}
    </>
  );
};
