import React from 'react';

const initialValue = {
  fieldsRefs: {},
  setFieldRef: () => {},
};

const FormContext = React.createContext(initialValue);

export default FormContext;
