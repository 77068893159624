import {observer} from 'mobx-react';
import React, {useEffect, useState, useCallback} from 'react';
import {useParams, Link, useRouteMatch, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {Card, Row, Col} from 'react-bootstrap';

import {mediaQueries} from '../../constants';
import {useApi} from '../../contexts/ApiContext';
import {useTranslation} from 'react-i18next';
import Avatar from '../../components/Avatar';
import ResponsiveImg from '../../components/ResponsiveImg';
import {getInitials} from '../../utils';
import InformationField from './InformationField';
import Title from '../../components/Title';
import {MEMBER_PAGE_EDIT, MEMBER_PAGE} from '../../urls';
import pencilIcon from '../../assets/icons/pencil.png';
import Route from '../../components/Layout/Route';
import EditMember from './EditMember';
import {useStores} from '../../contexts/StoresContext';
import {usePanelStore} from '../../contexts/PanelContext';
import Panel from '../../components/Panel';
import Toast from '../../components/Toast';

const Wrapper = styled.div`
  padding-top: 50px;
  margin-bottom: 180px;

  ${mediaQueries.lg} {
    padding-top: 0;
    margin-bottom: 30px;
  }
`;

const ContentWrapper = styled(Card)`
  padding: 0;
  &.card {
    border-radius: 0;
  }

  .card-header-tabs {
    border-bottom: 1px solid #e8e8e8;
    margin: 0;
    padding-bottom: 0;
  }

  ${mediaQueries.lg} {
    &.card {
      border-style: none;
      padding: 0;
    }
  }
`;

const Container = styled.div`
  padding: 50px 120px;

  ${mediaQueries.lg} {
    padding: 20px 15px;
  }
`;

const Label = styled.h3`
  font-size: 1rem;
  font-weight: bold;
`;

const MemberContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 50px 120px;

  ${mediaQueries.lg} {
    padding: 50px 15px;
  }
`;

const MemberName = styled(Title)`
  margin: 0 0 0 20px;
  ${mediaQueries.lg} {
    margin-top: 0;
  }
`;

const ColMemberInfo = styled(Col)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const HeaderTitle = styled.h2`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0;
`;

const ContactInfo = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #e9e5e5;
`;

const Block = styled.div`
  margin: 20px 0;
  ${mediaQueries.lg} {
    margin: 0;
  }
`;

const EditContainer = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
`;

const PencilIcon = styled(ResponsiveImg)`
  width: 14px;
  margin-left: 5px;
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  color: #1877f2;
`;

const EditLogoLink = styled(Link)`
  border-radius: 90px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 1px;
`;

const MembersPage = () => {
  const {slug} = useParams();
  const api = useApi();
  const match = useRouteMatch();
  const history = useHistory();
  const {t} = useTranslation(['member-page', 'common']);
  const [org, setOrg] = useState();
  const orgNameInitials = org ? getInitials(org.name) : null;
  const stores = useStores();
  const {user, organization, clientData} = stores.app;
  const [, panelActions] = usePanelStore();

  const fetchOrg = useCallback(async () => {
    const response = await api.getOrg(slug);
    setOrg(response);
  }, [slug, api]);

  useEffect(() => {
    fetchOrg();
  }, [fetchOrg]);

  if (!org) return null;

  const enableEdit =
    user && (user.isAdmin || user.isOrgAdmin) && organization.id === org.id;

  const getPrimaryAddress = () => {
    const primaryAddress = org.location_primary;

    let place = primaryAddress.place;
    if (org.setting_address === 'private') {
      place = primaryAddress.city ? `${primaryAddress.city}, ` : '';
      place += primaryAddress.state ? `${primaryAddress.state}, ` : '';
      place += primaryAddress.country ? `${primaryAddress.country}` : '';
    }

    return place;
  };

  let categoryText = '';
  if (org.other_focus_area !== null) {
    categoryText = `${org.other_focus_area}`;
  }

  const categories = org.categories.reduce((categoryText, category) => {
    if (!categoryText) return `${category.name}`;
    return `${categoryText}, ${category.name}`;
  }, categoryText);

  const orgImage = org.logo ? (
    <ResponsiveImg src={org.logo} />
  ) : (
    orgNameInitials
  );

  const renderColumn = (label, description, privacy) => {
    if (!description && privacy === 'public') return null;

    return (
      <Col sm={4}>
        <InformationField
          label={label}
          description={
            privacy === 'private' ? t('member-page:error.private') : description
          }
        />
      </Col>
    );
  };

  const renderTeamLeads = (user, index) => {
    return (
      <Row key={index}>
        {renderColumn(
          t('member-page:label.name'),
          user.name,
          org.setting_email,
        )}
        {renderColumn(
          t('member-page:label.email'),
          user.email,
          org.setting_email,
        )}
      </Row>
    );
  };

  const handleClosePanel = () => {
    history.goBack();
  };

  const handleEditSuccess = () => {
    history.push(MEMBER_PAGE.replace(':slug', slug));
    fetchOrg();
  };

  return (
    <React.Fragment>
      <Toast />
      <Wrapper>
        <MemberContainer>
          <EditLogoLink to={`${match.url}/edit`}>
            <Avatar click>{orgImage}</Avatar>
          </EditLogoLink>
          <MemberName>{org.name}</MemberName>
        </MemberContainer>
        <ContentWrapper>
          <Container>
            <Row>
              <ColMemberInfo>
                <HeaderTitle>{t('member-page:label.member-info')}</HeaderTitle>
                {enableEdit && (
                  <EditContainer
                    onClick={() => {
                      panelActions.setPanelMount(true);
                    }}>
                    <StyledLink to={`${match.url}/edit`}>
                      {t('member-page:edit.link')}
                    </StyledLink>
                    <PencilIcon src={pencilIcon} />
                  </EditContainer>
                )}
              </ColMemberInfo>
            </Row>
            <Row>
              <Col sm={6}>
                {org.description && (
                  <InformationField
                    label={t('member-page:label.description')}
                    description={org.description}
                  />
                )}
                <Block>
                  <Row>
                    <Col sm={6}>
                      <InformationField
                        label={t('member-page:label.organization-type')}
                        description={t(`common:organization.${org.org_kind}`)}
                      />
                    </Col>
                    {org.level_of_government && (
                      <Col sm={6}>
                        <InformationField
                          label={t('member-page:label.level-of-government')}
                          description={t(
                            `common:government-level.${org.level_of_government}`,
                          )}
                        />
                      </Col>
                    )}
                  </Row>
                  {(org.categories.length || org.other_focus_area) &&
                    clientData.focus_area_tags === 'enabled' && (
                      <Row>
                        <Col sm={12}>
                          <InformationField
                            label={t('member-page:label.focus-area')}
                            description={categories}
                          />
                        </Col>
                      </Row>
                    )}
                </Block>
                {(org.phone_number || org.email || org.web_site) && (
                  <Block>
                    <ContactInfo>
                      {t('member-page:label.contact-information')}
                    </ContactInfo>
                    <Row>
                      {renderColumn(
                        t('member-page:label.phone'),
                        org.phone_number,
                        org.setting_phone,
                      )}
                      {renderColumn(
                        t('member-page:label.email'),
                        org.email,
                        org.setting_email,
                      )}
                      {org.web_site && (
                        <Col sm={4}>
                          <InformationField
                            label={t('member-page:label.website')}
                            description={org.web_site}
                          />
                        </Col>
                      )}
                    </Row>
                  </Block>
                )}
                {org.setting_email === 'public' &&
                  org.org_admin_users.length > 0 && (
                    <Block>
                      <ContactInfo>
                        {t('member-page:label.team-leads')}
                      </ContactInfo>
                      {org.org_admin_users.map(renderTeamLeads)}
                    </Block>
                  )}
              </Col>
              {org.addresses && (
                <Col sm={6}>
                  <Label>{t('member-page:label.location')}</Label>
                  <AddressWrapper>
                    <div>{getPrimaryAddress()}</div>
                  </AddressWrapper>
                </Col>
              )}
            </Row>
          </Container>
          <Route path={[MEMBER_PAGE_EDIT]}>
            <Panel onClose={handleClosePanel}>
              <Route path={MEMBER_PAGE_EDIT}>
                {enableEdit && (
                  <EditMember
                    onEditSuccess={handleEditSuccess}
                    org={org}
                    enableEdit
                  />
                )}
              </Route>
            </Panel>
          </Route>
        </ContentWrapper>
      </Wrapper>
    </React.Fragment>
  );
};

export default observer(MembersPage);
