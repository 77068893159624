import React from 'react';
import styled from 'styled-components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Feedback from 'react-bootstrap/Feedback';

const ErrorIcon = styled(FontAwesomeIcon)`
  margin-right: 7px;
`;

const StyledFeedback = styled(({forceDisplay, ...rest}) => (
  <Feedback {...rest} />
))`
  &.invalid-feedback {
    display: ${(props) => (props.forceDisplay ? 'block' : 'none')};
  }
`;

const ErrorMessage = (props) => {
  const {children, forceDisplay} = props;
  return (
    <StyledFeedback type="invalid" forceDisplay={forceDisplay}>
      <ErrorIcon icon="exclamation-triangle" /> {children}
    </StyledFeedback>
  );
};

export default ErrorMessage;
