import React, {useState} from 'react';
import styles from './Export.module.scss';
import sharedStyles from './../shared.module.scss';
import {Form} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

const ExportDropdown = (props) => {
  const [includeTransactions, setIncludeTransactions] = useState(false);
  const {downloadItemReportCsv, downloadExportAllCsv} = props;
  const {t} = useTranslation('item-reports');

  const downloadCsv = () => {
    includeTransactions ? downloadExportAllCsv() : downloadItemReportCsv();
  };

  return (
    <div className={styles.dropdownContainer} id="exportDropdown">
      <Form.Check
        key={'seeTransactions'}
        label={t('labels.include-transactions')}
        type="checkbox"
        className={sharedStyles.checkbox}
        onChange={() => setIncludeTransactions(!includeTransactions)}
        checked={includeTransactions}
        id={'seeTransactions'}
      />
      <div className={styles.exportButtonContainer}>
        <div onClick={downloadCsv} className={styles.exportButton}>
          {t('buttons.export-csv')}
        </div>
      </div>
    </div>
  );
};

export default ExportDropdown;
