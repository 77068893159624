import {useApi} from '../../../contexts/ApiContext';

export function useApiSearchTemplate(props) {    
    const api = useApi();

    const searchApiTemplates = async (value) => {
        const response = await api.getItemTemplates(value, props.kinds);
        
        const availableTemplates = response.results.map((template) => {
            return {
                id: template.id,
                label: template.name,
                value: template.name,
                kind: template.kind,
            };
        });

        return availableTemplates
    }

    return {
        searchApiTemplates
    }
}
