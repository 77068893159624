import {useMemo, useCallback, useState} from 'react';
import algoliasearch from 'algoliasearch/lite';
import {useStores} from '../contexts/StoresContext';
import algoliaAnalitics from 'search-insights';
import {isFeatureEnabled} from '../utils/features';
import {FEATURES} from '../constants';
import {capitalizeString} from '../utils';

const useSearchIndex = () => {
  const store = useStores();
  const algolia = store.app.algolia;

  return useMemo(() => {
    const client = algoliasearch(
      window.ALGOLIA_APPLICATION_ID,
      algolia.search_api_key,
    );
    return client.initIndex(algolia.search_index);
  }, [algolia.search_api_key, algolia.search_index]);
};

const useSearchInsights = () => {
  const store = useStores();
  const algolia = store.app.algolia;

  return useMemo(() => {
    algoliaAnalitics('init', {
      appId: window.ALGOLIA_APPLICATION_ID,
      apiKey: algolia.search_api_key,
    });

    return algoliaAnalitics;
  }, [algolia.search_api_key]);
};

const useAvailableFor = () => {
  const store = useStores();
  const {organization, user} = store.app;

  const PostOnlyNonProfit = isFeatureEnabled(
    FEATURES.PostOnlyNonProfit,
    store.app.features,
  );

  const PostOnlyGoverment = isFeatureEnabled(
    FEATURES.PostOnlyGoverment,
    store.app.features,
  );
  return useMemo(() => {
    const result = ['any'];
    if (user === null) return result;
    if (
      !!PostOnlyNonProfit & (organization.org_kind === 'nporg') ||
      user.isAdmin
    ) {
      result.push('only_non_profit');
    }
    if (
      !!PostOnlyGoverment & (organization.org_kind === 'govorg') ||
      user.isAdmin
    ) {
      result.push('only_government');
    }

    return result;
  }, [PostOnlyNonProfit, PostOnlyGoverment, organization, user]);
};

const addArrayFacetFilter = (searchItemsParams, facet, arrayToAdd) => {
  if (arrayToAdd && arrayToAdd.length > 0) {
    searchItemsParams.facetFilters.push(
      arrayToAdd.map((record) => `${facet}:${record}`),
    );
  }
};

const addFacetFilter = (searchItemsParams, facet, valueToAdd) => {
  if (valueToAdd) {
    searchItemsParams.facetFilters.push([`${facet}:${valueToAdd}`]);
  }
};

const useSearchIndexApi = () => {
  const store = useStores();
  const algolia = store.app.algolia;
  const [lastResult, setLastResult] = useState();
  const {user, language} = store.app;

  const searchIndex = useSearchIndex();
  const searchInsigth = useSearchInsights();
  const metaAvailableFor = useAvailableFor();

  const queryID = useMemo(
    () => (lastResult ? lastResult[0].queryID : null),
    [lastResult],
  );

  const registerClick = useCallback(
    (object_id, position) => {
      searchInsigth('clickedObjectIDsAfterSearch', {
        index: algolia.search_index,
        eventName: 'Click item',
        queryID: queryID,
        objectIDs: [object_id],
        positions: [position],
      });
    },
    [searchInsigth, algolia.search_index, queryID],
  );

  const search = useCallback(
    async (
      query,
      {
        restrictSearchableAttributes = [],
        models = [],
        metaCountry = '',
        bounds = null,
        limit = 30,
        exactMatch = true,
        distinct = false,
        org_id = null,
        metaKinds = [],
        metaStatus = [],
        metaIssue = null,
        metaSide = [],
      },
    ) => {
      const params = {
        hitsPerPage: limit,
      };
      const queryToSearch = exactMatch ? `"${query}"` : query;
      const searchAttributesExact = exactMatch
        ? {
            advancedSyntax: true,
            advancedSyntaxFeatures: ['exactPhrase'],
          }
        : {};

      const searchQueries = [];

      if (bounds) {
        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();
        params.insideBoundingBox = [[ne.lat(), ne.lng(), sw.lat(), sw.lng()]];
      }

      const withManufacturer = models.includes('needslist_iop.Manufacturer');
      if (withManufacturer) {
        const index = models.indexOf('needslist_iop.Manufacturer');
        models.splice(index, 1);
      }

      // Search (need and offers)
      const searchItemsParams = {
        ...params,
      };
      if (models.length > 0) {
        searchItemsParams.facetFilters = [];

        addFacetFilter(searchItemsParams, 'meta_country', metaCountry);
        addArrayFacetFilter(searchItemsParams, 'model', models);
        addFacetFilter(searchItemsParams, 'org_id', org_id);
        // Only needs and offer have the attribute meta_available_for
        if (models.some((x) => x.includes('Needs') || x.includes('Offer')))
          addArrayFacetFilter(
            searchItemsParams,
            'meta_available_for',
            metaAvailableFor,
          );

        const metaKindSearch = metaKinds.map((element) => {
          return capitalizeString(element);
        });
        addArrayFacetFilter(searchItemsParams, 'meta_kind', metaKindSearch);
        addArrayFacetFilter(searchItemsParams, 'meta_status', metaStatus);
        addArrayFacetFilter(searchItemsParams, 'meta_issue', metaIssue);
        addArrayFacetFilter(searchItemsParams, 'meta_side', metaSide);

        const userLanguage = (user ? user.language.code : language).split(
          '-',
        )[0];
        searchQueries.push(
          searchIndex.search(queryToSearch, {
            restrictSearchableAttributes,
            distinct,
            ...searchAttributesExact,
            ...searchItemsParams,
            clickAnalytics: true,
            queryLanguages: [userLanguage],
          }),
        );
      }

      if (withManufacturer) {
        // Search (manufacturers)
        const searchManufacturersParams = {
          ...params,
          facetFilters: ['model:needslist_iop.Manufacturer'],
        };
        if (metaCountry) {
          searchManufacturersParams.facetFilters.push([
            `meta_country:${metaCountry}`,
          ]);
        }
        searchQueries.push(searchIndex.search('', searchManufacturersParams));
      }

      return Promise.all(searchQueries).then((responses) => {
        setLastResult(responses);
        const hits = responses.reduce((accum, current) => {
          return accum.concat(current.hits);
        }, []);

        return hits;
      });

      //hits
    },
    [searchIndex, metaAvailableFor, user, language],
  );

  return useMemo(() => {
    return {search, registerClick, MAX: 1000};
  }, [search, registerClick]);
};

export {useSearchIndex, useSearchIndexApi, useAvailableFor};
