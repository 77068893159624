import React, {useMemo} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useLocaleNumber} from '../../hooks/number';
import styles from './PaginatorV2.module.scss';
import {Form} from 'react-bootstrap';
import {usePaginator} from '../../hooks/paginator';
import {
  faAngleLeft,
  faAngleRight,
  faEllipsisH,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const PaginatorV2 = (props) => {
  const {total, pageSize, currentPage} = props;
  const {changePageSize} = usePaginator();
  const localeNumber = useLocaleNumber();
  const numberOfPages = Math.ceil(total / pageSize);
  const morePages = '...';

  const match = useRouteMatch();
  const {t} = useTranslation('common');

  const pages = useMemo(() => {
    let pages = [...new Array(numberOfPages + 1).keys()].slice(1);
    if (pages.length > (props.maxPages || 10)) {
      if (currentPage === 1) {
        pages = [1, 2, morePages, pages[numberOfPages - 1]];
      } else if (currentPage === numberOfPages) {
        pages = [1, morePages, numberOfPages - 1, numberOfPages];
      } else {
        pages = [1];
        if (currentPage - 1 > 2) pages.push(morePages);
        if (currentPage - 1 !== 1) pages.push(currentPage - 1);
        pages.push(currentPage);
        if (currentPage + 1 !== numberOfPages) pages.push(currentPage + 1);
        if (currentPage + 2 < numberOfPages) pages.push(morePages);
        pages.push(numberOfPages);
      }
    }
    return pages;
  }, [currentPage, numberOfPages, morePages, props.maxPages]);

  const handlePageSizeChange = (e) => {
    changePageSize(match.url, e.target.value);
  };

  const onPageSelectorClick = (page, arrow) => () => {
    if (arrow === 'next' && page !== numberOfPages) {
      props.onPageChange(match.url, page + 1);
    }
    if (arrow === 'prev' && page !== 1) {
      props.onPageChange(match.url, page - 1);
    }
    if (!arrow) {
      props.onPageChange(match.url, page);
    }
  };

  const renderItem = (page, index) => {
    if (page === morePages) {
      return (
        <div key={index} className={styles.divEllipsis}>
          <FontAwesomeIcon icon={faEllipsisH} className={styles.icon} />
        </div>
      );
    }

    return (
      <div
        key={index}
        className={styles.divItem}
        onClick={onPageSelectorClick(page)}>
        <span
          className={
            styles.textItem + (page === currentPage ? ' ' + styles.active : '')
          }>
          {page}
        </span>
      </div>
    );
  };
  return (
    <div className={styles.pagerContainer}>
      <span className={styles.summaryText}>
        {t('paginator_v2.page-count', {
          rows: total,
          current: localeNumber(currentPage),
          total: localeNumber(numberOfPages) || 1,
        })}
      </span>

      {numberOfPages > 1 && (
        <div className={styles.divPager}>
          <div
            className={
              styles.divPrev +
              ' ' +
              (currentPage === 1 ? styles.disabledArrow : styles.activeArrow)
            }
            onClick={onPageSelectorClick(currentPage, 'prev')}>
            <FontAwesomeIcon icon={faAngleLeft} className={styles.icon} />
          </div>

          {pages.map(renderItem)}

          <div
            className={
              styles.divNext +
              ' ' +
              (currentPage === numberOfPages
                ? styles.disabledArrow
                : styles.activeArrow)
            }
            onClick={onPageSelectorClick(currentPage, 'next')}>
            <FontAwesomeIcon icon={faAngleRight} className={styles.icon} />
          </div>
        </div>
      )}

      <div className={styles.selectPageSizeContainer}>
        <span className={styles.preDropdownText}>
          {t('paginator_v2.rows-to-display')}
        </span>
        <div className={styles.dropdownContainer}>
          <Form.Control
            className={styles.dropdown}
            as="select"
            onChange={handlePageSizeChange}
            value={pageSize}>
            <option key="page_size_10" value="10">
              10
            </option>
            <option key="page_size_20" value="20">
              20
            </option>
            <option key="page_size_50" value="50">
              50
            </option>
            <option key="page_size_100" value="100">
              100
            </option>
          </Form.Control>
        </div>
      </div>
    </div>
  );
};

export default PaginatorV2;
