import React, {useState, useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {observer} from 'mobx-react';

import {FEATURES} from '../../constants';
import {isFeatureEnabled} from '../../utils/features';
import {useApi} from '../../contexts/ApiContext';
import {useStores} from '../../contexts/StoresContext';
import {useLanguages} from '../../hooks/languages';
import Login from '../Login';
import LoginV2 from '../Login/LoginV2';

const UserInvitation = () => {
  const stores = useStores();
  const api = useApi();
  const [invitation, setInvitation] = useState();
  const {token} = useParams();
  const {setLanguageByQueryParam, setLanguage} = useLanguages();
  const history = useHistory();
  const auth0Enabled = stores.app.clientData.authentication_type === 'auth0';

  const showInvitation = isFeatureEnabled(
    FEATURES.invitations,
    stores.app.features,
  );
  const {user} = stores.app;

  useEffect(() => {
    const selectedLang = setLanguageByQueryParam();
    if (!selectedLang && invitation) {
      setLanguage(invitation.language.code);
    }
  }, [setLanguageByQueryParam, invitation, setLanguage, stores.app]);

  useEffect(() => {
    const fetchInvitation = async (token) => {
      try {
        const fetchedInvitation = await api.getInvitation(token);
        setInvitation(fetchedInvitation);
      } catch (error) {
        history.push('/');
      }
    };
    fetchInvitation(token);
  }, [token, history, stores.app, api]);

  if (!showInvitation || user) history.push('/');

  if (auth0Enabled) {
    if (!invitation) return <></>;

    return (
      <LoginV2
        invitation={invitation}
        isLoading={false}
        signUpType={false}
        isInvitationTokenValid={false}
      />
    );
  }

  return <>{invitation && <Login invitation={invitation} />}</>;
};

export default observer(UserInvitation);
