import React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Button as BootstrapButton} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';

import ResponsiveImg from '../../../components/ResponsiveImg';
import {Card} from 'react-bootstrap';
import {useStores} from '../../../contexts/StoresContext';
import {getInitials} from '../../../utils';
import Avatar from '../../../components/Avatar';
import {MEMBER_PAGE} from '../../../urls';

const Wrapper = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const OrganizationLogo = styled(Avatar)`
  height: 104px;
  width: 104px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  overflow: hidden;
  padding: 15px;
`;

const OrganizationName = styled.h3`
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: normal;
`;

const StyledButton = styled(BootstrapButton)`
  margin: 20px;
`;

const OrgProfile = () => {
  const {t} = useTranslation('manage');
  const stores = useStores();
  const history = useHistory();
  const {logo, name, slug} = stores.app.organization;
  const orgImage = logo ? <ResponsiveImg src={logo} /> : getInitials(name);

  const handleOrgProfileClick = () => {
    history.push(MEMBER_PAGE.replace(':slug', slug));
  };

  return (
    <Wrapper>
      <OrganizationLogo>{orgImage}</OrganizationLogo>
      <OrganizationName>{name}</OrganizationName>
      <StyledButton
        type="submit"
        variant="primary"
        className="text-uppercase"
        onClick={handleOrgProfileClick}
      >
        {t('org-profile.button')}
      </StyledButton>
    </Wrapper>
  );
};

export default observer(OrgProfile);
