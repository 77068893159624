import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Input from '../../../../components/Form/Input';
import Button from '../../../../components/Button';
import styles from './UserSearchBar.module.scss';

const UserSearchBar = (props) => {
  const {onSearchChange} = props;
  const [search, setSearch] = useState('');
  const {t} = useTranslation('user');

  const clearSearch = () => {
    setSearch('');
    onSearchChange('');
  };

  const onSearchClick = () => onSearchChange(search);

  const handleKeyPress = (target) => {
    if (target.charCode === 13) {
      onSearchChange(search);
    }
  };

  return (
    <div className={styles.searchContainer}>
      <Input
        value={search}
        placeholder={t('placeholders.searchbar')}
        inlineLabel={
          <span className={styles.label}>{t('titles.search-by')}</span>
        }
        inlineLabelPosition="left"
        onChange={(event) => setSearch(event.target.value)}
        onKeyPress={handleKeyPress}
      />
      <FontAwesomeIcon
        icon="times"
        onClick={clearSearch}
        className={`${styles.clearIcon} ${!search && styles.hide}`}
      />
      <Button className={styles.customButton} onClick={onSearchClick} size="sm">
        {t('buttons.search')}
      </Button>
    </div>
  );
};

export default UserSearchBar;
