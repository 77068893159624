import {useMemo, useCallback} from 'react';

const useSearchLocation = () => {
  return useMemo(() => {
    return new window.google.maps.places.AutocompleteService();
  }, []);
};

const useSearchLocationLatLng = () => {
  return useMemo(() => {
    return new window.google.maps.Geocoder();
  }, []);
};

const useSearchLocationApi = () => {
  const searchLocation = useSearchLocation();
  const searchLocationLatLng = useSearchLocationLatLng();

  const placesService = useMemo(() => {
    const el = document.createElement('div');
    const map = new window.google.maps.Map(el, {});
    return new window.google.maps.places.PlacesService(map);
  }, []);

  const getPlace = useCallback(
    (placeId) => {
      return new Promise((resolve, reject) => {
        placesService.getDetails({placeId}, (place, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            return reject();
          }
          resolve(place);
        });
      });
    },
    [placesService],
  );

  const search = useCallback(
    (query) => {
      return new Promise((resolve, reject) => {
        searchLocation.getPlacePredictions(
          {input: query, types: ['geocode']},
          (predictions, status) => {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
              reject();
            } else {
              resolve(predictions);
            }
          },
        );
      });
    },
    [searchLocation],
  );

  const searchByLatLng = useCallback(
    (lat, lng) => {
      return new Promise((resolve, reject) => {
        searchLocationLatLng
          .geocode({location: {lat: lat, lng: lng}})
          .then((response) => {
            resolve(response);
          })
          .catch((err) => reject(err));
      });
    },
    [searchLocationLatLng],
  );

  return useMemo(() => {
    return {search, getPlace, searchByLatLng};
  }, [search, getPlace, searchByLatLng]);
};

export {useSearchLocation, useSearchLocationApi, useSearchLocationLatLng};
