import React from 'react';
import {
  RadialBarChart as BaseRadialBarChart,
  RadialBar,
  PolarAngleAxis,
} from 'recharts';
import styled, {css} from 'styled-components';

const CHART_WIDTH = 200;
const CHART_HEIGHT = 200;

const RadialBarChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;
  ${(props) =>
    !props.title &&
    css`
      margin-top: 24px;
    `}
`;

const ChartTitle = styled.h4`
  align-self: flex-start;
  margin-left: 15px;
  font-weight: bold;
  font-size: 14px;
`;

const ContentWrapper = styled.div`
  width: ${CHART_WIDTH}px;
  height: ${CHART_HEIGHT}px;
  position: relative;
`;

const Legend = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Percentage = styled.span`
  color: #263238;
  font-weight: 600;
  font-size: 24px;
`;

const MetPostedValues = styled.span`
  color: #454545;
  font-size: 10px;
`;

const Footer = styled.span`
  font-size: 14px;
  margin-top: 5px;
`;

const RadialBarChart = (props) => {
  const {
    color,
    data,
    footer,
    formatNumber,
    title,
    currency,
    showProgress = true,
  } = props;

  return (
    <RadialBarChartWrapper title={title}>
      {title && <ChartTitle>{title}</ChartTitle>}
      <ContentWrapper>
        <BaseRadialBarChart
          width={CHART_WIDTH}
          height={CHART_HEIGHT}
          innerRadius="85%"
          outerRadius="100%"
          data={[data]}
          startAngle={90}
          endAngle={-270}
          margin={{top: 0, right: 0, bottom: 0, left: 0}}
          barCategoryGap={0}
        >
          <RadialBar
            background
            dataKey="met"
            fill={showProgress ? color : '#eeeeee'}
          />
          <PolarAngleAxis
            type="number"
            domain={[0, data.posted]}
            tick={false}
          />
        </BaseRadialBarChart>
        <Legend>
          <Percentage>
            {!showProgress
              ? formatNumber(data.met, currency)
              : data.posted === 0
              ? '-'
              : `${((data.met / data.posted) * 100).toFixed(1)}%`}
          </Percentage>
          {showProgress && (
            <MetPostedValues>
              {`${formatNumber(data.met, currency)} / ${formatNumber(
                data.posted,
                currency,
              )}`}
            </MetPostedValues>
          )}
        </Legend>
      </ContentWrapper>
      {footer && <Footer>{footer}</Footer>}
    </RadialBarChartWrapper>
  );
};

export default RadialBarChart;
